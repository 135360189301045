import {Application, LocalEventsManager, Cookies, RemoteEventsManager, ErrorHandler, translator} from 'core';
import {CustomNotification, EventsToolbar, GridMenu, MultiToggle, PreferencesPanel} from 'controls';
import {Utils, UserPrefs, State} from 'tools';
import Settings from 'settings';
import {ServiceQualifierForm} from "areas/administration/template";
import Dialog from 'controls/dialog';
import ModalView from 'controls/modalView';
import ModalWindow from 'controls/modalWindow';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {topLeftCornerPosition} from 'controls/modalWindow'

const i = translator({
  "Shared sq template name template": {
    "en": "{0} (public)",
    "no": "{0} (offentlig)"
  }
});

export {i};

export let ServiceQualifierView = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default ServiceQualifierView;

jQuery.extend(ServiceQualifierView.prototype, Application.prototype, {
	/**
	 * Service model templates view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.getTags();

		this.viewType = 'grid';
		this.gridLoaded = false;
		this.cardLoaded = false;

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 260
			},
			systemType: {
				hidden: false,
				width: 140
			},
			category: {
				hidden: false,
				width: 140
			},
			tags: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};
		this.loadUserPreferences();

		// update content button
		this.cardGridToggle = new MultiToggle({
			id: 'cw_sqtemplates_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_sqtemplates_grid_menu',
			items: [{
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onSQTemplateDelete,
				scope: this,
				disabled: true,
				role: 'SERVICE_MODEL_DELETE'
			},
				{
					id: 'cw_import',
					icon: 'disk-import',
					text: lang.IMPORT,
					fn: this.onSQTemplateImport,
					scope: this,
					disabled: false,
					role: 'SERVICE_MODEL_UPDATE'
				},
				{
					id: 'cw_export',
					icon: 'disk-export',
					text: lang.EXPORT,
					fn: this.onSQTemplateExport,
					scope: this,
					disabled: true,
					role: 'SERVICE_MODEL_UPDATE'
				}]
		});

		this.removeMask();
		this.initKendoComponents();
		this.removeListeners();
		this.attachListeners();
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Initialize Kendo components
	 */
	initKendoComponents: function () {
		$('#cw_sqtemplates_upload').kendoUpload({
			async: {
				autoUpload: true,
				saveUrl: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/import',
				saveField: 'file'
			},
			multiple: false,
			showFileList: true,
			withCredentials: false,
			localization: {
				select: lang.kendo.UPLOAD_SELECT,
				statusFailed: lang.kendo.UPLOAD_STATUS_FAILED,
				statusUploaded: lang.kendo.UPLOAD_UPLOADED,
				statusUploading: lang.kendo.UPLOAD_UPLOADING,
				uploadSelectedFiles: lang.kendo.UPLOAD_SELECTED_FILES
			},
			error: function (e) {

			},
			upload: $.proxy(function (e) {
				var xhr = e.XMLHttpRequest;
				if (xhr) {
					xhr.addEventListener("readystatechange", function (e) {
						if (xhr.readyState == 1 /* OPENED */) {
							xhr.setRequestHeader("Auth-Token", Cookies.sessionId);
						}
					});
				}
				var file = e.files[0];
				this.fileType = file.rawFile.type;
				this.fileName = file.rawFile.name;
			}, this),
			success: $.proxy(function (e) {
				this.actionNotification.setOptions({
					message: lang.account.messages.SERVICE_TEMPLATE_SUCCESS_UPLOADED,
					status: 'success'
				}).show();
				this.dataSource.read();
			}, this)
		});
		$('.k-upload').addClass('hide');
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {String} value
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			this.viewType = 'grid';
			$('#cw_sqtemplates_list').addClass('hide');
			$('#cw_sqtemplates_grid').removeClass('hide');

			if (this.grid) {
				$('.list_content').addClass('list_content_full');
			} else {
				$('.list_content').removeClass('list_content_full');
				this.createDataSource();
				this.gridLoaded = false;
				this.initGridView();
			}
		} else {
			this.viewType = 'card';
			$('#cw_sqtemplates_grid').addClass('hide');
			if (this.innerView) {
				$('#cw_sqtemplates_list').removeClass('hide');
			} else {
				$('#cw_accounts_grid').addClass('hide');
				this.createDataSource();
				this.cardLoaded = false;
				this.initCardView();
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#sqtemplates_grid').off();
		this.onSQTemplateSavedHandler = $.proxy(this.onSQTemplateSaved, this);
		LocalEventsManager.unbind('sqtemplatesaved', this.onSQTemplateSavedHandler);
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#sqtemplates_grid').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#sqtemplates_grid').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#sqtemplates_grid').on('click', '.cw_grid_link', $.proxy(this.onTeamNameClick, this));
		LocalEventsManager.bind('sqtemplatesaved', this.onSQTemplateSavedHandler);
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		var scope = this;
		var deleteClass = '';
		var updateClass = '';
		var template = '<li><div class="cw_card" id="${id}">';
		template += '<div class="cw_card_avatar">';
		template += '<div class="cw_avatar cw_avatar_sq_template">';
		template += '<span class="glyphicons cogwheel"></span>';
		template += '</div>';
		template += '</div>';
		template += '<div class="cw_card_content">';
		template += '<h2 class="card_title card2"><strong>' + lang.NAME + ':</strong> ${name} </h2>';
		template += '<p class="card_extra card2"><strong>' + lang.DESCRIPTION + ':</strong> ${description}</p>';
		template += '<p class="card_extra card2"><strong>' + lang.account.SYSTEM_TYPE + ':</strong> ${systemType}</p>';
		template += '<p class="card_extra card2"><strong>' + lang.CATEGORY + ':</strong> ${category}</p>';
		template += '</div>';
		template += '<div class="cw_card_options">';
		template += '<span class="glyphicons bin ' + deleteClass + '"></span>';
		template += '<span class="glyphicons pencil ' + updateClass + '"></span>';
		template += '</div></li>';
		$('#cw_sqtemplates_view_toggle').find('li').last().addClass('is_selected');

		// create sq templates view
		this.innerView = new ModalView({
			autoLoad: true,
			createIds: '',
			createButtonText: '',
			viewDataSource: this.dataSource,
			dataBound: $.proxy(this.cardViewDataBound, this),
			template: template,
			id: 'cw_sqtemplates_list',
			cssClass: 'sq_templates_ul',
			title: 'Service Qualifier Templates EN',
			countId: 'count_sq_templates',
			pager: true,
			onEditButton: function (e) {
				var templateId = e.currentTarget.parentNode.parentNode.id;
				var modalWindow = new ModalWindow({
					title: lang.account.SERVICE_QUALIFIER_TEMPLATE_DETAILS,
					width: 400,
					height: 320,
					minHeight: 320,
					actions: [],
					url: 'include/Administration/ServiceQualifierTemplateForm.jsp',
					onClose: function () {
						RemoteEventsManager.discard(templateId);
					},
					refresh: function () {
						 new ServiceQualifierForm({
							id: templateId,
							mode: 'update'
						});
					}
				});
				modalWindow.open();
			},
			onDeleteButton: function (e) {
				var id = e.currentTarget.parentNode.parentNode.id;
				url = Settings.serverPath + 'templates/' + id + '/servicequalifiers/';
				var dialog = new Dialog({
					title: lang.INFO,
					msg: lang.account.messages.SERVICE_QUALIFIER_TEMPLATE_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					scope: this,
					actionText: 'DELETE',
					fn: function (value, button) {
						if (button === 'ok') {
							Utils.ajax(url, 'DELETE', {}, $.proxy(function (result) {
								if (result.success) {
									scope.actionNotification.setOptions({
										message: lang.account.messages.SERVICE_QUALIFIER_TEMPLATE_SUCCESS_DELETED,
										status: 'success'
									}).show();

									// reload data source
									scope.dataSource.read();
								} else {
									Utils.showInfo(lang.ALERT, result.message, result.details);
								}
							}, this));
						}
					}
				});
				dialog.show();
			}
		});

		setTimeout(function () {
			$('#cw_sqtemplates_list').removeClass('hide');
		}, 300);

	},
	/**
	 * Init grid component
	 */
	initGridView: function () {
		var filterMessages = lang.grid.filter
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		var sqtemplatesColumns = this.sqtemplatesColumns || JSON.parse(UserPrefs.get('sqtemplatesColumns')) || this.defaultColumns;
		sqtemplatesColumns = Utils.completeColumns(sqtemplatesColumns, this.defaultColumns);
		this.grid = $('#sqtemplates_grid').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			autoBind: !this.gridLoaded,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				hidden: sqtemplatesColumns.id.hidden,
				width: sqtemplatesColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id #">#= !shared ? name : "' + i('Shared sq template name template') + '".replace("{0}", name)  #</a>',
				hidden: sqtemplatesColumns.name.hidden,
				width: sqtemplatesColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'systemType',
				title: lang.account.SYSTEM_TYPE,
				sortable: true,
				filterable: true,
				hidden: sqtemplatesColumns.systemType.hidden,
				width: sqtemplatesColumns.systemType.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'category',
				title: lang.CATEGORY,
				sortable: true,
				filterable: true,
				hidden: sqtemplatesColumns.category.hidden,
				width: sqtemplatesColumns.category.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: sqtemplatesColumns.tags.hidden,
				width: sqtemplatesColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: false,
				filterable: true,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: sqtemplatesColumns.description.hidden,
				width: sqtemplatesColumns.description.width
			}], sqtemplatesColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onGridDataBound, this)
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('sqtemplatesSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_sqtemplates_filters',
			grid: this.grid,
			modulePrefName: 'AdministrationSQTemplates',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'sqtemplatesFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	/*
	 * Handler function for card view databound event
	 * @param {Object} e The databound event
	 */
	cardViewDataBound: function () {
		this.cardLoaded = true;
	},
	/*
	 * Handler function for reloading sections which are using tags
	 * */
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Handler for the grid Data Bound event
	 */
	onGridDataBound: function () {
		this.gridLoaded = true;
		$('#sqtemplates_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
	},
	/**
	 * Handler function for the change(select) event on the grid
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#sqtemplates_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var sqtemplates = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				sqtemplates.push($(checkboxes[i]).data('id'));
			}
		}
		if (sqtemplates.length) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_export');
			if (sqtemplates.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
			this.gridMenu.disableItem('cw_export');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_export');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
			this.gridMenu.disableItem('cw_export');
		}
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onSQTemplateDelete: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var url = Settings.serverPath + 'templates/servicequalifiers/delete?dc=' + Utils.timestamp();
			var checkboxes = $('.cw_grid_check');
			var i, length = checkboxes.length, haveSQTemplates = false;
			var ids = [];
			for (i = 0; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var id = $(checkboxes[i]).attr('data-id');
					ids.push(id);
					haveSQTemplates = true;
				}
			}
			if (haveSQTemplates) {
				var dialog = new Dialog({
					title: lang.INFO,
					msg: lang.account.messages.SERVICE_QUALIFIER_TEMPLATE_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					position: topLeftCornerPosition,
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							this.gridMenu.disableItem('cw_delete');
							this.gridMenu.disableItem('cw_update');
							Utils.ajax(url, 'POST', JSON.stringify(ids), $.proxy(function (result) {
								if (result.success) {
									this.actionNotification.setOptions({
										message: lang.account.messages.SERVICE_QUALIFIER_TEMPLATE_SUCCESS_DELETED,
										status: 'success'
									}).show();

									$('.cw_grid_check_all').prop('checked', false);
									// reload data source
									this.dataSource.read();
								} else {
									Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
								}
							}, this));
						}
					}, this),
					deleteFromGrid: this.grid
				});
				dialog.show();
			}
		}
	},
	/**
	 * Handler function for the click event on the sm template update menu item
	 * @param {Object} e The click event object
	 */
	onTeamNameClick: function (e) {
		var templateId = $(e.currentTarget).data('id');
		var mode = 'view';
		if (State.mainApp.session.hasRole('SERVICE_MODEL_UPDATE')) {
			mode = 'update';
		}
		var modalWindow = new ModalWindow({
			title: lang.account.SERVICE_QUALIFIER_TEMPLATE_DETAILS,
			width: 400,
			height: 370,
			minHeight: 370,
			position: topLeftCornerPosition,
			actions: [],
			url: 'include/Administration/ServiceQualifierTemplateForm.jsp',
			onClose: function () {
				if (mode === 'update') {
					RemoteEventsManager.discard(templateId);
				}
			},
			refresh: function () {
				 new ServiceQualifierForm({
					id: templateId,
					mode: mode
				});
			}
		});
		modalWindow.open();
		e.stopPropagation();
	},
	/**
	 * Handler function for the click event on the sm template update menu item
	 * @param {Object} e The click event object
	 */
	onSQTemplateUpdate: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var templateId = $('.cw_grid_check:checked').attr('data-id');
			if (State.mainApp.session.hasRole('SERVICE_MODEL_UPDATE') || State.mainApp.session.hasRole('SERVICE_MODEL_READ')) {
				var modalWindow = new ModalWindow({
					title: lang.account.SERVICE_QUALIFIER_TEMPLATE_DETAILS,
					width: 400,
					height: 240,
					minHeight: 240,
					position: topLeftCornerPosition,
					actions: [],
					url: 'include/Administration/ServiceQualifierTemplateForm.jsp',
					onClose: function () {
						RemoteEventsManager.discard(templateId);
					},
					refresh: function () {
						 new ServiceQualifierForm({
							id: templateId,
							mode: 'update'
						});
					}
				});
				modalWindow.open();
			}
		}
	},
	/**
	 * Handler function for the click event on the sm template import menu item
	 * @param {Object} e The click event object
	 */
	onSQTemplateImport: function (e) {
		$("#cw_sqtemplates_upload").click();
	},
	/**
	 * Handler function for the click event on the sm template export menu item
	 * @param {Object} e The click event object
	 */
	onSQTemplateExport: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/servicequalifiers/export';
		var checkboxes = $('#cw_sqtemplates_grid').find('.cw_grid_check');
		var docs = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				docs.push($(checkboxes[i]).data('id'));
			}
		}
		Utils.ajax(url, 'POST', JSON.stringify(docs), function (result) {
			var sessionTemplateId, downloadUrl;
			if (result.success) {
				sessionTemplateId = result.data.id;
				downloadUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/download/' + sessionTemplateId;
				window.open(downloadUrl);
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		});
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'viewType',
			value: this.viewType
		}];

		var config = {
			category: 'AdministrationSQTemplates',
			preferences: preferences
		};

		if (this.viewType === 'grid') {
			config.filterPanel = this.gridFilterPanel
			config.grid = this.grid;
			config.keys = {
				searchPhrase: 'sqtemplatesSearchPhrase',
				columns: 'sqtemplatesColumns',
				sort: 'sqtemplatesSort',
				filter: 'sqtemplatesFilter',
				FsViews: 'sqtemplatesFSViews'
			};
		}

		this.saveUserPrefs(config);
	},
	createDataSource: function () {
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/servicequalifiers/private?dc=' + Utils.timestamp(),
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function (result) {
					for (let i = 0; i < result.length; i++) {
						if (result[i].tags) {
							result[i].tagsArray = result[i].tags;
							result[i].tags = result[i].tags.join(', ');
						}
					}
					return result;
				}
			},
			sort: this.sqtemplatesSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.sqtemplatesFilter || [],
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, pref;
		UserPrefs.load('AdministrationSQTemplates', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.viewType = UserPrefs.get('viewType') || 'grid';
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
						sort: [],
						filter: []
					};
					this.sqtemplatesSort = pref.sort;
					this.sqtemplatesFilter = pref.filter;
					this.sqtemplatesColumns = pref.columns;
				} else {
					if (UserPrefs.get('sqtemplatesSort')) {
						this.sqtemplatesSort = JSON.parse(UserPrefs.get('sqtemplatesSort'));
					} else {
						this.sqtemplatesSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('sqtemplatesFilter')) {
						this.sqtemplatesFilter = JSON.parse(UserPrefs.get('sqtemplatesFilter'));
					} else {
						this.sqtemplatesFilter = [];
					}
				}

				this.cardGridToggle.select(this.viewType === 'grid' ? 'cw_grid_view' : 'cw_card_view');
				this.createDataSource();

				if (this.viewType === 'grid') {
					$('#cw_filter_reset').removeClass('hide');
					$('#cw_sqtemplates_list').addClass('hide');
					$('#cw_sqtemplates_grid').removeClass('hide');
					this.initGridView();
				} else {
					this.initCardView();
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the sm template saved event
	 */
	onSQTemplateSaved: function () {
		this.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Application.prototype.destroy.call(this);
		this.saveUserPreferences();
	}
});
