import { throttle } from "lodash"
import { makeAutoObservable, reaction, IReactionDisposer, autorun } from "mobx"

import {GridDataItem} from "controls/grid/gridDataItem"
import {GridPlugin} from "controls/grid/gridConfiguration"
import {GridStore} from "controls/grid/gridStore"
import {RemoteDataProvider} from "controls/grid/remoteDataProvider"
import {RemoteEventsManager} from "core"
import {Event} from "framework/entities/events"
import {MobxManager} from "framework/mobx-integration";


type AutoReloadPluginSettings = {
	interval: number
	mode: UpdateMode
}

type AutoReloadPluginConfig<DataItem extends GridDataItem> = {
	subscriptions?: Array<object>
	getSubscriptions?: (store: GridStore<DataItem>) => Array<object>
	shouldReload?: (e: Event) => boolean
}

export enum UpdateMode{
	None = 'pause',
	OnTimer = 'on-timer',
	OnEvent = 'on-event'
}

export class AutoReloadPlugin<DataItem extends GridDataItem> implements GridPlugin<DataItem> {
	gridStore: GridStore<DataItem>
	timerId: ReturnType<typeof setInterval>

	countdownStartedAt: number
	lastTickAt: number

	editing: boolean = false
	tempInterval: number

	disabled: boolean
	disabledReason: string

	config: AutoReloadPluginConfig<DataItem>

	mobx = new MobxManager()

	reloadThrottled: () => void

	staticSubscription: {unsubscribe: () => void}
	dynamicSubscription: {unsubscribe: () => void}

	id: string

	constructor(config?: AutoReloadPluginConfig<DataItem>) {
		this.config = config ?? {}

		makeAutoObservable(this, {
			staticSubscription: false,
			dynamicSubscription: false,
			timerId: false
		})

		this.reloadThrottled = throttle(this.forceReload, 5000, {
			'leading': false
		})

		this.id = 'auto-reload'
	}

	get timerSettings() : AutoReloadPluginSettings{
		const customData = this.gridStore.state.customData

		if(!customData.autoReload) {
			customData.autoReload = {
				interval: 30,
				mode: UpdateMode.OnTimer
			}
		}

		return customData.autoReload
	}

	attach(store: GridStore<DataItem>): void {
		if (!(store.dataProvider instanceof RemoteDataProvider)) {
			console.warn('AutoReloadPlugin requires RemoteDataProvider to be used')
			return
		}

		this.gridStore = store

		if(this.timerSettings.mode != UpdateMode.None){
			this.resetCountdown()
		}

		this.timerId = setInterval(this.tick, 500)

		this.mobx.reaction(() => this.timeLeft, async () => {
			if(this.timeLeft != 0)
				return

			if(this.#events.some(this.shouldReload)) {
				await this.dataProvider.silentReload()
			}

			this.resetCountdown()
		})

		this.mobx.reaction(() => this.timerSettings.interval, () => {
			this.resetCountdown()
		})

		this.mobx.reaction(() => this.timerSettings.mode, (mode) => {
			if(mode == UpdateMode.OnTimer) {
				this.resetCountdown()
			}
		})

		if(this.config.subscriptions?.length > 0){
			this.staticSubscription = RemoteEventsManager.subscribeCallback(this.config.subscriptions, this.onEvent)
		}

		this.mobx.reaction(() => this.disabled,() => {
			if(!this.disabled){
				this.resetCountdown()
			}
		})

		if(this.config.getSubscriptions != null){
			this.mobx.autorun(() => {
				if(!this.gridStore.dataProvider.initialized)
					return

				this.dynamicSubscription?.unsubscribe()
				if(!this.disabled) {
					this.dynamicSubscription = RemoteEventsManager.subscribeCallback(
						this.config.getSubscriptions(this.gridStore), this.onEvent)
				}
			})
		}
	}

	get dataProvider(){
		return this.gridStore.dataProvider as RemoteDataProvider<DataItem>
	}

	get reloading(){
		return this.dataProvider.reloading
	}

	get timeLeft(){
		if(this.editing){
			return null
		}

		if(this.timerSettings.mode != UpdateMode.OnTimer){
			return this.timerSettings.interval
		}

		if(this.disabled){
			return this.timerSettings.interval
		}

		return Math.max(0, this.timerSettings.interval - Math.floor((this.lastTickAt - this.countdownStartedAt)/1000))
	}

	get timeLeftDisplay(){
		return Math.ceil(this.timeLeft / 5) * 5
	}

	#events: Event[] = []
	onEvent = (e: Event) => {
		if (this.timerSettings.mode == UpdateMode.None)
			return

		if (this.timerSettings.mode == UpdateMode.OnTimer) {
			this.#events.push(e)
			return
		}

		if (this.shouldReload(e)) {
			this.reloadThrottled()
		}
	}

	shouldReload = (e: Event) => {
		return !this.config.shouldReload || this.config.shouldReload(e)
	}

	resetCountdown(){
		this.lastTickAt = this.countdownStartedAt = Date.now()
	}

	tick = () => {
		this.lastTickAt = Date.now()
	}

	editTimer = () => {
		this.editing = true
		this.tempInterval = this.timerSettings.interval
	}

	saveInterval = () => {
		this.timerSettings.interval = Math.max(10, this.tempInterval)
		this.cancelEditing()
	}

	cancelEditing(){
		this.editing = false
		this.resetCountdown()
	}

	forceReload = async () => {

		await this.dataProvider.silentReload()

		this.resetCountdown()

		this.#events = []
	}

	destroy(){
		clearInterval(this.timerId)
		this.mobx.destroy()
		this.staticSubscription?.unsubscribe()
		this.dynamicSubscription?.unsubscribe()
	}
}
