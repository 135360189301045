import './antConfirmModal.less';
import React, {FunctionComponent} from 'react';
import {AntModal, ModalPosition} from './antModal';
import {Section} from 'controls/react/layout/section';
import classnames from "classnames";
import {CloseCircleFilled, QuestionCircleFilled, WarningFilled} from '@ant-design/icons';

const b = require('b_').with('ant-confirm-modal');

export type IconType = 'INFO' | 'WARNING' | 'ERROR';

export interface AntConfirmModalProps {
	onCancel: () => void;
	onOk: () => void;
	title: string;
	okText: string;
	icon: IconType;
	message: string;
	className?: string;
	positionType?: ModalPosition;
}


export const AntConfirmModal: FunctionComponent<AntConfirmModalProps> = props => {
	const classes = classnames(props.className ?? '', b());
	return <AntModal okText={props.okText}
					 title={props.title}
					 visible={true}
					 onCancel={props.onCancel}
					 onOk={props.onOk}
					 positionType={props.positionType}
					 className={classes}>
				<Section>
					<Icon icon={props.icon} /> {props.message}
				</Section>
			</AntModal>
}

const Icon: FunctionComponent<{icon: IconType}> = (props: {icon: IconType}) => {
	switch(props.icon) {
		case 'INFO': {
			return <QuestionCircleFilled className={b('icon')} />
		}
		case 'WARNING': {
			return <WarningFilled className={b('icon')} />
		}
		case 'ERROR': {
			return <CloseCircleFilled className={b('icon')} />
		}
	}
	return null;
}
