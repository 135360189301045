import './gridHeaderFilter.less'

import React from "react"
import {observer} from "mobx-react"
import {makeAutoObservable} from "mobx"
import {deserialize, serialize} from 'serializr'
import {FilterFilled} from "@ant-design/icons"


import {GridColumn} from "controls/grid/gridColumnConfig"
import {FormEntry} from "controls/react/form"
import {Section} from "controls/react/layout/section"
import {AntSelect} from "controls/react/ant/antSelect"
import {Toolbar} from "controls/react/layout"
import {AntButton} from "controls/react/ant/antButton"
import {AntPopover} from "controls/react/ant/antPopover"
import {GridDataItem} from "controls/grid/gridDataItem"
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition"
import {QueryBuilderRuleOperator, QueryBuilderRuleValue} from 'controls/queryBuilder/queryBuilder'
import {copyViaSerializr} from "framework/serializr-integration";
import {styleForThingsOnPopover} from "controls/react/ant/zIndexes";


const i = require('core/localization/localization').translator({
	'Custom filtering applied message': {
		en: 'It looks like custom filtering is applied in view settings so we cant show a simple filter editor for the column.'
	}
})

type GridHeaderFilterProps<DataItem extends GridDataItem> = {
	column: GridColumn<DataItem>
}

const b = require('b_').with('grid-header-filter')

class GridHeaderFilterStore{
	constructor(public operator: string, public value: string|string[]) {
		makeAutoObservable(this)
	}
}

type GridHeaderFilterState = {
	visible: boolean
}

export const GridHeaderFilter = observer(
	class GridHeaderFilterInner<DataItem extends GridDataItem> extends React.Component<GridHeaderFilterProps<DataItem>, GridHeaderFilterState>{

		constructor(props: GridHeaderFilterProps<DataItem>) {
			super(props);

			this.state = {
				visible: false
			}
		}

		render() {
			if (!this.props.column.filteringEnabled)
				return null

			return <div onClick={this.toggleVisibility}>
				<AntPopover visible={this.state.visible}
				            trigger={"click"}
				            content={<GridHeaderFilterContent column={this.props.column} hidePopup={this.hidePopup}/>}
				            destroyTooltipOnHide={true}
				            onVisibleChange={newVisibile => this.setState({visible: newVisibile})}
				            placement={"bottom"}>
					<FilterFilled/>
				</AntPopover>
			</div>
		}

		toggleVisibility = (e: React.MouseEvent) => {
			this.setState({visible: !this.state.visible})

			e.preventDefault()
			e.stopPropagation()
		}

		hidePopup = () => {
			this.setState({visible: false})
		}
})

type GridHeaderFilterContentProps<DataItem extends GridDataItem> = GridHeaderFilterProps<DataItem> & {hidePopup: () => void}

const GridHeaderFilterContent = observer(
	class GridHeaderFilterContentInner<DataItem extends GridDataItem> extends React.Component<GridHeaderFilterContentProps<DataItem>>{
		filterCopy: RuleDefinition

		constructor(props: GridHeaderFilterContentProps<DataItem>) {
			super(props)

			if(this.props.column.store.customFiltering)
				return

			if(this.props.column.filter)
				this.filterCopy = copyViaSerializr(this.props.column.filter)
			else
			{
				this.filterCopy = RuleDefinition.emptyRule(
					this.props.column.field,
					this.filterDescription.operators[0].value)
			}
		}

		get filterDescription(){
			return this.props.column.filterDescription
		}

		render(){
			if(this.props.column.store.customFiltering){
				return <Section className={b()}>
					<p>{i('Custom filtering applied message')}</p>
				</Section>
			}
			return <Section className={b()} childrenPadding={true} onClick={this.preventPropagation}>
				<FormEntry label={i("Operator")} vertical={true} model={this.filterCopy.properties} modelField={"operator"}>
					<QueryBuilderRuleOperator rule={this.filterCopy}
					                          config={this.props.column.store.filtersConfigurationEffective}/>
				</FormEntry>
				<FormEntry label={i("Value")} vertical={true}>
					<QueryBuilderRuleValue rule={this.filterCopy}
					                       config={this.props.column.store.filtersConfigurationEffective}
					                       onPressEnter={this.updateFilter}
					                       onPressEsc={this.props.hidePopup}
					/>
				</FormEntry>
				<Toolbar appearance={"transparent"}>
					<AntButton onClick={this.clearFilter}>{i('Reset')}</AntButton>
					<AntButton type={"primary"} onClick={this.updateFilter}>{i('Ok')}</AntButton>
				</Toolbar>
			</Section>
		}

		preventPropagation = (e: React.MouseEvent) => {
			e.stopPropagation()
		}

		updateFilter = () => {
			this.props.column.updateFilter(this.filterCopy)
			this.props.hidePopup()
		}

		clearFilter = () => {
			this.props.column.clearFilter()
			this.props.hidePopup()
		}
	}
)
