import {Api} from "tools";
import { ApiRequest } from 'framework/api';

export default class CalendarsAPI {
	static getBaseUrl() {
		return `${Api.serverRoot()}calendars`
	}
	static getGenericCalendarConfiguration(calendarId, update) {
		return Api.fetch(`${CalendarsAPI.getBaseUrl()}/generic/${calendarId}?update=${update}`)
	}
	static getHolidayCalendarConfiguration(calendarId, update) {
		return Api.fetch(`${CalendarsAPI.getBaseUrl()}/holiday/${calendarId}?update=${update}`)
	}
	static generateHolidayCalendar(calendarId, year) {
		return Api.fetch(`${CalendarsAPI.getBaseUrl()}/holiday/${calendarId}/generate/${year}`)
	}
	static generateNewHolidayCalendar(year, country) {
		return Api.fetch(`${CalendarsAPI.getBaseUrl()}/holiday/generate/${year}?country=${country}`)
	}
	static saveCalendar(calendarType, calendarObj) {
		return Api.fetchPost(`${CalendarsAPI.getBaseUrl()}/${calendarType}`, calendarObj)
	}
	static getSearchUrl() {
		return Api.accountRoot() + 'calendars?name=';
	}
}

export {CalendarsAPI}

export function getTimeZones() {
	return new ApiRequest({
		url: 'sessions/timezones/',
		method: 'GET'
	})
}
