export enum Actions{
	ZoomIn = 'zoom-in',
	ZoomOut = 'zoom-out',
	ToFront = 'to-front',
	Scale = 'scale',
	ToBack = 'to-back',
	Delete = 'delete',
	Undo = 'undo',
	Redo = 'redo',
	OutlineWindowToggle = 'outline-window-toggle',
	ShapePropertiesToggle = 'show-shape-properties',
	CollectionsToggle = 'collections-toggle',
	WidgetsWizard = 'widgets-wizard-show',
	PreviewToggle = 'preview-toggle',
	SetContainerSize = 'set-container-size',
	AssetsWindowToggle = "assets-window-toggle",
	AssetsGroupsWindowToggle = "assets-groups-window-toggle",
	FillColorSelect = "fill-color-select",
	ServiceDesignerElementWindowToggle = "sd-element-window-toggle",
	ServiceDesignerHistoryWindowToggle = "sd-history-window-toggle",
	ServiceDesignerLogWindowToggle = "sd-log-window-toggle",
	ServiceDesignerAutoLayout = 'sd-autolayout',
	ServiceDesignerResetLayout = 'sd-reset-layout',
	ServiceDesignerDraft = 'sd-draft',
}
