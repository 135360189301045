import {ArgsProps} from "antd/lib/message";
import {message} from "antd";
import {apiFetch} from "framework/api";

export type PerformActionOptions =  {
	loadingOptions?: Partial<ArgsProps>
	completedOptions?: Partial<ArgsProps>
	loadingDelay?: number
}

const i18n = require('core/localization').translator({
	'Working...': {

	},
	'Action completed': {
		no: 'Handling utført'
	}
})

export async function performAction<T>(action: () => Promise<T>, options?: PerformActionOptions): Promise<T>{
	const loadingOptions: Partial<ArgsProps> = Object.assign({
		content: i18n('Working'),
		duration: 10000,
		key: 'action-message'
	}, options?.loadingOptions )


	const timer = setTimeout(() => {
		message.loading(loadingOptions)
	}, 500)

	const result = await action()

	clearTimeout(timer)

	const completedOptions = Object.assign({
		content: i18n('Action completed'),
		key: 'action-message'
	})

	message.success(completedOptions)

	return result
}
