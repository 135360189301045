import {isHtmlContainerCell} from "controls/designer/features/htmlContainerShape";
import {getWidgetDescription} from "controls/designer/features/widgets/allWidgets";

export function isWidgetCell(graph, cell) {
	return isHtmlContainerCell(graph, cell) && cell.value.getAttribute('type') == 'widget';
}

export async function getWidgetActualConfig(cell) {
	let config = cell.getWidgetStoredConfig();
	const widgetDescription = await getWidgetDescription(config.type);

	config = Object.assign({}, widgetDescription.defaultConfig, config);
	if (widgetDescription.preProcessConfig) {
		config = widgetDescription.preProcessConfig(config);
	}
	return [config, widgetDescription];
}
