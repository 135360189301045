import {ApiRequest, PagedList} from "framework/api";
import {ServicesListEntry} from "./servicesListEntry";
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {GridStore} from "controls/grid/gridStore"

export type GetServicesArgs = {
	accountId?: string,
	assetId?: string,
	linkServiceId?: string,
	tags?: string[],
	showUntagged?: boolean,
	includeSubaccounts?: boolean
	ignoreUserTags?: boolean
}

export function getServices(args?: GetServicesArgs){
	return new ApiRequest<PagedList<ServicesListEntry>>({
		url: 'services/serviceDetails',
		accountBased: true,
		accountId: args?.accountId,
		subaccountsFilter: true,
		includeSubaccounts: args?.includeSubaccounts,
		method: 'POST',
		payload: {
			assetId: args?.assetId,
			linkServiceId: args?.linkServiceId,
			tags: args?.tags,
			showUntagged: args?.showUntagged,
			ignoreUserTags: args?.ignoreUserTags
		}
	});
}

type GetServicesFiltersArgs = {
	accountId?: string
}

export function getServicesListFilters(args?: GetServicesFiltersArgs){
	return new ApiRequest<RulesConfiguration>({
		url: 'services/serviceDetails/ruleConfiguration',
		accountBased: true,
		subaccountsFilter: true,
		method: 'GET'
	});
}

export type NamedItem = {
	id: string,
	name: string
}

export function deleteServices(store: GridStore<any>){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/delete',
	});
}

export function setServicesInMaintenance(store: GridStore<any>, maintenanceData: object){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/startMaintenance',
		payload: {
			bulkMaintenance: maintenanceData
		}
	});
}

export function endMaintenance(store: GridStore<any>){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/endMaintenance',
	});
}

export function exportCSV(store: GridStore<any>){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/exportCSV',
	});
}

export function setInformation(store: GridStore<any>, informationText: string){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/setInformation',
		payload: {
			bulkInformation: informationText
		}
	});
}

export function setTags(store: GridStore<any>, tags: string[]){
	return store.getSelectionApiRequest({
		url: 'services/summaryList/setTags',
		payload: {
			bulkTags: tags
		}
	});
}

export class RecalculationStatus {
	total: number;
	completed: number;
	errors: number;
	details?: RecalculationDetail[] | undefined;
}

export class RecalculationDetail {
	id: string;
	name: string;
	details: string[];
}

export interface IGridStorePayloadProvider {
	getSelectionApiRequest<T>(args: { url: string, payload?: Record<string, any> }): ApiRequest<T>;
}

export function startRecalculation(store: IGridStorePayloadProvider, startTime: Date, recalculateSla: boolean) {
	return store.getSelectionApiRequest<string>({
		url: 'services/summaryList/recalculateStates',
		payload: {
			recalculateTime: startTime.getTime(),
			recalculateSla: recalculateSla
		}
	});
}

export function recalculationStatus(jobId: string) {
	return new ApiRequest<RecalculationStatus>({
		url: `sessions/scheduledJobs/${jobId}/status`,
		method: 'GET'
	});
}

export function cancelRecalculation(jobId: string) {
	return new ApiRequest<RecalculationStatus>({
		url: `sessions/scheduledJobs/${jobId}/cancel`,
		method: 'GET'
	});
}
