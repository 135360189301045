import React, {useCallback, useMemo, useState} from 'react'
import {observer} from 'mobx-react'
import Select, {RefSelectProps, SelectProps, SelectValue} from 'antd/lib/select'
import {Input, Tag, TagProps, Tooltip} from 'antd'

import {EditOutlined} from '@ant-design/icons'
import {renderSelectOptions} from "controls/react/ant/utils"
import {Link} from 'core/react/links'
import {ValidationState} from "framework/mobx-integration"
import {BaseOptionType, DefaultOptionType} from "rc-select/lib/Select"

const {Option, OptGroup} = Select;


export type IdObject = {id: string}
export type AntSelectValue = SelectValue
export type AntSelectRefProps = RefSelectProps
export type AntDefaultOptionType = DefaultOptionType
export const AntTag = Tag


export interface AntSelectPropsInner<VT extends AntSelectValue, OptionType extends BaseOptionType = DefaultOptionType> extends SelectProps<VT, OptionType>{
	sortValues?: boolean,
	sortDataList?: boolean
	nameField?: string,
	valueField?: string,
	groupField?: string,
	dataList?: Array<any>,
	grouping?: boolean,
	valueLink?: Link<VT>,
	errors?: string[],
	invalid?: boolean,
	editable?: boolean,
	nonRemovableMessage?: string,
	onBlur?: () => void
	componentRef?: React.Ref<AntSelectRefProps>
	closeOnMouseLeave?: boolean
	customRenderer?: (item: any) => React.ReactNode
	validationState?: ValidationState
	onLabelEdit?: (value: string, label: string) => void
	width?: number
}

export type AntSelectPropsObject<VT extends AntSelectValue, VTObj extends IdObject = {id: string}, OptionType extends BaseOptionType = DefaultOptionType> = Omit<AntSelectPropsInner<VT, OptionType>, 'value'|'onChange'|'mode'> & {
	valueAsObject: true
	value?: VTObj[]
	mode: 'multiple'
	onChange?: (value: VTObj[], option: OptionType|OptionType[]) => void
	objectFields?: (keyof VTObj)[]
}

export type AntSelectPropsPlain<VT extends AntSelectValue, OptionType extends BaseOptionType = DefaultOptionType> = Omit<AntSelectPropsInner<VT, OptionType>, 'value'|'onChange'|'mode'> & ( AntSelectPropsInner<VT, OptionType> & {
	valueAsObject?: false | null | undefined}
)

export type AntSelectProps<VT extends AntSelectValue, VTObj extends IdObject = {id: string}, OptionType extends BaseOptionType = DefaultOptionType>
	= AntSelectPropsObject<VT, VTObj, OptionType> | AntSelectPropsPlain<VT, OptionType>

export const AntOption = Option;
export const AntOptGroup = OptGroup;
export const AntSelect = observer(<VT extends AntSelectValue, VTObj extends IdObject = {id: string}, OptionType extends BaseOptionType = DefaultOptionType>(props: AntSelectProps<VT, VTObj, OptionType>) => {
	let {valueLink, validationState, ...props0} = props;

	if (valueLink) {
		Object.assign(props0, valueLink.props);
	}

	let props1 = useObjectValue<VT, VTObj, OptionType, typeof props0>(props0)

	props1 = useCustomDefaults(props1)
	props1 = useSortedValues(props1)
	props1 = useEditableTagRender(props1)
	props1 = useCloseOnMouseLeave(props1)
	props1 = useSortedDataList(props1)
	props1 = useGroupedDataList(props1)
	props1 = useCustomWidth(props1)
	props1 = useFixForExtraOptionsProperties(props1)

	let {nameField, valueField, groupField, dataList, customRenderer, invalid, componentRef, ...restProps} = props1
	return (
		<Select {...restProps} ref={componentRef}>
			{props.children}

			{!props.children && !props.grouping && dataList && renderSelectOptions(dataList, {nameField, valueField}, customRenderer)}
			{!props.children && props.grouping && dataList && renderGroupedSelectOptions(dataList)}

		</Select>
	);
});

function useCustomWidth<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsInner<VT, OptionType>, 'width'|'style'>>(props: P) {
	const {width, style, ...rest} = props

	const styleActual = React.useMemo(() => {
		const result = {...(style ?? {})}

		if (width != null) {
			result.width = width + 'px'
		}

		return result
	}, [style, width])

	return {
		style: styleActual,
		...rest
	}
}

//all extra properties on "options" entry a mapped to a div in layout which causes warnings\errors if you provide
//anything but label\value to options. For example if you load Assets list to use as datasource it will be messy.
function useFixForExtraOptionsProperties<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsInner<VT, OptionType>, 'options'>>(props: P) {
	const {options, ...rest} = props

	const optionsActual = React.useMemo(() => {
		if (options == null)
			return null

		return options.map(x => {
			const item: Record<string, any> = {
				label: x.label
			}

			if (x.value !== undefined) {
				item.value = x.value
			}

			if (x.options !== undefined) {
				item.options = x.options
			}

			return item
		}) as unknown as OptionType[]

	}, [options])

	if (options == null)
		return props

	return {
		options: optionsActual,
		...rest
	}
}

function useObjectValue<VT extends AntSelectValue, VTObj extends IdObject, OptionType extends BaseOptionType,
	P extends AntSelectProps<VT, VTObj, OptionType>>(props: P) {

	let fields = ''
	if(props.valueAsObject == true){
		fields = props.objectFields?.join('') ?? ''
	}

	let processedValue = React.useMemo(() => {
		if(props.valueAsObject == true){
			let valueObject = props.value as {id: string}[]
			return valueObject.map(x => x.id) as VT
		}else{
			return props.value
		}
	}, [props.value])

	let onChangeWrapper = React.useCallback((value: VT, option: OptionType | OptionType[]) => {
		if (props.valueAsObject == true) {
			let ids = value as string[]
			let selectedItems = ids
				.map(id => props.dataList.find(x => x.id == id))
				.filter(x => x)
				.map(item => {
					if (props.objectFields?.length > 0) {
						let result = {} as Record<keyof VTObj, any>
						props.objectFields.forEach(field => {
							result[field] = item[field]
						})
						return result
					} else {
						return item
					}
				})
			props.onChange(selectedItems, option)
		} else {
			props.onChange(value, option)
		}
	}, [fields, props.onChange])

	let propsCopy = {...props}
	if(propsCopy.valueAsObject == true){
		delete propsCopy.objectFields
	}

	let {value, valueAsObject, onChange, ...rest} = propsCopy

	return {
		value: processedValue,
		onChange: onChangeWrapper,
		...rest
	}
}

function useCustomDefaults<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsInner<VT, OptionType>, 'mode'|'dataList'|'defaultValue'>>(props: P){

	return {
		allowClear: props.mode == 'multiple' || props.mode == "tags",
		optionFilterProp: props.dataList != null ?  'children' : null,
		showSearch: true,
		nameField: 'name',
		valueField: 'id',
		groupField: 'type',
		...props,
		defaultValue: props.defaultValue === null ? undefined : props.defaultValue
	}
}
function useCloseOnMouseLeave<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsInner<VT, OptionType>, 'closeOnMouseLeave'|'onMouseEnter'|'onMouseLeave'|'onDropdownVisibleChange'>>(props: P)
{
	const {onMouseEnter, onMouseLeave, closeOnMouseLeave, onDropdownVisibleChange, ...rest} = props;

	const mouseEnteredDropdownRef = React.useRef(false)
	const mouseLeftTimeoutRef = React.useRef(null)
	const [open, setOpen] = React.useState(false)

	const onMouseEnterWrapper = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
		if(closeOnMouseLeave === true) {
			const target = e.target as HTMLElement
			if (target.closest('.ant-select-dropdown')) {
				mouseEnteredDropdownRef.current = true
				if (mouseLeftTimeoutRef.current) {
					clearTimeout(mouseLeftTimeoutRef.current)
					mouseLeftTimeoutRef.current = null
				}
			}
		}

		onMouseEnter?.(e)
	}, [onMouseEnter])

	const onMouseLeaveWrapper = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
		if(closeOnMouseLeave === true) {
			if (mouseLeftTimeoutRef.current == null) {
				mouseEnteredDropdownRef.current = false
				mouseLeftTimeoutRef.current = setTimeout(() => {
					setOpen(false)
					mouseLeftTimeoutRef.current = null
				}, 300)
			}
		}
		onMouseLeave?.(e)
	}, [onMouseLeave])

	const onDropdownVisibleChangeWrapper = React.useCallback((open: boolean) => {
		setOpen(open)
		onDropdownVisibleChange?.(open)
	}, [closeOnMouseLeave])

	return {
		onMouseEnter: onMouseEnterWrapper,
		onMouseLeave: onMouseLeaveWrapper,
		onDropdownVisibleChange: onDropdownVisibleChangeWrapper,
		open: closeOnMouseLeave === true ? open : undefined,
		...rest
	}
}

type AntGroupEntry = {
	name: string
	items: {
		name: string,
		id: string
	}[]
}

function useGroupedDataList<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsInner<VT, OptionType>, 'nameField'|'valueField'|'groupField'|'grouping'|'dataList'>>(props: P){

	let {grouping, dataList, ...rest} = props

	let groupedDataList = React.useMemo(() => {
		if(!grouping || !dataList)
			return dataList

		let groups: string[] = [];
		let groupedItems: AntGroupEntry[] = [];
		dataList.forEach(x => {
			if (groups.indexOf(x[props.groupField]) === -1) {
				groups.push(x[props.groupField]);
				groupedItems.push({
					name: x[props.groupField],
					items: [{
						name: x[props.nameField],
						id: x[props.valueField]
					}]
				})
			} else {
				groupedItems.map(y => {
					if (y.name === x[props.groupField]) {
						y.items.push({
							name: x[props.nameField],
							id: x[props.valueField]
						});
					}
				})
			}
		});
	}, [dataList, props.nameField, props.valueField, props.groupField, grouping])

	return {
		dataList: groupedDataList,
		...rest
	}
}

function useEditableTagRender<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsInner<VT, OptionType>,
		'mode'|'tagRender'|'options'|'nonRemovableMessage'|'editable'|'onLabelEdit'>>(props: P){

	let {tagRender, nonRemovableMessage, editable, onLabelEdit, ...rest} = props

	const tagRenderForEditableTags = React.useCallback((item) => {
		let element = props.options.find(element => element.value === item.value) as AntSelectEditableTagOptionsEntry<VT>;
		return (
			<EditableTag {...item}
			             editable={props.editable !== false && (element?.editable ?? false) && props.onLabelEdit}
			             removable={(element ? element.removable : true)}
			             tooltip={element ? element.tooltip : ''}
			             nonRemovableMessage={nonRemovableMessage}
			             onLabelEdit={props.onLabelEdit}
			/>
		);

	}, [props.options]);

	return {
		tagRender: props.mode == 'tags' && tagRender == null ? tagRenderForEditableTags : tagRender,
		...rest
	}
}

function useSortedDataList<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsInner<VT, OptionType>, 'sortDataList'|'dataList'|'nameField'>>(props: P){

	let {sortDataList, dataList, ...rest} = props

	let sortedDataList = useMemo(() => {
		if(!sortDataList || !dataList)
			return dataList

		const dataListCopy = JSON.parse(JSON.stringify(dataList));
		dataListCopy.sort((a: any, b: any) =>
			a[props.nameField]?.toString().localeCompare(b[props.nameField]?.toString(), undefined, {sensitivity: 'accent'})
		);
		return dataListCopy;
	}, [sortDataList, dataList, props.nameField])

	return {
		dataList: sortedDataList,
		...rest
	}
}

//does not work in uncontrolled mode
function useSortedValues<
	VT extends AntSelectValue,
	OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsInner<VT, OptionType>, 'mode'|'dataList'|'nameField'|'valueField'|'value'|'sortValues'>>(
	props: P
){
	let {value, sortValues, ...rest} = props
	let sortedValue = React.useMemo(() => {
		if(!props.value){
			return props.value
		}

		if (!props.dataList || !props.sortValues || (props.mode != "tags" && props.mode != "multiple") ) {
			return value
		}

		let values = props.value as VT[]
		if(!values){
			values = []
		}

		//we need to sort selected values by names and not by values
		//so we are looking for names in children collection
		let selectedObjects = values.map(value => {
			let object = props.dataList.find(node => node[props.valueField] == value);
			return object == null ?
				{[props.valueField]: value}
				: object;
		});

		selectedObjects.sort((a,b) =>
			a[props.nameField]?.toString().localeCompare(b[props.nameField]?.toString(), undefined, {sensitivity: 'accent'})
		);

		return selectedObjects.map( x => x[props.valueField]);
	}, [props.value, props.dataList, props.nameField, props.value, props.sortValues])

	return {
		value: sortedValue as VT,
		...rest
	}
}

type AntSelectEditableTagProps<VT extends AntSelectValue> = TagProps & {
	value: VT
	label: string
	editable?: boolean
	removable?: boolean
	nonRemovableMessage?: string
	tooltip?: string
	onLabelEdit?: (value: VT, newLabel: string) => void
}

export type AntSelectEditableTagOptionsEntry<VT extends AntSelectValue>
	= Pick<AntSelectEditableTagProps<VT>, 'value'|'label'|'editable'|'removable'|'tooltip'>


export const EditableTag = observer(<VT extends AntSelectValue>(props: AntSelectEditableTagProps<VT>) => {
	const [editMode, setEditMode] = useState(false)

	const setValue = useCallback(e => {
		setEditMode(false);
		props.onLabelEdit?.(props.value, e.target.value.trim())
	}, []);

	const preventPropagationToParent = useCallback(e => {
		//ant select control removes tag on backspace
		//enter forces all tags list to be opened, we dont need it
		if (e.keyCode == 8 || e.keyCode == 13) { //backspace or enter
			e.stopPropagation();
		}
	}, []);

	let tooltip = props.tooltip
		? props.tooltip
		: props.removable
			? ''
			: props.nonRemovableMessage

	const defaultValue = Array.isArray(props.label) ? props.label.filter(x => !!x) : props.label

	return (
		<div onClick={e => e.stopPropagation()}>
			{!editMode && <Tag onClose={props.onClose} closable={props.removable !== undefined ? props.removable : true}>
				<Tooltip title={tooltip}
				         zIndex={10010}>{props.label}</Tooltip>
				{props.editable && <EditOutlined onClick={() => {
					setEditMode(true)
				}}/>}
			</Tag>}
			{editMode && <Input size="small"
			                    defaultValue={defaultValue}
			                    allowClear
			                    autoFocus={true}
			                    onKeyDown={preventPropagationToParent}
			                    onBlur={setValue}
			                    onPressEnter={setValue}
			                    onMouseDown={e => e.stopPropagation()} //changing position in input by clicking would not work without that
			                    style={{width: '100px'}}/>}
		</div>
	);
})

export const textValueFields = Object.freeze({
	nameField: 'text',
	valueField: 'value',
	groupField: 'type'
});

export function renderGroupedSelectOptions(list: AntGroupEntry[]) {
	const {OptGroup, Option} = Select;
	return list.map(x => <OptGroup label={x.name} key={x.name}>
		{x.items.map(y => <Option value={y.id} key={y.id}>{y.name}</Option>)}
	</OptGroup>)
}

export function fromIdName<VT, T extends {id: VT, name: string}>(items: T[]){
	return items.map(x => {
		let result: (T & {value?: VT, label?: string}) = {...x}
		result.value = x.id
		result.label = x.name

		delete result.id
		delete result.name

		return result as (Omit<T, 'id'|'name'> & {value: VT, label: string})
	})
}
