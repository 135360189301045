import './actionButtons.less'

import React from 'react';

import {translator} from "core/localization";
import {Button} from 'antd';
import {observer} from 'mobx-react';
import {ModelValidator, ValidationState} from "framework/mobx-integration";


let i = translator({});
let b = require('b_').with('form-action-buttons');

export interface ActionButtonsProps{
	mode?: string;
	update?: boolean;

	actionButtonLabel?: string;

	className?: string;

	onSave: string | (() => void);
	onCancel: string | (() => void);
	saveDisabled?: boolean;
	showSave?: boolean;
	disabled?: boolean;
	validator?: ModelValidator<any>
}

export const ActionButtons = observer( (props:ActionButtonsProps) => {
	const showSave = props.showSave ?? true;

	let saveDisabled = props.saveDisabled ?? false;
	if(props.validator){
		saveDisabled = props.validator.validationState != ValidationState.Valid
	}

	let mode = props.mode || 'create';
	if (props.update === true) {
		mode = 'update';
	}

	let className = props.className ?? '';

	return (
		<div className={b(className)}>
			{showSave && <Button className={b('button')}
			                           type={"primary"}
			                           disabled={saveDisabled || props.disabled}
			                           onClick={typeof props.onSave == "function" ? props.onSave : null}
			                           href={typeof props.onSave == "string" ? props.onSave : null}
			>{props.actionButtonLabel || i(mode, {capitalize: true})}</Button>
			}
			<Button className={b('button')}
			        onClick={typeof props.onCancel == "function" ? props.onCancel : null}
			        href={typeof props.onCancel == "string" ? props.onCancel : null}
			        disabled={props.disabled}
			>{i('Cancel')}</Button>
		</div>
	);
});
