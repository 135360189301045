import './coordinatesEditor.less';
import {observer} from "mobx-react";
import React from "react";
import {EnvironmentFilled} from "@ant-design/icons";
import {AntModal} from "controls/react/ant/antModal";
import {IReactionDisposer, makeObservable, observable} from "mobx";
import {translator} from "core/localization";
import {Section, Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import {AntInput} from '../../controls/react/ant/antInput';
import {FormEntry} from 'controls/react/form';
import {AntButton} from 'controls/react/ant/antButton';
import {Geotag} from "framework/entities/geotag";
import {OlMap, OlMapOverlay} from './olMap';
import {MobxManager} from 'framework/mobx-integration';
import {AntPopover} from 'controls/react/ant/antPopover';
import {Resizable} from 're-resizable';
import {Direction} from 're-resizable/lib/resizer';

const b = require('b_').with('coordinates-editor');
const i = translator({
	'Lat:': {no: 'Lat:'},
	'Long:': {no: 'Long:'},
	'Click to set Geo Tag': {no: 'Klikk for å sette Geo Tagg'}
});

const initialHeight = 542;
const initialWidth = 670;
const defaultLatitude = 59.911491;
const defaultLongitude = 10.757933;

export let CoordinatesEditorContext = React.createContext<CoordinatesEditorStore>(null);
export class CoordinatesEditorStore {
	props: CoordinatesEditorProps;
	reactions: IReactionDisposer[] = [];
	opened: boolean;
	longitude: string;
	latitude: string;
	position: [number | undefined, number | undefined];
	center: Geotag;
	size: {width: number, height: number} = {width: initialWidth, height: initialHeight};
	fixedSize: {width: number, height: number};
	private mobx = new MobxManager();

	constructor(props: CoordinatesEditorProps) {
		this.props = props;
		makeObservable(this, {
			opened: observable,
			latitude: observable,
			longitude: observable,
			position: observable,
			overlays: observable,
			size: observable,
			center: observable
		});
		let long = this.props.coordinates?.longitude ?? defaultLongitude;
		let lat = this.props.coordinates?.latitude ?? defaultLatitude;
		if (long == 0) {
			long = defaultLongitude;
		}
		if (lat == 0) {
			lat = defaultLatitude;
		}
		this.latitude = lat.toString();
		this.longitude = long.toString();
		this.mobx.reaction(() => this.position, () => {
			this.latitude = this.position[0]?.toString() ?? '';
			this.longitude = this.position[1]?.toString() ?? '';
			if (this.position[0] && this.position[1]) {
				this.addOrUpdatePoint({
					latitude: this.position[0],
					longitude: this.position[1]
				} as Geotag);
				this.center = {
					latitude: this.position[0],
					longitude: this.position[1]
				} as Geotag;
			}
		});
		this.position = [lat, long];
		this.fixedSize = {...this.size};
	}

	onOk = () => {
		if (!this.position.length) {
			return;
		}
		if (!this.position[0] || !this.position[1]) {
			this.position = [undefined, undefined];
		}
		this.props.onChanged?.({
			latitude: this.position[0],
			longitude: this.position[1]
		} as Geotag);
		this.closeMap();
	}

	onCancel = () => {
		this.closeMap();
	}

	onLatitudeChanged = (value: string) => {
		this.latitude = value;
	}

	onLongitudeChanged = (value: string) => {
		this.longitude = value;
	}

	updatePosition = () => {
		const lat = Number.isNaN(parseFloat(this.latitude))
			? undefined
			: parseFloat(this.latitude);

		const long = Number.isNaN(parseFloat(this.longitude))
			? undefined
			: parseFloat(this.longitude);

		this.position = [lat, long];
	}

	openMap = () => {
		this.opened = true;
	}

	closeMap = () => {
		this.opened = false;
	}

	destroy() {
		this.mobx.destroy();
	}

	overlays: OlMapOverlay[] = [];

	onMapClick = (ev: Geotag) => {
		this.position = [ev.latitude, ev.longitude];
	}

	addOrUpdatePoint = (ev: Geotag) => {
		if (this.overlays.length) {
			this.overlays[0].updatePosition(ev);
		} else {
			const point = new OlMapOverlay(ev);
			this.overlays = [...this.overlays, point];
		}
	}

	onSizeChanged = (event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement, delta: {width: number, height: number}) => {
		this.size = {width: this.fixedSize.width + delta.width, height: this.fixedSize.height + delta.height};
	}

	fixSize = (event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement, delta: {width: number, height: number}) => {
		this.size = {width: this.fixedSize.width + delta.width, height: this.fixedSize.height + delta.height};
		this.fixedSize = {...this.size};
	}
}

export class CoordinatesEditorProps {
	coordinates: Geotag;
	onChanged: (point: Geotag) => void;
}

export const CoordinatesEditor = observer(class CoordinatesEditorInner extends React.PureComponent<CoordinatesEditorProps> {
	store: CoordinatesEditorStore;

	constructor(props: CoordinatesEditorProps) {
		super(props);
		this.store = new CoordinatesEditorStore(props);
	}

	componentDidUpdate(prevProps: Readonly<CoordinatesEditorProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if (this.props.coordinates && JSON.stringify(prevProps.coordinates) != JSON.stringify(this.props.coordinates)) {
			this.store.position = [this.props.coordinates.latitude, this.props.coordinates.longitude];
		}
	}

	componentWillUnmount() {
		this.store.destroy()
	}

	render() {
		return <CoordinatesEditorContext.Provider value={this.store}>
			<AntPopover content={<Section>{i('Click to set Geo Tag')}</Section>}>
				<EnvironmentFilled className={b('button')} onClick={this.store.openMap} />
			</AntPopover>
			<AntModal okText={i('Ok')}
					  title={<></>}
					  visible={this.store.opened}
					  closable={false}
					  destroyOnClose={true}
					  centered
					  width={this.store.size.width}
					  footer={false}
					  className={b('modal')}>
						<Resizable onResize={this.store.onSizeChanged}
								   onResizeStop={this.store.fixSize}
								   defaultSize={this.store.size}
								   minWidth={initialWidth}
								   minHeight={initialHeight}>
							<Section className={b('modal-content')} childrenPadding={true} contentPadding={true} appearance="none">
								<OlMap onClick={this.store.onMapClick} overlays={this.store.overlays} size={this.store.size} center={this.store.center} />
							</Section>
							<CoordinatesEditorFooter />
						</Resizable>
			</AntModal>
		</CoordinatesEditorContext.Provider>
	}
})

const CoordinatesEditorFooter = observer(() => {
	const context = React.useContext(CoordinatesEditorContext);
	return <Toolbar appearance="transparent" containerClass={b('modal-footer')}>
				<FormEntry position={ToolbarItemPosition.BEFORE_TITLE} label={i('Lat:')}>
					<AntInput className={b('coordinate')} value={context.latitude} onChange={context.onLatitudeChanged} onBlur={context.updatePosition} />
				</FormEntry>
				<FormEntry position={ToolbarItemPosition.BEFORE_TITLE} label={i('Long:')}>
					<AntInput className={b('coordinate')} value={context.longitude} onChange={context.onLongitudeChanged} onBlur={context.updatePosition} />
				</FormEntry>
				<FormEntry position={ToolbarItemPosition.AT_THE_END}>
					<AntButton type={"primary"} onClick={context.onOk}>{i('Update')}</AntButton>
				</FormEntry>
				<FormEntry position={ToolbarItemPosition.AT_THE_END}>
					<AntButton onClick={context.onCancel}>{i('Cancel')}</AntButton>
				</FormEntry>
			</Toolbar>
})
