import React from "react";
import ReactDOM from 'react-dom';
import {translator} from "core";
import {Button, TextBox, Grid, FormEntry, ActionButtons} from "controls/react/form";
import {Window, Section, Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import Settings from 'settings';
import MultiToggle from 'controls/multiToggle';
import Utils from "tools/utils";
import Cookies from 'core/cookies';
import Api from 'tools/api';
import WidgetInfoContainer from "controls/react/widgetInfoContainer";
import {MetricLegendContainer} from './metricLegendContainer'
import RemoteEventsManager from 'core/remoteEventsManager';
import {ApplicationState} from 'framework/applicationState';

import './metricTableDataWindow.less'
import { extractQualifierSubscriptionFields } from "areas/service-boards/widgets/customMetrics/metricSelector";

let i = translator({
  "Metrics data": {
    "no": "Metrikk data",
    "en": "Metrics Data"
  }
});

export class MetricTableDataWindow extends React.PureComponent {
	gridDataSource;
	constructor(props) {
		super(props);
		this.state = {
			gridShown: true
		};
		this.metricId = this.props.metricId;
		this.windowActions = this.props.actions || ['Close']
	}

	render() {
		let content;
		if (this.props.metricId && this.state.gridShown) {
			content = <>
			<Grid dataSource={this.gridDataSource}
				  columns={this.getGridColumns()}
				  height={this.getContentHeight() - 40}
				  scrollable={{virtual: true}}
				  serverSorting={true}
				  serverPaging={true}
				  serverFiltering={true}
				  skipSelectorColumn={true}
				  noRecords={{template: i('No data')}}
				  ref={grid => this.grid = grid}>
			</Grid>
			<MetricLegendContainer data={this.state.legendData}/>
			</>
		} else {
			content = <div className="no-data-placeholder"><span>{i('No data')}</span></div>;
		}

		if(this.props.window != true)
			return content;

		if (this.props.context) {
			let openedTableWindows = this.props.context.openedTableDataWindowIds || [];
			let index = openedTableWindows.indexOf(this.metricId);
			this.isWindowOpened = index !== -1 ? true : false;
		} else {
			this.isWindowOpened = true;
		}

		let windowDimensions = this.getWindowDimensions();

		return (
			<div>
				{this.isWindowOpened && <Window height={windowDimensions.windowHeight}
						width={windowDimensions.windowWidth}
						title={this.props.title}
						top={windowDimensions.windowTop}
						left={windowDimensions.windowLeft}
						minHeight={100}
						minWidth={300}
						actions={this.windowActions}
						ref={window => this.window = window}
						onResize={() => this.resizeTableDataWindow()}
						onClose={() => this.hideTableDataWindow()}>
					{content}
				</Window>}
			</div>
		)
	}

	async componentDidMount() {
		this.gridDataSource = this.getGridDataSource();
		if (this.metricId) {
			await this.renderInfoContainer();
			this.renderTimeSelector();
			this.renderInfoSign();
		}
		if(this.window) {
			let htmlClass = 'table' + this.metricId;
			this.window.window.wrapper.addClass(htmlClass);
			if (this.props.cacheWindow) {
				this.props.cacheWindow(this.window.window, this.props.metricId);
			}
		}
		this.subscribe();
	}

	componentDidUpdate() {
		if (this.isWindowOpened) {
			this.renderTimeSelector();
			this.renderInfoSign();
		}
	}

	getWindowDimensions() {
		let windowWidth = this.props.width || 600;
		let windowHeight = this.getContentHeight() + 30;
		let windowTop = this.props.coordinates?.top || 150;
		let windowLeft = this.props.coordinates?.left || 350;

		if (!this.props.skipDimensionsAdjust) {
			let maxLeft = 0;
			let maxTop = 0;
			$('.k-widget.k-window').each(function() {
				let currentTop = $(this).offset().top;
				let currentLeft = $(this).offset().left;
				if (currentTop >= maxTop) {
					maxTop = currentTop;
					maxLeft = currentLeft;
					if (currentLeft > maxLeft) {
						maxLeft = currentLeft;
					}
				}
			});

			if (maxLeft && maxTop) {
				windowTop = maxTop;
				windowLeft = maxLeft + windowWidth;
				if (windowLeft + windowWidth > window.innerWidth) {
					windowLeft = 350;
					windowTop = maxTop + windowHeight;
					if (windowTop + windowHeight > window.innerHeight) {
						windowTop = window.innerHeight - windowHeight;
					}
				}
			}
		}

		return {
			windowTop,
			windowLeft,
			windowHeight,
			windowWidth
		}
	}

	getContentHeight(){
		return this.props.height || 220;
	}

	getGridDataSource() {
		let periodTranslationObj = {
			'LASTHOUR': 'LASTDAY',
			'LASTDAY': 'LAST7DAYS',
			'LAST7DAYS': 'LAST30DAYS'
		};

		let currentTimeSelector = this.props.defaultTimeSelectorValue ?? this.props.viewerContext.defaultWindowsTimeSelector ?? 'LASTHOUR';
		this.dataSourceUrl = Settings.serverPath + 'sessions/' + ApplicationState.apiToken + '/metrics/' + this.metricId;
		if (currentTimeSelector == 'CUSTOM' && (this.props.defaultTimeSelectorDatesValue ?? this.props.viewerContext.defaultTimeSelectorDatesValue)) {
			const {from, to} = this.props.defaultTimeSelectorDatesValue ?? this.props.viewerContext.defaultTimeSelectorDatesValue;
			this.dataSourceUrl +='?fromTime=' + from + '&toTime=' + to;
		} else {
			this.dataSourceUrl += '?timeSelector=' + currentTimeSelector;
		}
		const oThis = this;
		return new kendo.ceeview.DataSource({
			transport: {
				read: (options) => {
					$.ajax({
						url: this.dataSourceUrl,
						contentType: "application/json; charset=utf-8",
						type: "POST",
						dataType: "json",
						cache: false,
						data: kendo.stringify({}),
						processData: false,
						success(result) {
							oThis.setState({unitType: result.unitType})
							oThis.subscribe()
							options.success(result);
						},
						error: () => {
							this.setState({
								gridShown: false
							});
						}
					});
				},
			},
			pageSize: 1000,
			schema: {
				data: $.proxy(function(response) {
					if (response.items && !response.items.length && !this.doNotTranslatePeriod) {
						let newTimeSelector = periodTranslationObj[this.currentTimeSelector];
						if (newTimeSelector) {
							if (this.timeMultiToggle) {
								this.timeMultiToggle.value(newTimeSelector);
							}
							if (this.periodMultiToggle) {
								this.periodMultiToggle.setSelectedItem(newTimeSelector);
							}
							this.dataSourceUrl = Settings.serverPath + 'sessions/' + ApplicationState.apiToken + '/metrics/' + this.metricId + '?timeSelector=' + newTimeSelector;
							this.gridDataSource.read();
						}
					}
					return response.items || [];
				}, this),
				total: function (response) {
					this.visibleItems = response.visible;
					this.totalItems = response.total;
					return response.visible;
				}
			},
			sort: {
				field: 't',
				dir: 'desc'
			}
		});
	}

	getGridColumns() {
		return [{
			field: 't',
			title: lang.TIME,
			template: '${Renderer.browserDateRenderer(t, "datetime")}',
			width: 190
		}, {
			field: 'v',
			title: lang.VALUE,
			template: function (data) {
				if (data.err) {
					if (data.err[0] !== 0) {
						return '<span class="cw_label cw_label_error">' + lang.ERROR + '</span>';
					}
				}
				if (data.e) {
					if (data.e[0] !== 0) {
						return '<span class="cw_label cw_label_error">' + lang.ERROR + '</span>';
					}
				}
				if (data.v === null) {
					return lang.NA;
				} else {
					return data.v + data.u;
				}
			},
			width: 100
		}];
	}

	async renderInfoContainer() {
		let url = `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/metrics/registeredMetrics`;
		let metrics = [this.metricId];
		let result = await Api.fetchPost(url, metrics);
		let infoData = result[0];
		this.hoverInfoData = infoData;

		this.setState({
			legendData: infoData
		})

	}

	renderTimeSelector() {
		this.target = this.props.timeSelectorContainer == null
			? this.window.window.wrapper
			: $(this.props.timeSelectorContainer)

		var currentSelector;
		if (this.target.find('.period_multi_toggle').length) {
			currentSelector = this.target.find('.period_multi_toggle');
		} else if (this.target.find('#period_multi_toggle').length) {
			currentSelector = this.target.find('#period_multi_toggle');
		}
		if (currentSelector) {
			currentSelector.remove();
		}

		this.target.append('<div class="period_multi_toggle"></div>');
		let toggleContainer = this.target.find('.period_multi_toggle');

		toggleContainer.addClass('cw_table_info_multitoggle_time_selector').css('right', this.windowActions.length * 30 + 'px');
		toggleContainer.attr('data-qualifierid', this.metricId);
		let cssClass = 'cw_multi_toggle small right';
		this.periodMultiToggle = new MultiToggle({
			selector: this.target.find('.period_multi_toggle'),
			type: 'label',
			cssClass: cssClass,
			value: 'LASTHOUR',
			items: [{
				id: 'cw_hour',
				title: lang.periodMultiToggle.HOUR,
				label: lang.periodMultiToggle.HOUR,
				fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
				scope: this,
				value: 'LASTHOUR'
			}, {
				id: 'cw_day',
				title: lang.periodMultiToggle.DAY,
				label: lang.periodMultiToggle.DAY,
				fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
				scope: this,
				value: 'LASTDAY'
			}, {
				id: 'cw_week',
				title: lang.periodMultiToggle.WEEK,
				label: lang.periodMultiToggle.WEEK,
				fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
				scope: this,
				value: 'LAST7DAYS'
			}, {
				id: 'cw_month',
				title: lang.periodMultiToggle.MONTH,
				label: lang.periodMultiToggle.MONTH,
				fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
				scope: this,
				value: 'LAST30DAYS'
			}, {
				id: 'cw_custom',
				title: lang.CUSTOM,
				label: lang.CUSTOM,
				fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
				scope: this,
				value: 'CUSTOM'
			}]
		});
		toggleContainer.find('li').css({
			'height': '22px',
			'padding-top': '4px'
		});
		this.periodMultiToggle.setSelectedItem(this.props.defaultTimeSelectorValue ?? this.props.viewerContext.defaultWindowsTimeSelector ?? 'LASTHOUR');
	}

	renderInfoSign() {
		this.target.append('<span class="cw_widget_info_sign glyphicons info-sign"></span>');
		this.target.find('.cw_widget_info_sign').on('mouseover', (e) => this.onInfoSignHover(e));
		this.target.find('.cw_widget_info_sign').on('mouseout', this.onInfoSignHoverOut);
		this.truncateWindowTitle();
	}

	onInfoSignHover(e) {
		let coordinates = e.target.getBoundingClientRect();
		ReactDOM.render(<WidgetInfoContainer
			data={this.hoverInfoData}
			type={'preloaded'}
			coordinates={coordinates}
		/>, e.target);
	}

	truncateWindowTitle() {
		if(!this.props.window)
			return;

		let timeSelector = this.target.find('.period_multi_toggle');
		let timeSelectorPosition = timeSelector.position().left;
		let titleContainer = this.target.find('.k-window-title');
		titleContainer.css('width', timeSelectorPosition - 30);
		this.target.find('.cw_widget_info_sign').css('left', timeSelectorPosition - 15);
	}

	onInfoSignHoverOut(e) {
		this.hoverInfoContainerTarget = e.target;
		let destroyContainer = setInterval(() => ReactDOM.unmountComponentAtNode(e.target), 200);
		setTimeout(() => clearInterval(destroyContainer), 400);
	}

	onPeriodMultiToggleClick(value, target) {
		this.doNotTranslatePeriod = true;
		if (value === 'CUSTOM') {
			if (this.target.find('.cw_custom_time_selector').length) {
				this.target.find('.cw_custom_time_selector').removeClass('hide');
				this.endTimePicker?.value(new Date());
			} else {
				var html = '';
				html += '<div class="cw_custom_time_selector" style="top: 30px;">';
				html += '<div class="cw_time_input_container">';
				html += '<div>' + lang.FROM + '</div>';
				html += '<input class="cw_start_time cw_time_input"/>';
				html += '</div>';
				html += '<div class="cw_time_input_container">';
				html += '<div>' + lang.TO + '</div>';
				html += '<input class="cw_end_time cw_time_input"/>';
				html += '</div>';
				html += '<button disabled class="k-button k-primary right" id="cw_apply_custom_time">' + lang.APPLY + '</button>';
				html += '</div>';
				this.target.append(html);
				this.customTimeSelectorContainer = this.target.find('.cw_custom_time_selector');
				this.startTimePicker = this.target.find(".cw_start_time").kendoDateTimePicker({
					format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
					timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
					change: $.proxy(function() {
						let startValue = this.startTimePicker.value();
						let endValue = this.endTimePicker.value();
						if (endValue) {
							this.target.find('#cw_apply_custom_time').attr('disabled', false);
							if (endValue < startValue) {
								this.endTimePicker.value(startValue);
							}
						}
						this.endTimePicker.min(startValue);
					}, this)
				}).data('kendoDateTimePicker');
				this.endTimePicker = this.target.find('.cw_end_time').kendoDateTimePicker({
					format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
					timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
					//max: new Date(),
					value: new Date(),
					change: $.proxy(function() {
						if (this.startTimePicker.value()) {
							this.target.find('#cw_apply_custom_time').attr('disabled', false);
						}
					}, this)
				}).data('kendoDateTimePicker');

				this.target.find('#cw_apply_custom_time').on('click', $.proxy(this.onApplyCustomTime, this));
			}
		} else {
			if (this.startTimePicker && this.endTimePicker) {
				this.resetCustomTimePickers();
			}
			this.dataSourceUrl = Settings.serverPath + 'sessions/' + ApplicationState.apiToken + '/metrics/' + this.metricId + '?timeSelector=' + value;
			this.gridDataSource.dataSourceUrl = this.dataSourceUrl;
			this.gridDataSource.read();
			this.target.find('.cw_custom_time_selector').addClass('hide');
			this.props.viewerContext.defaultWindowsTimeSelector = value;

			if (this.props.onWidgetPeriodChanged) {
				this.props.onWidgetPeriodChanged(this.props.viewerContext, value, target);
			}
		}
	}

	onApplyCustomTime() {
		let fromTime = this.startTimePicker.value().getTime();
		let toTime = this.endTimePicker.value().getTime();
		this.dataSourceUrl = Settings.serverPath + 'sessions/' + ApplicationState.apiToken + '/metrics/' + this.metricId + '?fromTime=' + fromTime + '&toTime=' + toTime;
		this.gridDataSource.read();
		this.target.find('.cw_custom_time_selector').addClass('hide');
	}

	resetCustomTimePickers() {
		this.startTimePicker.value('');
		this.startTimePicker.min(new Date(1900, 0, 1));
		this.startTimePicker.max(new Date(2100, 0, 1));
		this.endTimePicker.value('');
		this.endTimePicker.min(new Date(1900, 0, 1));
		this.endTimePicker.max(new Date(2100, 0, 1));
	}

	hideTableDataWindow () {
		this.doNotTranslatePeriod = false;
		if (this.props.hideWindow) {
			this.window.window.wrapper.find('.k-grid').remove();
			this.props.hideWindow(this.metricId);
		}
		if (this.props.destroyWindow) {
			this.props.destroyWindow(this.props.containerId);
		}
		if (this.props.unsubscribeWindow) {
			this.props.unsubscribeWindow();
		}
		if (this.props.context) {
			this.props.context.hideTableDataWindow(this.props.metricData);
		}
	}

	resizeTableDataWindow() {
		let windowContainer = this.target.find('.k-window-content');
		let windowHeight = windowContainer.height();
		let gridContainer = this.target.find('.k-grid');
		let grid = gridContainer.data('kendoCustomGrid');
		grid.setOptions({
			height: windowHeight - 40
		});
		this.truncateWindowTitle();
	}

	subscribe() {
		if(!this.state.legendData || ! this.state.unitType)
			return

		let subscriptionObj = [{
			eventType: 'Metric',
			releaseEvents: true,
			qualifierId: this.metricId,
			...extractQualifierSubscriptionFields({...this.state.legendData,...{unitType: this.state.unitType}})
		}];
		this.subscription = RemoteEventsManager.subscribeCallback(subscriptionObj, _.throttle(this.onEvents, 2000));
	}

	onEvents = events => {
		if(!events?.metric)
			return;
		this.grid?.kendoGrid.dataSource.insert(0, events.metric);
	}

	onEventsTimeout() {
		this.refreshGridOnEvent();
	}

	refreshGridOnEvent() {
		this.grid?.kendoGrid.dataSource.read();
	}

	destroy () {
		this.startTimePicker?.destroy();
		this.endTimePicker?.destroy();
		this.customTimeSelectorContainer?.remove();
	}
}
