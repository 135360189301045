import React, {FunctionComponent, useState, useCallback, useEffect} from 'react';
import {Select, SelectProps} from 'antd';
import Api from 'tools/api';
import Settings from "settings";
import { EditableTag } from './ant/antSelect';
import {ApplicationState} from "framework/applicationState";
import {styleForThingsOnModal} from "controls/react/ant/zIndexes";

const {Option} = Select;

const i = require('core/localization').translator({
	'There are no tags...': {
		no: 'Velg etikett...'
	},
	'Choose a tag...': {
		no: 'Etikett er satt...'
	}
});

interface TagsSelectProps extends Omit<SelectProps, 'defaultValue'>{
	accountId?: string,
	includeSubaccounts?: boolean,
	value?: any,
	onChange?: any
	invalid?: boolean
	defaultValue?:  Array<any>
	items?: {id: string, name: string}[]
}

interface TagsSelectState {
	existingTags: Array<any>;
	selectedValue: Array<any>
}


export class TagsSelect<VT extends any> extends React.Component<TagsSelectProps, TagsSelectState>{
	constructor(props: TagsSelectProps) {
		super(props);

		this.state = {
			existingTags: [],
			selectedValue: props.defaultValue
		}
	}

	render(){
		const {onChange, accountId, includeSubaccounts, disabled, invalid, ...others} = this.props;
		const tagRender = (item: any) => {
			let element = this.state.existingTags.find(element => element.id === item.value);
				return (
					<EditableTag {...item}
								 editable={false}
								 removable={element ? element.removable : true}
								 tooltip={element ? element.tooltip : ''}
					/>
				);

		};
		return (
			<Select value={this.props.value || this.state.selectedValue}
			        mode={'tags'}
					onChange={this.onChange}
					disabled={disabled}
			        placeholder={this.state.existingTags.length ? i('Choose a tag...') : i('There are no tags...')}
			        {...others}
					allowClear
			        dropdownStyle={styleForThingsOnModal}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					autoComplete="new-state"
					tagRender={tagRender}>
				{this.state.existingTags.map( tag =>
					<Option key={tag.id} title={tag.name}>
						{tag.name}
					</Option>
				)}
			</Select>
		);
	}

	onChange = (newValue: string[]) => {
		const cleanedNewValue = newValue.filter(x => x.trim());

		this.setState({
			selectedValue: cleanedNewValue
		});
		this.props.onChange(cleanedNewValue);
	}

	componentDidUpdate(prevProps: TagsSelectProps) {
		if(prevProps.defaultValue != this.props.defaultValue) {
			this.setState({selectedValue: this.props.defaultValue});
		}
	}

	async componentDidMount() {
		let tagsList: {id: string, name: string}[] = [];
		if (this.props.items?.length) {
			tagsList = this.props.items;
		} else {
			const url = `${Settings.serverPath}accounts/${this.props.accountId ?? ApplicationState.accountId}/tags?includeSubaccounts=${this.props.includeSubaccounts ?? false}`;

			let result = await Api.fetch(url);
			if (!result.success)
				return;

			for (let tag of result.data) {
				tagsList.push({
					id: tag,
					name: tag
				});
			}
		}

		tagsList.sort((a,b) => {
			const lvalue = a.name?.toLowerCase();
			const rvalue = b.name?.toLowerCase();
			if(lvalue > rvalue)
				return 1;
			if(lvalue < rvalue)
				return -1;
			return 0;
		});

		this.setState({existingTags: tagsList});
	}
}
