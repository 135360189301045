import Cookies from "core/cookies";
import Lang from "./lang";

let sharedDictionary = {
	"create": {
		"no": "opprett"
	},
	"cancel": {
		"no": "avbryt"
	},
	"update": {
		"no": "oppdater"
	},
	"name": {
		"no": "navn"
	},
	"type": {
		"no": "type"
	},
	"value": {
		"no": "verdi"
	},
	"selector": {
		"no": "selektor"
	},
	"select": {
		"no": "velg"
	},
	"select...": {
		"no": "velg..."
	},
	"description": {
		"no": "beskrivelse"
	},
	"search": {
		"no": "søk"
	},
	"search...": {
		"no": "søk..."
	},
	"get": {
		"no": "hent"
	},
	"minimize": {
		"no": "minimere"
	},
	"maximize": {
		"no": "maksimere"
	},
	"default": {
		"no": "standard"
	},
	"next": {
		"no": "neste"
	},
	"previous": {
		"no": "forrige"
	},
	"create new": {
		"no": "opprett ny"
	},
	"favorites": {
		"no": "favoritter"
	},
	"delete": {
		"no": "slett"
	},
	"operator": {
		"no": "operator"
	},
	"from": {
		"no": "fra"
	},
	"to": {
		"no": "til"
	},
	"severity": {
		"no": "alvorlighetsgrad"
	},
	"authenticate": {
		"no": "autentiser"
	},
	"authenticating": {
		"no": "autentiserer"
	},
	"authentication": {
		"no": "autentisering"
	},
	"Asset Target": {
		"no": "ressursmål"
	},
	"Asset Group": {
		"no": "ressursgruppe"
	},
	"Asset Groups": {
		"no": "ressursgruppe"
	},
	"url": {
		"no": "url"
	},
	"port": {
		"no": "port"
	},
	"uptime": {
		"no": "oppetid"
	},
	"source": {
		"no": "Kilde"
	},
	"reload": {
		"no": "last på nytt"
	},
	"configuration": {
		"no": "konfigurasjon"
	},
	"profile": {
		"no": "profil"
	},
	"clear results": {
		"no": "fjern resultater"
	},
	"there is no data available": {
		"no": "ingen data tilgjengelig"
	},
	"Service Element": {
		"no": "tjenesteelement"
	},
	"Service Qualifier": {
		"no": "tjenestepunkt"
	},
	"application": {
		"no": "applikasjon"
	},
	"Dirty check confirmation": {
		"en": "You have unsaved content. You will lose the changes if you click Close. Click Cancel and save the changes if you want to keep them.",
		"no": "Du har ulagret innhold. Du vil miste endringen hvis du klikker Lukk. Trykk avbryt og lagre endringen hvis du vil beholde dem."
	},
	"Service Model": {
		"no": "Tjenestemodell"
	},
	"database": {
		"no": "database"
	},
	"driver": {
		"no": "driver"
	},
	"Connection String": {
		"no": "tilkoblingstreng"
	},
	"generic": {
		"no": "generisk"
	},
	"general": {
		"no": "generell"
	},
	"monitor general": {
		"en": "general",
		"no": 'standard"'
	},
	"Monitor Settings": {
		"no": 'Monitorinstillinger'
	},
	"reason message": {
		"no": "årsaksmelding"
	},
	"Authentication Type": {
		"no": "autentiseringstype"
	},
	"select service": {
		"no": "Velg tjeneste"
	},
	"Exclude": {
		"no": "Eksluder"
	},
	'padding': {
		no: 'padding'
	},
	'dashboard': {
		no: 'dashboard'
	},
	'dashboards': {
		no: 'dashboards'
	},
	'authentications': {
		no: 'autentisering'
	},
	'calendar': {
		no: 'kalender'
	},
	'roles': {
		no: 'roller'
	},
	'templates': {
		no: 'maler'
	},
	'team': {
		no: 'gruppe'
	},
	'teams': {
		no: 'grupper'
	},
	'user': {
		no: 'Bruker'
	},
	'users': {
		no: 'Brukere'
	},
	'asset': {
		no: 'ressurs'
	},
	'assets':{
		no: 'ressurser'
	},
	'Health Reason':{
		no: 'helseårsak'
	},
	'Health Reasons':{
		no: 'helseårsaker'
	},
	'monitor':{
		no: 'monitor'
	},
	'monitors':{
		no: 'monitorer'
	},
	'filter': {
		no: 'filter'
	},
	'filters':{
		no: 'filtre'
	},
	'management':{
		no: 'system'
	},
	'accounts': {
		no: 'kontoer'
	},
	'account': {
		no: 'konto'
	},
	'agent':{
		no: 'agent'
	},
	'agents':{
		no: 'agenter'
	},
	'integration':{
		no: 'integrasjon'
	},
	'repository':{
		no: 'pakkearkiv'
	},
	'modules':{
		no: 'komponenter'
	},
	'audit':{
		no: 'revisjon'
	},
	'Active Users':{
		no: 'aktive brukere'
	},
	'system messages':{
		no: 'systemmeldinger'
	},
	'incident':{
		no: 'sak'
	},
	'incidents':{
		no: 'saker'
	},
	'home': {
		no: 'hjem'
	},
	'infrastructure': {
		no: 'infrastruktur'
	},
	'administration': {
		no: 'administrasjon'
	},
	'about': {
		no: 'om'
	},
	'advanced': {
		no: 'avansert'
	},
	'enabled':{
	},
	'scale': {
		no: 'skaler'
	},
	'data is not available': {
		no: 'data is not available'
	},
	"loading...": {
		"no": "laster..."
	},
	'top': {},
	'bottom':{},
	'none': {},
	'position': {},
	'Include subaccounts': {
		no: 'Inkluder underkontoer'
	},
	'Show Untagged': {
		no: 'Vis uten etikett'
	},
	"Select Team": {
		"no": "Velg team"
	},
	'no data': {
		no: 'ingen data'
	},
	'Measuring Agent': {
		'no': 'måleagent'
	},
	'Measuring Target': {
		no: 'Mål'
	},
	"please fill in the field": {
		"no": "fyll inn feltet"
	},
	"The input value should be between {0} and {1}": {
		"no": "Sett inn en verdi mellom {0} og {1}"
	},
	'Template Asset':  {no: 'ressursmal'},
	'Warning threshold':  {no: 'advarsel terskel'},
	'Critical threshold':  {no: 'kritisk terskel'},

	"Warning severity": {
		"no": "Advarsel alvorlighet",
	},
	"Critical severity": {
		"no": "Kritisk alvorlighet",
	},
	"threshold": {
		"no": "terskelverdi"
	},
	"new monitor": {
		"no": "ny monitor"
	},
	'click to edit': {},
	'interface traffic': {},
	'trend': {
		no: 'trend'
	},
	"timeout (sec)": {
		"no": "tidsavbrudd (sek)"
	},
	'disable': {},
	'enable': {},
	'Monitor Error': {
		'no': 'monitorfeil'
	},
	'Please input Name...': {
		no: 'Skriv inn navn...'
	},
	'cost': {
		'no': 'kostnad'
	},
	'Unexpected server error': {
		no: 'Uventet server feil'
	},
	"Not available": {
		no: 'Ikke tilgjengelig'
	},
	"Set in Maintenance":{
		no: "Sett i vedlikehold"
	},
	"End Maintenance": {
		no: "Avslutt vedlikehold"
	},
	"Set Tags": {
		"no": "Sett etikett"
	},
	"Agent Name": {
		"no": "Agentnavn",
	},
	"Measuring Asset": {
		no: "Måle ressurs"
	},
	"Asset Name": {
		"no": "Ressursnavn"
	},
	"Monitor Name": {
		"no": "Monitornavn"
	},
	"Monitor Type": {
		no: "Monitortype"
	},
	"Target Name": {
		no: "Målnavn"
	},
	"Target Type": {
		no: "Måltype"
	},
	"Measuring Type": {
		no: "Måletype"
	},
	"Monitor":{
		no: 'Monitor'
	},
	'Select Asset Group...': {
		no: 'Velg ressursgruppe...'
	},
	'Select Asset...': {
		no: 'Velg ressurs...'
	},
	"Event State": {
		"no": "Hendelse Tilstand"
	},
	"Content is filtered": {
		"no": "Innholdet er filtrert"
	},
	'Agent ID': {
		no: 'Agent ID'
	},
	'External ID': {
		no: 'Ekstern ID'
	},
	'External Owner': {
		no: 'Ekstern eier'
	},
	'Identifier Name': {
		no: 'Identifikator navn'
	},
	'Identifier Instance': {
		no: 'Identifikator instans'
	},
	'Monitor Class': {
		no: 'Monitorklasse'
	},
	'Subsystem': {
		no: 'Delsystem'
	},
	'Source ID': {
		no: 'Source ID'
	},
	'Target ID': {
		no: 'Target ID'
	},
	'Time Deleted': {
		no: 'Tid slettet'
	},
	'Time': {
		no: 'Tid'
	},
	'Message': {
		no: 'Melding'
	},
	'Suppression': {
		no: 'Undertrykk'
	},
	'Acknowledged At': {
		no: 'Bekreftet tid'
	},
	'Acknowledged By': {
		no: 'Bekreftet av'
	},
	'Acknowledged': {
		no: 'Bekreftet'
	},
	'Acknowledge':{
		no: 'Bekreft'
	},
	'Unacknowedge':{
		no: 'Ubekreft'
	},
	'Action Count': {
		no: 'Handlingsantall'
	},
	'In Maintenance': {
		no: 'Vedlikehold'
	},
	'Assigned Type': {
		no: 'Tildelt type'
	},
	'Assigned To': {
		no: 'Tildelt til'
	},
	'Assigned Time' : {no : 'Tildelt tid'},
	'Assign': {
		no: 'Tildele'
	},
	'Unassign': {
		no: 'Fjern tildeling'
	},
	"New Monitor": {
		"no": "Ny monitor",
		"en": "New Monitor"
	},
	"Select All": {
		no: 'Velg alle'
	},
	'Deselect All': {},
	'Interval': {no: 'Intervall'},
	'Schedule': {no: 'Tidsplan'},
	'Expand': {no: 'Ekspander'},
	'Collapse': {no: 'Kollaps'},
	"Select Severity": {no: "Velg alvorlighet"},
	'activate': {
		no: 'aktivere'
	},
	'deactivate': {
		no: 'inaktivere'
	},
	"Profile Name": {
		"no": "Profilnavn"
	},
	"Edit Profile Name": {
		"no": "Rediger profilnavn",
	},
	"Delete Profile": {
		"no": "Slett profil",
	},
	"Alert Message": {
		"no": "Varselmelding",
	},
	"Default Configuration": {
		"no": "Standard konfigurasjon",
	},
	'Process Name':  {no: 'Prosess navn'},
	'Command Line':  {no: 'Kommandolinje'},
	"Process State": {
		"no": "Prosesstilstand",
	},
	"Profiles": {"no": "Profiler"},
	"N/A": {
		"no": "N/A"
	},
	'Menu': {
		no: 'Meny'
	},
	'Refresh Timer': {

	},
	'Filter': {
		no: 'Filter'
	},
	'Timebar': {
		no: 'Tidsvelger'
	},
	'Required field': {
		no: 'Påkrevd felt'
	},
	'custom': {

	}
}; //do not remove the semicolon or Localization parser would not pick up the dictionary

export class Localization {
	constructor(...args) {
		this.dictionary = {};
		for(let dictionary of args){
			if( dictionary?.dictionary ){
				this.dictionary = {...this.dictionary, ...dictionary.dictionary};
			}else{
				this.dictionary = {...this.dictionary, ...dictionary};
			}
		}
	}

	get() {
		let key = arguments[0];

		let replacementsIndex = 1;

		let options = {};
		if( arguments.length > 1 && typeof arguments[1] === 'object'){
			options = arguments[1];
			replacementsIndex ++;
		}

		if( key == null ){
			console.log('here')
		}

		const lowerCaseKey = key.toLowerCase();
		const keyCapitalized = lowerCaseKey != key;

		options = Object.assign({
			capitalize: keyCapitalized
		}, options);


		let value = this.tryDictionary(this.dictionary, key);

		if (value == null) {
			value = this.tryDictionary(this.dictionary, lowerCaseKey)
		}

		if (value == null) {
			value = this.tryDictionary(sharedDictionary, key)
		}

		if (value == null) {
			value = this.tryDictionary(sharedDictionary, lowerCaseKey)
		}

		if (value == null) {
			value = Lang[key.replace('-', '_').toUpperCase()];
		}


		if (value != null) {
			if (options.capitalize) {
				value = value.charAt(0).toUpperCase() + value.slice(1);
			}
		}

		if( value == null ){
			value = key;
			console.error('No localization found for ' + key);
		}

		if( arguments.length > replacementsIndex){
			value = this.formatString(value, Array.prototype.slice.call(arguments, replacementsIndex));
		}

		return value;
	}

	tryDictionary(dictionary, key){
		if (dictionary[key] == null)
			return null;

		const entry = dictionary[key];
		if( entry[this.getLanguageKey()] != null )
			return entry[this.getLanguageKey()];

		if (this.getLanguageKey() == 'en'){
			return key;
		}

		return null;
	}

	getLanguageKey() {
		return Cookies.CeesoftUserLocale == 'no' ? 'no' : 'en';
	}

	formatString(value, replacements){
		return value.replace(/{(\d+)}/g, function(match, number) {
			return typeof replacements[number] != 'undefined'
				? replacements[number]
				: match;
		});
	}

	getTranslator(){
		return this.get.bind(this);
	}
}

export let translator = function (...args) {
	const localization = new Localization(...args);
	return localization.getTranslator();
}

export default translator;

export let i = translator();

export function getNumericTranslation(count, singularForm, pluralForm){
	return count == 1 ? singularForm : pluralForm;
}

export function getNumericTranslationStart(count, singularForm, pluralForm){
	return count + ' ' + getNumericTranslation(count, singularForm, pluralForm)
}

