import React from "react";
import {translator} from "core";
import {Button, TextBox, Grid, FormEntry, ActionButtons, MultiSelect} from "controls/react/form";
import {Window, Section, Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import AttributesApi from './attributesApi'
import {Utils} from "tools";
import Dialog from 'controls/dialog';
import {AntSelect} from "controls/react/ant/antSelect";
import withRequired from 'controls/react/dropdowns/withRequired';
import {inputWithAction} from 'controls/react/form/common';
import {IconButton} from "controls/react/form/iconButton";
import Window from "../../controls/react/kendoWrappers/window";

import './attributeWindow.less'

const b = require('b_').with('asset-attributes-window');
let i = translator({
	"Attribute": {no: "Attributer"},
	"Duplicate attribute message": {
		"en": "Key and group combination already exist, creating this attribute will overwrite existing attribute. Click update to continue or cancel to cancel operation.",
		"no": "N\u00f8kkel og gruppe finnes, opprette denne attributen vil overskrive attributen som finnes. Klikk oppdater for \u00E5 fortsette eller avbryt for g\u00E5 tilbake"
	},
	'Create Group': {
		no: 'Opprett Gruppe'
	}
});

const TextBoxWithRequired = (props) => withRequired(TextBox)(props);

export class AttributeWindow extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			attributeData: this.props.attributeData || {
				group: '',
				key: '',
				value: ''
			},
			groupData: [],
			createGroupWindowPosition: {},
			newGroupValue: ''
		};
	}

	render() {
		return (
			<>
				<Window width={400}
						modal
						height={220}
						title={i('Attribute')}
						ref={window => this.window = window}
						onClose={this.props.onCancel}>
					<Section childrenPadding={true}>
						<FormEntry label={i('Key')} value={this.state.attributeData.key}>
							<TextBoxWithRequired onChange={(value) => this.onAttributeFieldChange(value, 'key')}
												 value={this.state.attributeData.key}
												 required={true}
							/>
						</FormEntry>
						<FormEntry label={i('Value')}>
							<TextBoxWithRequired onChange={(value) => this.onAttributeFieldChange(value, 'value')}
												 value={this.state.attributeData.value}
												 required={true}
							/>
						</FormEntry>
						<FormEntry label={i('Group')} required={!this.state.attributeData.group}>
							<div className={inputWithAction()}>
								<AntSelect value={this.state.attributeData.group}
										   sortValues={false}
										   isAttributesView={true}
										   onChange={(value) => this.onGroupChange(value)}
										   dataList={this.state.groupData}
										   className={b('group-select')}
								/>
								<IconButton title={i('Create Group')}
											onClick={(e) => this.onCreateGroup(e)}
											iconName='plus-sign'
											embedded
											containerClass={inputWithAction('action')}
								/>
							</div>
						</FormEntry>
					</Section>
					<Toolbar>
						<ActionButtons onSave={() => this.saveAttribute()}
									   onCancel={this.props.onCancel}
									   saveDisabled={!this.checkIfMandatoryFieldsCompleted()}
									   mode={this.props.mode}
						/>
					</Toolbar>
				</Window>
				{
					this.state.openCreateNewGroupWindow && <Window width={350}
																   height={100}
																   modal
																   position={this.state.createGroupWindowPosition}
																   title={i('Create Group')}
																   onClose={() => this.onNewGroupCancel()}
					>
						<Section childrenPadding={true}>
							<FormEntry label={i('Name')}>
								<TextBox value={this.state.newGroupValue}
										 onChange={(value) => {this.setState({
											 newGroupValue: value
										 })}}/>
							</FormEntry>
						</Section>
						<Toolbar>
							<ActionButtons onSave={() => this.onNewGroupUpdate()}
										   onCancel={() => {this.onNewGroupCancel()}}
										   mode={'update'}
							/>
						</Toolbar>
					</Window>
				}
			</>

		)
	}

	async componentDidMount() {
		this.initialData = {};
		for (let key in this.props.attributeData) {
			this.initialData[key] = this.props.attributeData[key];
		}

		let result = await AttributesApi.getGroups(this.props.id, this.props.type);
		if (result.success) {
			let data = result.data;
			let groupData = [];
			for (let i = 0; i < data.length; i++) {
				groupData.push({
					id: Utils.guid(),
					name: data[i]
				});
			}
			this.setState({
				groupData:groupData
			});
		}
	}

	onGroupChange(value) {
		let groupName;
		for (let group of this.state.groupData) {
			if (group.id === value) {
				groupName = group.name
			}
		}
		this.onAttributeFieldChange(groupName, 'group')
	}

	checkIfMandatoryFieldsCompleted() {
		let mandatoryCompleted = true;
		for (let key in this.state.attributeData) {
			if (!this.state.attributeData[key]) {
				mandatoryCompleted = false;
			}
		}
		return mandatoryCompleted;
	}

	onAttributeFieldChange = (value, field) => {
		let attributeData = {...this.state.attributeData};

		attributeData[field] = value;
		this.setState({
			attributeData: attributeData,
			[field]: value
		});
	}

	saveAttribute = () => {
		let existingAttributes = this.props.existingAttributes;
		let attribute = this.state.attributeData;
		let duplicateAttribute;
		for (let i = 0; i < existingAttributes.length; i++) {
			if (existingAttributes[i].key === attribute.key && existingAttributes[i].group === attribute.group) {
				if (this.initialData.key !== attribute.key && this.initialData.group !== attribute.group) {
					//excluding self if edit mode
					duplicateAttribute = existingAttributes[i];
				}
			}
		}
		if (duplicateAttribute) {
			let dialog = new Dialog({
				title: lang.INFO,
				msg: i('Duplicate attribute message'),
				icon: 'WARNING',
				actionText: 'UPDATE',
				buttons: {
					ok: true,
					cancel: true
				},
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						this.props.onSave(attribute, this.initialData);
					}
				}, this)
			});
			dialog.show();
		} else {
			this.props.onSave(attribute, this.initialData);
		}
	}

	onCreateGroup(e) {
		this.setState({
			openCreateNewGroupWindow: true,
			newGroupValue: '',
			createGroupWindowPosition: {
				left: e.pageX + 15,
				top: e.pageY - 10
			}
		});
	}

	onNewGroupUpdate() {
		let newGroup = {
			id: Utils.guid(),
			name: this.state.newGroupValue
		};
		let currentGroups = this.state.groupData;
		currentGroups.push(newGroup);
		let currentAttributeData = this.state.attributeData;
		currentAttributeData.group = this.state.newGroupValue;
		this.setState({
			groupData: currentGroups,
			openCreateNewGroupWindow: false,
			attributeData: currentAttributeData
		});
	}

	onNewGroupCancel() {
		this.setState({
			openCreateNewGroupWindow: false
		});
	}
}

