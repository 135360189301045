import { IObservableArray } from "mobx"

import {GridColumnConfig} from "controls/grid/gridColumnConfig"
import {GridDataItem} from "controls/grid/gridDataItem"
import { GridStore } from "./gridStore"
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition"
import { GridState } from "./gridState"
import {GridStateProvider} from "controls/grid/stateProviders"

export enum GridSelectionType {
	None,
	One,
	Many
}

export interface DataProvider<DataItem extends GridDataItem>{
	initialized: boolean
	visibleRowsCount: number
	totalRowsCount: number
	filtersConfiguration: RulesConfiguration
	attach: (store: GridStore<DataItem>) => void
	reload: () => void
	silentReload: () => void
	informVisibleRangeChanged: (startIndex: number, stopIndex: number) => void

	get: (index: number) => DataItem

	destroy: () => void
	data: IObservableArray<DataItem>
}

export type GridDefaultFilterEntry = {
	field: string,
	operator: string
	value: string[]
}

export type GridConfiguration<DataItem extends GridDataItem> = {
	dataProvider: DataProvider<DataItem>
	columns: GridColumnConfig<DataItem>[]

	selection?: GridSelectionType

	plugins?: Array<GridPlugin<DataItem>>

	persistState?: boolean

	state?: GridState<DataItem>

	fit?: boolean
	heightByContent?: boolean

	stateProviders?: GridStateProvider<DataItem>[]

	emptyMessage?: string

	onFiltersCleared?: (store: GridStore<DataItem>) => void

	defaults?: {
		payload?: Record<string, any>,
		data?: Record<string, any>,
		sorting?: {field: string, direction: 'asc'|'desc'}[],
		columns?: string[]
		filters?: GridDefaultFilterEntry[]
	}

	customization?: {
		isSelectionDisabled: (item: DataItem, store: GridStore<DataItem>) => boolean | void | {
			disabled: boolean,
			reason: string
		}
	}
}

export interface GridPlugin<DataItem extends GridDataItem>{
	id?: string
	attach(store: GridStore<DataItem>): void
	destroy?: () => void
}
