import React, { CSSProperties } from "react"
import {flowResult, makeAutoObservable} from "mobx"
import { primitive } from "serializr"

import {createModelSchemaWrapper} from "framework/serializr-integration"
import {GridDataItem} from "controls/grid/gridDataItem"
import {GridStore} from "controls/grid/gridStore"
import Data from "tools/apis/data"
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition"
import {canApplyStringFilter} from "controls/queryBuilder/utils";


export type RenderingResult = {
	content: React.ReactNode
	className?: string
	disabled?: boolean
}

export type FixedType = 'left' | 'right' | 'none'

export type GridColumnRendererExtra = {
	width: number
}

export type GridColumnConfig<DataItem> = {
	field: string
	title: string
	renderer: (item: DataItem, extra: GridColumnRendererExtra) => React.ReactNode | RenderingResult
	rendererHeader?: () => React.ReactNode | RenderingResult
	width?: number
	minWidth?: number
	visible?: boolean
	className?: string
	align?: 'left'|'right'|'center',
	sortable?: boolean
	getHeaderTitle?: () => string
	fixed?: FixedType
	expandOnClick?: boolean
	filterDropDownRenderer?: (item: any) => React.ReactNode
}

export class GridColumnState{
	field: string
	width: number
	resizingWidth?: number
	visible?: boolean
	sorting?: 'asc' | 'desc'
	fixed?: FixedType = 'none'

	constructor(init?: Partial<GridColumnState>) {
		init && Object.assign(this, init)
		makeAutoObservable(this)
	}
}

createModelSchemaWrapper(GridColumnState, {
	field: primitive(),
	width: primitive(),
	visible: primitive(),
	sorting: primitive(),
	fixed: primitive()
})

export class GridColumn<DataItem extends GridDataItem>{
	constructor(public state: GridColumnState,
	            public config: GridColumnConfig<DataItem>,
	            public store: GridStore<DataItem>) {

		makeAutoObservable(this)
	}

	get next() : GridColumn<DataItem> {
		if (this.index == this.store.columns.all.length - 1)
			return null

		return this.store.columns.all[this.index + 1]
	}

	get prev() : GridColumn<DataItem>{
		if(this.index == 0)
			return null

		return this.store.columns.all[this.index - 1]
	}

	get index(){
		return this.store.columns.all.indexOf(this)
	}

	get styles(){
		let result: CSSProperties = {}
		result.width = this.actualWidth + 'px'

		if(this.state.fixed == 'left'){
			let shift = 0
			for(let prev of iteratePrev(this)){
				if(prev.state.visible) {
					shift += prev.actualWidth
				}
			}
			result.left = shift + 'px'
		}

		if(this.state.fixed == 'right'){
			let shift = 0
			for(let next of iterateNext(this)){
				if(next.state.visible) {
					shift += next.actualWidth
				}
			}
			result.right = shift + 'px'
		}

		return result
	}

	get actualWidth(){
		return (this.state.width ?? this.config.width ?? this.config.minWidth ?? 100)
	}

	get fixed(){
		return this.state.fixed != 'none'
	}

	get field(){
		return this.config.field
	}

	get filteringEnabled(){
		return this.config.field != 'id' && this.filterDescription != null
	}

	get filterDescription(){
		return this.store.filtersConfigurationEffective[this.field]
	}

	get sortingIndex(){
		return this.store.state.sortingOrder.indexOf(this.field)
	}

	get filter(){
		if(this.store.customFiltering)
			return null

		for(const filter of this.store.state.filters.iterateRules()){
			if(filter.properties.field == this.state.field){
				return filter
			}
		}

		return null
	}

	flipSorting = () => {
		if(this.state.sorting == null)
			return

		if(this.state.sorting == 'asc'){
			this.state.sorting = 'desc'
		}else{
			this.state.sorting = 'asc'
		}
	}

	triggerSorting = () => {
		const sortingOrder = this.store.state.sortingOrder

		if (this.state.sorting == null) {
			this.state.sorting = 'asc'
			sortingOrder.push(this.state.field)
		} else if (this.state.sorting == 'asc') {
			this.state.sorting = 'desc'
		} else {
			this.removeSorting()
		}
	}

	removeSorting = () => {
		const sortingOrder = this.store.state.sortingOrder
		this.state.sorting = null

		let index;
		while((index = sortingOrder.indexOf(this.state.field)) != -1){
			sortingOrder.splice(sortingOrder.indexOf(this.state.field), 1)
		}
	}

	isFilterApplied(store: GridStore<any>){
		if(store.customFiltering)
			return false

		if(this.filter != null)
			return true

		return !!store.state.searchString && canApplyStringFilter(store.filtersConfigurationEffective, this.field)
	}

	clearFilter = () => {
		if(!this.filter)
			return

		this.store.state.filters.removeRule(this.filter)
	}

	updateFilter(filter: RuleDefinition){
		if(filter.empty()) {
			this.store.state.filters.removeRule(filter)
		}else{
			this.store.state.filters.addOrUpdateRule(filter)
		}
	}

	fixOnLeft = () => {
		this.store.columns.fixOnLeft(this)
	}

	fixOnRight = () => {
		this.store.columns.fixOnRight(this)
	}
}

function* iteratePrev<DataItem extends GridDataItem>(column: GridColumn<DataItem>){
	let result = column.prev
	while(result != null ){
		yield result
		result = result.prev
	}
}

function* iterateNext<DataItem extends GridDataItem>(column: GridColumn<DataItem>){
	let result = column.next
	while(result != null ){
		yield result
		result = result.next
	}
}
