import { ApiRequest } from "framework/api";

export interface Calendar {
	accountId: string;
	calendarType: 'generic' | 'holiday';
	description: string;
	icalendar: string;
	id: string;
	name: string;
}

export interface ParentCalendarData {
	calendar: string;
	maintenanceCalendars: string[];
}

export function getCalendars(accountId: string) {
	const config = {
		url: `calendars`,
		accountBased: true,
		accountId: accountId
	} as Partial<ApiRequest<Calendar[]>>;
	return new ApiRequest<Calendar[]>(config);
}

export function getAgentCalendarsByAssetId(accountId: string, assetId: string) {
	const config = {
		url: `assets/${assetId}/agentMaintenanceInfo`,
		accountBased: true,
		accountId: accountId
	} as Partial<ApiRequest<ParentCalendarData>>;
	return new ApiRequest<ParentCalendarData>(config);
}

export function getServiceCalendarsBySlaId(accountId: string, slaId: string) {
	const config = {
		url: `slas/${slaId}/serviceMaintenanceInfo`,
		accountBased: true,
		accountId: accountId
	} as Partial<ApiRequest<ParentCalendarData>>;
	return new ApiRequest<ParentCalendarData>(config);
}
