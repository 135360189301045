import './grid.less'

import React from "react"
import {observer} from "mobx-react"
import {VariableSizeList} from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"

import {getStore, GridStore, GridStoreHolder} from "controls/grid/gridStore"
import {ItemRenderer} from "controls/grid/itemRenderer"
import {GridHeader} from "controls/grid/gridHeader"
import {AntSpin} from "controls/react/ant/antSpin"
import {GridDataItem} from "controls/grid/gridDataItem"

type SizeUpdaterProps = {
	store: {width: number, height: number},
	width: number,
	height: number
}

const SizeUpdater = (props: SizeUpdaterProps) : any => {
	React.useEffect(() => {
		props.store.width = props.width;
		props.store.height = props.height
	}, [props.width, props.height])
	return null
}

const b = require('b_').with('ceeview-grid')

const i18n = require('core/localization/localization').translator();

export const
	Grid = observer(<DataItem extends GridDataItem,>(props: { store: GridStoreHolder<DataItem>}) => {

	const store = getStore(props.store)

	//we need to subscribe for onScroll event of list container to scroll header with content horizontally
	//se to provide on outerListElement wrapper is the only official way of doing that
	const outerListElement = React.useMemo(() => store && getOuterListElement(store), [store])


	React.useEffect(() => {
		if(!store)
			return

		if(!store.initialRenderHappened){
			store.initialRenderHappened = true
		}
	}, [store?.initialRenderHappened])

	if(!store || !store.initialRenderHappened)
		return null

	return <div className={b({fit: store.config.fit !== false})}>
		{/*todo move gridHeader as a fixed row of the list - then there will bo no need to sync scrolling */}
		{store.initialized && <GridHeader ref={store.headerRef} store={store} scrollable={true}/>}
		<div className={b('wrapper')}>
			<AutoSizer disableHeight={store.config.heightByContent === true}>
				{({height, width}: { height: number, width: number }) => (
					<SizeUpdater store={store} width={width} height={height}/>
				)}
			</AutoSizer>

			{!store.dataProvider.initialized && <AntSpin spinning={true} centered={true}/>}

			{store.dataProvider.initialized && store.dataProvider.visibleRowsCount == 0 &&
				<div className={b('no-data-message')}>
					{store.remoteDataProvider?.responseMessage ?? store.config.emptyMessage ?? i18n('There is no data available')}
				</div>
			}

			{store.initialized && store.dataProvider.initialized && store.dataProvider.visibleRowsCount != 0 &&
				<VariableSizeList<GridStore<DataItem>>
					height={store.height }
					ref={(v) => store.listControl = v}
					width={store.width}
					outerElementType={outerListElement}
					itemCount={store.dataProvider.visibleRowsCount}
					itemData={store}
					itemSize={(i) => store.getRowHeight(i)}
					onItemsRendered={store.itemsRendered}
				>
					{ItemRenderer}
				</VariableSizeList>
			}
		</div>
	</div>
})

type OuterListElementProps = {
	onScroll: (e: React.UIEvent<HTMLDivElement>) => void
}

function getOuterListElement<DataItem extends GridDataItem>(store: GridStore<DataItem>){
	return React.forwardRef((props: OuterListElementProps, ref: any) => {
		const {onScroll, ...restProps} = props

		const onScrollWrapper = (e: React.UIEvent<HTMLDivElement>) => {
			store.listScrolled(e)
			onScroll(e)
		}

		return <div ref={ref} onScroll={onScrollWrapper} {...restProps}/>
	})
}
