import {Api} from "tools/api";
import {UrlBuilder} from "tools/urlBuilder";
import {useRemoteList} from "tools/api";
import State from 'tools/state';

export class ApplicationsApi {
	static listUrl(accountId, includeSubaccounts){
		return Api.accountRoot(accountId) + 'summary/applications?includeSubaccounts=' + (includeSubaccounts !== undefined ? includeSubaccounts : State.includeSubaccounts)
	}

	static list(accountId, includeSubaccounts) {
		return Api.fetch(ApplicationsApi.listUrl(accountId, includeSubaccounts));
	}

	static details(applicationId, canUpdate) {
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '?update=' + canUpdate);
	}

	static detailsReadonly(applicationId, sessionId) {
		return Api.fetch(Api.sessionRoot(sessionId) + 'applications/' + applicationId + '?update=false');
	}

	static save(application){
		return Api.fetchPost(Api.serverRoot() + 'applications', application);
	}

	static delete(applicationIds) {
		return Api.fetchPost(Api.serverRoot() + 'applications/delete', applicationIds);
	}

	static getReasons(applicationId) {
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '/reasons');
	}

	static getAssetReasons(applicationId, assetId) {
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '/reasons?assetId=' + assetId);
	}

	static getAssetGroupReasons(applicationId, assetGroupId, severity) {
		const builder = new UrlBuilder(Api.serverRoot() + 'applications/' + applicationId + '/reasons')
			.add("assetGroupId", assetGroupId)
			.add("severity", severity == 'INVALID' ? 'MAINTENANCE' : severity, !!severity);

		return Api.fetch(builder.build());
	}

	static getLastReasons(applicationId) {
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '/lastReasons');
	}
	static getDependencies(applicationId) {
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '/dependencies');
	}
	static deleteDependencies(applicationId) {
		return Api.fetchDelete(Api.serverRoot() + 'applications/' + applicationId + '/dependencies/deleteAll')
	}

	static getAssetGroups(assetGroupId, includeSubaccounts) {
		return Api.fetchPost(Api.accountRoot() + 'health/assetGroups/treelist?includeSubaccounts=' + includeSubaccounts, {});
	}

	static getSearchUrl() {
		return Api.accountRoot() + 'applications/search/lite?includeSubaccounts=false';
	}
}

export function useApplications(accountId) {
	return useRemoteList(ApplicationsApi.listUrl(accountId));
}
