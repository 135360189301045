import './link.less';

import React from 'react'
import classnames from 'classnames'
import {NavigationAction} from "framework/navigationStore";
import {observer} from "mobx-react";
import {CeeviewNavigator} from "tools/ceeviewNavigator";

let b = require('b_').with('c-link');

export type LinkProps = {
	className?: string
	onClick?: (e: React.MouseEvent) => void
	url?: string
	children: React.ReactNode
	enabled?: boolean
	navigationAction?: NavigationAction
	navigator?: CeeviewNavigator
	title?: string
}

const LinkInner = observer((props: LinkProps) => {
	const classes = classnames(b({clickable: props.enabled !== false}), props.className)

	if(props.enabled === false)
		return <span className={classes}>{props.children}</span>

	const otherProps = {} as {[x: string]: any}
	if (props.title)
		otherProps.title = props.title;

	if(props.url){
		return <a className={classes} href={'#' + props.url} navigation-action={props.navigationAction} {...otherProps}>{props.children}</a>
	}else {
		return <span className={classes} onClick={props.onClick} {...otherProps}>{props.children}</span>
	}
})

export const Link = observer((props: LinkProps) => {
	let {url, onClick, navigator, enabled, ...others} = props

	if(navigator){
		if(navigator.forceEnabled === true){
			enabled = true
		}

		if(!navigator.canDoDefault()){
			const initialUrl = url
			onClick = () => navigator.go({url: initialUrl})
			url = undefined
		}
	}
	return <LinkInner url={url} onClick={onClick} enabled={enabled} {...others}/>
})
