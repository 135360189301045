import Application from 'core/application';
import Settings from 'settings';


/**
 * @class ceeview.management.BundleConfiguration
 * @namespace ceeview.assethealth
 * @constructor
 */
export default class BundleConfiguration {
    init() {
        this.removeListeners();
        this.attachListeners();
        this.firstLoad = true;
        this.grouping = false;
        this.groupsConfiguration = [];
        this.initKendoComponents();
        this.adjustSectionHeight();
		this.initialized({ title: this.bundleName + ' ' + lang.CONFIGURATION.toLowerCase() });
    }
    /**
     * Removes listeners
     */
    removeListeners() {
        $('#cw_agent_config_update').off();
        $('#cw_agent_config_list').off();
    }
    /**
     * Attaches listeners
     */
    attachListeners() {
        $('#cw_agent_config_update').on('click', $.proxy(this.onUpdate, this));
        $('#cw_agent_config_list').on('keyup', 'input[type=text]', $.proxy(this.onInputChange, this));
        $('#cw_agent_config_list').on('click', 'input[type=checkbox]', $.proxy(this.onInputChange, this));
    }
    initKendoComponents() {
        var columns;
        this.agentConfigDataSource = new kendo.ceeview.DataSource({
            transport: {
                read: {
                    url: Settings.serverPath + 'agents/' + this.agentId + '/configurations/' + this.configurationId + '/bundles/' + this.bundleId + '',
                    contentType: "application/json; charset=utf-8",
                    type: "GET",
                    dataType: "json",
                    cache: false
                }
            },
			schema: {
            	parse: $.proxy(function(result) {
            		this.selectOptions = [];
            		for (let i = 0; i < result.length; i++) {
            			if (result[i].options.length) {
            				for (let j = 0; j < result[i].options.length; j++) {
            					result[i].options[j].defaultValue = result[i].defaultValue
							}
            				this.selectOptions.push({
								id: result[i].id,
								value: result[i].value,
								options: result[i].options
							})
						}
					}
					return result;
				}, this)
			},
            sort: this.grouping ? [{
                field: 'subsystem',
                dir: 'asc'
            }] : [{
                field: 'name',
                dir: 'asc'
            }],
            change: $.proxy(this.saveGroupsConfiguration, this)
        });
        // check if bundle is com.ceesoft.ceeview.healthmonitor.system
        if (this.grouping) {
            columns = [{
                field: 'subsystem',
                title: lang.assethealth.SUBSYSTEM,
                sortable: true,
                filterable: true,
                hidden: true,
                width: 150
            }, {
                field: 'metric',
                title: lang.widget.METRIC,
                sortable: true,
                filterable: true,
                width: 150
            }, {
                field: 'instance',
                title: lang.assethealth.INSTANCE,
                sortable: true,
                filterable: true,
                width: 150
            }, {
                field: 'threshold',
                title: lang.designer.THRESHOLD,
                sortable: true,
                filterable: true,
				width: 150
            }, {
                field: 'value',
                title: lang.VALUE,
                sortable: false,
                filterable: false,
                template: '#=Renderer.agentConfiguration(value, defaultValue, id)#'
            }];
            this.agentConfigDataSource.group({
                field: 'subsystem'
            });
        } else {
            columns = [{
                field: 'name',
                title: lang.NAME,
                sortable: true,
                filterable: true,
                width: 250,
                attributes: {
                    "class": "expand ellipsis"
                }
            }, {
                field: 'description',
                title: lang.DESCRIPTION,
                sortable: true,
                filterable: true,
				width: 450,
                attributes: {
                    "class": "expand ellipsis"
                }
            }, {
                field: 'value',
                title: lang.VALUE,
                sortable: false,
                filterable: false,
                template: '#=Renderer.agentConfiguration(value, defaultValue, id)#'
            }];
        }
        this.gridMessages = {
            isTrue: '<span class="glyphicons service_state ok-sign"></span>',
            isFalse: '<span class="glyphicons service_state remove-sign"></span>',
            clear: lang.CLEAR,
            info: lang.grid.filter.SHOW_ITEMS,
            filter: lang.FILTER
        };
        var filterMessages = lang.grid.filter;
        this.agentConfigurationView = $('#cw_agent_config_list').kendoCustomGrid({
            dataSource: this.agentConfigDataSource,
            resizable: true,
            selectable: 'row',
            autoBind: !this.grouping,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            filterable: {
                extra: false,
                operators: {
                    string: {
                        startswith: filterMessages.STARTS_WITH,
                        neq: filterMessages.NEQ,
                        eq: filterMessages.EQ
                    },
                    number: {
                        eq: filterMessages.EQ,
                        neq: filterMessages.NEQ,
                        gte: filterMessages.GTE,
                        gt: filterMessages.GT,
                        lte: filterMessages.LTE,
                        lt: filterMessages.LT
                    }
                },
                messages: this.gridMessages
            },
            columnMenu: true,
            columns: columns,
            dataBound: $.proxy(this.onDataBound, this),
            change: $.proxy(this.onRowExpand, this)
        }).data('kendoCustomGrid');
        // Add Kendo tooltip to the header of the columns
        Utils.gridColumnHeaderTooltip(this.agentConfigurationView);
    }
    /**
     * Handler function for the change(select) event on the grid
     * @param {Object} e The change event object
     */
    onRowExpand(e) {
        var selectedRow = $(e.sender.select());
        selectedRow.find('.expand').toggleClass('ellipsis');
    }
    /**
     * Handler function for the grid data bound event
     */
    onDataBound(e) {
        var data = this.agentConfigurationView.dataSource.data();
        var gridContent = $('#cw_agent_config_list').find('.k-grid-content');
        if (data.length === 0) {
            var placeholder = '<div class="cw_excerpt">';
            placeholder += '<p>' + lang.kendo.pager.messages.empty + '</p>';
            placeholder += '</div>';
            $(gridContent).find('.cw_excerpt').remove();
            $(gridContent).prepend(placeholder);
        } else {
            $(gridContent).find('.cw_excerpt').remove();
            var infoIcons = $(gridContent).find('.cw_hint');
            for (var i = 0, length = infoIcons.length; i < length; i++) {
                if ($(infoIcons[i]).hasClass('info-sign')) {
                    var input = $(infoIcons[i]).parent().find('input'), value;
                    if (input.is('input[type=text]')) {
                        value = input.val();
                    } else {
                        value = input.is(':checked');
                    }
                    $(infoIcons[i]).attr('title', Renderer.agentConfigurationTooltip(value, $(infoIcons[i]).attr('data-info')));
                    /*$(infoIcons[i]).kendoTooltip({
                        width: 160,
                        content: Renderer.agentConfigurationTooltip(value, $(infoIcons[i]).attr('data-info'))
                    });*/
                }
            }
            if (this.grouping) {
                this.collapseGroups();
            }
        }
        this.makeOptionsSelect();

    }
	makeOptionsSelect() {
    	this.optionDropdowns = [];
    	for (let i = 0; i < this.selectOptions.length; i++) {
    		let input = $("[data-id='" + this.selectOptions[i].id + "']");
			input.removeClass('cw_grid_input').css('width', '163px');
    		let dropdownOption = input.kendoDropDownList({
				dataSource: this.selectOptions[i].options,
				dataTextField: 'text',
				dataValueField: 'value',
				value: this.selectOptions[i].value,
				change: function (e) {
					var defaultValue;
					let dataSource = e.sender.dataSource.data();
					for (let i = 0; i < dataSource.length; i++) {
						if (dataSource[i].value === e.sender.value()) {
							defaultValue = dataSource[i].defaultValue;
						}
					}
					let info = e.sender.wrapper.closest('td').find('.cw_hint');
					let text = e.sender.text();
					let value = e.sender.value();
					if (value !== defaultValue) {
						info.addClass('info-sign');
						$(info).attr('title', Renderer.agentConfigurationTooltip(text, defaultValue));
					} else {
						info.removeClass('info-sign');
					}
					$('#cw_agent_config_update').removeClass('no_rights');
				}
			}).data('kendoDropDownList');
    		this.optionDropdowns.push({
				id: this.selectOptions[i].id,
				dropdown: dropdownOption
			});
		}
	}
    /**
     * Collapse groups
     */
    collapseGroups() {
        var gridContent = $('#cw_agent_config_list').find('.k-grid-content');
        var groups = gridContent.find('.k-grouping-row');
        var i, length = groups.length;
        if (this.firstLoad) {
            for (i = 0; i < length; i++) {
                this.agentConfigurationView.collapseGroup($(groups[i]));
                this.groupsConfiguration.push(false);
            }
            this.firstLoad = false;
        } else {
            if (this.groupsConfiguration.length === length) {
                for (i = 0; i < length; i++) {
                    if (this.groupsConfiguration[i]) {
                        this.agentConfigurationView.expandGroup($(groups[i]));
                    } else {
                        this.agentConfigurationView.collapseGroup($(groups[i]));
                    }
                }
            } else {
                for (i = 0; i < length; i++) {
                    this.agentConfigurationView.expandGroup($(groups[i]));
                    this.groupsConfiguration.push(true);
                }
            }
        }
    }
    /**
     * Save groups configuration
     */
    saveGroupsConfiguration(e) {
        var gridContent = $('#cw_agent_config_list').find('.k-grid-content');
        var groups = gridContent.find('.k-grouping-row');
        var i, length = groups.length, groupState;
        this.groupsConfiguration = [];
        for (i = 0; i < length; i++) {
            groupState = $(groups[i]).find('td').first().attr('aria-expanded');
            this.groupsConfiguration.push(groupState === 'true' ? true : false);
        }
    }
    /**
     * Handler function for the change event of value inputs
     * @param {Object} e The click event object
     */
    onInputChange(e) {
        var input = $(e.currentTarget);
        var value = input.attr('type') === 'text' ? input.val() : String(input.is(':checked'));
        var info = input.parent().find('.cw_hint');
        var defaultValue = info.attr('data-info') !== 'null' ? info.attr('data-info') : '';
        var tooltip = info.data('kendoTooltip');
        $('#cw_agent_config_update').removeClass('no_rights');
        if (defaultValue !== value) {
            if (!tooltip) {
                info.addClass('info-sign');
				$(info).attr('title', Renderer.agentConfigurationTooltip(value, defaultValue));
                /*$(info).kendoTooltip({
                    width: 160,
                    content: Renderer.agentConfigurationTooltip(value, defaultValue)
                });*/
            }
        } else {
            info.removeClass('info-sign');
            if (tooltip) {
                tooltip.destroy();
            }
        }
    }
    /**
     * Handler function for the click event on the update button
     * @param {Object} e The click event object
     */
    onUpdate(e) {
        var inputs = $('.cw_grid_input');
        var configs = [], data, value;
        if (!$(e.currentTarget).hasClass('no_rights')) {
            for (var i = 0, length = inputs.length; i < length; i++) {
                data = $($('.cw_grid_input')[i]).data();
                if (data.orig === 'null') {
                    value = null;
                } else {
                    if (data.type === 'boolean') {
                        value = $($('.cw_grid_input')[i]).is(':checked').toString();
                    } else {
                        value = $($('.cw_grid_input')[i]).val().toString();
                    }
                }
                configs.push({
                    id: data.id,
                    value: value
                });
            }
            for (let j = 0; j < this.optionDropdowns.length; j++) {
            	configs.push({
					id: this.optionDropdowns[j].id,
					value: this.optionDropdowns[j].dropdown.value()
				})
			}
            var url = Settings.serverPath + 'agents/' + this.agentId + '/configurations/' + this.configurationId + '/bundles/' + this.bundleId;
            Utils.ajax(url, 'POST', JSON.stringify(configs), $.proxy(function (result) {
                if (result.success) {
                    $('#cw_agent_config_update').addClass('no_rights');
                    var status = $('#cw_agent_config_window').find('.status');
                    status.find('p').addClass('success');
                    status.find('p').text(lang.assethealth.messages.AGENT_CONFIG_SUCCESS_UPDATED);
                    status.slideDown().delay(2000).slideUp();
                    $('.cw_breadcrumb_item[data-application="ManagementAgentDetails"]').trigger('click');
                } else {
                    Utils.showInfo(lang.ALERT, result.message, result.details);
                }
            }, this));
        }
    }
    /**
     * Method by Andy
     */
    adjustSectionHeight() {
        var section = $('.cw_management_module .cw_section_full:visible');
        var sectionHeight = section.height();
        section.find('.cw_section_content').css('height', sectionHeight);
        section.find('.k-grid-content').css('height', sectionHeight - 40);
    }
}
