import './olMap.less';
import {observer} from "mobx-react";
import React from "react";
import {makeAutoObservable} from 'mobx';
import {Map, MapBrowserEvent, Overlay, View} from 'ol';
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import {fromLonLat, transform} from "ol/proj";
import {Geotag} from './../../framework/entities/geotag';
import RenderEvent from "ol/render/Event";
import ReactDOM from "react-dom";
import {EnvironmentFilled} from "@ant-design/icons";
import {AntPopover} from "controls/react/ant/antPopover";
import {Section} from "controls/react/layout/section";
import {newGuid} from 'tools/guid';
import {MobxManager} from 'framework/mobx-integration';

const b = require('b_').with('ol-map');

export class OlMapOverlay {
	id: string;
	position: Geotag;
	title?: string;
	content?: React.ReactNode;

	constructor(position: Geotag) {
		this.id = newGuid();
		this.position = position;
	}

	private overlay?: Overlay;

	getOverlay() {
		return this.overlay;
	}

	destroy = () => {
		if (this.content) {
			ReactDOM.unmountComponentAtNode(this.overlay.getElement());
		}
		if (this.overlay.getElement()) {
			this.overlay.getElement().parentElement.removeChild(this.overlay.getElement());
		}
		this.overlay.dispose();
	}

	build = () => {
		const container = document.createElement("div");
		container.className = b('overlay-container');

		this.overlay = new Overlay({
			element: container,
			positioning: 'top-left',
			position: fromLonLat([this.position.longitude, this.position.latitude])
		});
		if (!this.content) {
			this.content = <EnvironmentFilled />;
		}
		if (this.title) {
			this.content = <AntPopover content={<Section>{this.title}</Section>}>{this.content}</AntPopover>
		}
		ReactDOM.render(<>{this.content}</>, container);
	}

	updatePosition = (position: Geotag) => {
		this.position = position;
		this.overlay?.setPosition(fromLonLat([this.position.longitude, this.position.latitude]));
	}

	removeFromMap = (map: Map) => {
		map.removeOverlay(this.overlay);
		this.destroy();
	}
}

export class OlMapProps {
	center?: Geotag;
	zoom?: number;
	overlays?: OlMapOverlay[];
	size?: {width: number; height: number};
	onClick?: (point: Geotag) => void;
	onMove?: (center: Geotag) => void;
	onZoom?: (zoom: number) => void;
	onEvent?: (ev: any) => void;
}

export class OlMapStore {
	private availableEvents = [
		'change',
		'change:layerGroup',
		'change:size',
		'change:target',
		'change:view',
		'click',
		'dblclick',
		'error',
		'moveend',
		'movestart',
		'pointerdrag',
		'pointermove',
		'postcompose',
		'postrender',
		'precompose',
		'propertychange',
		'rendercomplete',
		'singleclick'
	];
	private mapContainer: HTMLElement;
	private map: Map;
	private overlays: OlMapOverlay[];
	private mobx = new MobxManager();

	center: Geotag;
	zoom: number;

	onClick: (point: Geotag) => void;
	onMove: (center: Geotag) => void;
	onZoom: (zoom: number) => void;
	onEvent: (ev: any) => void;

	constructor(props: OlMapProps) {
		makeAutoObservable(this);
		this.onClick = props.onClick;
		this.onMove = props.onMove;
		this.onZoom = props.onZoom;
		this.onEvent = props.onEvent;
		this.overlays = [...(props.overlays ?? [])];
		this.center = props.center ?? this.overlays?.[0]?.position ?? {latitude: 59.911491, longitude: 10.757933} as Geotag;
		this.zoom = props.zoom ?? 10;
		this.mobx.reaction(() => this.center, () => this.onMove?.(this.center));
		this.mobx.reaction(() => this.zoom, () => this.onZoom?.(this.zoom));
	}

	destroy() {
		this.mobx.destroy();
		this.overlays.forEach(x => {
			this.map.removeOverlay(x.getOverlay());
			x.destroy();
		});
		this.removeListeners();
		this.map.dispose();
	}

	setMapContainer = async (node: HTMLElement) => {
		if (node == null)
			return;

		this.mapContainer = node;
		this.createMap();
	}

	async createMap() {
		this.map = new Map({
			target: this.mapContainer,
			layers: [
				new TileLayer({
					source: new OSM()
				})
			],
			view: new View({
				center: fromLonLat([this.center.longitude, this.center.latitude]),
				zoom: this.zoom
			})
		});
		this.attachListeners();
		this.overlays.forEach(o => {
			o.build();
			this.map.addOverlay(o.getOverlay());
		})
	}

	attachListeners = () => {
		this.availableEvents.forEach((eventType: any) => {
			this.map.on(eventType, this.handleEvent);
		});
	}

	removeListeners = () => {
		this.availableEvents.forEach((eventType: any) => {
			this.map.un(eventType, this.handleEvent);
		});
	}

	handleEvent = (ev: any) => {
		this.onEvent?.(ev);
		switch(ev.type) {
			case 'click': {
				this.handleCoordinates(ev);
			}
			case 'rendercomplete': {
				this.handleState(ev);
			}
		}
	}

	handleState = (ev: RenderEvent) => {
		this.zoom = this.map.getView().getZoom();
		const newCenter = this.map.getView().getCenter();
		if (JSON.stringify(this.center) != JSON.stringify({latitude: newCenter[0], longitude: newCenter[1]})) {
			this.center = {latitude: newCenter[0], longitude: newCenter[1]} as Geotag;
		}
	}

	handleCoordinates = (ev: MapBrowserEvent<any>) => {
		const clickedCoord = this.map.getCoordinateFromPixel(ev.pixel);
		const point = transform(clickedCoord, 'EPSG:3857', 'EPSG:4326');
		this.onClick?.({latitude: point[1], longitude: point[0]} as Geotag);
		return point;
	}

	updateOverlays = (overlays: OlMapOverlay[]) => {
		const newOverlays = overlays.filter(x => !this.overlays.some(y => y.id == x.id));
		newOverlays.forEach(x => {
			this.overlays.push(x);
			x.build();
			this.map.addOverlay(x.getOverlay());
		});

		const overlaysToRemove = this.overlays.map(x => x.id)
			.filter(x => !overlays.some(y => y.id == x));

		overlaysToRemove.forEach(id => {
			const index = this.overlays.findIndex(y => y.id == id);
			this.overlays[index].removeFromMap(this.map);
			this.overlays.splice(index, 1);
		});
	}

	updateCenter = (center?: Geotag) => {
		if (!center) {
			return;
		}
		if (JSON.stringify(this.center) == JSON.stringify(center)) {
			return;
		}
		const point = transform([center.longitude, center.latitude], 'EPSG:4326', 'EPSG:3857')
		this.map.getView().setCenter(point);
		this.center = center;
	}

	updateZoom = (zoom?: number) => {
		if (!zoom || this.zoom == zoom) {
			return;
		}
		this.map.getView().setZoom(zoom);
		this.zoom = zoom;
	}

	refreshSize = () => {
		this.map.updateSize();
	}
}

export const OlMap = observer(class OlMapInner extends React.PureComponent<OlMapProps> {
	store: OlMapStore;

	constructor(props: OlMapProps) {
		super(props);
		this.store = new OlMapStore(props);
	}

	componentDidUpdate(prevProps: Readonly<OlMapProps>, prevState: Readonly<{}>, snapshot?: any): void {
		this.store.updateOverlays(this.props.overlays ?? []);
		this.store.updateCenter(this.props.center);
		this.store.updateZoom(this.props.zoom);
		if (JSON.stringify(prevProps.size) != JSON.stringify(this.props.size)) {
			this.store.refreshSize();
		}
	}

	componentWillUnmount() {
		this.store.destroy();
	}

	render() {
		return <div className={b('container')} ref={this.store.setMapContainer}></div>
	}
})
