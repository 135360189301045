import React, {CSSProperties} from "react";
import {observer} from "mobx-react"
import classnames from "classnames"

import {GridStore} from "controls/grid/gridStore"
import {GridColumn, RenderingResult} from "controls/grid/gridColumnConfig"
import {GridDataItem} from "controls/grid/gridDataItem"
import {addResizeListener} from "tools/detectElementResize";

const i = require('core/localization/localization').translator({
	'Loading': {

	}
});

type ItemRendererProps<DataItem extends GridDataItem> = {
	index: number
	style: CSSProperties
	data: GridStore<DataItem>
	isScrolling?: boolean | undefined
}

export class ItemRenderer<DataItem extends GridDataItem> extends React.PureComponent<ItemRendererProps<DataItem>> {
	render() {
		return <GridRowRenderer {...this.props}/>
	}
}

const b = require('b_').with('ceeview-grid')

const GridRowRenderer = observer(
	class GridRowRendererInner<DataItem extends GridDataItem> extends React.Component<ItemRendererProps<DataItem>> {

		get item(){
			return this.props.data.dataProvider.get(this.props.index)
		}

		get store(){
			return this.props.data
		}

		render() {
			const {index, data: store} = this.props


			//there is a very hackish algorithm to get real height of a row content
			//When user clicks on a row we remove CSS restrictions which prevents row from expanding and
			//and also ignoring row height provided by the plugin so the row expands to its real height
			//Then we receive an event that row size changed and update store.rowsCustomHeight dictionary with a real height of the row
			//and then apply this height on the row so the plugin can update scrollbar with a new value
			//There is a catch though - we fix row height on a first resize event. If size is changed for some reason again then a new click is required

			let styles = {...this.props.style}
			styles.width = store.columns.visibleWidth

			if (this.item && store.rowsCustomHeight[this.item.id] == -1) {
				delete styles.height
			}

			if (store.dataProvider.get(index) == undefined) {
				return <div className={b('row', {loading: true})}  style={styles}>
					{i('Loading...')}
				</div>
			} else {
				const classes = b('row', {
					loaded: true,
					selected: this.store.selection.isSelected(this.item),
					'free-size': store.rowsCustomHeight[this.item.id] !== undefined
				})

				return <div
					className={classes}
					style={styles}
					ref={this.elementSetRefCallback}>
					{store.columns.visible.map(c => <GridCellRenderer
						store={store}
						rowIndex={index}
						onClick={c.config.expandOnClick && this.startHeightCalculation}
						column={c}
						key={c.field}/>
					)}
				</div>
			}
		}

		elementSetRefCallback = (ref: HTMLDivElement) => {
			if (!ref) {
				return
			}

			addResizeListener(ref, () => {
				if(this.item == null)
					return

				this.store.updateRowHeight(this.item, this.props.index, ref)
			})
		}

		startHeightCalculation = () => {
			if(this.props.data.rowsCustomHeight[this.item.id]){
				delete this.props.data.rowsCustomHeight[this.item.id]
				this.store.listControl.resetAfterIndex(this.props.index)
			}else {
				this.props.data.rowsCustomHeight[this.item.id] = -1
			}
		}
	}
)

type GridCellRendererProps<DataItem extends GridDataItem> = {
	store: GridStore<DataItem>
	rowIndex: number
	column: GridColumn<DataItem>
	onClick: () => void
}

const GridCellRenderer = observer(<DataItem extends GridDataItem>(props: GridCellRendererProps<DataItem>) => {
	const {store, column, rowIndex} = props

	let renderingResult = getRenderingResult(
		() => column.config.renderer(store.dataProvider.get(rowIndex), {
			width: column.actualWidth
		})
	)

	const classes = b('cell', {
		nowrap: true,
		fixed: column.state.fixed ?? 'none',
		align: column.config.align,
		disabled: renderingResult.disabled
	})

	return <div className={classnames(classes, renderingResult.className, column.config.className)}
	            onClick={props.onClick}
	            style={column.styles}>
		{renderingResult.content}
	</div>
})

export function getRenderingResult(renderer: () => React.ReactNode | RenderingResult) : RenderingResult{
	const result = renderer()

	if(!isReactContent(result))
		return result

	return {
		content: result
	}
}

function isReactContent<DataItem>(result: React.ReactNode | RenderingResult): result is React.ReactNode{
	if(result === null)
		return true

	if(result === undefined){
		//to find it just hit a breakpoint here, then in  call stack get to GridCellRenderer and take a look at column.config
		console.warn('Looks like you forgot to return a result from a render callback')
	}

	// @ts-ignore
	return result.content === undefined
}
