import React from 'react';
import PropTypes from 'prop-types';
import {TableContext} from './constants';
import {ACTIONS, VARS} from './reducer';

function withSelection(WrappedComponent) {
	function WithSelectionComponent(props) {
		const {onSelect, defaultSelection, ...restProps} = props;
		const {state, dispatch} = React.useContext(TableContext);

		React.useEffect(() => {
			if(!_.isEqual(state[VARS.CHECKED_ROWS], defaultSelection))
				dispatch({ type: ACTIONS.SET_SELECTION, value: defaultSelection })
		}, [defaultSelection]);

		const onSelectRef = React.useRef();
		React.useEffect(() => onSelectRef.current = props.onSelect, [props.onSelect]);

		React.useEffect(() => {
			onSelectRef.current?.(state[VARS.CHECKED_ROWS], state[VARS.MANUAL_SELECTION]);
		}, [state[VARS.CHECKED_ROWS]]);

		return <WrappedComponent
			{...restProps} />
	}

	WithSelectionComponent.propTypes = {
		onSelect: PropTypes.func,
		defaultSelection: PropTypes.array
	}

	return WithSelectionComponent;
}

export default withSelection;
