import React from 'react';
import {FormEntry, TextBox, ActionButtons, TextArea} from 'controls/react/form';
import {Toolbar} from 'controls/react/layout/toolbar';
import {Section} from 'controls/react/layout/section';
import {Window} from 'controls/react/kendoWrappers/window';
import {translator} from "core/localization";
import Cookies from 'core/cookies';
import Utils from 'tools/utils';
import {addLinks} from "core/react/links";
import {AntSelect} from "controls/react/ant/antSelect";
import Api from 'tools/api';
import {ApplicationsApi} from "./api";
import {ApplicationsRouter} from "./bundleDescription";
import State from 'tools/state';
import {topLeftCornerPosition} from 'controls/modalWindow';
import CustomNotification from 'controls/customNotification';
import {TagsSelect} from "controls/react/tagsSelect";
import {ApplicationState} from "framework/applicationState";

let i = translator({
  "Create Application": {
    "no": "Opprett applikasjon"
  }
});

@addLinks
export default class NewApplicationWindow extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			teams : []
		}

		this.modalNotification = new CustomNotification({
			appendToElement: '.k-window-content',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
	}

	render(){
		const name = this.linkState('application.name');
		const description = this.linkState('application.description');
		const tags = this.linkState('application.tags');
		const teams = this.linkState('application.responsibleTeams');
		return (
			<Window title={i('Create Application')}
			        modal
					position={topLeftCornerPosition}
					onClose={this.props.onCancel}
			        width={400}>
				<Section childrenPadding={true}>
					<FormEntry label={i('Name')} valueLink={name.required()}>
						<TextBox {...name.props} placeholder={i('Please input Name...')}/>
					</FormEntry>
					<FormEntry label={i('Description')} vertical>
						<TextArea {...description.props}/>
					</FormEntry>
					<FormEntry label={i('Team')} valueLink={teams.required()}>
						<AntSelect dataList={this.state.teams}
								   mode={"multiple"}
								   sortValues={true}
								   placeholder={i('Select Team')}
								   defaultValue={[]}
								   {...teams.props}/>
					</FormEntry>
					<FormEntry label={i('Tags')} vertical>
						<TagsSelect
							mode={'tags'}
							accountId={ApplicationState.accountId}
							includeSubaccounts={false}
							onChange={value => tags.props.onChange(value)}
							defaultValue={tags.props.value ?? undefined}
							placeholder={lang.messages.TAG_CHOOSE} />
					</FormEntry>
				</Section>
				<Toolbar>
					<ActionButtons onSave={this.onSaveClicked}
					               saveDisabled={!this.canSave()}
					               onCancel={this.props.onCancel}/>
				</Toolbar>
			</Window>
		);
	}

	async componentDidMount() {
		let teams = await Api.fetch(Api.team.urls.list());
		this.linkState('application.responsibleTeams').update([]);
		this.setState({
			teams
		});
	}

	onSaveClicked = async () => {
		let nameExists = await Utils.checkIfNameExists(ApplicationsApi.getSearchUrl(), this.state.application?.name, this.modalNotification, false);
		if (nameExists) {
			return;
		}

		let application = JSON.parse(JSON.stringify(this.state.application));
		application.accountId = Cookies.CeesoftCurrentAccountId;
		application.assets = [];
		application.assetGroups = [];
		application.xml = "";

		const result = await ApplicationsApi.save(application);

		if (result.success) {
			State.mainApp.navigate(ApplicationsRouter.designer(result.data.id));
			this.props.onCancel?.();
		}
	}

	canSave = () => {
		return !this.linkState('application.name').invalid &&
			   !this.linkState('application.responsibleTeams').invalid;
	}
}
