import {GridDataItem} from "controls/grid/gridDataItem"
import {GridStore} from "controls/grid/gridStore"
import {GridState} from "controls/grid/gridState"
import {getSettings, saveSettings} from "framework/userSettings"
import {UserSettings} from "tools/userSettings";
import {copyViaSerializr} from "framework/serializr-integration";
import {serialize} from "serializr";

export interface  GridStateProvider<DataItem extends GridDataItem>{
	getState(store: GridStore<DataItem>) : Promise<GridState<DataItem>>
	saveState(state: GridState<DataItem>, store: GridStore<DataItem>): Promise<void>
}

type UserSettingsStateProviderProps = {
	category: string
}

export class UserSettingsStateProvider<DataItem extends GridDataItem> implements GridStateProvider<DataItem>{
	props: UserSettingsStateProviderProps

	constructor(props: UserSettingsStateProviderProps) {
		this.props = props
	}

	async getState(store: GridStore<DataItem>){
		if(!this.props.category)
			return null

		return await getSettings<GridState<DataItem>>("grid-settings-" + this.props.category, {
			clazz: GridState,
			trackable: false,
			ignoreCache: true
		})
	}

	async saveState(state: GridState<DataItem>, store: GridStore<DataItem>){
		await saveSettings("grid-settings-" + this.props.category, serialize(GridState<DataItem>, state))
	}
}

export class ExternalStateProvider<DataItem extends GridDataItem> implements GridStateProvider<DataItem>{
	state: GridState<DataItem>

	constructor(state: GridState<DataItem>) {
		this.state = state
	}

	async getState(store: GridStore<DataItem>){
		if(!this.state)
			return null

		return copyViaSerializr(this.state)
	}

	async saveState(state: GridState<DataItem>, store: GridStore<DataItem>){
		this.state = copyViaSerializr(state)
	}
}
