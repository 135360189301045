import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import LocalEventsManager from 'core/localEventsManager';
import Renderer from 'tools/renderer';
import GridMenu from 'controls/gridMenu';
import PreferencesPanel from 'controls/preferencesPanel';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import UserPrefs from 'tools/userPrefs';
import Application from 'core/application';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter'
import {SlaRouter} from 'areas/sla/bundleDescription';
import {ServicesRouter} from 'areas/services/bundleDescription';
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import {getServiceState} from 'controls/stateRenderer/serviceState';
import ServiceLogDetailsView from './logDetailsView'
import ModalWindow from 'controls/modalWindow';
import {translator} from "core/localization";
import {sharedLocalization} from "areas/services/localization"
import {NavigationStore} from "framework/navigationStore";
import ReactDOM from "react-dom";
import {AntSwitch} from "controls/react/ant/antSwitch";
import React from "react";

const translator = require('core/localization').translator(sharedLocalization, {
	'Server time': {},
	'Agent time': {},
	'Offset': {},
	'Show acknowledged': {
		no: 'Vis bekreftet'
	}
});

export default function LogsView(config) {
	// initializing data inside this class
	Utils.apply(this, config);
	this.module = {
		name: 'LogsView'
	};

	this.initComponent();
};

LogsView.prototype = {
	 /*
	 * @cfg {Object} renderTo The jQuery element where to render the component
	 * */
	initComponent: function () {
		this.removeListeners();
		this.attachListeners();
		this.loadUserPreferences();
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#cw_service_logs').off();
		$('#cw_service_qualifiers_states').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cw_service_logs').on('click', '.status_icon', $.proxy(this.onLogDetails, this));
		$('#cw_service_logs').on('click', '.cw_action_log_details', $.proxy(this.onLogDetails, this));

		$('#cw_service_logs').on('click', '.cw_log_status', $.proxy(this.onServiceLogRowClick, this));
		$('#cw_service_qualifiers_states').on('click', '.k-grid-content table tbody tr', $.proxy(this.onServiceQualifierStatesRowClick, this));

		$('#cw_service_logs').on('click', '.cw_grid_check', $.proxy(this.onLogsCheck, this));
		$('#cw_service_logs').on('click', '.cw_grid_check_all', $.proxy(this.onLogsCheckAll, this));
		$('#cw_reload_logs').on('click', $.proxy(this.onReloadNewLogs, this));

		$(window).on('resize', $.proxy(this.adjustGridsHeight, this));
	},
	/**
	 * Initializes kendo components
	 */
	initKendoComponents: function () {
		if( this.destroyed )
			return;

		this.menu = new GridMenu({
			renderTo: 'cw_logs_grid_menu',
			items: [{
				id: 'cw_service_detail_incident_create',
				icon: 'plus-sign',
				text: lang.incidents.CREATE_INCIDENT,
				fn: this.onCreateServiceIncident,
				role: 'INCIDENT_CREATE',
				scope: this,
				disabled: false
			}, {
				id: 'cw_logs_add_note',
				icon: 'plus-sign',
				text: lang.service.ADD_NOTE,
				fn: this.onAddServiceNote,
				role: 'SERVICE_UPDATE',
				scope: this,
				disabled: false
			}, {
				id: 'cw_logs_acknowledge',
				icon: 'check',
				text: lang.incidents.MARK_AS_SEEN,
				fn: this.onServiceLogAcknowledge,
				role: 'SERVICE_UPDATE',
				scope: this,
				disabled: true
			}, {
				id: 'cw_logs_acknowledge_all',
				icon: 'check',
				text: lang.incidents.MARK_ALL_AS_SEEN,
				fn: this.onServiceLogAcknowledgeAll,
				role: 'SERVICE_UPDATE',
				scope: this,
				disabled: true
			}]
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			status: {
				hidden: false,
				width: 45
			},
			message: {
				hidden: false,
				width: 500
			},
			time: {
				hidden: false,
				width: 200
			},
			detailType: {
				hidden: false,
				width: 80
			},
			acknowledgeAt: {
				hidden: false,
				width: 220
			},
			acknowledgedBy: {
				hidden: false,
				width: 140
			},
			description: {
				width: 200,
				hidden: false
			}
		};

		var filterMessages = lang.grid.filter;
		var serviceLogsColumns;
		var sort, filter;

		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			sort = pref.sort;
			filter = pref.filter;
			serviceLogsColumns = pref.columns;
		} else {
			if (UserPrefs.get('sort')) {
				sort = JSON.parse(UserPrefs.get('sort'));
			} else {
				sort = [{
					field: 'time',
					dir: 'desc',
					compare: null
				}];
			}
			if (UserPrefs.get('Filter')) {
				filter = JSON.parse(UserPrefs.get('Filter'));
			} else {
				filter = [];
			}
		}

		serviceLogsColumns = JSON.parse(UserPrefs.get('Columns')) || this.defaultColumns;
		serviceLogsColumns = Utils.completeColumns(serviceLogsColumns, this.defaultColumns);

		if (this.gridFilter) {
			filter = this.gridFilter;
		}
		this.createDataSource(sort, filter);
		var scope = this;
		this.grid = $('#cw_service_logs').kendoCustomGrid({
			autoBind: this.autoBind,
			dataSource: this.dataSource,
			cacheScrollCheckboxes: true,
			height: ($('#cw_service_details_tab_nav-6').height() - 45) || "100%",
			resizable: true,
			selectable: 'row',
			noRecords: {
				template: lang.service.messages.NO_LOGS
			},
			scrollable: {
				virtual: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			columnMenu: true,
			columns: [{
				field: 'id',
				title: lang.SELECTOR,
				menu: false,
				filterable: false,
				sortable: false,
				template: '<input type="checkbox" class="cw_grid_check" data-page="${page}" data-id="${notificationId}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: serviceLogsColumns.id.hidden,
				width: serviceLogsColumns.id.width
			}, {
				field: 'status',
				title: lang.widget.STATUS,
				headerTemplate: '<span class="cw_status_indicator empty hide"></span>',
				template: function (model) {
					var cssClass = '';
					var allowedItems = ['STATE', 'STATE_QUALIFIER', 'STATE_MODEL', 'STATE_ELEMENT'];
					if (allowedItems.indexOf(model.detailType) > -1) {
						cssClass = 'cw_log_status';
					}
					return '<span class="' + cssClass + ' pointer">' + Renderer.serviceLogStatus(model.status) + '</span>';
				},
				sortable: true,
				filterable: {
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						element.kendoDropDownList({
							dataTextField: 'text',
							dataValueField: 'value',
							change: Utils.onFilterDropDownChange,
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							template: '#=data.template# #=data.text#'
						}).data('kendoDropDownList');
						element.data('kendoDropDownList').setDataSource(Renderer.getLogTypeDS());
					}
				},
				hidden: serviceLogsColumns.status.hidden,
				width: serviceLogsColumns.status.width,
				attributes: {
					"class": "text_center"
				}
			}, {
				field: 'message',
				title: lang.service.MESSAGE,
				sortable: true,
				template: '<span>${message}</span>',
				attributes: {
					'class': 'tooltip ellipsis to_expand',
					'title': '#=message#'
				},
				hidden: serviceLogsColumns.message.hidden,
				width: serviceLogsColumns.message.width
			}, {
				field: 'time',
				title: lang.service.TIME,
				filterable: false,
				sortable: true,
				template: function(data){
					return Renderer.browserDateRenderer(data.time, Constants.DATETIME, "", scope.timezone);
				},
				attributes: {
					'class': 'cw_action_log_details pointer tooltip ellipsis to_expand',
					'title': '#=timeMouseover#'
				},
				hidden: serviceLogsColumns.time.hidden,
				width: serviceLogsColumns.time.width
			}, {
				field: 'detailType',
				title: lang.service.DETAIL_TYPE,
				template: '#= Renderer.serviceLogDetailType(detailType, hasAttachments)#',
				filterable: {
					extra: false,
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'detailType',
							grid: scope.grid,
							dataSource: [{
								text: lang.service.MESSAGE,
								icon: '<span class="glyphicons status_icon chat pointer"></span>',
								value: 'MESSAGE'
							}, {
								text: lang.service.MODEL_ERROR,
								icon: '<span class="glyphicons status_icon share-alt pointer"></span>',
								value: 'MODEL_ERROR'
							}, {
								text: lang.service.NOTE,
								icon: '<span class="glyphicons status_icon note pointer"></span>',
								value: 'NOTE'
							}, {
								text: lang.service.SERVICE_LOG,
								icon: '<span class="glyphicons status_icon sort pointer"></span>',
								value: 'SERVICE_LOG'
							}, {
								text: lang.service.SLA,
								icon: '<span class="glyphicons status_icon ok pointer"></span>',
								value: 'SLA'
							}, {
								text: lang.STATE,
								icon: '<span class="glyphicons status_icon lightbulb pointer"></span>',
								value: 'STATE'
							}, {
								text: lang.service.STATE_QUALIFIER,
								icon: '<span class="glyphicons status_icon cogwheel pointer"></span>',
								value: 'STATE_QUALIFIER'
							}, {
								text: lang.service.STATE_MODEL,
								icon: '<span class="glyphicons status_icon git-merge pointer"></span>',
								value: 'STATE_MODEL'
							}, {
								text: lang.service.STATE_ELEMENT,
								icon: '<span class="glyphicons status_icon vector-path-circle pointer"></span>',
								value: 'STATE_ELEMENT'
							}, {
								text: lang.SERVICE_LINK,
								icon: '<span class="glyphicons status_icon link pointer"></span>',
								value: 'STATE_LINK'
							}, {
								text: lang.service.SERVICE_INCIDENT,
								icon: '<span class="glyphicons status_icon construction-cone pointer"></span>',
								value: 'SERVICE_INCIDENT'
							}, {
								text: lang.service.SYSTEM_ERROR,
								icon: '<span class="glyphicons status_icon cogwheels pointer"></span>',
								value: 'SYSTEM_ERROR'
							}]
						});
					}
				},
				sortable: true,
				hidden: serviceLogsColumns.detailType.hidden,
				width: serviceLogsColumns.detailType.width,
				headerAttributes: {
					"class": "text_center"
				},
				attributes: {
					"class": "text_center pointer"
				}
			}, {
				field: 'acknowledgeAt',
				title: lang.service.ACKNOWLEDGED_DATE,
				filterable: false,
				template: '<span class="cw_action_log_details pointer"> #= Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME, null, this.timezone)#</span>',
				attributes: {
					'class': 'cw_action_log_details pointer tooltip ellipsis to_expand'
				},
				sortable: true,
				hidden: serviceLogsColumns.acknowledgeAt.hidden,
				width: serviceLogsColumns.acknowledgeAt.width
			}, {
				field: 'acknowledgedBy',
				title: lang.service.ACKNOWLEDGED_BY,
				sortable: true,
				attributes: {
					'class': 'cw_action_log_details pointer tooltip ellipsis to_expand'
				},
				hidden: serviceLogsColumns.acknowledgedBy.hidden,
				width: serviceLogsColumns.acknowledgedBy.width
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: true,
				attributes: {
					'class': 'tooltip ellipsis to_expand',
					'title': '#=description#'
				},
				hidden: serviceLogsColumns.description.hidden,
				width: serviceLogsColumns.description.width
			}],
			change: $.proxy(this.onGridExpand, this),
			dataBound: $.proxy(function (e) {
				$('#cw_reload_logs').addClass('hide');
				this.onDataBound(e);
				this.adjustGridsHeight();
			}, this)
		}).data('kendoCustomGrid');

		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		if (this.grid) {
			this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		}
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('SearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_servicelog_filters',
			grid: this.grid,
			modulePrefName: this.module.name + this.moduleSufix,
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'FSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['message', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});

		if (this.moduleSufix === 'Viewer') {
			//workaround for service logs window header in viewer to work together with filter panel
			$('.cw_view_button').attr('style', 'margin-top: -8px !important; height: 25px');
			$('.cw_filters_header').children().attr('style', 'margin-top: -8px !important; margin-bottom: 2px; height: 25px');
			$('.cw_filters_header').find('.k-dropdown-wrap').css('height', '25px')
		}

		ReactDOM.render(
			<AntSwitch defaultChecked={this.showAcknowledged}
			           size={"small"}
			           unCheckedChildren={translator('Show acknowledged')}
			           onChange={v => this.onShowAllLogsToggle(v)}
			           checkedChildren={translator('Show acknowledged')}/>,
			document.getElementById('cw_show_acknowledged'))
	},

	setShowAcknowledged() {
		this.showAcknowledged = true;
		for (let pref of this.userPref) {
			if (pref.key === 'showAcknowledged') {
				this.showAcknowledged = JSON.parse(pref.value);
			}
		}
	},

	/*
	 * Handler function for creating the datasource
	 * @param {Array} sort Kendo sort array
	 * @param {Array} filter Kendo filter array
	 * */
	createDataSource: function (sort, filter) {
		var self = this;
		var settings = this.getWidgetSettings();
		if (settings.period === 'CUSTOM') {
			settings.startDate = new Date(settings.startDate);
			settings.endDate = new Date(settings.endDate);
		}

		var parameterMap = function (data, type) {
			if (type === 'read') {
				if (data.filter && data.filter.filters) {
					data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
					var filters = data.filter.filters;
					for (var i = 0, length = filters.length; i < length; i++) {
						if (filters[i].field === 'status' && filters[i].value === 'MODEL_ERROR') {
							if (filters[i].operator === 'eq') {
								data.filter.filters[i].value = 'ERROR';
								data.filter.filters.push({
									field: 'detailType',
									operator: filters[i].operator,
									value: 'MODEL_ERROR'
								});
							} else {
								data.filter.filters[i].field = 'detailType';
							}
						} else if (filters[i].field === 'status' && filters[i].value === 'ERROR') {
							if (filters[i].operator === 'eq') {
								data.filter.filters.push({
									field: 'detailType',
									operator: 'neq',
									value: 'MODEL_ERROR'
								});
							} else {
								data.filter.filters[i] = {
									filters: [{
										field: 'status',
										operator: 'neq',
										value: 'ERROR'
									}, {
										filters: [{
											field: 'detailType',
											operator: 'neq',
											value: 'STATE'
										}, {
											field: 'detailType',
											operator: 'neq',
											value: 'NOTE'
										}],
										logic: 'or'
									}],
									logic: 'or'
								};
							}
						}
					}
				}
				return kendo.stringify(data);
			}
		};

		var period = this.calculatePeriod(settings.period || 'LASTDAY', settings);

		if (!this.dataSourceUrl) {
			var url = Settings.serverPath + 'services/' + this.serviceId + '/notifications/search/';
			if (settings.period && settings.period !== 'CUSTOM') {
				url += '?timeSelector=' + settings.period;
			} else {
				url += '?timeZone=' + Cookies.CeesoftTimezone + '&fromTime=' + period.startDate.getTime() + '&toTime=' + period.endDate.getTime();
			}
			this.dataSourceUrl = url;
		}

		if (this.dataSourceUrl.indexOf('unAcknowledgedOnly=false') !== -1) {
			this.dataSourceUrl = this.dataSourceUrl.replace('unAcknowledgedOnly=false', 'unAcknowledgedOnly=' + !this.showAcknowledged);
		} else if (this.dataSourceUrl.indexOf('unAcknowledgedOnly=true') !== -1) {
			this.dataSourceUrl = this.dataSourceUrl.replace('unAcknowledgedOnly=true', 'unAcknowledgedOnly=' + !this.showAcknowledged);
		} else {
			this.dataSourceUrl = this.dataSourceUrl + '&unAcknowledgedOnly=' + !this.showAcknowledged;
		}

		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
                    url: $.proxy(function () {
                        return this.dataSourceUrl;
                    }, this),
					contentType: "application/json; charset=utf-8",
					type: 'POST',
					dataType: 'json',
					cache: false
				},
				parameterMap: parameterMap
			},
			pageSize: 1000,
			sort: sort,
			filter: filter,
			schema: {
				data: $.proxy(function (response) {
					var items = response.items;
					var results = [];
					for (var i = 0; i < items.length; i++) {
						items[i].page = response.page;
						if (items[i].status === 'ERROR' && items[i].detailType === 'MODEL_ERROR') {
							items[i].status = 'MODEL_ERROR';
						}
						const shownTime = Renderer.browserDateRenderer(items[i].time, Constants.DATETIME, "", self.timezone);

						items[i].timeMouseover = '';
						if (items[i].timeOffset) {
							let agentTime = Renderer.browserDateRenderer(items[i].time + items[i].timeOffset, Constants.DATETIME, "", this.timezone);
							items[i].timeMouseover += translator('Server time') + ': ';
							items[i].timeMouseover += shownTime;
							items[i].timeMouseover += '\n';
							items[i].timeMouseover += translator('Agent time') + ': ';
							items[i].timeMouseover += agentTime;
							items[i].timeMouseover += '\n';
							items[i].timeMouseover += translator('Offset') + ': ';
							items[i].timeMouseover += items[i].timeOffset;
							items[i].timeMouseover += ' ms';
						}

						results.push(items[i]);
					}
					return results;
				}, this),
				total: function (response) {
					this.visibleItems = response.visible;
					this.totalItems = response.total;

					return response.visible;
				},
				model: {
					id: 'notificationId',
					fields: {
						status: {
							type: 'string'
						},
						message: {
							type: 'string'
						}
					}
				}
			},
			serverSorting: true,
			serverPaging: true,
			serverFiltering: true,
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Handler function for the Service logs grid data bound event
	 * @param {Object} e The databound event
	 */
	onDataBound: function (e) {
		var clickedElement, scrollTop = 0, selectElement = false;
		if( this.menu ) {
			if ($('#cw_service_logs').data('kendoCustomGrid').dataSource.data().length) {
				this.menu.enableItem('cw_logs_acknowledge_all');
			} else {
				this.menu.disableItem('cw_logs_acknowledge_all');
			}
		}
		if (this.highlightNotificationId) {
			//30 row height, 50 rows per page, logGridPage is page number
			if (this.logsGridPage > 1) {
				setTimeout($.proxy(function () {
					var rowHeight = e.sender.tbody.children().eq(0).height();
					var pageSize = e.sender.dataSource.pageSize();
					this.scrollTop = rowHeight * pageSize * (this.logsGridPage - 1);
					e.sender.wrapper.find(".k-scrollbar").scrollTop(this.scrollTop);
					this.logsGridPage = null;
				}, this), 200);
			} else {
				setTimeout($.proxy(function () {
					var row = e.sender.wrapper.find(".cw_grid_check[data-id='" + this.highlightNotificationId + "']").closest('tr');
					if (row.length) {
						row.removeClass('k-alt');
						this.grid.select(row);
						var top = row.position().top + e.sender.element.find('.k-scrollbar').scrollTop();
						e.sender.element.find(".k-grid-content").scrollTop(top);
					}

					this.highlightNotificationId = null;
					this.logsGridPage = null;
				}, this), 200);
			}
		}
	},
	/**
	 * Handler function for the selection of one service log grid row
	 * @param {Object} e The select event object
	 */
	onGridExpand: function (e) {
		//fix for losing click events in service details/logs view after coming back from redirect, might find a better solution for this
		$('#cw_service_logs').off();
		$('#cw_service_logs').on('click', '.status_icon', $.proxy(this.onLogDetails, this));
		$('#cw_service_logs').on('click', '.cw_action_log_details', $.proxy(this.onLogDetails, this));
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_service_logs').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/*
	 * Handler function for calculating the period
	 * @param {String} timeSelector
	 * @param {Object} period The period dates object
	 * */
	calculatePeriod: function (timeSelector, period) {
		var startDate, endDate;
		switch (timeSelector) {
			case 'LASTDAY':
				startDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
				endDate = new Date();
				endDate.setHours(23, 59, 59, 999);
				break;
			case 'LASTHOUR':
				startDate = new Date();
				startDate.setHours(startDate.getHours() - 1, startDate.getMinutes(), startDate.getSeconds(), 0);
				endDate = new Date();
				break;
			case 'LAST7DAYS':
				startDate = new Date();
				startDate.setDate(startDate.getDate() - 7);
				startDate.setHours(0, 0, 0, 0);
				endDate = new Date();
				endDate.setHours(23, 59, 59, 999);
				break;
			case 'LAST30DAYS':
				startDate = new Date();
				startDate.setDate(startDate.getDate() - 30);
				startDate.setHours(0, 0, 0, 0);
				endDate = new Date();
				endDate.setHours(23, 59, 59, 999);
				break;
			default:
				//CUSTOM
				startDate = period.startDate;
				endDate = period.endDate;
				break;
		}

		return {
			period: Utils.getPeriodInterval({
				period: timeSelector,
				width: 300,
				startDate: startDate,
				endDate: endDate
			}),
			startDate: startDate,
			endDate: endDate
		};
	},
	/*
	 * Handler function for reloading service logs grid
	 * @param {String} timeSelector
	 * @param {Object} dates The period dates object
	 * */
	reloadServiceLogs: function (timeSelector, dates) {
		var url = '';
		var period = this.calculatePeriod(timeSelector, {
			startDate: this.startDate || (dates && new Date(dates.startDate)) || null,
			endDate: this.endDate || (dates && new Date(dates.endDate)) || null
		});
		url = Settings.serverPath + 'services/' + this.serviceId + '/notifications/search';
		if (timeSelector && timeSelector !== 'CUSTOM') {
			url += '?timeSelector=' + timeSelector + '&unAcknowledgedOnly=' + !this.showAcknowledged;
		} else {
			url += '?timeZone=' + Cookies.CeesoftTimezone + '&fromTime=' + period.startDate.getTime() + '&toTime=' + period.endDate.getTime() + '&unAcknowledgedOnly=' + !this.showAcknowledged;
		}
		this.grid.dataSource.transport.options.read.url = url;
		this.dataSourceUrl = url;
		this.grid.dataSource.read();

	},
	/**
	 * Handler function for the service log show all toggle button
	 * @param {Object} e The click event object
	 */
	onLogDetails: function (e) {
		var type = '';
		var target = $(e.currentTarget);
		if (target.closest('.cw_log_status').length) {
			return;
		}
		e.stopPropagation();

		var item = target.closest('tr');
		var logUid = item.attr('data-uid');
		var record = this.dataSource.getByUid(logUid);

		/*
		 * Allow only model error to be clickable in Viewer
		 * */
		var allowedInViewer = ['MODEL_ERROR', 'SERVICE_INCIDENT', 'STATE_ELEMENT', 'STATE_QUALIFIER'];
		if (this.moduleSufix === 'Viewer' && allowedInViewer.indexOf(record.detailType) === -1) {
			record.detailType = '';
		}

		const settings = this.getWidgetSettings();
		NavigationStore.updateCurrentItem({
			logsGridPage: record.page,
			highlightNotificationId: record.notificationId,
			highlightWidgetSettings: settings
		} );


		switch (record.detailType) {
			case 'STATE_ELEMENT':
				var url = Settings.serverPath + 'accounts/' + this.accountId + '/services/' + this.serviceId + '/notifications/' + record.notificationId + '/details/' + record.detailType + '';
				Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
					if (result.success) {
						var data = result.data;
						if (this.moduleSufix === 'Viewer') {
							State.currentApp.highlightNotificationId = record.notificationId;
							State.currentApp.serviceElementId = data.elementId;
							State.currentApp.serviceQualifierId = null;
							State.currentApp.highlightState = null;
							this.highlightNotificationId = record.notificationId;
							State.currentApp.configuration = {
								parentModuleSufix: this.moduleSufix,
								period: 'CUSTOM',
								startDate: data.time - 1800000,
								endDate: data.time + 1800000
							};
                            $('.cw_smp_se_item').trigger('click');
						} else {
							State.mainApp.navigate(ServicesRouter.viewer(this.serviceId, {
								serviceElementId: data.elementId,
								highlightNotificationId: record.notificationId,
								isFromLogsView: true,
								configuration: {
									parentModuleSufix: this.moduleSufix,
									period: 'CUSTOM',
									startDate: data.time - 1800000,
									endDate: data.time + 1800000
								},
								timezone: this.timezone
							}))
						}
					} else {
						//Utils.showInfo(lang.ALERT, result.message, result.details);
					}
				}, this));
				break;
			case 'STATE_QUALIFIER':
				var url = Settings.serverPath + 'accounts/' + this.accountId + '/services/' + this.serviceId + '/notifications/' + record.notificationId + '/details/' + record.detailType + '';
				Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
					if (result.success) {
						var data = result.data;
						if (this.moduleSufix === 'Viewer') {
							State.currentApp.serviceQualifierId = data.qualifierId;
							State.currentApp.serviceElementId = data.elementId;
							State.currentApp.highlightNotificationId = record.notificationId;
							this.highlightNotificationId = record.notificationId;
							State.currentApp.configuration = {
								parentModuleSufix: this.moduleSufix,
								period: 'CUSTOM',
								startDate: data.time - 1800000,
								endDate: data.time + 1800000
							};
                            $('#smp_sq_el_' + data.qualifierId).trigger('click');
						} else {
							State.mainApp.navigate(ServicesRouter.viewer(this.serviceId, {
								serviceElementId: data.elementId,
								highlightNotificationId: record.notificationId,
								serviceQualifierId: data.qualifierId,
								isFromLogsView: true,
								configuration: {
									parentModuleSufix: this.moduleSufix,
									period: 'CUSTOM',
									startDate: data.time - 1800000,
									endDate: data.time + 1800000
								},
								timezone: this.timezone,
							}));
						}
					}
				}, this));
				break;
			case 'STATE_MODEL':
			case 'STATE_LINK':
				//root node
				var url = Settings.serverPath + 'accounts/' + this.accountId + '/services/' + this.serviceId + '/notifications/' + record.notificationId + '/details/' + record.detailType + '';
				Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
					if (result.success) {
						var data = result.data;
						let configuration = {
							period: 'CUSTOM',
							startDate: data.time - 1800000,
							endDate: data.time + 1800000
						}
						if(data.linkServiceId) {
							State.mainApp.navigate(ServicesRouter.viewer(data.linkServiceId, {configuration: configuration}));
						} else {
							configuration.parentModuleSufix = this.moduleSufix;
							State.mainApp.navigate(ServicesRouter.viewer(this.serviceId, {
								highlightRootNode: true,
								highlightNotificationId: record.notificationId,
								isFromLogsView: true,
								configuration: configuration,
								timezone: this.timezone,
							}));
						}
					} else {
						//Utils.showInfo(lang.ALERT, result.message, result.details);
					}
				}, this));
				break;
			case 'STATE':
				LocalEventsManager.trigger('serviceModelView');
				break;
			case 'SERVICE_INCIDENT':
				if (this.moduleSufix === 'Viewer') {

					if ($('li.cw_current_qualifier').length) {
						NavigationStore.updateCurrentItem({
							serviceQualifierId : $('.cw_current_qualifier').attr('id').replace('smp_sq_el_', '')
						});
					}

					NavigationStore.updateCurrentItem({
						serviceElementId: $('.cw_highlight_node').attr('id'),
						configuration: {
							parentModuleSufix: 'IncidentForm'
						}
					});
				}
				State.mainApp.navigate(IncidentsRouter.details(record.notificationId));

				break;
			case 'SLA':
				var url = Settings.serverPath + 'accounts/' + this.accountId + '/services/' + this.serviceId + '/notifications/' + record.notificationId + '/details/' + record.detailType + '';
				Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
					if (result.success) {
						var data = result.data;
						State.mainApp.navigate(SlaRouter.details(data.state.slaId));
					} else {
						//Utils.showInfo(lang.ALERT, result.message, result.details);
					}
				}, this));
				break;
			case 'MODEL_ERROR':
				if (this.moduleSufix === 'Viewer') {
					LocalEventsManager.trigger('setNotificationHighlightItem', record.notificationId);
				}
				const {offsetWidth, offsetHeight} = document.getElementById('content_area')
				const modalConfig = {
					serviceId: this.serviceId,
					serviceName: this.serviceName,
					notificationId: record.notificationId,
					type: record.detailType,
					status: record.status,
					message: record.message
				}
				const logDetailsWindow = new ModalWindow({
					title: lang.service.SERVICE_LOG_DETAILS,
					width: offsetWidth * 0.8,
					height: offsetHeight * 0.8,
					minHeight: 567,
					url: 'include/Service/LogDetailsView.jsp',
					refresh: function () {
						new ServiceLogDetailsView(
							{
								...modalConfig,
								closeWindow : () => {
									logDetailsWindow.close();
								}
							}
						);
					}
				});
				logDetailsWindow.open();
				break;
			case 'NOTE':
			case 'MESSAGE':
				this.serviceNoteWindowId = Utils.guid();
				$('body').append('<div id="' + this.serviceNoteWindowId + '"></div>');

				var window = $('#' + this.serviceNoteWindowId).kendoWindow({
					width: '500px',
					title: translator('Log entry'),
					modal: true,
					visible: false,
					close: $.proxy(function () {
						$('#' + this.serviceNoteWindowId).data("kendoWindow").destroy();
					}, this)
				}).data('kendoWindow');
				var html = '<div class="cw_service_note">';
				html += '<div class="form">';
				html += '<p><textarea id="cw_service_note_message" class="w100" readonly>' + record.message + '</textarea></p>';
				html += '</div>';
				html += '</div>';

				window.content(html);
				window.open().center();
				$(document).off('click', '.k-overlay', $.proxy(this.onCancelServiceNote, this));
				$(document).on("click", '.k-overlay', $.proxy(this.onCancelServiceNote, this));
				break;
		}
	},
	/*
	 * Handler function for service states row click
	 * @param {Object} e The click event object
	 * */
	onServiceLogRowClick: function (e) {
		var target = $(e.currentTarget)
		var id = target.closest('tr').find('.cw_grid_check').data('id'), uid = target.closest('tr').data('uid');
		var item = this.grid.dataSource.getByUid(uid);

		var filterMessages = lang.grid.filter;
		//k-window-title
		if (this.moduleSufix === 'Viewer') {
			target.closest('.k-window').find('.k-window-title').html('<span class="cw_service_state_breadcrumb pointer">' + lang.service.SERVICE_LOG + '</span> - ' + lang.summary.SERVICE_QUALIFIER_STATE);
			target.closest('.k-window').find('.k-window-title').off().on('click', '.cw_service_state_breadcrumb', $.proxy(this.onServiceStateBreadcrumbClick, this));
		} else {
			this.gridFilterPanel.enable(false);
			target.closest('.cw_section').find('.cw_section_label').html('<span class="cw_service_state_breadcrumb pointer">' + lang.service.SERVICE_LOG + '</span> - ' + lang.summary.SERVICE_QUALIFIER_STATE);
			target.closest('.cw_section').find('.cw_section_label').off().on('click', '.cw_service_state_breadcrumb', $.proxy(this.onServiceStateBreadcrumbClick, this));
		}
		$('#cw_service_logs').addClass('hide');
		$('#cw_service_qualifiers_states').removeClass('hide');

		this.stateFromTime = item.time;
		this.stateToTime = item.time;
		this.itemState = item.status === 'OK';

		$('.cw_service_state_breadcrumb').attr('data-id', id);

		if (this.serviceQualifierStatesGrid) {
			this.serviceQualifierStatesGrid.dataSource.read();
		} else {
			this.serviceQualifierStatesGrid = $('#cw_service_qualifiers_states').kendoCustomGrid({
				dataSource: new kendo.ceeview.DataSource({
					transport: {
						read: {
							url: $.proxy(function () {
								return Settings.serverPath + 'services/' + this.serviceId + '/statehistory/qualifierBreaches?fromTime=' + this.stateFromTime + '&toTime=' + this.stateToTime + '&state=' + this.itemState + '';
							}, this),
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					schema: {
						model: {
							id: 'id',
							fields: {
								state: {
									type: 'boolean'
								},
								name: {
									type: 'string'
								},
								elementName: {
									type: 'string'
								},
								time: {
									type: 'number'
								},
								cause: {
									type: 'string'
								},
								description: {
									type: 'string'
								}
							}
						}
					}
				}),
				resizable: true,
				selectable: 'row',
				sortable: {
					mode: "multiple",
					allowUnsort: true
				},
				filterable: {
					extra: false,
					operators: {
						string: {
							startswith: filterMessages.STARTS_WITH,
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						},
						number: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ,
							gte: filterMessages.GTE,
							gt: filterMessages.GT,
							lte: filterMessages.LTE,
							lt: filterMessages.LT
						},
						date: {
							eq: filterMessages.EQ,
							gte: filterMessages.IAE,
							gt: filterMessages.IA,
							lte: filterMessages.IBE,
							lt: filterMessages.IB
						}
					},
					messages: this.gridMessages
				},
				columns: [{
					field: 'state',
					//template: '#= Renderer.summaryState(state ? 2 : 0) #',
					template: item => getServiceState(item.state ? 2 : 0),
					title: lang.STATE,
					sortable: true,
					filterable: {
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: lang.DOWN,
									value: '0'
								}, {
									text: lang.UP,
									value: '2'
								}],
								dataTextField: 'text',
								dataValueField: 'value',
								optionLabel: lang.grid.FILTER_SELECT_VALUE,
								change: Utils.onFilterDropDownChange,
								//template: '#=Renderer.summaryState(value, text)#'
								template: item => getServiceState(item.value, item.text),
							});
						}
					},
					width: 40
				}, {
					field: 'name',
					template: '<span data-id="#= id#">#= name#</span>',
					title: lang.NAME,
					sortable: true,
					filterable: true,
					width: 300,
					attributes: {
						'class': 'expand ellipsis'
					}
				}, {
					field: 'elementName',
					title: lang.designer.SERVICE_ELEMENT,
					sortable: true,
					filterable: true,
					width: 200,
					attributes: {
						'class': 'expand ellipsis'
					}
				}, {
					field: 'time',
					title: 'Breach time',
					template: '#= Renderer.browserDateRenderer(time, "datetime", "", "' + this.timezone + '")#',
					sortable: true,
					filterable: {
						ui: function (el) {
							el.kendoDateTimePicker({
								format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
								timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
							});
						}
					},
					width: 150
				}, {
					field: 'cause',
					title: lang.service.CAUSE,
					template: '<span class="cw_tooltip">#= cause || ""#</span>',
					sortable: true,
					filterable: true,
					width: 200,
					attributes: {
						'class': 'expand ellipsis'
					}
				}, {
					field: 'description',
					title: lang.DESCRIPTION,
					template: '#= "<span class=\'cw_tooltip\'>" + description + "</span>" || ""#',
					sortable: true,
					filterable: true,
					width: 200,
					attributes: {
						'class': 'expand ellipsis'
					}
				}],
				columnMenu: true,
				dataBound: $.proxy(this.onServiceQualifiersStatesDataBound, this),
				change: $.proxy(this.onGridExpand, this),
			}).data('kendoCustomGrid');
			// Add Kendo tooltip to the header of the columns
			Utils.gridColumnHeaderTooltip(this.serviceQualifierStatesGrid);

			this.columnTooltip = $("#cw_service_qualifiers_states").kendoTooltip({
				filter: ".cw_tooltip",
				content: function (e) {
					return $(e.target).text();
				}
			}).data("kendoTooltip");
		}
	},
	/**
	 * Handler function for the grid dataBaound event
	 */
	onServiceQualifiersStatesDataBound: function () {
		if (this.highlightSubItemId) {
			this.highlightServiceQualifierStatesItem(this.highlightSubItemId);
			this.highlightSubItemId = null;
		}

		$('#cw_service_qualifiers_states').find('.k-grid-content table tbody tr').addClass('pointer');
	},
	/*
	 * Handler function for highlighting service state item
	 * @param {Number} itemId The element unique id
	 */
	highlightServiceQualifierStatesItem: function (itemId) {
		var row = $('#cw_service_qualifiers_states').find('span[data-id="' + itemId + '"]').closest('tr');
		row.removeClass('k-alt');
		var rowPos = row.position();
		$('#cw_service_qualifiers_states').data('kendoCustomGrid').select(row);
		$('.cw_servicedetails_data').find('.k-content.k-active').scrollTop(rowPos.top);
	},
	/*
	 * Handler function for service qualifier states row click
	 * @param {Object} e The click event object
	 * */
	onServiceQualifierStatesRowClick: function (e) {
		var uid = $(e.currentTarget).closest('tr').data('uid');
		var item = this.serviceQualifierStatesGrid.dataSource.getByUid(uid);


		var settings = this.getWidgetSettings();

		NavigationStore.updateCurrentItem({
			highlightNotificationId: null,
			highlightItemId: $('.cw_service_state_breadcrumb').data('id'),
			highlightSubItemId: item.id,
			highlightWidgetSettings: settings
		});

		State.mainApp.navigate(ServicesRouter.viewer(item.serviceId,{
			serviceElementId: item.elementId,
			serviceQualifierId: item.id,
			configuration: {
				period: 'CUSTOM',
				startDate: item.time - 1800000,
				endDate: item.time + 1800000
			},
			timezone: this.timezone,
		}));
	},
	/*
	 * Handler function for service state breadcrumb click
	 * @param {Object} e The click event object
	 * */
	onServiceStateBreadcrumbClick: function (e) {
		if (this.moduleSufix === 'Viewer') {
			$(e.currentTarget).closest('.k-window').find('.k-window-title').text(lang.service.SERVICE_LOG);
		} else {
			this.gridFilterPanel.enable(true);
			$('#cw_logs_grid_menu').removeClass('hide');
			$(e.currentTarget).closest('.cw_section_label').text(lang.service.SERVICE_LOG);
		}
		$('#cw_service_logs').removeClass('hide');
		$('#cw_service_qualifiers_states').addClass('hide');
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		Application.prototype.saveUserPrefs.call(this, {
			category: this.module.name + this.moduleSufix,
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			keys: {
				searchPhrase: 'SearchPhrase',
				columns: 'Columns',
				sort: 'Sort',
				filter: 'Filter',
				FsViews: 'FSViews',
				showAcknowledged: this.showAcknowledged
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load(this.module.name + this.moduleSufix, $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.setShowAcknowledged();
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the click event on the create incident menu item
	 */
	onCreateServiceIncident: function (e) {
		var serviceLogs = [], accountList = [{
			id: this.accountId,
			name: this.accountName
		}];

		var checkboxes = $('#cw_service_logs .cw_grid_check:checked'), uid, item;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			uid = $(checkboxes[i]).closest('tr').data('uid');
			item = this.grid.dataSource.getByUid(uid);
			serviceLogs.push(item);
		}

		State.mainApp.navigate(IncidentsRouter.createNew('SERVICE'), {
			extra: {
				serviceId: this.serviceId,
				serviceName: this.serviceName,
				accountList,
				serviceLogs
			}
		});
	},
	/**
	 * Handler function for the click event on Add Service Note button
	 * @param {Object} e The click event
	 */
	onAddServiceNote: function (e) {
		this.serviceNoteWindowId = Utils.guid();
		$('body').append('<div id="' + this.serviceNoteWindowId + '"></div>');

		var window = $('#' + this.serviceNoteWindowId).kendoWindow({
			width: '400px',
			height: '400px',
			minHeight: 400,
			title: translator('Create Log Entry'),
			modal: true,
			visible: false,
			close: $.proxy(function () {
				$('#' + this.serviceNoteWindowId).data("kendoWindow").destroy();
			}, this)
		}).data('kendoWindow');
		let html = '<div class="cw_service_note form">';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + 'Status' + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_service_note_status" type="text" class="" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_comment_wrapper">';
		html += '<textarea id="cw_service_note_message" placeholder="' + lang.service.messages.ENTER_LOG_ENTRY + '" class="cw_comment_text"></textarea>';
		html += '</div>';
		html += '</div>';
		html += '<div class="status"><p></p></div>';
		html += '<div class="actions absolute">';
		html += '<button class="k-button right" id="cancel_service_note">' + lang.CANCEL + '</button>';
		html += '<button class="k-button k-primary go_right" id="save_service_note" disabled>' + lang.CREATE + '</button>';
		html += '</div>';
		window.content(html);
		window.open().center();
		$('#cw_service_note_status').kendoDropDownList({
			dataSource: [{
				id: 'OK',
				text: lang.OK
			}, {
				id: 'ERROR',
				text: lang.ERROR
			}, {
				id: 'WARNING',
				text: lang.WARNING
			}, {
				id: 'FAILED',
				text: lang.FAILED
			}, {
				id: 'UNKNOWN',
				text: lang.UNKNOWN
			}],
			dataTextField: 'text',
			dataValueField: 'id'
		});
		$('#cw_service_note_message').on('keyup', $.proxy(this.onNoteChange, this));
		$('#save_service_note').off();
		$('#save_service_note').on('click', $.proxy(this.onSaveServiceNote, this));
		$('#cancel_service_note').off();
		$('#cancel_service_note').on('click', $.proxy(this.onCancelServiceNote, this));
	},
	onNoteChange: function (e) {
		var text = $(e.target).val();
		if (text) {
			$('#save_service_note').attr('disabled', false);
		} else {
			$('#save_service_note').attr('disabled', true);
		}
	},
	/**
	 * Handler function for the click event on Save Service Note button
	 * @param {Object} e The click event
	 */
	onSaveServiceNote: function (e) {
		var message = $('#cw_service_note_message').val();
		var state = $('#cw_service_note_status').data('kendoDropDownList').value();
		if (message) {
			var data = {
				message: message
			};
			var url = Settings.serverPath + 'services/' + this.serviceId + '/notifications/?status=' + state;
			Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
				if (result.success) {
					this.grid.dataSource.read();
					this.onCancelServiceNote();
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		} else {
			var status = $('.cw_service_note').find('.status');
			status.find('p').text(lang.service.messages.ENTER_MESSAGE);
			status.find('p').addClass('error');
			status.slideDown().delay(4000).slideUp();
		}
	},
	/**
	 * Handler function for the click event on Save Service Note button
	 * @param {Object} e The click event
	 */
	onCancelServiceNote: function (e) {
		var win = $('#' + this.serviceNoteWindowId).data("kendoWindow");
		if (win) {
			win.close();
			win.destroy();
		}
	},
	/**
	 * Handler function for the Service log acknowledge toggle
	 * @param {Object} e The click event
	 */
	onServiceLogAcknowledge: function (e) {
		//should be handled with components help
		var checkboxes = $('#cw_service_logs').find('.cw_grid_check');
		var grid = $('#cw_service_logs').data('kendoCustomGrid');
		var url = Settings.serverPath + 'services/' + this.serviceId + '/notifications/acknowledge';
		var logs = [];
		this.currentLogsPage = grid.dataSource.page();
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				logs.push($(checkboxes[i]).data('id'));
			}
		}
		if (logs.length) {
			this.menu.disableItem('cw_logs_acknowledge');
			Utils.ajax(url, 'POST', JSON.stringify(logs), $.proxy(function (result) {
				if (result.success) {
					this.reloadLogsPage = true;
					grid.dataSource.read();
					$('#cw_service_logs').find('.cw_grid_check_all').prop('checked', false);
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		}
	},
	/**
	 * Handler function for the Service log acknowledge toggle
	 * @param {Object} e The click event
	 */
	onServiceLogAcknowledgeAll: function (e) {
		//should be handled with components help
		var grid = $('#cw_service_logs').data('kendoCustomGrid');
		var url = Settings.serverPath + 'services/' + this.serviceId + '/notifications/acknowledgeAll';
		this.currentLogsPage = grid.dataSource.page();

		this.menu.disableItem('cw_logs_acknowledge');
		Utils.ajax(url, 'POST', {}, $.proxy(function (result) {
			if (result.success) {
				this.reloadLogsPage = true;
				grid.dataSource.read();
				$('#cw_service_logs').find('.cw_grid_check_all').prop('checked', false);
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},

	onShowAllLogsToggle: function (value) {
		let currentUrl = this.dataSourceUrl;
		let url = '';

		this.showAcknowledged = value

		if (currentUrl.indexOf('unAcknowledgedOnly=false') !== -1) {
			url = currentUrl.replace('unAcknowledgedOnly=false', 'unAcknowledgedOnly=' + !this.showAcknowledged);
		} else if (currentUrl.indexOf('unAcknowledgedOnly=true') !== -1) {
			url = currentUrl.replace('unAcknowledgedOnly=true', 'unAcknowledgedOnly=' + !this.showAcknowledged);
		} else {
			url = currentUrl + '&unAcknowledgedOnly=' + !this.showAcknowledged;
		}

		this.grid.dataSource.options.transport.read.url = url;
		this.grid.dataSource.read();

		$('#cw_show_acknowledged').toggleClass('is_selected');
	},
	/**
	 * Handler for the logs checkbox click
	 * @param {Object} e The click event
	 */
	onLogsCheck: function (e) {
		e.stopPropagation();

		//should be handled with components help
		var checkboxes = $('#cw_service_logs').find('.cw_grid_check');
		var logs = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				logs.push(i);
			}
		}
		if (logs.length) {
			this.menu.enableItem('cw_logs_acknowledge');
		} else {
			this.menu.disableItem('cw_logs_acknowledge');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onLogsCheckAll: function (e) {
		//should be handled with components help
		var checkAll = $('#cw_service_logs').find('.cw_grid_check_all').is(':checked');
		var checkboxes = $('#cw_service_logs').find('.cw_grid_check');
		var i, length = checkboxes.length;
		for (let i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.menu.enableItem('cw_logs_acknowledge');
		} else {
			this.menu.disableItem('cw_logs_acknowledge');
		}
	},
	onReloadNewLogs: function () {
		this.grid.dataSource.read();
	},
	/*
	 * Handler function for adjusting tab grids height
	 * */
	adjustGridsHeight: function () {
		// if (this.moduleSufix === 'Viewer') {
		// 	var section = $('#cw_service_logs').parent();
		// 	var sectionHeight = section.outerHeight();
		// 	section.find('.cw_section_content').css('height', sectionHeight);
		// 	section.find('.k-grid-content').css('height', sectionHeight - 35);
		// } else {
		// 	var section = $('.cw_tier_bottom .cw_servicelog_section');
		// 	var sectionHeight = $('.cw_tabs_absolute').height() - 40 - 160 - 320;
		// 	section.find('.cw_section_content').css('height', sectionHeight - 40);
		// 	section.find('.k-grid-content').css('height', sectionHeight - 40 - 35);
		// 	this.adjustGridsHeight = function () {
		// 	};
		// }
	},
	/*
	 * Handler function for getting the widget settings
	 * */
	getWidgetSettings: function () {
		var highlightWidgetSettings = {};
		LocalEventsManager.trigger('getActiveWidgetSettings', highlightWidgetSettings);

		return {
			period: highlightWidgetSettings.period,
			startDate: highlightWidgetSettings.startDate,
			endDate: highlightWidgetSettings.endDate
		};
	},
	/*
	 * Handler function for resizing the grid
	 * */
	onResize: function () {
		this.refresh();
	},
	/*
	 * Handler function for refreshing the component
	 * */
	refresh: function () {
		this.grid.dataSource.read();
	},
	toggle(){
		if( this.isVisible() )
			this.hide();
		else
			this.show();
	},

	show(){
		$('#cw_service_logs').parent().parent().removeClass('hide');
	},

	hide(){
		$('#cw_service_logs').parent().parent().addClass('hide');
	},

	isVisible(){
		return !$('#cw_service_logs').parent().parent().hasClass('hide');
	},
	/**
	 * Destroy the component
	 */
	destroy: function () {
		this.saveUserPreferences();
		this.destroyed = true;
	}
};
