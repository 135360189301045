import './antModal.less'

import React, {useCallback, useState} from 'react';
import Modal, {ModalProps} from 'antd/lib/modal';
import {Button} from "antd"
import {observer} from 'mobx-react'
import ReactDOM from 'react-dom'
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';

import {State} from 'tools'
import {topLeftCornerPosition} from "controls/modalWindow";

const i = require('core/localization').translator();

export enum ModalPosition {
	Centered = 'centered',
	TopLeft = 'topLeft',
	Default = 'default',
	TopRight = 'topRight',
	Mouse = 'mouse'
}

interface AntModalProps extends Omit<ModalProps, 'centered'> {
	mode?: 'update' | 'create'
	positionType?: ModalPosition
	width?: number
	draggable?: boolean
	height?: number
	draggableHandle?: string
	draggableExclusion?: string
}


let mouseCurrentPosition = {
	left: 0,
	top: 0
}

document.addEventListener('mousemove', e => {
	mouseCurrentPosition.left = e.x
	mouseCurrentPosition.top = e.y
})

const defaultAntModalWidth = 520
const b = require('b_').with('ant-modal-body')

export const AntModal: React.FunctionComponent<AntModalProps> = observer(props => {
	let props1 = useHeight(props);

	let {mode, style, width,
		positionType, children, mask, draggable, draggableHandle, draggableExclusion, ...others} = props1;

	draggable ??= true;
	draggableHandle ??= '.ant-modal-header';
	draggableExclusion ??= 'input';

	positionType ??= ModalPosition.Default

	const draggableRef = React.useRef<HTMLDivElement>(null);

	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

	let styleMerged = React.useMemo(() => {
		if (positionType == ModalPosition.Default || positionType == ModalPosition.Centered) {
			return style
		}

		let result: React.CSSProperties = {
			...(style ?? {})
		}

		if(positionType == ModalPosition.TopLeft) {
			result.top = 50
			result.left = 100
		}else if(positionType == ModalPosition.TopRight){
			result.top = 50
			result.left = window.innerWidth - 100 - (width ?? defaultAntModalWidth)
		}else if( positionType == ModalPosition.Mouse){
			result.top = mouseCurrentPosition.top
			result.left = mouseCurrentPosition.left
		}

		result.margin = '0'

		return result
	}, [width, style, positionType]);

	const onStart = useCallback((_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggableRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}

		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	}, []);

	if (others.okText === undefined) {
		others.okText = mode == 'update' ? i('Update') : i('Create');
	}

	if (others.footer === undefined) {
		//ant modal comes with cancel/ok buttons but we use ok/cancel in the app so we swap them here
		others.footer = [
			<Button onClick={props.onOk} type={'primary'} key={'ok'} {...props.okButtonProps}>{others.okText}</Button>,
			<Button onClick={props.onCancel} key={'cancel'} {...props.cancelButtonProps}>{i('Cancel')}</Button>
		]
	}

	const modalRender = React.useMemo(() => {
		if (draggable) {
			return (modal: React.ReactNode) => {
				return <Draggable handle={draggableHandle} bounds={bounds} cancel={draggableExclusion}
				                  onStart={(event, uiData) => onStart(event, uiData)}>
					<div ref={draggableRef}>{modal}</div>
				</Draggable>
			}
		} else {
			return null;
		}
	},[draggable, bounds]);

	const classNames = b('wrapper', {draggable: draggable});

	return <Modal
		{...others}
		style={styleMerged}
		centered={positionType == ModalPosition.Centered}
		mask={draggable && mask === undefined ? false : mask}
		modalRender={modalRender}
		width={width}>
		<div className={classNames}>
			{children}
		</div>
	</Modal>
});

type OpenModalProps = Omit<AntModalProps, 'visible'>

type UseHeightProps = {
	height?: number
	bodyStyle?: React.CSSProperties
}

export function useHeight<T extends UseHeightProps>(props: T) {
	const {height, bodyStyle, ...rest} = props

	let bodyStyleMerged = React.useMemo(() => {
		if (height == null) {
			return bodyStyle
		}

		let result: React.CSSProperties = {
			...(bodyStyle ?? {})
		}

		result.height = height + 'px'

		return result
	}, [bodyStyle, height])

	return {
		bodyStyle: bodyStyleMerged,
		...rest
	}
}

export function openModal(props: OpenModalProps, content: React.ReactNode) {
	const [container, resolveWrapper] = createContainer();
	const {onCancel, onOk, ...restProps} = props;

	const onCancelWrapper = (e: React.MouseEvent<HTMLElement>) => {
		resolveWrapper();
		onCancel?.(e);
	}

	const onOkWrapper = (e: React.MouseEvent<HTMLElement>) => {
		resolveWrapper();
		onOk?.(e);
	}

	ReactDOM.render(<AntModal onOk={onOkWrapper}
							  onCancel={onCancelWrapper}
							  visible={true}
							  {...restProps}>
		{content}
	</AntModal>, container)

	State.mainApp.registerForOnNavigate(() => {
		resolveWrapper();
	});
}

export function createContainer() {
	const container = document.createElement('div')
	document.body.appendChild(container)

	const unmount = () => {
		ReactDOM.unmountComponentAtNode(container)
	}
	return [container, unmount] as const
}
