import {makeAutoObservable} from 'mobx';
import React from 'react';
import {apiFetch} from 'framework/api';
import {getTagList, getUserTags, saveTags} from './api';
import {SaveTagsModel} from './tagsModel';
import {State} from 'tools';
import { ApplicationState } from 'framework/applicationState';
import {stubFalse} from "lodash";

export class UserTagsStore {
	displayWindow: boolean;
	mode: 'user' | 'team' = 'user';
	tagList: {label: string, value: string}[] = [];
	model: SaveTagsModel;

	constructor() {
		makeAutoObservable(this);
	}

	get dirty(): boolean {
		return this.model?.dirty ?? false;
	}

	get active(): boolean {
		return !this.model?.ignoreTags ?? false;
	}

	onOk = async () => {
		const result = await apiFetch(saveTags(this.model));
		if (result.success) {
			this.onWindowToggle()

			ApplicationState.showUntagged = this.model.showUntagged
			ApplicationState.ignoreTags = this.model.ignoreTags
			ApplicationState.userTags = this.model.tags

			if (State.currentApp.reloadByTags) {
				State.currentApp.reloadByTags();
			}
		}
	}

	onCancel = () => {
		this.onWindowToggle();
	}

	init = async () => {
		const tagsResult = await apiFetch(getUserTags());
		if (tagsResult.success) {
			this.model = new SaveTagsModel(tagsResult.data.tags, tagsResult.data.showUntagged, tagsResult.data.ignoreTags);

			ApplicationState.showUntagged = this.model.showUntagged ?? false
			ApplicationState.ignoreTags = this.model.ignoreTags ?? false
			ApplicationState.userTags = this.model.tags ?? []

			if (tagsResult.data.forceTeamTags) {
				this.mode = 'team';
			}
		}
		const tagList = await apiFetch(getTagList());
		if (tagList.success) {
			this.tagList = tagList.data?.map(x => ({label: x, value: x}));
		}
	}

	changeActive = () => {
		this.model.ignoreTags = !this.model.ignoreTags
	}

	onWindowToggle = () => {
		this.displayWindow = !this.displayWindow;
	}

	onChangeUntagged = (value: boolean) => {
		this.model.showUntagged = value
	}

	onUserTagsChanged = (value: string[]) => {
		this.model.tags = value;
	}
}
export const UserTagsContext = React.createContext<UserTagsStore>(null);
