export enum Permission{
	AggregatedManegementAgentRead = 'AGGR_MGMTAGENT_READ',
	AggregatedServiceRead = 'AGGR_SERVICE_READ',
	AggregatedHealthAssetRead = 'AGGR_HEALTHASSET_READ',
	AggregatedHealthMonitorRead = 'AGGR_HEALTHMONITOR_READ',

	AgentRead = 'AGENT_READ',
	AgentUpdate = 'AGENT_UPDATE',

	ServiceModelUpdate = 'SERVICE_MODEL_UPDATE',
	ServiceUpdate = 'SERVICE_UPDATE',
	ServiceMaintenanceUpdate = 'SERVICE_MAINTENANCE_CREATE'
}
