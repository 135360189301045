import './toolbar.less'

import classes from 'classnames';
import React, {FunctionComponent, ReactNodeArray, ReactElement} from 'react';

import MenuButton from "../menuButton";

import {Menu} from "antd";

let b = require('b_').with('toolbar');

export interface ToolbarProps {
	title?: React.ReactNode
	containerClass?: string,
	margin?: ToolbarMargin,
	appearance?: "transparent" | 'elements-on-line' | 'section-header',
	containerStyle?: React.CSSProperties
	style?: 'default' | 'dark'
	children?: React.ReactNode
	onClick?: () => void
}

export const Toolbar:FunctionComponent<ToolbarProps> = (props) => {
	let {beforeTitle, afterTitle, atTheEnd} = sourtOutChildren(props)
	let containerRef = React.useRef<HTMLDivElement>()

	let modifiers = {
		appearance: props.appearance,
		margin: props.margin,
		style: props.style ?? 'default',
		clickable: props.onClick != null
	}

	const onClick = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
		if(e.target == containerRef.current){
			props.onClick?.()
		}
	}, [props.onClick])

	return (
		<div className={classes(b(modifiers), props.containerClass)}
		     style={props.containerStyle} onClick={onClick} ref={containerRef}>
			{beforeTitle.map( (child,i) =>
				<ToolbarEntry key={i}>{child}</ToolbarEntry>
			)}
			{props.title && <ToolbarEntry title>{props.title}</ToolbarEntry>}
			{afterTitle.map( (child,i) =>
				<ToolbarEntry key={i}>{child}</ToolbarEntry>
			)}
			<ToolbarEntry spacer/>
			{atTheEnd.map( (child,i) =>
				<ToolbarEntry key={i}>{child}</ToolbarEntry>
			)}
		</div>
	);
}
interface ToolbarEntryProps {
	title?: boolean,
	spacer?: boolean
	children?: React.ReactNode
}

const ToolbarEntry:FunctionComponent<ToolbarEntryProps> = props => {
	let children = React.Children.toArray(props.children);
	let firstChild = (children.length > 0 ? children[0] : null) as ReactElement;
	let isToolbarSet = firstChild != null && firstChild.type == ToolbarItemsSet;

	return <div className={b('entry', {title: props.title, spacer: props.spacer, set: isToolbarSet})}>{props.children}</div>
}

interface ToolbarItemsSetProps{
	noPadding: boolean,
	children: ReactNodeArray
}

export const ToolbarItemsSet:FunctionComponent<ToolbarItemsSetProps> = props => {
	if(!props.children)
		return null;

	return (
		<>
			{React.Children.map(props.children, ( (child,i) =>
				<div key={i} className={b('set-entry', {'no-padding': props.noPadding} )}>{child}</div>
			))}
		</>)
}

export enum ToolbarItemPosition {
	BEFORE_TITLE = "before-title",
	AFTER_TITLE = "after-title",
	AT_THE_END = "at-the-end"
}

export enum ToolbarMargin {
	Top ='top',
	Bottom = 'bottom',
	Both = 'both',
	None = 'none'
}

Toolbar.defaultProps = {
	appearance: "section-header",
	margin: ToolbarMargin.None,
	title: ""
}

function sourtOutChildren(props:any) {
	let beforeTitle: any[] = [];
	let afterTitle: any[] = [];
	let atTheEnd: any[] = [];

	React.Children.forEach(props.children, c => {
		if( c == null )
			return;

		if( typeof c === "string"){
			atTheEnd.push(c)
		}else if (c.type == MenuButton || c.type == Menu || c.props.position == ToolbarItemPosition.BEFORE_TITLE) {
			beforeTitle.push(c);
		} else if( c.props.position == ToolbarItemPosition.AFTER_TITLE ){
			afterTitle.push(c)
		} else {
			atTheEnd.push(c)
		}
	});
	return {beforeTitle, afterTitle, atTheEnd};
}

declare module 'react' {
	interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
		// extends React's HTMLAttributes
		position?: "after-title" | "before-title" | "at-the-end";
	}
}

declare global {
	namespace JSX {
		interface IntrinsicAttributes extends React.Attributes {
			position?: "after-title" | "before-title" | "at-the-end";
		}
	}
}
