import './breadcrumb.less';

import ReactDOM from 'react-dom';
import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {NavigationAction, NavigationStore} from "framework/navigationStore"
import {observer} from "mobx-react";


let b = require('b_').with('breadcrumb');

export const Breadcrumb = observer(props => {
	const items = NavigationStore.items.slice();

	if( items.length == 0){
		return <div></div>
	}

	const currentItem = items.pop();

	const onClick = (e, item) => {
		item = JSON.parse(JSON.stringify(item));
		if (item.config) {
			if (item.config.application) {
				item.config.application = item.config.application.replace(/-/g, '');
			}
			if (item.config.moduleName) {
				item.config.moduleName = item.config.moduleName.replace(/-/g, '');
			}
		}

		!item.url && NavigationStore.goToItem(item);
	}
	
	
	return <ul className={b()}>
		{items.map( item =>
			<React.Fragment key={item.config && item.config.moduleName || item.url}>
				<li className={b('item')}><a onClick={(e) => onClick(e, item)}
				                             href={item.url}
				                             navigation-action={NavigationAction.RewindToTheSame}>{item.title}</a></li>
			</React.Fragment>
		)}
		<li className={b('item', {current: true})}>{currentItem.title}</li>
	</ul>
})
