import DataSourceElement from 'controls/designer/dataSourcesManager/dataSourceElement';
import State from 'tools/state';
import {AssetsRouter} from "areas/assets/bundleDescription";
import {compareByOperator} from "tools/helpers/math";
import {indicatorToState} from "tools/states";
import {TOTAL_METRIC_TYPE} from 'areas/dashboards/graph-editor-extensions/datasources/totalMetricDataSource';
import {DISPLAY_LABEL} from 'areas/dashboards/graph-editor-extensions/datasources/displayLabelDataSource';
import {DISPLAY_UNIT} from 'areas/dashboards/graph-editor-extensions/datasources/displayUnitDataSource';
import {formatNumber} from "tools/helpers/math";
import {TimePeriodType} from 'controls/react/form/timePeriodSelector';
import {dateToString} from "tools/dateTimeUtils";

const i = require('core/localization').translator({
  "Metric: {0}": {
    "no": "Metrikk: {0}"
  },
  "Qualifier not found": {
    "no": "Tjenestepunkt ble ikke funnet",
    "en": "Servicequalifier not found"
  },
  "No metric on qualifier": {
    "no": "Ingen metrikker for tjenestepunktet",
    "en": "No metric for this servicequalifier"
  },
  "No data": {
    "no": "Ingen data",
    "en": "No data"
  },
  "Trend: {0}": {
    "no": "Trend: {0}"
  }
});


function getMetricValue(metric, decimalsNumber) {
	let value = null;

	if (metric && metric.data?.length > 0 && metric.data[0] !== null && metric.data[0].e.length === 1 && metric.data[0].e[0] === 0) {
		value = parseFloat(metric.data[0].v);
	}

	return value;
}

export class MetricDataSourceElement extends DataSourceElement {
	getEntriesToLoad() {
		this.metricsToLoad = [{metricId: this.datasource.metric.id, showTrend: this.datasource.metricTrendPeriod?.period && this.datasource.metricTrendPeriod?.period != TimePeriodType.None}];
		if (this.datasource.metricTrendPeriod?.period && this.datasource.metricTrendPeriod?.period != TimePeriodType.None) {
			this.metricsToLoad[0].trendPeriod = this.datasource.metricTrendPeriod.period;
		}

		if (this.datasource.metric.totalMetricType === TOTAL_METRIC_TYPE.METRIC && this.datasource.metric.totalMetricId) {
			this.metricsToLoad.push({ metricId: this.datasource.metric.totalMetricId});
		}

		return this.metricsToLoad;
	}

	onEntriesLoaded(entries) {
		this.metrics = entries;
	}

	updateState() {
		if(!this.metrics || this.metrics.length == 0)
			return;

		let label = i('No data');

		const metric = this.getMetric();
		const totalMetric = this.getTotalMetric();

		const value = getMetricValue(metric);

		let totalMetricValue = null;
		switch (this.datasource.metric.totalMetricType){
			case TOTAL_METRIC_TYPE.METRIC:
				totalMetricValue = getMetricValue(totalMetric);
				break;
			case TOTAL_METRIC_TYPE.USER_INPUT:
				totalMetricValue = parseFloat(this.datasource.metric.totalMetricCustomValue);
				break;
		}

		if(value !== null) {
			label = this.formatValue(value)

			if (totalMetricValue) {
				label += ' / ' + this.formatValue(totalMetricValue)
			}

			const displayUnitType = this.datasource.metric.displayUnitType;
			let unit = null

			if(displayUnitType == DISPLAY_UNIT.METRIC_UNIT) {
				unit = metric.qualifier.unitTypeSymbol;
			}
			else if (displayUnitType == DISPLAY_UNIT.TOTAL_UNIT) {
				unit = totalMetric.qualifier.unitTypeSymbol;
			}
			else if (displayUnitType == DISPLAY_UNIT.CUSTOM_UNIT) {
				unit = this.datasource.metric.customUnit
			}

			if(unit){
				label += " " + unit
			}

			if(this.datasource.metricValueAsSeverity) {
				const valueToSeverity = this.datasource.metricValueToSeverity;

				let severityIndicator = valueToSeverity.findIndex(x => compareByOperator(x.operator, value, x.threshold));

				if(severityIndicator === -1) {
					severityIndicator = 3
				}
				this.setState(indicatorToState(severityIndicator));
			}
		}

		if (this.datasource.metricTrendPeriod?.period && this.datasource.metricTrendPeriod?.period != TimePeriodType.None && metric) {
			const firstPoint = metric.intercept + metric.slope * moment().valueOf()

			const secondPoint = metric.intercept + metric.slope * moment().add(this.getOffsetInHours(), 'hour').valueOf();
			const delta = firstPoint - secondPoint;

			// this needed to show same trend as in tooltip
			let deltaValue = 0;
			if (Math.abs(delta) > 0.001) {
				deltaValue = +formatNumber(delta, 3).replace(/\s/ig, '');
			}
			if (deltaValue > 0) {
				label += ' <span style="color: green; font-weight: bold; position: relative; top: -2px;">↑</span>';
			}
			if (deltaValue < 0) {
				label += ' <span style="color: red; font-weight: bold; position: relative; top: -1px;">↓</span>';
			}
			if (deltaValue == 0) {
				label += ' <span style="font-weight: bold">-</span>';
			}
		}

		this.removeIcon();
		if (!this.datasource.hideMetricValue) {
			this.addContentLabel(label);
		}
	}

	getOffsetInHours() {
		switch (this.datasource.metricTrendPeriod?.period) {
			case TimePeriodType.LastHour:
				return -1

			case TimePeriodType.LastDay:
				return -24

			case TimePeriodType.Last7Days:
				return -24 * 7

			case TimePeriodType.Last30Days:
				return -24 * 30
		}
	}

	formatValue(value){
		var decimalsNumber = this.datasource.decimalsNumber
		decimalsNumber = parseInt(decimalsNumber)
		return formatNumber(value, decimalsNumber )
	}

	getHealthIndex(){
		return null;
	}

	getSubscriptions(subscriptions) {
		let trendPeriod = this.datasource.metricTrendPeriod ?? {period: TimePeriodType.None}

		let showTrend = this.datasource.showTrend && trendPeriod.period != TimePeriodType.None

		return {
			metrics: this.metrics.map(x => {
				let result = {
					metricId: x.metricId,
					unitType: x.metricInfo.qualifier.unitType
				}
				if(showTrend){
					result.showTrend = true
					result.timePeriod = {
						period : trendPeriod.period,
						from: trendPeriod.startDate,
						to: trendPeriod.endDate
					}
				}

				return result
			})
		};
	}

	consumeEvent(event) {
		if (event.eventType != 'Metric' )
			return false;

		const metric = this.getMetric();
		if(metric == null)
			return false;


		if (event.slope && event.intercept) {
			metric.slope = event.slope;
			metric.intercept = event.intercept;
		}

		if(this.metrics[0].metricId === event.qualifierId) {
			if(metric.data[0].v !== event.metric.v) {
				metric.data[0] = event.metric;
				return true;
			}
			else {
				return false;
			}
		}

		if(this.metrics[1]?.metricId === event.qualifierId) {
			if(this.metrics[1].metricInfo.data[0].v !== event.metric.v) {
				this.metrics[1].metricInfo.data[0] = event.metric;
				return true;
			}
			else {
				return false;
			}
		}

		return false;
	}


	getTooltipInternal(accountName) {
		const metric = this.getMetric();
		if(!metric)
			return null;

		const value = getMetricValue(metric);
		if(value == null)
			return null

		let valueLabel = this.formatValue(value);
		let result = i('Metric: {0}', metric.qualifier.assetName + '/' + metric.qualifier.instanceName);
		result += "\n" + valueLabel + " " + metric.qualifier.unitTypeSymbol;
		if (this.datasource.metricTrendPeriod?.period && this.datasource.metricTrendPeriod?.period != TimePeriodType.None && metric) {
			let value = metric.slope * 60 * 1000;
			let unit = 'minute';
			switch (this.datasource.metricTrendPeriod?.period) {
				case 'LASTDAY':
					value *= 60;
					unit = 'hour';
					break;
				case 'LAST7DAYS':
				case 'LAST30DAYS':
					value *= 60 * 24;
					unit = 'day';
					break;
			}
			let valueWithUnit = '0';
			if (Math.abs(value) > 0.001) {
				valueWithUnit = formatNumber(value, 3) + '/' + unit;
			}
			result += "\n";
			result += i(`Trend: {0}`, valueWithUnit);
		}

		result += "\n\n";
		let timetstamp = new Date(metric.data[0].t);
		result += dateToString(timetstamp, "datetime");
		return result
	}

	redirect(navigator) {
		const metric = this.getMetric();
		if(metric) {
			navigator.go({url: AssetsRouter.details(metric.qualifier.assetId)});
		}
	}

	getMetric() {
		if( this.metrics?.length > 0
			&& this.metrics[0].metricInfo.found
			&& this.metrics[0].metricInfo.hasMetrics) {
			return this.metrics[0].metricInfo;
		}
	}

	getTotalMetric() {
		if (this.datasource.metric.totalMetricType === TOTAL_METRIC_TYPE.METRIC
			&& this.metrics?.length > 1
			&& this.metrics[1].metricInfo.found
			&& this.metrics[1].metricInfo.hasMetrics) {
			return this.metrics[1].metricInfo;
		}

		return null;
	}

	empty() {
		return this.datasource.metric.id == null;
	}

	getLabel() {
		let metric;
		if (this.datasource.metric.displayLabelType === DISPLAY_LABEL.METRIC_DISPLAY_LABEL) {
			metric = this.getMetric();
		} else if (this.datasource.metric.displayLabelType === DISPLAY_LABEL.TOTAL_DISPLAY_LABEL) {
			metric = this.getTotalMetric();
		}

		if (!metric) {
			return null;
		}

		return metric.qualifier.assetName + '/' + metric.qualifier.instanceName;
	}
}
