import './antInput.less'

import React, {CSSProperties} from "react"
import { observer } from 'mobx-react'
import Input, {InputProps, TextAreaProps} from "antd/lib/input"

const {TextArea} = Input;

import {addEnterEscHandlers, captureValueLink, ChangeEventHandlerWrapped, useBouncedWithEvent, wrapOnChange} from "controls/react/ant/utils";


interface AntInputProps extends Omit<InputProps, 'onChange'|'value'|'width'>{
	valueLink?: any
	invalid?: boolean
	errors?: Array<any>
	onChange?: ChangeEventHandlerWrapped<HTMLInputElement>
	ref?: any
	onPressEnter?: () => void
	onPressEsc?: () => void
	bounced?: number
	value?: string
	width?: number
}

export const AntInput:React.FunctionComponent<AntInputProps> = observer(React.forwardRef((props, ref) => {
	let {errors, invalid, ...restProps} = captureValueLink(props)


	const wrappedProps = wrapOnChange(restProps)
	const wrappedProps2 = useCustomWidth(wrappedProps)
	const wrappedProps3 = addEnterEscHandlers(wrappedProps2)

	const {onChange, value, ...restProps2} = wrappedProps3

	const [bouncedValue, bouncedOnChange] = useBouncedWithEvent(props.bounced, props.value, onChange)

	return <Input ref={ref}
	              onChange={bouncedOnChange}
	              value={bouncedValue}
	              {...restProps2}/>
}))

function useCustomWidth<T extends {width?: number, style?: CSSProperties}>(props: T){
	const {width, style,  ...restProps} = props

	let newStyles = React.useMemo(() => {
		let result: CSSProperties = {
			...(style ?? {})
		}

		if(Number.isInteger(props.width)){
			result.width = props.width + 'px'
		}

		return result

	}, [props.width, props.style])


	return {
		style: newStyles,
		...restProps
	}
}

interface AntTextAreaProps extends Omit<TextAreaProps, 'onChange'>{
	valueLink?: any,
	invalid?: boolean,
	errors?: Array<any>,
	onChange?: ChangeEventHandlerWrapped<HTMLTextAreaElement>,
	height?: number,
}

export const AntTextArea:React.FunctionComponent<AntTextAreaProps> = observer(props => {
	let {errors, invalid, style, height, ...antNativeProps} = captureValueLink(props);

	const wrappedProps = wrapOnChange(antNativeProps);

	const styleReal = React.useMemo(() => {
		let result = style ?? {}
		if(height != null){
			result.height = height
		}
		return result
	}, [style, height])

	return <TextArea style={styleReal} {...wrappedProps}/>
});
