import { observable } from 'mobx'
import State from "./state"
import UserPrefs from "./userPrefs"

const FAVORITES_CATEGORY = 'FavoriteItems'
const favorites = observable.array([])
let favoritesLoaded = false

export default favorites

export const loadFavorites = async () => {
	if(favoritesLoaded) {
		return
	}
	const result = await UserPrefs.load(FAVORITES_CATEGORY)
	if(result.success != true) {
		const error = new Error(result.message)
		error.details = result.details
		throw error
	}
	favoritesLoaded = true
	const data = UserPrefs.get('favorites')
	favorites.replace(JSON.parse(data) || [])
}

const saveFavorites = () => {
	const preferences = [{
		key: 'favorites',
		value: JSON.stringify(favorites)
	}];

	UserPrefs.save(FAVORITES_CATEGORY, preferences);
}

const addToFavorites = async (name) => {
	await loadFavorites()
	let currentItem, itemId, breadcrumbConfig;
	if (!name) {
		const breadcrumb = State.mainApp.breadcrumb;
		currentItem = breadcrumb.module;
		if (breadcrumb.items.length) {
			currentItem = breadcrumb.items[breadcrumb.items.length - 1];
		}
		if (currentItem.application === 'AssetGroupSummaryView') {
			currentItem.name = lang.widget.GROUP_SUMMARY;
		}
		if (currentItem.application === 'AssetHealthSummary') {
			currentItem.name = lang.widget.ASSET_SUMMARY;
		}
		itemId = $('.aside').find('.current[data-state!="closed"]').not('.cw_favorites_menu_item').attr('id');
		breadcrumbConfig = breadcrumb.module;
	} else {
		itemId = name;
		let obj = {
			application: name,
			name: name,
			text: name,
			config: {
				moduleName: name
			},
			reactModule: true
		}
		currentItem = obj;
		breadcrumbConfig = obj;
	}

	favorites.push({
		id: itemId,
		item: currentItem,
		breadcrumbConfig: breadcrumbConfig
	});

	saveFavorites();
}

export const removeFromFavorites = async (name) => {
	await loadFavorites()
	let itemName = name || State.mainApp.breadcrumb.currentItem.application || State.mainApp.breadcrumb.module.application;
	const favorite = favorites.find(x => x.item.application === itemName);
	favorites.remove(favorite);

	saveFavorites();
	State.mainApp.breadcrumb.currentItem = {};
}

export const onFavoritesIcon = (e, name) => {
	const target = $(e.currentTarget);
	if (target.hasClass('gold')) {
		target.removeClass('gold');
		removeFromFavorites(name);
	} else {
		target.addClass('gold');
		addToFavorites(name);
	}
}

export const checkFavorite = async (name) => {
	await loadFavorites()
	let currentSection;
	if (name) {
		currentSection = name;
	} else {
		const breadcrumb = State.mainApp?.breadcrumb
		if (breadcrumb) {
			currentSection = breadcrumb.items.length ? breadcrumb.items[breadcrumb.items.length - 1].application : breadcrumb.module.application;
		}
	}
	const isFavorite = favorites.some(f => f.item?.application == currentSection)
	if(!isFavorite) {
		return
	}

	$('.window_titlebar').find('.cw_favorites').addClass('gold');
	$('#content_area').find('.grid_favorite_item').addClass('gold');
}
