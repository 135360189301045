import './formEntry.less'

import React from 'react'
import classnames from 'classnames'
import {observer} from 'mobx-react'
import {linkModel, ValidatableModel, ValidationState} from "framework/mobx-integration";
import {translator} from "core";
import {AntTooltip} from "controls/react/ant/antTooltip"
import ContextField from "controls/react/form/contextField"
import IconButton from "controls/react/form/iconButton"

let b = require('b_').with('form-entry');

const i = translator({
	'Advanced tooltip': {
		en: 'Advanced Service Qualifier setting',
		no: 'Avansert Tjenestepunkt innstilling'
	}
});

export interface FormEntryBaseProps {
	label?: 'string' | React.ReactNode,
	title?: 'string',
	children: React.ReactNode | React.ReactNode[],
	vertical?: boolean,
	disabled?: boolean,
	required?: boolean,
	advanced?: boolean,
	advancedIcon?: React.ReactNode | React.ReactNode[],
	valueLink?: any,
	width?: 'default' | 'fit' | 'half' | number,
	errors?: string[],
	containerClass?: string,

	id?: string,
	style?: any
	context?: object
	path?: string
}

export interface FormEntryProps<TModel extends object, TModelField extends keyof TModel> extends FormEntryBaseProps{
	model?: TModel,
	modelField?: TModelField,
	onChange?: (value: TModel[TModelField]) => void
	validationOnly?: boolean
}

const  FormEntry = observer(<TModel extends ValidatableModel<TModel>|object, TModelField extends keyof TModel>(props: FormEntryProps<TModel, TModelField>) => {
	let {icon, children} = checkForIcon(props);

	let errors = props.errors || [];

	const blockClasses: { [index: string]: any } = {
		vertical: props.vertical,
		horizontal: !props.vertical,
		width: props.width
	}

	let bindingProps = null;
	let hasValidation = false;
	let required = false
	let validationState: ValidationState;
	let initialValueChanged = true;

	if (props.valueLink) {
		hasValidation = props.valueLink.hasValidation;
		bindingProps = props.valueLink.props;
		validationState = bindingProps.invalid ? ValidationState.Invalid : ValidationState.Valid
		required = props.valueLink.isRequired && validationState == ValidationState.Invalid
		initialValueChanged = props.valueLink.storage.initialValueChanged
	}

	if (props.model && props.modelField) {
		if( "validator" in props.model) {
			let fieldValidator = props.model.validator.getFieldValidator(props.modelField)
			if(fieldValidator){
				hasValidation = true
				required = fieldValidator.requiredValidationState != ValidationState.Valid
				validationState = fieldValidator.revalidated ? fieldValidator.validationState : ValidationState.Valid
			}
		}
		bindingProps = linkModel(props.model, props.modelField, {validation: true});
	}

	if (bindingProps) {
		blockClasses['has-validation'] = hasValidation;

		blockClasses['required'] = required

		if (validationState) {
			blockClasses["validation-state"] = validationState
		}

		if (hasValidation && validationState != ValidationState.Valid && initialValueChanged) {
			errors = bindingProps.errors;
		}

		if (props.validationOnly !== true) {

			props.onChange && (bindingProps.onChange = props.onChange);

			delete bindingProps.invalid; //form entry takes care of validation so we dont need an input to act on validation
			delete bindingProps.errors;
			delete bindingProps.validationState

			//We are assuming there that the first child element is an input so we can pass all value, onChange, errors etc to it
			let input = children[0]
			if (React.isValidElement(input)) {
				children[0] = React.cloneElement(input, bindingProps);
			}
		}
	}

	if (props.required && bindingProps == null) {
		blockClasses['required'] = true;
	}

	const rootClasses = classnames(
		b(blockClasses),
		props.containerClass
	);

	const isLongLabel = props.label && typeof (props.label) == 'string' && props.label.length >= 18 && props.vertical !== true;
	const isVeryLongLabel = !!(isLongLabel && icon);

	let advancedIcon;
	if (props.advanced) {
		advancedIcon = props.advancedIcon ||
			<span className="glyphicons cogwheel field-advanced-icon" title={i('Advanced tooltip')}></span>
	}

	let validationMessage = errors.join()

	return (
		<div id={props.id} className={rootClasses} style={props.style} title={validationMessage ? null : props.title}>
			{props.advanced && advancedIcon}
			{props.label &&
				<label className={b('label')}>
					<span className={b('label-text', {"smaller": isLongLabel, "very_small": isVeryLongLabel})}>
						{props.label}
					</span>
					{icon}
				</label>
			}

			<AntTooltip className={b('field')} title={validationMessage}
			            placement={"rightTop"}
			            color={"red"} trigger={validationMessage ? 'hover' : 'none'}
			            zIndex={11000}
			>
				{!props.context && children}
				{props.context &&
					<ContextField context={props.context} path={props.path}>
						{children[0]}
					</ContextField>
				}
			</AntTooltip>
		</div>
	);
});

function checkForIcon(props: FormEntryBaseProps) {
	let icon = null;
	let children = React.Children.toArray(props.children);

	if (children.length > 0) {
		if (React.isValidElement(children[0])) {
			if (children[0].type == IconButton) {
				icon = children[0]
				children.shift();
			}
		}
	}
	return {icon, children};
}

export {FormEntry, FormEntry as default}
