import Utils, {isOneOf} from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import Highcharts from 'highcharts';
import RemoteEventsManager from 'core/remoteEventsManager';
import LocalEventsManager from 'core/localEventsManager';
import Renderer from 'tools/renderer';
import State from 'tools/state';
import Configuration from 'configuration';
import moment from 'moment';
import ErrorCodes from 'tools/errorCodes';
import {getLegendLabelFormatter, getTrendValueWidgetWrapper} from "vendor-init/hightcharts-intergation";
import {debounce, orderBy, throttle} from 'lodash';
import {extractMetricSubscriptionFields} from '../widgets/customMetrics/metricSelector';
import { MultipleMetricEvent } from 'framework/entities/events';
import { UrlBuilder } from 'tools';
import {NavigationStore} from "../../../framework/navigationStore";
import {AssetsRouter} from "../../assets/bundleDescription";
const hcRegression = require('vendor/highcharts/regression');

export function GenericMultigraphWidget(config) {
	Widget.call(this, config);
	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}

	if (!this.instanceConfiguration.metricsItems) {
		this.instanceConfiguration.metricsItems = [];
		var metrics = this.instanceConfiguration.metrics;
		if (metrics && metrics.length) {
			for (var i = 0; i < metrics.length; i++) {
				this.instanceConfiguration.metricsItems.push({
					metricId: metrics[i],
					customUnit: '',
					conversion: '',
					unitTypeSymbol: '',
					color: ''
					//unitType: ''
				});
			}
		}
	}

	if (this.instanceConfiguration.advanced && this.instanceConfiguration.metricsItems) {
		this.instanceConfiguration.metricsItems.forEach(m => {
			m.unitTypeSymbol = this.instanceConfiguration.unitLabel;
			if (this.instanceConfiguration.unit == 'CUSTOM') {
				m.conversion = this.instanceConfiguration.formula;
				m.customUnit = this.instanceConfiguration.unitLabel;
			} else if (this.instanceConfiguration.unit == 'AUTOSCALING') {
				m.conversion = null;
				m.customUnit = null;
				m.conversionUnit = null;
			} else {
				m.conversionUnit = this.instanceConfiguration.unit;
			}
		})
	}

	if (this.instanceConfiguration.metricsItems) {
		this.instanceConfiguration.metricsItems.forEach(item => {
			if (item.advanced) {
				item.unitTypeSymbol = item.unitLabel;
				if (item.unit == 'CUSTOM') {
					item.conversion = item.formula;
					item.customUnit = item.unitLabel;
				} else if (this.instanceConfiguration.unit == 'AUTOSCALING') {
					item.conversion = null;
					item.customUnit = null;
					item.conversionUnit = null;
				} else {
					item.conversionUnit = item.unit;
				}
			}
		})
	}

	this.instanceConfiguration.timezone = this.instanceConfiguration.timezone == "<User Time>" || !this.instanceConfiguration.timezone
		? Cookies.CeesoftTimezone
		: this.instanceConfiguration.timezone

	this.loadErrorCodes();
	//this.initComponent();
}

export {GenericMultigraphWidget as default};

jQuery.extend(GenericMultigraphWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		if (!this.renderTo) {
			this.renderTo = $('#' + this.id).find('.cw_section_content');
			this.widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			if (typeof this.renderTo === 'string') {
				this.renderTo = $('#' + this.renderTo);
			}
			this.widgetContentDiv = this.renderTo;
		}
		if (this.instanceConfiguration.chartType === 'range') {
			this.instanceConfiguration.chartType = 'arearange';
		}

		this.seriesNames = [];

		if (this.isAssetDetails || this.isMetricMonitor || this.isDataRegistry) {
			this.instanceConfiguration.showThreshold = true;
			if (this.type === 'multigraph') {
				this.instanceConfiguration.legendType = 'legend';
			}
		}
		//this.hasTimeSelector = true;
		this.hasToggleTimeSelector = true;
		this.hasChartTypeSelector = true;
		this.hasMissingData = true;
		this.hasRegression = true;
		this.hasHideErrors = true;
		this.hasThreshold = this.configuration.hideThreshold ? false : true;

		this.intervals = {};

		this.getData();

		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: this.widgetContentDiv,
		});

		LocalEventsManager.bind('metricUpdate', $.proxy(this.onMetricUpdate, this));

		this.getDataDebounced = debounce(this.getData, 1000)
	},
	/**
	 * Get chart data
	 */
	getData: function () {
		let width = this.renderTo.width() * 0.6;
		if (isNaN(width)) {
			width = 300;
		}
		width = Math.max(width, 300);
		this.roundRobinPoints = width;
		const periodData = {
			period: this.zoomPeriod || this.instanceConfiguration.period,
			startDate: this.zoomStartDate || this.instanceConfiguration.startDate,
			endDate: this.zoomEndDate || this.instanceConfiguration.endDate,
			width: width
		}
		this.interval = Utils.getPeriodInterval(periodData).interval;
		periodData.interval = this.interval;

		kendo.ui.progress(this.renderTo, true);
		Utils.ajax(this.url(periodData), 'POST', JSON.stringify(this.queryData()), $.proxy(function (result) {
			var item, widgetTitle, qualifier, isBooleanMetric, set = [],
				length = result.length, dataSet, min, max, errors = [], data;

			this.chartData = [];
			this.qualifierSeriesKeys = [];
			this.booleanMetricsArray = [];
			this.booleanMetricsOnly = true;

			this.qualifiers = result.map(x => x.qualifier);
			if (!this.onZoom) this.subscribe();

			if (length === 1) {
				this.hasInfoSign = true;
				var item = result[0].qualifier.configuration;
				if (item && item.algorithm) {
					this.algorithmType = item.algorithm.type;
					if (this.algorithmType === 'StaticThresholdAlgorithm') {
						this.threshold = item.algorithm.threshold;
						if (item.warning) {
							this.warningThreshold = item.warning.threshold;
						}
					}
					if (this.algorithmType === 'RangeAlgorithm') {
						this.rangeMin = item.algorithm.min;
						this.rangeMax = item.algorithm.max;
					}
				}
				let metricCustomUnit = result[0].qualifier.customUnit;
				this.singleGraphUnitType = typeof metricCustomUnit === 'string' ? metricCustomUnit : result[0].qualifier.unitTypeSymbol;
				widgetTitle = result[0].qualifier.assetName + ' \u00bb ' + result[0].qualifier.categoryNode;
			} else {
				widgetTitle = lang.MULTIGRAPH;
				this.hasInfoSign = false;

				let itemsWithMetrics = 0;
				let singleMetricItemUnit;
				for (let metricItem of result) {
					if (metricItem.hasMetrics) {
						itemsWithMetrics++;
						let metricCustomUnit = metricItem.qualifier.customUnit;
						singleMetricItemUnit = typeof metricCustomUnit === 'string' ? metricCustomUnit : metricItem.qualifier.unitTypeSymbol;
					}
				}
				if (itemsWithMetrics === 1) {
					this.singleGraphUnitType = singleMetricItemUnit;
				}
			}

			if (!this.customControls) {
				this.customControls = {
					target: '#' + this.id,
					windowTarget: this.configuration.windowTarget,
					toggleClick: function (value) {
						if ($('#' + this.id).find('.k-i-toggle').length) {
							$('#' + this.id).find('.k-i-toggle').trigger('click');
						} else {
							$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
						}
					}
				};
			}
			this.createCustomControls();

			if (this.onCustomControlsCreated) {
				this.onCustomControlsCreated();
			}

			this.updateTitle(widgetTitle);

			if (!length && result.success === false) {
				if (!this.onZoom) {
					this.renderTo.empty();
				}
				kendo.ui.progress(this.widgetContentDiv, false);
				this.statusNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();

			} else {
				var k = 0, fullName, name, emptyItems = 0;

				this.seriesNamesUnit = [];
				this.noData = [];
				var allDataSet = [];

				this.subscribeToMetrics = [];

				for (var i = 0; i < length; i++) {
					item = result[i];
					qualifier = item.qualifier;

					this.subscribeToMetrics.push(qualifier.metricId);

					this.intervals[qualifier.metricId] = 0;

					if (item.found && item.hasMetrics) {
						dataSet = orderBy(item.data, ['t'], ['asc']);
						allDataSet.push({
							qualifierId: item.qualifier.metricId,
							dataSet: dataSet
						});
						this.qualifierSeriesKeys[qualifier.metricId] = k++;

						data = [];
						this.aggregate = false;

						isBooleanMetric = qualifier.dataType === 'Boolean';
						if (isBooleanMetric) {
							this.booleanMetricsArray.push(qualifier.metricId);
						} else {
							this.booleanMetricsOnly = false;
						}


						if (dataSet[0].vH !== undefined) {
							this.aggregate = true;
						}

						if (isBooleanMetric) {
							min = 0;
							max = 1;
						} else {
							if (dataSet[0].vH !== undefined/* && this.instanceConfiguration.chartType === 'range'*/) {
								min = Utils.getMinFromObjectArray(dataSet, 'vH');
								max = Utils.getMaxFromObjectArray(dataSet, 'vH');
							} else {
								min = Utils.getMinFromObjectArray(dataSet, 'v');
								max = Utils.getMaxFromObjectArray(dataSet, 'v');
							}
						}
						if (this.instanceConfiguration.chartType === 'area') {
							min = 0;
						}


						for (var j = 0, dataSetLength = dataSet.length; j < dataSetLength; j++) {
							set = [];
							if (dataSet[j].e[0] !== 0) {
								set = {
									x: dataSet[j].t,
									y: Utils.aggregate(this.instanceConfiguration.aggregationType, dataSet[j]),
									marker: {
										enabled: !this.instanceConfiguration.hideErrors,
										fillColor: '#FF0000',
										symbol: 'circle',
										radius: 4
									}
								};
							} else {
								set = [dataSet[j].t, Utils.aggregate(this.instanceConfiguration.aggregationType, dataSet[j])];
							}
							data.push(set);

							var noDataState;
							if (dataSet[j].v === null) {
								this.noData.push([dataSet[j].t, min, max]);
								noDataState = true;
							} else {
								if (noDataState) {
									this.noData.push([dataSet[j].t, min, max]);
									noDataState = false;
								} else {
									if (!this.noData.length || this.noData[this.noData.length - 1][1] !== null) {
										this.noData.push([dataSet[j].t, null, null]);
									}
								}
							}
						}

						var customUnit = '';
						var conversion = '';
						var unitTypeSymbol = '';
						let decimals = this.instanceConfiguration.decimals ?? State.defaultDecimals;
						//var unitType = '';
						var color;

						if (this.instanceConfiguration.metricsItems) {
							for (var ii = 0; ii < this.instanceConfiguration.metricsItems.length; ii++) {
								if (this.instanceConfiguration.metricsItems[ii].metricId === qualifier.metricId) {
									customUnit = qualifier.customUnit || this.instanceConfiguration.metricsItems[ii].customUnit;
									conversion = qualifier.conversion || this.instanceConfiguration.metricsItems[ii].conversion;
									decimals = qualifier.decimals || this.instanceConfiguration.metricsItems[ii].decimals || decimals;
									unitTypeSymbol = qualifier.unitTypeSymbol || this.instanceConfiguration.metricsItems[ii].unitTypeSymbol;
									color = this.instanceConfiguration.metricsItems[ii].color || undefined;
								}
							}
						}
						if (this.legendArray && this.legendArray.length && (this.isAssetDetails || this.isDataRegistry)) {
							for (var j = 0; j < this.legendArray.length; j++) {
								if (qualifier.metricId === this.legendArray[j].metricId) {
									fullName = this.legendArray[j].metricLegend;
								}
							}
						} else {
							fullName = this.generateMultigraphLabel(qualifier);
						}
						name = fullName.length > 60 ? fullName.substr(0, 57) + '...' : fullName;
						this.seriesNames[qualifier.metricId] = {
							name: name,
							fullName: fullName,
							customUnit: customUnit,
							conversion: conversion,
							unitTypeSymbol: unitTypeSymbol,
							color: color,
							decimals: decimals,
							//unitType: unitType
						};
						this.seriesNamesUnit.push({
							name: name,
							unitTypeSymbol: unitTypeSymbol
						});

						var zones = [];
						if (this.hasThreshold && length === 1 && this.instanceConfiguration.showThreshold) {
							if (item.qualifier && item.qualifier.configuration && item.qualifier.configuration.algorithm) {
								var thresholdOperator = item.qualifier.configuration.algorithm.operator;
								var thresholdValue = item.qualifier.configuration.algorithm.threshold;
								if (thresholdOperator === 'LT' || thresholdOperator === 'LE') {
									zones = [{
										value: thresholdValue,
										color: '#7cb5ec' //blue
									}, {
										color: '#ff0000' //red
									}]
								} else if (thresholdOperator === 'GT' || thresholdOperator === 'GE') {
									zones = [{
										value: thresholdValue,
										color: '#ff0000'
									}, {
										color: '#7cb5ec'
									}]
								}
							}
						}

						if (this.instanceConfiguration.chartType === 'arearange') {
							//duplicate the second data parameter in order to fulfill the arearange HighCharts format; discuss if low/high values should be take into account instead aggregate ones
							for (let j = 0; j < data.length; j++) {
								if (data[j][1]) {
									data[j].push(data[j][1]);
								}
							}
						}
						this.chartData.push({
							regression: this.instanceConfiguration.showRegression,
							marker: {
								enabled: false
							},
							regressionSettings: {
								marker: {
									enabled: false
								},
								name: name,
								fullName: fullName,
								//unitType: unitType,
								id: Utils.guid(),
								type: 'linear',
								color: 'rgba(255,165,0, 1)'
							},
							fullName: fullName,
							name: name,
							customUnit: customUnit,
							conversion: conversion,
							unitTypeSymbol: unitTypeSymbol,
							color: color,
							step: isBooleanMetric,
							decimals: decimals,
							//unitType: unitType,
							data: data,
							qualifierId: qualifier.metricId,
							type: this.instanceConfiguration.chartType,
							zones: zones
						});

						this.infoQualifier = qualifier;
						if (!name || !(this.instanceConfiguration.legendType === 'legend')) {
							this.chartData[this.chartData.length - 1].showInLegend = false;
						}

						//for info container
						this.hoverInfoQualifier = qualifier;
						if (qualifier.configuration) {
							this.hoverInfoQualifier.description = qualifier.configuration.description;
							if (qualifier.configuration.algorithm) {
								this.hoverInfoQualifier.algorithm = qualifier.configuration.algorithm.type;
								this.hoverInfoQualifier.operator = qualifier.configuration.operator;
								this.hoverInfoQualifier.threshold = qualifier.configuration.threshold;
							}
						}

						if (this.aggregate) {
							this.intervals[qualifier.metricId] = this.interval;
						}
					} else {
						emptyItems++;
					}
				}

				if (this.instanceConfiguration.metricsItems && !this.instanceConfiguration.metricsItems.length) {
					for (let i = 0; i < this.subscribeToMetrics.length; i++) {
						this.instanceConfiguration.metricsItems.push({
							metricId : this.subscribeToMetrics[i],
							conversion : '',
							customUnit: ''
						});
					}
				}

				if (emptyItems === length) {
					if (!this.onZoom) {
						this.renderTo.empty();
					}
					kendo.ui.progress(this.widgetContentDiv, false);
					this.statusNotification.setOptions({
						message: result.message || lang.messages.NO_DATA_AVAILABLE,
						status: 'error'
					}).show();
				} else {
					this.render(allDataSet);
					if(!this.onZoom) this.releaseEvents();
				}
			}

			if (this.widgetSettingsOpened) {
				this.renderTo.siblings('.cw_widget_settings').css({
					'display': 'block',
					'top': '30px'
				})
			}
			this.widgetSettingsOpened = false;

		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(this.widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},

	isAssetGroupMetric() {
		return isOneOf(this.type, ['multi_graph_assetgroup', 'metric-multi-graph-asset-group'])
	},

	queryData() {
		if (this.isAssetGroupMetric()) {
			return {
				assetGroupIds: [...this.instanceConfiguration.assetGroups],
				metricCategoryIds: [...this.instanceConfiguration.metricCategories]
			}
		}

		let metrics = this.instanceConfiguration.metricsItems;
		let data = [];
		for (let metric of metrics) {
			if(metric.registryType == 'COST') {
				data.push(metric);
			} else {
				data.push({
					metricId: metric.metricId,
					conversion: metric.conversion,
					customUnit: metric.customUnit,
					conversionUnit: metric.conversionUnit
				})
			}
		}

		if (this.instanceConfiguration.type === 'MONITOR') {
			if (this.type === 'metrics') {
				data.push({
					metricId: this.instanceConfiguration.qualifier.id,
					customUnit: '',
					conversion: '',
					unitTypeSymbol: ''
				});
			}
			if (this.type === 'multigraph') {
				for (let i = 0; i < this.instanceConfiguration.qualifiersList.length; i++) {
					data.push({
						metricId: this.instanceConfiguration.qualifiersList[i],
						customUnit: '',
						conversion: '',
						unitTypeSymbol: ''
					});
				}
			}
		}

		return data;
	},
	url(calculatedPeriod) {
		let baseUrl = `metrics/${this.instanceConfiguration.aggregated ? 'aggregatedMetrics' : 'registeredMetrics'}/data`;

		if (this.isAssetGroupMetric()) {
			baseUrl = "metrics/assetGroupMetrics/data";
		}
		const {ignoreMissingData, timezone, metricsOnly} = this.instanceConfiguration;
		const urlBuilder = new UrlBuilder(`${this.requestPath}${baseUrl}?ignoreMissingData=${ignoreMissingData}&timeZone=${timezone}`);
		urlBuilder.add('metricsOnly', metricsOnly, metricsOnly);
		if (this.configuration.overrideDataUrl) {
			this.configuration.overrideDataUrl(urlBuilder, calculatedPeriod);
		} else {
			urlBuilder.add('interval', this.interval);
			if (this.zoomPeriod === 'CUSTOM' || this.instanceConfiguration.period === 'CUSTOM') {
				const startDate = new Date(this.zoomStartDate || parseInt(this.instanceConfiguration.startDate));
				const endDate = new Date(this.zoomEndDate || parseInt(this.instanceConfiguration.endDate));
				urlBuilder.add('startDate', startDate.getTime())
						  .add('endDate', endDate.getTime());
			} else {
				urlBuilder.add('timeSelector', this.instanceConfiguration.period);
			}
		}

		return urlBuilder.build();
	},

	onMetricUpdate() {
		if (this.renderTo.siblings('.cw_widget_settings').css('display') === 'block') {
			this.widgetSettingsOpened = true;
		}

		this.getData();
	},

	/**
	 * Updates the title
	 */
	updateTitle: function (title) {
		if (!this.title) {
			var titleSpan = this.isKendoWindow ? this.renderTo.closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
			titleSpan.html(title);
			var toolTipText = titleSpan.text();
			titleSpan.attr('title', toolTipText);
		}
	},
	/*
	 * Handler function which renders the chart
	 */
	render: function (dataSet) {
		var oThis = this,
			exporting = jQuery.extend(true, {}, Configuration.highcharts.exporting);
		this.errors = [];
		this.saveDisabledSeries();
		for (var i = 0, length = dataSet.length; i < length; i++) {
			var current = dataSet[i];
			for (var j = 0; j < current.dataSet.length; j++) {
				if (!this.instanceConfiguration.hideErrors) {
					if (current.dataSet[j].e[0] !== 0) {
						this.errors.push({
							x: current.dataSet[j].t,
							y: 0,
							marker: {
								enabled: true,
								fillColor: '#FF0000',
								symbol: 'circle',
								radius: 4,
								states: {
									hover: {
										radius: 6
									}
								}
							},
							qualifierId: current.qualifierId
						});
					} else {
						if (!this.errors.length || this.errors[this.errors.length - 1].y !== null) {
							this.errors.push({
								x: current.dataSet[j].t,
								y: null
							});
						}
					}
				}
			}
		}

		if (this.onZoom) {
			exporting.enabled = true;
			exporting.buttons.popUpBtn = {
				onclick: $.proxy(function () {
					this.zoomStartDate = null;
					this.zoomEndDate = null;
					this.zoomPeriod = null;
					this.onZoom = false;
					this.getData();

					if (this.customControls && typeof this.customControls.zoom === 'function') {
						this.customControls.zoom.call(this, {
							startDate: this.instanceConfiguration.startDate,
							endDate: this.instanceConfiguration.endDate,
							period: this.instanceConfiguration.period
						});
					}
				}, this),
				align: 'left',
				width: 5,
				x: 5,
				y: 5,
				text: lang.RESET,
				theme: {
					'stroke-width': 1,
					stroke: '#aaa',
					fill: '#fff',
					r: 0,
					states: {
						hover: {
							fill: '#eee'
						},
						select: {
							fill: '#ccc'
						}
					}
				}
			};
		}

		var plotLines = [];
		var currentChartData = this.chartData[0].data;
		if (this.hasThreshold && this.algorithmType === 'StaticThresholdAlgorithm' && this.instanceConfiguration.showThreshold !== false) {
			plotLines.push({
				id: 'threshold',
				color: '#FF0000',
				dashStyle: 'ShortDash',
				width: 2,
				value: this.threshold,
				zIndex: 0
			});

			this.chartData.push({
				zIndex: 0,
				name: 'Threshold',
				color: '#ffffff',
				lineWidth: 0,
				type: 'scatter',
				//data: currentChartData.length ? [[currentChartData[currentChartData.length - 2][0], this.threshold]] : [],
				data: currentChartData.length ? [[currentChartData.findLast(x => x[0] != undefined)[0], this.threshold]] : [],
				showInLegend: false
			});
		}
		if (this.hasThreshold && this.warningThreshold && this.instanceConfiguration.showThreshold !== false) {
			plotLines.push({
				id: 'threshold',
				color: '#FFA500',
				dashStyle: 'ShortDash',
				width: 2,
				value: this.warningThreshold,
				zIndex: 0
			});
			this.chartData.push({
				zIndex: 0,
				name: 'Threshold',
				color: '#ffffff',
				lineWidth: 0,
				type: 'scatter',
				data: currentChartData.length ? [[currentChartData.findLast(x => x[0] != undefined)[0], this.warningThreshold]] : [],
				showInLegend: false
			});
		}
		if (this.algorithmType === 'RangeAlgorithm') {
			plotLines.push({
				id: 'rangemin',
				color: '#FF0000',
				dashStyle: 'ShortDash',
				width: 2,
				value: this.rangeMin,
				zIndex: 0
			});
			plotLines.push({
				id: 'rangemax',
				color: '#FF0000',
				dashStyle: 'ShortDash',
				width: 2,
				value: this.rangeMax,
				zIndex: 0
			});
		}

		if (this.errors.length && !this.instanceConfiguration.hideErrors) {
			this.chartData.push({
				type: 'line',
				color: '#FF0000',
				data: orderBy(this.errors, ['x'], ['asc']),
				zIndex: 4,
				showInLegend: false
			})
		}
		if (this.noData.length) {
			this.chartData.push({
				type: 'arearange',
				color: '#CCCCCC',
				data: orderBy(this.noData, [0], ['asc']),
				name: 'NODATA_AREA',
				zIndex: 1,
				showInLegend: false
			});
		}

		$(this.renderTo[0]).css('margin-bottom', '0px');
		var isLegendEnabled;
		if (this.instanceConfiguration.legendType === 'legend') {
			isLegendEnabled = true;
		} else {
			isLegendEnabled = false;
		}

		let container = this.renderTo;
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
		} else {
			height = container.closest('.cw_section_content').height();
		}

		let header = dashboardWindow.closest('.html-shape-container').find('.toolbar_appearance_section-header').first();
		let missingHeader = false;
		if (header.css('display') === 'none' || header.width() === -30) {
			missingHeader = true;
		}
		if (this.instanceConfiguration.timeSelectorPosition === 'BOTTOM') {
			height = height - 30;
			this.moveTimeSelectorBottom(missingHeader);
		} else if (this.instanceConfiguration.timeSelectorPosition === 'TOP' && missingHeader)  {
			height = height - 30;
			this.renderTo.css('margin-top', '35px');
		}
		this.chart = new Highcharts.Chart({
			chart: {
				renderTo: this.renderTo[0],
				height: height,
				type: this.instanceConfiguration.chartType,
				zoomType: 'x',
				backgroundColor: 'transparent',
				events: {
					load: function () {
						if (oThis.instanceConfiguration.legendType !== 'legend' && oThis.instanceConfiguration.legendType !== 'none') {
							let renderTo = $(oThis.renderTo);
							let widgetWidth = renderTo.width();
							this.renderer.style.fontSize = '11px';
							let currentSvgHeight = $(oThis.renderTo).height();
							let newSvgHeight = currentSvgHeight - 14;
							$(renderTo).find('svg').css('height', newSvgHeight);

							let currentQualifier = oThis.infoQualifier;
							let timezone = oThis.instanceConfiguration.timezone;
							let labels = {
								'<Asset>': 'shownAsset',
								'<Name>': 'shownName',
								'<Type>': 'shownType',
								'<Timezone>': 'shownTimezone'
							};

							let shownAsset = lang.ASSET + ': ' + '<span class="cw_link cw_asset_name">' + currentQualifier.assetName + '</span>';
							let shownName = lang.NAME + ': ' + currentQualifier.categoryNode;
							let shownType = lang.TYPE + ': ' + currentQualifier.registryType;
							let shownTimezone = lang.TIMEZONE + ': ' + timezone;

							let legendRowLabel;
							let defaultInfoTemplate = ['<Asset>', '<Name>', '<Type>', '<Timezone>'];
							let currentLabels = oThis.instanceConfiguration.informationalTemplate || defaultInfoTemplate;
							if (currentLabels && currentLabels.length) {
								let legendRowTemplate = [];
								for (let i = 0; i < currentLabels.length; i++) {
									let label = labels[currentLabels[i]];
									let labelString = eval(label);
									if (labelString) {
										legendRowTemplate.push(labelString)
									}
								}
								legendRowLabel = legendRowTemplate.join(' | ');
							}
							let legendRowLabelPosHeight = this.chartHeight - 25;
							if (legendRowLabel) {
								this.renderer.label(legendRowLabel, 5, legendRowLabelPosHeight, null, null, null, true).css({
									'color': '#999'
								}).add();
							}

							let shownLabels = renderTo.find('.highcharts-label').find('span:not(.cw_link)');
							//QUICKFIX to be found a better solution
							let avgCharPerPx = 5.8;
							let maxCharInLabel = widgetWidth / avgCharPerPx;
							let toolTipArray = [];
							for (let i = 0; i < shownLabels.length; i++) {
								let el = $(shownLabels[i]);
								let labelText = el.text();
								toolTipArray.push(labelText);
								if (el.width() + 5 > widgetWidth) {
									let shownLabelText = labelText.substring(0, maxCharInLabel) + '...';
									el.text(shownLabelText);
								}
							}
							let toolTipText = toolTipArray.join(' | ');
							shownLabels.attr('title', toolTipText);

							if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
								oThis.widgetContentDiv.find('.cw_asset_name').off().on('click', $.proxy(oThis.onAssetNameClick, oThis));
							}
						}
					},
					selection: $.proxy(function (event) {
						if (event.xAxis) {
							this.onZoom = true;
							this.zoomStartDate = parseInt(event.xAxis[0].min, 10);
							this.zoomEndDate = parseInt(event.xAxis[0].max, 10);
							this.zoomPeriod = 'CUSTOM';
							this.unsubscribe();

							if (this.customControls && typeof this.customControls.zoom === 'function') {
								this.customControls.zoom.call(this, {
									startDate: this.instanceConfiguration.startDate,
									endDate: this.instanceConfiguration.endDate,
									period: this.instanceConfiguration.period
								});
							}
						}

						event.preventDefault();
						this.getData();
					}, this)
				}
			},
			title: {
				text: ' '
			},
			legend: {
				enabled: isLegendEnabled,
				title: {
					align: 'center'
				},
				x: 2,
				y: 20,
				floating: false,
				useHTML: true,
				borderWidth: 0,
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				itemMarginBottom: 10,
				itemStyle: {
					fontSize: "10px"
				},
				style: {
					fontSize: "10px"
				},
				labelFormatter: function () {
					return getLegendLabelFormatter(this);
				}
			},
			credits: {
				enabled: false
			},
			exporting: exporting,
			time: {
				timezone: this.instanceConfiguration.timezone
			},
			xAxis: {
				type: 'datetime',
				labels: {
					staggerLines: 1
				},
				dateTimeLabelFormats: {
					millisecond: '%H:%M:%S',
					second: '%H:%M:%S',
					minute: '%H:%M',
					hour: '%H:%M',
					day: '%e. %b',
					week: '%e. %b',
					month: '%b \'%y',
					year: '%Y'
				},
				visible: this.instanceConfiguration.showXAxis
			},
			yAxis: {
				softMin: this.instanceConfiguration.minValue == 'dynamic' ? undefined : 0,
				minPadding: 0,
				title: {
					text: null
				},
				plotLines: plotLines,
				labels: {
					formatter: function () {
						var v = this.value;
						if (oThis.instanceConfiguration.decimals) {
							v = Utils.truncateDecimals(v, oThis.instanceConfiguration.decimals)
						}
						if (oThis.booleanMetricsOnly) {
							switch (this.value) {
								case 1:
									v = lang.UP;
									break;
								case 0:
									v = lang.DOWN;
									break;
								default:
									v = ' ';
									break;
							}
						} else {
							let shownUnit = oThis.instanceConfiguration.advanced && oThis.instanceConfiguration.unit != 'AUTOSCALING'
								? oThis.instanceConfiguration.unitLabel
								: (oThis.singleGraphUnitType || oThis.infoQualifier.unitTypeSymbol);
							return v + (shownUnit || '');
						}
						return v;
					}
				},
				visible: this.instanceConfiguration.showYAxis
			},
			tooltip: {
				crosshairs: true,
				snap: 1,
				shared: false,
				backgroundColor: 'white',
				borderWidth: 0,
				shadow:{
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				formatter: function (e) {
					return oThis.formatChartTooltip(this, e);
				}
			},
			plotOptions: {
				series: {
					marker: {
						enabled: false
					}
				},
				line: {
					events: {
						click: $.proxy(this.onPointClick, this),
						legendItemClick: $.proxy(this.onLegendClick, this)
					}
				}
			},
			series: this.chartData
		});

		this.restoreDisabledSeries();
	},

	formatChartTooltip(context, e) {
		context.points = [{
			point: context.point,
			series: context.point.series,
			y: context.point.y
		}];
		let errorPoint;
		if (context.points) {
			var length = context.points.length, tooltip = [], errorObj,
				userOptions, v = '';
			for (var i = 0; i < length; i++) {
				var y = context.points[i].y;
				if (this.booleanMetricsArray.indexOf(context.series.options.qualifierId) !== -1) {
					y = parseInt(y) === 1 ? lang.UP : lang.DOWN;
				} else {
					let decimals = context.points[i].series.options.decimals ??this.instanceConfiguration.decimals ?? State.defaultDecimals;
					y = Utils.truncateDecimals(y, decimals);
				}
				if (context.points[i].series.options.fullName) {
					if (!context.points[i].series.options.customUnit) {
						tooltip.push(context.points[i].series.options.fullName + ': ' + y + ' ' + (context.points[i].series.options.unitTypeSymbol || ''));
					} else {
						tooltip.push(context.points[i].series.options.fullName + ': ' + y + ' ' + context.points[i].series.options.customUnit);
					}
					break;
				} else {
					if (context.points[i].series.options.name) {
						if (!context.points[i].series.options.customUnit) {
							tooltip.push(context.points[i].series.options.name + ': ' + y + ' ' + (context.points[i].series.options.unitTypeSymbol || ''));
						} else {
							tooltip.push(context.points[i].series.options.name + ': ' + y + ' ' + context.points[i].series.options.customUnit);
						}
					} else {
						//error point
						errorPoint = true;
						let errorDate = Renderer.browserDateRenderer(context.x, "datetime", '', this.instanceConfiguration.timezone);
						let toTime = this.interval ? context.point.x + this.interval : context.point.x;
						let qualifierId = context.point.qualifierId || context.series.options.qualifierId;
						let url = this.requestPath + 'metrics/' + qualifierId + '/errorDetails?fromTime=' + context.point.x + '&toTime=' + toTime + '&timeZone=' + this.instanceConfiguration.timezone;
						return this.getErrorTooltip(url, errorDate);
					}
				}
			}
			if (!errorPoint) {
				var charPerPx = 7.5;
				var chartWidth = this.renderTo.width();
				for (var j = 0; j < tooltip.length; j++) {
					var currentTooltip = tooltip[j];
					var tooltipLength = currentTooltip.length;
					if (tooltipLength * charPerPx > chartWidth) {
						for (var k = currentTooltip.length; k > 0; k--) {
							if (currentTooltip[k] === '/' && !tooltipTruncated) {
								var newTooltip = currentTooltip.slice(0, k) + '<br/>' + '<span style="padding-left: 20px; padding-right: 10px">' + currentTooltip.slice(k + 1) + '</span>';
								var tooltipTruncated = true;
								tooltip[j] = newTooltip;
							}
						}
					}
					tooltip[j] = '<span style="padding-left: 20px; padding-right: 10px">' + tooltip[j] + '</span>';
				}
				var s = Renderer.browserDateRenderer(context.x, "datetime", '', this.instanceConfiguration.timezone) + '<br/>' + tooltip.join('<br/>');
				if (this.instanceConfiguration.showRegression){
					const v = getTrendValueWidgetWrapper(context, this);

					if (this.isAssetDetails || this.isMetricMonitor || this.isDataRegistry) {
						return '<span style="color:' + e.chart.hoverSeries.color + '; padding-left: 20px; padding-right: 10px; ">' + s + '</span>' + '<br />' + '<span style="color:' + e.chart.hoverSeries.color + '; padding-left: 20px; padding-right: 10px; ">' + v + '</span>';
					} else {
						return '<span style="padding-left: 20px; padding-right: 10px">' + s + '</span><br /><span style="padding-left: 20px; padding-right: 10px;">' + v + '</span>';
					}
				} else {
					for (var i = 0; i < length; i++) {
						errorObj = this.getErrorCode(context.x, context.points[i].series.options.qualifierId);
					}
					var s = Renderer.browserDateRenderer(context.x, "datetime", '', this.instanceConfiguration.timezone) + '<br/>' + tooltip.join('<br/>');
					var v = '<br />';
					if (!errorObj) {
						if (this.isAssetDetails || this.isMetricMonitor || this.isDataRegistry) {
							return '<span style="color:' + e.chart.hoverSeries.color + '; padding-left: 20px; padding-right: 10px">' + s + '</span>';
						} else {
							return '<span style="padding-left: 20px; padding-right: 10px">' + s + '</span>';
						}
					} else {
						context.points[0].point.color = '#FF0000';
						var e = errorObj.e[0];
						v += ErrorCodes.get(e).text;
						if (this.isAssetDetails || this.isMetricMonitor || this.isDataRegistry) {
							return '<span style="color:' + e.chart.hoverSeries.color + '; padding-left: 20px; padding-right: 10px;">' + s + '</span>' + '<span style="color:' + e.chart.hoverSeries.color + '">' + v + '</span>' + '<br />';
						} else {
							return '<span style="padding-left: 20px; padding-right: 10px;">' + s + v + '</span><br />';
						}
					}
				}
			}
		}
	},

	computeConversion: function (value, conversion) {
		var expression = value + conversion;
		return eval(expression);
	},
	onAssetNameClick: function (e) {
		e.stopPropagation();

		const {assetId} = this.infoQualifier;
		this.navigator.go({
			url: AssetsRouter.details(assetId)
		});
	},
	/**
	 * Search the data for a timestamp point and returns its error code
	 * @param {Number} timestamp
	 * @return {String} errorCode
	 */
	getErrorCode: function (timestamp, qualifierId) {
		var errorCode = '';
		var t0;
		var qualifierKey = this.qualifierSeriesKeys[qualifierId];
		if (this.chartData[qualifierKey]) {
			var data = this.chartData[qualifierKey].data;
			for (var i = 0, length = data.length; i < length; i++) {
				if (data[i].t && data[i].t === timestamp) {
					var item = data[i];
					if (item.e && item.e.length && item.e[0] !== 0) {
						return item;
					} else {
						break;
					}
				}
			}
		}
	},

	getErrorTooltip: throttle(function(url, errorDate) {
		return Utils.ajax(url, 'GET', {}, false, false)
			.then(function (result) {
				if (result.success && result.data) {
					let v = '';
					v += '<br />' + result.data.message;
					v += '<br />' + '<div class="cw_widget_tooltip">' + result.data.serviceLogMessage + '</div>';
					let errorTooltip = errorDate + '<br />' + v;
					return errorTooltip;
				} else {
					return 'There is no data for this error';
				}
			});
	}, 500),
	/*
	 * handler for clicking a point
	 */
	onPointClick: function (e) {
		var timeStamp = e.point.options.x, serieName = e.point.series.name;
	},
	/*
	 * Handler for clicking legend
	 */
	onLegendClick: function (e) {
		var clickedLegend = e.target.name;
	},
	/**
	 * Called when a metric event is received
	 * @param {Object} data The event data object
	 */
	onEvent: function (data) {
		if (this.onZoom) return;
		if (this.chart) {
			var doRoundRobin, length = this.chartData.length, dataSet,
				seriesDataSet, qualifierKey;

			qualifierKey = this.qualifierSeriesKeys[data.qualifierId];
			if (qualifierKey === undefined) {
				// series doesn't exist, created it
				qualifierKey = Object.keys(this.qualifierSeriesKeys).length;
				this.qualifierSeriesKeys[data.qualifierId] = qualifierKey;
				var name = this.seriesNames[data.qualifierId].name;
				var fullName = this.seriesNames[data.qualifierId].fullName;
				var customUnit = this.seriesNames[data.qualifierId].customUnit;
				var conversion = this.seriesNames[data.qualifierId].conversion;
				let decimals  = this.instanceConfiguration.decimals ?? this.seriesNames[data.qualifierId].decimals;
				var unitTypeSymbol = this.seriesNames[data.qualifierId].unitTypeSymbol;
				var color = this.seriesNames[data.qualifierId].color;

				//var unitType = this.seriesNames[data.qualifierId].unitType;
				this.chartData[qualifierKey] = {
					name: name,
					fullName: fullName,
					conversion: conversion,
					customUnit: customUnit,
					unitTypeSymbol: unitTypeSymbol,
					color: color,
					decimals: decimals,
					//unitType: unitType,
					data: [],
					marker: {
						enabled: false
					},
					regression: this.instanceConfiguration.showRegression,
					regressionSettings: {
						marker: {
							enabled: false
						},
						name: name,
						fullName: fullName,
						id: Utils.guid(),
						type: 'linear',
						color: 'rgba(255,165,0, 1)'
					}
				};
				this.chart.addSeries(this.chartData[qualifierKey], true);
			}

			dataSet = this.chartData[qualifierKey].data;
			seriesDataSet = this.chart.series[qualifierKey];

			doRoundRobin = true;
			if (dataSet.length < this.roundRobinPoints) {
				doRoundRobin = false;
			}

			if (!this.aggregate || this.instanceConfiguration.chartType !== 'arearange') {
				var color = '#7cb5ec';
				var isError = false;
				if (data.metric.e[0] !== 0) {
					color = '#FF0000';
					isError = true;
				}
				seriesDataSet.addPoint({
					x: data.metric.t,
					y: Utils.aggregate(this.instanceConfiguration.aggregationType, data.metric),
					marker: {
						enabled: isError ? true : false,
						fillColor: color,
						symbol: 'circle',
						radius: 4,
						states: {
							hover: {
								radius: 6
							}
						}
					}
				}, true, doRoundRobin);
			} else {
				seriesDataSet.addPoint([data.metric.t, Utils.aggregate(this.instanceConfiguration.aggregationType, data.metric)], true, doRoundRobin);
			}

			if (seriesDataSet.userOptions.regression) {
				const regressionSerie = this.chart.series.find(x => seriesDataSet.userOptions.regressionSettings.id == x.userOptions.id);
				const regression = hcRegression._linear(seriesDataSet.data, 2);
				regressionSerie.regressionOutputs = regression;
				regressionSerie.userOptions.regressionOutputs = regression;
				regressionSerie.setData(regression.points);
			}

			if (doRoundRobin) {
				dataSet.splice(0, 1);
			}
			dataSet.push(data.metric);
		} else {
			this.getData();
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		kendo.ui.progress(this.renderTo, true);
		this.getDataDebounced()
	},
	/*
	 * Handler function for release events
	 * */
	releaseEvents: function () {
		if (this.aggregate) {
			this.recalculateIntervalsConsideringData();
		}
		RemoteEventsManager.releaseEvents(this.subscriberId, {
			intervals: this.intervals
		});
	},

	recalculateIntervalsConsideringData: function() {
		if (!this.chartData.length || !this.chartData[0].length) {
			return;
		}

		this.chartData.forEach(chart => {
			if (!chart.qualifierId) {
				return;
			}
			const period = Utils.getPeriodInterval({
				period: this.zoomPeriod || this.instanceConfiguration.period,
				startDate: this.zoomStartDate || this.instanceConfiguration.startDate,
				endDate: this.zoomEndDate || this.instanceConfiguration.endDate,
				width: this.roundRobinPoints
			}, chart.data[0][0]);
			this.intervals[chart.qualifierId] = period.interval;
		});
	},

	/**
	 * Subscribes to qualifier emtrics events
	 * @param {Boolean} reload If true, reloads chart data after a successful subscription
	 */
	subscribe: function (reload) {
		this.isDataSourceSubscribed && this.unsubscribe();
		this.isDataSourceSubscribed = true
		const qualifiers = this.qualifiers
			.map(x => {
				if (!this.instanceConfiguration.advanced
					|| this.instanceConfiguration.advanced && this.instanceConfiguration.unit == 'AUTOSCALING') {
					return x;
				}
				if (this.instanceConfiguration.unit == 'CUSTOM') {
					x.conversion = this.instanceConfiguration.formula;
					x.customUnit = this.instanceConfiguration.unitLabel;
					x.conversionUnit = null;
				} else {
					x.conversion = null;
					x.customUnit = null;
					x.conversionUnit = this.instanceConfiguration.unit;
				}
				x.unitType = null;
				return x;
			});
		const subscriptionObj = [MultipleMetricEvent.subscription(extractMetricSubscriptionFields(qualifiers))]
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		RemoteEventsManager.unsubscribe(this.subscriberId)
		LocalEventsManager.unbind('metricUpdate');
		Widget.prototype.destroy.call(this);
	}
});
