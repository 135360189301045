import Utils from "tools/utils";
import {KeysMatching} from "framework/typescript-integration";

export default class ArrayHelpers {
	static generateUniqueName<T extends {name: string}>(items: T[], name: string) {
		let maxIndex = 0, nameExists = false;
		const startsWith = `${name}-`;

		items.forEach((item) => {
			if (item.name.startsWith(startsWith)) {
				const len = startsWith.length;
				const strNumber = item.name.substring(len);
				const number = +strNumber;

				if (maxIndex < number) {
					maxIndex = number;
				}
			}

			if (item.name == name)
				nameExists = true;
		});

		if( maxIndex == 0 && !nameExists)
			return name;

		return startsWith + ++maxIndex;
	}

	static generateId<T extends {id: string}>(array: T[], subArrayAccessor?: (element: T) => T[]) {
		array.forEach(entry => {
			if (!entry.id) {
				entry.id = Utils.guid();
				if(subArrayAccessor){
					ArrayHelpers.generateId(subArrayAccessor(entry));
				}
			}
		});

		return array;
	}

	static cleanId<T extends {id: string}>(array: T[]) {
		array.forEach(entry => {
			delete entry.id
		});

		return array;
	}

	static remove<T, K extends keyof T>(array: T[], element: T, realId: K = null) {
		let index = null;

		if (realId && element[realId] != null) {
			index = array.findIndex(e => e[realId] == element[realId]);
		} else {
			index = array.indexOf(element);
		}
		if(index != -1) {
			array.splice(index, 1);
		}
		return array;
	}


	static addOrReplace<T, K extends KeysMatching<T, string>>(array: T[], entry: T, idFieldName: K){
		if( entry[idFieldName] == null ){
			array.push( entry );
			entry[idFieldName] = Utils.guid();
		}else{
			let index = array.findIndex( e => e[idFieldName] == entry[idFieldName]);
			if( index == -1){
				array.push(entry);
			}else {
				array.splice(index, 1, entry);
			}
		}
	}

	static objectToArray<T extends object, K extends keyof T>(object: object, propertyField: K){
		return Object.entries(object).map( ([key, value]) =>{
			return {
				[propertyField]: key,...value
			}
		});
	}
}


export function getLastEntry<T>(array: T[]): T{
	if(array.length == 0)
		return null;

	return array[array.length - 1];
}

export function takeWhile<T>(fn: (element: T) => boolean, array: T[], start: number = 0): T[] {
	let result = [];

	for (let i = start; i < array.length; i++) {
		if (fn(array[i]))
			result.push(array[i]);
		else
			break;
	}

	return result;
}

export const insertAfter = <T>(arr: T[], moved: T, after: T = null) => {
	if (moved === after) {
		return arr;
	}
	const copy = arr.slice();
	const movedIndex = arr.indexOf(moved);
	const afterIndex = arr.indexOf(after);
	copy.splice(movedIndex, 1);
	if(movedIndex > afterIndex) {
		copy.splice(afterIndex+1, 0, moved);
	} else {
		copy.splice(afterIndex, 0, moved);
	}
	return copy;
};
