import Configuration from "../configuration";
import moment from 'moment-timezone';

import {Api} from "tools/api";
import {State} from "tools/state";
import {Utils} from "tools/utils";
import {Cookies} from "core/cookies";

import ModalWindow from "controls/modalWindow";
import MultiSelectGridFilter from "controls/multiSelectGridFilter";
import {parseDateFormat, dateToString} from "./dateTimeUtils";
import lang from "../core/localization/lang";
import {renderSeverityTag} from "tools/states";
import classnames from 'classnames';
import React from "react";

export let Renderer = {
	/**
	 * Returns the formatted local time converted to Moment.js readable pattern
	 * @param {String} pattern The date pattern to convert
	 * @return {String}formattedTime The local time
	 */
	parseDateFormat: function (pattern) {
		return parseDateFormat(pattern);
	},
	/*
	 * Handler function for formatting a date
	 * @param {Number} time Long time in milliseconds
	 * @param {String} dateType The type of format. Can be 'datetime' || 'date' || 'time'
	 * @param {String} optionalFormat Optional parameter that ovewrites the
	 * */
	dateRenderer: function (time, dateType, optionalFormat) {
		return dateToString(time, dateType, optionalFormat);
	},
	/*
	 * Handler function for rendering date with specific format and timezone
	 * @param {Number} time Long time in milliseconds (UTC)
	 * @param {String} dateType The type of format. Can be 'datetime' || 'date' || 'time'
	 * @param {String} optionalFormat Optional parameter that ovewrites the
	 * default date/time formats
	 * @param {String} optionalTimezone
	 * @return {String} formattedTime The local time
	 * */
	browserDateRenderer: function (time, dateType, optionalFormat, optionalTimezone) {
		return dateToString(time, dateType, optionalFormat, optionalTimezone);
	},
	/**
	 * Returns the formatted local time based on the Moment.js/Moment-timezone.js framework
	 * @param {Number} time Long time in milliseconds (UTC)
	 * @param {String} dateType The type of format. Can be 'datetime' || 'date' || 'time'
	 * @param {String} optionalFormat Optional parameter that ovewrites the
	 * default date/time formats
	 * @param {String} optionalTimezone
	 * @return {String} formattedTime The local time
	 */
	localDate: function (time, dateType, optionalFormat, optionalTimezone) {
		var dateFormat, formattedTime = '', lang = 'en', timezone,
			timeUTC = moment(time),
			utcYear = timeUTC.utc().year(),
			utcMonth = timeUTC.utc().month() + 1,
			utcDayOfMonth = timeUTC.utc().date(),
			utcHours = timeUTC.utc().hours(),
			utcMinutes = timeUTC.utc().minutes(),
			utcSeconds = timeUTC.utc().seconds(),
			utcMilliseconds = timeUTC.utc().milliseconds();
		if (time) {
			if (optionalFormat) {
				dateFormat = Renderer.parseDateFormat(optionalFormat);
			} else {
				if (dateType) {
					switch (dateType) {
						case 'datetime':
							dateFormat = Renderer.parseDateFormat(Cookies.CeesoftUserDateTimeFormat);
							break;
						case 'date':
							dateFormat = Renderer.parseDateFormat(Cookies.CeesoftUserDateFormat);
							break;
						case 'time':
							dateFormat = Renderer.parseDateFormat(Cookies.CeesoftUserTimeFormat);
							break;
					}
				} else {
					dateFormat = Renderer.parseDateFormat(Cookies.CeesoftUserDateTimeFormat);
				}
			}
			if (Cookies.CeesoftUserLocale === 'no') {
				lang = 'nb';
			}
            if (optionalTimezone && optionalTimezone !== 'undefined') {
                timezone = optionalTimezone;
            } else {
                timezone = Cookies.CeesoftTimezone;
            }
			formattedTime = moment.tz(utcYear + '-' + (utcMonth.toString().length > 1 ? utcMonth : '0' + utcMonth) + '-' + (utcDayOfMonth.toString().length > 1 ? utcDayOfMonth : '0' + utcDayOfMonth) + ' ' + (utcHours.toString().length > 1 ? utcHours : '0' + utcHours) + ':' + (utcMinutes.toString().length > 1 ? utcMinutes : '0' + utcMinutes) + ':' + (utcSeconds.toString().length > 1 ? utcSeconds : '0' + utcSeconds) + '.' + utcMilliseconds, timezone).locale(lang).format(dateFormat);

		}
		return formattedTime;
	},
	/**
	 * Returns the elapsed time since the message was received
	 * @param {Number} time Long time in milliseconds (used only to compare
	 * dates of messages)
	 * @param {Number} ftime Long time in milliseconds (UTC)
	 * @return {String} formattedTime for messages
	 */
	messageLocalDate: function (time, ftime) {
		var messageUtcTime = new Date(time),
			ct = new Date(), // current time
			timeDiff = Math.abs(ct.getTime() - messageUtcTime.getTime()),
			diffHours = Math.floor(timeDiff / (1000 * 3600)),
			diffMinutes = Math.floor(timeDiff / (1000 * 60)),
			formattedTime = '';

		if (diffMinutes <= 59) {
			formattedTime = diffMinutes + ' min';
		} else if (diffHours <= 23) {
			formattedTime = diffHours + ' h';
		} else if (ct.getFullYear() === messageUtcTime.getUTCFullYear()) {
			formattedTime = Renderer.localDate(ftime, '', 'dd MMMM');
		} else {
			formattedTime = Renderer.localDate(ftime, Constants.DATETIME);
		}

		return formattedTime;
	},
	/**
	 * Returns an icon for a given type
	 * @param {String} type
	 * @return {String} icon The icon HTML code
	 */
	fileType: function (type) {
		var file_type;

		switch (type) {
			case 'image/png':
				file_type = lang.file_type.IMAGE;
				break;
			case 'image/jpeg':
				file_type = lang.file_type.IMAGE;
				break;
			case 'image/jpg':
				file_type = lang.file_type.IMAGE;
				break;
			case 'image/gif':
				file_type = lang.file_type.IMAGE;
				break;
			case 'image/tiff':
				file_type = lang.file_type.IMAGE;
				break;
			case 'application/pdf':
				file_type = lang.file_type.PDF;
				break;
			case 'text/plain':
				file_type = lang.file_type.TXT;
				break;
			default:
				file_type = lang.UNKNOWN;
				break;
		}


		return file_type;
	},
	/**
	 * Returns an icon for a given type
	 * @param {String} type
	 * @return {String} icon The icon HTML code
	 */
	fileIcon: function (type) {
		var icon = '<span class="glyphicons status_icon file"></span>';
		switch (type) {
			case 'image/png':
				icon = '<span class="glyphicons status_icon picture"></span>';
				break;
			case 'image/jpeg':
				icon = '<span class="glyphicons status_icon picture"></span>';
				break;
			case 'image/jpg':
				icon = '<span class="glyphicons status_icon picture"></span>';
				break;
			case 'image/gif':
				icon = '<span class="glyphicons status_icon picture"></span>';
				break;
			case 'image/tiff':
				icon = '<span class="glyphicons status_icon picture"></span>';
				break;
			case 'application/pdf':
				icon = '<span class="glyphicons status_icon file"></span>';
				break;
			case 'application/vnd.ms-powerpoint':
				icon = '<span class="glyphicons status_icon keynote"></span>';
				break;
			case 'application/vnd.ms-excel':
				icon = '<span class="glyphicons status_icon table"></span>';
				break;
			case 'application/zip':
				icon = '<span class="glyphicons status_icon file"></span>';
				break;
			case 'application/x-url':
				icon = '<span class="glyphicons status_icon link"></span>';
				break;
		}
		if (type) {
			if (type.indexOf('audio') > -1) {
				icon = '<span class="glyphicons status_icon music"></span>';
			} else if (type.indexOf('text') > -1) {
				icon = '<span class="glyphicons status_icon notes-2"></span>';
			} else if (type.indexOf('video') > -1) {
				icon = '<span class="glyphicons status_icon facetime-video"></span>';
			}
		}

		return icon;
	},
	/**
	 * Formats a float size into a readable size
	 * @param {Number} size The size to be formatted
	 * @param {String} fileType The not supported file type
	 * @return {String} readableSize
	 */
	fileSize: function (size, fileType) {
		if (fileType === "application/x-url") {
			return 'N/A';
		}

		size = size / 1024;
		return Math.round(size) + ' KB';
	},
	/**
	 * Creates the icons for the service log status column
	 * @param {String} status
	 * @return {String} icon
	 */
	serviceLogStatus: function (status) {
		var icon = '';

		switch (status) {
			case 'OK':
				icon = '<span class="glyphicons status_icon circle-arrow-top" title="' + lang.OK + '"></span>';
				break;
			case 'ERROR':
				icon = '<span class="glyphicons status_icon circle-arrow-down" title="' + lang.ERROR + '"></span>';
				break;
			case 'MODEL_ERROR':
				icon = '<span class="glyphicons status_icon remove-sign orange" title="' + lang.ERROR + '"></span>';
				break;
			case 'WARNING':
				icon = '<span class="glyphicons status_icon exclamation-sign orange" title="' + lang.WARNING + '"></span>';
				break;
			case 'FAILED':
				icon = '<span class="glyphicons status_icon ban-circle" title="' + lang.FAILED + '"></span>';
				break;
			case 'UNKNOWN':
				icon = '<span class="glyphicons status_icon question-sign" title="' + lang.UNKNOWN + '"></span>';
				break;
			case 'OPEN':
				icon = '<span class="glyphicons status_icon folder-open" title="' + lang.OPEN + '"></span>';
				break;
			case 'CLOSED':
				icon = '<span class="glyphicons status_icon open-closed" title="' + lang.CLOSED + '"></span>';
				break;
		}
		return icon;
	},
	/**
	 * Creates the icons for the service log detailType column
	 * @param {String} status
	 * @param {Boolean} hasAttachments
	 * @return {String} icon
	 */
	serviceLogDetailType: function (status, hasAttachments) {
		var icon = '';
		switch (status) {
			case 'MESSAGE':
				icon = '<span class="glyphicons status_icon chat" data-type="' + status + '" title="' + lang.service.MESSAGE + '"></span>';
				break;
			case 'MODEL_ERROR':
				icon = '<span class="glyphicons status_icon share-alt pointer" data-type="' + status + '" title="' + lang.service.MODEL_ERROR + '"></span>';
				break;
			case 'NOTE':
				icon = '<span class="glyphicons status_icon note pointer" data-type="' + status + '" title="' + lang.service.NOTE + '"></span>';
				break;
			case 'SERVICE_LOG':
				icon = '<span class="glyphicons status_icon sort" data-type="' + status + '" title="' + lang.service.SERVICE_LOG + '"></span>';
				break;
			case 'SLA':
				icon = '<span class="glyphicons status_icon ok" data-type="' + status + '" title="' + lang.service.SLA + '"></span>';
				break;
			case 'STATE':
				icon = '<span class="glyphicons status_icon lightbulb" data-type="' + status + '" title="' + lang.STATE + '"></span>';
				break;
			case 'STATE_QUALIFIER':
				icon = '<span class="glyphicons status_icon cogwheel pointer" data-type="' + status + '" title="' + lang.service.STATE_QUALIFIER + '"></span>';
				break;
			case 'STATE_MODEL':
				icon = '<span class="glyphicons status_icon git-merge pointer" data-type="' + status + '" title="' + lang.service.STATE_MODEL + '"></span>';
				break;
			case 'STATE_ELEMENT':
				icon = '<span class="glyphicons status_icon vector-path-circle pointer" data-type="' + status + '" title="' + lang.service.STATE_ELEMENT + '"></span>';
				break;
			case 'SERVICE_INCIDENT':
				icon = '<span class="glyphicons status_icon construction-cone pointer" data-type="' + status + '" title="' + lang.service.SERVICE_INCIDENT + '"></span>';
				break;
			case 'SYSTEM_ERROR':
				icon = '<span class="glyphicons status_icon cogwheels pointer" data-type="' + status + '" title="' + lang.service.SYSTEM_ERROR + '"></span>';
				break;
			case 'STATE_LINK':
				icon = '<span class="glyphicons status_icon link pointer" data-type="' + status + '" title="' + lang.SERVICE_LINK + '"></span>';
				break;
		}
		if (hasAttachments) {
			icon += '<span class="glyphicons status_icon paperclip pointer" title="' + lang.service.HAS_ATTACHMENTS + '"></span>';
		}
		return icon;
	},
	rootCauseType: function (status, serviceName) {
		var icon = '';
		switch (status) {
			case 'SERVICE_QUALIFIER':
				icon = '<span class="glyphicons status_icon cogwheel root_cause_type" data-type="' + status + '" title="' + lang.designer.SERVICE_QUALIFIER + '"></span>';
				break;
			case 'SERVICE_MODEL':
				icon = '<span class="glyphicons status_icon git-merge root_cause_type" data-type="' + status + '" title="' + lang.service.SERVICE_MODEL + '"></span>';
				break;
			case 'SERVICE_ELEMENT':
				icon = '<span class="glyphicons status_icon vector-path-circle root_cause_type" data-type="' + status + '" title="' + lang.designer.SERVICE_ELEMENT + '"></span>';
				break;
			case 'SERVICE_LINK':
				let title = kendo.template(lang.SERVICE_LINK_MESSAGE)({
					serviceName: serviceName
				});
				icon = '<span class="glyphicons status_icon link black root_cause_type" data-type="' + status + '" title="' + title + '"></span>';
				break;
		}
		return icon;
	},
	/**
	 * Creates the important status icon for the notifications grid
	 * @param {Boolean} important The state of the notification
	 * @return {String} htmlCode
	 */
	notificationsImportant: function (important) {
		var htmlCode = '';
		if (important) {
			htmlCode = '<span class="glyphicons exclamation-sign cw_important_message" title="Important Message"></span>';
		} else {
			htmlCode = '<span class="glyphicons exclamation-sign cw_regular_message" title="Not Important"></span>';
		}
		return htmlCode;
	},
	/**
	 * Creates the icons for the SLA status column
	 * @param {String} status
	 * @return {String} icon
	 */
	slaStatus: function (status) {
		var icon = '';
		switch (status) {
			case 'FAILED':
				icon = '<span class="glyphicons status_icon circle-arrow-down pointer"></span>';
				break;
			case 'OK':
				icon = '<span class="glyphicons status_icon circle-arrow-top pointer"></span>';
				break;
			case 'WARNING':
				icon = '<span class="glyphicons status_icon circle-arrow-right pointer"></span>';
				break;
		}
		return icon;
	},
	/**
	 * Handler function for sla indicator status
	 */
	slaIndicatorStatus: function (statusIndex) {
		var icon = '';
		switch (statusIndex) {
			case 2:
				icon = '<span class="glyphicons status_icon circle-arrow-down pointer"></span>';
				break;
			case 0:
				icon = '<span class="glyphicons status_icon circle-arrow-top pointer"></span>';
				break;
			case 1:
				icon = '<span class="glyphicons status_icon circle-arrow-right pointer"></span>';
				break;
		}
		return icon;
	},
	/**
	 * Implements inheritance to service team when no responsible team
	 * defined
	 * @param {String} teamName
	 * @return {String} updatedTeamName
	 */
	slaTeam: function (teamName) {
		var defaultTeamName;
		if ($('#cw_service_teamId').data('kendoDropDownList')) {
			defaultTeamName = '<span class="cw_default_team">' + $('#cw_service_teamId').data('kendoDropDownList').text() + '</span>';
		} else {
			defaultTeamName = '<span class="cw_default_team"></span>';
		}
		return teamName || defaultTeamName;
	},

	/**
	 * Render status icon based on stateIndex
	 *
	 * @deprecated use renderSummaryState instead
	 * @param stateIndex
	 * @param text
	 * @param operatingState
	 * @param agentStatus
	 * @returns {*}
	 */
	summaryState: function (stateIndex, text, operatingState, agentStatus) {
		var statusClass = '';
		if (stateIndex === 0) {
			statusClass = "is_critical";
		} else if (stateIndex === 1) {
			statusClass = "is_major";
		} else if (stateIndex === 2) {
			statusClass = "is_ok";
		} else if (stateIndex === 3) {
			statusClass = "is_idle";
		}

		let columnPointer = [1, 2, 5];

		let $span = this._renderSummaryState(statusClass, operatingState, agentStatus);

		if (columnPointer[stateIndex]) {
			$span.addClass('pointer');
		}

		return $span.prop('outerHTML') + (text || '');
	},

	renderSummaryState: function (state, text, operatingState, agentStatus, qualifierError, assetMaintenance) {
		let statusClass = Utils.getStateClass(state);
		let $span = this._renderSummaryState(statusClass, operatingState, agentStatus, qualifierError, assetMaintenance);
		return $span.prop('outerHTML') + (text || '');
	},

	_renderSummaryState: function (statusClass, operatingState, agentStatus, qualifierError, assetMaintenance) {
		let $span = $('<span/>').addClass('cw_service_indicator js_service_state cw_status pointer').addClass(statusClass);

		if (operatingState === 'IN_MAINTENANCE') {
			$span.removeClass(statusClass).addClass('is_idle').attr('title', lang.SERVICE_MAINTENANCE).append($('<span/>').addClass('cw_indicator glyphicons wrench'));
		}
		else if (operatingState === 'NON_OPERATIONAL') {
			$span.removeClass(statusClass).addClass('is_idle').attr('title', lang.service.OUTSIDE_OPERATION_PERIOD).append($('<span/>').addClass('cw_indicator glyphicons clock'));
		}
		else if (qualifierError || ['AGENT_DOWN', 'AGENT_MAINTENANCE'].indexOf(agentStatus) !== -1 || assetMaintenance) {
			let $exclamation = $(Utils.renderExclamationMark());
			let messages = [];

			if (qualifierError) {
				$exclamation.addClass('qualifier-error');
				messages.push(lang.account.messages.QUALIFIER_ERROR);
			}

			if (agentStatus === 'AGENT_DOWN') {
				$exclamation.addClass('agent-down');
				messages.push(lang.account.messages.AGENT_WARNING);
			}
			else if (agentStatus === 'AGENT_MAINTENANCE') {
				if (!qualifierError) {
					$exclamation = $(Utils.renderWrench());
				}
				$exclamation.addClass('agent-maintenance');
				messages.push(lang.account.messages.AGENT_MAINTENANCE)
			}

			if (assetMaintenance) {
				if (!qualifierError) {
					$exclamation = $(Utils.renderWrench());
					$exclamation.css({'margin': '1px', 'font-size': '14px'});
				}
				messages.push(lang.account.messages.ASSET_MAINTENANCE)
			}
			$exclamation.attr('title', messages.join(' / '));
			$span.append($exclamation);
		}

		return $span;
	},

	summaryServiceStatus: function (states) {
		let statusClass = Utils.getStateClass(states.serviceState);
		let agentStatus = states.agentStatus;
		let operatingState = states.operatingState;
		let qualifierError = states.qualifierError;
		let assetMaintenance = states.assetMaintenance;

		let $span = this._renderSummaryState(statusClass, operatingState, agentStatus, qualifierError, assetMaintenance);

		return $span.prop('outerHTML')
	},

	/**
	 * Renderer function for the account name
	 * @param {String} accName
	 * @return {String} html
	 */
	accountName: function (accName, accId) {
		var html = '', currentAccount = Cookies.CeesoftCurrentAccountName;
		var cssClass = State.mainApp && State.mainApp.session.hasRole('ACCOUNT_READ') ? 'cw_grid_link' : '';
		if (accName === currentAccount) {
			html += '<a class="cw_current_account_grid cw_account_name pointer ' + accName + '" data-link="account" data-uid="' + Cookies.CeesoftCurrentAccountId + '">' + accName + '</a>';
		} else {
			html += '<a class="' + cssClass + ' cw_account_name pointer" data-link="account" data-uid="' + accId + '">' + accName + '</a>';
		}
		return html;
	},
	/**
	 * Renderer function for the state filter dropdown
	 * @param {Object} dataItem
	 * @return {String} html
	 */
	summaryStateFilter: function (dataItem) {
		var html = '';
		switch (dataItem.value) {
			case 0:
				html = '<span class="glyphicons status_icon remove-sign"></span> ' + lang.DOWN;
				break;
			case 1:
				html = '<span class="glyphicons status_icon ok-sign"></span>' + lang.UP;
				break;
			case 2:
				html = '<span class="cw_status_indicator cw_status_widget_color cw_color6"></span> ' + lang.UNAVAILABLE;
				break;
			default:
				html = dataItem.text;
		}
		return html;
	},
	/**
	 * Renderer function for the summary agents status
	 * @param {Number} agentsDown
	 * @param {Number} agentsWarning
	 * @param {Number} agentsTotal
	 * @return {String} html
	 */
	summaryAgentsStatus: function (agentsDown, agentsWarning, agentsTotal) {
		var title;

		if (agentsDown) {
			title = agentsDown + ' ' + lang.OUT_OF + ' ' + agentsTotal + ' ' + lang.summary.AGENTS_DOWN;
		} else if (agentsWarning) {
			title = agentsWarning + ' ' + lang.OUT_OF + ' ' + agentsTotal + ' ' + lang.summary.AGENTS_WARNING;
		} else {
			title = lang.summary.ALLAGENTS_UP;
		}

		var html = '<div class="cw_status_widget cw_agent_status pointer" title="' + title + '">';
		if (agentsDown || agentsWarning || agentsTotal) {
			if (agentsDown) {
				html += '<span class="glyphicons status_icon remove-sign pointer"></span>';
			} else if (agentsWarning) {
				html += '<span class="glyphicons status_icon exclamation-sign pointer"></span>';
			} else {
				html += '<span class="glyphicons status_icon ok-sign pointer pointer"></span>';
			}
		} else {
			html += '<span class="cw_status_indicator cw_status_widget_color cw_color6 pointer"></span>';
		}
		if (agentsDown) {
			html += agentsDown + '/' + agentsTotal;
		} else if (agentsWarning) {
			html += agentsWarning + '/' + agentsTotal;
		} else {
			html += agentsTotal;
		}
		html += '</div>';

		return html;
	},
	/**
	 * Renders the agent state
	 * @param {String} state Agent state
	 * @param {Number} lastConnect
	 * @return {String} html
	 */
	agentState: function (state, status, lastConnect) {
		var html = '', icon = '', statusClass = '';
		var elementTitle = '';
		if (!isNaN(lastConnect)) {
			elementTitle = Renderer.localDate(lastConnect, Constants.DATETIME);
		}

		if (status === 'MAINTENANCE') {
			statusClass = 'is_idle';
			icon = 'glyphicons wrench';
			elementTitle = lang.account.messages.AGENT_MAINTENANCE;
		} else if (state === 'OK') {
			icon = 'glyphicons status_icon ok';
			statusClass = 'is_ok';
		} else if (state === 'ERROR') {
			icon = 'glyphicons status_icon remove';
			statusClass = 'is_critical';
			elementTitle = lang.account.messages.AGENT_DOWN;
		} else if (state === 'WARNING') {
			icon = 'glyphicons status_icon exclamation-mark';
			statusClass = 'is_major';
			elementTitle = lang.account.messages.AGENT_WARNING;
		}

		html += '<span class="cw_agent_indicator cw_status ' + statusClass + '">';
		html += '<span title="' + elementTitle + '" class="cw_indicator ' + icon + '"></span>';
		html += '</span>';

		return html;
	},
	/*
	 * Handler functiuon for rendering pilbox agent state
	 * @param {String} state Agent state
	 * @values OK, ERROR, WARNING, MAINTENANCE
	 * @param {String} monitorType
	 * */
	renderPilboxAgentState: function (state, monitorType) {
		var title, cssClass = '';
		if (state === 'ERROR') {
			title = lang.account.messages.AGENT_DOWN;
			cssClass = 'remove-sign';
		} else if (state === 'WARNING') {
			title = lang.account.messages.AGENT_WARNING;
			cssClass = 'exclamation-sign';
		} else if (state === 'MAINTENANCE') {
			title = lang.account.messages.AGENT_MAINTENANCE;
			cssClass = 'wrench';
		}

		var html = '';
		html += '<div data-type="' + monitorType + '" class="cw_status_widget pointer text_center" title="' + title + '">';
		html += '<span class="left glyphicons status_icon ' + cssClass + '"></span>';
		html += lang.AGENT;
		html += '</div>';

		return html;
	},
	/**
	 * Renders generic progress bar
	 * @param {string} renderTo
	 * @param {string} cssClass
	 * @param {Number} maxValue
	 * @param {Number} currentValue
	 * @param {string} progressColor
	 * @param {string} unit - optional measure unit
	 * @return {String} html
	 */
	genericProgress: function (renderTo, cssClass, maxValue, currentValue, progressColor, unit) {
		var html = '',
			currentPercentage = currentValue * 100 / maxValue;
		if (unit && unit === 'bytes') {
			html += '<div class="cw_progress ' + cssClass + '" title="' + Utils.humanizeBytes(maxValue) + '">';
			html += '<div class="cw_progress_slice" style="width:' + currentPercentage.toFixed(2) + '%; background-color:' + progressColor + '" title="' + Utils.humanizeBytes(currentValue) + '"></div>';
		} else {
			html += '<div class="cw_progress ' + cssClass + '">';
			html += '<div class="cw_progress_slice" style="width:' + currentPercentage.toFixed(2) + '%; background-color:' + progressColor + '"></div>';
		}
		html += '</div>';
		$('#' + renderTo).append(html);
	},
	/**
	 * Renders the service sla column in summary
	 * @param {String} slaAggregate
	 * @return {String} html
	 */
	summarySla: function (sla) {
		var icon = '', html = '';
		if (sla) {
			if (sla.total) {
				switch (sla.indicator) {
					case 'FAILED':
						icon = '<span class="glyphicons status_icon circle-arrow-down pointer add-tooltip" kendo-title="' + lang.BREACHED + '"></span>';
						break;
					case 'OK':
						icon = '<span class="glyphicons status_icon circle-arrow-top pointer" kendo-title="' + lang.COMPLIANT + '"></span>';
						break;
					case 'WARNING':
						icon = '<span class="glyphicons status_icon circle-arrow-right pointer add-tooltip" kendo-title="' + lang.WARNING + '"></span>';
						break;
				}
				html = icon;
			} else {
				html = '<span class="cw_status_indicator cw_status_widget_color cw_color6"></span>';
			}
		}
		return html;
	},
	/**
	 * Renders the service state column in summary
	 * @param {Number} srvStateIndex
	 * @return {String} html
	 */
	serviceState: function (srvStateIndex) {
		var html = '';
		if (srvStateIndex === 1) {
			html = '<span class="glyphicons status_icon ok-sign"></span>';
		} else if (srvStateIndex === 0) {
			html = '<span class="glyphicons status_icon remove-sign"></span>';
		} else {
			html = '<span class="cw_status_indicator cw_status_widget_color cw_color6"></span>';
		}
		return html;
	},
	/**
	 * Renders duration
	 * @param {Number} period - Milliseconds
	 * @param {Boolean }hideNA
	 * @return {String} html
	 */
	duration: function (period, hideNA, reactResult) {
		var html = '', duration, years, months, days, hours, minutes, seconds, milliseconds;
		let hasData = true;
		if (parseInt(period, 10) !== 0) {
			duration = moment.duration(parseInt(period, 10), 'ms');
			days = duration.days();
			hours = duration.hours();
			minutes = duration.minutes();
			seconds = duration.seconds();
			months = duration.months();
			years = duration.years();
			milliseconds = duration._milliseconds;
			var textArray = [];
			if (years && textArray.length < 2) {
				textArray.push(years + ' ' + (years === 1 ? lang.YEAR : lang.YEARS));
			}
			if (months && textArray.length < 2) {
				textArray.push(months + ' ' + (months === 1 ? lang.MONTH : lang.MONTHS));
			}
			if (days && textArray.length < 2) {
				textArray.push(days + ' ' + (days === 1 ? lang.DAY : lang.DAYS));
			}
			if (hours && textArray.length < 2) {
				textArray.push(hours + ' ' + (hours === 1 ? lang.HOUR : lang.HOURS));
			}
			if (minutes && textArray.length < 2) {
				textArray.push(minutes + ' ' + (minutes === 1 ? lang.MINUTE : lang.MINUTES));
			}
			if (seconds && textArray.length < 2) {
				textArray.push(seconds + ' ' + (seconds === 1 ? lang.SECOND : lang.SECONDS));
			}
			if (!textArray.length && milliseconds) {
				//round to 1 second
				textArray.push(1 + ' ' + lang.SECOND);
			}
			var s = textArray.join(' ');
			html = '<span data-s="' + seconds + '" data-ms="' + milliseconds + '">' + s.toLowerCase() + '</span>';
		} else {
			hasData = false;
			if (!hideNA) {
				html = '<span>N/A</span>';
			}
		}

		if (reactResult) {
			if (hasData) {
				return <span data-s={seconds} data-ms={milliseconds}>{s.toLowerCase()}</span>
			} else {
				if (!hideNA) {
					return <span>N/A</span>
				}
			}
		} else {
			return html;
		}
	},

	timer: function (period) {
		const duration = moment.duration(period, 'ms');

		let entries = [];
		function addNonZero(value, code, singularName, pluralName){
			if(value){
				entries.push({
					short: value + code,
					full: value + ' ' + (value == 1 ? singularName : pluralName)
				});
			}
		}

		addNonZero(duration.years(), 'y', lang.YEAR, lang.YEARS);
		addNonZero(duration.months(), 'm', lang.MONTH, lang.MONTHS);
		addNonZero(duration.days(), 'd', lang.DAY, lang.DAYS);
		addNonZero(duration.hours(), 'h', lang.HOUR, lang.HOURS);
		addNonZero(duration.minutes(), 'm', lang.MINUTE, lang.MINUTES);
		addNonZero(duration.seconds(), 's', lang.SECOND, lang.SECONDS);

		return {
			full: entries.map(x => x.full).join(' '),
			timer: entries.slice(0, 2).map(x => x.short).join(' ')
		};
	},

	/**
	 * Renders the service state indicator
	 * @param {Object} states Contains agentStatus, serviceState
	 * @param {String} cssClass
	 * @param {Number} coverage
	 * @returns {String} html
	 */
	serviceDetailsState: function (states, cssClass, coverage) {
		var state = 'ACTIVE', html = '', statusClass, icon = '';
		if (states.agentStatus === 'AGENT_DOWN') {
			state = 'AGENT_DOWN';
		} else if (states.state) {
			state = states.state;
		} else if (states.serviceState) {
			state = states.serviceState;
		} else {
			state = states.agentStatus;
		}

		if (coverage) {
			if (state === 'ACTIVE' && coverage < 100) {
				state = 'WARNING';
			}
		}
		statusClass = Utils.getStateClass(state);

		if (states.operatingState === 'IN_MAINTENANCE') {
			icon = '<span class="cw_indicator glyphicons wrench"></span></span>';
			statusClass = 'is_idle';
		} else if (states.operatingState === 'NON_OPERATIONAL') {
			icon = '<span class="cw_indicator glyphicons clock"></span></span>';
			statusClass = 'is_idle';
		}

		html = '<span class="cw_service_indicator ' + statusClass + '">' + icon + '</span>';

		return html;
	},
	/**
	 * Renders the service sla column in summary
	 * @param {Number} srvSlaIndex
	 * @return {String} html
	 */
	serviceSla: function (srvSlaIndex) {
		var icon = '';
		switch (srvSlaIndex) {
			case 1:
				icon = '<span class="glyphicons status_icon circle-arrow-top pointer"></span>';
				break;
			case 2:
				icon = '<span class="glyphicons status_icon circle-arrow-right pointer"></span>';
				break;
			case 3:
				icon = '<span class="glyphicons status_icon circle-arrow-down pointer"></span>';
				break;
			default:
				icon = '<span class="cw_status_indicator cw_status_widget_color cw_color6"></span>';
		}
		return icon;
	},
	/**
	 * Renders the service health worst case column in summary
	 * @param (String} cssClass
	 * @param {Object} serviceHealth
	 * @return {String} html
	 */
	summaryWorstCase: function (serviceHealth, cssClass, warning) {
		var color = 6, html, warningClass;
		var messages = [];
		if (serviceHealth.total > 0) {
			if (serviceHealth.critical > 0) {
				color = 1;
				warningClass = 'critical';
				messages.push(lang.account.messages.MONITOR_ERROR_UNAVAILABLE);
			} else if (serviceHealth.major > 0) {
				color = 2;
				warningClass = 'major';
			} else if (serviceHealth.minor > 0) {
				color = 3;
				warningClass = 'minor';
			} else if (serviceHealth.ok > 0) {
				color = 5;
				warningClass = 'ok';
			} else if (serviceHealth.noHealth > 0 || serviceHealth.maintenance) {
				color = 6;
				warningClass = 'maintenance';
				messages.push(lang.account.messages.AGENT_MAINTENANCE);
			}
		}
		html = '<span class="cw_status_indicator pointer cw_status_widget_color cw_color' + color + ' ' + (cssClass || '') + ' ' + warningClass + '" kendo-title="' + messages.join(' / ') + '">';
		if (warning) {
			messages.push(lang.account.messages.AGENT_WARNING);
			html += '<span class="glyphicons exclamation-mark qualifier-error add-tooltip" kendo-title="' + messages.join(' / ') + '"></span>';
		}
		html += '</span>';
		return html;
	},
	/*
	* handler function for rendering the
	* @param {Object} stateAggregate The object storing the data about states
	* @param {String} renderTo The selector where the method is applied
	* */
	serviceBarsIcons: function (stateAggregate, renderTo) {
		var context = $(renderTo);
		var barDownHandler = context.find('.cw_bar.is_critical');
		var barWarningHandler = context.find('.cw_bar.is_major');
		var barUpHandler = context.find('.cw_bar.is_ok');

		barUpHandler.find('.cw_up_agent_error').remove();
		if (stateAggregate.upWithError && !$('.cw_service_indicator.cw_agent_error.exclamation-mark-circle.cw_up_agent_error.pointer', barUpHandler).length) {
			barUpHandler.append(`<span class="cw_service_indicator cw_agent_error exclamation-mark-circle cw_up_agent_error pointer"></span>`);
			const element = $('.cw_service_indicator.cw_agent_error.exclamation-mark-circle.cw_up_agent_error.pointer', barUpHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.SERVICES_AGENT_DOWN;
				}
			});
		}

		barWarningHandler.find('.cw_warning_agent_error').remove();
		if (stateAggregate.warningWithError && !$('.cw_service_indicator.cw_agent_error.exclamation-mark-circle.cw_warning_agent_error.pointer', barWarningHandler).length) {
			barWarningHandler.append(`<span class="cw_service_indicator cw_agent_error exclamation-mark-circle cw_warning_agent_error pointer"></span>`);
			const element = $('.cw_service_indicator.cw_agent_error.exclamation-mark-circle.cw_warning_agent_error.pointer', barWarningHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.SERVICES_AGENT_DOWN;
				}
			});
		}

		barDownHandler.find('.cw_down_agent_error').remove();
		if (stateAggregate.downWithError && !$('.cw_service_indicator.cw_agent_error.exclamation-mark-circle.cw_down_agent_error.pointer', barDownHandler).length) {
			barDownHandler.append(`<span class="cw_service_indicator cw_agent_error exclamation-mark-circle cw_down_agent_error pointer"></span>`);
			const element = $('.cw_service_indicator.cw_agent_error.exclamation-mark-circle.cw_down_agent_error.pointer', barDownHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.SERVICES_AGENT_DOWN;
				}
			});
		}
	},
	/*
	 * handler function for rendering the
	 * @param {Object} stateAggregate The object storing the data about states
	 * @param {String} renderTo The selector where the method is applied
	 * */
	assetBarsIcons: function (health, renderTo) {
		var context = $(renderTo);
		var barDownHandler = context.find('.cw_bar.is_critical');
		var barWarningHandler = context.find('.cw_bar.is_major');
		var barMinorHandler = context.find('.cw_bar.is_minor');
		var barUpHandler = context.find('.cw_bar.is_ok');
		var barInactiveHandler = context.find('.cw_bar.is_idle');

		if (health.criticalFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_critical_flag.pointer', barDownHandler).length) {
			barDownHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_critical_flag pointer"></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_critical_flag.pointer', barDownHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
		if (health.majorFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_major_flag.pointer', barWarningHandler).length) {
			barWarningHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_major_flag pointer" ></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_major_flag.pointer', barWarningHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
		if (health.minorFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_minor_flag.pointer', barMinorHandler).length) {
			barMinorHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_minor_flag pointer"></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_minor_flag.pointer', barMinorHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
		if (health.okFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_ok_flag.pointer', barUpHandler).length) {
			barUpHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_ok_flag pointer"></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_ok_flag.pointer', barUpHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
		if (health.inactiveFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_inactive_flag.pointer', barInactiveHandler).length) {
			barInactiveHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_inactive_flag pointer" ></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_inactive_flag.pointer', barInactiveHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
		$('#cw_account_health_chart').find('.cw_service_indicator').on('click', $.proxy(this.onAssetHealthClick, this));
	},

	applicationBarsIcons(health, renderTo) {
		let context = $(renderTo);
		let barDownHandler = context.find('.cw_bar.is_critical');
		let barWarningHandler = context.find('.cw_bar.is_major');
		let barMinorHandler = context.find('.cw_bar.is_minor');
		let barUpHandler = context.find('.cw_bar.is_ok');
		let barInactiveHandler = context.find('.cw_bar.is_idle');

		if (health.criticalFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_critical_flag.pointer', barDownHandler).length) {
			barDownHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_critical_flag pointer"></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_critical_flag.pointer', barDownHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
		if (health.majorFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_major_flag.pointer', barWarningHandler).length) {
			barWarningHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_major_flag pointer"></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_major_flag.pointer', barWarningHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
		if (health.minorFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_minor_flag.pointer', barMinorHandler).length) {
			barMinorHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_minor_flag pointer"></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_minor_flag.pointer', barMinorHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
		if (health.okFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_ok_flag.pointer', barUpHandler).length) {
			barUpHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_ok_flag pointer"></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_ok_flag.pointer', barUpHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
		if (health.inactiveFlag && !$('.cw_service_indicator.exclamation-mark-circle.cw_inactive_flag.pointer', barInactiveHandler).length) {
			barInactiveHandler.append(`<span class="cw_service_indicator exclamation-mark-circle cw_inactive_flag pointer"></span>`);
			const element = $('.cw_service_indicator.exclamation-mark-circle.cw_inactive_flag.pointer', barInactiveHandler);
			element.data("kendoTooltip")?.destroy();
			element.kendoTooltip({
				content: function (event) {
					return lang.messages.ASSET_DOWN;
				}
			});
		}
	},

	/**
	 * Renders the System Monitor column in Asset grid
	 * @param {Object} config
	 * @return {String} html
	 */
	monitor: function (config, isMonitorColumn) {
		var template = '', assetConsole = '', assetConsoleClass = '', colorIndex, statusClass = '', title, value;
		config.item = config.item || {};
		if (config.item.monitors) {
			var currentMonitors = config.item.monitors;
			var currMonitor;
			for (var i = 0; i < currentMonitors.length; i++) {
				if (currentMonitors[i].type === config.currentType) {
					currMonitor = currentMonitors[i];
				}
			}
		}

		if (currMonitor) {
			config.monitorIndex = currMonitor.healthIndex || config.monitorIndex;
			config.dataUnavailable = currMonitor.dataUnavailable || config.dataUnavailable;
			config.monitorError = currMonitor.monitorError || config.monitorError;
			config.monitorMaintenance = currMonitor.monitorMaintenance || config.monitorMaintenance;
			config.monitorId = currMonitor.id || config.monitorId;
		}
		config.showAssetConsoleButton = config.showAssetConsoleButton || false;
		config.cssClass = config.cssClass || '';

		if (config.monitorIndex >= -1) {
			if (this.i === undefined) {
				this.i = 0;
			} else {
				this.i++;
			}
			if (config.showAssetConsoleButton && !config.dataUnavailable && State.mainApp && State.mainApp.session.hasRole('MONITOR_READ')) {
				assetConsole = '<span class="cw_assetconsole_details glyphicons target"></span>';
				assetConsoleClass = 'has_asset';
			}

			if (config.monitorIndex < 0) {
				colorIndex = 6;
				statusClass = "is_idle";
			} else if (config.monitorIndex >= 0 && config.monitorIndex < 25) {
				colorIndex = 1;
				statusClass = "is_critical";
			} else if (config.monitorIndex >= 25 && config.monitorIndex < 50) {
				colorIndex = 2;
				statusClass = "is_major";
			} else if (config.monitorIndex >= 50 && config.monitorIndex < 75) {
				colorIndex = 3;
				statusClass = "is_minor";
			} else if (config.monitorIndex >= 75 && config.monitorIndex < 101) {
				colorIndex = 5;
				statusClass = "is_ok";
			}
			var pointerClass = '';
			if ((config.assetType === 'AGENT' && State.mainApp && State.mainApp.session.hasRole('AGENT_LIST'))
				|| (config.assetType !== 'ASSET' && State.mainApp && State.mainApp.session.hasRole('MONITOR_LIST'))) {
				if(config.noPointer != true) {
					pointerClass = 'pointer';
				}

			}

			if (config.agentState === 'MAINTENANCE') {
				statusClass = "is_idle";
			}
			var templateDataType = config.currentType || config.type;
			let maintenanceTooltip = [], tooltip;
			if ((isMonitorColumn && config.monitorIndex >= 0) || !isMonitorColumn ) {
				template += '<div class="cw_pillbox ' + pointerClass + ' ' + statusClass + ' ' + config.cssClass + ' ' + assetConsoleClass + '" data-type="' + templateDataType + '">';
				if ((config.assetMaintenance && config.targetType !== 'ASSET_GROUP') || config.agentState === 'MAINTENANCE' || (config.monitorMaintenance && config.monitorMaintenanceHasPriority)) {
					maintenanceTooltip = [];
					if (config.assetMaintenance) {
						maintenanceTooltip.push(lang.account.messages.ASSET_MAINTENANCE);
						tooltip = maintenanceTooltip.join(' | ');
					}
					if (config.monitorMaintenance) {
						maintenanceTooltip.push(lang.account.messages.MONITOR_MAINTENANCE);
						tooltip = maintenanceTooltip.join(' | ');
					}
					template += '<div class="cw_status glyphicons wrench monitor_grid_maintenance" cached-title="' + tooltip + '" title="' + tooltip + '">';
				} else {
					template += '<div class="cw_status">';
					if (config.agentState === 'ERROR') {
						maintenanceTooltip = [lang.account.messages.AGENT_ERROR];
						if (config.assetMaintenance) {
							maintenanceTooltip.push(lang.account.messages.ASSET_MAINTENANCE);
						}
						if (config.monitorMaintenance) {
							maintenanceTooltip.push(lang.account.messages.MONITOR_MAINTENANCE);
						}
						if (config.monitorError) {
							maintenanceTooltip.push(lang.account.messages.MONITOR_ERROR);
						}
						const errorTooltip = maintenanceTooltip.join(' | ');
						template += '<span class="cw_indicator glyphicons remove asset-error-x" cached-title="' + errorTooltip + '" title="' + errorTooltip + '"></span>';
					} else if (config.dataUnavailable || config.monitorDataUnavailable) {
						if (config.agentState === 'WARNING') {
							title = lang.account.messages.AGENT_WARNING + ' | ' + lang.account.messages.MONITOR_ERROR_UNAVAILABLE;
						} else {
							if (config.assetMaintenance) {
								title = lang.account.messages.MONITOR_ERROR_UNAVAILABLE + ' | ' + lang.account.messages.ASSET_MAINTENANCE;
							} else {
								title = lang.account.messages.MONITOR_ERROR_UNAVAILABLE;
							}
						}
						template += '<span class="cw_indicator glyphicons exclamation-mark" cached-title="' + title + '" title="' + title + '"></span>';
					} else if (config.agentState === 'WARNING') {
						title = lang.account.messages.AGENT_WARNING;
						if (config.monitorError) {
							title += ' | ' + lang.account.messages.MONITOR_ERROR;
						}
						template += '<span class="cw_indicator glyphicons exclamation-mark" cached-title="' + title + '" title="' + title + '"></span>';
					} else if (config.monitorError) {
						maintenanceTooltip = [];
						maintenanceTooltip.push(lang.account.messages.MONITOR_ERROR);
						if (config.assetMaintenance) {
							maintenanceTooltip.push(lang.account.messages.ASSET_MAINTENANCE);
						}
						if (config.monitorMaintenance) {
							maintenanceTooltip.push(lang.account.messages.MONITOR_MAINTENANCE);
						}
						maintenanceTooltip = maintenanceTooltip.join(' | ');
						template += '<span class="cw_indicator glyphicons exclamation-mark" cached-title="' + maintenanceTooltip + '" title="' + maintenanceTooltip + '"></span>';
					} else if (config.assetMaintenance) {
						maintenanceTooltip = [];
						maintenanceTooltip.push(lang.account.messages.ASSET_MAINTENANCE);
						if (config.monitorMaintenance) {
							maintenanceTooltip.push(lang.account.messages.MONITOR_MAINTENANCE);
						}
						maintenanceTooltip = maintenanceTooltip.join(' | ');
						template += '<span class="glyphicons wrench white" cached-title="' + maintenanceTooltip + '" title="' + maintenanceTooltip + '"></span>';
					} else if (config.monitorMaintenance) {
						maintenanceTooltip.push(lang.account.messages.MONITOR_MAINTENANCE);
						template += '<span class="glyphicons wrench white" cached-title="' + maintenanceTooltip + '" title="' + maintenanceTooltip + '"></span>';
					}
				}
				value = config.monitorIndex >= 0 ? config.monitorIndex : 'N/A';
				template += '</div>';
				template += '<span class="cw_value">' + value + '</span>';
				template += assetConsole;
				template += '</div>';
			}
		}
		return template;
	},



	severity: function (severityType, item) {
		renderSeverityTag(severityType, item);
	},
	/*
	 * Handler function for rendering the severity state
	 * */
	// severityState: function (type, severity, operatingState, agentState) {
	// 	var html = '', statusClass = '', icon = '';
	// 	switch (severity) {
	// 		case 'NONE':
	// 			statusClass = "is_ok";
	// 			break;
	// 		case 'MINOR':
	// 			statusClass = "is_minor";
	// 			break;
	// 		case 'MAJOR':
	// 			statusClass = "is_major";
	// 			break;
	// 		case 'CRITICAL':
	// 			statusClass = "is_critical";
	// 			break;
	// 	}
	// 	html += '<span class="cw_event_indicator cw_status ' + statusClass + '">';
	// 	if (type === 'ASSET') {
	// 		if (agentState === 'MAINTENANCE' || operatingState === 'IN_MAINTENANCE') {
	// 			icon = '<span class="cw_indicator glyphicons wrench" title="' + lang.account.messages.AGENT_MAINTENANCE + '"></span>';
	// 		}
	// 		if (agentState === 'WARNING') {
	// 			icon = '<span class="cw_indicator glyphicons exclamation-mark" title="' + lang.account.messages.AGENT_WARNING + '"></span>';
	// 		}
	// 		if (agentState === 'ERROR') {
	// 			icon = '<span class="cw_indicator glyphicons remove" title="' + lang.account.messages.AGENT_DOWN + '"></span>';
	// 		}
	// 	}
	// 	html += icon;
	// 	html += '</span>';
	// 	return html;
	// },

	/**
	 * Renders the asset status
	 * @param {Number} assetHI
	 * @param {String} agentState
	 * @param {Boolean} monitorError
	 * @param {Boolean} assetMaintenance
	 * @return {String} html
	 */
	assetStatus: function (assetHI, agentState, monitorError, assetMaintenance, monitorDataUnavailable, monitorMaintenance) {
		var html = '', statusClass = '', title, realTitle;
		if (assetMaintenance || agentState === 'MAINTENANCE') {
			realTitle = lang.account.messages.ASSET_MAINTENANCE;
			html += '<div class="cw_status_indicator is_round glyphicons wrench asset_maintenance">';
		} else {
			if (assetHI < 0) {
				statusClass = 'is_idle';
			}
			if (assetHI >= 0 && assetHI < 25) {
				statusClass = 'is_critical';
			}
			if (assetHI >= 25 && assetHI < 50) {
				statusClass = 'is_major';
			}
			if (assetHI >= 50 && assetHI < 75) {
				statusClass = 'is_minor';
			}
			if (assetHI >= 75 && assetHI < 101) {
				statusClass = 'is_ok';
			}
			html += '<div id="asset_status_indicator" class="cw_status_indicator is_round ' + statusClass + '">';
			if (agentState === 'ERROR') {
				html += '<span class="cw_indicator glyphicons remove"></span>';
			} else if (agentState === 'WARNING') {
				if (monitorDataUnavailable) {
					title = lang.account.messages.AGENT_WARNING + ' | ' + lang.account.messages.MONITOR_ERROR_UNAVAILABLE;
				} else if (monitorError) {
					title = lang.account.messages.AGENT_WARNING + ' | ' + lang.account.messages.MONITOR_ERROR;
				}
				if (title) {
					html += '<span class=" cw_indicator glyphicons exclamation-mark" cached-title="' + title + '"></span>';
				}
			} else {
				if (monitorDataUnavailable) {
					title = lang.account.messages.MONITOR_ERROR_UNAVAILABLE;
				} else if (monitorError) {
					title = lang.account.messages.MONITOR_ERROR;
				}
				if (title) {
					html += '<span class=" cw_indicator glyphicons exclamation-mark" cached-title="' + title + '"></span>';
				} else if (monitorMaintenance) {
					html = '';
					realTitle = lang.account.messages.MONITOR_MAINTENANCE;
					html += '<div class="cw_status_indicator is_round glyphicons wrench monitor_maintenance ' + statusClass + '">';
				}
			}
		}
		html += '</div>';
		const element = $(html);
		if (realTitle) {
			element.kendoTooltip({
				content: function (event) {
					return realTitle
				}
			});
		}
		return element;
	},
	/**
	 * Renders the State Widget
	 * @param {number} assetHI
	 * @return {String} html
	 */
	stateWidgetColorClass: function (assetHI) {
		var colorIndex;
		if (assetHI < 0) {
			colorIndex = 6;
		}
		if (assetHI >= 0 && assetHI < 25) {
			colorIndex = 1;
		}
		if (assetHI >= 25 && assetHI < 50) {
			colorIndex = 2;
		}
		if (assetHI >= 50 && assetHI < 75) {
			colorIndex = 3;
		}
		if (assetHI >= 75 && assetHI < 101) {
			colorIndex = 5;
		}
		return 'bg_color' + colorIndex;
	},
	/**
	 * Renders the targets in asset column
	 * @param {Array} targets
	 * @return {String} result
	 */
	assetTargets: function (targets) {
		var result = '',
			i,
			length = targets.length;
		if (length) {
			for (let i = 0; i < length - 1; i++) {
				result += targets[i] + ', ';
			}
			result += targets[length - 1];
		}
		return result;
	},
	/**
	 * Renders the expected state
	 * @param {String} value
	 * @return {String} text
	 */
	expectedState: function (value) {
		return value;
	},
	/**
	 * Renderer function for the agentCOnfiguration value column
	 * @param {String} value
	 * @param {String} defaultValue
	 * @param {String} id
	 * @return {String} htmlCode
	 */
	agentConfiguration: function (value, defaultValue, id) {
		var htmlCode = '',
			val = value || '',
			defVal = defaultValue || '',
			params = 'data-id="' + id + '" ',
			checked;
		if (value === 'true' || value === 'false') {
			checked = (value === 'true' ? 'checked="checked"' : '');
			htmlCode = '<input type="checkbox" class="cw_grid_input w95" ' + checked + ' data-type="boolean" data-orig="' + value + '" ' + params + ' />';
		} else {
			if (value === null) {
				htmlCode = '<input type="text" class="cw_grid_input w95" value="" data-orig="null" ' + params + ' />';
			} else {
				value = value.replace(/"/g, '&quot;');
				htmlCode = '<input type="text" class="cw_grid_input w95" value="' + value + '" data-orig="' + value + '" ' + params + ' />';
			}
		}
		if (defVal !== val) {
			htmlCode += '<span style="margin-left: 15px;" class="cw_hint glyphicons info-sign" data-info="' + defVal + '" />';
		} else {
			htmlCode += '<span class="cw_hint glyphicons" data-info="' + defVal + '" />';
		}
		return htmlCode;
	},
	/**
	 * Renderer function for the agentConfiguration tooltip
	 * @param {String} value
	 * @param {String} defaultValue
	 * @return {String} htmlCode
	 */
	agentConfigurationTooltip: function (value, defaultValue) {
		var htmlCode = lang.DEFAULT_VALUE + ': ';
		if (value !== 'null' && defaultValue !== 'null' && value !== '' && defaultValue !== '') {
			//htmlCode += '<strong>' + defaultValue + '</strong>';
			htmlCode += defaultValue;
		} else {
			htmlCode += lang.NO_VALUE;
		}

		return htmlCode;
	},
	/**
	 * Renderers the privacy column
	 * @param {boolean} isPublic
	 * @return {String} html
	 */
	showPrivacyIcon: function (isPublic) {
		var html = '';
		if (isPublic) {
			html = '<span class="glyphicons globe" title="' + lang.PUBLIC + '"></span>';
		} else {
			html = '<span class="glyphicons user-lock" title="' + lang.PRIVATE + '"></span>';
		}
		//leave blank otherwise
		return html;
	},
	/**
	 * Renderers external monitor url column
	 * @param {boolean} isPublic
	 * @return {String} html
	 */
	externalMonitorUrl: function (assetId, targetType, webServiceURL) {
		var url;
		if (targetType === 'ASSET') {
			url = webServiceURL;
		} else {
			url = webServiceURL + (assetId ? '&assetId=' + assetId : '') + '&value=';
		}
		return url;
	},

	renderDynamicProperties: function (properties) {
		'use strict';

		let html = '';

		for (let i = 0; i < properties.length; i++) {
			let inputValue = '';
			if (properties[i].value === 'null') {
				properties[i].value = '';
			}
			let selector = "#" + properties[i].uuid;
			// add properties
			switch (properties[i].type) {
				case 'CETextInput':
					if (properties[i].value) {
						inputValue = properties[i].value || '';
						if (properties[i].password) {
							inputValue = '*************';
						}
						html += '<div class="cw_field"><label title="' + properties[i].tooltip + '" class="left">' + properties[i].caption + ':</label> <span class="cw_sq_component">' + inputValue + '</span></div>';
					}
					break;
				case 'CERange':
					if (properties[i].value) {
						inputValue = properties[i].value || '';
						html += '<div class="cw_field"><label title="' + properties[i].tooltip + '" class="left"> ' + properties[i].caption + ':</label> <span class="cw_sq_component">' + inputValue + '</span></div>';
					}
					break;
				case 'CESelectionMulti':
					if (properties[i].values.length > 0) {
						html += '<div class="cw_field"><label title="' + properties[i].tooltip + '" class="left"> ' + properties[i].caption + ':</label>';
						html += '<span class="cw_sq_component">';

						if (properties[i].description) {
							var descriptionObjects, shownDescription;
							var descriptionTexts = [];
							if (Utils.isJSON(properties[i].description)) {
								descriptionObjects = JSON.parse(properties[i].description);
								for (var j = 0; j < descriptionObjects.length; j++) {
									descriptionTexts.push(descriptionObjects[j].text)
								}
								shownDescription = descriptionTexts.join(', ');
							} else {
								shownDescription = properties[i].description;
							}
							html += shownDescription;
							html += '</span></div>';
						}
					}
					break;
				case 'CECheckBox':
					html += '<div class="cw_field"><label title="' + properties[i].tooltip + '" class="left"> ' + properties[i].caption + ':</label>';
					html += '<span class="cw_sq_component">' + (properties[i].value === 'true' ? 'yes' : 'no') + '</span></div>';
					break;
				case 'CESelection':
					let selection = properties[i].description;
					if (selection) {
						html += '<div class="cw_field"><label title="' + properties[i].tooltip + '" class="left"> ' + properties[i].caption + ':</label>';
						if (properties[i].id === 'monitorId') {
							//monitorType doesn't exist, waiting to be provided
							html += '<span class="cw_sq_component cw_link cw_monitor_name" data-id="' + properties[i].value + '" data-type="' + properties[i].monitorType + '">' + selection + '</span></div>';
						} else if (properties[i].id === 'groupId') {
							html += '<span class="cw_sq_component cw_link cw_assetgroup_name" data-id="' + properties[i].value + '"data-name="' + selection + '">' + selection + '</span></div>';
						} else {
							html += '<span class="cw_sq_component">' + selection + '</span></div>';
						}
					}
					break;
				case 'CELabel':
					if (properties[i].value) {
						html += '<div class="cw_field"><label title="' + properties[i].tooltip + '" class="left"> ' + properties[i].caption + ':</label>';
						html += '<span class="cw_sq_component">' + properties[i].value + '</span></div>';
					}
					break;
				case 'CEAuthentication':
					let auth = properties[i].value;
					if (auth) {
						html += '<div class="cw_field"><label title="' + properties[i].tooltip + '" class="left"> ' + properties[i].caption + ':</label>';
						html += '<span class="cw_sq_component">' + auth + '</span></div>';
					}
					break;
			}
		}

		return html;
	},

	renderWarning: function (configuration) {
		'use strict';

		let html = '';

		if (!configuration.warning) {
			return html;
		}

		html += '<div class="cw_field"><label class="left">'
			+ lang.designer.SERVICE_QUALIFIERS_SETTINGS_WARNING_OPERATOR
			+ ': </label><span class="cw_sq_component">' + configuration.warning.operator + '</span></div>';

		html += '<div class="cw_field"><label class="left">'
			+ lang.designer.SERVICE_QUALIFIERS_SETTINGS_WARNING_THRESHOLD
			+ ': </label><span class="cw_sq_component">' + configuration.warning.threshold + '</span></div>';

		return html;
	},
	/**
	 * Generate the HTML code for the SQ preview
	 * @param {Object} qualifier The SQ
	 * @return {String} html The preview HTML code
	 */
	sqPreview: function (qualifier) {

		var html = '';
		html += '<div class="cw_field"><label class="left">' + lang.NAME + ': </label><span class="cw_sq_component">' + qualifier.name + '</span></div>';
		html += '<div class="cw_field"><label class="left">' + lang.DESCRIPTION + ': </label><span class="cw_sq_component">' + (qualifier.description || '') + '</span></div>';
		html += '<div class="cw_field"><label class="left">' + lang.CONFIGURATION_NAME + ': </label><span class="cw_sq_component">' + (qualifier.configuration.configurationName || '') + '</span></div>';
		html += '<div class="cw_field"><label class="left">' + lang.AGENT_ACCOUNT + ': </label><span class="cw_sq_component">' + qualifier.configuration.accountName + '</span></div>';
		html += '<div class="cw_field"><label class="left">' + lang.AGENT + ': </label>';
		if (qualifier.agentStatus === 'AGENT_DOWN') {
			html += '<span class="cw_agent_indicator cw_status is_critical cw_agent_down_icon"><span class="cw_indicator glyphicons status_icon remove"></span></span>';
			html += '<span class="cw_sq_component cw_link cw_agent_name cw_agent_down_name" data-agentid="' + qualifier.configuration.agentId + '"data-name="' + qualifier.configuration.agentName + '" data-accountid="' + qualifier.configuration.accountId + '" data-accountname="' + qualifier.configuration.accountName + '" data-qualifierid="' + qualifier.id + '" >' + qualifier.configuration.agentName + '</span></div>';
		} else {
			html += '<span class="cw_sq_component cw_link cw_agent_name" data-agentid="' + qualifier.configuration.agentId + '"data-name="' + qualifier.configuration.agentName + '" data-accountid="' + qualifier.configuration.accountId + '" data-accountname="' + qualifier.configuration.accountName + '" data-qualifierid="' + qualifier.id + '" >' + qualifier.configuration.agentName + '</span></div>';
		}
		html += '<div class="cw_field"><label class="left">' + lang.ASSET_ACCOUNT + ': </label><span class="cw_sq_component">' + qualifier.configuration.assetAccountName + '</span></div>';
		html += '<div class="cw_field"><label class="left">' + lang.ASSET + ': </label><span class="cw_sq_component cw_link cw_asset_name" data-id="' + qualifier.configuration.assetId + '" data-name="' + qualifier.configuration.assetName + '" data-assetaccountid="' + qualifier.configuration.assetAccountId + '" data-assetaccountname="' + qualifier.configuration.assetAccountName + '">' + qualifier.configuration.assetName + '</span></div>';
		html += '<div class="cw_field"><label class="left">' + lang.TARGET + ': </label><span class="cw_sq_component">' + qualifier.configuration.targetName + '</span></div>';

		html += this.renderDynamicProperties(qualifier.configuration.properties);

		html += '<div class="cw_field"><label class="left">' + lang.designer.INTERVAL + ': </label><span class="cw_sq_component">' + qualifier.configuration.interval + '</span></div>';
		html += '<div class="cw_field"><label class="left">' + lang.designer.BREACHES + ': </label><span class="cw_sq_component">' + qualifier.configuration.breaches + '</span></div>';

		if (qualifier.configuration.algorithm) {
			html += '<div class="cw_field"><label class="left">' + lang.designer.ALGORITHM + ': </label><span class="cw_sq_component">' + qualifier.configuration.algorithm.type + '</span></div>';
			switch (qualifier.configuration.algorithm.type) {
				case 'DeltaThresholdAlgorithm':
				case 'StaticThresholdAlgorithm':
					html += '<div class="cw_field"><label class="left">' + lang.designer.OPERATOR + ': </label><span class="cw_sq_component">' + qualifier.configuration.algorithm.operator + '</span></div>';
					html += '<div class="cw_field"><label class="left">' + lang.designer.THRESHOLD + ': </label><span class="cw_sq_component">' + qualifier.configuration.algorithm.threshold + '</span></div>';
					break;
				case 'DynamicThresholdAlgorithm':
					html += '<div class="cw_field"><label class="left">' + lang.designer.FACTOR + ': </label><span class="cw_sq_component">' + qualifier.configuration.algorithm.factor + '</span></div>';
					break;
				case 'RangeAlgorithm':
					html += '<div class="cw_field"><label class="left">' + lang.MIN + ': </label><span class="cw_sq_component">' + qualifier.configuration.algorithm.min + '</span></div>';
					html += '<div class="cw_field"><label class="left">' + lang.MAX + ': </label><span class="cw_sq_component">' + qualifier.configuration.algorithm.max + '</span></div>';
					break;
			}
		}

		html += this.renderWarning(qualifier.configuration);

		if (!qualifier.hideQualifierId) {
			html += '<div class="cw_field"><label class="left">CVID: </label><span id="cw_cvid" class="cw_sq_component">' + qualifier.id + '</span></div>';
		}

		html += '<div class="cw_field"><label class="left">' + lang.CLASSNAME + ': </label><span class="cw_sq_component">' + qualifier.className + '</span></div>';

		return html;
	},
	/**
	 * Renders the asset indicator multiselect filter
	 * @param {Object} element Element where filter is applied
	 * @param {Object} dataSource Kendo DataSource that is filtered
	 */
	renderAssetIndicatorFilter: function (element) {
		var multiSelect = new MultiSelectGridFilter({
			element: element,
			field: 'assetIndicator',
			secondField: 'customFilter',
			monitorErrorField: this.monitorFiltering ? 'isMonitorError' : null,
			grid: this.gridSelector.data('kendoCustomGrid') || this.gridSelector.data('kendoCustomTreeList'),
			forceFilter: this.serverSorting || this.monitorFiltering ? [] : [{
				field: 'agentState',
				operator: 'eq',
				value: 'ERROR'
			}, {
				field: 'agentState',
				operator: 'eq',
				value: 'WARNING'
			}, {
				field: 'agentState',
				operator: 'eq',
				value: 'MAINTENANCE'
			}],
			dataSource: new kendo.data.DataSource({ data: this.monitorFiltering ? [{
				text: lang.CRITICAL,
				stateClass: 'is_critical',
				value: '0'
			}, {
				text: lang.MAJOR,
				stateClass: 'is_major',
				value: '1'
			}, {
				text: lang.MINOR,
				stateClass: 'is_minor',
				value: '2'
			}, {
				text: lang.OK,
				stateClass: 'is_ok',
				value: '3'
			}, {
				text: lang.account.messages.MONITOR_ERROR,
				imgClass: 'glyphicons exclamation-sign',
				stateClass: '',
				value: 'monError'
			}, {
				text: lang.MAINTENANCE,
				imgClass: 'glyphicons wrench',
				stateClass: '',
				value: '-3'
			}] : [{
				text: lang.CRITICAL,
				stateClass: 'is_critical',
				value: '3'
			}, {
				text: lang.MAJOR,
				stateClass: 'is_major',
				value: '2'
			}, {
				text: lang.MINOR,
				stateClass: 'is_minor',
				value: '1'
			}, {
				text: lang.OK,
				stateClass: 'is_ok',
				value: '0'
			}, {
				text: lang.account.messages.AGENT_DOWN,
				imgClass: 'glyphicons remove-sign',
				value: '-1',
				stateClass: ''
			}, {
				text: lang.account.messages.AGENT_WARNING,
				imgClass: 'glyphicons exclamation-sign',
				value: '-2',
				stateClass: ''
			}, {
				text: lang.MAINTENANCE,
				imgClass: 'glyphicons wrench',
				stateClass: '',
				value: '-3'
			}, {
				text: lang.account.messages.MONITOR_ERROR,
				imgClass: 'glyphicons exclamation-sign',
				stateClass: '',
				value: '-4'
			}], serverSorting: true}),
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			itemTemplate: '<div class="cw_filter_item"><div class="cw_status_indicator is_round #=data.stateClass# #if (data.imgClass) { #no_status# } #"># if (data.imgClass) { # <span class="cw_indicator #= data.imgClass #"></span> # } #</div><span class="cw_filter_label">${data.text}</span></div>',
			tagTemplate: '<div class="cw_filter_tag"><div class="cw_status_indicator is_round #=data.stateClass# #if (data.imgClass) { #no_status# } #"># if (data.imgClass) { # <span class="cw_indicator #= data.imgClass #"></span> # } #</div><span class="cw_filter_label">${data.text}</span></div>'
		});
	},
	filterByIndicator: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: 'assetIndicator', operator: operator, value: indicators[i]});
		}

		if (this.scope) {
			this.dataSource.filter(filter);
		} else {
			this.grid.dataSource.filter(filter);
		}
	},
	/**
	 * Handler function which draws the multiselect for filter column
	 * @param {Object} element Filterable ui object
	 * @param {String} field Name of column
	 * Scope of the function is:
	 * this.scope
	 * grid object is send with the scope
	 */
	filterGridByOwnElementDS: function (element, field, grid) {
		var menu = $(element).parent();
		let selector;
		if (grid) {
			selector = grid;
		} else if (this.grid) {
			selector = this.grid;
		} else {
			selector = this.treeList;
		}
		var data = selector.dataSource.data();
		var dataLength = data.length;
		var items = [];
		var value;
		var temItems = [];
		let filterHandler = selector.dataSource.filter();
		var filter = filterHandler || {filters: []};
		element.removeAttr("data-bind");

		//quickfix for asset summary group; to be further investigated
		if (!$(this.element).parent().parent().parent().is('body')) {
			$(this.element).parent().parent().parent().addClass('cw_filter_panel_custom');
		}
		for (var i = 0; i < dataLength; i++) {
			value = data[i][field];
			if (temItems.indexOf(value) === -1 && value) {
				temItems.push(value);

				items.push({
					text: value,
					value: value
				});
			}
		}

		temItems = null;
		var values = [];

		if (filter) {
			for (var i = 0, length = filter.filters.length; i < length; i++) {
				if (filter.filters[i] && filter.filters[i].filters) {
					for (var j = 0, length = filter.filters[i].filters.length; j < length; j++) {
						if (filter.filters[i].filters[j].field === field) {
							values.push(filter.filters[i].filters[j].value);
						}
					}
				}
			}
		}

		var multiselectValues = values;
		this.filteringMultiSelect = element.kendoSortedMultiSelect({
			dataSource: items || [],
			value: values,
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			itemTemplate: '<span>${data.text}</span>',
			tagTemplate: '<span class="cw_multiselect_item" title="${data.text}">${data.text}</span>',
			dataBound: $.proxy(function (e) {
				menu.find('[type=submit]').off().on('click', $.proxy(function (e) {
					e.preventDefault();
					if (dataLength) {
						var operator = menu.find("[data-role=dropdownlist]").data('kendoDropDownList').value(),
							values = multiselectValues;
						if (operator && values && values.length) {
							var currentFilterKey = -1;
							var filter = selector.dataSource.filter();
							var subFilters = [];
							if (filter) {
								var filters = filter.filters;
								for (var i = 0, length = filter.filters.length; i < length; i++) {
									if (filter.filters[i] && filter.filters[i].filters) {
										for (var j = 0, length = filter.filters[i].filters.length; j < length; j++) {
											if (filter.filters[i].filters[j].field === field) {
												currentFilterKey = i;
												break;
											}
										}
										if (currentFilterKey > -1) {
											break;
										}
									} else if (filter.filters[i].field === field) {
										currentFilterKey = i;
										break;
									}
								}

								if (currentFilterKey > -1) {
									filters.splice(currentFilterKey, 1);
								}
								filter.filters.push({
									logic: operator === 'eq' ? 'or' : 'and',
									filters: subFilters
								});
							} else {
								//no filter added - add it
								filter = {
									logic: 'and',
									filters: [{
										logic: operator === 'eq' ? 'or' : 'and',
										filters: subFilters
									}]
								};
							}

							$.each(values, function (i, v) {
								subFilters.push({field: field, operator: operator, value: v});
							});

							selector.dataSource.filter(filter);
						}
					}
				}, this));
				menu.find('[type=reset]').off().on('click', () => {
					setTimeout(() => {
						this.filteringMultiSelect.value([]);
					}, 500);
				})

			}, this),
			change: function (e) {
				multiselectValues = e.sender.value();
			}
		}).data('kendoSortedMultiSelect');
	},
	operatingState: function (state) {
		var states = {
			OPERATIONAL: lang.ACTIVE,
			NON_OPERATIONAL: lang.INACTIVE,
			IN_MAINTENANCE: lang.SERVICE_MAINTENANCE
		}

		return states[state] || '';
	},
	operatingStateClass: function (state) {
		var className = {
			OPERATIONAL: 'operational',
			NON_OPERATIONAL: 'non_operational',
			IN_MAINTENANCE: 'in_maintenance'
		}

		return className[state] || '';
	},
	/*
	 * Handler function for indicating the view is filtered by tags
	 * @param {Object} config Configuration properties
	 * @properties {Object} renderTo jQuery object where the indificator will be rendered
	 * @deprecated Move to Application
	 */
	tagIndicator: function (config) {
		console.error('tagIndicator is deprecated')
	},
	/*
	 * Returns detail type datasource
	 * @returns {Array}
	 * */
	getDetailTypeDS: function () {
		return [{
			template: '<span class="glyphicons status_icon chat"></span>',
			text: lang.service.MESSAGE,
			value: 'MESSAGE'
		}, {
			template: '<span class="glyphicons status_icon share-alt pointer"></span>',
			text: lang.service.MODEL_ERROR,
			value: 'MODEL_ERROR'
		}, {
			template: '<span class="glyphicons status_icon exclamation-sign"></span>',
			text: lang.service.NOTE,
			value: 'NOTE'
		}, {
			template: '<span class="glyphicons status_icon sort"></span>',
			text: lang.service.SERVICE_LOG,
			value: 'SERVICE_LOG'
		}, {
			template: '<span class="glyphicons status_icon ok"></span>',
			text: lang.service.SLA,
			value: 'SLA'
		}, {
			template: '<span class="glyphicons status_icon lightbulb pointer"></span>',
			text: lang.STATE,
			value: 'STATE'
		}, {
			template: '<span class="glyphicons status_icon cogwheels"></span>',
			text: lang.service.SYSTEM_ERROR,
			value: 'SYSTEM_ERROR'
		}];
	},
	/*
	 * Returns detail type datasource
	 * @returns {Array}
	 * */
	getLogTypeDS: function () {
		return [{
			template: '<span class="glyphicons status_icon circle-arrow-top"></span>',
			text: lang.UP,
			value: 'OK'
		}, {
			template: '<span class="glyphicons status_icon circle-arrow-down"></span>',
			text: lang.DOWN,
			value: 'ERROR'
		}, {
			template: '<span class="glyphicons status_icon remove-sign orange"></span>',
			text: lang.ERROR,
			value: 'MODEL_ERROR'
		}, {
			template: '<span class="glyphicons status_icon exclamation-sign orange"></span>',
			text: lang.WARNING,
			value: 'WARNING'
		}, {
			template: '<span class="glyphicons status_icon ban-circle"></span>',
			text: lang.FAILED,
			value: 'FAILED'
		}, {
			template: '<span class="glyphicons status_icon question-sign"></span>',
			text: lang.UNKNOWN,
			value: 'UNKNOWN'
		}, {
			template: '<span class="glyphicons status_icon folder-open"></span>',
			text: lang.OPEN,
			value: 'OPEN'
		}, {
			template: '<span class="glyphicons status_icon folder-closed"></span>',
			text: lang.CLOSED,
			value: 'CLOSED'
		}];
	},

	/*
	 * Handler function for
	 * */
	setAccountLogo: function (logoId) {
		var logo = $('.logo');
		var customLogo = $('.cw_logo_custom');
		var negativeLogo = $('.cw_logo_negative');
		if (logoId && Utils.isGuid(logoId) && (State.mainApp.session.hasRole('ACCOUNT_READ') || State.mainApp.session.hasRole('ACCOUNT_PROFILE'))) {
			logo.addClass('is_hidden');
			customLogo.removeClass('is_hidden');
			customLogo.find('a').empty().append($('<img src="' + Api.images.urls.image(logoId) + '" />'));
			negativeLogo.removeClass('is_hidden');
		} else {
			logo.removeClass('is_hidden');
			customLogo.remove();
			negativeLogo.addClass('is_hidden');
		}
	},
	/*
	 * Handler function for rendering the monitor CVID value
	 * @param {String} id. Optional
	 * */
	monitorCVID: function (id) {
		if (id) {
			var cvidHandler = $('#cw_cvid');
			cvidHandler.closest('.cw_field').removeClass('hide');
			cvidHandler.val(id);
		}
	},
	/*
	* Handler function to set default image on cloned service element
	* @param {Object} target The jQuery selector
	* @param {String} type The type of the icon
	*/
	setTemplateDefaultIcon: function (target, type) {
		var target = $(target), cssIcon = '';
		target.addClass('hide');
		switch (type) {
			case 'model':
				cssIcon = 'git-merge';
				break;
			case 'element':
				cssIcon = 'plus-sign';
				break;
			case 'qualifier':
				cssIcon = 'cogwheel';
				break;
			default:
				cssIcon = 'article';
				break;
		}

		target.after('<span class="glyphicons ' + cssIcon + '"></span>');
	},
	/*
	* Handler function for rendering state
	* */
	metricState: function (item) {
		let stateClass;
		switch (item.stateIndex) {
			case 0: stateClass = 'is_critical'; break;
			case 1: stateClass = 'is_major'; break;
			case 2: stateClass = 'is_minor'; break;
			case 3: stateClass = 'is_ok'; break;
			default: stateClass = 'is_idle'; break;
		}

		let classes = classnames(
			'cw_status_indicator',
			'is_round',
			'glyphicons',
			stateClass
		);

		let tooltip = [];

		if (item.monitorError || item.metricError || item.errorCode || item.agentState === 'WARNING') {
			classes = classnames(
				classes,
				'exclamation-mark'
			);

			if (item.type === 'MONITOR') {
				if (item.monitorError) {
					tooltip.push(lang.account.messages.MONITOR_ERROR);
				}
				if (item.metricError) {
					tooltip.push(lang.account.messages.METRIC_ERROR);
				}
				if (item.errorCode) {
					tooltip.push(item.errorText);
				}
				if (item.agentState === 'WARNING') {
					tooltip.push(lang.account.messages.AGENT_WARNING);
				}
			} else if (item.type === 'COLLECTOR') {
				classes = classnames(
					classes,
					'collector_metric'
				)
			}

		} else if (item.shared) {
			classes = classnames(
				classes,
				'share'
			)
		}

		tooltip = tooltip.join(' | ');

		let html = '<div class="cw_status">';
		html += `<span class="${classes}" title='${tooltip}' style="margin-left: 25px !important"></span>`;
		html += '</div>';

		return html;
	},
	rootCauseState: function (state) {
		var html, stateClass;
		if (state) {
			stateClass = 'is_ok';
		} else if (state === null) {
			return '';
		} else {
			stateClass = 'is_critical';
		}
		html = '<span class="cw_status_indicator is_round ' + stateClass + '"></span>'
		return html;
	},
	/*
	* Handler function for rendering the tooltip
	* @param {Object} handler The jquery handler
	* */
	errorToolTip: function (handler, isIndicator) {
		var filter;
		if (isIndicator) {
			filter = '.cw_indicator .exclamation-mark';
		} else {
			filter = '[data-monitorerror="true"]';
		}
		return handler.kendoTooltip({
			filter: filter,
			autoHide: true,
			position: 'bottom',
			show: function (e) {
				e.sender.popup.element.addClass('warning_tooltip');
			}
		}).data("kendoTooltip");
	},

	safeHtml: function(val) {
		return val
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/\s/g, '&nbsp;');
	},

	templates: {
		responsibleTeam: '#= accountName ? (name+ " (" + accountName + ")") : name#'
	}
}

export default Renderer;
