import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from 'antd';
import {TableContext} from './constants';
import {ACTIONS, VARS} from './reducer';
import "./indexCell.less";
import withComponentLoaded from "./withComponentLoaded";
import State from 'tools/state'

const b = require('b_').with('rtable-indexcell');

function IndexCell(props) {
	const {Table, Cell, Column, HeaderCell, rowData, dataKey, singleMetricSelection, ...restProps} = props;
	const {state, dispatch} = React.useContext(TableContext);
	const ref = React.useRef();

	React.useEffect(() => {
		const dragStartHandler = (e) => {
			e.dataTransfer.setData("text/plain", JSON.stringify(rowData));
		}

		ref.current?.addEventListener("dragstart", dragStartHandler);

		return () => {
			ref.current?.removeEventListener("dragstart", dragStartHandler);
		}
	}, [])

	const value = React.useMemo(() => {
		return state[VARS.CHECKED_ROWS] && state[VARS.CHECKED_ROWS].indexOf(rowData[state[VARS.INDEX_KEY]]) !== -1;
	}, [rowData, state[VARS.CHECKED_ROWS]]);

	let disableCheckbox;
	State.singleMetricSelection = singleMetricSelection;

	const handleChange = React.useCallback((e) => {
		if (State.singleMetricSelection) {
			let checkedItems = 0;
			$('.rtable-indexcell').find('.ant-checkbox').each(function() {
				if ($(this).hasClass('ant-checkbox-checked')) {
					checkedItems++;
				}
			});

			if (!checkedItems) {
				//select
				dispatch({ type: ACTIONS.TOGGLE_CHECKED, id: rowData[state[VARS.INDEX_KEY]] })
			}

			if ($(e.nativeEvent.target).closest('.ant-checkbox').hasClass('ant-checkbox-checked')) {
				//unselecting current selected one
				dispatch({ type: ACTIONS.TOGGLE_CHECKED, id: rowData[state[VARS.INDEX_KEY]] })
			}
		} else {
			dispatch({ type: ACTIONS.TOGGLE_CHECKED, id: rowData[state[VARS.INDEX_KEY]] })
		}
	}, [rowData, dataKey]);

	if (singleMetricSelection && !value && state.checkedRows?.length === 1) {
		disableCheckbox = true;
	}

	const onCheckbox = React.useCallback((node) => {
		if (node && node.input) {
			$(node.input).addClass('skip_dirty_check');
		}
	}, []);

	return <Cell {...restProps}>
		<div className={b()}>
			{props.draggable && <div draggable="true" className={b("drag-source")} ref={ref} />}
			<Checkbox ref={onCheckbox} disabled={disableCheckbox} checked={value} onChange={handleChange}/>
		</div>
	</Cell>
};

IndexCell.propTypes = {
	dataKey: PropTypes.string.isRequired,
	rowData: PropTypes.object.isRequired,
	draggable: PropTypes.bool.isRequired
};

export default withComponentLoaded(IndexCell);
