import React from "react";
import {translator} from "core";
import Cookies from 'core/cookies';
import './metricLegendContainer.less'
import {AssetsRouter} from "../areas/assets/bundleDescription";

let i = translator({

});

export class MetricLegendContainer extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className='cw_raw_data_info'>
				{this.props.data?.assetName && <span>{i('Asset')}: <a className="cw_link" href={`#${AssetsRouter.details(this.props.data.assetId)}`}>{this.props.data.assetName}</a> | </span>}
				{this.props.data?.categoryNode && <span>{i('Name')}: {this.props.data.categoryNode} | </span>}
				{this.props.data?.registryType && <span>{i('Type')}: {this.props.data.registryType} | </span>}
				<span>{i('Timezone')}: {Cookies.CeesoftTimezone}</span>
			</div>
		)
	}
}

