export const popoverZIndex = 99999

export const thingsOnPopoverZIndex = popoverZIndex + 10 //so for example a datepicker on popover is shown above popover
export const styleForThingsOnPopover = {zIndex: thingsOnPopoverZIndex}

export const modalZIndex = thingsOnPopoverZIndex + 10 //so a modal window hides popover & datepicker on popover

export const thingsOnModalZIndex = modalZIndex + 10 //so a popover on modal window is on top of modal
export const styleForThingsOnModal = {zIndex: thingsOnPopoverZIndex}

export const thingsOnPopoverOnModalZIndex = thingsOnModalZIndex + 10//so a datepicker on popover on modal is on top
export const styleForThingsOnPopoverOnModal = {zIndex: thingsOnPopoverOnModalZIndex}

export const getStylesForThings = (onModal: boolean) => {
	return onModal ? styleForThingsOnPopover : styleForThingsOnPopoverOnModal
}
