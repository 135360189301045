import React from "react"
import {translator} from "core";
import {Button, TextArea} from "controls/react/form";
import {Window, Section, Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import {topLeftCornerPosition} from 'controls/modalWindow'

let i = translator({
  "Set information placeholder": {
    "en": "Add the text you want to be displayed in the information field. Note that this text will overwrite existing information, if present...",
    "no": "Legg til tekst du ønsker skal vises i informasjonsfeltet. Merk at det overskriver eksisterende informasjonstekst."
  },
  "Set information": {
    "no": "Sett informasjon",
    "en": "Set Information"
  }
});

export class SetInformationWindow extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			information: ''
		};
	}

	render() {
		return (
			<Window height={240}
					width={500}
					minHeight={200}
					modal
					title={i('Set information')}
					ref={window => this.window = window}
					onClose={this.props.onClose}
					top={this.props.topLeftCorner ? topLeftCornerPosition.top : undefined}
					left={this.props.topLeftCorner ? topLeftCornerPosition.left : undefined}>
				<TextArea containerClass='cw_set_information'
						  placeholder={i('Set information placeholder')}
						  value={this.state.information}
						  onChange={(value) => this.onInformationChange(value)}
				/>
				<Toolbar>
					<Button title={i('Update')}
							primary={true}
							onClick={() => this.onUpdate()}
							position={ToolbarItemPosition.AT_THE_END} />
					<Button title={i('Cancel')}
							primary={false}
							onClick={() => this.props.onClose()}
							position={ToolbarItemPosition.AT_THE_END} />
				</Toolbar>
			</Window>
		)
	}

	onInformationChange(value) {
		this.setState({
			information: value
		})
	}

	onUpdate() {
		this.props.onUpdate(this.state.information, this.props.onClose);
	}
}

