import _ from 'lodash';

import {translator} from "core/localization/localization";

import AssetDataSourceElement from 'controls/designer/dataSourcesManager/assetDataSourceElement';
import SlaDataSourceElement from 'controls/designer/dataSourcesManager/slaDataSourceElement';
import {KpiDataSourceElement} from 'controls/designer/dataSourcesManager/kpiDataSourceElement';
import ServiceDataSourceElement from 'controls/designer/dataSourcesManager/serviceDataSourceElement';
import AssetGroupDataSourceElement from 'controls/designer/dataSourcesManager/assetGroupDataSourceElement';
import {MetricDataSourceElement} from 'controls/designer/dataSourcesManager/metricDataSourceElement';
import {CostDataSourceElement} from 'controls/designer/dataSourcesManager/costDataSourceElement';
import {getHealthInfo} from "./api";

import {ServiceModelImportingActions} from "controls/designer/features/serviceModelImport";
import {RemoteEventsManager} from "core/remoteEventsManager";
import {iterateAllCells, executeUpdate, iterateEdges} from "./utils";
import {apiFetch} from "framework/api";
import {debounce} from 'lodash';


const i = translator({
  "Link: ": {
    "no": "Link: "
  }
});

export class StatesManager{
	ui = null;
	graph = null;
	elements = [];
	entries = [];

	constructor(designer){
		this.designer = designer;
		this.ui = designer.editorUi;
		this.graph = this.ui.editor.graph;

		if(!this.graph.assetGroupExpandedOverride){
			this.graph.assetGroupExpandedOverride = {}
		}

		if(this.designer.config.disableDynamicContent)
			return;

		this.findElements();

		this.designer.registerForCleanUp(this.cleanUp);
	}

	findElements() {
		this.elements = new Array();

		iterateAllCells(this.graph, cell => {
			var element = this.createElementFromCell(cell);

			if (element != undefined)
				this.elements.push(element);
		})

		this.graph.getModel().addListener(mxEvent.EXECUTE, this.checkIfAnElementAddedOrRemoved);
		this.graph.addListener('cell-state-color-changed', this.onCellStateColorChanged);
		this.designer.editorUi.addListener('styleChanged', this.stylesChanged);
	}

	createElementFromCell(cell) {
		const userData = cell.getValueAsXml();

		if(userData.getAttribute("noDataSource") == "true")
			return null;

		let element = null;

		const datasourceType = cell.getDatasource()?.type;

		switch (datasourceType) {
			case 'asset':
				element = new AssetDataSourceElement(this.designer, cell);
				break;
			case 'assetGroup':
				element = new AssetGroupDataSourceElement(this.designer, cell);
				break;
			case 'sla':
				element = new SlaDataSourceElement(this.designer, cell);
				break;
			case 'service':
				element = new ServiceDataSourceElement(this.designer, cell);
				break;
			case 'metric':
				element = new MetricDataSourceElement(this.designer, cell);
				break;
			case 'kpi':
				element = new KpiDataSourceElement(this.designer, cell);
				break;
			case 'cost':
				element = new CostDataSourceElement(this.designer, cell);
				break;
		}

		if (element?.empty())
			return null;

		return element;
	}

	async showStates(elements) {
		if (elements == null) {
			elements = this.elements;
		}

		if(elements.length == 0)
			return;

		const entriesToLoad = elements.reduce((result, e) => {
			const newEntries = e.getEntriesToLoad()
			newEntries.forEach(x => x.id = e.guid);
			return result.concat(newEntries);
		}, []);

		if (entriesToLoad.length) {
			const result = await apiFetch(getHealthInfo(entriesToLoad));
			if (result.success) {
				for(const element of elements){
					let entriesForTheCell = result.data.filter(x => x.id == element.guid);
					element.onEntriesLoaded(entriesForTheCell);
				}
			}
		}

		await this.updateStates(elements);

		if(!this.subscription && elements == this.elements) {
			this.subscription = RemoteEventsManager.subscribeCallback(this.getSubscriptions(), this.consumeEvents);
		}
	}

	async updateStates(elements = []){
		if (!elements.length)
			return;

		this.graph.getModel().beginUpdate();

		let cache = {};
		for(const element of elements){
			try {
				if(element.destroyed)
					continue;

				await element.cleanUp();
				await element.updateState(cache, elements);
			}
			catch(e){
				console.error(e)
			}
		}

		this.graph.getModel().endUpdate();
	}

	cleanUp = () => {
		this.graph.getModel().beginUpdate();
		try {
			this.elements.forEach((e) => {
				e.cleanUp();
			});
		}
		catch(e){
			console.error(e);
		}
		finally {
			this.graph.getModel().endUpdate();
		}
	}

	checkIfAnElementAddedOrRemoved = async (graphModel, ev) => {
		let newElement = this.processMxGraphChange(ev.properties.change);
		if(newElement){
			await this.showStates([newElement]);
		}
	}

	processMxGraphChange(change){
		if (change instanceof mxValueChange ||
			change instanceof mxChildChange && change.previous == null) {
			let newElement = this.checkCellForDatasource(change);
			if(newElement){
				this.elements.push(newElement);
				return newElement;
			}
			if(change instanceof mxChildChange) {
				this.checkCellForTriggers(change);
			}
		}else if(change instanceof mxChildChange && change.parent == null){
			this.removeElement(change.child);
		}if (change instanceof mxGeometryChange) {
			this.cellMoved(change.cell);

			iterateEdges(change.cell, (edge) => {
				//at the current stage the state of the edge cell is not updated yet
				//so if we call it right now the values will be old ones
				//We can substribe to the Notify event instead and thats where the values will be up to date
				//BUT at the same time if we performe changes the graph would not be updated an this time
				//So the only option I found is to save that edge is moved and trigger the moved event later when graph finishes
				setTimeout(() => {
					this.cellMoved(edge)
				}, 0)
			})
		}else if(change instanceof mxTerminalChange){
			setTimeout(() => {
				this.cellMoved(change.cell)
			}, 0)
		}
	}


	cellMoved(cell){
		var element = this.getElementForCell(cell);
		if( element == null )
			return;

		element.cellMoved();
	}

	checkCellForDatasource(change) {
		const cell = change.cell || change.child;
		const currentElementForThisCell = this.getElementForCell(cell);

		if (currentElementForThisCell != null) {
			if(change.previous.getAttribute('datasource') == change.value.getAttribute('datasource')){
				return;
			}

			this.removeElement(cell);
		}

		return this.createElementFromCell(cell);
	}

	//triggers are empty cells with trigger attribute. They are used to trigger an action when they are created
	checkCellForTriggers(change){
		const cell = change.child;
		const value = cell.getValueAsXml();
		const trigger = value.getAttribute('trigger');
		if(trigger) {
			if (trigger == 'service-model') {

				this.ui.actions.get(ServiceModelImportingActions.ShowImportDialog).funct(
					cell.getGeometry()
				);
			}
			executeUpdate(this.graph, () =>
				this.graph.removeCells([cell])
			);
		}
	}

	removeElement(cell){
		var element = this.getElementForCell(cell);
		if(!element)
			return;

		var index = this.elements.indexOf(element);
		this.elements.splice(index, 1);
		element.destroy();
	}

	getElementForCell(cell) {
		return this.elements.find( (e) => e.cell == cell);
	}

	getSubscriptions() {
		let entitiesForSubscriptions = {
			services: [],
			agents: [],
			slas: [],
			assets: [],
			links: [],
			assetGroups: [],
			metrics: [],
			kpis: [],
			costs: []
		};

		let subscriptions = [];
		let trends = {};

		this.elements.forEach( (e) => {
			if(e.getSubscriptions) {
				let newEntities = e.getSubscriptions();
				for (let key in newEntities) {
					entitiesForSubscriptions[key] = entitiesForSubscriptions[key].concat(newEntities[key]);
				}
			}
			if(e.getSubscriptionsDirectly) {
				subscriptions = subscriptions.concat(e.getSubscriptionsDirectly());
			}
		});

		if( entitiesForSubscriptions.services.length != 0) {
			subscriptions.push({
				eventType: 'ServiceStatus',
				serviceIds: _.uniq(entitiesForSubscriptions.services),
				//reasons: ["MODEL_CHANGE", "ELEMENT_CHANGE", "QUALIFIER_CHANGE"]
			});
		}

		if(entitiesForSubscriptions.links.length != 0 || entitiesForSubscriptions.services.length != 0){
			subscriptions.push({
				eventType: 'ServiceSummary',
				serviceIds: _.uniq([...entitiesForSubscriptions.links, ...entitiesForSubscriptions.services])
			});
		}

		for(let slaId of _.uniq(entitiesForSubscriptions.slas)){
			subscriptions.push({
				eventType: 'Sla',
				slaId: slaId,
			});
		}

		if(entitiesForSubscriptions.kpis.length){
			subscriptions.push({
				eventType: 'Kpi',
				filters: entitiesForSubscriptions.kpis
			});
		}

		for(let metricObj of _.uniq(entitiesForSubscriptions.metrics)){
			//@ts-ignore
			const subscription = {
				eventType: 'Metric',
				//@ts-ignore
				qualifierId: metricObj.metricId,
				releaseEvents: true,
				//@ts-ignore
				unitType: metricObj.unitType,
				//@ts-ignore
				showTrend: metricObj.showTrend,
				//@ts-ignore
				timePeriod: metricObj.timePeriod
			};
			subscriptions.push(subscription);
		}

		for(let assetId of _.uniq(entitiesForSubscriptions.assets)){
			subscriptions.push({
				eventType: 'AssetHealth',
				assetId: assetId,
			});
		}

		if(entitiesForSubscriptions.assetGroups.length != 0) {
			subscriptions.push({
				eventType: 'AssetGroupHealth',
				assetGroupIds: _.uniq(entitiesForSubscriptions.assetGroups),
			});

			subscriptions.push({
				eventType: 'Administration',
				entityIds: _.uniq(entitiesForSubscriptions.assetGroups),
				actionTypes: [
					'ASSET_GROUP_UPDATE',
					'ASSET_GROUP_DELETE',
					'ASSET_GROUP_MEMBER_CREATE',
					'ASSET_GROUP_MEMBER_DELETE',
					'ASSET_GROUP_MEMBER_UPDATE'
				]
			});
		}

		if( entitiesForSubscriptions.agents.length != 0) {
			subscriptions.push({
				eventType: 'AgentState',
				agentIds: _.uniq(entitiesForSubscriptions.agents)
			});
		}

		return subscriptions;
	}

	consumeEvents = event => {
		let elementsToRedraw = [];
		let elementsToReload = [];
		for (const element of this.elements) {
			let result = element.consumeEvent(event);
			if(typeof result == 'boolean'){
				result = {
					redraw: result
				}
			}

			if(result.reload)
				elementsToReload.push(element);
			else if(result.redraw)
				elementsToRedraw.push(element);
		}
		if(elementsToRedraw.length > 0) {
			if (this.designer.config.mode == 'service') {
				this.updateStatesDebounced(this.elements);
			} else {
				this.updateStatesDebounced(elementsToRedraw);
			}
		}

		if(elementsToReload.length > 0){
			this.showStatesDebounced(elementsToReload);
		}

		if(this.designer.config.features.presentationMode){
			if(elementsToRedraw.some(x => x instanceof ServiceDataSourceElement)) {
				this.refreshGraphDebounced();
			}
		}
	}

	updateStatesDebounced = debounce((elementsToRedraw) => this.updateStates(elementsToRedraw), 1000);
	showStatesDebounced = debounce((elementsToReload) => this.showStates(elementsToReload), 1000);
	refreshGraphDebounced = debounce(() => this.graph.refresh(), 1000);

	getTooltipForCell(cell) {
		var cellElement = this.getElementForCell(cell);
		if(cellElement == null && cell.parent != null && cell.parent.id > 2){
			cell = cell.parent
			cellElement = this.getElementForCell(cell)
		}

		let promise = null;
		if (cellElement) {
			promise = cellElement.getTooltip();
		}else{
			promise = Promise.resolve( "");
		}

		const link = cell.getDatasource()?.link;

		if(link){
			promise = promise.then( tooltip => {
				if(tooltip){
					tooltip += "\r\n"
				}

				tooltip += i('Link: ') + link

				return tooltip;
			});
		}

		return promise;
	}

	getLabelForCell(cell){
		var cellElement = this.getElementForCell(cell);
		if (cellElement) {
			return cellElement.getLabel();
		}
	}

	onCellStateColorChanged = (graph, e) => {
		let element = this.getElementForCell(e.properties.cell);
		element.refreshStateColor();
	}

	stylesChanged = (ui, e) => {
		this.elements.forEach( element => {
			if (e.properties.cells.findIndex(x => x == element.cell) == -1)
				return;

			executeUpdate(this.graph, graph => {
				element.stylesChanged(e);
			});
		})
	}

	destroy() {
		this.subscription?.unsubscribe();

		this.elements.forEach((e) => {
			e.destroy()
		});
	}
}
