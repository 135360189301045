import React from 'react'
import ReactDOM from 'react-dom'

import Cookies from 'core/cookies'
import Settings from 'settings'
import ModalWindow from "./modalWindow";
import {Api} from 'tools/api'
import {infoDialogAsync} from 'controls/dialog';
import {topLeftCornerPosition} from 'controls/modalWindow'
import {TagsSelect} from "controls/react/tagsSelect"
import {FormEntryNew} from "controls/react/form/formEntryNew";


const i18n = require('core/localization').translator()

export default class TagsForm{
	window = null
	type = ''
	viewScope =  null

	constructor(config) {
		Object.assign(this, config);
		this.accountId = this.accountId || Cookies.CeesoftCurrentAccountId;

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();
	}

	removeListeners() {
		this.window.kendoWindow.wrapper.find('#cw_close').off();
		this.window.kendoWindow.wrapper.find('#cw_update').off();
	}

	attachListeners() {
		this.window.kendoWindow.wrapper.find('#cw_close').on('click', $.proxy(this.onClose, this));
		this.window.kendoWindow.wrapper.find('#cw_update').on('click', $.proxy(this.onUpdate, this));
	}

	initKendoComponents() {
		ReactDOM.render(<FormEntryNew label={i18n('Tags')} vertical={true}>
			<TagsSelect	onChange={this.onChange}/>
		</FormEntryNew>, document.querySelector('.cw_tags').parentNode)
	}

	tags = []
	onChange = (tags) =>{
		this.tags = tags
	}

	onUpdate() {
		var ids = [], url, control, checkboxes;
		if( this.viewScope.grid?.kendoGrid){
			//a case for react pages. But the whole implementation still sucks a lot, requires reworks
			// no paramenters should being looked this way
			control = this.viewScope.grid.kendoGrid
		}else if (this.viewScope.grid) {
			control = this.viewScope.grid;
		} else if (this.viewScope.treeList) {
			control = this.viewScope.treeList;
		}
		var checkboxes;
		if (control.wrapper) {
			checkboxes = control.wrapper.find('.cw_grid_check:checked');
		} else {
			checkboxes = $('#content_area').find('.cw_grid_check:checked');
		}
		var length = checkboxes.length;

		for (var i = 0; i < length; i++) {
			var id = $(checkboxes[i]).data('id');
			if (this.type === 'assetgroup') {
				var record = this.viewScope.treeList.dataSource.get(id);
				var realId = record.realId;
				ids.push(realId);
			} else {
				ids.push(id);
			}
		}
		const tags = this.tags.map( x=> x.trim())

		// for (var i = 0, length = this.tags.length; i < length; i++) {
		// 	tags.push(tagValues[i].name.trim());
		// }
		var data = {
			ids: this.viewScope.selectedIds || ids,
			tags: tags
		};
		var url = this.type === 'application' ? Settings.serverPath : Settings.serverPath + 'accounts/' + this.accountId + '/';
		switch (this.type) {
			case 'service':
				url += 'services';
				break;
			case 'dashboards':
				url += 'dashboards';
				break;
			case 'asset':
				url += 'assets';
				break;
			case 'account':
				url = Settings.serverPath + 'accounts';
				break;
			case 'kpi':
				url += 'kpi';
				break;
			case 'sla':
				url += 'slas';
				break;
			case 'incident':
				url += 'incidents';
				break;
			case 'report':
				url += 'reports';
				break;
			case 'monitor':
				url += 'monitors';
				break;
			case 'assetgroup':
				url += 'assetGroups';
				break;
			case 'application':
				url += 'applications';
				break;
			default:
				break;
		}
		url += '/tags';

		Api.fetchPost(url, data)
			.then(result => {
				if (result.success) {
					control.dataSource.read();
					control.refresh();
					this.onClose();
					this.viewScope.gridMenu?.disableItem('cw_set_tags');
					this.viewScope.actionNotification?.setOptions({
						message: lang.messages.TAGS_SUCCESS_MESSAGE,
						status: 'success'
					}).show();
				} else {
					infoDialogAsync(lang.ALERT, result.message, result.details);
				}
			});
	}
	onClose(e) {
		this.window.close();
	}
}

export function openTagsFormWindow (type, viewScope) {
	const window = new ModalWindow({
		title: lang.SET_TAGS,
		width: 400,
		resizable: true,
		minHeight: 320,
		height: 320,
		position: topLeftCornerPosition,
		modal: true,
		actions: [],
		url: 'include/TagsForm.jsp',
		refresh: $.proxy(function () {
			var tagsFormView = new TagsForm({
				window: window,
				type: type,
				viewScope: viewScope
			});
		}, this)
	});
	window.open();
}
