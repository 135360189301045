import './calendarEventForm.less';
import {Cookies} from "core";
import {CustomNotification} from "controls";
import {Renderer} from "tools";
import {Utils} from "tools";
import RuleGenerator from 'controls/ruleGenerator';
import MultiToggle from 'controls/multiToggle';
import {combineDateAndTime, convertTimezone, generateKendoTimePickerPeriods, convertTimezoneViaUtc} from "tools/dateTimeUtils";
import {newGuid} from "tools/guid";

export class CalendarEventForm {
	// this needs to allow set dates to fromDate and toDate if this dates before disableDateTo value
	loadingForm = true;
	disabled = false;
	enableName = true;
	tillTheEndDay = false;
	constructor(config) {
		Object.assign(this, config);

		//we need to convert time from the user's timezone to the selected in the event because in calendar we use user's
		this.initComponent();
	}

	getName(){
		return $('#cw_calendar_event_name').val().trim();
	}

	initComponent () {
		this.formEl = $('#cw_calendar_event_form');
		this.removeListeners();
		this.attachListeners();

		if(this.mode === 'update'){
			$('#cw_event_organizer').val(this.event.organizer?.replace('mailto:', '') || 'N/A');
			$('#cw_event_created').val(this.event.created ? Renderer.browserDateRenderer(this.event.created, 'datetime') : 'N/A');
		}
		else{
			$('#cw_event_organizer').parent().hide();
			$('#cw_event_created').parent().hide();
		}


		this.initKendoComponents();
		if (this.mode === 'update') {
			$('#save_calendar_event').text(lang.UPDATE);
			$('#delete_calendar_event').removeClass('hide');
			this.load();
		} else {
			$('#save_calendar_event').text(lang.CREATE);
		}
		this.disableInaccessibleDates();

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
	}

	initKendoComponents () {
		const disableTo = this.event.disableDateTo ? moment(this.event.disableDateTo) : undefined;
		this.isRecurringEvent = !!this.event.rruleObject;
		const until = this.event.rruleObject?.until
			? convertTimezoneViaUtc(moment(this.event.rruleObject.until).toDate(), moment.tz.guess(), this.event.timezone)
			: undefined;

		const end = moment(this.event.end);
		this.disabled |= !!disableTo
			&& (end.isSameOrBefore(disableTo, 'minute') && !this.tillTheEndDay
				&& (!this.isRecurringEvent
					|| this.isRecurringEvent
						&& until
						&& moment(until).isSameOrBefore(disableTo, 'minute')));

		this.fromDate = $('#cw_event_from_day').kendoDatePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateFormat),
			value: this.event.start,
			change: this.ensureIntervalValid
		}).data('kendoDatePicker');
		this.fromAllDayToggle = new MultiToggle({
			id: 'cw_from_all_day_toggle',
			cssClass: 'cw_multi_toggle left',
			value: false,
			items: [{
				id: 'cw_from_not_all_day',
				title: lang.CUSTOM,
				selected: true,
				cssClass: 'glyphicons stopwatch',
				fn: this.onFromAllDayClick,
				scope: this,
				value: false
			}, {
				id: 'cw_from_all_day',
				title: lang.service.SLA_ALL_DAY,
				selected: false,
				cssClass: 'glyphicons calendar all_day',
				fn: this.onFromAllDayClick,
				scope: this,
				value: true
			}]
		});
		this.fromTime = $('#cw_event_from_hour').kendoTimePicker({
			format: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
			value: this.event.start,
			change: (ev) => this.onTimeChanged(ev, this.fromDate)
			// There is issue in kendo timepicker if we set dates and min value selection of value shifts time to earlier time
			// dates: generateKendoTimePickerPeriods(this.fromDate.value())
		}).data('kendoTimePicker');

		$('#cw_event_from_day').closest('.k-datepicker').addClass('cw_event_form_date_picker');
		$('#cw_event_from_hour').closest('.k-timepicker').addClass('cw_event_form_hour_picker');
		$('#cw_from_all_day_toggle').addClass('cw_event_form_time_toggle');

		this.toDate = $('#cw_event_to_day').kendoDatePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateFormat),
			value: this.event.end,
			change: this.ensureIntervalValid
		}).data('kendoDatePicker');

		this.toAllDayToggle = new MultiToggle({
			id: 'cw_to_all_day_toggle',
			cssClass: 'cw_multi_toggle left',
			value: false,
			items: [{
				id: 'cw_to_not_all_day',
				title: lang.CUSTOM,
				selected: true,
				cssClass: 'glyphicons stopwatch',
				fn: this.onToAllDayClick,
				scope: this,
				value: false
			}, {
				id: 'cw_to_all_day',
				title: lang.service.SLA_ALL_DAY,
				selected: false,
				cssClass: 'glyphicons calendar all_day',
				fn: this.onToAllDayClick,
				scope: this,
				value: true
			}]
		});

		this.toTime = $('#cw_event_to_hour').kendoTimePicker({
			format: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
			value: this.event.end,
			change: (ev) => this.onTimeChanged(ev, this.toDate)
			// dates: generateKendoTimePickerPeriods(this.toDate.value())
		}).data('kendoTimePicker');

		$('#cw_event_to_day').closest('.k-datepicker').addClass('cw_event_form_date_picker');
		$('#cw_event_to_hour').closest('.k-timepicker').addClass('cw_event_form_hour_picker');
		$('#cw_to_all_day_toggle').addClass('cw_event_form_time_toggle');

		if (this.fromDate.value()) {
			this.toDate.min(this.fromDate.value());
		}

		if (this.disableRecurringEvent) {
			$('#cw_toggle_recurring_event').closest('.cw_field').addClass('hide');
		}
		this.loadingForm = false;
		if (this.mode == 'create') {
			return;
		}
		if (this.disabled) {
			this.fromDate.enable(false);
			this.fromTime.enable(false);
			this.fromAllDayToggle.disable(true);
			this.toDate.enable(false);
			this.toTime.enable(false);
			this.toAllDayToggle.disable(true);
			$('#cw_calendar_event_name').prop('disabled', !this.enableName);
			$('#cw_toggle_recurring_event').prop('disabled', true);
			$('#delete_calendar_event').attr('disabled', true);
			$('#cw_toggle_recurring_event').attr('disabled', true);
		} else if (disableTo && moment(this.event.start).isSameOrBefore(disableTo, 'minute') && !this.isRecurringEvent) {
			this.fromDate.enable(false);
			this.fromTime.enable(false);
			this.fromAllDayToggle.disable(true);
		}
	}

	onFromAllDayClick(value) {
		let sameDate = this.isTheSameDay(this.fromDate.value(), this.toDate.value());
		if (value) {
			this.fromTime.enable(false);
			this.fromTimeDisabled = true;
			if (sameDate && !this.fromToSameAllDay) {
				this.fromToSameAllDay = true;
				this.toAllDayToggle.setSelectedItem(true);
				this.onToAllDayClick(true);
			}
		} else {
			this.fromTime.enable(true);
			this.fromTimeDisabled = false;
			this.fromToSameAllDay = false;
		}
	}

	onToAllDayClick(value) {
		if (value) {
			this.toTime.enable(false);
			this.toTimeDisabled = true;
			let sameDate = this.isTheSameDay(this.fromDate.value(), this.toDate.value());
			if (sameDate && !this.fromToSameAllDay) {
				this.fromToSameAllDay = true;
				this.fromAllDayToggle.setSelectedItem(true);
				this.onFromAllDayClick(true);
			}
		} else {
			this.toTime.enable(true);
			this.toTimeDisabled = false;
			this.fromToSameAllDay = false;
		}
	}

	onTimeChanged = (ev, dateControl) => {
		const value = ev.sender.element[0].value;
		if (value == '00:00') {
			dateControl.value(moment(dateControl.value()).add(1, 'd').toDate());
		}
		this.ensureIntervalValid();
	}

	ensureIntervalValid = () => {
		if(!this.fromDate.value()){
			this.fromDate.value(this.event.disableDateTo);
		}
		if(!this.fromTime.value()){
			this.fromTime.value(this.event.disableDateTo);
		}
		if (!this.toDate.value()) {
			this.toDate.value(this.fromDate.value());
		}
		if (this.event.disableDateTo) {
			if (moment(this.fromDate.value()).isSameOrBefore(this.event.disableDateTo, 'day')) {
				this.fromDate.value(this.event.disableDateTo);
			}
			const fromTime = moment(combineDateAndTime(this.fromDate.value(), this.fromTime.value()));
			if (fromTime.isSameOrBefore(this.event.disableDateTo, 'minute')) {
				this.fromTime.value(this.event.disableDateTo);
			} else if (moment(this.fromTime.value()).isSame(fromTime)) {
				this.fromTime.value(fromTime.toDate());
			}
		}

		if (moment(this.toDate.value()).isBefore(this.fromDate.value(), 'day')) {
			this.toDate.value(this.generateToTime(this.fromDate.value()));
			this.toTime.value(this.generateToTime(this.fromTime.value()));
		}
		if (!this.toTime.value()) {
			this.toTime.value(this.generateToTime(this.fromTime.value()));
		}

		const toTimeIsZero = this.toTime.value().getHours() + this.toTime.value().getMinutes() == 0;
		if (this.isTheSameDay(this.fromDate.value(), this.toDate.value()) && moment(this.toTime.value()).isBefore(this.fromTime.value(), 'minute') && !toTimeIsZero) {
			this.toTime.value(this.generateToTime(this.fromTime.value()));
		}
		this.disableInaccessibleDates();
		this.fixRestrictionsInRuleGenerator();
	}

	disableInaccessibleDates = () => {
		this.toTime.min(new Date(1900, 0, 1));
		this.fromTime.min(new Date(1900, 0, 1));

		if(!this.fromDate.value()){
			this.fromDate.value(this.event.disableDateTo ?? new Date());
		}
		if (this.event.disableDateTo) {
			this.fromDate.min(this.event.disableDateTo);
			if (moment(this.fromDate.value()).isSameOrBefore(this.event.disableDateTo, 'day')) {
				this.fromTime.min(this.event.disableDateTo);
			}
		}

		this.toDate.min(this.fromDate.value());
		if (this.isTheSameDay(this.fromDate.value(), this.toDate.value())) {
			this.toTime.min(moment(this.fromTime.value()).set({'seconds': 0, 'milliseconds': 0}).toDate());
		}
		if (!this.event.disableFrom) {
			return;
		}
		this.toDate.max(this.event.disableFrom);
		this.fromDate.max(this.event.disableFrom);
		if (this.isTheSameDay(this.toTime.value(), this.event.disableFrom)) {
			this.toTime.max(this.event.disableFrom);
		}
		if (this.isTheSameDay(this.fromTime.value(), this.event.disableFrom)) {
			this.fromTime.max(this.event.disableFrom);
		}
	}

	isTheSameDay(firstDate, secondDate) {
		return moment(firstDate).isSame(moment(secondDate), 'day');
	}

	generateToTime(fromTime) {
		return new Date(fromTime.getFullYear(), fromTime.getMonth(), fromTime.getDate(), fromTime.getHours() + 1);
	}

	load() {
		$('#cw_calendar_event_name').val(this.event.title);
		if (this.event.allDay) {
			this.fromAllDayToggle.setSelectedItem(true);
			this.onFromAllDayClick(true);
			this.toAllDayToggle.setSelectedItem(true);
			this.onToAllDayClick(true);
			this.toDate.value(new Date(this.event.end.setDate(this.event.end.getDate() - 1)));
		}

		if (this.event.rruleObject) {
			$('#cw_toggle_recurring_event').prop('checked', true);
			this.onToggleRecurring(null, true);
		}

		this.validate();
	}

	removeListeners () {
		$('#save_calendar_event').off();
		$('#cancel').off();
	}

	attachListeners () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_calendar_event').on('click', $.proxy(this.onSaveButton, this));
		$('#delete_calendar_event').on('click', $.proxy(this.onDeleteButton, this));
		$('#cw_toggle_recurring_event').on('click', $.proxy(this.onToggleRecurring, this));
		$('#cw_calendar_event_name').on('keyup', $.proxy(this.onNameKeyUp, this));
	}

	clearInvalid () {
		this.formEl.find('.js_calendar_event_name').removeClass('invalid');
	}

	onNameKeyUp () {
		this.validate()
	}

	validate() {
		Utils.setInvalidField(this.getName(), $('#cw_calendar_event_name'), false, 'required_name');
		if (this.disabled) {
			return;
		}
		const isValid = this.getName !== '' && !!this.fromDate.value() && !!this.toDate.value();
		$('#save_calendar_event').attr('disabled', !isValid);
		$('#delete_calendar_event').attr('disabled', !this.enabledDeleteButton());
	}

	enabledDeleteButton() {
		const disableTo = this.event.disableDateTo;
		return !disableTo
			|| (moment(this.event.end).isAfter(moment(disableTo), 'minute') && !this.isRecurringEvent
			|| this.isRecurringEvent && (!this.event.rruleObject.until || moment(this.event.rruleObject.until).isAfter(moment(disableTo), 'minute')));
	}

	onSaveButton () {
		if(!this.fromDate.value() || !this.toDate.value())
			return;

		let fromTimestamp = combineDateAndTime(this.fromDate.value(), this.fromTime.value(), this.fromTimeDisabled);
		let toTimestamp = combineDateAndTime(this.toDate.value(), this.toTime.value(), this.toTimeDisabled);

		if(moment(fromTimestamp).isSameOrAfter(toTimestamp) && (!moment(fromTimestamp).isSame(toTimestamp, 'day') || toTimestamp.getHours() + toTimestamp.getMinutes() != 0)) {
			toTimestamp = moment(fromTimestamp).add('1', 'minutes').toDate();
		}

		let data = {
			id: this.event.id || newGuid(),
			title: this.getName().trim(),
			start: fromTimestamp,
			end: toTimestamp,
			timezone: this.event.timezone,
			allDay: this.fromTimeDisabled && this.toTimeDisabled ? true : false,
			extendedProps: this.event.extendedProps,
			created: this.event.created,
			organizer: this.event.organizer,
			categories: this.event.categories
		};

		if (this.isRecurringEvent) {
			let stringRule = this.ruleGenerator.getRule();
			let rule = this.ruleGenerator.parseRule(stringRule, {
				lowerCaseKeys: true,
				bydayToByweekday: true
			})

			if(rule.byweekday) {
				rule.byweekday = rule.byweekday.split(',');
			}

			if(rule.until) {
				rule.until = this.ruleGenerator.until;
			}

			data.rruleObject = rule;
		}

		$('#cancel').trigger('click');
		if (this.mode === 'create') {
			this.onSave(data);
		} else if (this.mode === 'update') {
			this.onEdit(data);
		}
	}

	onDeleteButton () {
		if (!this.isRecurringEvent) {
			$('#cancel').trigger('click');
		}

		this.onDelete(this.event.id);
	}

	onCancelButton () {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	}

	fixRestrictionsInRuleGenerator() {
		if (!this.ruleGenerator) {
			return;
		}
		this.ruleGenerator.untilDate = this.getUntilDate();
		this.ruleGenerator.onDateChange();
	}

	getUntilDate() {
		let toDate = this.toDate.value();
		let toDateTime, toTime;
		if (this.toTimeDisabled) {
			toDateTime = new Date(toDate.setDate(toDate.getDate() + 1));
			this.additionalDayAdded = true;
			toDateTime = new Date(toDate.setHours(0));
			toDateTime = new Date(toDate.setMinutes(0));
		} else {
			toTime = this.toTime.value();
			toDateTime = new Date(toDate.setHours(toTime.getHours()));
			toDateTime = new Date(toDateTime.setMinutes(toTime.getMinutes()));
		}
		return toDateTime;
	}

	onToggleRecurring(e, isRecurring) {
		let target = e ? $(e.target) : '';
		var ruleGeneratorContainer = $('#cw_event_rule_generator');
		if (isRecurring || target.is(':checked')) {
			this.isRecurringEvent = true;
			ruleGeneratorContainer.removeClass('hide');
			if (!this.ruleGenerator) {
				const toDateTime = this.getUntilDate();
				this.ruleGenerator = new RuleGenerator({
					renderTo: 'cw_event_rule_generator',
					noMinutely: true,
					mode: this.mode,
					type: 'calendar',
					untilDate: toDateTime,
					disabled: this.disabled,
					timeZone: this.event.timezone
				});
				$('#cw_rule_end_pick').closest('.k-datetimepicker').css('width', '200px');
			}
			if (this.event.rruleObject) {
				this.fillRecurringData();
			}
		} else {
			this.isRecurringEvent = false;
			ruleGeneratorContainer.addClass('hide');
		}
	}

	fillRecurringData () {
		var daysArray = [], daysString;
		let frequencySelector = $('#cw_rule_frequency').data('kendoDropDownList');
		let frequencyValue = this.event.rruleObject.freq;
		frequencySelector.value(frequencyValue);
		frequencySelector.trigger('change');

		let intervalSelector = $('#cw_rule_interval').data('kendoNumericTextBox');
		intervalSelector.value(this.event.rruleObject.interval);
		this.ruleGenerator.onIntervalChange();

		if (this.event.rruleObject.until) {
			let ruleEndSelector = $('#cw_rule_end').data('kendoDropDownList');
			ruleEndSelector.value('ONDATE');
			ruleEndSelector.trigger('change');

			let ruleEndDateSelector = $('#cw_rule_end_pick').data('kendoDatePicker');
			ruleEndDateSelector.value(convertTimezone(convertTimezone(this.event.rruleObject.until, 'UTC', this.event.timezone), moment.tz.guess(), 'UTC'));

			let ruleEndTimeSelector = $('#cw_rule_end_time_pick').data('kendoTimePicker');
			ruleEndTimeSelector.value(convertTimezone(convertTimezone(this.event.rruleObject.until, 'UTC', this.event.timezone), moment.tz.guess(), 'UTC'));

			ruleEndDateSelector.trigger('change');
		}
		if (this.event.rruleObject.byweekday) {
			for (let i = 0 ; i < this.event.rruleObject.byweekday.length; i++) {
				let day = this.event.rruleObject.byweekday[i];

				$('[data-value="' + day + '"]').addClass('is_selected');

				daysArray.push(day);
				daysString = daysArray.toString();
			}
			this.ruleGenerator.byDay = daysString;

			this.ruleGenerator.setRule();
		}
		if (this.event.rruleObject.bymonthday) {
			if (this.event.rruleObject.bymonth) {
				let monthSelector = $('#cw_rule_month_select').data('kendoDropDownList');
				monthSelector.value(this.event.rruleObject.bymonth);
				let daySelector = $('#cw_rule_year_month_day_select').data('kendoDropDownList');
				daySelector.value(this.event.rruleObject.bymonthday);
				this.ruleGenerator.byMonth = this.event.rruleObject.bymonth;

			} else {
				let daySelector = $('#cw_rule_day_select').data('kendoDropDownList');
				daySelector.value(this.event.rruleObject.bymonthday);
			}
			this.ruleGenerator.byMonthDay = this.event.rruleObject.bymonthday;
			this.ruleGenerator.setRule();
		}
	}

	closeForm() {
		$('#cancel').trigger('click');
	}
}
