import React from 'react';
import {observer} from "mobx-react";

import {EventsSummaryPageContext, EventsSummaryPageStore} from "areas/summary/events/eventsSummaryPageStore";
import Page from 'controls/react/layout/page';
import {Toolbar} from "controls/react/layout";
import {RefreshTimer} from "controls/grid/refreshTimer";
import {
	CustomSelectorMode,
	TimePeriodAppearance,
	TimePeriodSelector,
	TimePeriodType
} from "controls/react/form/timePeriodSelector";
import {Grid} from 'controls/grid/grid';
import {AntSwitch} from "controls/react/ant/antSwitch";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {linkGridAdditionalPayload} from 'controls/grid/gridStore';
import {ViewSelector} from "controls/grid/viewManager/viewSelector";
import {GridSearchInput} from 'controls/grid/gridSearchInput';
import {GridFilteredIndicator} from "controls/grid/gridFilteredIndicator";
import {MenuButton} from "controls/react";
import {EventsSummaryStore} from "areas/summary/events/eventsSummaryStore";

const i18n = require('core/localization/localization').translator({
	'Show Historic': {
		no: 'Vis historisk'
	}
});

export const EventsTimePeriods = [TimePeriodType.LastDay, TimePeriodType.Last7Days,
	TimePeriodType.Last30Days, TimePeriodType.Custom, TimePeriodType.All]

export const EventsSummaryPage = observer(() => {
	const store = React.useContext(EventsSummaryPageContext)

	if(!store.gridStore?.selfInitialized)
		return null

	return <Page>
		<Toolbar title={store.title}>
			<MenuButton items={store.menuItems}
						indexedByCategory={true}
			/>
			<RefreshTimer store={store.gridStore}/>
			<GridFilteredIndicator store={store.gridStore} position={"after-title"}/>
			<ShowHistoricButton store={store}/>
			<TimePeriodSelector periods={EventsTimePeriods}
			                    customSelectorMode={CustomSelectorMode.Popup}
			                    appearance={TimePeriodAppearance.Buttons}
			                    size={"small"}
			                    {...linkGridAdditionalPayload(store.gridStore, "timePeriod")}/>
			<DataSizeIndicator store={store.gridStore}/>
			<GridSearchInput store={store.gridStore}/>
			<ViewSelector store={store.gridStore}/>
		</Toolbar>
		<Grid store={store.gridStore} />
	</Page>
})

type ShowHistoricButtonProps = {
	store: EventsSummaryStore
}
export const ShowHistoricButton = observer((props: ShowHistoricButtonProps) => {
	return <AntSwitch checkedChildren={i18n("Show Historic")}
	                  unCheckedChildren={i18n("Show Historic")}
	                  size={"small"}
	                  {...linkGridAdditionalPayload(props.store.gridStore, "showHistoricEvents")}
	/>
})

export {EventsSummaryPage as default, EventsSummaryPageStore as Store, EventsSummaryPageContext as Context}
