import {AntSelect, AntSelectPropsPlain, AntSelectValue} from "../../../../react/ant/antSelect";
import React, {useEffect, useState} from "react";
import {apiFetch} from "../../../../../framework/api";
import {getCurrencies} from "../../../../../areas/cost/api";

const i = require('core/localization').translator({})

export type CurrencyDropDownProps = AntSelectPropsPlain<AntSelectValue> & {
	accountId?: string;
	defaultValue?: string;
}

export const CurrencyDropDown = (props: CurrencyDropDownProps) => {
	const {accountId, defaultValue, ...others} = props;
	const [currencies, setCurrencies] = useState([]);
	useEffect(() => {
		const load = async () => {
			const result = await apiFetch(getCurrencies(accountId));
			if (result.success) {
				setCurrencies(result.data);
			}
		};
		load();
	}, [accountId]);
	return (
		<AntSelect placeholder={i('Select...')}
				   dataList={currencies}
				   nameField={'text'}
				   valueField={'value'}
				   defaultValue={defaultValue ?? 'NOK'}
				   {...others}
		/>
	);
}
