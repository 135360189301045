import {makeAutoObservable} from "mobx";
import {State} from "tools";
import {getLastEntry} from "tools/helpers/array";
import {getUrlBuilderForCurrentHash} from "tools/urlBuilder";

export class NavigationItem{
	config?: {
		moduleName?: string,
		id: string
	}
	url?: string
	title: string
}

type PushExtraArgs = {
	action?: NavigationAction,
	defaultItems?: NavigationItem[]
}

type NavigationStoreGoAdditionalArgs = {
	extra?: any,
	newTab?: boolean,
	doNotPushState?: boolean,
	navigationAction?: NavigationAction
}

class NavigationStoreInner {
	items: NavigationItem[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	go(url: string, additional?: NavigationStoreGoAdditionalArgs) {
		State.mainApp.navigate(url, additional)
	}

	push = async (item: NavigationItem, extra: PushExtraArgs = {}) => {
		const action = extra?.action ?? NavigationAction.Add
		const currentItem = this.items[this.items.length - 1]

		if (action != NavigationAction.Clear
			&& item.config && currentItem?.config
			&& item.config.moduleName == currentItem.config.moduleName
			&& item.config.id == currentItem.config.id) {
			return
		}

		(action == NavigationAction.Clear) && (this.items = []);
		(action == NavigationAction.PopOne) && (this.items.pop());
		(action == NavigationAction.PopTwo) && (this.items.pop() && this.items.pop())

		if(this.items.length == 0){
			this.items.splice(0, 0, ...(extra?.defaultItems ?? []))
		}

		const existingItemIndex = this.items
			.findIndex(existingItem =>
				existingItem.url && existingItem.url == item.url
				|| existingItem.config?.moduleName && existingItem.config?.moduleName == item.config?.moduleName
			);

		if (action == NavigationAction.RewindToTheSame) {
			if (existingItemIndex != -1) {
				this.items.splice(existingItemIndex, this.items.length)
			}
			this.items.push(item)
		} else {
			if (existingItemIndex == -1) {
				this.items.push(item)
			}
		}
	}

	pop = () => {
		const items = this.items;
		if( items.length == 1){
			this.go('#/');
			return;
		}

		const targetItem = items[items.length - 2]
		this.goToItem(targetItem)
	}

	goToItem = (item: NavigationItem) => {
		let itemIndex = -1
		let items = this.items
		for (let i = 0; i < items.length; i++) {
			if (JSON.stringify(items[i]) === JSON.stringify(item)) {
				itemIndex = i
			}
		}

		if (itemIndex == -1)
			return

		this.items.splice(itemIndex, this.items.length)

		if (item.config) {
			if (item.config.moduleName || !item.url) {
				State.mainApp.loadModule(JSON.parse(JSON.stringify(item.config)))
			} else {
				State.mainApp.navigate(item.url)
			}
		} else {
			State.mainApp.navigate(item.url)
		}

	}

	updateCurrentItem = (params: object) => {
		const items = this.items
		const currentItem = items[items.length - 1]
		currentItem.config = {...currentItem.config, ...params}
	}

	updateCurrentItemUrl = (params: object) => {
		const currentItem = getLastEntry(this.items)
		if(currentItem?.url == null)
			return;

		let builder = getUrlBuilderForCurrentHash()
		Object.assign(builder.params, params)
		let newUrl = builder.build()
		window.history.replaceState(null, null, '#' + newUrl)

		currentItem.url = '#' + newUrl
	}
}

export const NavigationStore = new NavigationStoreInner();

export enum NavigationAction {
	Add = 'add',
	Clear = 'clear',
	PopOne = 'pop one',
	PopTwo = 'pop two',
	RewindToTheSame = 'rewind to the same'
}

