import { Cookies } from "core/cookies";
import {makeAutoObservable} from "mobx";
import State from "tools/state";
import {Permission} from "framework/permission";
import {MonitorType} from "areas/assets/monitors/monitorType";

class ApplicationStateInner {
	userName: string
	userId: string

	apiToken: string;
	accountId: string;
	accountName: string;

	timezone: string;

	dateFormat: string;
	timeFormat: string;
	dateTimeFormat: string;

	locale: string;

	apiUrl: string = '/rest/';

	includeSubaccounts: boolean;

	standalone: boolean;

	enabledMonitors: {
		value: MonitorType
		text: string
		sharedAgents: boolean
	}[] = []

	userTags: string[] = []
	ignoreTags: boolean
	showUntagged: boolean

	constructor() {
		makeAutoObservable(this);
	}

	get isGuestUser(){
		return this.userName == 'public'
	}

	get tagsEnabled(){
		return this.userTags?.length > 0 && !this.ignoreTags
	}

	initFromCookies() {
		this.apiToken = Cookies.get('sessionId');
		this.accountId = Cookies.get('CeesoftCurrentAccountId');
		this.accountName = Cookies.get('CeesoftCurrentAccountName');
		this.dateFormat = Cookies.get('CeesoftUserDateFormat');
		this.timeFormat = Cookies.get('CeesoftUserTimeFormat');
		this.locale = Cookies.get('CeesoftUserLocale');
		this.dateTimeFormat = Cookies.get('CeesoftUserDateTimeFormat');
		this.timezone = Cookies.get('CeesoftTimezone')
		this.userName = Cookies.get("CeesoftUsername")
		this.userId = Cookies.get("CeesoftUserId")
	}

	hasPermissions = (...permisisons: Permission[]) => {
		return permisisons.every(p => State.mainApp.session.hasRole(p))
	}
}

export const ApplicationState = new ApplicationStateInner();
