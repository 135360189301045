import {DataListEntry} from "framework/entities/dataList";
import {AgentState} from "framework/entities/agentState";
import {translator} from "core/localization";
import {createModelSchemaWrapper} from "framework/serializr-integration";
import {primitive} from "serializr";

const i18n = translator({
	'Agent in warning': {
		no: 'Agent har advarsel'
	},
	'Monitor error: data unavailable': {
		no: 'Monitorfeil: Data er ikke tilgjengelig'
	},
	'Monitor error': {
		no: 'Monitorfeil'
	},
	'Monitor in maintenance': {
		no: 'Monitor in vedlikehold'
	},
	'Asset in maintenance': {
		no: 'Ressursen er satt i vedlikehold'
	},
	'Agent in maintenance': {
		no: 'Agent er i vedlikehold'
	},
	'Service in maintenance': {
		no: 'Tjenesten er i vedlikehold'
	}
})

export {i18n as healthDataLocalization}

// AGENT_DOWN: 'Agenten er nede',
// 	QUALIFIER_ERROR: 'Tjenestepunkt feil',
// 	METRIC_ERROR: 'Metrikkfeil',
// 	AGENT_MAINTENANCE: 'Agent er i vedlikehold',
// 	ASSET_MAINTENANCE: 'Ressursen er satt i vedlikehold',
// 	MONITOR_MAINTENANCE: 'Monitor in vedlikehold',

export enum Severity {
	None = 'NONE',
	Minor = 'MINOR',
	Major = 'MAJOR',
	Critical = 'CRITICAL',
	Invalid = 'INVALID'
}

export enum SlaSeverity {
	Breached = 'BREACHED',
	Warning = 'WARNING',
	Compliant = 'COMPLIANT'
}

let severityDataList: Array<DataListEntry<Severity>>;

export function getSeverityDataList(): typeof severityDataList{
	if(severityDataList == null){
		severityDataList = [{
			id: Severity.None,
			name: i18n('None')
		},{
			id: Severity.Minor,
			name: i18n('Minor')
		},{
			id: Severity.Major,
			name: i18n('Major')
		},{
			id: Severity.Critical,
			name: i18n('Critical')
		}];
	}

	return severityDataList;
}

export function severityToText(severity: Severity){
	switch (severity) {
		case Severity.None: return i18n('None');
		case Severity.Minor: return i18n('Minor');
		case Severity.Major: return i18n('Major');
		case Severity.Critical: return i18n('Critical');
		case Severity.Invalid: return i18n('Invalid');
	}
}

export const healthIndexToSeverity = (healthIndex:number) => {
	if(healthIndex == null)
		return Severity.Invalid;

	if(healthIndex >= 75 )
		return Severity.None;

	if (healthIndex >= 50)
		return Severity.Minor;

	if (healthIndex >= 25)
		return Severity.Major;

	if (healthIndex >= 0)
		return Severity.Critical;

	return Severity.Invalid;
}

export const severityToColor = (severity: Severity) => {
	switch (severity) {
		case Severity.None:
			return '#7cc623';
		case Severity.Minor:
			return '#f7e400';
		case Severity.Major:
			return '#f1ae31';
		case Severity.Critical:
			return '#e14b32';
		case Severity.Invalid:
			return '#dddddd';
	}
}

export class HealthDataHolder {
	agentState?: AgentState
	agentDown?: boolean
	assetMaintenance?: boolean
	healthIndex?: number
	assetHI?: number
	monitorDataUnavailable?: boolean
	monitorError?: boolean
	monitorMaintenance?: boolean
	monitorHI?: number
	severity?: Severity
	maintenanceDetails?: MaintenanceSubject[]
}

createModelSchemaWrapper(HealthDataHolder, {
	agentState: primitive(),
	agentDown: primitive(),
	assetMaintenance: primitive(),
	healthIndex: primitive(),
	assetHI: primitive(),
	monitorDataUnavailable: primitive(),
	monitorError: primitive(),
	monitorMaintenance: primitive(),
	monitorHI: primitive(),
	maintenanceDetails: primitive(),
	severity: primitive()
})

export enum MaintenanceSubject{
	Asset = 'ASSET',
	Monitor = 'MONITOR',
	Service = 'SERVICE',
	Agent = 'AGENT'
}

export class HealthDataFlags {
	severity: Severity
	exclamationMark: boolean
	wrench: boolean
	cross: boolean
	title: string
	label: string
}

const maintenanceLabelMap = {
	[MaintenanceSubject.Asset]: 'Asset in maintenance',
	[MaintenanceSubject.Monitor]: 'Monitor in maintenance',
	[MaintenanceSubject.Service]: 'Service in maintenance',
	[MaintenanceSubject.Agent]: 'Agent in maintenance',
}


export function healthDataToFlags(data: HealthDataHolder) {
	const flags = new HealthDataFlags();

	data = {...data}

	data.maintenanceDetails = data.maintenanceDetails ?? []

	if (data.assetMaintenance) {
		data.maintenanceDetails.push(MaintenanceSubject.Asset)
	}

	if (data.monitorMaintenance) {
		data.maintenanceDetails.push(MaintenanceSubject.Monitor)
	}

	if (data.agentState == AgentState.Maintenance) {
		data.maintenanceDetails.push(MaintenanceSubject.Agent)
	}

	data.healthIndex = data.healthIndex ?? data.assetHI ?? data.monitorHI

	if (data.healthIndex !== undefined && !data.severity) {
		flags.severity = healthIndexToSeverity(data.healthIndex)
	} else if (data.severity) {
		flags.severity = data.severity
	}

	flags.label = data.healthIndex?.toString() ?? 'N/A';

	if (data.maintenanceDetails.length > 0) {
		flags.wrench = true
		flags.title = data.maintenanceDetails.map(x => i18n(maintenanceLabelMap[x])).join(" | ")
	} else {

		if (data.agentState == AgentState.Error) {
			flags.cross = true;
		} else if (data.agentState == AgentState.Warning) {
			if (data.monitorDataUnavailable) {
				flags.title = i18n('Agent in warning') + ' | ' + i18n('Monitor error: data unavailable')
			} else if (data.monitorError) {
				flags.title = i18n('Agent in warning') + ' | ' + i18n('Monitor error')
			}

			if (flags.title) {
				flags.exclamationMark = true;
			}
		} else {
			if (data.monitorDataUnavailable) {
				flags.title = i18n('Monitor error: data unavailable')
			} else if (data.monitorError) {
				flags.title = i18n('Monitor error')
			}
			if (flags.title) {
				flags.exclamationMark = true;
			} else if (data.monitorMaintenance) {
				flags.title = i18n('Monitor in maintenance')
				flags.wrench = true;
			}
		}
	}

	flags.severity = flags.severity ?? Severity.Invalid;

	return flags;
}
