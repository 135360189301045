import {makeAutoObservable} from "mobx"
import {createModelSchemaWrapper, rawCopy} from "framework/serializr-integration"
import {list, object, primitive, serialize} from "serializr"

import {GridColumnState} from "controls/grid/gridColumnConfig"
import {GridDataItem} from "./gridDataItem"
import {GridStore} from "./gridStore"
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition";

export class GridViewState<DataItem extends GridDataItem> {
	id: string
	name: string

	columns: GridColumnState[] = []
	sortingOrder: string[] = []
	filters = RuleDefinition.emptyGroup()

	searchString: string

	customPayload: Record<string, any> = {}
	customData: Record<string, any> = {}

	_initialJson: string

	constructor() {
		makeAutoObservable(this)
	}

	get nameEffective(){
		if(this.isDirty){
			return this.name + '(*)'
		}else{
			return this.name
		}
	}

	get isDirty(){
		if(this.isDefault() || this.isTemporary())
			return false

		const currentJson = JSON.stringify(serialize(this))
		return currentJson != this._initialJson
	}

	isDefault(){
		return this.id == defaultViewId
	}

	isTemporary(){
		return this.id == tempViewId
	}

	resetToDefault(store: GridStore<DataItem>) {
		if(this.isDefault()) {
			this.sortingOrder = []
			this.filters = RuleDefinition.emptyGroup()

			this.searchString = null

			this.columns = []
			this.customPayload = {}
			this.customData = {}

			this.validate(store)

			if (store.config.defaults?.sorting) {
				store.config.defaults.sorting.forEach(x => {
					const column = this.columns.find(c => c.field == x.field)
					if (!column) {
						console.warn('Column not found', x.field)
						return
					}
					this.sortingOrder.push(x.field)
					column.sorting = x.direction
				})
			}

			let defaultColumns = store.config.defaults?.columns
			if (defaultColumns) {
				let i = 0
				for (const field of defaultColumns) {
					let columnIndex = this.columns.findIndex(x => x.field == field)
					if (columnIndex == -1) {
						console.warn('Column not found', field)
						continue
					}

					const [column] = this.columns.splice(columnIndex, 1)
					this.columns.splice(i++, 0, column)
				}

				for (; i < this.columns.length; i++) {
					this.columns[i].visible = false
				}
			}

			const defaultFilters = store.config.defaults?.filters
			if (defaultFilters) {
				for (const defaultFilterEntry of defaultFilters) {
					const rule = RuleDefinition.emptyRule(defaultFilterEntry.field, defaultFilterEntry.operator)
					rule.properties.value = defaultFilterEntry.value
					this.filters.addOrUpdateRule(rule)
				}
			}
		}else{

		}
	}

	validate = (store: GridStore<DataItem>) => {
		this.filters.removeInvalidRules()
		this.addMissingColumns(store)
		this.deleteInvalidColumns(store)

		this.columns.forEach(x => x.fixed = x.fixed == null ? 'none' : x.fixed)

		if(store.config.defaults?.payload != null)
		{
			Object.keys(store.config.defaults.payload).forEach(x => {
				if (this.customPayload[x] == null) {
					this.customPayload[x] = JSON.parse(JSON.stringify(store.config.defaults.payload[x]))
				}
			})
		}

		if(store.config.defaults?.data != null)
		{
			Object.keys(store.config.defaults.data).forEach(x => {
				if (this.customData[x] == null) {
					this.customData[x] = JSON.parse(JSON.stringify(store.config.defaults.data[x]))
				}
			})
		}
	}

	addMissingColumns(store: GridStore<DataItem>){
		store.columns.config.forEach(x => {
			if (!this.columns.find(y => y.field == x.field)) {
				this.columns.push(new GridColumnState({
					field: x.field,
					width: x.width,
					visible: x.visible === undefined ? true : x.visible,
					fixed: x.fixed
				}))
			}
		})
	}

	deleteInvalidColumns<T>(store: GridStore<DataItem>){
		for (let i = 0; i < this.columns.length; i++) {
			let item = this.columns[i]
			if (!store.columns.config.find(x => x.field == item.field)) {
				this.columns.splice(i--, 1)
			}
		}
	}

	calculateHash(){
		this._initialJson = JSON.stringify(serialize(this))
	}
}

createModelSchemaWrapper(GridViewState, {
	id: primitive(),
	name: primitive(),
	columns: list(object(GridColumnState)),
	sortingOrder: list(primitive()),
	filters: object(RuleDefinition),
	customPayload: rawCopy(),
	customData: rawCopy(),
	searchString: primitive()
});

export const defaultViewId = 'default'
export const tempViewId = 'temp view'
