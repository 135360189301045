import './graph-editor/styles/grapheditor.less';

import {Cookies} from 'core/cookies'
import {StateColors} from 'tools/states';
import {Api} from 'tools/api';
import {Utils, ensureMaterialIconsLoaded, isNullOrWhitespace, ensureGlyphIconsLoaded} from "tools/utils";

import {Dialog} from "controls/dialog";

import {StatesManager} from './statesManager';

import {ServicesApi} from "areas/services/api";
import {executeUpdate, isCellGenerated, iterateAllCells} from "./utils";
import ServiceDataSourceElement from 'controls/designer/dataSourcesManager/serviceDataSourceElement';
import {DesignerEvents} from "controls/designer/shared";
import {DesignerActions} from "controls/designer/shared";
import {State} from "tools";
import {enableFeatures} from "controls/designer/features/features";
import ImageUploader from "../imageUploader";
import {LayoutType} from "controls/designer/features/adaptiveLayout/layoutType";
import {DesignerStore} from "controls/designer/designerStore";
import {UserSettings} from "tools/userSettings";
import {toJS} from "mobx";
import {newGuid} from "tools/guid";

let i = require('core/localization/localization').translator({
  "Image Shape": {
    "no": "Bildeform"
  },
  "Connectors": {
    "no": "Koblinger"
  },
  "Shapes": {
    "no": "Former"
  },
  "All": {
    "no": "Alle"
  }
});

export class Designer{
	config = null;
	deferred = null;

	entityToCellMap = null;
	services = null;
	slas = null;
	assets = null;

	dashboardSettings = {};

	cleanUpCallbacks = [];

	//field to store an xml of model just after it was loaded to use in before unload handler
	initialXml = null;
	store;

	constructor(config) {
		this.id = newGuid()

		this.config = {
			padding: 0,
			...config
		}

		if(this.config.features == null){
			this.config.features = {};
		}

		this.initGraphEditor();
	}

	createEditor (themes) {
		var editor = new Editor(this.config.chromeless, themes);
		editor.cancelFirst = false;
		editor.extendCanvas = false;


		this.editorUi = new EditorUi(this, editor, this.config.container, this.config);
		this.ui = this.editorUi;

		this.editor = this.ui.editor;
		this.graph = this.ui.editor.graph;
		this.graph.designer = this;
		this.graph.config = this.config;
	}

	async initGraphEditor () {
		let promises = [];
		let themes = {};

		this.store = new DesignerStore(this);

		this.store.settings = await UserSettings.get(this.getDesignerType());
		if(this.store.settings.windows == null){
			this.store.settings.windows = {};
		}


		if(State.mainApp == null || State.mainApp.standalone ) {
			//if we are not in standalone mode then icons-font should be loaded already

			//if icons are not loaded before designer starts rendering
			//then it calculates size of icons wrong way because they are shown as name-text at that time like 'star'
			promises.push(ensureMaterialIconsLoaded());
			promises.push(ensureGlyphIconsLoaded());
		}

		mxResources.loadDefaultBundle = false;
		var bundle = mxResources.getDefaultBundle(RESOURCE_BASE, Cookies.CeesoftUserLocale === 'no' ? 'no' : null) ||
			mxResources.getSpecialBundle(RESOURCE_BASE, Cookies.CeesoftUserLocale === 'no' ? 'no' : null);

		promises.push(
			Api.fetch(bundle, {plainText: true})
				.then(r => mxResources.parse(r))
		);

		promises.push(
			Api.fetch(STYLE_PATH + '/default.xml', {plainText: true})
				.then(r => {
					themes[Graph.prototype.defaultThemeName] = mxUtils.parseXml(r).documentElement;
				})
		);

		await Promise.all(promises);

		this.createEditor(themes);

		//we disable rendering until all additional information is loaded and graph is updated
		this.graph.view.rendering = false;

		await enableFeatures(this);

		this.store.featuresLoaded = true;

		//this exclude customData property from serialization to xml
		mxCodecRegistry.getCodec(mxCell).exclude.push('customData');
		mxCodecRegistry.getCodec(mxCell).exclude.push('designerElement');


		if (this.config.disableCellEdit) {
			this.graph.cellsEditable = false;
		}

		this.ui.customData = this.config.data;

		this.graph.allowNegativeCoordinates = false;

		this.addToolbarButtons();

		if (!isNullOrWhitespace(this.config.data?.xml)) {
			const xml = this.config.data.xml;
			const doc = mxUtils.parseXml(xml);
			this.ui.editor.setGraphXml(doc.documentElement);

			if (this.config.data.model) {
				this.initCustomData(this.config.data.model);
			}
		}

		if (this.config.chromeless) {
			if (this.shouldFit()) {
				this.ui.setScrollbars(false);
			}

			//disables scrolling if you hold mouse button near border and move it a bit
			this.graph.allowAutoPanning = false;

			this.graph.cellsMovable = false;
			this.graph.cellsSelectable = !!this.config.allowSelectionInReadOnlyMode;
			this.graph.graphHandler.setMoveEnabled(false);
		}

		await this.notifyFeaturesThatXmlLoaded();

		this.graph.fireEvent(new mxEventObject(DesignerEvents.XmlLoaded), this);

		this.config.onXmlLoaded && this.config.onXmlLoaded(this);

		this.graph.connectionArrowsEnabled = this.config.connectionArrowsEnabled !== false;
		if(this.config.mode == 'application'){
			this.graph.setDropEnabled(false);
		}

		this.statesManager = new StatesManager(this);

		await this.statesManager.showStates();

		this.ui.editor?.undoManager.clear();

		this.initialXml = this.getXml();


		this.graph.view.rendering = true;
		this.graph.refresh();

		if(this.config.chromeless) {
			if (this.shouldFit()) {
				this.fit();
			} else if (this.config.alignOnTopLeft) {
				this.graph.alignOnTopLeft();
			}
		}

		if (this.config.onLoaded && typeof (this.config.onLoaded) === 'function') {
			this.config.onLoaded(this);
		}

		this.initDragAndDrop();
		this.addActions();

		this.graph.fireEvent(new mxEventObject(DesignerEvents.Loaded), this);
		this.config.extensionsManager?.editorLoaded();

		this.store.init()
	}

	get containerBoundingRect(){
		return this.config.container.querySelector('.geDiagramContainer').getBoundingClientRect()
	}


	_xmlLoadedCallbacks = [];

	async notifyFeaturesThatXmlLoaded(){
		for( const callback of this._xmlLoadedCallbacks){
			await callback(this);
		}
	}

	registerForXmlLoaded(callback){
		this._xmlLoadedCallbacks.push(callback);
	}

	initDragAndDrop(){
		const diagramContainer = this.config.container.querySelector('.geDiagramContainer');

		diagramContainer.addEventListener('dragover', e => {
			e.preventDefault();
		});

		diagramContainer.addEventListener('drop', e => {
			e.preventDefault();
			let data = e.dataTransfer.getData('designer/new-entry');
			if(!data)
				return;

			const entries = JSON.parse(data);
			this.graph.getModel().beginUpdate();
			try {
				const [x,y] = this.getMouseCoords();
				mxUtils.placeElementsInGrid(entries,  new mxGeometry(x, y, 50, 50), 10, 50, 30, (entry, position) => {
					this.graph.insertEntity(entry, position);
				} );
			} finally {
				this.graph.getModel().endUpdate();
			}
		});
	}

	getMouseCoords(e){
		e = e || window.event;
		const parentRect = this.config.container.querySelector('.geDiagramContainer')
			.getBoundingClientRect();

		return [e.clientX - parentRect.left, e.clientY - parentRect.top];
	}

	addToolbarButtons () {
		if (this.ui.toolbar == null || this.ui.config.chromeless)
			return;

		if(this.config.mode == 'presentation')
			return;

		this.ui.toolbar.addItems( ['outline']);
	}

	selectElement(elementId){
		var cell = this.graph.getCellForServiceElement(elementId);
		this.graph.setSelectionCells([cell]);
	}

	initCustomData(serviceModel){
		const cells = this.graph.getModel().cells;

		for (let i in cells) {
			if (!cells.hasOwnProperty(i))
				continue;

			let cell = cells[i];
			let elementId = cell.getDatasource()?.serviceElements[0].id;
			if( elementId == null )
				continue;

			var customData = serviceModel.nodes.find(o => o.id == elementId );

			if( customData == null )
				continue;

			this.setCellCustomData(cell, customData);
		}
	}

	setCellCustomData(cell, customData) {
		cell.customData = customData;

		if (cell.customData.type == 'ROOT') {
			this.graph.setSelectionCell(cell);
		}

		customData.getFirstLevelChildrenNodes = function () {
			var nodes = [];
			for (var i = 0; i < cell.getEdgeCount(); i++) {
				var edge = cell.getEdgeAt(i);
				if (edge.source == cell && edge.target != null) {
					nodes.push(edge.target.customData);
				}
			}

			return nodes;
		}
	}

	generateImageUrl(image){
		//This code is needed because previously the full url were stored in xml instead of just ID
		if (image.indexOf('/') != -1) {
			let imageParts = image.split('/');
			let fileNamePart = imageParts[imageParts.length - 1];
			if (Utils.isGuid(fileNamePart))
				image = fileNamePart;
		}

		//images were moved in 2.5 from one folder to another
		image = image.replace('assets/graphEditorExt', 'assets-static/designer/drawio');
		image = image.replace('img/ibm', IMAGE_PATH + '/ibm');

		if (Utils.isGuid(image)) {
			let imageAccountId = this.config.subaccountId || this.config.accountId;
			image = Api.images.urls.image(image, imageAccountId, this.config.sessionId || Cookies.sessionId);
		}

		return image;
	}

	getTooltipForCell (cell) {
		return this.statesManager.getTooltipForCell(cell);
	}

	isDirty () {
		const currentXml = this.getXml();
		return this.initialXml != currentXml;
	}

	getXml () {
		if (this.ui == null || this.ui.editor == null)
			return null;

		let xml = this.ui.getEditBlankXml();

		xml = this.removeStateColors(xml);
		xml = this.removeForceInvalidateStyle(xml)
		return xml;
	}

	cleanUp() {
		this.graph.view.rendering = false;
		this.cleanUpCallbacks.forEach(x => x(this));
		this.removeGeneratedShapes();
	}

	removeGeneratedShapes() {
		let cellsToRemove = [];
		iterateAllCells(this.graph, cell => {
			if (isCellGenerated(cell)) {
				cellsToRemove.push(cell)
			}
		});

		if (cellsToRemove.length) {
			executeUpdate(this.graph, graph => {
				graph.removeCells(cellsToRemove, false);
			});
		}
	}

	removeForceInvalidateStyle(xml) {
		//these colors are set by States managers and 1) should not be saved 2) will cause fails in dirty check
		let re = new RegExp(`forceInvalidate=[^;"]*([";])`, 'g')

		xml = xml.replace(re, (match, endingCharacter) => endingCharacter == '"' ? '"' : '');

		return xml;
	}

	removeStateColors(xml) {
		let re = new RegExp(
			`(${mxConstants.STYLE_FILLCOLOR}|${mxConstants.STYLE_IMAGE_BACKGROUND})`
			+ `=(${StateColors.ACTIVE}|${StateColors.INACTIVE}|${StateColors.ERROR}|${StateColors.INVALID}|${StateColors.WARNING})(;)?`, 'g')

		xml = xml.replace(re, () => '');

		//cleaning up traling ; in styles since it might be there or migth not depending on what happend
		return xml.replace(new RegExp('(style="[^"]*);"', 'g'), (everything, firstPart) => firstPart + '"' );
	}

	shouldFit(){
		return this.config.fit || this.dashboardSettings.layoutType == LayoutType.Scale;
	}

	fit(){
		if(this.shouldFit() && this.config.chromeless ){
			this.fitInternal();
		}
	}

	fitInternal(){
		if(!this.graph)
			return;

		let paddings = this.graph.previewPaddings;
		if(paddings == undefined) {
			paddings = this.config.padding;
		}

		this.graph.fit(null, null, paddings);
	}

	addActions(){
		this.ui.actions.addAction(DesignerActions.InsertImage, () => {
			this.graph.getModel().beginUpdate();

			try{
				const [x,y] = this.getMouseCoords();
				const data = mxUtils.createUserObject({
					disableDataSourceSettings: true,
					label: ''
				});

				let cell = this.graph.insertVertex(this.graph.getDefaultParent(), null, data,
					x, y, 50, 50,
					"shape=image;html=1;verticalLabelPosition=bottom;"
					+ "labelBackgroundColor=#ffffff;verticalAlign=top;imageAspect=0;image="+ Sidebar.prototype.gearImage + ";"
					+ mxConstants.STYLE_ICON_COLOR + "=#000000;"
				);

				this.ui.actions.get('set-image').funct(cell);
			}finally{
				this.graph.getModel().endUpdate();
			}
		}, null, null, null, i('Image Shape'));

		this.ui.actions.addAction('set-image', (cell) => {
			const modalContent = `
				<div id="imageUploaderModal" class="cw_media_library_content"></div>
					<div class="actions">
						<button class="k-button right" id="icon_cancel">${ lang.CANCEL }</button>
						<button class="k-button k-primary go_right" id="change_icon">${ lang.UPDATE }</button>
				</div>
				<div class="status"><p></p></div>
			`

			let $modal = $('#modal');
			if (!$modal.length) {
				$modal = $('<div id="modal"></div>');
				$('body').append($modal);
			}

			$modal.kendoWindow({
				title: lang.MEDIA_LIBRARY,
				width: '640',
				height: '480',
				modal: true,
				close: function () {
					this.destroy();
					$modal.remove();
				}
			});

			let kendoWindow = $modal.data("kendoWindow");

			kendoWindow.content(modalContent);
			kendoWindow.center();

			let cellStyle = this.graph.getCellStyle(cell);
			let currentImageStyle = {
				id: mxUtils.getValue(cellStyle, mxConstants.STYLE_IMAGE),
				contentType: mxUtils.getValue(cellStyle, mxConstants.STYLE_IMAGE_CONTENT_TYPE),
				iconsPack: mxUtils.getValue(cellStyle, mxConstants.STYLE_IMAGE_ICONS_PACK)
			};

			let updateImage = ({ contentType, iconsPack, id }) => {
				const graph = this.ui.editor.graph;
				graph.getModel().beginUpdate();
				try {
					graph.setCellStyles(mxConstants.STYLE_IMAGE, id, [cell]);
					graph.setCellStyles(mxConstants.STYLE_IMAGE_ICONS_PACK, iconsPack, [cell]);
					graph.setCellStyles(mxConstants.STYLE_IMAGE_CONTENT_TYPE, contentType, [cell]);
				}
				finally {
					graph.getModel().endUpdate();
				}
			}

			new ImageUploader({
				id: 'imageUploaderModal',
				iconsTabEnabled: true,
				onImageUploadedAndSelected: () => kendoWindow.close(),
				onImageSelected:  (image) => {
					updateImage({ contentType: image.type, iconsPack: image.iconPack, id: image.id });
				}
			});

			$('#change_icon').off().on('click', () => kendoWindow.close());

			$('#icon_cancel').off().on('click', () => {
				updateImage(currentImageStyle);
				kendoWindow.close();
			});
		});


		this.ui.actions.addAction("insert-asset", () => {
			this.graph.getModel().beginUpdate();

			try{
				const [x,y] = this.getMouseCoords();
				this.graph.insertVertex(this.graph.getDefaultParent(), null,
					mxUtils.createUserObject(Sidebar.prototype.ceeviewAssetUserObject),
					x, y, 50, 50,
					Sidebar.prototype.ceeviewAssetStyle
				);

			}finally{
				this.graph.getModel().endUpdate();
			}
		}, null, null, null, i('Asset'));

		this.ui.actions.addAction("insert-asset-group", () => {
			this.graph.getModel().beginUpdate();

			try{
				const [x,y] = this.getMouseCoords();
				this.graph.insertVertex(this.graph.getDefaultParent(), null,
					mxUtils.createUserObject(Sidebar.prototype.ceeviewAssetGroupUserObject),
					x, y, 50, 50,
					Sidebar.prototype.ceeviewAssetGroupStyle
				);

			}finally{
				this.graph.getModel().endUpdate();
			}
		}, null, null, null, i('Asset Group'));

		this.ui.actions.addAction(DesignerActions.SelectConnectors, () => {
			this.ui.actions.get('selectEdges').funct();
		}, null, null, null, i('Connectors'));

		this.ui.actions.addAction(DesignerActions.SelectShapes, () => {
			this.ui.actions.get('selectVertices').funct();
		}, null, null, null, i('Shapes'));

		this.ui.actions.addAction(DesignerActions.SelectAll, () => {
			this.ui.actions.get('selectAll').funct();
		}, null, null, null, i('All'));
	}

	getDesignerType = () => {
		if(this.config.mode == 'service'){
			return 'designerUserSettings'
		}
		return this.config.mode;
	}

	registerForCleanUp(callback){
		this.cleanUpCallbacks.push(callback);
	}

	_onDestroyCallbacks = []
	onDestroy(callback){
		this._onDestroyCallbacks.push(callback)
	}

	async destroy () {
		this._onDestroyCallbacks.forEach(x => x())

		this.store.destroy()
		this.statesManager?.destroy();
		this.ui?.destroy();

		await UserSettings.set(this.getDesignerType(), toJS(this.store.settings));
	}
}

const datasourceAttributes = ['redirectConfig', 'datasource', 'entityType','serviceElementId',
	'serviceId', 'assetId', 'assetMonitorId', 'assetGroupId', 'slaId', 'kpiId', 'metricsIds', 'totalMetric', 'totalMetricType',
	'displayUnitType', 'showUnit', 'customUnit', 'displayLabelType', 'useDataSourceAsLabel', 'assetGroupShowSummary', 'readOnlyDataSource', 'showHealthIndex',
	'decimalsNumber', 'metricValueAsSeverity', 'metricValueToSeverity', 'expanded', 'hideMetricValue'];

export const extractDatasourcesFromXmlString = (xml) => {
	let dom = mxUtils.parseXml(xml);
	let datasources = extractDatasourcesFromXmlTree(dom.documentElement.firstChild);

	return [mxUtils.getXml(dom), datasources];
}

const extractDatasourcesFromXmlTree = (node, datasources = {}) => {
	for(const child of node.children){
		extractDatasourcesFromXmlTree(child, datasources);

		if(child.id == null)
			continue;

		for(const datasourceAttribute of datasourceAttributes){
			if(child.getAttribute(datasourceAttribute) != null){
				if(datasources[child.id] == null){
					datasources[child.id] = {}
				}

				datasources[child.id][datasourceAttribute] = child.getAttribute(datasourceAttribute);
				child.removeAttribute(datasourceAttribute);
			}
		}
	}

	return datasources;
}

export const cleanupIdsFromXmlTreeString = (xml) => {
	let dom = mxUtils.parseXml(xml);
	let datasources = cleanupIdsFromXmlTree(dom.documentElement.firstChild);

	return mxUtils.getXml(dom);
}

export const cleanupIdsFromXmlTree = (node) => {
	for(const child of node.children){
		extractDatasourcesFromXmlTree(child);

		if(child.id == null)
			continue;

		for(const datasourceAttribute of datasourceAttributes){
			if(child.getAttribute(datasourceAttribute) != null){
				child.removeAttribute(datasourceAttribute);
			}
		}

		const widgetConfigString = child.getAttribute('config');
		if(widgetConfigString != null){
			const widgetConfig = JSON.parse(widgetConfigString);

			const cleanedConfig = {
				type: widgetConfig.type
			}
			child.setAttribute('config', JSON.stringify(cleanedConfig))
			child.removeAttribute('customProperties');
		}
	}
}
