import "./shutdownPage.less";
import React from 'react';
import {translator} from "../../core";
import Settings from "../../settings";
import {Api} from "tools";

const i = translator({
	"Title": {
		"en": "Ceeview Shutdown"
	},
	"Ceeview down": {
		"en": "Ceeview is down due to database issue"
	},
	"Support text": {
		"en": "We are committed to providing first-class technical support for our Products and Services. Through our support portal, you can take full advantage of our support solution.\n\n"
	},
	"Portal link": {
		"en": "Sign in to the Ceeview Customer Support Portal.\n\n"
	},
	"Contact text": {
		"en": "You can also use the following email or phone to contact our support team:\nsupport@ceeview.com or call +47 24 07 72 00"
	},
	"Ceeview is currently starting": {
		"en": "Ceeview is currently starting\n"
	}
});

const b = require('b_').with('shutdown');

interface ShutDownPageState {
	progress: number
}

export default class ShutDownPage extends React.Component<any, ShutDownPageState> {
	progressInterval: ReturnType<typeof setInterval>;

	constructor(props: any) {
		super(props);
		this.state = {
			progress: 0
		};
	}
	render() {
		return(
			<div className={b()}>
				<div className={b("logo")}></div>
				<div className={b("banner")}></div>
				<div className={b("title")}>{i("Title")}</div>
				<div className={b("subtitle")}>
					{i("Ceeview down")}
					<div className={b("progress")}>
						{i("Ceeview is currently starting")}
						{this.state.progress}%</div>
					</div>
				<div className={b("text")}>
					{i("Support text")}
					<a href={"https://ceesoft.na1.teamsupport.com/login/user"}>{i("Portal link")}</a>
					{i("Contact text")}
				</div>
			</div>
		);
	}

	componentDidMount = () => {
		setTimeout(() => {
			this.progressInterval = setInterval(this.getProgress, 1000);
		}, 11000);
	}

	getProgress = async () => {
		const url = Settings.managementPath + 'progress';
		let result = await Api.fetch(url);
		if(result.success) {
			this.setState({
				progress: result.data
			});
			if(result.data == 100) {
				clearInterval(this.progressInterval);
				window.location.replace(Settings.httpRoot + "#/login.jsp");
				window.location.reload();
			}
		}
	}
}
