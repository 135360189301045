import {Application, LocalEventsManager, Cookies, RemoteEventsManager, ErrorHandler} from 'core';
import {
	CustomNotification,
	Dialog,
	EventsToolbar,
	GridMenu,
	ModalView,
	ModalWindow,
	MultiToggle,
	PreferencesPanel
} from 'controls';
import {Utils, UserPrefs, State} from 'tools';
import {Settings} from 'settings';
import {AuthenticationForm} from "areas/administration/authenticationForm";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import Api from 'tools/api';
import Dependencies from 'controls/dependencies.js';
import {AuthenticationDropDown} from "controls/react/dropdowns/authenticationDropDown";
import Window from "controls/react/kendoWrappers/window";
import React from "react";
import ReactDOM from "react-dom";
import {AuthenticationReplacementWindow} from './authenticationReplacementWindow'
import {translator} from "core";
import {topLeftCornerPosition} from 'controls/modalWindow'

let i = translator({
  "Replace authentication": {
    "no": "Erstatt autentisering",
    "en": "Replace Authentication"
  },
  "Replace all": {
    "no": "Erstatt alle",
    "en": "Replace All"
  }
});

export let AuthenticationsView = function (config) {
	Utils.apply(this, config);
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

export default AuthenticationsView;

jQuery.extend(AuthenticationsView.prototype, Application.prototype, {
	/**
	 * Authentications view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.getTags();

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 180
			},
			authenticationTypeText: {
				hidden: false,
				width: 150
			},
			tags: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};
		this.loadUserPreferences();

		// update content button
		State.mainApp.contextMenu.setActionButton('AUTHENTICATION');
		this.cardGridToggle = new MultiToggle({
			id: 'cw_authentications_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_authentications_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: State.mainApp.onNewAuthentication,
				scope: State.mainApp,
				role: 'AUTHENTICATION_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onAuthenticationDelete,
				scope: this,
				disabled: true,
				role: 'AUTHENTICATION_DELETE'
			}]
		});
		this.removeMask();
		this.removeListeners();
		this.attachListeners();
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {Object} e The click event
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			if (this.grid && this.gridViewLoaded) {
				this.showGridView = true;
				$('#cw_authentications_list').addClass('hide');
				$('#cw_authentications_grid').removeClass('hide');
				this.grid.refresh();
			} else {
				this.initGridView();
				this.grid.refresh();
			}
		} else {
			$('#cw_authentications_grid').addClass('hide');
			if (this.innerView) {
				this.showGridView = false;
				$('#cw_authentications_list').removeClass('hide');
				//$('#cw_filter_reset').addClass('inactive');
			} else {
				this.initCardView();
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#authentications_grid').off();
		this.onAuthenticationSavedHandler = $.proxy(this.onAuthenticationSaved, this);
		LocalEventsManager.unbind('authenticationsaved', this.onAuthenticationSavedHandler);
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#authentications_grid').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#authentications_grid').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#authentications_grid').on('click', '.cw_grid_link', $.proxy(this.onAuthNameClick, this));
		LocalEventsManager.bind('authenticationsaved', this.onAuthenticationSavedHandler);
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		$('#authentication_group_name').hide();
		var deleteClass = State.mainApp.session.hasRole('AUTHENTICATION_DELETE') ? '' : ' hide';
		var updateClass = State.mainApp.session.hasRole('AUTHENTICATION_UPDATE') ? '' : ' hide';
		var template = '<li><div class="cw_card" id="${id}">';
		template += '<div class="cw_card_avatar">';
		template += '<div class="cw_avatar cw_avatar_auth"></div>';
		template += '</div>';
		template += '<div class="cw_card_content">';
		template += '<h2 class="card_title"><strong>' + lang.NAME + ':</strong> ${name} </h2>';
		if (State.mainApp.session.hasRole("AUTHENTICATION_READ")) {
			template += '<p class="card_extra"><strong>' + lang.DESCRIPTION + ':</strong> #if(description){# ${description} #}# </p><p class="card_extra"><strong>' + lang.TYPE + ': ${authenticationTypeText}</strong> </p>';
		}
		template += '</div>';
		template += '<div class="cw_card_options">';
		template += '<span class="glyphicons bin ' + deleteClass + '"></span>';
		template += '<span class="glyphicons pencil ' + updateClass + '"></span>';
		template += '</div>';
		template += '</div></li>';
		this.showGridView = false;
		$('#cw_authentications_view_toggle').find('li').last().addClass('is_selected');

		this.innerView = new ModalView({
			autoLoad: true,
			createButtonText: lang.account.AUTH_NEW,
			viewDataSource: this.dataSource,
			template: template,
			id: 'cw_authentications_list',
			cssClass: 'auth_ul',
			title: lang.AUTHENTICATIONS,
			pager: true,
			onCreateButton: function (e) {
				if (State.mainApp.session.hasRole('AUTHENTICATION_CREATE')) {
					var modalWindow = new ModalWindow({
						title: lang.CREATE,
						width: 400,
						minHeight: 400,
						maxHeight: 800,
						id: 'authentication_modal',
						url: 'include/Administration/AuthenticationForm.jsp',
						refresh: $.proxy(function () {
							new AuthenticationForm({
								id: 'new_authentication',
								modalId: 'authentication_modal',
								mode: 'create'
							});
						}, this)
					});
					modalWindow.open();
				}
			},
			onEditButton: function (e) {
				if (State.mainApp.session.hasRole('AUTHENTICATION_UPDATE') || State.mainApp.session.hasRole('AUTHENTICATION_READ')) {
					var authenticationId = e.currentTarget.parentNode.parentNode.id;
					var modalWindow = new ModalWindow({
						title: lang.account.AUTH_DETAILS,
						width: 400,
						minHeight: 400,
						maxHeight: 800,
						id: 'authentication_modal',
						url: 'include/Administration/AuthenticationForm.jsp',
						onClose: function () {
							RemoteEventsManager.discard(authenticationId);
						},
						refresh: $.proxy(function () {
							new AuthenticationForm({
								id: authenticationId,
								modalId: 'authentication_modal',
								mode: 'update'
							});
						}, this)
					});
					modalWindow.open();
				}
			},
			onDeleteButton: function (e) {
				if (State.mainApp.session.hasRole('AUTHENTICATION_DELETE')) {
					var id = e.currentTarget.parentNode.parentNode.id;
					var url = Settings.serverPath + 'accounts/authentications/' + id;
					var dialog = new Dialog({
						title: lang.INFO,
						msg: lang.account.messages.AUTH_REMOVE_CONFIRMATION,
						icon: 'ERROR',
						actionText: 'DELETE',
						scope: this,
						position: topLeftCornerPosition,
						fn: function (value, button) {
							if (button === 'ok') {
								Utils.ajax(url, 'DELETE', {}, $.proxy(function (result) {
									if (result.success) {
										this.actionNotification.setOptions({
											message: lang.account.messages.AUTH_SUCCESS_DELETED,
											status: 'success'
										}).show();

										// reload data source
										this.viewDataSource.read();
									} else {
										Utils.showInfo(lang.ALERT, result.message, result.details);
									}
								}, this));
							}
						}
					});
					dialog.show();
				}
			}
		});
		setTimeout(function () {
			$('#cw_authentications_list').removeClass('hide');
		}, 300);
	},
	/**
	 * Init grid component
	 * @param {boolean} showGrid Defaults to true
	 */
	initGridView: function (showGrid) {
		var filterMessages = lang.grid.filter,
			loadGrid = true;
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		if (showGrid === false) {
			loadGrid = false;
			this.gridViewLoaded = false;
		} else {
			this.gridViewLoaded = true;
		}
		if (loadGrid) {
			this.showGridView = true;
			$('#cw_authentications_view_toggle').find('li').first().addClass('is_selected');
			$('#cw_filter_reset').removeClass('hide');
			$('#cw_authentications_list').addClass('hide');
			$('#cw_authentications_grid').removeClass('hide');
		}

		var authenticationsColumns = this.authenticationsColumns || JSON.parse(UserPrefs.get('authenticationsColumns')) || this.defaultColumns;
		authenticationsColumns = Utils.completeColumns(authenticationsColumns, this.defaultColumns);
		this.grid = $('#authentications_grid').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			autoBind: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				hidden: authenticationsColumns.id.hidden,
				width: authenticationsColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id #">#= name #</a>',
				hidden: authenticationsColumns.name.hidden,
				width: authenticationsColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'authenticationTypeText',
				title: lang.TYPE,
				sortable: true,
				filterable: true,
				hidden: authenticationsColumns.authenticationTypeText.hidden,
				width: authenticationsColumns.authenticationTypeText.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							contains: filterMessages.ISIN,
							doesnotcontain: filterMessages.ISNOTIN
						}
					}
				},
				hidden: authenticationsColumns.tags.hidden,
				width: authenticationsColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: false,
				filterable: true,
				template: '#= description #',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: authenticationsColumns.description.hidden,
				width: authenticationsColumns.description.width
			}], authenticationsColumns),
			columnMenu: true,
			dataBound: $.proxy(this.onDataBound, this),
			change: $.proxy(this.onRowExpand, this)
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('authenticationsSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_authentications_filters',
			grid: this.grid,
			modulePrefName: 'AdministrationAuthentications',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'authenticationsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'authenticationTypeText', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#authentications_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the grid data bound event
	 */
	onDataBound: function () {
		$('#authentications_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var authentications = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				authentications.push($(checkboxes[i]).data('id'));
			}
		}
		if (authentications.length) {
			this.gridMenu.enableItem('cw_delete');
			if (authentications.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
			this.gridMenu.enableItem('cw_delete');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
		}
	},

	clearCheckboxes: function () {
		let checkAll = $('#cw_authentications_grid').find('.cw_grid_check_all');
		let checkboxes = $('#cw_authentications_grid').find('.cw_grid_check');
		let i;
		let length = checkboxes.length;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
	},

	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onAuthenticationDelete: async function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var checkboxes = $('.cw_grid_check');
			var i, length = checkboxes.length, haveAuthentications = false, checked = 0;
			var ids = [];
			for (i = 0; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var id = $(checkboxes[i]).attr('data-id');
					ids.push(id);
					haveAuthentications = true;
					checked++;
				}
			}
			this.selectedAuthentications = ids;
			if (haveAuthentications) {
				this.authenticationIndex = 0;
				var dialog = new Dialog({
					title: lang.INFO,
					msg: checked > 1 ? lang.account.messages.AUTHS_REMOVE_CONFIRMATION : lang.account.messages.AUTH_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							this.checkAuthenticationDependencies();
						}
					}, this),
					deleteFromGrid: this.grid
				});
				dialog.show();
			}
		}
	},

	checkAuthenticationDependencies: async function () {
		let authenticationId = this.selectedAuthentications[this.authenticationIndex++];
		let record, authenticationName, authenticationType;
		if (authenticationId) {
			record = this.dataSource.get(authenticationId);
			this.authenticationAccountId = record.accountId;
			authenticationName = record.name;
			authenticationType = record.authenticationType;
			let dependenciesUrl = `${Api.serverRoot()}accounts/${Cookies.CeesoftCurrentAccountId}/authentications/${authenticationId}/dependencies`;
			let result = await Api.fetch(dependenciesUrl);
			if (result.length) {
				this.openDependenciesPopup(result, authenticationName, authenticationId, authenticationType);
			} else {
				let deleteUrl = `${Api.serverRoot()}accounts/${Cookies.CeesoftCurrentAccountId}/authentications/delete`;
				let deletePayload = [authenticationId];
				let deleteResult = await Api.fetchPost(deleteUrl, deletePayload);
				if (deleteResult.success) {
					this.checkAuthenticationDependencies();
				} else {
					this.resetCheckingAuthentications();
					Utils.showInfo(lang.ALERT, deleteResult.message, deleteResult.details);
				}
			}
		} else {
			this.grid.dataSource.read();
			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
		}
	},

	openDependenciesPopup: function (dependencies, authenticationName, authenticationId, authenticationType) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}

		this.dependenciesWindow = new ModalWindow({
			title: i('Replace authentication'),
			width: 960,
			height: 640,
			minWidth: 220,
			position: topLeftCornerPosition,
			actions: [],
			url: 'include/Dependencies.jsp',
			onClose: $.proxy(function () {
				this.clearCheckboxes();
				this.checkAuthenticationDependencies();
			}, this),
			refresh: $.proxy(function () {
				$('#cw_dependencies_deleteall').removeClass('hide').text(i('Replace all'));
				$('#cw_dependencies_close').text(lang.CANCEL);
				this.dependencyWindow = new Dependencies({
					accountId: this.authenticationAccountId,
					dependencies: dependencies,
					name: authenticationName,
					container: '#cw_agents_list',
					selectedChecks: authenticationId,
					infoTemplate: lang.administration.DELETE_INFO,
					deleteAll: $.proxy(function (e) {
						$('#cw_dependencies_deleteall').append('<div id="authentications_popup"></div>');
						ReactDOM.render(<AuthenticationReplacementWindow
							currentAuthenticationId={authenticationId}
							authenticationType={authenticationType}
							onReplace={(result) => this.onAuthDependenciesReplaceUpdate(result)}
							onClose={this.onAuthDependenciesReplaceClose}
						/>, $('#authentications_popup').get(0));
						$('.cw_dependencies_list').closest('.cw_modal').css('pointer-events', 'none');
					}, this)
				});
			}, this)
		});
		this.dependenciesWindow.open();
	},

	onAuthDependenciesReplaceUpdate(result) {
		this.onAuthDependenciesReplaceClose();
		$('.cw_dependencies_list').closest('.cw_modal').css('pointer-events', 'initial');
		if (result.success) {
			this.checkAuthenticationDependencies();
		} else {
			this.resetCheckingAuthentications();
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}

	},

	onAuthDependenciesReplaceClose() {
		ReactDOM.unmountComponentAtNode($('#authentications_popup').get(0));
		$('.cw_dependencies_list').closest('.cw_modal').css('pointer-events', 'initial');
	},

	resetCheckingAuthentications: function () {
		this.selectedAuthentications = [];
		this.authenticationIndex = 0;
		this.grid.dataSource.read();
		let dependenciesGridContainer = $('.cw_dependencies_list');
		if (dependenciesGridContainer.length) {
			let dependenciesGrid = dependenciesGridContainer.data('kendoCustomGrid');
			dependenciesGrid.dataSource.read();
		}
	},

	/**
	 * Handler function for the click event on the authentication name
	 */
	onAuthNameClick: function (e) {
		var authenticationId = $(e.currentTarget).data('id');

		var mode = 'view';
		if (State.mainApp.session.hasRole('AUTHENTICATION_UPDATE')) {
			mode = 'update';
		}
		var modalWindow = new ModalWindow({
			title: lang.account.AUTH_DETAILS,
			width: 400,
			minHeight: 400,
			maxHeight: 800,
			position: topLeftCornerPosition,
			id: 'authentication_modal',
			url: 'include/Administration/AuthenticationForm.jsp',
			onClose: function () {
				if (mode === 'update') {
					RemoteEventsManager.discard(authenticationId);
				}
			},
			refresh: $.proxy(function () {
				new AuthenticationForm({
					id: authenticationId,
					modalId: 'authentication_modal',
					mode: mode
				});
			}, this)
		});
		modalWindow.open();
	},
	/**
	 * Handler function for the click event on the authentication update menu item
	 */
	onAuthenticationUpdate: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var authenticationId = $('.cw_grid_check:checked').attr('data-id');
			if (State.mainApp.session.hasRole('AUTHENTICATION_UPDATE') || State.mainApp.session.hasRole('AUTHENTICATION_READ')) {
				var modalWindow = new ModalWindow({
					title: lang.account.AUTH_DETAILS,
					width: 400,
					minHeight: 400,
					maxHeight: 800,
					position: topLeftCornerPosition,
					id: 'authentication_modal',
					url: 'include/Administration/AuthenticationForm.jsp',
					onClose: function () {
						RemoteEventsManager.discard(authenticationId);
					},
					refresh: $.proxy(function () {
						new AuthenticationForm({
							id: authenticationId,
							modalId: 'authentication_modal',
							mode: 'update'
						});
					}, this)
				});
				modalWindow.open();
			}
		}
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'showGridView',
			value: this.showGridView
		}];
		this.saveUserPrefs({
			category: 'AdministrationAuthentications',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			preferences: preferences,
			keys: {
				searchPhrase: 'authenticationsSearchPhrase',
				columns: 'authenticationsColumns',
				sort: 'authenticationsSort',
				filter: 'authenticationsFilter',
				FsViews: 'authenticationsFSViews'
			}
		});
	},
	createDataSource: function () {
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/authentications/list?&dc=' + Utils.timestamp(),
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function (result) {
					for (let i = 0; i < result.length; i++) {
						if (result[i].tags) {
							result[i].tags = result[i].tags.join(', ');
						}
					}
					return result;
				}
			},
			sort: this.authenticationsSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.authenticationsFilter || [],
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, showGridView, pref;
		UserPrefs.load('AdministrationAuthentications', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				showGridView = UserPrefs.get('showGridView');
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
						sort: [],
						filter: []
					};
					this.authenticationsSort = pref.sort;
					this.authenticationsFilter = pref.filter;
					this.authenticationsColumns = pref.columns;
				} else {
					if (UserPrefs.get('authenticationsSort')) {
						this.authenticationsSort = JSON.parse(UserPrefs.get('authenticationsSort'));
					} else {
						this.authenticationsSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('authenticationsFilter')) {
						this.authenticationsFilter = JSON.parse(UserPrefs.get('authenticationsFilter'));
					} else {
						this.authenticationsFilter = [];
					}
				}

				if (showGridView) {
					this.showGridView = showGridView === 'true' ? true : false;
				} else {
					this.showGridView = true;
				}
				this.cardGridToggle.select(this.showGridView ? 'cw_grid_view' : 'cw_card_view');

				this.createDataSource();
				var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
					startFrom: 0,
					playing: true
				};
				this.eventsToolbar = new EventsToolbar({
					renderTo: $('#cw_authentications_events_toolbar'),
					callBackFn: $.proxy(this.onEventsTimeout, this),
					subscriberId: this.subscriberId,
					startFrom: eventStatus.startFrom,
					playing: eventStatus.playing
				});
				if (this.showGridView) {
					this.initGridView();
				} else {
					this.initCardView();
					this.initGridView(false);
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the authentication saved event
	 */
	onAuthenticationSaved: function () {
		this.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'Administration',
			//Entity types: ACCOUNT, AGENT, ASSET, ASSET_GROUP, AUTHENTICATION, MONITOR, NOTIFICATION_POLICY, TEAM, TEAM_CALENDAR, USER
			entityType: 'AUTHENTICATION',
			//Optional. If entityId is specified, only events for this entity are received
			//entityId:"1b8a6722-97a5-4e85-adf1-fcd2c958ebe5"
			//Optional. If accountId is specified, events for all entities of type "entityType" in this account are received
			accountId: Cookies.CeesoftCurrentAccountId
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	onEventsTimeout: function (events) {
		this.dataSource.read();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
