import {Api} from "tools";
import {ApiRequest} from "../../framework/api";

export class ManagementApi {
	static getAgentUrl(accountId, id) {
		return Api.accountRoot(accountId) + `agents/${id}/details`;
	}

	static getQualifiersLastValue(data) {
		return Api.fetchPost(`${Api.serverRoot()}metrics/registeredMetrics/lastinfo`, data)
	}

	static getSearchUrl() {
		return Api.accountRoot() + 'agents/lite?name=';
	}
}

export class AgentsRouter {
	static list(){
		return '/agents'
	}
}

export function deleteDataRegistry(ids) {
	const url = `dataRegistry/delete`;
	return new ApiRequest({
		url,
		method: 'POST',
		payload: ids
	})
}
