import {Api} from "tools"
import {ApiRequest} from "../../framework/api";

export class AdministrationApi {
	static getUserUrl(id) {
		return Api.accountRoot() + `users/${id}/?update=true`;
	}

	static getAssetGroupUrl(accountId, id, update) {
		return Api.accountRoot(accountId) + `assetGroups/${id}/?update=${update}`;
	}

	static getAuthenticationUrl(accountId, id, update) {
		return Api.accountRoot(accountId) + `authentications/${id}/?update=${update}`;
	}

	static getTeamUrl(accountId, id, update) {
		return Api.accountRoot(accountId) + `teams/${id}/?update=${update}`;
	}
}

export function getAuthentications(accountId) {
	let url = 'authentications/list'
	return new ApiRequest({
		url,
		accountBased: true,
		accountId
	})
}
