import {Api} from "tools";

export default class AttributesApi {
	static getAttributes(id, type) {
		if (type === 'asset') {
			return Api.fetch(`${Api.accountRoot()}assets/${id}/attributes`)
		} else if (type === 'account') {
			return Api.fetch(`${Api.accountRoot(id)}attributes`)
		}
	}
	static saveAttribute(attribute, id, type, initialData) {
		let url;
		if (type === 'asset') {
			url =  `${Api.serverRoot()}assets/${id}/attributes`;
		} else if (type === 'account') {
			url =  `${Api.accountRoot(id)}attributes`;
		}
		if (initialData) {
			url += `?group=${initialData.group}&key=${initialData.key}`;
		}
		return Api.fetchPost(url, attribute)
	}
	static deleteAttribute (attributes, id, type) {
		if (type === 'asset') {
			return Api.fetchPost(`${Api.serverRoot()}assets/${id}/attributes/delete`, attributes)
		} else if (type === 'account') {
			return Api.fetchPost(`${Api.accountRoot(id)}attributes/delete`, attributes)
		}
	}
	static getGroups(id, type) {
		if (type === 'asset') {
			return Api.fetch(`${Api.accountRoot()}assets/${id}/attributes/groups`);
		} else if (type === 'account') {
			return Api.fetch(`${Api.accountRoot(id)}attributes/groups`);
		}
	}
}
