import React from "react";
import {translator} from "core";
import {Window, Section, Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import {FormEntry, Button} from 'controls/react/form';
import {AuthenticationDropDown} from 'controls/react';
import Api from 'tools/api';
import Cookies from 'core/cookies';

let i = translator({
  "Replace authentication": {
    "no": "Erstatt autentisering",
    "en": "Replace Authentication"
  },
  "Replace & delete": {
    "no": "Erstatt og slett",
    "en": "Replace & Delete"
  }
});

export class AuthenticationReplacementWindow extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			newAuthId: null
		};
	}

	render() {

		return (
			<div>
				<Window height={100}
						width={450}
						title={i('Replace authentication')}
						ref={window => this.window = window}
						onClose={() => this.props.onClose()}>
					<Section>
						<FormEntry label={i('Authentication')}>
							<AuthenticationDropDown
								onChange={(newAuthId) => this.onAuthenticationChange(newAuthId)}
								authToBeRemoved={this.props.currentAuthenticationId}
								newAuthModalWindowId={'new_authentication'}
								authTypes={[this.props.authenticationType]}
							/>
						</FormEntry>
					</Section>
					<Toolbar containerClass="conversion_actions">
						<Button title={i('Replace & delete')}
								primary={true}
								onClick={() => this.onUpdate()}
								position={ToolbarItemPosition.AT_THE_END} />
						<Button title={i('Cancel')}
								primary={false}
								onClick={() => this.props.onClose()}
								position={ToolbarItemPosition.AT_THE_END} />
					</Toolbar>
				</Window>
			</div>
		)
	}

	onAuthenticationChange = (newAuthId) => {
		this.setState({
			newAuthId: newAuthId
		});
	}

	onUpdate = async () => {
		let oldAuthId = this.props.currentAuthenticationId;
		let newAuthId = this.state.newAuthId;
		if (newAuthId) {
			let url = `${Api.serverRoot()}accounts/${Cookies.CeesoftCurrentAccountId}/authentications/${oldAuthId}/dependencies/deleteAll?replaceId=${newAuthId}`;
			let result = await Api.fetchDelete(url);
			this.props.onReplace(result);
		}
	}
}

