import {Cookies, translator} from 'core';
import Settings from 'settings';
import ModalWindow from 'controls/modalWindow';
import {Utils, State, Api} from 'tools';

const i = translator({
  "Locked by message": {
    "en": "Servicemodel is locked by user <strong>{0}</strong> since <strong>{1}</strong>",
    "no": "Tjenestemodellen er låst av bruker <strong>{0}</strong> siden <strong>{1}</strong>"
  },
  "Remove lock?": {
    "en": "Would you like to unlock the servicemodel?",
    "no": "Vil du åpne tjenestemodellen?"
  },
  "Remove lock warning": {
    "en": "Note that all changes will be lost for user <strong>{0}</strong>",
    "no": "Merk at bruker <strong>{0}</strong> vil miste alle endringer"
  },
  "No permissions for that lock": {
    "en": "This servicemodel is locked by another User.",
    "no": "Tjenestemodellen er låst av en annen bruker."
  }
});

export async function isServiceLocked(serviceId){
	const getLockResult = await Api.fetch(Api.server + 'locks/services/' + serviceId);

	if(!getLockResult.success){
		Utils.showInfo(lang.ALERT, getLockResult.message, getLockResult.details);
		return true;
	}

	if (!getLockResult.data) { //no existing lock
		return false;
	}

	const removeLock = await askIfLockIsToBeRemove(getLockResult.data);
	if (!removeLock)
		return true;

	const deleteResult = await Api.fetchDelete(Api.server + 'locks/' + getLockResult.data.objectId);
	return !deleteResult.success;
}

function askIfLockIsToBeRemove (lockData) {
	return new Promise(resolve => {
		const window = new ModalWindow({
			title: lang.service.MODEL_LOCKED,
			width: 480,
			resizable: false,
			minHeight: 200,
			modal: true,
			actions: [],
			url: 'include/Service/LockPopup.jsp',
			refresh: () => {
				$('#cw_locks_info').html(getRemoveLockMessage(lockData));
				$('#cw_lock_close').off().on('click', () => {
					resolve(false);
					window.close();
				});
				if (State.mainApp.session.hasRole('LOCK_DELETE') || lockData.username === Cookies.CeesoftUsername) {
					$('#cw_lock_delete').off().on('click', () => {
						resolve(true);
						window.close();
					});
				} else {
					$('#cw_lock_delete').addClass('hide');
				}
			}
		});
		window.open();
	});
}

function getRemoveLockMessage(lockData) {
	let message = '';
	if (State.mainApp.session.hasRole('LOCK_READ')) {
		message = '<p>' + i('Locked by message', lockData.username, Renderer.localDate(lockData.created, Constants.DATETIME)) + '.</p> ';
		if (State.mainApp.session.hasRole('LOCK_DELETE') || lockData.username === Cookies.CeesoftUsername) {
			message += '<p>' + i('Remove lock?') + '</p>';
			message += '<div class="cw_excerpt left relative"><p>' + i('Remove lock warning', lockData.username) + '</p></div>';
		}
	} else {
		message = i('No permissions for that lock');
	}
	return message;
}
