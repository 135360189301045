import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import PreferencesPanel from 'controls/preferencesPanel';
import UserPrefs from 'tools/userPrefs';
import Application from 'core/application';


export default function Integration(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'Integration',
		initialConfig: config
	};

	// initializing data inside this class
	this.initComponent();
};

jQuery.extend(Integration.prototype, Application.prototype, {
	/**
	 * Application related initializations can be done here
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);


		this.actionNotification = new CustomNotification({
			appendToElement: '#cw_integration_section .k-grid-content',
			type: 'icon',
			hideOnClick: true
		});

		this.removeListeners();
		this.attachListeners();
		// removes loading mask
		this.removeMask();
		this.loadUserPreferences();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_integration_list').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$(window).on('resize', $.proxy(this.adjustSectionHeight, this));
		$('#cw_integration_list').on('click', '.cw_grid_link', $.proxy(this.onProfileNameClick, this));
		$('#cw_integration_list').on('click', '.cw_grid_check', $.proxy(this.onProfileCheck, this));
		$('#cw_integration_list').on('click', '.cw_grid_check_all', $.proxy(this.onProfileCheckAll, this));
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('Integration', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
			} else {
				Utils.showInfo(Lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Initializes kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = Lang.grid.filter, url;
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: Lang.CLEAR,
			info: Lang.grid.filter.SHOW_ITEMS,
			filter: Lang.FILTER
		};

		this.defaultColumns = {
			icon: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				//width: 450
			},
			url: {
				hidden: false,
				//width: 350
			},
			launchInNewWindow: {
				hidden: false,
				width: 250
			}

		};
		var integrationSort, integrationFilter;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			integrationSort = pref.sort;
			integrationFilter = pref.filter;
			this.integrationColumns = pref.columns;
		} else {
			if (UserPrefs.get('integrationSort')) {
				integrationSort = JSON.parse(UserPrefs.get('integrationSort'));
			} else {
				integrationSort = [{
					field: 'name',
					dir: 'asc',
					compare: null
				}];
			}
			if (UserPrefs.get('integrationFilter')) {
				integrationFilter = JSON.parse(UserPrefs.get('integrationFilter'));
			} else {
				integrationFilter = [];
			}
		}
		var integrationColumns = this.integrationColumns || JSON.parse(UserPrefs.get('integrationColumns')) || this.defaultColumns;
		integrationColumns = Utils.completeColumns(integrationColumns, this.defaultColumns);
		var dontLoadDefault = false;
		url = Settings.serverPath + 'management/extensions';

		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: $.proxy(function (results) {
					if (results.success) {
						for (var i = 0; i < results.data.length; i++) {
							if (!results.data[i].icon) {
								results.data[i].icon = 'disk-export';
							}
						}
						return results.data;
					}
				}, this)
			},
			sort: integrationSort,
			filter: integrationFilter
		});
		this.grid = $('#cw_integration_list').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			selectable: 'single',
			reorderable: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			columns: Utils.rearrangeColumns([{
				field: 'icon',
				title: Lang.ICON,
				template: '<span class="glyphicons #=icon#" style="font-size:16px;padding:2px 0 2px 5px"></span>',
				sortable: false,
				filterable: false,
				hidden: integrationColumns.icon.hidden,
				width: integrationColumns.icon.width
			}, {
				field: 'name',
				title: Lang.NAME,
				template: '<span class="cw_grid_link ellipsis to_expand">#= name #</span>',
				sortable: true,
				filterable: true,
				hidden: integrationColumns.name.hidden,
				width: integrationColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'url',
				title: Lang.URL,
				sortable: true,
				filterable: true,
				hidden: integrationColumns.url.hidden,
				width: integrationColumns.url.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'launchInNewWindow',
				title: Lang.LAUNCH,
				sortable: true,
				filterable: true,
				hidden: integrationColumns.launchInNewWindow.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], integrationColumns),
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onDataBound, this),
			columnMenu: true
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		var iconColumn = $('th:contains(' + Lang.ICON + ')');
		iconColumn.css('padding-bottom', '6px');
		iconColumn.find('a').remove();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('integrationSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_integration_filters',
			grid: this.grid,
			modulePrefName: 'Integration',
			defaultPrefViewKey: 'defaultFsView',
			dontLoadDefault: dontLoadDefault,
			prefListKey: 'integrationFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'url'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_integration_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},

	/**
	 * Handler function for the grid data bound event
	 * @param {Object} e The databound event
	 */
	onDataBound: function () {
		$('#cw_integration_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		this.showNotifications();
	},
	showNotifications: function () {
		if (this.savedProfileNotification) {
			this.actionNotification.setOptions({
				message: this.savedProfileNotification,
				status: 'success'
			}).show();
			this.savedProfileNotification = null;
		}
	},
	/**
	 * Handler for checking agent dependencies
	 */
	checkDependencies: function () {
		if (this.selectedProfiles.length === 1) {
			var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/integrations/' + this.selectedProfiles[0];
			Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
				if (result.success) {
					this.grid.dataSource.read();
					this.actionNotification.setOptions({
						message: Lang.integration.PROFILE_SUCCESS_DELETED,
						status: 'success'
					}).show();
					//this.gridMenu.enableItem('cw_create');
					//this.gridMenu.enableItem('cw_delete');
					$('.k-loading-mask').remove();
				} else {
					Utils.showInfo(Lang.ALERT, result.message, result.details);
					//this.gridMenu.enableItem('cw_create');
					//this.gridMenu.enableItem('cw_delete');
				}
			}, this));
		} else {
			var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/integrations/delete';
			Utils.ajax(deleteUrl, 'POST', JSON.stringify(this.selectedProfiles), $.proxy(function (result) {
				if (result.success) {
					this.grid.dataSource.read();
					this.actionNotification.setOptions({
						message: Lang.integration.PROFILES_SUCCESS_DELETED,
						status: 'success'
					}).show();
					//this.gridMenu.enableItem('cw_create');
					//this.gridMenu.enableItem('cw_delete');
					$('.k-loading-mask').remove();
				} else {
					Utils.showInfo(Lang.ALERT, result.message, result.details);
					//this.gridMenu.enableItem('cw_create');
					//this.gridMenu.enableItem('cw_delete');
				}
			}, this));
		}
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'Integration',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			keys: {
				searchPhrase: 'integrationSearchPhrase',
				columns: 'integrationColumns',
				sort: 'integrationSort',
				filter: 'integrationFilter',
				FsViews: 'integrationFSViews'
			}
		});
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function () {
		this.saveUserPreferences();
	},
	/**
	 * Handler for the edit monitor button
	 */
	onProfileNameClick: function (e) {
		var data = this.grid.dataItem($(e.currentTarget).closest('tr'));
		var url = data.url + '/' + '?cvSessionId=' + Cookies.sessionId + '&cvAccountId=' + Cookies.CeesoftCurrentAccountId;
		if (data.launchInNewWindow) {
			window.open(url);
		} else {
			var el = document.createElement('embed');
			el.setAttribute('id', 'integration_container');
			el.setAttribute('src', url);
			//el.setAttribute('type', 'application/pdf');
			el.setAttribute('width', '100%');
			el.setAttribute('height', '100%');
			$('#integration_container').ready(function () {
				$('#content_area').find('.cw_content_section').remove();
			});
			document.getElementById('content_area').appendChild(el);
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onProfileCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		this.onProfileCheck();
	},
	/**
	 * Handler for the asset checkbox click
	 */
	onProfileCheck: function () {
		var checkboxes = $('.cw_grid_check');
		var checked = false;
		var count = 0;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				checked = true;
				count++;
			}
		}
		if (checked) {
			//this.gridMenu.enableItem('cw_delete');
		} else {
			//this.gridMenu.disableItem('cw_delete');
		}
	},
});
