import React from 'react';
import ReactDOM from 'react-dom';

import {DatasourceProperties} from './datasourceProperties';
import {getUserData} from 'tools/utils';
import {readJsonValue} from "controls/designer/dataSourcesManager/dataSourceElement";
import {Cookies} from "core/cookies";
import {TOTAL_METRIC_TYPE} from './datasources/totalMetricDataSource';
import {DISPLAY_UNIT} from './datasources/displayUnitDataSource';
import {DISPLAY_LABEL} from './datasources/displayLabelDataSource';

class DSPanel {
	constructor(wrapper, ui, cell) {
		this.wrapper = wrapper;
		this.ui = ui;
		this.cell = cell;
	}

	getWrapper() {
		return this.wrapper;
	}

	readValue(attribute, json = true) {
		return this.cell.readValue(attribute, json);
	}

	render() {
		ReactDOM.render(<DatasourceProperties
			ui={this.ui}
			datasource={this.cell.getDatasource()}
			onChange={this.updateDatasource}
			redirectConfig={this.cell.getRedirectConfig()}
			onRedirectConfigChange={this.updateRedirectConfig}
		/>, this.getWrapper());

		return this;
	}

	updateDatasource = datasource => {
		const {graph} = this.ui.editor;

		const object = this.cell.getValueAsXml()

		object.setAttribute('datasource', JSON.stringify(datasource));

		graph.getModel().setValue(graph.getSelectionCell(), object);
	}

	updateRedirectConfig = redirectConfig => {
		const {graph} = this.ui.editor;

		const object = this.cell.getValueAsXml()

		object.setAttribute('redirectConfig', JSON.stringify(redirectConfig));

		graph.getModel().setValue(graph.getSelectionCell(), object);
	}

	destroy(){

	}
}

class DatasourcePropertiesPanelManager {
	cellMap = new window.Map();

	getPanel(format, editorUi, container) {
		const ui = editorUi;
		const editor = ui.editor;
		const graph = editor.graph;
		const cell = graph.getSelectionCell();

		if (!this.cellMap.has(cell)) {
			const wrapper = document.createElement('div');
			this.cellMap.set(cell, new DSPanel(wrapper, ui, cell));
		}

		const panel = this.cellMap.get(cell);

		container.appendChild(panel.getWrapper());

		return panel;
	}
}

export default new DatasourcePropertiesPanelManager();



