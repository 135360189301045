import {observer} from "mobx-react";
import React from "react";
import {Asset} from "framework/entities/asset";
import {apiFetch} from "framework/api";
import {getAssetsWithGeotag} from "areas/assets/api";
import {AntSelect, AntSelectProps, AntSelectValue} from "controls/react/ant/antSelect";


export type AssetsWithGeoTagProps = AntSelectProps<AntSelectValue>& {
	accountId: string
}

export const AssetsWithGeoTag = observer((props: AssetsWithGeoTagProps) => {
	const {accountId, ...antNativeProps} = props
	const [assets, setAssets] = React.useState<Asset[]>([]);

	React.useEffect(() => {
		if (accountId == null)
			return;

		const abortController = new AbortController()

		apiFetch(getAssetsWithGeotag(accountId), {
			signal: abortController.signal
		})
			.then(response => {
				if (response.success) {
					setAssets(response.data);
				}
			})
			.catch(() => {});

		return () => abortController.abort()
	}, [accountId]);

	return <AntSelect
		dataList={assets}
		{...antNativeProps}
	/>
})
