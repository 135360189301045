import "./redirectToConfiguration.less";

import React, { useEffect, useState } from "react";

import { FormEntry } from "controls/react/form";
import { AntInput } from "controls/react/ant/antInput";
import { CollapsibleFrame } from "controls/react/layout/collapsibleFrame";
import { translator } from "core";
import { PresentationApi } from 'areas/dashboards/api'
import {AntSelect} from 'controls/react/ant/antSelect';
import { AntAccountDropDown } from 'controls/react/ant/specific/antAccountDropDown';
import {RedirectType} from "controls/designer/features/redirectOnClick/redirectType";
import {styleForThingsOnPopoverOnModal} from "controls/react/ant/zIndexes";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {RedirectModifier} from "tools/ceeviewNavigator";
import {ModalPosition} from "controls/react/ant/antModal";
import {linkState, useStateLink} from "core/react/links";

const i = translator({
  "Redirect": {
    "no": "Videresend"
  },
  "Custom Link": {
    "no": "Tilpasset lenke",
    "en": "Custom link"
  },
  "Open in new tab": {
    "no": "Åpne i ny tab"
  },
  "Dashboard": {
    "no": "Dashboard"
  },
	'Option': {},
	'New tab': {},
	'Popup': {},
	'Upper left corner': {},
	'Upper right corner': {},
	'Center': {},
	'Mouse click': {},
	'Popup position': {},
	'Popup width': {},
	'Popup height': {}
});

const b = require('b_').with('redirect-to-config-section');

export const RedirectToConfiguration = props => {
	const {configLink} = props;

	const tempHeightLink = useStateLink(configLink.value?.popup?.height ?? 600)
	const tempWidthLink = useStateLink(configLink.value?.popup?.width ?? 800)

	const typeLink = configLink.get('type');
	const accountLink = configLink.get('accountId');
	const linkLink = configLink.get('link');
	const dashboardLink = configLink.get('dashboardId');
	const modifierLink = configLink.get('modifier')
		.changing(({rootStore, value}) => {
			const config = configLink.getLinkStore(rootStore)
			if(value == RedirectModifier.Popup && configLink.popup == null) {
				config.popup = {
					height: 600,
					width: 800,
					position: 'topLeft'
				}
			}
		});

	const labelLink = configLink.get('label');
	const [dashboardsList, setDashboardsList] = useState([]);
	const [customLinkText, setCustomLinkText] = React.useState(linkLink.value)

	useEffect(() => {
		loadDashboardList();
		dashboardLink.update(null);
	}, [accountLink])

	useEffect(() => {
		updateInfoLabel();
	}, [linkLink])

	async function loadDashboardList() {
		let dashboards = [];

		if (typeLink.value == RedirectType.Dashboard && accountLink.value) {
			const response = await PresentationApi.list(accountLink.value)
			if(response.success !== false) {
				dashboards = response
			}
		}

		setDashboardsList(dashboards)
	}

	function updateInfoLabel() {
		let label = ''
		if (typeLink.value == RedirectType.Dashboard && dashboardsList.length > 0)
			label = dashboardsList.find(x => x.value == linkLink.value)?.text;

		if (typeLink.value == RedirectType.Custom)
			label = linkLink.value ?? ''

		labelLink.update(`${label + ' '}(${typeLink.value ?? 'Default'})`)
	}

	return (
		<div className={b()}>
			<CollapsibleFrame contentPadding={false} childr enPadding={true} title={"Redirect"}>
				<FormEntry label={i('Redirect')}>
					<AntSelect options={getRedirectTypeDatasource()}
					           dropdownStyle={styleForThingsOnPopoverOnModal}
					           {...typeLink.props}/>
				</FormEntry>
				{typeLink.value == RedirectType.Dashboard &&
				<>
					<FormEntry label={i('Account')} width={"fit"}>
						<AntAccountDropDown valueLink={accountLink}/>
					</FormEntry>
					<FormEntry label={i('Dashboard')}>
						<AntSelect dataList={dashboardsList}
						           nameField={"tag"}
						           {...dashboardLink.props}/>
					</FormEntry>
				</>
				}
				{typeLink.value == RedirectType.Custom &&
					<FormEntry label={i('Custom Link')}>
						<AntInput value={customLinkText}
						          onChange={v => setCustomLinkText(v)}
						          onBlur={() => linkLink.onChange(customLinkText)}/>
					</FormEntry>
				}
				{typeLink.value != RedirectType.None &&
					<FormEntry label={i('Option')} width={"fit"}>
						<AntSelect options={getRedirectConfigModifierOptions()}
						           {...modifierLink.props}/>
					</FormEntry>
				}
				{modifierLink.value == RedirectModifier.Popup && typeLink.value != RedirectType.None && <>
					<FormEntry label={i('Popup height')} width={"fit"}>
						<AntInputNumber onBlur={() => configLink.get('popup.height').onChange(tempHeightLink.value)}
						                {...tempHeightLink.props}/>
					</FormEntry>
					<FormEntry label={i('Popup width')} width={"fit"}>
						<AntInputNumber onBlur={() => configLink.get('popup.width').onChange(tempWidthLink.value)}
						                {...tempWidthLink.props}/>
					</FormEntry>
					<FormEntry label={i('Popup position')} width={"fit"}>
						<AntSelect options={getRedirectPopupPositionOptions()}
						           {...configLink.get('popup.position').props}/>
					</FormEntry>
				</>}
			</CollapsibleFrame>
		</div>
	)
}

let redirectConfigModifierOptions = null

function getRedirectConfigModifierOptions(){
	if(redirectConfigModifierOptions == null){
		redirectConfigModifierOptions = [{
			label: i('None'),
			value: RedirectModifier.None
		},{
			label: i('New tab'),
			value: RedirectModifier.NewTab
		},{
			label: i('Popup'),
			value: RedirectModifier.Popup
		}]
	}

	return redirectConfigModifierOptions
}

let redirectPopupPositionOptions = null

function getRedirectPopupPositionOptions(){
	if(redirectPopupPositionOptions == null){
		redirectPopupPositionOptions = [{
			label: i('Upper left corner'),
			value: ModalPosition.TopLeft
		},{
			label: i('Upper right corner'),
			value: ModalPosition.TopRight
		},{
			label: i('Center'),
			value: ModalPosition.Centered
		},{
			label: i('Mouse click'),
			value: ModalPosition.Mouse
		}]
	}

	return redirectPopupPositionOptions
}

let redirectTypeDatasource = null

function getRedirectTypeDatasource() {
	if (!redirectTypeDatasource) {
		redirectTypeDatasource = [{
			label: i('Default'),
			value: RedirectType.Default,
		}, {
			label: i('Dashboard'),
			value: RedirectType.Dashboard,
		}, {
			label: i('Custom'),
			value: RedirectType.Custom
		}, {
			text: i('None'),
			value: RedirectType.None
		}]
	}
	return redirectTypeDatasource;
}
