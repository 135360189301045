import React, {ChangeEventHandler, KeyboardEventHandler, useCallback} from "react";
import {Select} from 'antd';
import { Link } from "core/react/links";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { debounce } from "lodash";


export function renderSelectOptions<DataItem extends Record<string, string|number>, TKey extends keyof DataItem>(
	list: DataItem[], {nameField, valueField} : {nameField: TKey, valueField: TKey},
                                     renderer?:  (item: any) => React.ReactNode ) {
	const {Option} = Select
	return list.map(x => <Option key={x[valueField]} value={x[valueField]} disabled={x.disabled}>
		{!renderer && x[nameField]}
		{renderer && renderer(x)}
	</Option>);
}

export type AntSizeType = SizeType

type ValueLinkHolder<T> = {
	valueLink?: Link<T>
}

export function captureValueLink<VT, T extends ValueLinkHolder<VT>>(props: T): Omit<T, "valueLink"> & {onChange: ChangeEventHandlerWrapped<any>} {
	let {valueLink, ...others} = props;

	if (valueLink) {
		Object.assign(others, valueLink.props);
	}

	return others;
}


export type ChangeEventHandlerWrapped<E extends HTMLInputElement|HTMLTextAreaElement> = (value: string, e: React.ChangeEvent<E>) => void;

type OnChangeHolder<E extends HTMLInputElement|HTMLTextAreaElement> = {
	onChange: ChangeEventHandlerWrapped<E>
}


export function wrapOnChange<E extends HTMLInputElement|HTMLTextAreaElement, T extends OnChangeHolder<E>>(props: T){
	return {
		...props,
		onChange: (e: React.ChangeEvent<E>) => {
			props.onChange?.(e.target.value as VT, e);
		}
	}
}

type OnKeyUpHolder<E extends Element> = {
	onKeyUp?: KeyboardEventHandler<E>,
	onPressEnter?: () => void,
	onPressEsc?: () => void,
}

export function addEnterEscHandlers<E extends Element, P extends OnKeyUpHolder<E>>(props: P){
	let {onPressEnter, onPressEsc, ...restProps} = props

	if(!onPressEnter && !onPressEsc)
		return restProps

	const initialOnKeyUp = props.onKeyUp

	const onKeyUpWrapper = (e: React.KeyboardEvent<E>) => {
		if(onPressEnter && e.key == 'Enter'){
			onPressEnter()
		}

		if(onPressEsc && e.key == 'Escape'){
			onPressEsc()
		}

		initialOnKeyUp && initialOnKeyUp(e)
	};

	restProps.onKeyUp = onKeyUpWrapper;
	return restProps
}


export function useBounced(bounced: number = null, value: string|number, onChange: (v: string|number) => void){
	const [valueProxy, setValueProxy] = React.useState(value)

	const onChangeBounced = React.useRef(debounce((v: string|number) => {
		onChange(v)
	}, bounced ?? 1000))

	const onChangeBouncedWrapper = React.useCallback((v: string|number) => {
		setValueProxy(v)
		onChangeBounced.current(v)
	}, [])

	React.useEffect(() => {
		setValueProxy(value)
	}, [value])

	return [bounced ? valueProxy : value, bounced ? onChangeBouncedWrapper: onChange] as const
}

export function useBouncedWithEvent(bounced: number = null, value: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void){
	const [valueProxy, setValueProxy] = React.useState(value)

	const onChangeBounced = React.useRef(debounce((e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e)
	}, bounced ?? 1000))

	const onChangeBouncedWrapper = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		e.persist()
		setValueProxy(e.target.value)
		onChangeBounced.current(e)
	}, [])

	React.useEffect(() => {
		setValueProxy(value)
	}, [value])

	return [bounced ? valueProxy : value, bounced ? onChangeBouncedWrapper: onChange] as const
}
