import './assetDetails.less'

import React from 'react';
import ReactDOM from 'react-dom';

import {State, CeeViewDataSource, Renderer, UserPrefs, Utils} from "tools";
import {Application, Cookies, ErrorHandler, RemoteEventsManager, StaticVars as Constants} from "core";
import {Settings} from "settings";
import moment from "moment";
import Configuration from "./../../configuration";
import {GenericMultigraphWidget} from "areas/service-boards/widgets";
import {
	CustomNotification,
	ExpandableTextarea,
	GridMenu,
	MultiSelectGridFilter,
	PreferencesPanel,
	TimeRange
} from 'controls';
import {MaintenanceView} from "areas/management/agentViews";
import {AssetMonitorsView} from "areas/assets/assetMonitorsView";
import {ReasonsView} from "areas/views";
import Highcharts from 'highcharts';
import Api from "tools/api";
import {setMonitorErrorTooltipText} from "./monitors/monitorUi";
import {unassignLegacy, setInformationLegacy} from "areas/summary/events/api";
import {ServicesRouter} from 'areas/services/bundleDescription';
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import aggregationTypeDataSource from "areas/service-boards/widgets/common/dataSources/aggregationTypeDataSource";
import {AssetsApi} from "./api";
import AssignReasonsWindow from "controls/assignReasonsWindow";
import AttributesTab from './attributesTab'
import {openAssetConsoleWindow} from "./helpers";
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";
import {getSeverityState} from 'controls/stateRenderer/severityState';
import {getAgentStateAgents} from 'controls/stateRenderer/agentState';
import {AntSelect} from "controls/react/ant/antSelect";
import {BoxView} from "controls/react/layout/boxView";
import {renderHorizontalKendoSplitter} from '../../tools/helpers/splitterHelper'
import WidgetInfoContainer from "controls/react/widgetInfoContainer";
import {getHealthInfo} from "controls/designer/api";
import QualifiersHelper from 'tools/entityHelpers/qualifiers';
import {MetricTableDataWindow} from 'controls/metricTableDataWindow';
import {SetInformationWindow} from 'controls/setInformationWindow';
import {AgentsRouter} from 'areas/management/agents/bundleDescription';
import {translator} from "../../core";
import {apiFetch} from "framework/api";
import {MetricEvent} from 'framework/entities/events';
import {debounce, groupBy, sortBy, throttle} from 'lodash';
import { ImageUploader} from "controls";
import EventsToolbar from 'controls/eventsToolbar';
import {CoordinatesEditor} from './coordinatesEditor';
import {NavigationStore} from "../../framework/navigationStore";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AssetTargetsEditor} from "controls/assetTargetsEditor";
import {createContainer} from "../../controls/react/ant/antModal";
import {AntConfirmModal} from "../../controls/react/ant/antConfirmModal";
import {deleteDataRegistry} from "../management/api";

const t = translator({
  "Geo Tag": {
    "no": "Geo tag"
  },
  "Latitude": {
    "no": "Latitude"
  },
  "Longitude": {
    "no": "Longitude"
  },
  "Aggregated metric": {
    "no": "Aggregert metrikk",
    "en": "Aggregated Metric"
  },
  "Click to change Asset icon": {
    "no": "Klikk for \u00E5 endre ressurs ikon",
  },
  "Are you sure you want to delete {0} metrics?": {
	  no: "Vil du slette {0} metrikker?"
  },
  "Are you sure you want to delete {0} metric?": {
    no: "Vil du slette {0} metrikker?"
  }
});

export default function AssetDetails(config) {

};

jQuery.extend(AssetDetails.prototype, Application.prototype, {
	metricSubscribtion: null,
	metricsEvents: [],
	/**
	 * Main init function
	 */

	async init(){
		this.assetTargetsList = []
		await this.loadMultipleUserPreferences();
		await this.load();

		this.subscriberId = Utils.guid();

		this.accountNameContext = this.accountName || Cookies.CeesoftCurrentAccountName;
		this.accountIdContext = this.accountId || Cookies.CeesoftCurrentAccountId;
		this.hasEvents = true;
		this.mode = this.mode || 'update';

		this.initComponent();
		//this.renderAssetTargets()

		this.initialized({
			title: this.getTitle()
		});

		await renderHorizontalKendoSplitter('.cw_content_wrapper','.cw_assetdetails_settings','Configuration', 'assetDetails')
		this.modalNotification = new CustomNotification({
			appendToElement: '#cw_asset_details_form',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
	},

	getTitle: function() {
		return this.name;
	},

	initComponent: function () {
		this.globalWidgetConfig = {};
		this.windowsPos = [];
		this.widgets = [];
		this.widgetsByWindowID = [];
		this.widgetsHoverInfo = [];
		this.clonedWindows = [];
		this.openedTableGrids = [];
		this.openedTableWindows = [];
		this.openedTableWindowIds = [];

		this.reasonsChartVisibleSeries = [];

		this.windowDim = {
			width: 600,
			height: 250
		};

		this.update = true;
		if (!this.app.session.hasRole('MONITOR_UPDATE')) {
			this.mode = 'view';
			this.update = false;
		}
		Application.prototype.initComponent.call(this);

		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('.cw_description'),
			readonly: false
		});

		this.informationHandler = new ExpandableTextarea({
			renderTo: $('.cw_information'),
			readonly: false,
			label: lang.INFORMATION
		});

		this.clickedMonitors = [];
		this.tagsDataSource = [];
		this.isFirstLoad = true;
		this.saved = false;
		this.formEl = $('#cw_asset_details_form');
		this.loadCurrentReasons = false;

		this.reasonsMenu = new GridMenu({
			renderTo: 'cw_reasons_menu',
			items: [{
				id: 'cw_reason_create_incident',
				icon: 'construction-cone',
				text: lang.incidents.CREATE_INCIDENT,
				role: 'INCIDENT_UPDATE',
				fn: this.onIncidentCreate,
				scope: this,
				disabled: true
			}, {
				id: 'cw_reason_acknowledge',
				icon: 'check',
				text: lang.incidents.MARK_AS_SEEN,
				fn: this.onAckUnack,
				scope: this,
				disabled: true
			}, {
				id: 'cw_reason_unacknowledge',
				icon: 'unchecked',
				text: lang.UNACKNOWLEDGE,
				fn: this.onAckUnack,
				scope: this,
				disabled: true
			}, {
				id: 'cw_assign',
				icon: 'object-align-horizontal',
				text: lang.ASSIGN,
				fn: $.proxy(function () {
					this.onAssign('cw_all_reasons_grid');
				}, this),
				role: 'EVENT_SUMMARY_LIST',
				scope: this,
				disabled: true
			}, {
				id: 'cw_unassign',
				icon: 'undo',
				text: lang.UNASSIGN,
				fn: $.proxy(function () {
					this.onUnassign('cw_all_reasons_grid');
				}, this),
				role: 'EVENT_SUMMARY_LIST',
				scope: this,
				disabled: true
			}, {
				id: 'cw_delete_reason',
				icon: 'remove-circle',
				text: lang.DELETE,
				fn: () => {
					this.onRemove('cw_all_reasons_grid');
				},
				scope: this,
				disabled: true,
				deleteFromGrid: 'cw_all_reasons_grid'
			}, {
				id: 'cw_create_filter',
				icon: 'filter',
				text: lang.assethealth.CREATE_FILTER,
				fn: $.proxy(function () {
					this.onCreateFilter('cw_all_reasons_grid');
				}, this),
				scope: this,
				cssClass: '',
				disabled: true,
				role: 'HEALTHFILTER_CREATE'
			}, {
				id: 'cw_reasons_reset_hi',
				icon: 'restart',
				text: lang.assethealth.RESET_HI,
				fn: $.proxy(function () {
					this.onResetHealthIndexButton('cw_all_reasons_grid');
				}, this),
				scope: this,
				cssClass: '',
				disabled: true,
				role: 'MONITOR_UPDATE'
			}, {
				id: 'cw_set_information',
				icon: 'info-sign',
				text: lang.SET_INFORMATION,
				fn: () => {
					this.onSetInformation('cw_all_reasons_grid');
				},
				scope: this,
				disabled: true
			}]
		});

		this.hasCustomTime = false;
		this.chartType = 'ALL';
		this.chartEntries = '10';
		this.app.contextMenu.setActionButton('ASSET');
		// this.setWidgetsHeight();
		this.removeListeners();
		this.attachListeners();

		if (this.mode === 'view') {
			$('#asset_name').addClass('is_readonly');
			if (!State.mainApp.session.hasRole('ASSET_MAINTENANCE_CREATE') && !State.mainApp.session.hasRole('ASSET_MAINTENANCE_UPDATE') && !State.mainApp.session.hasRole('ASSET_MAINTENANCE_DELETE')) {
				$('#cw_assetdetails_update').addClass('hide');
			}
			this.descriptionHandler.readonly(true);
			$('.is_readonly').attr('readonly', true);
		}
		Utils.equalHeight('.cw_assethealth_details .cw_section_content', '.cw_assetdetails_data');

		$('#cw_cvid').val(this.id);
		this.tagsList = this.tags;
		this.renderTags();
		this.agentState = this.agentState;
		this.agentStatus = this.agentStatus;
		this.monitorError = this.indicators.monitorError;
		$('#asset_name').val(this.name || '');
		this.descriptionHandler.value(this.description || '');
		this.informationHandler.value(this.information || '');
		$('#asset_type').text(this.assetType || 'NA');
		if (this.assetType === 'AGENT') {
			$('#asset_type').addClass('pointer').off().on('click', $.proxy(this.onAssetTypeClick, this));
			//var agentStateIndicator = Renderer.agentState(this.agentState, this.agentStatus);
			var agentStateIndicator = getAgentStateAgents(this.agentState, this.agentStatus);
			$('#asset_agentname').html('<span id="cw_agent_name" class="label ellipsis pointer" title="' + this.agentName + '">' + this.agentName + '</span>').data('agentid', this.agentId).off().on('click', $.proxy(this.onAgentNameClick, this));
			$('#asset_agentname').prepend(agentStateIndicator);
		} else if (this.assetType !== 'STATIC' && this.assetType !== 'EXTERNAL') {
			$('#asset_agentname').html('<span class="label"> NA </span>');
		}
		if (this.externalId.length > 0 || this.externalType.length > 0) {
			for (var j = 0; j < this.externalId.length; j++) {
				var externalId = this.externalId[j] || 'NA';
				var externalType = this.externalType[j] || 'NA';
				var rowTemplate = '<div class="cw_list_row"><div class="cw_list_item">' + externalType + '</div><div class="cw_list_item label ellipsis pointer" title="' + externalId + '">' + externalId + '</div></div>';
				$('.cw_list').append(rowTemplate);
			}
		} else {
			$('.cw_list').append('<div class="cw_list_row"><div class="cw_list_item"></div><div class="cw_list_item"></div></div>');
		}
		$('#cw_asset_account_name').val(this.accountNameContext);
		$('#asset_last_update').text(Renderer.browserDateRenderer(this.modified, Constants.DATETIME));

		let template = `<li class="cw_list_row"><div id="geotag_header">${t('Geo Tag')}</div></li>`;
		$('.cw_list').append(template);
		if(this.geotag) {
			 template = `<div class="cw_list_row" id="geotag_values"><div class="cw_list_item"><span> ${t('Latitude')}: ${this.geotag.latitude}</span></div><div class="cw_list_item"><span>${t('Longitude')}: ${this.geotag.longitude}</span></div></div>`;
			$('.cw_list').append(template);
		} else {
			$('.cw_list').append('<div class="cw_list_row" id="geotag_values"><div class="cw_list_item"></div><div class="cw_list_item"></div></div>');
		}
		const coordinateEditorWrapper = $('<span id="cw_coordinate_editor_wrapper"></span>')
		$('.cw_assetdetails #geotag_header').append(coordinateEditorWrapper);
		this.appendCoordinateEditor();

		this.reasonsList = this.indicators.reasons;

		var currentMonitors = this.indicators.monitors;
		for (var i = 0; i < currentMonitors.length; i++) {
			if (currentMonitors[i].monitorType === 'SYSTEM') {
				this.sysMonId = currentMonitors[i].id;
				this.systemMonitorId = currentMonitors[i].id;
			}
			if (currentMonitors[i].monitorType === 'IBMSVC') {
				this.ibmMonId = currentMonitors[i].id;
			}
			if (currentMonitors[i].monitorType === 'NIMSOFT') {
				this.nimMonId = currentMonitors[i].id;
			}
		}

		$('#cw_splitter').kendoSplitter({
			orientation: "vertical",
			panes: [{size: "60%"}, {size: "40%", min: '300px'}]
		});

		this.qualifiersList = this.indicators.qualifiers;
		this.populateIndicators(this.indicators);
		this.updateView();
		this.initKendoComponents();
		this.assetGroupControl.value(this.assetGroups);
		this.adjustBottomSectionHeight();

		this.onAssetDetailsExpand();
		//asset title
		$('#cw_asset_details_title').text(this.accountNameContext + ' / ' + this.name);
		if (this.assetMaintenance) {
			this.addMaintenanceStatus();
		}
	},

	appendCoordinateEditor() {
		ReactDOM.render(<CoordinatesEditor coordinates={this.geotag} onChanged={coordinates => this.onCoordinatesChanged(coordinates)} />, $('#cw_coordinate_editor_wrapper').get(0));
	},

	onCoordinatesChanged(coordinates) {
		this.geotag = coordinates;
		this.manualGeotag = coordinates;
		const geoRow = $('.cw_assetdetails #geotag_values');
		geoRow.html(`<div class="cw_list_item"><span> ${t('Latitude')}: ${this.manualGeotag.latitude ?? ''}</span></div><div class="cw_list_item"><span>${t('Longitude')}: ${this.manualGeotag.longitude ?? ''}</span></div>`);
		this.removeCoordinateEditor();
		this.appendCoordinateEditor();
	},

	removeCoordinateEditor() {
		ReactDOM.unmountComponentAtNode($('#cw_coordinate_editor_wrapper').get(0));
	},

	renderTags() {
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical width={"fit"}>
			<TagsSelect
				mode={'tags'}
				accountId={this.accountIdContext}
				includeSubaccounts={false}
				disabled={this.mode === 'view'}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>, $('.cw_account_tags').parent().get()[0]);
	},

	onTagsChange(value) {
		this.tagsList = value || [];
	},

	renderAssetTargets() {
		this.assetTargetContainer ??= $('#asset_target_container')[0]

		ReactDOM.render(<FormEntryNew label={lang.ASSET_TARGET} vertical={true}>
			<AssetTargetsEditor onChange={(v) => this.assetTargetsList = v}
			                    targets={this.assetTargetsList}
			/>
		</FormEntryNew>, this.assetTargetContainer);
	},
	addMaintenanceStatus() {
		let message = lang.account.messages.ASSET_MAINTENANCE;
		if (message !== '') {
			$('.cw_additional_warning_text').text(message);
			$('.cw_additional_warning').css('display', 'inline-block');
		} else {
			$('.cw_additional_warning').css('display', 'none');
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_assetdetails_cancel').off();
		$('#cw_assetdetails_update').off();
		$('#cw_all_reasons_grid').off('click');
		$('#cw_assetdetails_qualifiers_list').off();
		$('#cw_assetdetails_reasons_list').off();
		$('.cw_asset_console').off();
		$('#cw_metrics_list').off('click', '.cw_grid_check');
		$('#cw_metrics_list').off('click', '.cw_grid_check_all');
		$('body').off('click', '.cw_monitorname');
		$('body').off('click', '.cw_agentname');
		$('#cw_asset_icon').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		var allReasonsGrid = $('#cw_all_reasons_grid');
		var assetReasonsList = $('#cw_assetdetails_reasons_list');
		var assetQualifiersList = $('#cw_assetdetails_qualifiers_list');
		var metricsList = $('#cw_metrics_list');
		$('#cw_assetdetails_cancel').on('click', $.proxy(this.onCancel, this));
		$('#cw_assetdetails_update').on('click', $.proxy(this.onUpdate, this));
		assetReasonsList.on('click', '.cw_incident_check', $.proxy(this.onReasonsIncidentCheck, this));
		assetReasonsList.on('click', '.cw_incident_check_all', $.proxy(this.onReasonsIncidentCheckAll, this));
		allReasonsGrid.on('click', '.cw_incident_check', $.proxy(this.onIncidentCheck, this));
		allReasonsGrid.on('click', '.cw_incident_check_all', $.proxy(this.onIncidentCheckAll, this));
		allReasonsGrid.on('click', '.cw_incident_number', $.proxy(this.onIncidentNumberClick, this));
		allReasonsGrid.on('click', '.cw_monitor_link', $.proxy(this.onMonitorClick, this));
		allReasonsGrid.on('click', '.cw_agent_name', $.proxy(this.onAgentNameClick, this));
		assetReasonsList.on('click', '.cw_monitor_link', $.proxy(this.onMonitorClick, this));
		assetReasonsList.on('click', '.cw_agent_name', $.proxy(this.onAgentNameClick, this));
		assetQualifiersList.on('click', '.cw_sq_link', $.proxy(this.onQualifierClick, this));
		assetQualifiersList.on('click', '.cw_element_link', $.proxy(this.onElementClick, this));
		assetQualifiersList.on('click', '.cw_service_link', $.proxy(this.onServiceClick, this));
		metricsList.on('click', '.cw_grid_check', $.proxy(this.onMetricsCheckboxClick, this));
		metricsList.on('click', '.cw_grid_check_all', $.proxy(this.onMetricsCheckboxAllClick, this));
		metricsList.on('click', '.cw_metrics_source', $.proxy(this.onMetricsSourceNameClick, this));
		metricsList.on('click', '.cw_metrics_monitor_name', $.proxy(this.onMetricsMonitorNameClick, this));
		metricsList.on('click', '.cw_instance_link', $.proxy(this.onInstanceNameClick, this));
		metricsList.on('click', '.cw_metrics_qualifier_link', $.proxy(this.onMetricsQualifierClick, this));
		allReasonsGrid.off('mouseover', '[data-monitorerror="true"]').on('mouseover', '[data-monitorerror="true"]', $.proxy(function (e) {
			Utils.onMonitorErrorOver(e, {
				dataSource: this.allReasonsGrid.dataSource,
				toolTip: this.tooltipAllReasons
			});
		}, this));
		assetReasonsList.off('mouseover', '[data-monitorerror="true"]').on('mouseover', '[data-monitorerror="true"]', $.proxy(function (e) {
			Utils.onMonitorErrorOver(e, {
				dataSource: this.reasonsGrid.dataSource,
				toolTip: this.tooltipHealthReasons
			});
		}, this));
		$('body').on('click', '.cw_monitorname', $.proxy(this.onMonitorNameClick, this));
		$('body').on('click', '.cw_agentname', $.proxy(this.onAgentNameClick, this));
		$('body').on('keyup', '#asset_name', $.proxy(this.onAssetNameKeyup, this));
		$(window).off().on('resize', $.proxy(function () {
			this.adjustSectionHeight();
			this.adjustChartHeight();
			this.adjustBottomSectionHeight();
			this.metricsGrid.wrapper.css("height", $('.cw_metricstab_container').height() - 40);
		}, this));
		$('#cw_asset_icon').on('click', $.proxy(this.onAssetIconClick, this));
	},
	onAssetNameKeyup: function () {
		const name = $('#asset_name').val();
		Utils.setInvalidField(name, $('#asset_name'), false, 'required_text');
		Utils.setPlaceholder($('#asset_name'), t('Please input Name...'));
		if(name.trim() !== '') {
			$('#cw_assetdetails_update').attr('disabled', false);
		}
		else {
			$('#cw_assetdetails_update').attr('disabled', true);
		}
	},
	onAssetIconClick: function(){
		var modalContent = '';
		modalContent += '<div id="imageUploaderModal" class="cw_media_library_content"></div>';
		modalContent += '<div class="actions">';
		modalContent += '<button class="k-button right" id="icon_cancel">' + lang.CANCEL + '</button>';
		modalContent += '<button class="k-button k-primary go_right" id="change_icon">' + lang.UPDATE + '</button>';
		modalContent += '</div>';
		modalContent += '<div class="status"><p></p></div>';

		if (!$('#modal').length) {
			$('body').append('<div id="modal"></div>');
		}
		$('#modal').kendoWindow({
			title: lang.MEDIA_LIBRARY,
			width: '640',
			height: '480',
			modal: true,
			close: function () {
				$('#modal').data("kendoWindow").destroy();
				$('#modal').remove();
			}
		});

		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.content(modalContent);
		modalWindow.center();

		let selectedIcon = null;
		var imageUploader = new ImageUploader({
			id: 'imageUploaderModal',
			context: 'assets',
			onImageSelected: $.proxy(function (selectedImage) {
				selectedIcon = selectedImage;
			}, this)
		});

		$('#change_icon').off()
			.on('click', $.proxy(function () {
				$('#modal').data("kendoWindow").close();
				this.imageId = selectedIcon.id;
				this.iconPack = selectedIcon.iconPack;
				this.setAssetImage();
			}, this));

		$('#icon_cancel').off().on('click', $.proxy(function () {
			$('#modal').data("kendoWindow").close();
		}, this));
	},

	setAssetImage: function(){
		var imagePreviewDiv = $('#cw_asset_icon');
		imagePreviewDiv.empty();
		imagePreviewDiv.attr('title', t('Click to change Asset icon'));
		if (this.imageId) {
			imagePreviewDiv.addClass('no_bg');
			if (Utils.isGuid(this.imageId)) {
				imagePreviewDiv.prepend('<img width="28px" height="28px" src="' + Api.images.urls.image(this.imageId) + '" />');
			} else {
				if (this.iconPack === 'glyph') {
					imagePreviewDiv.prepend('<span class="glyphicons ' + this.imageId + '" style="font-size: 23px;"></span>');
				} else {
					imagePreviewDiv.prepend('<i class="material-icons">' + this.imageId + '</i>');
				}
			}
		}
	},
	/**
	 *  Handler function for loading the entire view user preferences
	 */
	loadMultipleUserPreferences: async function () {
		this.userPref = [];
		let result = await UserPrefs.loadMultipleCategories(['ReasonsMonitors', 'AssetHealthAllReasons', 'AssetHealthHealthReasons', 'AssetDetailsInventory', 'AssetDetailsMetrics']);

		if (result.success) {
			for (var i = 0; i < result.data.length; i++) {
				this.userPref = result.data;
			}
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}

	},
	/**
	 * Handler for the click event on the monitor name
	 * @param {Object} e The click event
	 */
	onMonitorNameClick: function (e) {
		e.preventDefault();
		var target = $(e.currentTarget);

		var monitorId = target.data('id');
		var monitorType = target.data('type');

		this.openMonitorView(monitorId, monitorType);
	},
	/*
	* Handler function for clicking metric checkox
	* */
	onMetricsCheckboxClick: function () {
		var checkboxes = $('#cw_metrics_list .cw_grid_check:checked');
		if (checkboxes.length) {
			this.metricsGridMenu.enableItem('cw_delete_metrics');
			this.metricsGridMenu.enableItem('cw_show_metrics');
			this.metricsGridMenu.enableItem('cw_show_aggregated_metrics');
			this.metricsGridMenu.enableItem('cw_show_multigraph_metrics');
			this.metricsGridMenu.enableItem('cw_show_table');
		} else {
			this.metricsGridMenu.disableItem('cw_delete_metrics');
			this.metricsGridMenu.disableItem('cw_show_metrics');
			this.metricsGridMenu.disableItem('cw_show_aggregated_metrics');
			this.metricsGridMenu.disableItem('cw_show_multigraph_metrics');
			this.metricsGridMenu.disableItem('cw_show_table');
		}
	},
	/*
	 * Handler function for clicking metric select all checkox
	 * @param {Object} e The click event object
	 * */
	onMetricsCheckboxAllClick: function (e) {
		var target = $(e.currentTarget);
		$('#cw_metrics_list .cw_grid_check').prop('checked', target.is(':checked'));

		this.onMetricsCheckboxClick();
	},
	/*
	 * Handler function for clicking the asset console icon
	 * @param {Object} e The click event
	 * */
	onAssetConsole: function (e) {
		e.stopPropagation();
		var target = $(e.currentTarget);
		var monitorType = target.closest('.cw_state_widget').attr('monitor-type');
		var monitorId = target.closest('.cw_state_widget').attr('data-uid');
		switch (monitorType) {
			case 'SYSTEM':
				this.assetConsoleWindow = openAssetConsoleWindow({
					type: monitorType,
					title: lang.widget.SYSTEM_MONITOR_CONSOLE,
					assetId: this.id,
					monitorId: monitorId,
					accountId: this.accountId
				});
				break;
			case 'IBMSVC':
				this.assetConsoleWindow = openAssetConsoleWindow({
					type: monitorType,
					title: lang.widget.ibm.TITLE,
					width: 800,
					height: 626,
					assetId: this.id,
					monitorId: monitorId,
					assetName: this.name,
					assetDescription: this.description,
					accountId: this.accountId
				});
				break;
		}

	},
	/**
	 * Handler for the click event on the monitor name
	 * @param {Object} e The click event
	 */
	onMonitorClick: function (e) {
		e.preventDefault();
		var target = $(e.currentTarget);
		var monitorId = target.data('id');
		var monitorType = target.data('type');
		this.openMonitorView(monitorId, monitorType);
	},
	/**
	 * Handler for the summary expand button
	 */
	onAssetDetailsExpand: function () {
		var loadUrl = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '?update=' + this.update;

		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.assetTargetsList = result.data.targets;
				this.iconPack = result.data.iconPack;
				this.setAssetImage();
				$('#asset_name').val(result.data.name);
				this.descriptionHandler.value(result.data.description);
				$('#asset_system_type').data('kendoDropDownList')?.value(result.data.systemType);
				this.oldDetailValues = {
					name: this.name,
					description: this.description,
					systemType: this.systemType,
					targets: this.assetTargetsList,
					assetGroups: this.assetGroups
				};
				this.renderAssetTargets()
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for create incident button
	 * @param {Object} e The click event object
	 */
	onIncidentNumberClick: function (e) {
		var currentTarget = $(e.currentTarget), incidentId = currentTarget.data('incidentid'),
			incidentNumber = currentTarget.data('incidentnumber');

		if (incidentId) {

			State.mainApp.navigate(IncidentsRouter.details(incidentId),
				{
					extra: {
						isFromAssetDetails: true
					}
				});
		}
	},
	renderHistoricEventsFilter: function () {
		ReactDOM.unmountComponentAtNode($('#historic_filters_enabled').get(0));
		let message = lang.assethealth.HISTORIC_MODE;
		ReactDOM.render(<BoxView type={"info-2"}
		                         onClick={() => {this.resetReasonsGrid()}}
		                         rounded={true}
		                         border={true}
		                         style={{height: '35px', padding: '2px 10px'}}>
				<div>{message}</div>
		</BoxView>, $('#historic_filters_enabled').get(0));
	},
	resetReasonsGrid() {
		ReactDOM.unmountComponentAtNode($('#historic_filters_enabled').get(0));
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/reasons';
		var newDatasource = this.setReasonsGridDatasource(url);
		this.reasonsGrid.setDataSource(newDatasource);
		this.healthFilterPanel.setGrid(this.reasonsGrid);
	},
	/**
	 * Handler function for create incident button
	 * @param {Object} e The click event object
	 */
	onIncidentCreate: function (e) {
		var assets = [], accountList = [], reasonsIds = [], checkboxes = $('.cw_incident_check:checked');
		assets.push({
			id: this.id,
			name: this.name,
			sysMonId: this.sysMonId,
			nimMonId: this.nimMonId
		});
		accountList.push({
			id: this.accountId,
			name: this.accountNameContext
		});

		for (var i = 0, length = checkboxes.length; i < length; i++) {
			reasonsIds.push($(checkboxes[i]).data('id'));
		}

		State.mainApp.navigate(IncidentsRouter.createNew('ASSET'),
			{
				extra: {
					reasonsIds,
					assets,
					isFromAssetDetails: true
				},
			});
	},
	/**
	 * Initialize Kendo UI controls
	 */
	initKendoComponents: function () {
		var scope = this;
		var filterMessages = lang.grid.filter, reasonsUrl;
		$('body').append('<div id="widget_wrapper" class="cw_sq_details"></div>');

		this.actionNotification = new CustomNotification({
			appendToElement: '.cw_summary_container .cw_assetdetails_reasons',
			style: 'top:15px;',
			hideOnClick: true
		});

		this.gridMessages = {
			isTrue: '<span class="glyphicons ok"></span>',
			isFalse: '<span class="glyphicons remove"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		this.tabStrip = $('#cw_assethealth_tabs_nav').kendoTabStrip({
			animation: false,
			activate: $.proxy(this.onTabActivate, this),

		}).data('kendoTabStrip');

		if (!this.app.session.hasRole('ASSET_MAINTENANCE_LIST')) {
			this.tabStrip.disable($('#nav_maintenance_tab'));
		}

		$('#asset_system_type').kendoDropDownList({
			dataTextField: 'text',
			enabled: this.mode === 'view' ? false : true,
			dataValueField: 'value',
			value: this.systemType || 'GENERIC',
			dataSource: [{
				text: lang.account.SYSTEM_TYPE_GENERIC,
				value: 'GENERIC'
			}, {
				text: lang.account.SYSTEM_TYPE_WINDOWS,
				value: 'WINDOWS'
			}, {
				text: lang.account.SYSTEM_TYPE_UNIX,
				value: 'UNIX'
			}]
		});

		this.monitorTypeSelector = $('#cw_assetdetails_type_selector').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			width: 60,
			value: 'ALL',
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + this.accountIdContext + '/assets/' + this.id + '/monitors/types',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: function (response) {
						response.push({
							text: lang.ALL,
							value: 'ALL'
						});
						return response;
					}
				}
			}),
			select: function (e) {
				//e.sender.trigger('change');
			},
			change: $.proxy(this.onMonitorTypeChange, this)
		}).data('kendoDropDownList');

		this.assetDetailsEntriesSelector = $('#cw_assetdetails_entries_selector').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			width: 60,
			value: '10',
			dataSource: [{
				text: '10 ' + lang.ENTRIES,
				value: 10
			}, {
				text: '50 ' + lang.ENTRIES,
				value: 50
			}, {
				text: '100 ' + lang.ENTRIES,
				value: 100
			}],
			select: function (e) {
				e.sender.trigger('change', e);
			},
			change: $.proxy(this.onEntriesSelectorChange, this)
		}).data('kendoDropDownList');

		this.assetDetailsAggregationType = $('#cw_assetdetails_aggregation_type').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			width: 60,
			value: 'high',
			dataSource: aggregationTypeDataSource,
			change: () => this.onAggregationTypeChange()
		}).data('kendoDropDownList');

		if (this.app.session.hasRole('MONITOR_LIST')) {
			this.timeRange = new TimeRange({
				renderTo: $('.cw_history_filter_container'),
				toggleElement: $('#cw_history_filter_button'),
				type: 'dateTime',
				toValue: moment().add(15, 'minutes').toDate(),
				'class': '',
				style: '',
				onSubmit: $.proxy(function (element, fromDate, toDate) {
					this.hasCustomTime = true;
					this.reasonsFromTime = Utils.customDateToString(fromDate, 'YYYYMMDDHHmmss');
					this.reasonsToTime = Utils.customDateToString(toDate, 'YYYYMMDDHHmmss');

					$('#cw_assetdetails_entries_selector').data('kendoDropDownList').text(lang.CUSTOM);

					this.getReasonsChartData();
					this.renderHistoricEventsFilter();
				}, this),
				onReset: $.proxy(function () {
					this.hasCustomTime = false;
					this.resetReasonsGrid();
					this.getReasonsChartData();
					this.assetDetailsEntriesSelector.value(this.chartEntries);
				}, this)
			});

			this.healthReasonsDefaultColumns = {
				id: {
					hidden: false,
					width: 40
				},
				severityIndex: {
					hidden: false,
					width: 60
				},
				timestamp: {
					hidden: false,
					width: 140
				},
				text: {
					hidden: false,
					width: 200
				},
				monitorName: {
					hidden: false,
					width: 120
				},
				monitorTypeText: {
					width: 140,
					hidden: false,
				},
				monitorClassText: {
					width: 140,
					hidden: true
				},
				agentName: {
					width: 140,
					hidden: false
				},
				subsystem: {
					hidden: false,
					width: 110
				},
				externalId: {
					hidden: false,
					width: 120
				},
				acknowledged: {
					hidden: false,
					width: 160
				},
				acknowledgedByName: {
					hidden: false,
					width: 160
				},
				acknowledgedAt: {
					hidden: false,
					width: 160
				},
				assignedToName: {
					hidden: false,
					width: 160
				},
				assignedToType: {
					hidden: false,
					width: 160
				},
				information: {
					hidden: false,
					width: 200
				}
			};

			var healthReasonsSort, healthReasonsFilter;
			var defaultFsView = UserPrefs.get('defaultFsView');
			if (Utils.isGuid(defaultFsView)) {
				var pref = JSON.parse(UserPrefs.get(defaultFsView));
				pref = pref || {
					sort: [],
					filter: []
				};
				healthReasonsSort = pref.sort;
				healthReasonsFilter = pref.filter;
				this.healthReasonsColumns = pref.columns;
			} else {
				const ahHealthReasonsSort = findUserPref(this.userPref, 'ahHealthReasonsSort');
				const ahHealthReasonsFilter = findUserPref(this.userPref, 'ahHealthReasonsFilter');

				if (ahHealthReasonsSort) {
					healthReasonsSort = JSON.parse(ahHealthReasonsSort);
				} else {
					healthReasonsSort = [{
						field: 'timestamp',
						dir: 'desc',
						compare: null
					}];
				}
				if (ahHealthReasonsFilter) {
					healthReasonsFilter = JSON.parse(ahHealthReasonsFilter);
				} else {
					healthReasonsFilter = [];
				}
			}

			const ahHealthReasonsColumns = findUserPref(this.userPref, 'ahHealthReasonsColumns');
			let parsedColumns = ahHealthReasonsColumns ? JSON.parse(ahHealthReasonsColumns) : undefined;
			let columns = this.healthReasonsColumns || parsedColumns || this.healthReasonsDefaultColumns;
			columns = Utils.completeColumns(columns, this.healthReasonsDefaultColumns);
			//quickfix for 2.4
			for (var key in columns) {
				if (!columns[key].width) {
					columns[key].width = this.healthReasonsDefaultColumns[key].width;
				}
			}

			this.reasonsGridDataSource = new CeeViewDataSource({
				data: this.reasonsList,
				schema: {
					model: {
						id: 'id',
						fields: {
							severityIndex: {
								type: 'number'
							},
							acknowledged: {
								type: 'boolean'
							},
							acknowledgedByName: {
								type: 'string'
							},
							assignedToName: {
								type: 'string',
								editable: false
							},
							information: {
								type: 'string',
								editable: false
							}
						}
					},
					parse: function (data) {
						for (var i = 0, length = data.length; i < length; i++) {
							if (data[i].assignedToType === 'ASSIGN_TEAM') {
								data[i].assignedToGlyph = 'parents';
								if (data[i].assignedToName) {
									data[i].assignedToMouseover = lang.TEAM + ': ' + data[i].assignedToName;
								} else {
									data[i].assignedToMouseover = lang.TEAM;
								}
							} else if (data[i].assignedToType === 'ASSIGN_USER') {
								data[i].assignedToGlyph = 'user';
								if (data[i].assignedToName) {
									data[i].assignedToMouseover = lang.USER + ': ' + data[i].assignedToName;
								} else {
									data[i].assignedToMouseover = lang.USER;
								}
							} else {
								data[i].assignedToGlyph = '';
								data[i].assignedToMouseover = '';
							}
							if (data[i].acknowledgedByName) {
								data[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + data[i].acknowledgedByName;
							} else {
								data[i].acknowledgedMouseover = '';
							}
						}
						return data;
					}
				},
				sort: healthReasonsSort,
				filter: healthReasonsFilter
			});

			this.assetDetailsReasonsMenu = new GridMenu({
				renderTo: 'cw_assetdetails_reasons_menu',
				items: [{
					id: 'cw_reason_create_incident',
					icon: 'construction-cone',
					text: lang.incidents.CREATE_INCIDENT,
					role: 'INCIDENT_UPDATE',
					fn: this.onIncidentCreate,
					scope: this,
					disabled: true
				}, {
					id: 'cw_reason_acknowledge',
					icon: 'check',
					text: lang.incidents.MARK_AS_SEEN,
					fn: this.onReasonsAckUnack,
					scope: this,
					disabled: true
				}, {
					id: 'cw_reason_unacknowledge',
					icon: 'unchecked',
					text: lang.UNACKNOWLEDGE,
					fn: this.onReasonsAckUnack,
					scope: this,
					disabled: true
				}, {
					id: 'cw_assign',
					icon: 'object-align-horizontal',
					text: lang.ASSIGN,
					fn: $.proxy(function () {
						this.onAssign('cw_assetdetails_reasons_list');
					}, this),
					role: 'EVENT_SUMMARY_LIST',
					scope: this,
					disabled: true
				}, {
					id: 'cw_unassign',
					icon: 'undo',
					text: lang.UNASSIGN,
					fn: $.proxy(function () {
						this.onUnassign('cw_assetdetails_reasons_list');
					}, this),
					role: 'EVENT_SUMMARY_LIST',
					scope: this,
					disabled: true
				}, {
					id: 'cw_delete_reason',
					icon: 'remove-circle',
					text: lang.DELETE_REASON,
					fn: () => {
						this.onRemove('cw_assetdetails_reasons_list');
					},
					scope: this,
					disabled: true,
					deleteFromGrid: 'cw_assetdetails_reasons_list'
				}, {
					id: 'cw_create_filter',
					icon: 'filter',
					text: lang.assethealth.CREATE_FILTER,
					fn: $.proxy(function () {
						this.onCreateFilter('cw_assetdetails_reasons_list');
					}, this),
					scope: this,
					cssClass: '',
					disabled: true,
					role: 'HEALTHFILTER_CREATE'
				}, {
					id: 'cw_reasons_reset_hi',
					icon: 'restart',
					text: lang.assethealth.RESET_HI,
					fn: $.proxy(function () {
						this.onResetHealthIndexButton('cw_assetdetails_reasons_list');
					}, this),
					scope: this,
					cssClass: '',
					disabled: true,
					role: 'MONITOR_UPDATE'
				}, {
					id: 'cw_set_information',
					icon: 'info-sign',
					text: lang.SET_INFORMATION,
					fn: () => {
						this.onSetInformation('cw_assetdetails_reasons_list');
					},
					scope: this,
					disabled: true
				}]
			});

			var monitorTemplate = this.app.session.hasRole('MONITOR_UPDATE') ? '#= monitorName ? "<a class=\'cw_grid_link cw_monitor_link\' data-id=\'" + monitorId + "\' data-type=\'" + monitorType + "\'>" + monitorName + "<a/>" : ""#' : '#=monitorName#';
			this.reasonsGrid = $('#cw_assetdetails_reasons_list').kendoCustomGrid({
				dataSource: this.reasonsGridDataSource,
				resizable: true,
				reorderable: true,
				scrollable: true,
				sortable: {
					mode: "multiple",
					allowUnsort: true
				},
				filterable: {
					extra: false,
					operators: {
						string: {
							startswith: filterMessages.STARTS_WITH,
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						},
						number: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
							/*gte: filterMessages.GTE,
							 gt: filterMessages.GT,
							 lte: filterMessages.LTE,
							 lt: filterMessages.LT*/
						}
					},
					messages: this.gridMessages
				},
				pageable: false,
				selectable: 'row',
				columns: Utils.rearrangeColumns([{
					field: 'id',
					title: lang.SELECTOR,
					sortable: false,
					filterable: false,
					menu: false,
					template: '<input type="checkbox" class="cw_incident_check" data-uid="${uid}" data-id="${id}" />',
					headerTemplate: '<input type="checkbox" class="cw_incident_check_all" />',
					attributes: {
						'class': 'text_center'
					},
					headerAttributes: {
						'class': 'text_center'
					},
					width: columns.id.width,
					hidden: columns.id.hidden
				}, {
					field: 'severityIndex',
					title: lang.assethealth.SEVERITY,
					sortable: true,
					filterable: {
						ui: $.proxy(this.getFilterableForSeverity, {
							scope: this,
							dataSource: this.reasonsGridDataSource,
							field: 'severityIndex',
							container: 'cw_assetdetails_reasons_list'
						}),
						messages: this.gridMessages
					},
					template: item => getSeverityState(item.severity, item, false),
					width: columns.severityIndex.width,
					hidden: columns.severityIndex.hidden
				}, {
					field: 'timestamp',
					title: lang.service.TIME,
					sortable: true,
					filterable: false,
					template: '#=Renderer.browserDateRenderer(timestamp, Constants.DATETIME)#',
					width: columns.timestamp.width,
					hidden: columns.timestamp.hidden,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'text',
					title: lang.REASONS,
					sortable: true,
					filterable: true,
					hidden: columns.text.hidden,
					width: columns.text.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand reason_text'
					}
				}, {
					field: 'monitorName',
					title: lang.assethealth.MONITOR_NAME,
					sortable: true,
					filterable: true,
					template: monitorTemplate,
					width: columns.monitorName.width,
					hidden: columns.monitorName.hidden,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'monitorTypeText',
					title: lang.assethealth.MONITOR_TYPE,
					sortable: true,
					filterable: {
						ui: $.proxy(function (element) {
							return Renderer.filterGridByOwnElementDS.call(this, element, 'monitorTypeText', this.reasonsGrid);
						}, this),
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								neq: filterMessages.NEQ,
								eq: filterMessages.EQ
							}
						}
					},
					width: columns.monitorTypeText.width,
					hidden: columns.monitorTypeText.hidden,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				},{
					field: 'monitorClassText',
					title: lang.assethealth.MONITOR_CLASS,
					sortable: true,
					filterable: {
						ui: $.proxy(function (element) {
							return Renderer.filterGridByOwnElementDS.call(this, element, 'monitorClassText', this.reasonsGrid);
						}, this),
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								neq: filterMessages.NEQ,
								eq: filterMessages.EQ
							}
						}
					},
					width: columns.monitorClassText.width,
					hidden: columns.monitorClassText.hidden,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'agentName',
					title: lang.assethealth.AGENT_NAME,
					sortable: true,
					filterable: {
						ui: $.proxy(function (element) {
							return Renderer.filterGridByOwnElementDS.call(this, element, 'agentName', this.reasonsGrid);
						}, this),
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								neq: filterMessages.NEQ,
								eq: filterMessages.EQ
							}
						}
					},
					hidden: columns.agentName.hidden,
					width: columns.agentName.width,
					template: item => {
						return State.mainApp.session.hasRole('AGENT_READ') ? `<span class="cw_grid_link cw_agent_name" data-agentid="${item.agentId}">${item.agentName || ""}</span>` : `${item.agentName || ""}`;
					},
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'subsystem',
					title: lang.assethealth.SUBSYSTEM,
					sortable: true,
					filterable: true,
					width: columns.subsystem.width,
					hidden: columns.subsystem.hidden,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'externalId',
					title: lang.account.ASSET_EXTERNAL_ID,
					sortable: true,
					filterable: true,
					width: columns.externalId.width,
					hidden: columns.externalId.hidden,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'acknowledged',
					title: lang.service.ACKNOWLEDGED,
					sortable: true,
					filterable: true,
					template: '#if(acknowledged){#<span class="glyphicons ok"></span>#}#',
					hidden: columns.acknowledged.hidden,
					width: columns.acknowledged.width,
					attributes: {
						'title': '#=acknowledgedMouseover || "" #'
					}
				}, {
					field: 'acknowledgedByName',
					title: lang.service.ACKNOWLEDGED_BY,
					sortable: true,
					filterable: true,
					template: '#=acknowledgedByName ? acknowledgedByName : "" #',
					hidden: columns.acknowledgedByName.hidden,
					width: columns.acknowledgedByName.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'acknowledgedAt',
					title: lang.incidents.TIME_OF_FLAG,
					sortable: true,
					filterable: true,
					template: '#=Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME)#',
					hidden: columns.acknowledgedAt.hidden,
					width: columns.acknowledgedAt.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'assignedToName',
					title: lang.ASSIGNED_TO,
					sortable: true,
					filterable: true,
					template: '#=assignedToName ? assignedToName : "" #',
					hidden: columns.assignedToName.hidden,
					width: columns.assignedToName.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'assignedToType',
					title: lang.ASSIGNED_TO_TYPE,
					sortable: true,
					filterable: {
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						},
						ui: function (element) {
							var multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'assignedToType',
								grid: scope.reasonsGrid,
								dataSource: [{
									text: lang.NONE,
									icon: '<span class="glyphicons"></span>',
									value: 'ASSIGN_NONE'
								}, {
									text: lang.USER,
									icon: '<span class="glyphicons user"></span>',
									value: 'ASSIGN_USER'
								}, {
									text: lang.TEAM,
									icon: '<span class="glyphicons parents"></span>',
									value: 'ASSIGN_TEAM'
								}]
							});
						}
					},
					template: '<span class="glyphicons #=assignedToGlyph#" title="#=assignedToMouseover#"></span>',
					attributes: {
						'class': 'tooltip ellipsis to_expand text_center'
					},
					hidden: columns.assignedToType.hidden,
					width: columns.assignedToType.width
				}, {
					field: 'information',
					title: lang.INFORMATION,
					sortable: true,
					filterable: true,
					template: '#=information ? information : "" #',
					hidden: columns.information.hidden,
					width: columns.information.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}], columns),
				columnMenu: true,
				dataBound: $.proxy(this.onHealthReasonsDataBound, this),
				change: $.proxy(function () {
					var selectedRow = this.reasonsGrid.select();
					var myRow = selectedRow[0];
					var messageEl = $(myRow).find('.to_expand');
					if ($(messageEl).hasClass('cw_message_expanded')) {
						$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
					} else {
						$('#cw_assetdetails_reasons_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
						$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
					}
				}, this)
			}).data('kendoCustomGrid');

			// Add Kendo tooltip to the header of the columns
			Utils.gridColumnHeaderTooltip(this.reasonsGrid);
			this.reasonsGrid.thead.find("[data-field='id']>.k-header-column-menu").remove();

			const defaultHealthReasonsFsView = findUserPref(this.userPref, 'defaultHealthReasonsFsView');
			const ahHealthReasonsSearchPhrase = findUserPref(this.userPref, 'ahHealthReasonsSearchPhrase');

			var searchValue = defaultHealthReasonsFsView ? '' : (ahHealthReasonsSearchPhrase || '');
			this.healthFilterPanel = new PreferencesPanel({
				renderTo: 'cw_health_reasons_filters',
				grid: this.reasonsGrid,
				modulePrefName: 'AssetDetailsHealthReasons',
				defaultPrefViewKey: 'defaultHealthReasonsFsView',
				prefListKey: 'ahHealthReasonsFSViews',
				userPref: this.userPref,
				onRemove: $.proxy(this.saveUserPreferences, this),
				searchFields: ['text', 'monitorName', 'monitorTypeText', 'monitorClassText'],
				searchValue: searchValue,
				defaultColumns: this.reasonsGrid.columns,
				category: 'AssetHealthHealthReasons'
			});
			this.reasonsGrid.dataSource.fetch();
			this.renderMonitorsTab();
		}

		this.qualifiersDataSource = new CeeViewDataSource({
			data: this.qualifiersList,
			schema: {
				model: {
					id: 'id',
					fields: {
						state: {
							type: 'string'
						}
					}
				},
				parse: $.proxy(function (result) {
					for (var i = 0; i < result.length; i++) {
						if (result[i].shared) {
							result[i].sharedClass = 'glyphicons share';
						} else {
							result[i].sharedClass = '';
						}
					}
					return result;
				}, this)
			}
		});

		this.qualifiersGrid = $('#cw_assetdetails_qualifiers_list').kendoCustomGrid({
			dataSource: this.qualifiersDataSource,
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					'boolean': {
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					'string': {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					'number': {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			resizable: true,
			selectable: 'row',
			columns: [{
				field: 'state',
				title: lang.STATE,
				filterable: {
					ui: $.proxy(this.getFilterableForState, {
						scope: this,
						dataSource: this.qualifiersDataSource,
						field: 'state'
					}),
					messages: this.gridMessages
				},
				attributes: {
					'class': 'text_center'
				},
				template: item => {
					if (item.assetMaintenance) {
						return '<span class="cw_service_indicator is_idle glyphicons wrench right" style="float: none !important;"></span>';
					}
					if (item.state === 'true') {
						if (item.warning) {
							return '<span class="cw_status_indicator cw_status_widget_color cw_color5"><span class="glyphicons exclamation-mark"></span></span>';
						}
						if (item.shared) {
							return '<span class="cw_status_indicator cw_status_widget_color cw_color5 glyphicons share"></span>';
						}
						return '<span class="cw_status_indicator cw_status_widget_color cw_color5"></span>';
					} else {
						if (item.warning) {
							return '<span class="cw_status_indicator cw_status_widget_color cw_color1"><span class="glyphicons exclamation-mark"></span></span>';
						}
						if (item.shared) {
							return '<span class="cw_status_indicator cw_status_widget_color cw_color1 glyphicons share"></span>';
						}
						return '<span class="cw_status_indicator cw_status_widget_color cw_color1"></span>';
					}
				},
				width: 100
			}, {
				field: 'name',
				title: lang.NAME,
				template: '<a class="cw_grid_link cw_sq_link ellipsis to_expand" data-id="${id}">${name}</a>',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'elementName',
				title: lang.designer.SERVICE_ELEMENT,
				template: '<a class="cw_grid_link cw_element_link ellipsis to_expand" data-id="${id}">${elementName}</a>',
				width: 160,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'serviceName',
				title: lang.SERVICE,
				template: '<a class="cw_grid_link cw_service_link ellipsis to_expand" data-id="${id}">${serviceName}</a>',
				width: 160,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'classname',
				title: lang.CLASSNAME,
				width: 160,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}],
			columnMenu: true,
			dataBound: function () {
				var tooltip = $('#cw_assetdetails_qualifiers_list').find('.k-grid-content').data('kendoTooltip');
				if (tooltip) {
					tooltip.destroy();
				}
				$('#cw_assetdetails_qualifiers_list').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});
				var items = this.dataSource.data();
				for (var i = 0; i < items.length; i++) {
					if (items[i].shared) {
						$('[data-uid=' + items[i].uid + ']').find('.cw_status_indicator').addClass('glyphicons share').css('color', '#FFF');
					}
				}
			},
			change: $.proxy(function () {
				var selectedRow = this.qualifiersGrid.select();
				var myRow = selectedRow[0];
				var messageEl = $(myRow).find('.to_expand');
				if ($(messageEl).hasClass('cw_message_expanded')) {
					$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
				} else {
					$('#cw_assetdetails_qualifiers_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
					$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
				}
			}, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.qualifiersGrid);

		this.qualifiersFilterPanel = new PreferencesPanel({
			renderTo: 'cw_qualifiers_filters',
			grid: this.qualifiersGrid,
			searchFields: ['name', 'elementName', 'serviceName'],
			hideViews: true
		});

		this.qualifiersDataSource.trigger('change');

		if (this.highlightQualifierId) {
			var uid = this.qualifiersGrid.dataSource.get(this.highlightQualifierId).uid;
			this.qualifiersGrid.select($('#cw_assetdetails_qualifiers_list').find('tr[data-uid="' + uid + '"]'));
		}

		this.summaryReasonsDefaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			severity: {
				hidden: false,
				width: 100
			},
			timestamp: {
				hidden: false,
				width: 140
			},
			text: {
				hidden: false,
				width: 400
			},
			incidentAssignedToName: {
				hidden: false,
				width: 140
			},
			monitorName: {
				hidden: false,
				width: 120
			},
			monitorTypeText: {
				width: 140,
				hidden: false,
			},
			monitorClassText: {
				width: 140,
				hidden: true,
			},
			agentName: {
				hidden: false,
				width: 140
			},
			subsystem: {
				hidden: false,
				width: 120
			},
			incidentNumber: {
				hidden: false,
				width: 120
			},
			acknowledged: {
				hidden: false,
				width: 160
			},
			acknowledgedByName: {
				hidden: false,
				width: 160
			},
			acknowledgedAt: {
				hidden: false,
				width: 160
			},
			assignedToName: {
				hidden: false,
				width: 160
			},
			assignedToType: {
				hidden: false,
				width: 160
			},
			information: {
				hidden: false,
				width: 200
			}
		};

		var allReasonsSort, allReasonsFilter;
		var defaultFsView = UserPrefs.get('defaultFsView');

		const ahAllReasonsSort = findUserPref(this.userPref, 'ahAllReasonsSort');
		const ahAllReasonsFilter = findUserPref(this.userPref, 'ahAllReasonsFilter');

		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			allReasonsSort = pref.sort;
			allReasonsFilter = pref.filter;
			this.allReasonsColumns = pref.columns;
		} else {
			if (ahAllReasonsSort) {
				allReasonsSort = JSON.parse(ahAllReasonsSort);
			} else {
				allReasonsSort = [{
					field: 'timestamp',
					dir: 'desc',
					compare: null
				}];
			}
			if (ahAllReasonsFilter) {
				allReasonsFilter = JSON.parse(ahAllReasonsFilter);
			} else {
				allReasonsFilter = [];
			}
		}

		var columns = this.allReasonsColumns || JSON.parse(UserPrefs.get('ahAllReasonsColumns')) || this.summaryReasonsDefaultColumns;
		columns = Utils.completeColumns(columns, this.summaryReasonsDefaultColumns);

		this.allReasonsGridDataSource = new CeeViewDataSource({
			data: this.reasonsList,
			schema: {
				model: {
					id: 'id',
					fields: {
						timestamp: {
							type: 'date'
						},
						incidentAssignedToName: {
							type: 'string'
						},
						monitorName: {
							type: 'string'
						},
						subsystem: {
							type: 'string'
						},
						incidentNumber: {
							type: 'string'
						},
						text: {
							type: 'string'
						},
						acknowledged: {
							type: 'boolean'
						},
						acknowledgedByName: {
							type: 'string'
						},
						assignedToName: {
							type: 'string',
							editable: false
						},
						information: {
							type: 'string',
							editable: false
						}
					}
				},
				parse: function (data) {
					for (var i = 0, length = data.length; i < length; i++) {
						data[i].timestamp = new Date(data[i].timestamp);
						if (data[i].assignedToType === 'ASSIGN_TEAM') {
							data[i].assignedToGlyph = 'parents';
							if (data[i].assignedToName) {
								data[i].assignedToMouseover = lang.TEAM + ': ' + data[i].assignedToName;
							} else {
								data[i].assignedToMouseover = lang.TEAM;
							}
						} else if (data[i].assignedToType === 'ASSIGN_USER') {
							data[i].assignedToGlyph = 'user';
							if (data[i].assignedToName) {
								data[i].assignedToMouseover = lang.USER + ': ' + data[i].assignedToName;
							} else {
								data[i].assignedToMouseover = lang.USER;
							}
						} else {
							data[i].assignedToGlyph = '';
							data[i].assignedToMouseover = '';
						}
						if (data[i].acknowledgedByName) {
							data[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + data[i].acknowledgedByName;
						} else {
							data[i].acknowledgedMouseover = '';
						}
					}
					return data;
				}
			},
			sort: allReasonsSort,
			filter: allReasonsFilter
		});
		var monitorTemplate = this.app.session.hasRole('MONITOR_UPDATE') ? '#= monitorName ? "<a class=\'cw_grid_link cw_monitor_link\' data-id=\'" + monitorId + "\' data-type=\'" + monitorType + "\'>" + monitorName + "<a/>" : ""#' : '#=monitorName#';
		var incidentTemplate = this.app.session.hasRole('INCIDENT_UPDATE') ? '#= incidentNumber ? \'<span data-incidentnumber="\' + incidentNumber + \'" data-incidentid="\' + incidentId + \'" class="cw_link cw_incident_number pointer"> \' + incidentNumber + \' <span>\' : ""#' : '#=incidentNumber#';
		this.allReasonsGrid = $('#cw_all_reasons_grid').kendoCustomGrid({
			dataSource: this.allReasonsGridDataSource,
			resizable: true,
			reorderable: true,
			scrollable: true,
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			selectable: 'row',
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_incident_check" data-uid="${uid}" data-id="${id}" />',
				headerTemplate: '<input type="checkbox" class="cw_incident_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				width: columns.id.width,
				hidden: columns.id.hidden
			}, {
				field: 'severity',
                title: lang.assethealth.SEVERITY,
				filterable: {
					ui: $.proxy(this.getFilterableForSeverity, {
						isString: true,
						scope: this,
						dataSource: this.allReasonsGridDataSource,
						field: 'severity',
						container: 'cw_all_reasons_grid'
					}),
					messages: this.gridMessages
				},
				template: item => getSeverityState(item.severity, item),
				width: columns.severity.width,
				hidden: columns.severity.hidden
			}, {
				field: 'timestamp',
				title: lang.service.TIME,
				filterable: false,
				template: '#= Renderer.browserDateRenderer(timestamp, Constants.DATETIME)#',
				width: columns.timestamp.width,
				hidden: columns.timestamp.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'text',
				title: lang.REASON,
				filterable: true,
				attributes: {
					"class": "tooltip ellipsis to_expand reason_text"
				},
				width: columns.text.width,
				hidden: columns.text.hidden
			}, {
				field: 'incidentAssignedToName',
				title: lang.RESPONSIBLE,
				filterable: true,
				template: '#= incidentAssignedToName ? incidentAssignedToName : ""#',
				width: columns.incidentAssignedToName.width,
				hidden: columns.incidentAssignedToName.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorName',
				title: lang.assethealth.MONITOR_NAME,
				filterable: true,
				template: monitorTemplate,
				width: columns.monitorName.width,
				hidden: columns.monitorName.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorTypeText',
				title: lang.assethealth.MONITOR_TYPE,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'monitorTypeText', this.allReasonsGrid);
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				width: columns.monitorTypeText.width,
				hidden: columns.monitorTypeText.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			},{
				field: 'monitorClassText',
				title: lang.assethealth.MONITOR_CLASS,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'monitorClassText', this.allReasonsGrid);
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				width: columns.monitorClassText.width,
				hidden: columns.monitorClassText.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'agentName',
				title: lang.assethealth.AGENT_NAME,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'agentName', this.allReasonsGrid);
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: columns.agentName.hidden,
				width: columns.agentName.width,
				template: item => {
					return State.mainApp.session.hasRole('AGENT_READ') ? `<span class="cw_grid_link cw_agent_name" data-agentid="${item.agentId}">${item.agentName || ""}</span>` : `${item.agentName || ""}`;
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'subsystem',
				title: lang.assethealth.SUBSYSTEM,
				filterable: true,
				template: '#= subsystem ? subsystem : ""#',
				width: columns.subsystem.width,
				hidden: columns.subsystem.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'incidentNumber',
				title: lang.service.INCIDENT,
				filterable: true,
				template: incidentTemplate,
				width: columns.incidentNumber.width,
				hidden: columns.incidentNumber.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'acknowledged',
				title: lang.service.ACKNOWLEDGED,
				filterable: true,
				template: '#if(acknowledged){#<span class="glyphicons ok"></span>#}#',
				hidden: columns.acknowledged.hidden,
				width: columns.acknowledged.width,
				attributes: {
					'title': '#=acknowledgedMouseover || "" #'
				}
			}, {
				field: 'acknowledgedByName',
				title: lang.service.ACKNOWLEDGED_BY,
				filterable: true,
				template: '#=acknowledgedByName ? acknowledgedByName : "" #',
				hidden: columns.acknowledgedByName.hidden,
				width: columns.acknowledgedByName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'acknowledgedAt',
				title: lang.incidents.TIME_OF_FLAG,
				filterable: false,
				template: '#=Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME)#',
				hidden: columns.acknowledgedAt.hidden,
				width: columns.acknowledgedAt.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assignedToName',
				title: lang.ASSIGNED_TO,
				filterable: true,
				template: '#=assignedToName ? assignedToName : "" #',
				hidden: columns.assignedToName.hidden,
				width: columns.assignedToName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assignedToType',
				title: lang.ASSIGNED_TO_TYPE,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'assignedToType',
							grid: scope.allReasonsGrid,
							dataSource: [{
								text: lang.NONE,
								icon: '<span class="glyphicons"></span>',
								value: 'ASSIGN_NONE'
							}, {
								text: lang.USER,
								icon: '<span class="glyphicons user"></span>',
								value: 'ASSIGN_USER'
							}, {
								text: lang.TEAM,
								icon: '<span class="glyphicons parents"></span>',
								value: 'ASSIGN_TEAM'
							}]
						});
					}
				},
				template: '<span class="glyphicons #=assignedToGlyph#"></span>',
				attributes: {
					'class': 'tooltip ellipsis to_expand text_center assigned_type'
				},
				hidden: columns.assignedToType.hidden,
				width: columns.assignedToType.width
			}, {
				field: 'information',
				title: lang.INFORMATION,
				filterable: true,
				template: '#=information ? information : "" #',
				hidden: columns.information.hidden,
				width: columns.information.width || 200,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], columns),
			columnMenu: true,
			dataBound: $.proxy(this.onAllReasonsDataBound, this),
			change: $.proxy(this.onAllReasonsRowExpand, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.allReasonsGrid);
		this.allReasonsGrid.thead.find("[data-field='id']>.k-header-column-menu").remove();

		const ahAllReasonsSearchPhrase = findUserPref(this.userPref, 'ahAllReasonsSearchPhrase');

		var searchValue = ahAllReasonsSearchPhrase || '';

		this.summaryFilterPanel = new PreferencesPanel({
			renderTo: 'cw_summary_reasons_filters',
			grid: this.allReasonsGrid,
			modulePrefName: 'AssetDetailsAllReasons',
			defaultPrefViewKey: 'defaultAllReasonsFsView',
			prefListKey: 'ahAllReasonsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['text', 'monitorName'],
			searchValue: searchValue,
			defaultColumns: this.allReasonsGrid.columns,
			category: 'AssetHealthAllReasons'
		});
		this.allReasonsGrid.dataSource.fetch();

		if (this.tabId) {
			this.tabStrip.activateTab($('#' + this.tabId));
		}
		this.assetGroupDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + this.accountId + '/assetGroups',
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			error: ErrorHandler.kendoServerError
		});
		this.assetGroupControl = $('#asset_group_control').kendoSortedMultiSelect({
			dataSource: this.assetGroupDataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			enable: this.mode === 'view' ? false : true,
			init: (instance) => {
				const originalTagTemplate = instance.tagTemplate;
				instance.tagTemplate = (data) => {
					const html = originalTagTemplate(data);
					let tooltip = data.groupType === 'EXTERNAL' ? lang.administration.EXTERNAL_ASSET_GROUP_TOOLTIP : data.groupType === 'DYNAMIC' ? lang.administration.DYNAMIC_ASSET_GROUP_TOOLTIP : '';
					if (data.groupType !== 'STATIC') {
						const stripped = $(html);
						stripped.attr('title', tooltip).find('span.k-i-close').hide();
						return stripped;
					}
					return html;
				};
			},
			change: function () {
				var currentItems = this.value();
				for (var i = 0; i < scope.nonStaticGroups.length; i++) {
					if (currentItems.indexOf(scope.nonStaticGroups[i]) === -1) {
						currentItems.push(scope.nonStaticGroups[i]);
					}
				}
				this.value(currentItems);
				scope.disableRemoveForNonStatic(this);
			},
			dataBound: function () {
				var placeholder, nonStaticItem, nonStaticGroupType, selectedItems;
				var data = this.dataSource.data();
				if (data.length) {
					placeholder = lang.account.messages.ASSET_GROUP_CHOOSE;
				} else {
					placeholder = lang.account.messages.ASSET_GROUP_NO_GROUPS;
				}
				this.options.placeholder = placeholder;
				if (!data.length) {
					this.enable(false);
				}
				for (var i = 0; i < data.length; i++) {
					if (data[i].groupType !== 'STATIC') {
						$('#asset_group_control_listbox').find('[data-offset-index=' + i + ']').addClass('hide');

					}
				}
				scope.disableRemoveForNonStatic(this);
			},
			deselect: (e) => {
				if (e.dataItem.groupType !== 'STATIC') {
					e.preventDefault();
				}
			}
		}).data('kendoSortedMultiSelect');
	},
	onAgentNameClick: function (e) {
		let target = $(e.currentTarget);
		let agentId = target.data('agentid');
		State.mainApp.navigate(AgentsRouter.details(agentId));
	},
	disableRemoveForNonStatic: function (select) {
		var nonStaticItem, nonStaticGroupType, selectedItems;
		var data = select.dataSource.data();
		this.nonStaticGroups = [];
		selectedItems = select.value();
		for (var k = 0; k < data.length; k++) {
			if (data[k].groupType !== 'STATIC') {
				for (var j = 0; j < selectedItems.length; j++) {
					if (data[k].id === selectedItems[j]) {
						//remove from other type than static the removal multiselect default x
						nonStaticItem = $('#asset_group_control_taglist').find('li:nth-child(' + (j + 1) + ')')
						nonStaticItem.find('.k-select').remove();
						nonStaticGroupType = data[k].groupType;
						nonStaticItem.attr('title', lang.assethealth.NON_STATIC_GROUP_ONE + ' ' + nonStaticGroupType + ' ' + lang.assethealth.NON_STATIC_GROUP_TWO);
						this.nonStaticGroups.push(data[k].id);
					}
				}
			}
		}
	},
	onAllReasonsRowExpand: function () {
		var selectedRow = this.allReasonsGrid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_all_reasons_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the click event on qualifier type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onMetricsPeriodToggle: function (value) {
		var period, startDate, endDate;

		if (typeof value === 'object') {
			period = 'CUSTOM';
			startDate = value.from.getTime();
			endDate = value.to.getTime();
		} else {
			period = value;
		}

		this.globalWidgetConfig = {
			period: period,
			startDate: startDate,
			endDate: endDate
		};

		var widget;
		for (var widgetElem in this.widgets) {
			widget = this.widgets[widgetElem];
			if (widget?.type === 'singlegraph' || widget?.type === 'multigraph') {
				widget.configuration.period = period;
				widget.configuration.startDate = startDate;
				widget.configuration.endDate = endDate;
				widget.zoomStartDate = null;
				widget.zoomEndDate = null;
				widget.zoomPeriod = null;
				widget.onZoom = false;
				widget.unsubscribe();
				widget.subscribe(true);
			}
		}
	},
	/**
	 * Handler function for service qualifier grid severity filter
	 * @param {Object} element
	 */
	getFilterableForSeverity: function (element) {
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(lang.assethealth.messages.SELECT_STATE);
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="contains"]').remove();
		operatorEl.find('option[value="startswith"]').remove();
		operatorEl.find('option[value="eq"]').text(lang.grid.filter.ISIN);
		operatorEl.find('option[value="neq"]').text(lang.grid.filter.ISNOTIN);
		operatorEl.attr('data-index', '1');
		var multiSelect = element.kendoSortedMultiSelect({
			dataSource: [{
				text: lang.OK,
				color: 5,
				value: this.isString ? 'NONE': 0
			}, {
				text: lang.MINOR,
				color: 3,
				value: this.isString ? 'MINOR': 1
			}, {
				text: lang.MAJOR,
				color: 2,
				value: this.isString ? 'MAJOR': 2
			}, {
				text: lang.CRITICAL,
				color: 1,
				value: this.isString ? 'CRITICAL': 3
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			itemTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>',
			tagTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>'
		}).data('kendoSortedMultiSelect');
		if (this.isString) {
			let filter = this.scope.allReasonsGrid.dataSource.filter();
			let severityFilters = [];
			if (filter) {
				let filters = filter.filters;
				for (let i = 0; i < filters.length; i++) {
					if (filters[i].field === 'severity') {
						severityFilters.push(filters[i].value);
					}
				}
			}
			multiSelect.value(severityFilters);
		}
		menu.find('[type=submit]').on('click', {
			widget: multiSelect,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterBySeverityIndicator, {
			scope: this.scope,
			dataSource: this.dataSource,
			field: this.field,
			container: this.container
		}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			multiSelect.value([]);
		}, this));
		//fix for default value
		setTimeout(function () {
			operatorEl.data('kendoDropDownList').select(1);
		}, 100);
	},
	/*
	 * Handlerfunction for filtering severity by indicator
	 * @param {Object} e
	 */
	filterBySeverityIndicator: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = $('#' + this.container).data('kendoCustomGrid').dataSource.filter();
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: this.field, operator: operator, value: indicators[i]});
		}

		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}

			for (let i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters && !currentFilter.filters[i].filters.length) {
					currentFilter.filters.splice(i, 1);
				}
			}
			$('#' + this.container).data('kendoCustomGrid').dataSource.filter(currentFilter);
		} else {
			$('#' + this.container).data('kendoCustomGrid').dataSource.filter(filter);
		}
	},
	/**
	 * Handler function for service qualifier grid state filter
	 * @param {Object} element
	 */
	getFilterableForState: function (element) {
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(lang.assethealth.messages.SELECT_STATE);
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="contains"]').remove();
		operatorEl.find('option[value="startswith"]').remove();
		operatorEl.find('option[value="eq"]').text(lang.grid.filter.ISIN);
		operatorEl.find('option[value="neq"]').text(lang.grid.filter.ISNOTIN);
		operatorEl.attr('data-index', '1');
		var dropdown = element.kendoDropDownList({
			dataSource: [{
				text: lang.UP,
				color: 5,
				value: true
			}, {
				text: lang.DOWN,
				color: 1,
				value: false
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>'
		}).data("kendoDropDownList");
		menu.find('[type=submit]').on('click', {
			widget: dropdown,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterByStateIndicator, {
			scope: this.scope,
			dataSource: this.dataSource,
			field: this.field
		}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			dropdown.value([]);
		}, this));
		//fix for default value
		setTimeout(function () {
			operatorEl.data('kendoDropDownList').select(1);
		}, 100);
	},
	/*
	 * Handlerfunction for filtering state by indicator
	 * @param {Object} e
	 */
	filterByStateIndicator: function (e) {
		var indicator = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = this.dataSource.filter();
		var filter = {logic: logic, filters: []};

		filter.filters.push({field: this.field, operator: operator, value: indicator});
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.dataSource.filter(currentFilter);
		} else {

			this.dataSource.filter(filter);
		}
	},
	/**
	 * Handler function for clicking one of the acknowledge or unacknowledge options in menu item
	 */
	onAckUnack: function (e) {
		var url;
		var payload = {
			assetIds: [this.id],
			eventIds: []
		};
		var checkboxes = $('.cw_incident_check');
		$(checkboxes).each(function () {
			if ($(this).is(':checked')) {
				var eventId = $(this).attr('data-id');
				if (Utils.isGuid(eventId)) {
					payload.eventIds.push(eventId);
				}
			}
		});
		var target = $(e.currentTarget);
		if (target.attr('id') === 'cw_reason_acknowledge') {
			url = Settings.serverPath + 'accounts/' + this.accountIdContext + '/health/acknowledgeAssetHealthEvents';
		} else if (target.attr('id') === 'cw_reason_unacknowledge') {
			url = Settings.serverPath + 'accounts/' + this.accountIdContext + '/health/unacknowledgeAssetHealthEvents';
		}
		if (this.allReasonsGrid.dataSource.data().length) {
			Utils.ajax(url, 'POST', JSON.stringify(payload), $.proxy(function (result) {
				if (result.success) {
					this.actionNotification.setOptions({
						message: result.message,
						status: 'success'
					}).show();
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		}
	},
	/**
	 * Handler function for clicking one of the acknowledge or unacknowledge options in menu item
	 */
	onReasonsAckUnack: function (e) {
		var url;
		var payload = {
			assetIds: [this.id],
			eventIds: []
		};
		var reasonsGrid = $('#cw_assetdetails_reasons_list');
		var checkboxes = reasonsGrid.find('.cw_incident_check');
		$(checkboxes).each(function () {
			if ($(this).is(':checked')) {
				var eventId = $(this).attr('data-id');
				if (Utils.isGuid(eventId)) {
					payload.eventIds.push(eventId);
				}
			}
		});
		var target = $(e.currentTarget);
		if (target.attr('id') === 'cw_reason_acknowledge') {
			url = Settings.serverPath + 'accounts/' + this.accountIdContext + '/health/acknowledgeAssetHealthEvents';
		} else if (target.attr('id') === 'cw_reason_unacknowledge') {
			url = Settings.serverPath + 'accounts/' + this.accountIdContext + '/health/unacknowledgeAssetHealthEvents';
		}
		if (this.reasonsGrid.dataSource.data().length) {
			Utils.ajax(url, 'POST', JSON.stringify(payload), $.proxy(function (result) {
				if (result.success) {
					var url = this.getReasonUrl();

					Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
						this.reasonsList = result || [];
						var newDataSource = this.setAssetReasonsGridDatasource();
						this.reasonsGrid.setDataSource(newDataSource);
						this.healthFilterPanel.setGrid(this.reasonsGrid);
					}, this));

					this.actionNotification.setOptions({
						message: result.message,
						status: 'success'
					}).show();
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		}
	},
	/**
	 * Handler function for changing the all reasons grid data source
	 */
	setAllReasonsGridDatasource: function () {
		var existingSort = this.allReasonsGrid ? this.allReasonsGrid.dataSource.sort() : [];
		var existingFilter = this.allReasonsGrid ? this.allReasonsGrid.dataSource.filter() : [];
		return new CeeViewDataSource({
			data: this.reasonsList,
			schema: {
				model: {
					id: 'id',
					fields: {
						timestamp: {
							type: 'date'
						},
						incidentAssignedToName: {
							type: 'string'
						},
						monitorName: {
							type: 'string'
						},
						subsystem: {
							type: 'string'
						},
						incidentNumber: {
							type: 'string'
						},
						text: {
							type: 'string'
						},
						acknowledged: {
							type: 'boolean'
						},
						acknowledgedByName: {
							type: 'string'
						},
						assignedToName: {
							type: 'string',
							editable: false
						},
						information: {
							type: 'string',
							editable: false
						}
					}
				},
				parse: function (data) {
					for (var i = 0, length = data.length; i < length; i++) {
						data[i].timestamp = new Date(data[i].timestamp);
						if (data[i].assignedToType === 'ASSIGN_TEAM') {
							data[i].assignedToGlyph = 'parents';
							if (data[i].assignedTo) {
								data[i].assignedToMouseover = lang.TEAM + ': ' + data[i].assignedTo;
							} else {
								data[i].assignedToMouseover = lang.TEAM;
							}
						} else if (data[i].assignedToType === 'ASSIGN_USER') {
							data[i].assignedToGlyph = 'user';
							if (data[i].assignedTo) {
								data[i].assignedToMouseover = lang.USER + ': ' + data[i].assignedTo;
							} else {
								data[i].assignedToMouseover = lang.USER;
							}
						} else {
							data[i].assignedToGlyph = '';
							data[i].assignedToMouseover = '';
						}
						if (data[i].acknowledgedByName) {
							data[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + data[i].acknowledgedByName;
						} else {
							data[i].acknowledgedMouseover = '';
						}
					}
					return data;
				}
			},
			sort: existingSort,
			filter: existingFilter,
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Handler function for changing the all reasons grid data source
	 */
	setAssetReasonsGridDatasource: function () {
		var existingSort = this.reasonsGrid ? this.reasonsGrid.dataSource.sort() : [];
		var existingFilter = this.reasonsGrid ? this.reasonsGrid.dataSource.filter() : [];
		return new CeeViewDataSource({
			data: this.reasonsList,
			schema: {
				model: {
					id: 'id',
					fields: {
						severityIndex: {
							type: 'number'
						},
						acknowledged: {
							type: 'boolean'
						},
						acknowledgedByName: {
							type: 'string'
						},
						assignedToName: {
							type: 'string',
							editable: false
						},
						information: {
							type: 'string',
							editable: false
						}
					}
				},
				parse: function (data) {
					for (var i = 0, length = data.length; i < length; i++) {
						if (data[i].assignedToType === 'ASSIGN_TEAM') {
							data[i].assignedToGlyph = 'parents';
							if (data[i].assignedTo) {
								data[i].assignedToMouseover = lang.TEAM + ': ' + data[i].assignedTo;
							} else {
								data[i].assignedToMouseover = lang.TEAM;
							}
						} else if (data[i].assignedToType === 'ASSIGN_USER') {
							data[i].assignedToGlyph = 'user';
							if (data[i].assignedTo) {
								data[i].assignedToMouseover = lang.USER + ': ' + data[i].assignedTo;
							} else {
								data[i].assignedToMouseover = lang.USER;
							}
						} else {
							data[i].assignedToGlyph = '';
							data[i].assignedToMouseover = '';
						}
						if (data[i].acknowledgedByName) {
							data[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + data[i].acknowledgedByName;
						} else {
							data[i].acknowledgedMouseover = '';
						}
					}
					return data;
				}
			},
			sort: existingSort,
			filter: existingFilter
		});
	},
	/**
	 * Handler function for changing the reasons grid data source
	 * @param {String] url
	 */
	setReasonsGridDatasource: function (url, type, payload) {
		var existingSort = this.reasonsGrid ? this.reasonsGrid.dataSource.sort() : [];
		var existingFilter = this.reasonsGrid ? this.reasonsGrid.dataSource.filter() : [];

		return new CeeViewDataSource({
			transport: {
				read: {
					url: url,
					contentType: 'application/json; charset=utf-8',
					type: type || 'GET',
					dataType: 'json',
					cache: false
				},
				parameterMap: (data, type) => {
					data = payload;
					return kendo.stringify(data);
				}
			},
			schema: {
				model: {
					id: 'id',
					fields: {
						severityIndex: {
							type: 'number'
						},
						acknowledged: {
							type: 'boolean'
						},
						acknowledgedByName: {
							type: 'string'
						},
						assignedToName: {
							type: 'string',
							editable: false
						},
						information: {
							type: 'string',
							editable: false
						}
					}
				},
				parse: function (data) {
					for (var i = 0, length = data.length; i < length; i++) {
						if (data[i].assignedToType === 'ASSIGN_TEAM') {
							data[i].assignedToGlyph = 'parents';
							if (data[i].assignedTo) {
								data[i].assignedToMouseover = lang.TEAM + ': ' + data[i].assignedTo;
							} else {
								data[i].assignedToMouseover = lang.TEAM;
							}
						} else if (data[i].assignedToType === 'ASSIGN_USER') {
							data[i].assignedToGlyph = 'user';
							if (data[i].assignedTo) {
								data[i].assignedToMouseover = lang.USER + ': ' + data[i].assignedTo;
							} else {
								data[i].assignedToMouseover = lang.USER;
							}
						} else {
							data[i].assignedToGlyph = '';
							data[i].assignedToMouseover = '';
						}
						if (data[i].acknowledgedByName) {
							data[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + data[i].acknowledgedByName;
						} else {
							data[i].acknowledgedMouseover = '';
						}
					}
					return data;
				}
			},
			sort: existingSort,
			filter: existingFilter,
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Handler function for clicking assign option in grid menu
	 */
	onAssign: function (gridSelector) {
		let selectedCheckboxes = $('#' + gridSelector).find('.cw_incident_check:checked');
		let selectedEventIds = [];
		for (let i = 0; i < selectedCheckboxes.length; i++) {
			let eventId = $(selectedCheckboxes[i]).attr('data-id');
			if (Utils.isGuid(eventId)) {
				selectedEventIds.push(eventId);
			}
		}
		this.assignSelector = new AssignReasonsWindow({
			renderTo: gridSelector,
			selectedItems: selectedEventIds,
			actionNotification: this.actionNotification
		});
	},
	/**
	 * Handler function for clicking unassign option in grid menu
	 */
	async onUnassign(gridSelector) {
		var selectedCheckboxes = $('#' + gridSelector).find('.cw_incident_check:checked');
		var selectedEventIds = [];
		for (var i = 0; i < selectedCheckboxes.length; i++) {
			var eventId = $(selectedCheckboxes[i]).attr('data-id');
			if (Utils.isGuid(eventId)) {
				selectedEventIds.push(eventId);
			}
		}
		const result = await apiFetch(unassignLegacy({
			selection: {
				mode: 'INCLUDE',
				ids: selectedEventIds,
			}
		}));
		if (result.success) {
			this.actionNotification.setOptions({
				message: lang.messages.EVENT_SUCCESSFULLY_UNASSIGNED,
				status: 'success'
			}).show();
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}
	},
	onRemove: async function (gridSelector) {
		let grid = $('#' + gridSelector).data('kendoCustomGrid');
		let selectedCheckboxes = $('#' + gridSelector).find('.cw_incident_check:checked');
		let uids = [];
		let ids = [];

		for (let i = 0; i < selectedCheckboxes.length; i++) {
			let row = $(selectedCheckboxes[i].closest('tr'));
			let check = row.find('.cw_incident_check');
			let id = check.attr('data-id');
			let record = grid.dataSource.get(id);
			uids.push({
				id: record.id,
				accountId: record.accountId
			});
			ids.push(record.id);
		}

		if (!uids) {
			return;
		}
		var message = uids.length === 1 ? lang.messages.REASON_SUCCESSFULLY_REMOVED : lang.messages.REASONS_SUCCESSFULLY_REMOVED;

		const url = `${Settings.serverPath}accounts/${this.accountIdContext}/health/assetReasons/delete`;
		const {result} = await Utils.ajaxPromise(url, 'POST', JSON.stringify(uids));

		if (result.success) {
			this.actionNotification.setOptions({
				message: message,
				status: 'success'
			}).show();

			for (let i = 0; i < ids.length; i++) {
				var item = grid.dataSource.get(ids[i]);
				item.id = null;
			}
			grid.refresh();
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details, grid);
		}
	},
	onCreateFilter: function (gridSelector, e) {
		var dataSource;
		if (gridSelector === 'cw_all_reasons_grid') {
			dataSource = this.allReasonsGrid.dataSource;
		} else {
			dataSource = this.reasonsGrid.dataSource;
		}

		let check = $('#' + gridSelector).find('.cw_incident_check:checked');
		let id = check.attr('data-id');
		let record = dataSource.get(id);

		this.app.loadModule('AssetHealthFiltersConfiguration', '', {
			mode: 'create',
			resetHealthIndex: true,
			populate: {
				assetId: record.assetId,
				monitorId: record.monitorId,
				monitorType: record.monitorType,
				severity: record.severity,
				reasonDescription: record.text,
				reasonSubsytem: record.subsystem,
				identifierName: record.identifierName || '',
				identifierInstance: record.identifierInstance || '',
				accountId: record.accountId,
				accountName: record.accountName
			}
		}, e);
	},
	onResetHealthIndexButton: function (gridSelector) {
		let dataSource, grid;
		if (gridSelector === 'cw_all_reasons_grid') {
			dataSource = this.allReasonsGrid.dataSource;
			grid = this.allReasonsGrid;
		} else {
			dataSource = this.reasonsGrid.dataSource;
			grid = this.reasonsGrid;
		}
		var selector = $('#' + gridSelector);
		var checkboxes = selector.find('.cw_incident_check:checked');
		var data = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				let uid = $(checkboxes[i]).data('uid');
				let record = dataSource.getByUid(uid);
				data.push({
					monitorId: record.monitorId,
					assetId: record.assetId
				});
			}
		}
		Utils.resetMonitorsHI(data, $.proxy(function () {
			selector.find('.cw_incident_check').prop('checked', false);
			selector.find('.cw_incident_check_all').prop('checked', false);
			grid.dataSource.read();
		}, this));

	},
	onSetInformation(gridSelector) {
		let reasonsGridSelectorString = gridSelector;
		let reasonsGridSelector = $('#' + reasonsGridSelectorString);
		this.currentReasonGrid = reasonsGridSelector.data('kendoCustomGrid');
		let selectedCheckboxes = reasonsGridSelector.find('.cw_grid_check:checked');
		if (!selectedCheckboxes.length) {
			selectedCheckboxes = reasonsGridSelector.find('.cw_incident_check:checked');
		}
		this.selectedSetInformationIds = [];
		let id;
		for (let i = 0; i < selectedCheckboxes.length; i++) {
			id = $(selectedCheckboxes[i]).attr('data-id');
			if (id) {
				this.selectedSetInformationIds.push(id);
			}
		}

		$('.window_area').append('<div id="set-information-window-container"></div>');
		ReactDOM.render(<SetInformationWindow
			onClose={this.onSetInformationClose}
			onUpdate={(value) => this.onSetInformationUpdate(value)}
		></SetInformationWindow>, $('#set-information-window-container').get(0))
	},
	onSetInformationClose() {
		ReactDOM.unmountComponentAtNode($('#set-information-window-container').get(0));
	},
	async onSetInformationUpdate(informationValue) {
		const result = await apiFetch(setInformationLegacy({
			selection: {
				mode: 'INCLUDE',
				ids: this.selectedSetInformationIds,
			},
			information: informationValue
		}));
		if (result.success) {
			this.actionNotification.setOptions({
				message: lang.messages.INFO_SUCCESSFULLY_UPDATE,
				status: 'success'
			}).show();
			if (this.currentReasonGrid === this.allReasonsGrid) {
				this.populateReasonsGrid(this.allReasonsGrid);
			} else {
				this.populateHealthReasonsGrid();
			}
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}
		this.onSetInformationClose();
	},
	/**
	 * Handler function for the reasons grid click checkbox event
	 * @param {Object} e The databound event
	 */
	onIncidentCheck: function (e) {
		e.stopPropagation();
		var missingId = false;
		var reasonsGrid = $('#cw_all_reasons_grid');
		var checkboxes = reasonsGrid.find('.cw_incident_check');
		var counter = 0, uid, item, isReasoned = false;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				uid = $(checkboxes[i]).data('uid');
				item = this.allReasonsGrid.dataSource.getByUid(uid);
				isReasoned = item.inIncident;
				counter++;
				if (!item.hasEvent) {
					missingId = true;
				}
			}
		}
		if (counter === 1) {
			this.reasonsMenu.enableItem('cw_create_filter');
		} else {
			this.reasonsMenu.disableItem('cw_create_filter');
		}
		if (counter) {
			if (!isReasoned) {
				this.reasonsMenu.enableItem('cw_reason_create_incident');
			} else {
				this.reasonsMenu.disableItem('cw_reason_create_incident');
			}
			this.reasonsMenu.enableItem('cw_reasons_reset_hi');
			this.reasonsMenu.enableItem('cw_set_information');
			if (missingId) {
				this.reasonsMenu.disableItem('cw_create_filter');
			} else {
				this.reasonsMenu.enableItem('cw_reason_acknowledge');
				this.reasonsMenu.enableItem('cw_reason_unacknowledge');
				this.reasonsMenu.enableItem('cw_assign');
				this.reasonsMenu.enableItem('cw_unassign');
				this.reasonsMenu.enableItem('cw_delete_reason');
				this.reasonsMenu.enableItem('cw_reasons_reset_hi');
			}
		} else {
			this.reasonsMenu.disableItem('cw_reason_acknowledge');
			this.reasonsMenu.disableItem('cw_reason_unacknowledge');
			this.reasonsMenu.disableItem('cw_assign');
			this.reasonsMenu.disableItem('cw_unassign');
			this.reasonsMenu.disableItem('cw_delete_reason');
			this.reasonsMenu.disableItem('cw_reasons_reset_hi');
			this.reasonsMenu.disableItem('cw_reason_create_incident');
			this.reasonsMenu.disableItem('cw_set_information');
		}
	},
	/**
	 * Handler function for the reasons grid click checkbox event
	 * @param {Object} e The databound event
	 */
	onReasonsIncidentCheck: function (e) {
		e.stopPropagation();
		var missingId = false;
		var reasonsGrid = $('#cw_assetdetails_reasons_list');
		var checkboxes = reasonsGrid.find('.cw_incident_check');
		var counter = 0, uid, item, isReasoned = false;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				uid = $(checkboxes[i]).data('uid');
				item = this.reasonsGrid.dataSource.getByUid(uid);
				isReasoned = item.inIncident;
				counter++;
				if (!item.hasEvent) {
					missingId = true;
				}
			}
		}
		if (counter === 1) {
			this.assetDetailsReasonsMenu.enableItem('cw_create_filter');
		} else {
			this.assetDetailsReasonsMenu.disableItem('cw_create_filter');
		}
		if (counter) {
			if (!isReasoned) {
				this.assetDetailsReasonsMenu.enableItem('cw_reason_create_incident');
			} else {
				this.assetDetailsReasonsMenu.disableItem('cw_reason_create_incident');
			}
			this.assetDetailsReasonsMenu.enableItem('cw_reasons_reset_hi');
			this.assetDetailsReasonsMenu.enableItem('cw_set_information');
			if (missingId) {
				this.assetDetailsReasonsMenu.disableItem('cw_create_filter');
			} else {
				this.assetDetailsReasonsMenu.enableItem('cw_reason_acknowledge');
				this.assetDetailsReasonsMenu.enableItem('cw_reason_unacknowledge');
				this.assetDetailsReasonsMenu.enableItem('cw_assign');
				this.assetDetailsReasonsMenu.enableItem('cw_unassign');
				this.assetDetailsReasonsMenu.enableItem('cw_delete_reason');
				this.assetDetailsReasonsMenu.enableItem('cw_reasons_reset_hi');
			}
		} else {
			this.assetDetailsReasonsMenu.disableItem('cw_reason_acknowledge');
			this.assetDetailsReasonsMenu.disableItem('cw_reason_unacknowledge');
			this.assetDetailsReasonsMenu.disableItem('cw_assign');
			this.assetDetailsReasonsMenu.disableItem('cw_unassign');
			this.assetDetailsReasonsMenu.disableItem('cw_delete_reason');
			this.assetDetailsReasonsMenu.disableItem('cw_reasons_reset_hi');
			this.assetDetailsReasonsMenu.disableItem('cw_reason_create_incident');
			this.assetDetailsReasonsMenu.disableItem('cw_set_information');
		}

	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.allReasonsGrid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_all_reasons_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the reasons grid click all checkbox event
	 * @param {Object} e The databound event
	 */
	onIncidentCheckAll: function (e) {
		var reasonsGrid = $('#cw_all_reasons_grid');
		var checkAll = reasonsGrid.find('.cw_incident_check_all').is(':checked');
		var checkboxes = reasonsGrid.find('.cw_incident_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.reasonsMenu.enableItem('cw_reason_acknowledge');
			this.reasonsMenu.enableItem('cw_reason_unacknowledge');
			this.reasonsMenu.enableItem('cw_reasons_reset_hi');
			this.reasonsMenu.enableItem('cw_assign');
			this.reasonsMenu.enableItem('cw_unassign');
			this.reasonsMenu.enableItem('cw_delete_reason');
			this.reasonsMenu.enableItem('cw_reason_create_incident');
			this.reasonsMenu.enableItem('cw_set_information');
		} else {
			this.reasonsMenu.disableItem('cw_reason_acknowledge');
			this.reasonsMenu.disableItem('cw_reason_unacknowledge');
			this.reasonsMenu.disableItem('cw_reasons_reset_hi');
			this.reasonsMenu.disableItem('cw_assign');
			this.reasonsMenu.disableItem('cw_unassign');
			this.reasonsMenu.disableItem('cw_delete_reason');
			this.reasonsMenu.disableItem('cw_reason_create_incident');
			this.reasonsMenu.disableItem('cw_set_information');
		}
	},
	/**
	 * Handler function for the reasons grid click all checkbox event
	 * @param {Object} e The databound event
	 */
	onReasonsIncidentCheckAll: function (e) {
		var reasonsGrid = $('#cw_assetdetails_reasons_list');
		var checkAll = reasonsGrid.find('.cw_incident_check_all').is(':checked');
		var checkboxes = reasonsGrid.find('.cw_incident_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.assetDetailsReasonsMenu.enableItem('cw_reason_acknowledge');
			this.assetDetailsReasonsMenu.enableItem('cw_reason_unacknowledge');
			this.assetDetailsReasonsMenu.enableItem('cw_reasons_reset_hi');
			this.assetDetailsReasonsMenu.enableItem('cw_assign');
			this.assetDetailsReasonsMenu.enableItem('cw_unassign');
			this.assetDetailsReasonsMenu.enableItem('cw_delete_reason');
			this.assetDetailsReasonsMenu.enableItem('cw_reason_create_incident');
			this.assetDetailsReasonsMenu.enableItem('cw_set_information');
		} else {
			this.assetDetailsReasonsMenu.disableItem('cw_reason_acknowledge');
			this.assetDetailsReasonsMenu.disableItem('cw_reason_unacknowledge');
			this.assetDetailsReasonsMenu.disableItem('cw_reasons_reset_hi');
			this.assetDetailsReasonsMenu.disableItem('cw_assign');
			this.assetDetailsReasonsMenu.disableItem('cw_unassign');
			this.assetDetailsReasonsMenu.disableItem('cw_delete_reason');
			this.assetDetailsReasonsMenu.disableItem('cw_reason_create_incident');
			this.assetDetailsReasonsMenu.disableItem('cw_set_information');
		}
	},
	/**
	 * Handler function for the reasons grid data bound event
	 * @param {Object} e The databound event
	 */
	onAllReasonsDataBound: function (e) {
		var tooltip = $('#cw_all_reasons_grid').find('.k-grid-content').data('kendoTooltip');
		if (tooltip) {
			tooltip.destroy();
		}
		$('#cw_all_reasons_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: (event) => {
				const grid = $('#cw_assetdetails_reasons_list').data("kendoCustomGrid");
				if(grid) {
					let dataItem = grid.dataItem(event.target.closest('tr'));
					if (dataItem.assignedToMouseover && event.target.hasClass('assigned_type')) {
						return dataItem.assignedToMouseover;
					} else {
						return event.target.text().split("\n").join("<br />");
					}
				}
			}
		});

		let grid = e.sender;
		Utils.greyReason(grid);

		this.tooltipAllReasons = Renderer.errorToolTip($('#cw_all_reasons_grid'));
	},
	/**
	 * Handler function for the health reasons grid data bound event
	 * @param {Object} e The databound event
	 */
	onHealthReasonsDataBound: function (e) {
		var tooltip = $('#cw_assetdetails_reasons_list').find('.k-grid-content').data('kendoTooltip');
		if (tooltip) {
			tooltip.destroy();
		}
		$('#cw_assetdetails_reasons_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});

		let grid = e.sender;
		Utils.greyReason(grid);

		this.healthHistoryPointClicked = false;
		this.tooltipHealthReasons = Renderer.errorToolTip($('#cw_assetdetails_reasons_list'));
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: async function () {
		const loadUrl = AssetsApi.getAssetDetailsUrl(this.id);

		let result = await Api.fetch(loadUrl);
		if (!result.success) {
			Utils.showInfo(lang.ALERT, result.message, result.details);
			return;
		}

		Object.assign(this, result.data);
	},
	/**
	 * Updates user view based on permissions
	 */
	updateView: function () {
		if (!this.app.session.hasRole('MONITOR_LIST')) {
			//remove health tab
			$('#nav_health_tab').remove();
			$('.cw_healthtab_container').remove();
			//remove monitor tab
			$('#nav_monitor_tab').remove();
			$('.cw_monitortab_container').remove();
		}
		let url = Settings.managementPath + 'modules/APPLICATION_INVENTORY';
		let isInstalled = true;
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success && result.data) {
				isInstalled = result.data.installed;
			}
			if (!isInstalled || !this.externalType.includes('INVENTORY')) {
				$('#nav_inventory_tab').remove();
			} else {
				this.getInventoryData();
			}
		}, this));

	},
	/**
	 * Handler function for the Cancel button
	 */
	onCancel: function (e) {
		if (this.source === 'service_assets') {
			this.app.loadModule('AssetHealthSummary', '', {
				serviceId: this.serviceId,
				source: this.source,
				accountList: this.accountList
			}, e);
		} else {
			this.app.loadModule('AssetHealthSummary', '', {}, e);
		}
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		var status = $('.cw_assetdetails').find('.status');
		if (result.success) {
			//update title
			$('#cw_asset_details_title').text(this.accountNameContext + ' / ' + $('#asset_name').val());
			// update status
			status.find('p').addClass('success');
			status.find('p').text(lang.account.messages.ASSET_SUCCESS_UPDATED);
			this.saved = true;
			status.slideDown().delay(2000).slideUp(400);
			$('#cw_assetdetails_cancel').trigger('click');
		} else {
			status.find('p').addClass('error');
			status.find('p').text(result.message);
			status.slideDown().delay(2000).slideUp();
		}
	},
	/**
	 * Handler function e Update button
	 */
	onUpdate: async function () {
		let name = $('#asset_name').val();
		let nameExists = await Utils.checkIfNameExists(AssetsApi.getDynamicSearchUrl('false'), name, this.modalNotification, false, this.name)
		if(nameExists) {
			return;
		}
		// clear invalid
		$('#asset_name').parent().removeClass('required');
		// get groups
		var assetGroupsSelectedItems = $('#asset_group_control').data('kendoSortedMultiSelect').dataItems();
		var groups = [];
		if (assetGroupsSelectedItems.length) {
			for (var i = 0, length = assetGroupsSelectedItems.length; i < length; i++) {
				groups.push(assetGroupsSelectedItems[i].id);
			}
		}
		// const targets = this.newTargets?.length > 0 ? this.newTargets : this.targets;
		// for (var i = 0, length = targets.length; i < length; i++) {
		// 	targets[i].address = targets[i].name.trim();
		// }
		// update the reasonData list
		this.data = {
			id: this.id,
			name: $('#asset_name').val().trim(),
			description: this.descriptionHandler.value().trim(),
			systemType: $('#asset_system_type').data('kendoDropDownList').value(),
			assetGroups: (groups !== null) ? groups : [],
			targets: this.assetTargetsList,
			imageId: this.imageId,
			iconPack: this.iconPack,
			tags: this.tagsList,
			information: this.informationHandler.value().trim(),
			calendar: this.assetMaintenanceView?.icalendar ?? this.calendar,
			maintenanceCalendars: this.assetMaintenanceView?.calendars ?? this.maintenanceCalendars,
			manualGeotag: this.manualGeotag
		};
		var status = $('.cw_assetdetails').find('.status');
		if (this.data.name.trim() === '') {
			$('#asset_name').parent().addClass('required');
			status.find('p').text(lang.account.messages.ASSET_INPUT_NAME);
			status.find('p').addClass('error');
			status.slideDown().delay(4000).slideUp();
		} else {
			var url = Settings.serverPath + 'accounts/' + this.accountId + '/assets';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * @private
	 * @param {Array} indicators
	 */
	populateHealthIndicators: function (indicators) {
		var incidentWidget = $('#cw_incident_widget');
		// Incident
		var incidents = indicators.incidents || {};

		if (incidents.open) {
			var width = {
				incidents: {
					high: incidents.high * 100 / incidents.open,
					medium: incidents.medium * 100 / incidents.open,
					low: incidents.low * 100 / incidents.open
				}
			};
			incidentWidget.find('.value').text(incidents.open);
			if (width.incidents.low !== 0) {
				incidentWidget.find('.content').removeClass().addClass('content bg_color3');
			}
			if (width.incidents.medium !== 0) {
				incidentWidget.find('.content').removeClass().addClass('content bg_color2');
			}
			if (width.incidents.high !== 0) {
				incidentWidget.find('.content').removeClass().addClass('content bg_color1');
			}
			if (this.app.session.hasRole('INCIDENT_LIST')) {
				incidentWidget.off().on('click', $.proxy(this.onIncidentWidgetClick, this));
				incidentWidget.addClass('pointer');
				incidentWidget.find('.critical').css('width', width.incidents.high + '%').attr('title', lang.widget.messages.TOTAL_HIGH_INCIDENTS + ' ' + incidents.high);
				incidentWidget.find('.major').css('width', width.incidents.medium + '%').attr('title', lang.widget.messages.TOTAL_MEDIUM_INCIDENTS + ' ' + incidents.medium);
				incidentWidget.find('.minor').css('width', width.incidents.low + '%').attr('title', lang.widget.messages.TOTAL_LOW_INCIDENTS + ' ' + incidents.low);
			}
		}

		var currentMonitors = indicators.monitors;
		var monitorNameByType = Configuration.installedMonitorsNames;
		var monitorsDiv = $('.cw_monitorwidgets_wrapper');
		monitorsDiv.empty();

		for (var i = 0; i < currentMonitors.length; i++) {
			var currentMonitorType = currentMonitors[i].type;
			var monitorTemplate = '';
			monitorTemplate += '<div id="' + currentMonitors[i].type + '" monitor-type="' + currentMonitors[i].monitorType + '" data-uid="' + currentMonitors[i].id + '" class="cw_state_widget">';
			monitorTemplate += '<div class="content bg_color6">';
			let hasConsole = false;
			if (currentMonitors[i].monitorType === 'SYSTEM') {
				hasConsole = true;
				monitorTemplate += '<span class="glyphicons target cw_asset_console"></span>';
				monitorTemplate += '<span class="glyphicons exclamation-sign hide"></span>';
			}
			if (currentMonitors[i].monitorType === 'IBMSVC') {
				hasConsole = true;
				monitorTemplate += '<span class="glyphicons target cw_asset_console"></span>';
			}
			if (currentMonitors[i].dataUnavailable) {
				monitorTemplate += '<span class="glyphicons exclamation-sign" title="' + lang.account.messages.MONITOR_ERROR_UNAVAILABLE + '"></span>';
			}
			if (currentMonitors[i].monitorError) {
				monitorTemplate += '<span data-monitorerror="true" class="glyphicons exclamation-sign"></span>';
			}
			if (currentMonitors[i].monitorMaintenance) {
				let monitorMaintenanceStyle = hasConsole ? 'font-size: 23px; right: 30px;': 'font-size: 23px;';
				monitorTemplate += '<span class="glyphicons wrench" title="' + lang.account.messages.MONITOR_MAINTENANCE + '" style="' + monitorMaintenanceStyle + '"></span>';
			}
			monitorTemplate += '<div class="vertical">';
			monitorTemplate += '<span class="value">0</span>';
			monitorTemplate += '</div>';
			monitorTemplate += '<div class="title">' + (monitorNameByType[currentMonitorType] || currentMonitorType) + '</div>';
			monitorTemplate += '</div>';
			monitorTemplate += '</div>';

			if (!($('#' + currentMonitors[i].type).length)) {
				monitorsDiv.append(monitorTemplate);
			}
			var widget = $('#' + currentMonitors[i].type);
			$('.exclamation-sign').off().on('mouseover', $.proxy(function (e) {
				var currentTarget = $(e.currentTarget);
				var target = currentTarget.parent().parent();
				var monitorId = target.attr('data-uid');
				var monitorType = target.attr('monitor-type');
				if ($(target).data("kendoTooltip")) {
					$(target).data("kendoTooltip").destroy();
				}
				Utils.onMonitorErrorOver(e, {
					accountId: this.accountId,
					assetId: this.id,
					monitorId: monitorId,
					monitorType: monitorType,
					dataSource: this.allReasonsGrid.dataSource,
					toolTip: $(target).kendoTooltip({
						filter: '[data-monitorerror="true"]',
						autoHide: true,
						position: 'bottom',
						show: function (e) {
							e.sender.popup.element.addClass('warning_tooltip');
						}
					}).data("kendoTooltip")
				})
			}, this));

			if (currentMonitors[i].healthIndex > -1) {
				widget.find('.content').removeClass().addClass('content ' + Renderer.stateWidgetColorClass(currentMonitors[i].healthIndex));
				widget.find('.value').text(currentMonitors[i].healthIndex);
				if (this.app.session.hasRole('MONITOR_LIST')) {
					widget.addClass('pointer');
					widget.attr('data-type', currentMonitors[i].type);
					widget.off().on('click', $.proxy(this.onMonitorWidgetClick, this));
					widget.find('.cw_asset_console').off().on('click', $.proxy(this.onAssetConsole, this));
				}
			} else {
				widget.addClass('hide');
			}
		}

		if (this.assetMaintenance) {
			monitorsDiv.find('.content').removeClass().append(`<span title="${lang.account.messages.ASSET_MAINTENANCE}"></span>`).addClass('content bg_color6');
		}

		// header indicator
		$('.cw_assethealth_details .cw_section_titlebar').find('.cw_status_indicator').remove();

		this.monitorMaintenance = indicators.monitorMaintenance;
		$('#cw_asset_details_menu').after(Renderer.assetStatus(indicators.assetHI, this.agentStatus, this.monitorError, this.assetMaintenance, indicators.monitorDataUnavailable, indicators.monitorMaintenance));

		//that is a dirty hach to check if there is a possible event to show popup about.
		//It relies on the fact that an icon will be rendered on a line above if there is something
		if(document.querySelector('.cw_section_title .cw_status_indicator').hasChildNodes()){
			this.indicatorTooltip = $('.cw_section_title .cw_status_indicator').kendoTooltip({
				autoHide: true,
				position: 'bottom',
				show: e => {
					e.sender.popup.element.addClass('warning_tooltip');
				}
			}).data("kendoTooltip");

			$('.cw_section_title .cw_status_indicator').on('mouseover', $.proxy(function (e) {
				const target = $(e.target)
				const mouseoverUrl = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/health/errorReasons';
				setMonitorErrorTooltipText(this.indicatorTooltip, this, mouseoverUrl, target);
			}, this));
		}
	},
	populateReasonsGrid: function(grid) {
		//Reasons list
		if (grid) {
			var url = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/reasons';
			Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
				this.reasonsList = result || [];
				var newDataSource = this.setAllReasonsGridDatasource();
				grid.setDataSource(newDataSource);
				if(grid === this.reasonsGrid) {
					this.healthFilterPanel.setGrid(grid);
				} else if(grid === this.allReasonsGrid) {
					this.summaryFilterPanel.setGrid(grid);
				}
			}, this));
		}
	},
	populateHealthReasonsGrid() {
		if (this.reasonsGrid.dataSource.transport.options) {
			this.reasonsGrid.dataSource.read();
		} else {
			this.populateReasonsGrid(this.reasonsGrid);
		}
	},
	/**
	 * @private
	 * @param {array} indicators
	 */
	populateServicesIndicators: function (indicators) {
		var servicesWidget = $('#cw_services_widget'), sqStateWidget = $('#cw_sq_state_widget');
		indicators.servicesUp = indicators.numberOfServices - indicators.servicesDown;					// Services
		if (indicators.numberOfServices) {
			if (indicators.servicesUp === 0 || indicators.servicesDown === 0) {
				servicesWidget.find('.value').text(indicators.numberOfServices);
			} else {
				servicesWidget.find('.value').html(indicators.servicesDown + ' / ' + '<span class="total">' + indicators.numberOfServices + '</span>');
			}
			servicesWidget.find('.subtitle').text(lang.assethealth.LAST_CHANGE + ': ' + Renderer.browserDateRenderer(indicators.lastStateChange, 'datetime'));
			servicesWidget.find('.content').removeClass().addClass('content ' + (indicators.serviceState ? 'bg_color5' : 'bg_color1'));
			if (this.app.session.hasRole('SERVICE_LIST')) {
				servicesWidget.off().on('click', $.proxy(this.onServicesWidgetClick, this));
				servicesWidget.addClass('pointer');
			}
		} else {
			servicesWidget.find('.content').removeClass().addClass('content bg_color6');
		}
		//Qualifiers
		var totalQualifiers = indicators.qualifiers.length;
		if (totalQualifiers) {
			var upQualifiers = 0;
			for (var i = 0; i < totalQualifiers; i++) {
				if (indicators.qualifiers[i].state) {
					upQualifiers++;
				}
			}
			sqStateWidget.find('.content').removeClass().addClass('content ' + (totalQualifiers === upQualifiers ? 'bg_color5' : 'bg_color1'));
			if (totalQualifiers !== upQualifiers && upQualifiers !== 0) {
				sqStateWidget.find('.value').html('' + (totalQualifiers - upQualifiers) + ' / ' + '<span class="total">' + totalQualifiers + '</span>');
			} else {
				sqStateWidget.find('.value').html('<span class="value">' + totalQualifiers + '</span>');
			}
			sqStateWidget.addClass('pointer');
			sqStateWidget.off().on('click', $.proxy(this.onQualifiersWidgetClick, this));
		}
		if (this.assetMaintenance) {
			sqStateWidget.find('.content').removeClass().addClass('content bg_color6');;
		}
	},
	/**
	 * Populates the indicator widgets
	 * @param {Object} indicators
	 */
	populateIndicators: function (indicators) {
		this.populateHealthIndicators(indicators);
		this.populateReasonsGrid(this.allReasonsGrid);
		this.populateServicesIndicators(indicators);
		this.alignDefaultWidgetsHeight();
	},

	alignDefaultWidgetsHeight() {
		let maxHeight = 0;
		let widgetContents = $('.cw_default_state_widget .vertical');
		widgetContents.each(function() {
			let height = $(this).height();
			if (height > maxHeight) {
				maxHeight = height;
			}
		});
		widgetContents.css('height', maxHeight);
	},

	/**
	 * Handler function for the click event on the Monitor widgets
	 * @param {Object} e
	 */
	onMonitorWidgetClick: function (e) {
		this.loadCurrentReasons = true;
		//TODO: add permission
		const selectedType = $(e.currentTarget).data('type');

		this.monitorTypeSelector.value(selectedType);
		this.monitorTypeSelector.trigger('change');

		this.tabStrip.activateTab($('#nav_health_tab'));
	},
	/**
	 * Handler function for the click event on the Services widget
	 * @param {Object} e
	 */
	onServicesWidgetClick: function (e) {
		State.mainApp.navigate(ServicesRouter.list({
			assetId: this.id
		}));
	},
	/**
	 * Handler function for the click event on the Monitor widgets
	 * @param {Object} e
	 */
	onQualifiersWidgetClick: function (e) {
		this.tabStrip.activateTab($('#nav_state_change'));
	},
	/**
	 * Handler function for the click event on the incident widget
	 * @param {Object} e
	 */
	onIncidentWidgetClick: function (e) {
		e.stopPropagation();
		var target = $(e.target);

		var options = {
			assetId: this.id
		};

		if (target.hasClass('label')) {
			return;
		}
		if (target.hasClass('critical')) {
			options.impact = 'HIGH';
		} else if (target.hasClass('major')) {
			options.impact = 'MEDIUM';
		} else if (target.hasClass('minor')) {
			options.impact = 'LOW';
		}
		options.isFromAssetDetails = true;

		this.app.navigate(IncidentsRouter.list(), {
			extra: options
		});
	},
	/**
	 * Gets the reasons chart data
	 */
	getReasonsChartData: function () {
		// NOTE this is where the history chart begins to render
		var loadUrl = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/healthIndexes/';
		if (this.hasCustomTime) {
			loadUrl += 'fromTime/' + this.reasonsFromTime + '/toTime/' + this.reasonsToTime + '?timeZone=' + Cookies.CeesoftTimezone;
		} else {
			loadUrl += 'last' + this.chartEntries + '';
		}
		if (this.chartType !== 'ALL') {
			if (this.hasCustomTime) {
				loadUrl += '&monitorType=' + this.chartType;
			} else {
				loadUrl += '?monitorType=' + this.chartType;
			}
		}
		// loadUrl += '&timeSelector=' + this.chartPeriod;
		// add loading mask
		kendo.ui.progress($('#cw_reasons_chart'), true);
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success !== undefined && !result.success) {
				$('#cw_reasons_chart').empty().append('<div class="cw_section_placeholder"><span class="glyphicons status_icon exclamation-sign"></span><span class="cw_placeholder_message">' + result.message + '</span></div>');
			} else {
				this.reasonsChartData = [];
				this.resultList = result;

				for (var i = 0, length = result.length; i < length; i++) {
					var seriesItem = {};
					var name = result[i].monitorName;
					seriesItem.name = name.length > 32 ? name.substr(0, 29) + '...' : name;
					seriesItem.monitorId = result[i].monitorId;
					seriesItem.agentId = result[i].agentId;
					seriesItem.agentName = result[i].agentName;
					switch (result[i].monitorType) {
						case 'SYSTEM':
							seriesItem.color = Constants.ORANGE;
							break;
						case 'NIMSOFT':
							seriesItem.color = Constants.BLUE;
							break;
						case 'PROCESSES':
							seriesItem.color = Constants.CYAN;
							break;
						case 'WINSERVICE':
							seriesItem.color = Constants.MAGENTA;
							break;
						case 'EXTERNAL':
							seriesItem.color = Constants.GREEN;
							break;
						case 'WINEVENTS':
							seriesItem.color = Constants.YELLOW;
							break;
						case 'NAGIOS':
							seriesItem.color = Constants.CUSTOM2;
							break;
						case 'PRTG':
							seriesItem.color = Constants.BLACK;
							break;
						case 'VMWARE':
							seriesItem.color = Constants.CUSTOM1;
							break;
						case 'SNMP':
							seriesItem.color = Constants.CUSTOM3;
							break;
						case 'HYPERV':
							seriesItem.color = Constants.CUSTOM4;
							break;
						case 'VMM':
							seriesItem.color = Constants.CUSTOM5;
							break;
						case 'MSCLUSTER':
							seriesItem.color = Constants.CUSTOM6;
							break;
						case 'IBMSVC':
							seriesItem.color = Constants.CUSTOM7;
							break;
						case 'MAILGTW':
							seriesItem.color = Constants.CUSTOM8;
							break;
					}
					seriesItem.visible = this.clickedMonitors[seriesItem.name] ? false : true;
					seriesItem.data = [];
					if (!this.aggregationType) {
						this.aggregationType = this.assetDetailsAggregationType?.value() ?? "high";
					}
					seriesItem.dataGrouping = {
						approximation: this.aggregationType
					};

					for (var j = 0, lng = result[i].metrics.length; j < lng; j++) {
						seriesItem.data.push([result[i].metrics[j].timestamp, result[i].metrics[j].value]);
					}
					this.reasonsChartData.push(seriesItem);
				}
				this.buildReasonsChart();
			}
		}, this));
	},
	getInventoryData: function () {
		let url = Settings.serverPath + 'accounts/' + this.accountIdContext + '/assets/' + this.id + '/inventory';
		Utils.ajax(url, 'GET', {}, $.proxy(async function (result) {
			if (result.success) {
				this.inventoryData = result.data;
				await this.populateInventoryTab();
				$('.cw_inventory_data').removeClass('hide');
			} else {
				$('#cw_no_inventory_data').removeClass('hide');
			}
		}, this));
	},
	populateInventoryTab: async function () {
		let properties = this.inventoryData.properties;
		let filterMessages = lang.grid.filter;
		$('#inventory_name').text(properties.name);
		$('#inventory_domain').text(properties.domain);
		$('#inventory_type').text(properties.type);
		$('#inventory_bios_date').text(properties.biosDate);
		$('#inventory_bios_description').text(properties.biosDescription);
		$('#inventory_manufacturer').text(properties.manufacturer);
		$('#inventory_model').text(properties.model);
		$('#inventory_identifier').text(properties.identifier);
		$('#inventory_product_uui').text(properties.productUUID);
		$('#inventory_os_name').text(properties.osName);
		$('#inventory_os_version').text(properties.osVersion);
		$('#inventory_os_csd_version').text(properties.osCSDVersion);
		$('#inventory_os_serial_number').text(properties.osSerialNumber);
		$('#inventory_os_install_date').text(properties.osInstallDate);
		$('#inventory_os_last_boot_up_time').text(properties.osLastBootUpTime);
		$('#inventory_os_local_date_time').text(properties.osLocalDateTime);
		$('#inventory_username').text(properties.userName);
		$('#inventory_primary_owner_name').text(properties.primaryOwnerName);
		$('#inventory_total_physical_memory').text(properties.totalPhysicalMemory);
		$('#inventory_register_time').text(Renderer.browserDateRenderer(properties.registerTime, Constants.DATETIME));

		this.inventoryDefaultColumns = {
			name: {
				hidden: false,
				width: 350
			},
			vendor: {
				hidden: false,
				width: 200
			},
			version: {
				hidden: false,
				width: 200
			},
			installDate: {
				hidden: false,
				width: 200
			},
			registerTime: {
				hidden: false
			}
		};

		let inventorySort, inventoryFilter;
		let defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			let pref = UserPrefs.get(defaultFsView);
			pref = pref || {
				sort: [],
				filter: []
			};
			inventorySort = pref.sort;
			inventoryFilter = pref.filter;
			this.inventoryColumns = pref.columns;
		} else {
			const ahInventorySort = findUserPref(this.userPref, 'ahInventorySort');
			const ahInventoryFilter = findUserPref(this.userPref, 'ahInventoryFilter');
			if (ahInventorySort) {
				inventorySort = JSON.parse(ahInventorySort);
			} else {
				inventorySort = [{
					field: 'name',
					dir: 'asc'
				}];
			}
			if (ahInventoryFilter) {
				inventoryFilter = JSON.parse(ahInventoryFilter);
			} else {
				inventoryFilter = [];
			}
		}

		const ahInventoryColumns = findUserPref(this.userPref, 'ahInventoryColumns');
		let parsedColumns = ahInventoryColumns ? JSON.parse(ahInventoryColumns) : undefined;
		let columns = this.inventoryColumns || parsedColumns || this.inventoryDefaultColumns;
		columns = Utils.completeColumns(columns, this.inventoryDefaultColumns);
		this.inventoryProductsGrid = $('#cw_inventory_products').kendoCustomGrid({
			dataSource: new CeeViewDataSource({
				data: this.inventoryData.products,
				sort: inventorySort,
				filter: inventoryFilter
			}),
			autoBind: true,
			selectable: 'row',
			resizable: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			columns: Utils.rearrangeColumns([{
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				attributes: {
					"class": "tooltip ellipsis to_expand"
				},
				width: columns.name.width,
				hidden: columns.name.hidden
			}, {
				field: 'vendor',
				title: lang.VENDOR,
				sortable: true,
				filterable: true,
				attributes: {
					"class": "tooltip ellipsis to_expand"
				},
				width: columns.vendor.width,
				hidden: columns.vendor.hidden
			},{
				field: 'version',
				title: lang.VERSION,
				sortable: true,
				filterable: true,
				attributes: {
					"class": "tooltip ellipsis to_expand"
				},
				width: columns.version.width,
				hidden: columns.version.hidden
			},{
				field: 'installDate',
				title: lang.INSTALL_DATE,
				sortable: true,
				filterable: false,
				template: '#= Renderer.browserDateRenderer(installDate, Constants.DATETIME)#',
				width: columns.installDate.width,
				hidden: columns.installDate.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'registerTime',
				title: lang.REGISTER_TIME,
				sortable: true,
				filterable: true,
				template: '#= Renderer.browserDateRenderer(installDate, Constants.DATETIME)#',
				attributes: {
					"class": "tooltip ellipsis to_expand"
				},
				hidden: columns.registerTime.hidden
			}], columns),
			change: $.proxy(function () {
				let selectedRow = this.inventoryProductsGrid.select();
				let myRow = selectedRow[0];
				let messageEl = $(myRow).find('.to_expand');
				if ($(messageEl).hasClass('cw_message_expanded')) {
					$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
				} else {
					$('#cw_inventory_products').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
					$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
				}
			}, this),
			columnMenu: true
		})
		.data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.inventoryProductsGrid);

		const defaultInventoryFsView = findUserPref(this.userPref, 'defaultInventoryFsView');
		const ahInventorySearchPhrase = findUserPref(this.userPref, 'ahInventorySearchPhrase');
		let searchValue = defaultInventoryFsView ? '' : (ahInventorySearchPhrase || '');
		this.inventoryFilterPanel = new PreferencesPanel({
			renderTo: 'cw_inventory_filters',
			grid: this.inventoryProductsGrid,
			modulePrefName: 'AssetDetailsInventory',
			defaultPrefViewKey: 'defaultInventoryFsView',
			prefListKey: 'ahInventoryFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'vendor', 'version'],
			searchValue: searchValue,
			defaultColumns: this.inventoryProductsGrid.columns,
			category: 'AssetDetailsInventory',
		});
		this.inventoryProductsGrid.dataSource.fetch();
	},
	/**
	 * Creates the reasons chart
	 */
	buildReasonsChart: function () {
		var exporting = {};
		// pass default configurations
		Utils.apply(exporting, Configuration.highcharts.exporting);
		// remove mask
		kendo.ui.progress($('#cw_reasons_chart'), false);
		$('#cw_reasons_chart').empty();

		this.reasonsChart = new Highcharts.Chart({
			chart: {
				renderTo: $('#cw_reasons_chart')[0],
				type: 'line',
				zoomType: 'x',
				backgroundColor: 'transparent',
				events: {
					selection: (event) => {
						if (event.resetSelection) {
							//reset zoom
							this.resetReasonsGrid();
						} else {
							//zoom in
							this.renderHistoricEventsFilter();
							let fromTime = parseInt(event.xAxis[0].min);
							let toTime = parseInt(event.xAxis[0].max);
							let selectedData = this.getReasonsChartSelectedData(fromTime, toTime);
							let url = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/healthIndexes/fromTime/' + fromTime + '/toTime/' + toTime + '/reasons';
							var newDatasource = this.setReasonsGridDatasource(url, 'POST', selectedData);
							this.reasonsGrid.setDataSource(newDatasource);
						}
					}
				}
			},
			title: {
				text: ' '
			},
			legend: {
				borderWidth: 0,
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				useHTML: true,
				labelFormatter: function () {
					return `<span title=${this.userOptions.name}/${this.userOptions.agentName}>${this.userOptions.name}</span>`;
				}
			},
			credits: {
				enabled: false
			},
			exporting: exporting,
			xAxis: {
				type: 'datetime',
				labels: {
					staggerLines: 1
				},
				dateTimeLabelFormats: {
					millisecond: '%H:%M:%S',
					second: '%H:%M:%S',
					minute: '%H:%M',
					hour: '%H:%M',
					day: '%e. %b',
					week: '%e. %b',
					month: '%b \'%y',
					year: '%Y'
				}
			},
			yAxis: {
				title: {
					text: null
				},
				min: 0,
				max: 100
			},
			tooltip: {
				crosshairs: true,
				snap: 1,
				backgroundColor: 'white',
				borderWidth: 0,
				shadow:{
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				formatter: function (e) {
					var s = Renderer.browserDateRenderer(this.x, Constants.DATETIME);
					var v = '<br />' + this.series.name + ': ' + this.y;
					return s + v;
				}
			},
			plotOptions: {
				line: {
					events: {
						click: $.proxy(this.onHealthHistoryPoint, this),
						legendItemClick: $.proxy(this.onHealthHistoryLegend, this)
					}
				}
			},
			series: this.reasonsChartData
		});

		this.reasonsChartVisibleSeries = this.reasonsChart.series;
	},
	onHealthHistoryLegend: function (e) {
		var clickedMonitor = e.target.name;
		this.monitorTypeSelector.text(lang.CUSTOM);
		this.clickedMonitors[clickedMonitor] = 1;
		setTimeout($.proxy(function() {
			this.reasonsChartVisibleSeries = this.getReasonsChartVisibleSeries();

			let reasonsGridData = this.reasonsGrid.dataSource.data().toJSON();
			let newReasonsGridData = [];
			for (let reasonsGridItem of reasonsGridData) {
				let itemVisible = false;
				for (let reasonsChartSerie of this.reasonsChartVisibleSeries) {
					if (reasonsGridItem.agentId === reasonsChartSerie.userOptions.agentId && reasonsGridItem.monitorId === reasonsChartSerie.userOptions.monitorId) {
						itemVisible = true;
					}
				}
				if (itemVisible) {
					newReasonsGridData.push(reasonsGridItem);
				}
			}
		}, this));
	},

	getReasonsChartVisibleSeries() {
		let visibleSeries = [];
		let series = this.reasonsChart.series;
		for (let serie of series) {
			if (serie.visible) {
				visibleSeries.push(serie);
			}
		}
		return visibleSeries;
	},

	getReasonsChartSelectedData(fromTime, toTime) {
		let series = this.reasonsChart.series;
		let seriesData = [];
		for (let serie of series) {
			if (serie.xData && serie.xData.length && serie.visible) {
				let seriesMin = serie.xData[0];
				let seriesMax = serie.xData[serie.xData.length - 1];
				if (seriesMin < fromTime < seriesMax || seriesMin < toTime < seriesMax) {
					let serieData = {
						agentId: serie.userOptions.agentId,
						monitorId: serie.userOptions.monitorId
					};
					seriesData.push(serieData);
				}
			}
		}
		return seriesData;
	},

	/**
	 * Handler for the click event on a history chart point
	 * @param {Object} e The event
	 */
	onHealthHistoryPoint: function (e) {
		let timeStamp = e.point.options.x;
		let monitorId = e.point.series.options.monitorId;
		let agentName = e.point.series.options.agentName;
		let agentId = e.point.series.options.agentId;
		let serieArray = null;
		for (var i = 0, length = this.resultList.length; i < length; i++) {
			if (this.resultList[i].monitorId === monitorId && this.resultList[i].agentId === agentId) {
				serieArray = this.resultList[i].metrics;
				break;
			}
		}
		if (serieArray) {
			var point = Utils.getFromJsonArray(serieArray, 'timestamp', timeStamp);
			if (point) {
				this.healthHistoryPointClicked = true;
				var url = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/healthIndexes/' + point.id + '/reasons';
				let payloadObj = {
					monitorId: point.monitorId,
					agentId: point.agentId
				};
				var newDatasource = this.setReasonsGridDatasource(url, 'POST', payloadObj);
				this.reasonsGrid.setDataSource(newDatasource);
			} else {
				this.reasonsGrid.setDataSource(new CeeViewDataSource({
					data: []
				}));
			}
			this.renderHistoricEventsFilter();
			this.healthFilterPanel.setGrid(this.reasonsGrid);
		}
	},

	getReasonUrl: function() {
		let url;

		this.chartType = this.monitorTypeSelector.value();

		if (this.chartType !== 'ALL') {
			url = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/monitors/reasons/types/' + this.chartType + '';
		} else {
			url = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/monitors/reasons';
		}

		return url;
	},

	/**
	 * Handler function for the chart type selector
	 * @param {Object} e The event
	 */
	onMonitorTypeChange: function (e) {
		var url, sender = e.sender, data = sender.dataSource.data(),
			text = Utils.getFromJsonArray(data, 'value', sender.value()).text;
		sender.text(text);

		this.clickedMonitors = [];

		var newDatasource = this.setReasonsGridDatasource(this.getReasonUrl());
		this.reasonsGrid.setDataSource(newDatasource);
		this.healthFilterPanel.setGrid(this.reasonsGrid);
		this.reasonsGrid.refresh();
		this.getReasonsChartData();
	},
	/**
	 * Handler function for the chart number of entries selector
	 * @param {Object} e The event
	 */
	onEntriesSelectorChange: function (e) {
		var list = e.sender;
		var data = list.dataSource.data();
		if (e.item) {
			var value = Utils.getFromJsonArray(data, 'text', e.item.text()).value;
			this.chartEntries = value;
		} else {
			var text = Utils.getFromJsonArray(data, 'value', list.value()).text;
			list.text(text);
			this.chartEntries = list.value();
		}

		this.hasCustomTime = false;
		if (this.chartEntries !== 0) {
			this.getReasonsChartData();
		}
	},

	onAggregationTypeChange: function() {
		this.aggregationType = this.assetDetailsAggregationType.value();
		this.getReasonsChartData();
	},

	/**
	 * Opens the custom time selector
	 */
	onOpenCustomTime: function () {

	},
	/**
	 * Handler for the change event of the Start date picker
	 * @param {Object} e The event
	 */
	startChange: function (e) {
		var startDate = this.reasonsStartDate.value(),
			endDate = this.reasonsEndDate.value();

		if (startDate) {
			startDate = new Date(startDate);
			startDate.setDate(startDate.getDate());
			this.reasonsEndDate.min(new Date(startDate.getTime() + 15 * 60 * 1000));
		} else if (endDate) {
			this.reasonsStartDate.max(new Date(endDate.getTime() - 15 * 60 * 1000));
		} else {
			endDate = new Date(kendo.date.today().getTime() + 15 * 60 * 1000);
			this.reasonsStartDate.max(endDate);
			this.reasonsEndDate.min(endDate);
		}
	},
	/**
	 * Handler for the change event of the End date picker
	 * @param {Object} e The change event object
	 */
	endChange: function (e) {
		var startDate = this.reasonsStartDate.value(),
			endDate = this.reasonsEndDate.value();

		if (endDate) {
			endDate = new Date(endDate);
			endDate.setDate(endDate.getDate());
			this.reasonsStartDate.max(new Date(endDate.getTime() - 15 * 60 * 1000));
		} else if (startDate) {
			this.reasonsEndDate.min(new Date(startDate.getTime() + 15 * 60 * 1000));
		} else {
			endDate = new Date(kendo.date.today().getTime() + 15 * 60 * 1000);
			this.reasonsStartDate.max(endDate);
			this.reasonsEndDate.min(endDate);
		}
	},
	/**
	 * Handler function for the chart period selector
	 * @param e
	 * @deprecated
	 */
	onPeriodSelector: function (e) {
		var target = $(e.currentTarget);
		this.chartPeriod = target.find('input').val();
		target.parent().find('.is_selected').removeClass('is_selected');
		target.addClass('is_selected');
		this.getReasonsChartData();
	},
	/**
	 * Handler function for the click event on asset type
	 * @param {type} e
	 */
	onAssetTypeClick: function (e) {
		this.app.loadModule('ManagementAgents', null, {}, e);
	},
	/**
	 * Handler function for the click event on qualifier
	 * @param {type} e
	 */
	onQualifierClick: function (e) {
		var qualifierId = $(e.currentTarget).attr('data-id');
		var record = this.qualifiersGrid.dataSource.get(qualifierId);
		this.app.breadcrumb.currentItem.config.tabId = 'nav_state_change';
		this.app.breadcrumb.currentItem.config.highlightQualifierId = qualifierId;
		this.app.navigate(ServicesRouter.viewer(record.serviceId, {
			serviceElementId: record.elementId,
			serviceQualifierId: qualifierId
		}));
	},
	/*
	* Handler function for the click event on element name
	* @param {Object} e The click event
	* */
	onElementClick: function (e) {
		var qualifierId = $(e.currentTarget).attr('data-id');
		var record = this.qualifiersGrid.dataSource.get(qualifierId);
		this.app.breadcrumb.currentItem.config.tabId = 'nav_state_change';
		this.app.breadcrumb.currentItem.config.highlightQualifierId = qualifierId;
		this.app.navigate(ServicesRouter.viewer(record.serviceId, {
			serviceElementId: record.elementId,
		}));
	},
	/*
	 * Handler function for the click event on service name
	 * @param {Object} e The click event
	 * */
	onServiceClick: function (e) {
		var qualifierId = $(e.currentTarget).attr('data-id');
		var record = this.qualifiersGrid.dataSource.get(qualifierId);
		this.app.breadcrumb.currentItem.config.tabId = 'nav_state_change';
		this.app.breadcrumb.currentItem.config.highlightQualifierId = qualifierId;
		NavigationStore.go(ServicesRouter.details(record.serviceId));
	},
	/*
	 * Handler function used when tab is activated
	 * */
	onTabActivate: function (e) {
		//this.adjustSectionHeight();
		var id = $(e.item).attr('id');
		if (id === 'nav_summary' || id === 'nav_health_tab') {
			this.adjustBottomSectionHeight();
		} else {
			this.adjustSectionHeight();
		}
		if (id === 'nav_health_tab') {
			this.adjustChartHeight();
            if (!$('#cw_reasons_chart').children().length) {
				this.getReasonsChartData();
			}
		} else if (id !== 'nav_metrics_tab') {
			$('#cw_metrics_list .cw_grid_check').prop('checked', false);
			$('#cw_metrics_list .cw_grid_check_all').prop('checked', false);
		} else {
			this.loadCurrentReasons = false;
		}
		this.destroyMetricWidgets();

		if (id === 'nav_maintenance_tab' && !this.assetMaintenanceView) {
			this.assetMaintenanceView = new MaintenanceView({
				assetId: this.id,
				accountId: this.accountIdContext,
				icalendar: this.calendar,
				calendars: this.maintenanceCalendars,
				onChange: () => {
					this.app.markDirty()
				}
			});
		}

		if (id === 'nav_metrics_tab') {
			this.renderMetricsTab();
		} else {
			this.unsubscribeMetric();
		}

		if (id === 'nav_attributes_tab') {
			ReactDOM.render(<AttributesTab id={this.id} type={'asset'}></AttributesTab>, $('#cw_attributes_tab').get(0));
		}
	},
	/**
	 * Method by Andy
	 */
	adjustBottomSectionHeight: function () {
		//grid in summary/health tab
		let sectionBottom = $('.cw_tier_bottom:visible');
		let sectionBottomHeight = sectionBottom.height() - 1;
		sectionBottom.find('.cw_section_content').css('height', sectionBottomHeight - 45);
		sectionBottom.find('.k-grid-content').css('height', sectionBottomHeight - 45 - 30);
	},
	adjustSectionHeight: function () {
		var section = $('.k-tabstrip.cw_section:visible');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight - 4);
		section.find('.k-grid-content').first().css('height', sectionHeight - 4 - 30);
	},
	/**
	 * Method by Andy
	 */
	adjustChartHeight: function () {
		var sectionTop = $('.cw_assethealth_module').find('.cw_tier_top').find('.cw_section');
		var sectionHeightTop = sectionTop.height();
		sectionTop.find('.cw_section_content').css('height', sectionHeightTop - 42 + 'px');
	},
	/**
	 * Take action when event(s) received
	 * @param {Object|Array} events A single event or acallban array of events
	 */
	handleEvents: function (events) {
		var indicators, record;
		var hasServiceEvents = false;
		var hasQualifierEvents = false;
		var hasEventSummaryEvents = false;
		if (!events.length) {
			if (events.eventType === 'ServiceStatus') {
				hasServiceEvents = true;
				this.assetMaintenance = events[i].assetMaintenance;
			}
			if (events.eventType === 'State') {
				record = this.qualifiersGrid.dataSource.get(events.sourceId);
				if (record) {
					record.state = events.state;
				}
				this.qualifiersGrid.refresh();
			}
			if (events.eventType === 'AssetHealth' && events.wId === this.subscriberId) {
				indicators = events.assetHealth;
			}
			if (events.eventType === 'EventSummary' && events.wId === this.subscriberId) {
				hasEventSummaryEvents = true;
			}
			if (events.eventType === 'Administration' && events.actionType === 'ASSET_DELETE') {
				this.isAssetDeleted = true;
			}
		} else {
			for (var i = 0; i < events.length; i++) {
				if (events[i].eventType === 'ServiceStatus') {
					hasServiceEvents = true;
					this.assetMaintenance = events[i].assetMaintenance;
				}
				if (events[i].eventType === 'State') {
					hasQualifierEvents = true;
					record = this.qualifiersGrid.dataSource.get(events[i].sourceId);
					if (record) {
						record.state = events[i].state;
					}
				}
				if (events[i].eventType === 'AssetHealth' && events[i].wId === this.subscriberId) {
					indicators = events[i].assetHealth;
				}
				if (events[i].eventType === 'EventSummary' && events[i].wId === this.subscriberId) {
					hasEventSummaryEvents = true;
				}
				if (events[i].eventType === 'Administration' && events[i].actionType === 'ASSET_DELETE') {
					this.isAssetDeleted = true;
				}
			}
		}
		if (hasServiceEvents) {
			this.getServicesIndicators();
		}
		if (indicators) {
			this.populateHealthIndicators(indicators);
		}
		if(indicators || hasEventSummaryEvents) {
			this.populateReasonsGrid(this.allReasonsGrid);
		}
		if (hasQualifierEvents) {
			this.qualifiersGrid.refresh();
		}
	},
	/**
	 * Gets services indicators from server
	 */
	getServicesIndicators: function () {
		var indicatorsUrl = Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/assetDetails/serviceIndicators';
		Utils.ajax(indicatorsUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.populateServicesIndicators(result.data);
				this.alignDefaultWidgetsHeight();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/*
	 * Handler function for rendering monitor tab
	 * */
	renderMonitorsTab: function () {
		var oThis = this;
		if (!this.monitorsView) {
			this.monitorsView = jQuery.extend({}, AssetMonitorsView.prototype, {
				initComponent: function () {
					this.hasEvents = true;
					this.monitorType = 'asset_status';
					this.assetId = oThis.id;
					this.accountId = oThis.accountId;
					this.isAgentAlive = oThis.agentState === 'OK';
					this.highlightMonitorId = oThis.highlightMonitorId;
					this.isInTab = true;
					Application.prototype.initComponent.call(this);
					this.monitorsActionNotification = new CustomNotification({
						appendToElement: '#cw_monitors_list',
						type: 'icon',
						hideOnClick: true
					});

					this.monitorsGridMenu = new GridMenu({
						renderTo: 'cw_assetdetails_monitors_menu',
						items: [{
							id: 'cw_reset_hi',
							icon: 'restart',
							text: lang.assethealth.RESET_HI,
							fn: this.onResetHIButton,
							scope: this,
							cssClass: '',
							disabled: true
						}]
					});

					this.init();
				},
				removeListeners: oThis.onMonitorRemoveListeners,
				attachListeners: oThis.onMonitorAttachListeners,
				onMonitorsCheck: oThis.onMonitorsCheck,
				onMonitorsCheckAll: oThis.onMonitorsCheckAll
			});
			this.monitorsView.initComponent();
		}

		if (this.highlightMonitorId) {
			var tab = this.highlightTab || 'nav_monitor_tab';
			this.tabStrip.activateTab($('#' + tab));
		}
	},

	metricSubscribe: function () {
		this.metricSubscribtion = RemoteEventsManager.subscribeCallback(
			[MetricEvent.subscription(this.id)],
			(ev) => this.onEvent(ev),
			this.subscriberId
		)
	},

	unsubscribeMetric: function () {
		this.metricSubscribtion?.unsubscribe();
	},

	onEvent: function (ev) {
		if (!MetricEvent.is(ev)) {
			return;
		}
		this.eventsToolbar.addEvents([ev]);
	},
	/*
	* Handler function for rendering the metrics tab
	* */
	renderMetricsTab: function () {
		if(this.metricsGrid != null)
			return

		this.metricsDefaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			stateIndex: {
				hidden: false,
				width: 80
			},
			type: {
				hidden: false,
				width: 120
			},
			parentName: {
				hidden: false,
				width: 120
			},
			name: {
				hidden: false,
				width: 160
			},
			elementName: {
				hidden: false,
				width: 120
			},
			qualifierName: {
				hidden: false,
				width: 230
			},
			targetName: {
				hidden: false,
				width: 120
			},
			qualifierId: {
				hidden: false
			},
			value: {
				hidden: false
			},
			timestamp: {
				hidden: false
			}
		};

		const ahMetricsSortString = findUserPref(this.userPref, 'ahMetricsSort');
		const ahMetricsSort = ahMetricsSortString ? JSON.parse(ahMetricsSortString): [];

		const ahMetricsFilterString = findUserPref(this.userPref, 'ahMetricsFilter');
		const ahMetricsFilter = ahMetricsFilterString ? JSON.parse(ahMetricsFilterString): [];

		const metricsColumnsString = findUserPref(this.userPref, 'ahMetricsColumns');

		const metricsColumns = metricsColumnsString ?
			{
				...this.metricsDefaultColumns,
				...JSON.parse(metricsColumnsString)
			}: this.metricsDefaultColumns;

		const ahMetricsSearchPhrase = findUserPref(this.userPref, 'ahMetricsSearchPhrase');

		this.metricsDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + this.accountId + '/assets/' + this.id + '/metrics',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			sort: ahMetricsSort,
			filter: ahMetricsFilter
		});
		this.metricsGrid = $('#cw_metrics_list').kendoCustomGrid({
			autoBind: true,
			selectable: 'row',
			scrollable: true,
			dataSource: this.metricsDataSource,
			resizable: true,
			reorderable: true,
			height: $('.cw_metricstab_container').height() - 40,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			columnMenu: true,
			pageable: false,
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: metricsColumns.id.hidden,
				width: metricsColumns.id.width
			}, {
				field: 'stateIndex',
				title: lang.STATE,
				template: item => {
					return Renderer.metricState(item);
				},
				hidden: metricsColumns.stateIndex.hidden,
				width: metricsColumns.stateIndex.width,
				attributes: {
					'class': 'text_center'
				}
			}, {
				field: 'type',
				title: lang.TYPE,
				template: '<span title="#=type#" class="cw_metrics_type">#=type#</span>',
				hidden: metricsColumns.type.hidden,
				width: metricsColumns.type.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'parentName',
				title: lang.SOURCE,
				template: '#if (type === "COLLECTOR") {# <span title="#=parentName#" class="cw_grid_link cw_metrics_source" data-sourceid="#= parentId #" data-type="#=type#"> #= parentName # </span> #} else {# <span title="#=parentName#" class="cw_metrics_instance">#=parentName#</span> #}#',
				hidden: metricsColumns.parentName.hidden,
				width: metricsColumns.parentName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'name',
				title: lang.NAME,
				template: '#if (type === "MONITOR") {# <span title="#=name#" class="cw_grid_link cw_metrics_monitor_name" data-sourceid="#= parentId #" data-type="#=type#"> #= name # </span> #} else {# <span title="#=name#" class="cw_metrics_instance">#=name#</span> #}#',
				hidden: metricsColumns.name.hidden,
				width: metricsColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'elementName',
				title: lang.assethealth.INSTANCE,
				template: '#if (type === "COLLECTOR") {# <a title="#=elementName#" class="cw_grid_link cw_instance_link" data-id="${parentId}">${elementName}</a> #} else {# <span title="#=elementName#" class="cw_metrics_instance">#=elementName#</span> #}#',
				hidden: metricsColumns.elementName.hidden,
				width: metricsColumns.elementName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'qualifierName',
				title: lang.service.QUALIFIER,
				template: '#if (type === "COLLECTOR") {# <a title="#=qualifierName#" class="cw_grid_link cw_metrics_qualifier_link" data-id="${parentId}">${qualifierName}</a> #} else {# <span title="#=qualifierName#" class="cw_metrics_qualifier">#=qualifierName#</span> #}#',
				hidden: metricsColumns.qualifierName.hidden,
				width: metricsColumns.qualifierName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'targetName',
				title: lang.assethealth.TARGET_NAME,
				hidden: metricsColumns.targetName.hidden,
				width: metricsColumns.targetName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'qualifierId',
				title: lang.IDENTIFIER,
				template: '<span title="#=qualifierId#" id="#=qualifierId#">#=qualifierId#</span>',
				hidden: metricsColumns.qualifierId.hidden,
				width: metricsColumns.qualifierId.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			},
			{
				field: 'value',
				title: lang.assethealth.LAST_VALUE,
				hidden: metricsColumns.value.hidden,
				width: metricsColumns.value.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			},
			{
				field: 'timestamp',
				title: lang.TIME,
				hidden: metricsColumns.timestamp.hidden,
				width: metricsColumns.timestamp.width,
				template: function (data) {
					if (data.timestamp) {
						return Renderer.browserDateRenderer(new Date(data.timestamp), "datetime")
					}
					else {
						return ''
					}
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}
			], metricsColumns),
			dataBound: $.proxy(function () {
				var tooltip = $('#cw_metrics_list').find('.k-grid-content').data('kendoTooltip');
				if (tooltip) {
					tooltip.destroy();
				}
				$('#cw_metrics_list').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});

				$('.collector_metric').on('mouseover', (e) => this.onCollectorMetricMouseOver(e));
			}, this),
			change: $.proxy(function () {
				var selectedRow = this.metricsGrid.select();
				var myRow = selectedRow[0];
				var messageEl = $(myRow).find('.to_expand');
				if ($(messageEl).hasClass('cw_message_expanded')) {
					$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
				} else {
					$('#cw_metrics_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
					$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
				}
			}, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.metricsGrid);
		this.metricsGrid.thead.find("[data-field='id']>.k-header-column-menu").remove();

		this.metricsGridMenu = new GridMenu({
			renderTo: 'cw_assetdetails_metrics_menu',
			items: [{
				id: 'cw_delete_metrics',
				icon: 'bin',
				text: t('Delete'),
				fn: () => this.onMetricDelete(),
				categoryIndex: 1,
				cssClass: '',
				disabled: true
			}, {
				id: 'cw_show_metrics',
				icon: 'stats',
				text: lang.SHOW_SINGLE_METRIC_GRAPH,
				fn: () => this.showMetrics('singlegraph'),
				cssClass: '',
				disabled: true
			}, {
				id: 'cw_show_multigraph_metrics',
				icon: 'stats',
				text: lang.SHOW_MULTIGRAPH,
				fn: () => this.showMetrics('multigraph'),
				cssClass: '',
				disabled: true
			}, {
				id: 'cw_show_table',
				icon: 'calendar',
				text: lang.SHOW_TABLE,
				fn: this.onShowTable,
				scope: this,
				cssClass: '',
				disabled: true
			}]
		});

		this.metricsFilterPanel = new PreferencesPanel({
			renderTo: 'cw_metrics_filters',
			grid: this.metricsGrid,
			searchFields: ['type', 'parentName', 'name', 'elementName', 'qualifierName', 'targetName'],
			modulePrefName: 'AssetDetailsMetrics',
			defaultPrefViewKey: 'defaultMetricsFsView',
			prefListKey: 'ahMetricsFSViews',
			userPref: this.userPref,
			defaultColumns: this.metricsGrid.columns,
			searchValue: ahMetricsSearchPhrase,
			category: 'AssetDetailsMetrics',
		});

		let eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 0,
			playing: true
		};

		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_services_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});

		this.globalWidgetConfig = {
			period: 'LASTDAY'
		};

		this.metricSubscribe();
	},

	onMetricDelete: function () {
		let checkboxes = $('#cw_metrics_list .cw_grid_check:checked');
		const ids = checkboxes.map((_i, x) => {
			const uid = $(x).closest('tr').data('uid')
			return this.metricsDataSource.getByUid(uid)
		}).toArray().map(x => x.qualifierId)

		this.showDeleteMetricConfirmation(async () => {
			const result = await apiFetch(deleteDataRegistry(ids))
			if(result.success) {
				this.metricsDataSource.read()
			}
		}, ids.length)
	},

	showDeleteMetricConfirmation: function (onOk, count) {
		const [container, onCancel] = createContainer();
		ReactDOM.render(<AntConfirmModal
		  onCancel={onCancel}
         onOk={() => {
             onOk();
             onCancel();
         }}
         title={t('Info')}
         okText={t('Delete')}
         icon={'ERROR'}
         positionType={'topLeft'}
         message={t(`Are you sure you want to delete {0} metric${count > 1 ? 's' : ''}?`, count)}
		/>, container)
	},

	onEventsTimeout: function (events) {
		this.metricsDataSource.read();
	},

	async onCollectorMetricMouseOver(e) {
		let target = $(e.target);
		let uid = target.closest('tr').attr('data-uid');
		let item = this.metricsDataSource.getByUid(uid);
		let payload = [{
			serviceId: item.parentId,
			serviceQualifierId: item.qualifierId,
			accountId: this.accountId
		}];
		let result = await apiFetch(getHealthInfo(payload))
		if (result.success) {
			let qualifierData = result.data[0];
			qualifierData.name = item.name;
			qualifierData.shared = item.shared;
			let properties = QualifiersHelper.getCalculatedProperties(qualifierData);
			target.attr('title', properties.iconTooltip);
		}
	},

	/**
	 * Handler for clicking a link in the source column in the metrics grid
	 */
	onMetricsSourceNameClick: function (e) {
		var sourceEl = $(e.currentTarget);
		var type = sourceEl.attr('data-type');
		var record = this.metricsDataSource.getByUid(sourceEl.closest('tr').attr('data-uid'));
		switch (type) {
			case 'COLLECTOR':
				NavigationStore.go(ServicesRouter.details(record.parentId));
				break;
		}
	},
	onMetricsMonitorNameClick: function (e) {
		var sourceEl = $(e.currentTarget);
		var record = this.metricsDataSource.getByUid(sourceEl.closest('tr').attr('data-uid'));

		this.openMonitorView(record.parentId, record.parentName);
	},
	onInstanceNameClick: function (e) {
		var instanceEl = $(e.currentTarget);
		var record = this.metricsDataSource.getByUid(instanceEl.closest('tr').attr('data-uid'));
		this.app.navigate(ServicesRouter.viewer(record.parentId, {
			serviceElementId: record.elementId,
			serviceQualifierId: record.qualifierId
		}));
	},
	onMetricsQualifierClick: function (e) {
		var instanceEl = $(e.currentTarget);
		var record = this.metricsDataSource.getByUid(instanceEl.closest('tr').attr('data-uid'));
		this.app.navigate(ServicesRouter.viewer(record.parentId, {
			serviceElementId: record.elementId,
			serviceQualifierId: record.qualifierId
		}));
	},
	/**
	 * Handler funtion for the click event on the Reset health index button
	 */
	onResetHIButton: function () {
		var checkboxes = $('#cw_monitors_list').find('.cw_monitor_grid_check:checked'), i, length = checkboxes.length,
			monitors = [], id;
		for (i = 0; i < length; i++) {
			id = $(checkboxes[i]).attr('data-id');
			monitors.push({
				monitorId: id,
				assetId: this.id
			});
		}
		Utils.resetMonitorsHI(monitors, function () {
			$('#cw_monitors_list').find('.cw_monitor_grid_check').prop('checked', false);
			$('#cw_monitors_list').find('.cw_monitor_grid_check_all').prop('checked', false);
		});
	},
	/**
	 * Attaches listeners for monitor tab
	 */
	onMonitorAttachListeners: function () {
		$('#cw_monitors_list').on('click', '.cw_monitor_grid_check', $.proxy(this.onMonitorsCheck, this));
		$('#cw_monitors_list').on('click', '.cw_monitor_grid_check_all', $.proxy(this.onMonitorsCheckAll, this));
		$('#cw_monitors_list').on('click', '.cw_monitor_name', $.proxy(this.onMonitorClick, this));
		$('#cw_monitors_list').on('click', '.cw_agent_name', $.proxy(this.onAgentNameClick, this));
		$('#cw_monitors_list').on('click', '.cw_target_name', $.proxy(this.onTargetNameClick, this));
		$('#cw_monitors_list').off('mouseover', '[data-monitorerror="true"]').on('mouseover', '[data-monitorerror="true"]', $.proxy(function (e) {
			Utils.onMonitorErrorOver(e, {
				dataSource: this.monitorsGrid.dataSource,
				toolTip: this.tooltipOverview,
				accountId: this.accountId
			});
		}, this));
		$('body').on('click', '.cw_monitorname', $.proxy(this.onMonitorNameClick, this));
		$('body').on('click', '.cw_agentname', $.proxy(this.onAgentNameClick, this));
	},
	/**
	 * Removes listeners for monitor tab
	 */
	onMonitorRemoveListeners: function () {
		$('#cw_monitors_list').off();
		$('body').off('click', '.cw_monitorname');
		$('body').off('click', '.cw_agentname');
	},
	/**
	 * Handler for the monitor checkbox click
	 * @param {Object} e The click event object
	 */
	onMonitorsCheck: function (e) {
		var checkboxes = $('#cw_monitors_list').find('.cw_monitor_grid_check:checked'), length = checkboxes.length;
		var uid = $('#cw_monitors_list').find('.cw_monitor_grid_check:checked').closest('tr').data('uid');
		var item = $('#cw_monitors_list').data('kendoCustomGrid').dataSource.getByUid(uid);
		if (length) {
			this.monitorsGridMenu.enableItem('cw_reset_hi');
		} else {
			this.monitorsGridMenu.disableItem('cw_reset_hi');
		}
		if (item?.monitorMaintenance) {
			this.monitorsGridMenu.disableItem('cw_reset_hi');
		}
	},
	/**
	 * Handler for the check all monitors checkbox click
	 * @param {Object} e The click event
	 */
	onMonitorsCheckAll: function (e) {
		var checkAll = $(e.currentTarget).is(':checked');
		var checkboxes = $('#cw_monitors_list').find('.cw_monitor_grid_check');

		$('#cw_monitors_list').find('.cw_monitor_grid_check:not(:disabled)').prop('checked', checkAll);
		this.onMonitorsCheck();
	},

	showMetrics: function(type){
		let checkboxes = $('#cw_metrics_list .cw_grid_check:checked');
		let serviceQualifierIds = [];
		for (let checkbox of checkboxes) {
			let uid = $(checkbox).closest('tr').data('uid');
			let item = this.metricsDataSource.getByUid(uid);
			if (item) {
				if (type === 'singlegraph') {
					this.openWindow({
						title: item.name,
						serviceQualifierId: [item.qualifierId],
						type
					});
				} else if (type === 'multigraph') {
					serviceQualifierIds.push(item.qualifierId);
				}
			}
		}
		if (type === 'multigraph') {
			this.openWindow({
				title: lang.MULTIGRAPH,
				serviceQualifierId: serviceQualifierIds,
				type
			});
		}
	},

	onShowTable: function () {
		let checkboxes = $('#cw_metrics_list .cw_grid_check:checked');
		for(const checkbox of checkboxes){
			const uid = $(checkbox).closest('tr').data('uid');
			const item = this.metricsDataSource.getByUid(uid);

			let containerId = item.qualifierId;
			this.openedTableWindowIds.push(containerId);
			if($('#metric-' + containerId).length > 0){
				this.removeTableWindowContainer(containerId);
			}
			$(document.body).append('<div id="metric-' + containerId + '" class="cw_table_info_fake_container"></div>');

			let widgetElem = 'cw_widget' + containerId;
			let windowPos = this.getWindowPosition(widgetElem, {width: this.windowDim.width, height: this.windowDim.height});

			let subscriberId = Utils.guid();
			this.windowSubscribe(subscriberId, item.qualifierId);
			const unsubscribe = () => RemoteEventsManager.unsubscribe(subscriberId);

			ReactDOM.render(<MetricTableDataWindow
				title={item.name}
				metricId={item.qualifierId}
				coordinates={windowPos}
				defaultTimeSelectorValue={this.globalWidgetConfig.period}
				cacheWindow={(window, metricId) => this.cacheTableWindow(window, metricId)}
				containerId={containerId}
				window={true}
				unsubscribeWindow={unsubscribe}
				destroyWindow={(id) => this.hideTableWindow(id)}>
			</MetricTableDataWindow>, $('#metric-' + containerId).get(0));
		}
	},

	cacheTableWindow(window, metricId) {
		this.openedTableWindows[metricId] = window;
	},

	hideTableWindow(id) {
		this.removeTableWindowContainer(id);
		this.updateOpenedTableWindows(id);

		let widgetElem = 'cw_widget' + id;
		let index = Utils.getIndexFromJsonArray(this.windowsPos, 'widgetElem', widgetElem);
		if (index > -1) {
			this.windowsPos.splice(index, 1);
		}
	},

	removeTableWindowContainer(id) {
		let container = $('#metric-' + id);
		ReactDOM.unmountComponentAtNode(container.get(0));
		container.remove();
	},

	updateOpenedTableWindows(id) {
		let index = this.openedTableWindowIds.indexOf(id);
		if (index > -1) {
			this.openedTableWindowIds.splice(index, 1);
		}
	},

	/*
	 * Handler function for opening a new window
	 * @param {Object} args
	 * @properties: event, qualifierId, widgetType, windowTitle, windowDim {width, height}
	 */
	openWindow: function (args) {
		let widgetId = Utils.guid();
		let widgetElem = 'cw_widget' + widgetId;
		let windowSubscriberId = Utils.guid();
		let qualifierId = args.serviceQualifierId || args.systemQualifierId;

		let datasetId = args.type + '_' + qualifierId + '_' + 'plain';

		let searchWindowElem = $('[data-uniqueid="' + datasetId + '"]');
		if (searchWindowElem.length) {
			let clonedWindow = searchWindowElem.data('kendoWindow');
			clonedWindow.restore();
			clonedWindow.toFront();
			return;
		}

		$('#widget_wrapper').append('<div data-uniqueid="' + datasetId + '" id="' + widgetElem + '"><span class="cw_period_toggle"></span><div class="cw_view_widget" class="left w100"></div></div>');
		let windowHandler = $('#' + widgetElem);
		let windowOpened = true;

		let windowPos = {};

		let windowActions = ['Close'];
		windowActions.unshift('Toggle');

		if (args.windowPos) {
			windowPos.top = args.windowPos.top;
			windowPos.left = args.windowPos.left;
		} else {
			windowPos = this.getWindowPosition(widgetElem, {width: this.windowDim.width, height: this.windowDim.height});
		}

		const resizeDebounced = debounce((wrapper) => {
			wrapper.find('.cw_raw_data_grid').data('kendoCustomGrid')?.setOptions({
				height: wrapper.height() - 40
			});

			this.widgets[widgetElem].onResize?.()
		}, 500)

		this.metricWindow = windowHandler.kendoWindow({
			widget: null, //custom property
			resizable: false,
			minWidth: 200,
			minHeight: 200,
			width: this.windowDim.width,
			height: this.windowDim.height,
			position: {
				top: windowPos.top,
				left: windowPos.left
			},
			title: args.title,
			appendTo: '#widget_wrapper',
			actions: windowActions,
			close: $.proxy(function () {
				//this.resetPanelWindow(widgetElem);
				if (this.windowWidget) {
					delete this.widgetsByWindowID[this.windowWidget.id];
				}
				windowOpened = false;

				var index = Utils.getIndexFromJsonArray(this.windowsPos, 'widgetElem', widgetElem);
				if (index > -1) {
					this.windowsPos.splice(index, 1);
				}

				if (this.widgets[widgetElem].destroy) {
					try {
						this.widgets[widgetElem].destroy();
					} catch (e) {
					}
				}
				delete this.widgets[widgetElem];
				delete this.widgetsHoverInfo[widgetElem];
				delete this.clonedWindows[widgetElem];

				windowHandler.data("kendoWindow").destroy();
				windowHandler.detach();
			}, this),
			activate: $.proxy(function (e) {
				let wrapper = e.sender.wrapper;
				wrapper.find('.k-window-titlebar').on('dblclick', function (e) {
					e.stopPropagation();
				});

				e.sender.setOptions({
					resizable: args.resizable !== undefined ? args.resizable : true
				});
			}, this),
			resize: (e) => {
				let wrapper = e.sender.wrapper;
				this.truncateWindowTitle(wrapper);

				resizeDebounced(wrapper)
			},
			open: $.proxy(async function (e) {
				//Do not try to render the metricsWidget, as window has been closed
				if (!windowOpened) {
					return;
				}
				var config;
				config = {
					metrics: args.serviceQualifierId
				};
				config.widgetElem = widgetElem;
				config.type = args.type;

				this.renderMetricsWidgetWindowContent(e, config);

				this.widgets[widgetElem] = this.windowWidget;
				this.widgetsByWindowID[this.windowWidget.id] = widgetElem;
				windowHandler.data('kendoWindow').widget = this.windowWidget;
			}, this),
			dragend: $.proxy(function (e) {
				var index = Utils.getIndexFromJsonArray(this.windowsPos, 'widgetElem', widgetElem);
				if (index > -1) {
					this.windowsPos.splice(index, 1);
				}
				Utils.checkWindowPosition(e, $('.cw_assetdetails .cw_content_wrapper'));
			}, this)
		}).data('kendoWindow');

		let wrapper = this.metricWindow.wrapper;
		wrapper.find('[aria-label="Toggle"]').on('click', $.proxy(this.onWidgetHeaderToggle, this));

		this.clonedWindows[widgetElem] = this.metricWindow;

		/*
		 * 02-03-2017
		 * Proper event is not handled by Kendo
		 * @todo check on later versions for a public Kendo method
		 * */
		this.metricWindow.resizing._draggable.userEvents.bind("release", function (e) {
			var target = $(e.sender.currentTarget),
				window = target.closest('.k-window').find('.k-window-content.k-content').data('kendoWindow');
			if (window) {
				//var widgetElem = 'find it';
				//delete this.windowsPos[widgetElem]
				let grid = $('#cw_widget' + widgetId).find('.cw_raw_data_grid').data('kendoCustomGrid');
				if (grid) {
					grid.setOptions({
						height: windowHandler.height()
					});
					grid.resize();
				}
				if (window.widget && window.widget.onResize) {
					window.widget.onResize();
				}
			}
		});
	},

	truncateWindowTitle(wrapper) {
		let timeSelector = wrapper.find('.period_multi_toggle');
		let timeSelectorPosition = timeSelector.position().left;
		let titleContainer = wrapper.find('.k-window-title');
		titleContainer.css('width', timeSelectorPosition - 30);
		titleContainer.addClass('ellipsis');
		wrapper.find('.cw_widget_info_sign').css('left', timeSelectorPosition - 15);
	},

	onInfoSignHover(e) {
		let widgetId = $(e.target).attr('widget-id');
		let data;
		for (let item in this.widgetsHoverInfo) {
			if (item === widgetId) {
				data = this.widgetsHoverInfo[item];
			}
		}
		let coordinates = e.target.getBoundingClientRect();
		ReactDOM.render(<WidgetInfoContainer
			data={data}
			type={'preloaded'}
			coordinates={coordinates}
		/>, e.target);
	},

	onInfoSignHoverOut(e) {
		this.hoverInfoContainerTarget = e.target;
		let destroyContainer = setInterval(() => ReactDOM.unmountComponentAtNode(e.target), 200);
		setTimeout(() => clearInterval(destroyContainer), 400);
	},

	/*
	 * Window custom subscribe event
	 */
	windowSubscribe: function (subscriberId, qualifierId, callback) {
		var subscriptionObj = [{
			eventType: 'Metric',
			releaseEvents: true,
			timeZone: this.timezone || Cookies.CeesoftTimezone,
			qualifierId: qualifierId,
			showUnit: true
		}];
		RemoteEventsManager.subscribe(subscriberId, subscriptionObj);

		if (callback) {
			callback.call(this, subscriberId);
		}
	},

	/*
	 * Window custom unsubscribe event
	 */
	windowUnsubscribe: function (subscriberId) {
		RemoteEventsManager.unsubscribe(subscriberId);
	},

	onWidgetHeaderToggle: function (e) {
		var customContainer;
		if ($(e.currentTarget).closest('.k-window').find('.cw_widget_settings').length) {
			customContainer = $(e.currentTarget).closest('.k-window').find('.cw_widget_settings');
		} else {
			customContainer = $(e.currentTarget).closest('.cw_widget').find('.cw_widget_settings');
		}
		if (customContainer.length) {
			customContainer.css('top', '30px');
			if (customContainer.is(':visible')) {
				customContainer.slideUp(150);
			} else {
				customContainer.slideDown(350);
			}
		}
		var widgetActions = $(e.currentTarget).closest('.k-window').find('.cw_widget_actions');
		widgetActions.removeClass('expanded');

		e.preventDefault();
	},
	/*
	 * Handler function for getting the correct window position
	 * @param {GUID} widgetID
	 * @param {Object} options
	 */
	getWindowPosition: function (widgetElem, options) {
		var defaults = {
			width: 380,
			height: 280
		};
		var area = $('.cw_assetdetails .cw_content_wrapper');
		var areaPos = area.offset();

		var areaDim = {
			width: area.width(),
			height: area.height()
		};

		$.extend(defaults, options);

		var pos = {
			top: 0,
			left: 0
		};

		var length = this.windowsPos.length;
		if (length) {
			var limitRowWindows = {
				perRow: Math.floor(area.width() / (defaults.width + 2 * 5))
			};
			limitRowWindows.rowNr = Math.ceil((length + 1) / limitRowWindows.perRow);

			if (length > Math.floor((limitRowWindows.rowNr - 1) * limitRowWindows.perRow)) {
				limitRowWindows.currentInRow = length - Math.floor((limitRowWindows.rowNr - 1) * limitRowWindows.perRow);
			} else if (length === Math.floor((limitRowWindows.rowNr - 1) * limitRowWindows.perRow)) {
				limitRowWindows.currentInRow = 0;
			} else {
				limitRowWindows.currentInRow = length;
			}

			limitRowWindows.maxRows = Math.floor((areaDim.height - 100) / defaults.height);

			if (limitRowWindows.rowNr <= limitRowWindows.maxRows) {
				pos = {
					top: areaPos.top + areaDim.height - (defaults.height + 20) * limitRowWindows.rowNr,
					left: areaPos.left + areaDim.width - defaults.width * (limitRowWindows.currentInRow + 1) + 25
				};
				pos.left -= 5 * limitRowWindows.currentInRow;

				if (limitRowWindows.currentInRow >= limitRowWindows.perRow) {
					pos.left = areaPos.left + areaDim.width - defaults.width + 25;
				}
			} else {
				//no space left, start adding from bottom right
				pos = {
					top: areaPos.top + areaDim.height - defaults.height - 20,
					left: areaPos.left + areaDim.width - defaults.width + 25
				};
			}

		} else {
			pos = {
				top: areaPos.top + areaDim.height - defaults.height - 20,
				left: areaPos.left + areaDim.width - defaults.width + 25
			};
		}

		this.windowsPos.push({
			pos: pos,
			widgetElem: widgetElem
		});
		return pos;
	},
	/*
	 * Render content function for metrics widget window
	 * @param e {Object} Event passed from window open event
	 * @param {Object} config The configuration object
	 * */
	renderMetricsWidgetWindowContent: function (e, config) {
		var currentMetric, currentMetricId, currentMetricTr, currentMetricType, currentMetricSource,
			currentMetricInstance, currentMetricQualifier, currentMetricLegend;
		var currentMetricsLegendArray = [];
		for (var i = 0; i < config.metrics.length; i++) {
			currentMetricId = config.metrics[i];
			currentMetric = $('#' + currentMetricId);
			currentMetricTr = currentMetric.closest('tr');
			currentMetricType = currentMetricTr.find('.cw_metrics_type').text();
			currentMetricSource = currentMetricTr.find('.cw_metrics_source').text();
			currentMetricInstance = currentMetricTr.find('.cw_metrics_instance').text();
			currentMetricQualifier = currentMetricTr.find('.cw_metrics_qualifier').text();
			currentMetricLegend = currentMetricType + '/' + currentMetricSource + '/' + currentMetricInstance + '/' + currentMetricQualifier;
			currentMetricsLegendArray.push({
				metricId: currentMetricId,
				metricLegend: currentMetricLegend
			});
		}

		this.metricWindowId = Utils.guid();
		let wrapper = $('#' + config.widgetElem).closest('.k-window');
		wrapper.attr('id', this.metricWindowId);

		this.windowWidget = new GenericMultigraphWidget({
			id: this.metricWindowId,
			title: '',
			renderTo: config.widgetElem,
			customControls: {
				target: e.sender.wrapper,
				change: $.proxy(function (e) {
					e.sender.wrapper.closest('.k-window').find('.k-i-toggle').trigger('click');
				}, this),
				toggleClick: $.proxy(function (value) {
					e.sender.wrapper.closest('.k-window').find('.k-i-toggle').trigger('click');
				}, this),
				zoom: $.proxy(function (args) {

				}, this)
			},
			onCustomControlsCreated: () => {
				this.truncateWindowTitle(wrapper);
			},
			isViewer: true,
			isAssetDetails: true,
			type: config.type,
			legendArray: currentMetricsLegendArray,
			configuration: {
				metrics: config.metrics,
				aggregated: config.aggregated,
				timezone: Cookies.CeesoftTimezone,
				period: this.globalWidgetConfig.period || 'LASTDAY',
				startDate: this.globalWidgetConfig.startDate,
				endDate: this.globalWidgetConfig.endDate,
			}
		});
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			assetId: this.id
		}, {
			eventType: 'ServiceStatus',
			assetId: this.id
		}, {
			eventType: 'State',
			assetId: this.id
		}, {
			eventType: 'EventSummary',
			targetId: this.id,
			types: ["ASSET_HEALTH_REASON"]
		}, {
			eventType: 'Administration',
			actionTypes:['ASSET_DELETE'],
			entityIds: [this.id]
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/*
	* Handler function for destroying the metric widgets
	* */
	destroyMetricWidgets: function () {
		if (this.widgets) {
			for (var widgetElem in this.widgets) {
				if (this.widgets[widgetElem].destroy) {
					try {
						this.widgets[widgetElem].destroy();
					} catch (e) {
					}
				}
				delete this.widgets[widgetElem];
			}
		}
		if (this.clonedWindows) {
			for (var widgetElem in this.clonedWindows) {
				if (this.clonedWindows[widgetElem].destroy) {
					try {
						this.clonedWindows[widgetElem].destroy();
					} catch (e) {
					}
					delete this.clonedWindows[widgetElem];
				}
			}
		}
		if(this.openedTableWindowIds?.length > 0){
			for (const id of [...this.openedTableWindowIds]) {
				this.removeTableWindowContainer(id);
				this.updateOpenedTableWindows(id);
			}
		}
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'AssetHealthAllReasons',
			filterPanel: this.summaryFilterPanel,
			grid: this.allReasonsGrid,
			keys: {
				searchPhrase: 'ahAllReasonsSearchPhrase',
				columns: 'ahAllReasonsColumns',
				sort: 'ahAllReasonsSort',
				filter: 'ahAllReasonsFilter',
				FsViews: 'ahAllReasonsFSViews',
				defaultFsView: 'defaultAllReasonsFsView'
			}
		});
		this.saveUserPrefs({
			category: 'AssetHealthHealthReasons',
			filterPanel: this.healthFilterPanel,
			grid: this.reasonsGrid,
			keys: {
				searchPhrase: 'ahHealthReasonsSearchPhrase',
				columns: 'ahHealthReasonsColumns',
				sort: 'ahHealthReasonsSort',
				filter: 'ahHealthReasonsFilter',
				FsViews: 'ahHealthReasonsFSViews',
				defaultFsView: 'defaultHealthReasonsFsView'
			}
		});
		this.saveUserPrefs({
			category: 'AssetDetailsInventory',
			filterPanel: this.inventoryFilterPanel,
			grid: this.inventoryProductsGrid,
			keys: {
				searchPhrase: 'ahInventorySearchPhrase',
				columns: 'ahInventoryColumns',
				sort: 'ahInventorySort',
				filter: 'ahInventoryFilter',
				FsViews: 'ahInventoryFSViews',
				defaultFsView: 'defaultInventoryFsView'
			}
		});

		this.saveUserPrefs({
			category: 'AssetDetailsMetrics',
			filterPanel: this.metricsFilterPanel,
			grid: this.metricsGrid,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'ahMetricsSearchPhrase',
				columns: 'ahMetricsColumns',
				sort: 'ahMetricsSort',
				filter: 'ahMetricsFilter',
				FsViews: 'ahMetricsFSViews',
				defaultFsView: 'defaultMetricsFsView'
			}
		});
	},
	/**
	 * Destroy method
	 */
	destroy: function () {
		this.saveUserPreferences();
		this.unsubscribeMetric();

		if (this.monitorsView) {
			ReasonsView.prototype.destroy.call(this.monitorsView);
		}
		if (this.timeRange) {
			this.timeRange.destroy();
		}
		if (this.tooltipOverview) {
			this.tooltipOverview.destroy();
		}
		if(this.eventsToolbar)
			this.eventsToolbar.destroy();
		this.destroyMetricWidgets();

		if ($('#widget_wrapper').length) {
			$('#widget_wrapper').remove();
		}

		ReactDOM.unmountComponentAtNode($('#cw_attributes_tab').get(0));
		ReactDOM.unmountComponentAtNode($('#historic_filters_enabled').get(0));
		this.removeCoordinateEditor();

		if (this.hoverInfoContainerTarget) {
			ReactDOM.unmountComponentAtNode(this.hoverInfoContainerTarget);
		}

		Application.prototype.destroy.call(this);
	}
});

function findUserPref(userPref, key) {
	let item;
	for (let i = 0; i < userPref.length; i++) {
		let currentUserPrefValues = userPref[i].values;
		for (let j = 0; j < currentUserPrefValues.length; j++) {
			if (currentUserPrefValues[j].key === key) {
				item = currentUserPrefValues[j].value;
			}
		}
	}
	if (item) {
		return item;
	}
}
