import './calendarSelector.less';
import React, {Component, createRef} from 'react';

import {translator} from 'core/localization/localization';
import {Settings} from "settings";
import {Cookies} from 'core/cookies';
import {ErrorHandler} from 'core/errorHandler';
import {CeeViewDataSource} from 'tools/ceeViewDataSource';

const i = translator({
	"Global": {
		no: "Global"
	}
});

let b = require('b_').with('calendar-selector-block');

interface CalendarSelectorProps {
	value: object,
	accountId: string,
	disabledItems: any[],
	disabledItemTitle: string,
	onChange: (value: object, e: object) => void
}

export const CalendarSelectorBlock = (props: CalendarSelectorProps) => {
	return <div className={b()}>
		<label className={'cw_section_title'}>{i('Global')}</label>
		<CalendarSelector {...props} />
	</div>
}

export class CalendarSelector extends Component<CalendarSelectorProps, {}>{
	private containerRef = createRef<HTMLDivElement>();
	private kendoMultiselect: any;

	render() {
		return (<div ref={this.containerRef}>

		</div>)
	}

	componentDidMount() {
		const values = [...this.props.value as any[], ...(this.props.disabledItems ?? [])];
		const accountId = this.props.accountId || Cookies.CeesoftCurrentAccountId;
		this.kendoMultiselect = $(this.containerRef.current).kendoSortedMultiSelect({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: `${Settings.serverPath}accounts/${accountId}/calendars`,
						contentType: 'application/json; charset=utf-8',
						type: 'GET',
						dataType: 'json',
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				schema: {
					parse: $.proxy(function (response) {
						if (response.success) {
							for (const calendar of response.data) {
								calendar.langCalendarType = calendar.calendarType === 'generic'
									? i('PLANNING')
									: i('HOLIDAY');
								const readOnly = (this.props.disabledItems ?? []).some(x => x.id == calendar.id);
								calendar.title = readOnly && this.props.disabledItemTitle
									? this.props.disabledItemTitle
									: calendar.name;

								calendar.readOnlyClass = readOnly ? b('readonly') : '';
							}
							return response.data;
						}
					}, this)
				}
			}),
			autoBind: true,
			placeholder: i('Select...'),
			dataTextField: 'name',
			itemTemplate: '${name} (${langCalendarType})',
			tagTemplate: '<span class="${readOnlyClass}" title="${title} (${langCalendarType})">${name} (${langCalendarType})</span>',
			dataValueField: 'id',
			value: values,
			change: e => this.props.onChange(e.sender.value(), e)
		}).data('kendoSortedMultiSelect');
	}

	componentDidUpdate(prevProps: Readonly<CalendarSelectorProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if (prevProps.value != this.props.value || prevProps.disabledItems != this.props.disabledItems) {
			const values = [...this.props.value as any[], ...(this.props.disabledItems ?? [])];
			this.kendoMultiselect.value(values);
		}
	}
}
