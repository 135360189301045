import './viewer.less';

import React from "react";
import ReactDOM from "react-dom";

import Settings from 'settings';
import {RemoteEventsManager, Cookies, LocalEventsManager} from 'core';
import {State, UserSettings, Utils}  from "tools";

import Application from 'core/application';
import ServiceHistoryWidget from './widgets/historyWidget';
import MetricsWidget from 'areas/service-boards/widgets/metricsWidget';
import MultigraphWidget from 'areas/service-boards/widgets/multigraphWidget';
import LogsView from './logsView';
import {Designer} from "controls/designer/";
import {translator} from "core/localization";
import {ServicesApi} from 'areas/services/api';
import QualifiersHelper, {updateFromEvent}  from 'tools/entityHelpers/qualifiers';

import SqInfoForm from "./designer/qualifierWizard/subforms/sqInfoForm";
import ChartToolbar from "../../controls/react/chartToolbar";
import ExternalConfigErrorWindow from "./externalConfigErrorWindow";
import {loadQualifierDetails } from "./designer/qualifierWizard/wizardSave";
import {isServiceLocked} from "./utils";
import {ServiceDesignerRouter} from './designer/bundleDescription';

import {AccountsApi} from "api";
import {ServicesRouter} from "./bundleDescription";
import {getPresentationModeLabelByValue} from "areas/services/designer/graph-editor-extensions/presentationMode";
import {getAdminEvents} from "areas/services/eventsManager";
import {MetricTableDataWindow} from 'controls/metricTableDataWindow';
import {Actions} from "controls/designer/actionsManager/actions";
import {GroupOutlined, HistoryOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {
	addDesignerWindowStateStorage,
	getAutoWindowPositions, getDefaultSize
} from "controls/designer/extensions/helperDesignerWindowPositions";
import {NavigationStore} from "framework/navigationStore";
import {AntSwitch} from "controls/react/ant/antSwitch";

const i = translator({
  "Service Log": {
    "no": "Tjenestelogg",
    "en": "Service log"
  },
  "Service history": {
    "no": "Tjenestehistorikk",
    "en": "Service history"
  },
  "Service Element": {
    "no": "Tjenesteelement",
    "en": "Service element"
  },
  "Viewer": {
    "no": "Visning"
  },
  "Reset position and size for windows": {
    "no": "Tilbakestill vinduer til standard posisjon"
  },
  "Show/hide service log window": {
    "no": "Vis/Skjul tjenestelogg vindu",
    "en": "Show/Hide Service Log Window"
  },
  "Show/hide service elements window": {
    "no": "Vis/skjul tjeneste element vindu",
    "en": "Show/hide service elements window"
  },
  "Show/hide servicehistory window": {
    "no": "Vis/Skjul tjenestehistorikk vindu",
    "en": "Show/Hide service History Window"
  },
  "Test configuration": {
    "no": "Test konfigurasjon"
  },
});

const SETTINGS_CATEGORY = "ServiceViewer";

export default function Viewer(config) {
};

jQuery.extend(Viewer.prototype, Application.prototype, {
	/**
	 * @cfg {String} id The id of the model
	 */
	/**
	 * @cfg {String} serviceId The id of the service
	 */
	/**
	 * @cfg {String} serviceName The name of the service
	 */
	/**
	 * @cfg {String} serviceElementId (Optional) The id of the element to be selected
	 */
	/**
	 * @cfg {String} serviceQualifierId (Optional) The id of the qualifier to be selected. serviceElementId must be set.
	 */

	highlightState: 'INACTIVE',

	init: async function () {
		if(this.configuration?.endDate){
			this.configuration.endDate = parseInt(this.configuration.endDate) || null
		}

		if(this.configuration?.startDate){
			this.configuration.startDate = parseInt(this.configuration.startDate) || null
		}

		let serviceResult = await ServicesApi.getService(this.id, false);
		if(serviceResult.success){
			this.draftUpdateTime = serviceResult.data.draftUpdateTime;
		}

		const modelResult = await ServicesApi.getModel(this.id);

		if(modelResult.success !== false) {
			this.model = modelResult.data.model;
			this.xml = modelResult.data.xml;

			const accountResult = await AccountsApi.getAccount(this.model.accountId);
			this.account = accountResult.data;

			//initializing data inside this class
			this.requestPath = Settings.serverPath + 'accounts/' + this.account.id + '/';
			this.subscriberId = Utils.guid();
			this.hasEvents = true;

			this.otherWindowPositions = [];

			this.subscribe();

			this.serviceSettings = await UserSettings.forCategory(this.id);

			this.userSettings = await UserSettings.forCategory(SETTINGS_CATEGORY);

			this.sqDetailsWindowConfig = this.userSettings.get('sqDetailsWindowConfig');
			this.savedWindowsConfig = this.userSettings.get('windows');
			if (this.savedWindowsConfig) {
				this.savedWindowsConfig = Utils.getForcedWindowsConfig($('#painting_area'), this.savedWindowsConfig);
			} else {
				this.savedWindowsConfig = [];
			}
			this.reloadNotificationType = '';

			this.windows = [];

			this.document = $(document);
			this.type = 'Application';
			this.filterMessages = lang.grid.filter;
			this.firstLoad = true;
			this.firstHistoryWidgetLoad = true;
			State.mainApp.isPreview = true;

			this.widgets = [];
			this.widgetsByWindowID = [];
			this.clonedWindows = [];

			this.openedRawDataWindows = [];
			this.openedRawDataWindowIds = [];

			this.removeListeners();
			this.attachListeners();
			this.initDesigner();
			this.setPanelBarItemHeight();
			this.windows = [];

			$('.cw_page_title').text(this.model.name);

			if (!this.isFromLogsView) {
				NavigationStore.push({
					url: '#' + ServicesRouter.details(this.id),
					title: this.model.name
				});
			}
		}

		this.initialized({
			title: i('Viewer')
		});
	},

	calculatePositions: function (panel) {
		const $container = $('.geDiagramContainer');
		const containerPos = $container.offset();
		const width = $container.outerWidth();
		const height = $container.outerHeight();
		var defaultSize = {width: 600, height: 250}
		if (panel === 'elementChart') {
			defaultSize = {width: 600, height: 330}
		}

		if (panel === 'metricsWidget' || panel === 'preview') {
			defaultSize = {width: 600, height: 250}
		}

		if (panel === 'qualifierDetails') {
			defaultSize = this.designer.store.settings.autoLayout || !this.sqDetailsWindowConfig
				? getDefaultSize(panel)
				: this.sqDetailsWindowConfig;
		}

		let previousWindow;

		if (this.otherWindowPositions.length) {
			previousWindow = this.otherWindowPositions[this.otherWindowPositions.length - 1];
		}
		else {
			previousWindow = getAutoWindowPositions('qualifiers-viewer');
		}

		const {width: pWidth, height: pHeight, position: {top: pTop, left: pLeft}} = previousWindow;

		const position = {
			...defaultSize,
			position: {
				top: pTop,
				left: pLeft - defaultSize.width - 20
			}
		};

		if (position.position.left < 0) {
			position.position.left += defaultSize.width + 20;
			position.position.top += pHeight + 40;
		}

		if (position.position.top + position.height > containerPos.top + height) {
			position.position.top -= pHeight + 40;
			position.position.left += pWidth + 20;
		}

		this.otherWindowPositions.push(position);

		return position;
	},

	initKendoComponents: function () {
		//window.addEventListener('resize', () => this.onResize());

		$('body').append('<div id="widget_wrapper" class="cw_viewer_sq_details"></div>');
		this.widgetWrapper = $('#widget_wrapper');
		this.viewerArea = $('#painting_area');
		this.viewerAreaOffset = this.viewerArea.offset();

		this.kendoWindowQualifiers = $('#cw_service_qualifiers').kendoWindow(
			addDesignerWindowStateStorage(this.designer,'qualifiers-viewer', {
				//draggable: false,
				visible: !this.userSettings.serviceElementPanelClosed,
				minWidth: 215,
				minHeight: 100,
				title: lang.designer.SERVICE_ELEMENT,
				appendTo: '#widget_wrapper',
				actions: [
					'Close'
				],
				activate: $.proxy(function (e) {
					e.sender.wrapper.find('.k-window-titlebar').on('dblclick', function (event) {
						event.stopPropagation();
					});

					this.firstWindowEl = $('.k-window:eq(0)');
					this.firstWindow = {
						position: this.firstWindowEl.offset(),
						dimension: {
							width: this.firstWindowEl.outerWidth(),
							height: this.firstWindowEl.outerHeight()
						}
					};
				}, this),
				dragend: $.proxy(function (e) {
					Utils.checkWindowPosition(e, this.viewerArea);
				}, this),
		})).data('kendoWindow');

		this.designer.editorUi.addWindowButtonToToolbar(
			"sv-service-element-widget-toggle",
			i('Show/hide service elements window'),
			this.kendoWindowQualifiers,
			() =>  $('<a href="javascript:void(0)" class="geButton geToolbar__service-element"><i class="glyphicons credit"></i></a>')[0]
		);

		this.designer.store.am.addToggleWindowAction({
			id: Actions.ServiceDesignerElementWindowToggle,
			title: i('Show/hide service elements window'),
			icon: GroupOutlined
		}, this.kendoWindowQualifiers);

		var scope = this;
		//KendoWindow Model Preview
		this.kendoWindowModalPreview = $('#cw_service_model_preview').kendoWindow(
			addDesignerWindowStateStorage(this.designer,'preview-viewer',{
				visible: !this.userSettings.serviceHistoryPanelClosed,
				widget: null, //custom property
				resizable: false,
				minWidth: 300,
				minHeight: 100,
				title: lang.viewer.METRICS_PREVIEW,
				appendTo: '#widget_wrapper',
				actions: [
					'toggle',
					'Close'
				],
				activate: function (e) {
					e.sender.wrapper.find('.k-window-title').addClass('ellipsis');
					e.sender.wrapper.off('click', '.k-i-toggle').on('click', '.k-i-toggle', $.proxy(scope.onWindowToggleClick, this));
					e.sender.wrapper.off('click', '.k-i-restore').on('click', '.k-i-restore', $.proxy(scope.onMaximizeWindow, this));

					e.sender.wrapper.find('.k-window-titlebar').on('dblclick', function (e) {
						e.stopPropagation();
					});
					e.sender.setOptions({
						resizable: true
					});
					/*
					 * 11-04-2017
					 * Proper event is not handled by Kendo
					 * @todo check on later versions for a public Kendo method
					 * */
					e.sender.resizing._draggable.userEvents.bind("release", function (e) {
						var target = $(e.sender.currentTarget),
							window = target.closest('.k-window').find('.k-window-content.k-content').data('kendoWindow');
						if (window) {
							if (window.widget && window.widget.onResize) {
								window.widget.onResize();
							}
						}
					});
				},
				resize: (e) => {
					//this.kendoWindowModalPreview.widget = this.widget;
					//if (args.widgetType == 'metricsWidget' || args.widgetType == 'elementChart') {
						e.sender.widget?.chart.setSize(e.width, e.height);
					//}
				},
				dragend: $.proxy(function (e) {
					Utils.checkWindowPosition(e, this.viewerArea);
				}, this),
				open: $.proxy(function () {
					if (!$('#cw_preview_widget').height()) {
						$('#cw_preview_widget').css('height', 250);
					}
					$('#cw_service_model_preview').css('position', 'unset')
				}, this),
			})).data('kendoWindow');

		this.designer.editorUi.addWindowButtonToToolbar(
			"sv-service-history-widget-toggle",
			i('Show/hide servicehistory window'),
			 this.kendoWindowModalPreview,
			() =>  $('<a href="javascript:void(0)" class="geButton geToolbar__history"><i class="glyphicons book-open"></i></a>')[0]
		);
		this.kendoWindowModalPreview.wrapper.find('.k-i-toggle').parent().off('click').on('click',  $.proxy(scope.onWindowToggleClick, scope));
		this.designer.store.am.addToggleWindowAction({
			id: Actions.ServiceDesignerHistoryWindowToggle,
			title: i('Show/hide servicehistory window'),
			icon: HistoryOutlined
		}, this.kendoWindowModalPreview);

		var serviceQualifierId = Utils.guid();

		this.openWindow({
			serviceQualifierId: serviceQualifierId,
			windowTitle: lang.service.SERVICE_LOG,
			widgetType: 'statusLog',
		});

		this.removeMask();
	},
	/**
	 * Handle event for the window toggle button
	 * @param {Object} e The click event object
	 */
	onWindowToggleClick: function (e) {
		var customContainer = $(e.currentTarget).closest('.k-window').find('.cw_widget_settings');
		if (customContainer.length) {
			if (customContainer.is(':visible')) {
				customContainer.slideUp(150);
			} else {
				customContainer.slideDown(350);
			}
		}

		e.preventDefault();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('.cw_smp_se_item').off();
		this.document.off('dblclick');
		$('#cw_viewer_to_designer').off();
		$('body').off('click', '.cw_agent_name');
		$('body').off('click', '.cw_asset_name');
		$('body').off('click', '.cw_assetgroup_name');
		$('body').off('click', '.cw_monitor_name');

		$('#menu_clear_context').off('click.viewer');

		$('#cw_revert').off();
		//$('#onscreen_element').off();
		//$('#onscreen_history').off();
		//$('#onscreen_log').off();

		LocalEventsManager.unbind('highlightItem');
		LocalEventsManager.unbind('previewWidgetAdded');
		LocalEventsManager.unbind('getActiveWidgetSettings');
		LocalEventsManager.unbind('setNotificationHighlightItem');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		if (State.mainApp.session.hasRole('SERVICE_MODEL_UPDATE')) {
			$('#cw_viewer_to_designer').on('click', $.proxy(this.onViewerToDesignerClick, this));
		} else {
			$('#cw_viewer_to_designer').remove();
		}
		$('.cw_smp_se_item').on('click', $.proxy(this.onSeClick, this));
		$('#cw_service_history_view').on('click', $.proxy(this.onServiceHistoryClick, this));
		$('#cw_service_ruleset_view').on('click', $.proxy(this.onServiceRulesetClick, this));
		$('#cw_element_chart_view').on('click', $.proxy(this.onServiceElementChartClick, this));
		$('body').on('click', '.cw_agent_name', $.proxy(function (e) {
			this.setHighlightItem(e);
			this.onAgentNameClick(e);
		}, this));
		$('body').on('click', '.cw_asset_name', $.proxy(function (e) {
			this.setHighlightItem(e);
			this.onSqAssetNameClick(e);
		}, this));
		$('body').on('click', '.cw_assetgroup_name', $.proxy(function (e) {
			this.setHighlightItem(e);
			this.onSqAssetGroupNameClick(e);
		}, this));
		$('body').on('click', '.cw_monitor_name', $.proxy(function (e) {
			this.setHighlightItem(e);
			this.onSqMonitorNameClick(e);
		}, this));

		$('#menu_clear_context').on('click.viewer', $.proxy(this.onClearContext, this));

		$('#cw_revert').on('click', $.proxy(this.onRevertLayout, this));

		LocalEventsManager.bind('highlightItem', $.proxy(this.setMetricsHighlightItem, this));
		LocalEventsManager.bind('previewWidgetAdded', $.proxy(this.renderLogsView, this));
		LocalEventsManager.bind('getActiveWidgetSettings', $.proxy(this.getActiveWidgetSettings, this));
		LocalEventsManager.bind('setNotificationHighlightItem', $.proxy(this.setNotificationHighlightItem, this));
	},
	/*
	 * Handler function for getting the active widget settings (metrics or history widget)
	 * @param {Object} settings Object where settings will be saved
	 * */
	getActiveWidgetSettings: function (settings) {
		if (this.userSettings.parentModuleSufix === 'ServiceDetails' || this.userSettings.parentModuleSufix === 'IncidentForm') {
			settings.period = this.userSettings.period;
			settings.startDate = new Date(this.userSettings.startDate);
			settings.endDate = new Date(this.userSettings.endDate);
		} else if (this.widget.zoomPeriod) {
			settings.period = this.widget.zoomPeriod;
			settings.startDate = this.widget.zoomStartDate;
			settings.endDate = this.widget.zoomEndDate;
		} else {
			settings.period = this.widget.configuration.period;
			settings.startDate = parseInt(this.widget.configuration.startDate);
			settings.endDate = parseInt(this.widget.configuration.endDate);
		}
	},
	/**
	 * Handler function for click event on viewer to designer button element
	 */
	onViewerToDesignerClick: async function (e) {
		if (State.mainApp.session.hasRole('SERVICE_MODEL_UPDATE')) {
			if (!await isServiceLocked(this.id)) {
				if(this.draftUpdateTime) {
					this.app.navigate(ServiceDesignerRouter.draft(this.id));
				}else{
					this.app.navigate(ServiceDesignerRouter.root(this.id));
				}
			}
		} else {
			this.showStatusMessage(lang.service.messages.SERVICE_MODEL_UPDATE_PERMISIONS, 'error');
		}
	},
	/*
	 * Handler function for setting highlight item
	 * @param {String} notificationId The notification id that we must save
	 * */
	setNotificationHighlightItem: function (notificationId) {
		var widget = this.widget;

		var highlightObj = {
			notificationId: notificationId,
			node: $('.cw_highlight_node').attr('id'),
			element: $('.cw_current_qualifier').attr('id') || $('.cw_current_qualifier .cw_smp_se_name').data('id'),
			timeSelector: widget.configuration.period,
			gridFilter: this.logsView.dataSource.filter()
		};

		if (this.widget.onZoom) {
			highlightObj.zoom = {
				period: widget.zoomPeriod,
				startDate: widget.zoomStartDate,
				endDate: widget.zoomEndDate
			};
		}

		NavigationStore.updateCurrentItem({
			highlightObj: highlightObj
		});
	},
	/*
	 * Handler function for setting highlight item when clicking asset in metrics widget window
	 * @param {Object} e The eventmanager event object
	 * */
	setMetricsHighlightItem: function (e) {
		this.setHighlightItem(e.element);
	},
	/*
	 * Handler function for setting the item which will be highlighted
	 * */
	setHighlightItem: function (e) {
		var target = $(e.currentTarget);
		var window = target.closest('.k-window-content.k-content').data('kendoWindow');
		var highlightObj = window.highlightObj || window.options.highlightObj;

		/*
		 * Check if target is coming from new opened window
		 * */
		if (!target.closest('#cw_service_model_preview').length) {
			highlightObj.openWindow = true;
		} else {
			highlightObj.openWindow = false;
		}

		NavigationStore.updateCurrentItem({
			highlightObj: highlightObj
		});
	},
	/*
	 * Event handler for clicking maximize
	 */
	onMaximizeWindow: function (e) {
		var windowId = $(e.currentTarget).closest('.k-window').find('.k-content').attr('id'),
			window = $('#' + windowId).data('kendoWindow');
		setTimeout(function () {
			window.widget.onResize();
		}, 100);
	},
	/**
	 * Called when a state event is received
	 * @param {Object} data The event data
	 */
	onEvent: function (data) {
		var serviceSummaryEvents = [];
		var widget, widgetID, event;


		for (var i = 0, length = data.length; i < length; i++) {
			event = data[i];

			if (this.widget && this.widget.id === event.wId) {
				this.widget.onEvent(event);
				continue
			}

			if (event.eventType === 'ServiceModel') {
				State.mainApp.reloadCurrentModule();
			} else {
				if (event.qualifiers && event.qualifiers.length) {
					this.updateQualifiersState(event.qualifiers);
				}
				if (event.eventType === 'AgentState') {
					//for the case when the qualifiers details window is opened and update can not be done in other way
					setTimeout($.proxy(function () {
						this.updateAgentState();
					}, this), 1000);
				}
			}

			if (this.widgetsByWindowID[event.wId]) {
				widgetID = this.widgetsByWindowID[event.wId];
				if (widgetID) {
					widget = this.widgets[widgetID];
					if (widget) {
						//if it has eventType property means it is a widget
						if (widget.eventType || widget.type) {
							widget.onEvent(event);
						} else {
							this.addToWidgetGridDataSource(widget, event.metric);
						}
					}
				}
			}
		}
	},
	updateAgentState: function () {
		var cell = this.graph.getSelectionCell();
		var node = cell.customData;
		for (var i = 0; i < node.qualifiers.length; i++) {
			var agentContainer = $('[data-qualifierid="' + node.qualifiers[i].id + '"]');
			if (agentContainer.length) {
				var agentDownIconContainer = agentContainer.siblings('.cw_agent_down_icon');
				if (agentDownIconContainer.length) {
					if (node.qualifiers[i].agentStatus === 'ACTIVE') {
						agentDownIconContainer.remove();
						agentContainer.removeClass('cw_agent_down_name');
					}
				} else {
					if (node.qualifiers[i].agentStatus === 'AGENT_DOWN') {
						agentContainer.before('<span class="cw_agent_indicator cw_status is_critical cw_agent_down_icon"><span class="cw_indicator glyphicons status_icon remove"></span></span>');
						agentContainer.addClass('cw_agent_down_name');
					}
				}
			}
		}
	},
	/**
	 * Handler function to update qualifiers state
	 * */
	updateQualifiersState: function (updatedQualifiersList) {
		let aQualifierOfCurrentElementIsUpdated = false;
		let qualifiersOfCurrentElement = this.figureId
			? this.model.nodes.find(x => x.id  == this.figureId)?.qualifiers || []
			: [];

		let selectedQualifierUpdated = false;

		let selectedQualifierId = $('.smp_sq_list li.cw_current_qualifier').attr('id');
		if (selectedQualifierId != null) {
			selectedQualifierId = selectedQualifierId.replace('smp_sq_el_', '');
		}

		this.model.nodes.forEach( node => {
			updatedQualifiersList.forEach( qualifier => {
				if(updateFromEvent(node.qualifiers, qualifier)){
					if(selectedQualifierId == qualifier.sourceId){
						selectedQualifierUpdated = true;
					}

					if(qualifiersOfCurrentElement.find(x => x.id == qualifier.sourceId)){
						aQualifierOfCurrentElementIsUpdated = true;
					}
				}
			});
		});


		let cell = this.graph.getSelectionCell();
		if(!aQualifierOfCurrentElementIsUpdated || !cell)
			return;

		this.updateSqList(cell, false);

		if (selectedQualifierId != null) {
			if(selectedQualifierUpdated){
				this.selectQualifier(selectedQualifierId)
			}else{
				this.highlightQualifier(selectedQualifierId);
			}
		}
	},


	initDesigner: function () {
		if( this.designer != null ){
			this.designer.destroy();
			this.designer = null;
		}

		let xmlWithConstraints = this.addPortConstraints(this.xml);
		this.xml = xmlWithConstraints;

		this.designer = new Designer({
			container: document.getElementById("painting_area"),
			chromeless: true,
			mode: "service",
			toolbar: 'sd-draft | zoom-in zoom-out | sd-reset-layout sd-autolayout | sd-element-window-toggle sd-history-window-toggle sd-log-window-toggle',
			data: {xml: this.xml, model: this.model},
			accountId: this.account.id,
			subaccountId: this.subaccountId,
			allowSelectionInReadOnlyMode: true,
			disableCellEdit: true,
			navigateOnServiceLink: true,
			features:{
				presentationMode: true,
				autoLayout: true
			},
			onLoaded: (designer) => {

				this.initKendoComponents();
				this.graph = designer.editorUi.editor.graph;

				let serviceGraphZoom = this.serviceSettings.get('zoom');
				if (serviceGraphZoom) {
					this.graph.zoomTo(serviceGraphZoom);
				}

				this.trackSelectionChange();

				if (this.highlightObj) {
					if (this.firstLoad && this.highlightObj.node) {
						nodeToBeHighlighted = this.highlightObj.node;
					}
					if (this.highlightObj.element) {
						for (var j = 0; j < designer.config.data.model.nodes.length; j++) {
							var currentNode = designer.config.data.model.nodes[j];
							for (var k = 0; k < currentNode.qualifiers.length; k++) {
								if (this.highlightObj.element === currentNode.qualifiers[k].id) {
									var nodeToBeHighlighted = currentNode.id;
								}
							}
						}
						this.highlightObj.element = 'smp_sq_el_' + this.highlightObj.element;
					}
					this.designer.selectElement(nodeToBeHighlighted);
				} else {
					this.selectFirstBreachedNode();
				}

				if ($('#main_loading_mask').length) {
					$('#main_loading_mask').remove();
				}
			}
		});
	},

	addPortConstraints: function (xml) {
		let pattern = '="root"';
		let index = xml.search(pattern);
		if (index) {
			let rootString = xml.substr(index);
			let rootPattern = 'style="';
			let rootIndex = rootString.search(rootPattern) + rootPattern.length;
			if (rootIndex) {
				let rootUpdatedString = rootString.substr(0, rootIndex) + 'portConstraint=south;points=[[0.5,1]];' + rootString.substr(rootIndex);
				let newXml = xml.substr(0, index) + rootUpdatedString;
				return newXml;
			} else return xml;
		} else return xml;
	},

	trackSelectionChange: function () {
		this.graph.getSelectionModel().addListener(mxEvent.CHANGE, () => this.onSelectionChanged());
	},

	onSelectionChanged(){
		var cell = this.graph.getSelectionCell();
		if(cell == null || cell.customData == null){
			this.figureId = null;
			return;
		}

		this.figureId = cell.customData.id;

		this.onServiceElementClick(cell);
	},

	/**
	 * Method called to repaint the SQ list
	 */
	updateSqList: function (cell, highlightElement = true) {
		var serviceQualifiers = cell.customData.qualifiers;

		var lastPos, pos, className, firstBreached;
		var html = '', icon = '';
		var qualifierNr = 1;

		var toolbar = $('.cw_sm_period').find('.cw_multi_toggle');

		$('#cw_smp_metrics .cw_section_content').empty();
		$(toolbar).find('li.is_selected').removeClass('is_selected');
		$(toolbar).find('li:nth-child(1)').addClass('is_select ed');
		if (cell.customData.type == 'LINK') {
			var smpSqList = $('.smp_sq_list');
			if (cell.customData.state === 'INVALID') {
				$('.cw_smp_se_container').addClass('cw_current_qualifier');
				smpSqList.empty().text(lang.viewer.messages.SERVICE_DELETED);
			} else {
				const serviceViewerLink = lang.viewer.messages.GOTO_VIEWER;
				const serviceDetailsLink = lang.viewer.messages.GOTO_SERVICEDETAILS;
				smpSqList.empty().append(`<li><a href="#${ServicesRouter.viewer(cell.customData.linkServiceId)}">${serviceViewerLink}</a></li>`)
				smpSqList.append(`<li><a href="#${ServicesRouter.details(cell.customData.linkServiceId)}">${serviceDetailsLink}</a></li>`)
				$('.cw_smp_se_item').trigger('click');
			}
		} else if (serviceQualifiers.length) {
			var list = $('.smp_sq_list'), type, itemClass = '';
			this.currentElementQualifiers = []
			for (var j = 0, length = serviceQualifiers.length; j < length; j++) {
				this.currentElementQualifiers.push(serviceQualifiers[j].id);
				let agentStatus = serviceQualifiers[j].agentStatus;

				className = serviceQualifiers[j].className;
				pos = className.indexOf('.');
				if (pos > -1) {
					lastPos = 0;
					while (pos > -1) {
						lastPos = pos;
						pos = className.indexOf('.', pos + 1);
					}
					type = className.substr(lastPos + 1);
				} else {
					type = className;
				}
				if (!serviceQualifiers[j].deleted) {
					let state = serviceQualifiers[j].state;

					var colorIndex = Utils.getServiceQualifierColorIndex(state);
					if (serviceQualifiers[j].state === 'INACTIVE') {
						type = serviceQualifiers[j].description || '';
						if (!firstBreached) {
							firstBreached = serviceQualifiers[j].id;
						}
					}

					var properties = QualifiersHelper.getCalculatedProperties(serviceQualifiers[j]);

					icon = '<span style="color:#FFF" data-state="' + state
						+ '" class="smp_sq_state cw_status_widget_color cw_color' + colorIndex
						+ ' ' + itemClass + ' ' + (serviceQualifiers[j].shared && !properties.inWarning ? ' glyphicons share ' : '')
						+ ' " title="' + properties.iconTooltip + '">' + (properties.inMaintenance ? Utils.renderWrench() : properties.inWarning ? Utils.renderExclamationMark() : '') + '</span>';

					html += '<li class="cw_item" id="smp_sq_el_' + serviceQualifiers[j].id
						+ '" data-type="' + serviceQualifiers[j].className
						+ '" title="' + type + '">' + icon
						+ '<span class="cw_qualifier_nr left">' + (qualifierNr++)
						+ '.</span><span class="smp_sq_name ellipsis">'
						+ serviceQualifiers[j].name
						+ `</span><div class="smp_sq_container"><span class="smp_sq_test glyphicons record right" title=${i('Test configuration')}></span><span class="smp_sq_preview glyphicons justify right" `
						+ 'title="' + kendo.template(lang.viewer.messages.SERVICE_QUALIFIER_TITLE)({sqName: serviceQualifiers[j].name})
						+ '"></span><span class="smp_sq_view glyphicons stats right" title="'
						+ kendo.template(lang.viewer.messages.METRIC_TITLE)({sqName: serviceQualifiers[j].name})
						+ '"></span><span class="smp_sq_raw_data glyphicons calendar right" title="'
						+ kendo.template(lang.viewer.messages.METRIC_DATA_TITLE)({sqName: serviceQualifiers[j].name})
						+ '"></span></div></li>';
				}
			}
			list.empty().html(html);

			list.find('li[id^="smp_sq_el_"]').off().on('click', $.proxy(this.onSqClick, this));
			list.find('.smp_sq_preview').off().on('click', $.proxy(this.onSqPreview, this));
			list.find('.smp_sq_view').off().on('click', $.proxy(this.onSqView, this));
			list.find('.smp_sq_raw_data').off().on('click', $.proxy(this.onSqRawDataView, this));
			list.find('.smp_sq_test').off().on('click', $.proxy(this.onTestConfigClick, this));

			highlightElement && this.highlightSqElement();

			$('#cw_element_chart_view').removeClass('hide');
		} else {
			$('#cw_element_chart_view').addClass('hide');

			$('.smp_sq_list').empty().append('<li class="noServiceQualifiers">' + lang.viewer.messages.NO_SERVICE_QUALIFIERS + '</li>');
			$('.noServiceQualifiers').on('click', function (e) {
				e.stopPropagation();
			});
			$('.cw_smp_se_item').trigger('click');
		}
		if (!State.mainApp.session.hasRole('SERVICE_MODEL_UPDATE')) {
			$('.smp_sq_test').addClass('hide');
		}
	},

	/**
	 * Handler function for the click event on SQ list item
	 * @param {Object} event The click event object
	 */
	onSqClick: function (event) {
		event.stopPropagation();
		const qualifierId = $(event.currentTarget).attr('id').replace('smp_sq_el_', '');
		this.selectQualifier(qualifierId);
	},

	highlightQualifier(qualifierId){
		$('.cw_smp_se_container').removeClass('cw_current_qualifier');
		$('.smp_sq_list li').removeClass('cw_current_qualifier');

		var target = $('#smp_sq_el_' + qualifierId);
		target.addClass('cw_current_qualifier');
		return target;
	},

	selectQualifier(qualifierId ){
		let target = this.highlightQualifier(qualifierId);

		var qualifierTitle;

		$('#cw_service_model_preview').parent().find('.cw_widget_settings').data('servicequalifierid', qualifierId);

		if (this.widgets[this.id]) {
			if (this.widgets[this.id].destroy) {
				this.widgets[this.id].destroy();
			}
			delete this.widgets[this.id];
			$('#cw_service_model_preview').parent().find('.cw_missing_data').detach();
		}

		//console.log('Show qualifier notifications');
		if (this.highlightObj && this.highlightObj.notificationId) {
			this.reloadObject = $.extend({
				type: 'qualifier',
				qualifierId: qualifierId
			}, this.highlightObj);
		}
		else if (this.configuration?.period && this.configuration?.startDate && this.configuration?.endDate) {
			this.reloadObject = {
				type: 'qualifier',
				qualifierId: qualifierId,
				timeSelector: this.configuration.period,
				period: {
					startDate: +this.configuration.startDate,
					endDate: +this.configuration.endDate
				}
			}
		}
		else {
			this.reloadObject = {
				type: 'qualifier',
				qualifierId: qualifierId,
				timeSelector: this.userSettings.period,
				period: {
					startDate: this.userSettings.startDate,
					endDate: this.userSettings.endDate
				}
			}
		}

		this.reloadStatesLogsDS(this.reloadObject);

		var qualifierName = $(target).find('.smp_sq_name').text();
		qualifierTitle = $(target).find('.cw_qualifier_nr').text() + " " + qualifierName;
		var previewWindow = $('#cw_service_model_preview').data('kendoWindow');
		previewWindow.title(qualifierTitle);

		previewWindow.highlightObj = {
			node: $('.cw_highlight_node').attr('id'),
			element: $('.cw_current_qualifier').attr('id')
		};

		$('#cw_preview_widget').empty();
		kendo.ui.progress($('#cw_preview_widget'), true);
		var qualifierType = target.attr('data-type');
		if (qualifierType === 'datacollector.health.HealthIndexConfiguration' || qualifierType === 'datacollector.health.GroupHealthIndexConfiguration') {
			var defaultIgnoreMissingData = true;
		}
		var defineNewWidget = $.proxy(function () {
			kendo.ui.progress($('#cw_preview_widget'), false);

			const period = this.highlightObj?.timeSelector
				|| this.configuration?.period
				|| this.userSettings.period
				|| this.notificationPeriod
				|| this.defaultWindowsTimeSelector
				|| 'LASTDAY'
			this.defaultWindowsTimeSelector = period;
			const startDate = this.highlightObj?.period?.startDate
				|| parseInt(this.configuration?.startDate)
				|| this.userSettings.startDate;
			const endDate = this.highlightObj?.period?.endDate
				|| parseInt(this.configuration?.endDate)
				|| this.userSettings.endDate;

			this.logsToolbar?.setState({
				timePeriod: period
			});

			var metricsWindowHeight = 250;

			this.firstHistoryWidgetLoad = false;

			this.widget = new MetricsWidget({
				id: Utils.guid(),
				type: 'metrics',
				title: '',
				renderTo: 'cw_preview_widget',
				isViewer: true,
				defaultIgnoreMissingData: defaultIgnoreMissingData,
				viewerWidgetHeight: metricsWindowHeight,
				onWidgetPeriodChanged: this.onWidgetPeriodChanged,
				viewerContext: this,
				viewerDefaultWindow: true,
				customControls: {
					target: $('#cw_service_model_preview').parent(),
					change: $.proxy(function (e) {
						//$('#cw_service_model_preview').closest('.k-window').find('.k-i-toggle').trigger('click');
						var realTimeSelector;
						if (e.sender.value && typeof(e.sender.value) !== 'function') {
							realTimeSelector = e.sender.value;
						} else {
							realTimeSelector = e.sender.value();
						}
						this.reloadObject = {
							type: this.reloadNotificationType,
							qualifierId: qualifierId,
							timeSelector: realTimeSelector,
							period: {
								startDate: e.startDate,
								endDate: e.endDate
							}
						};
						this.reloadStatesLogsDS(this.reloadObject);
					}, this),
					toggleClick: function (value) {
						$('#cw_service_model_preview').closest('.k-window').find('.k-i-toggle').trigger('click');
					},
					zoom: $.proxy(function (args) {
						this.reloadObject = {
							type: this.reloadNotificationType,
							qualifierId: qualifierId,
							timeSelector: args.period,
							period: {
								startDate: new Date(args.startDate),
								endDate: new Date(args.endDate)
							}
						};
						this.reloadStatesLogsDS(this.reloadObject);
					}, this)
				},
				configuration: {
					serviceId: this.id,
					serviceElementId: this.figureId,
					serviceQualifierId: qualifierId,
					timezone: this.timezone || Cookies.CeesoftTimezone,
					period: period || this.userSettings.cachedCategory.period,
					startDate: startDate,
					endDate: endDate,
					chartType: this.userSettings.cachedCategory.chartType || 'line',
					showThreshold: this.userSettings.cachedCategory.showThreshold,
					showRegression: this.userSettings.cachedCategory.showRegression,
					ignoreMissingData: this.userSettings.cachedCategory.ignoreMissingData,
					hideErrors: this.userSettings.cachedCategory.hideErrors,
					qualifierType: qualifierType,
					qualifierName: qualifierName
				},
				onConfigurationChanged: config => this.storeMetricsWidgetConfig(config),
				removeContainer: true,
				settingsKey: 'service-viewer'
			});

			let widgetId = 'cw_view_widgetmetricsWidget' + qualifierId;
			this.widgets[widgetId] = this.widget;
			this.widgetsByWindowID[this.widget.id] = widgetId;

			this.kendoWindowModalPreview.widget = this.widget;

			this.widgets[this.id] = this.widget;
			LocalEventsManager.trigger('previewWidgetAdded');
			LocalEventsManager.unbind('previewWidgetAdded');
		}, this);
		defineNewWidget();
	},

	/**
	 * Handler function for the click event on service element list item
	 * - clicking the nodes
	 */
	onSeClick: function (event) {
		$('.smp_sq_list').find('.cw_current_qualifier').removeClass('cw_current_qualifier');
		$('.cw_smp_se_container').addClass('cw_current_qualifier');

		var qualifierTitle = lang.viewer.SERVICE_HISTORY + ' - ' + $(event.currentTarget).find('.cw_smp_se_name').text();

		$('#cw_service_model_preview').data('kendoWindow').title(qualifierTitle);

		if (this.widgets[this.id]) {
			if (this.widgets[this.id].destroy) {
				this.widgets[this.id].destroy();
			}
			delete this.widgets[this.id];
		}

		$('#cw_service_model_preview').parent().find('.cw_multi_toggle').remove();

		kendo.ui.progress($('#cw_preview_widget'), true);
		var cell = this.graph.getSelectionCell();


		var rulesetDescription = $('#cw_service_ruleset_description');
		if (cell.customData.rule && cell.customData.rule.type !== 'Default') {
			if (cell.customData.rule.type === 'Ruleset') {
				rulesetDescription.removeClass('hide').text(lang.designer.STATE_EXPRESSION_RULESET);
			} else {
				rulesetDescription.removeClass('hide').text(cell.customData.rule.type + ' ' + lang.designer.RULESET.toLowerCase());
			}
		} else {
			rulesetDescription.addClass('hide');
		}
		var period = this.defaultWindowsTimeSelector
			|| this.highlightWidgetSettings?.period
			|| this.highlightObj?.timeSelector
			|| this.configuration?.period
			|| this.notificationPeriod
			|| this.userSettings.period
			|| 'LASTDAY';

		this.defaultWindowsTimeSelector = period;

		this.logsToolbar?.setState({
			timePeriod: period
		});

		let timeSelector = {
			timeSelector: period,
			period: {
				startDate: parseInt(this.configuration?.startDate) || this.userSettings?.startDate || null,
				endDate: parseInt(this.configuration?.endDate) || this.userSettings?.endDate || null
			}
		}

		if (cell.isServiceRoot()) {
			this.reloadObject = {
				type: 'service',
				...timeSelector
			};

		} else {
			this.reloadObject = {
				type: 'element',
				element: cell,
				...timeSelector
			};
		}

		if(this.reloadObject.timeSelector == 'CUSTOM'){
			this.reloadObject.period = {
				startDate: this.configuration?.startDate || this.userSettings.startDate || null,
				endDate: this.configuration?.endDate || this.userSettings.endDate || null,
			}
		}

		this.reloadStatesLogsDS(this.reloadObject);

		var defineNewWidget = $.proxy(function () {
			kendo.ui.progress($('#cw_preview_widget'), false);
			if( this.widget){
				this.widget.destroy();
			}

			this.widget = new ServiceHistoryWidget({
				id: Utils.guid(),
				type: 'history',
				title: '',
				noTitleNeeded: true,
				renderTo: 'cw_preview_widget',
				isViewer: true,
				cellType: cell.isServiceRoot() ? 'service' : 'element',
				onWidgetPeriodChanged: this.onWidgetPeriodChanged,
				viewerContext: this,
				viewerDefaultWindow: true,
				viewerFirstRender: this.firstHistoryWidgetLoad,
				customControls: {
					target: $('#cw_service_model_preview').parent(),
					change: $.proxy(function (e) {
						//$('#cw_service_model_preview').closest('.k-window').find('.k-i-toggle').trigger('click');
						this.reloadObject = {
							type: this.reloadNotificationType,
							element: cell,
							timeSelector: typeof(e.sender.value) === 'function' ? e.sender.value() : e.sender.value,
							period: {
								startDate: new Date(e.startDate),
								endDate: new Date(e.endDate)
							}
						};
						this.reloadStatesLogsDS(this.reloadObject);
					}, this),
					zoom: $.proxy(function (args) {
						this.reloadObject = {
							type: this.reloadNotificationType,
							element: cell,
							timeSelector: args.period,
							period: {
								startDate: new Date(args.startDate),
								endDate: new Date(args.endDate)
							}
						};
						this.reloadStatesLogsDS(this.reloadObject);
					}, this)
				},
				configuration: {
					serviceId: this.id,
					serviceElementId: this.figureId,
					period: period,
					timezone: this.timezone || Cookies.CeesoftTimezone,
					hideFooter: true,
					startDate: this.configuration?.startDate || this.userSettings.startDate || null,
					endDate: this.configuration?.endDate || this.userSettings.endDate || null,
					isRoot: cell.isServiceRoot()
				},
				removeContainer: true
			});

			this.firstHistoryWidgetLoad = false;
			this.kendoWindowModalPreview.widget = this.widget;
			this.widgets[this.id] = this.widget;
			this.historyWidget = this.widget;
			LocalEventsManager.trigger('previewWidgetAdded');
			LocalEventsManager.unbind('previewWidgetAdded');
		}, this);
		defineNewWidget();
	},
	/**
	 * Handler function for the click event on SQ test configuration icon
	 */
	onTestConfigClick: function (e) {
		const serviceId = this.id;
		const elementId = this.figureId;
		const target = $(e.currentTarget);
		const qualifierId = target.closest('li').attr('id').replace('smp_sq_el_', '');

		const wrapper = this.kendoWindowQualifiers.wrapper;

		const height = wrapper.height();
		const width = wrapper.width();
		const {top, left} = wrapper.position();

		const params = {
			serviceId,
			elementId,
			qualifierId,
			height,
			width,
			top,
			left,
			name: target.closest('li').find('.smp_sq_name').text()
		};

		ReactDOM.unmountComponentAtNode($('.cw_test_window').get(0));

		return ReactDOM.render(<ExternalConfigErrorWindow {...params}/>, $('.cw_test_window').get(0));
	},
	/**
	 * Handler function for the click event on SQ preview icon
	 */
	onSqPreview: function (e) {
		var qualifier, windowTitle;
		var target = $(e.currentTarget);
		var parent = target.closest('li.cw_item');
		if (parent.attr('id')) {
			var sqId = parent.attr('id').substr(10);
			var cell = this.graph.getSelectionCell();
			var node = cell.customData;

			e.stopPropagation();

			for (var j = 0, length = node.qualifiers.length; j < length; j++) {
				if (node.qualifiers[j].id === sqId) {
					qualifier = node.qualifiers[j];
					break;
				}
			}

			windowTitle = parent.find('.cw_qualifier_nr').text() + parent.find('.smp_sq_name').text();

			qualifier.figureId = this.figureId;
			this.openWindow({
				event: e,
				serviceQualifierId: sqId,
				widgetType: 'qualifierDetails',
				windowDim: {
					width: 426,
					height: 410
				},
				windowTitle: windowTitle,
				qualifier: qualifier,
				resizable: true,
				disableToggleAction: true,
				highlightObj: {
					node: $('.cw_highlight_node').attr('id'),
					element: target.closest('li').attr('id')
				}
			});
		}
	},
	/*
	 * Handler function
	 * */
	onSqRawDataView: function (e) {
		e.stopPropagation();
		let serviceQualifierId = $(e.currentTarget).closest('li.cw_item').attr('id').replace('smp_sq_el_', '');

		if (this.openedRawDataWindows[serviceQualifierId]) {
			this.openedRawDataWindows[serviceQualifierId].restore();
			this.openedRawDataWindows[serviceQualifierId].toFront();
		} else {
			let windowTitle = lang.viewer.METRICS_DATA + ' - ' + $(e.currentTarget).closest('li.cw_item').find('.cw_qualifier_nr').text() + " " + $(e.currentTarget).closest('li.cw_item').find('.smp_sq_name').text();
			let containerId = serviceQualifierId;
			this.openedRawDataWindowIds.push(containerId);
			$('body').append('<div id="metricpopup' + containerId + '" class="cw_table_info_fake_container"></div>');
			let coordinates = this.calculatePositions('rawGrid');
			let subscriberId = Utils.guid();
			this.windowSubscribe(subscriberId, serviceQualifierId);
			const unsubscribe = () => RemoteEventsManager.unsubscribe(subscriberId);
			ReactDOM.render(<MetricTableDataWindow
				metricId={serviceQualifierId}
				title={windowTitle}
				skipDimensionsAdjust={true}
				height={coordinates?.height}
				width={coordinates?.width}
				actions={['Minimize', 'Close']}
				coordinates={coordinates?.position}
				cacheWindow={(window, metricId) => this.cacheSqRawDataWindow(window, metricId)}
				containerId={containerId}
				window={true}
				onWidgetPeriodChanged={this.onWidgetPeriodChanged}
				viewerContext={this}
				unsubscribeWindow={unsubscribe}
				destroyWindow={(id) => this.hideSqRawDataWindow(id)}>
			</MetricTableDataWindow>, $('#metricpopup' + containerId).get(0));
		}
	},

	cacheSqRawDataWindow(window, metricId) {
		this.openedRawDataWindows[metricId] = window;
	},

	hideSqRawDataWindow(id) {
		let container = $('#metricpopup' + id);
		ReactDOM.unmountComponentAtNode(container.get(0));
		container.remove();
		let index = this.openedRawDataWindowIds.indexOf(id);
		if (index > -1) {
			this.openedRawDataWindowIds.splice(index, 1);
			delete this.openedRawDataWindows[id];
		}
	},

	/**
	 * Handler function for the click event on SQ preview icon
	 */
	onSqView: function (e) {
		//KendoWindow Model View
		e.stopPropagation();
		var target = $(e.currentTarget), parent = target.closest('li.cw_item');

		var windowTitle, serviceQualifierId = parent.attr('id').replace('smp_sq_el_', '');
		if ($('.' + serviceQualifierId).length) {
			$('.' + serviceQualifierId).data('kendoWindow').close();
			return;
		}
		var qualifierType = parent.attr('data-type');
		if (qualifierType === 'datacollector.health.HealthIndexConfiguration' || qualifierType === 'datacollector.health.GroupHealthIndexConfiguration') {
			var defaultIgnoreMissingData = true;
		}

		windowTitle = parent.find('.cw_qualifier_nr').text() + parent.find('.smp_sq_name').text();
		this.openWindow({
			event: e,
			windowTitle: windowTitle,
			serviceQualifierId: serviceQualifierId,
			widgetType: 'metricsWidget',
			figureId: this.figureId,
			highlightObj: {
				node: $('.cw_highlight_node').attr('id'),
				element: target.closest('li').attr('id')
			},
			qualifierType: qualifierType,
			qualifierName: parent.find('.smp_sq_name').text(),
			defaultIgnoreMissingData: defaultIgnoreMissingData
		});
	},

	/**
	 * Handler for clicking on revert icon
	 */
	onRevertLayout: function () {
		this.onClearContext();
	},
	/**
	 * Gets qualifier configuration
	 * @param {Object} qualifier
	 * @param {String} widgetID
	 */
	getQualifierConfiguration: async function (qualifier, widgetID, window) {

		var cell = this.graph.getSelectionCell();
		var node = cell.customData;

		const baseUrl = Settings.serverPath + 'accounts/' + this.account.id + '/services/' + this.id + '/';
		const url = baseUrl + 'elements/' + node.id + '/qualifiers/' + qualifier.id + '/?update=false';

		const info = await loadQualifierDetails(url);

		let windowTitle = lang.QUALIFIER + ' - ' + window.options.title;
		window.setOptions({
			title: windowTitle
		});
		let wrapper = window.wrapper;
		wrapper.find('.k-window-titlebar').append('<span class="qualifier_description_tooltip glyphicons pointer question-sign" title="' + info.staticProperties.configurationDescription + '"></span>');
		//trick to get the title text width
		wrapper.find('.k-window-titlebar').append('<span class="fake-title-container">' + windowTitle + '</span>');
		let fakeTitleContainer = wrapper.find('.fake-title-container');
		let textWidth = fakeTitleContainer.width();
		fakeTitleContainer.remove();
		if (textWidth > window.options.width - 100) {
			textWidth = window.options.width - 100;
		}
		wrapper.find('.qualifier_description_tooltip').css('left', textWidth + 10);
		wrapper.find('.k-window-title').css('width', textWidth);

		return ReactDOM.render(<SqInfoForm accountId={this.account.id} {...info} />, $('#' + widgetID).get(0));
	},
	/**
	 * Event handler for the click event on a ServiceHistory
	 * @param {Object} e The click event
	 */
	onServiceHistoryClick: function (e) {
		e.stopPropagation();

		var elementId = $('.cw_smp_se_name').attr('data-id');
		var windowTitle = $('.cw_smp_se_name').text();
		this.openWindow({
			event: e,
			serviceQualifierId: elementId,
			widgetType: 'serviceHistory',
			disableToggleAction: true,
			windowTitle: windowTitle + ' - ' + lang.viewer.SERVICE_HISTORY
		});
	},
	/**
	 * Event handler for the click event on  service element rulset icon
	 * @param {Object} e The click event
	 */
	onServiceRulesetClick: function (e) {
		e.stopPropagation();

		var elementId = $('.cw_smp_se_name').attr('data-id');
		var windowTitle = $('.cw_smp_se_name').text();
		this.openWindow({
			event: e,
			serviceQualifierId: elementId,
			widgetType: 'ruleset',
			windowTitle: windowTitle + ' - ' + lang.designer.RULESET,
			figureId: this.figureId,
			disableToggleAction: true
		});
	},

	onServiceElementChartClick: function (e) {
		e.stopPropagation();

		var elementId = $('.cw_smp_se_name').attr('data-id');
		var windowTitle = $('.cw_smp_se_name').text();
		this.openWindow({
			event: e,
			serviceQualifierId: elementId,
			widgetType: 'elementChart',
			windowTitle: 'Metric - ' + windowTitle,
			figureId: this.figureId
		});
	},
	/**
	 * Event handler for the click event on a Service Element
	 * @param {Object} e The click event
	 */
	onServiceElementClick: function (cell) {

		let elementRow = $('.cw_smp_se_item .cw_smp_se_container')

		$('.cw_smp_se_name', elementRow )
			.attr('data-id', cell.customData.id)
			.text(cell.customData.name);

		this.setElementStateInQualifiersList(cell.customData.state);

		$('#cw_service_ruleset_view').attr('title', kendo.template(lang.viewer.messages.RULSET_TITLE)({sqName: cell.customData.name}));
		$('#cw_service_history_view').attr('title', kendo.template(lang.viewer.messages.SQ_SERVICEHISTORY_TITLE)({sqName: cell.customData.name}));

		$('#cw_preview_widget').empty();
		var rulesetDescription = $('#cw_service_ruleset_description');
		if (cell.customData.rule && cell.customData.rule.type !== 'Default') {
			if (cell.customData.rule.type === 'Ruleset') {
				rulesetDescription.removeClass('hide').text(lang.designer.STATE_EXPRESSION_RULESET);
			} else {
				rulesetDescription.removeClass('hide').text(cell.customData.rule.type + ' ' + lang.designer.RULESET.toLowerCase());
			}
		} else {
			rulesetDescription.addClass('hide');
		}

		this.updateSqList(cell);
	},

	setElementStateInQualifiersList( state ){
		let elementRow = $('.cw_smp_se_item .cw_smp_se_container')

		$('.cw_status_widget_color', elementRow )
			.removeClass('cw_color1 cw_color2 cw_color3 cw_color4 cw_color5 cw_color6')
			.addClass('cw_color' + Utils.getStateIndex(state));
	},
	/**
	 * Handler function for the click event on Save Service Note button
	 * @param {Object} e The click event
	 */
	onCancelServiceNote: function (e) {
		var win = $('#' + this.serviceNoteWindowId).data("kendoWindow");
		if (win) {
			win.close();
			win.destroy();
		}
	},

	/*
	 * Handler function for highlighting node
	 * @param {String} node the id of the node to be highlighted
	 * */
	highlightNode: function (node) {
		$('#' + node).trigger('click');
		//this.highlightObj.node = null;
	},
	/*
	 * Handler function for highlighting SQ element
	 * */
	highlightSqElement: function () {
		this.firstLoad = false;

		if (this.highlightObj && this.highlightObj.element) {
			var highlightedObject = $('#' + this.highlightObj.element);
			this.highlightObj.element = null;
			//fix for when clicking parent element
			if (highlightedObject.hasClass('node')) {
				$('.cw_smp_se_name').trigger('click');
			} else {
				highlightedObject.trigger('click');
			}
			if (this.highlightObj.openWindow) {
				$('#' + this.highlightObj.element).find('.smp_sq_preview').trigger('click');
			}
		} else if (this.serviceQualifierId) {
			var configSq = $('#smp_sq_el_' + this.serviceQualifierId);
			if (configSq.length) {
				configSq.trigger('click');
			} else {
				$('.cw_smp_se_item').trigger('click');
			}
		} else if (this.highlightState) {
			var list = $('.smp_sq_list');
			var sqItemDown = list.find('span[data-state="' + this.highlightState + '"]:first').closest('li');
			if (sqItemDown.length) {
				sqItemDown.trigger('click');
			} else {
				$('.cw_smp_se_item').trigger('click');
			}
		} else {
			$('.cw_smp_se_item').trigger('click');
		}
	},
	/**
	 * Search and highlights the first found breached element
	 * @param {Object} serviceModel
	 */
	selectFirstBreachedNode: function () {
		if (this.serviceElementId && this.firstLoad) {
			this.designer.selectElement(this.serviceElementId);
		} else {

			let node = this.model.nodes.find( node =>{
				if( node.type == 'ROOT' && this.highlightRootNode)
					return true;

				return node.state === this.highlightState && !node.isAggregated;
			});

			let qualifier = null;
			if (node === undefined ) {
				node = this.model.nodes.find(node => {
					if( node.qualifiersDown ){
						qualifier = node.qualifiers.find( () => {
							return qualifier.state === 'INACTIVE';
						});

						return qualifier != null;
					}
				});
			}

			if (node === undefined) {
				node = this.model.nodes.find(node => node.type == 'ROOT');
			}

			this.designer.selectElement(node.id);
		}
	},
	/**
	 * Set Panelbar items height
	 */
	setPanelBarItemHeight: function () {
		var height = $('#painting_area').height() - 20;
		var panelBar = $('.cw_viewer_panelbar');
		var items = panelBar.find('.cw_panelbar_item');

		items.css('max-height', Math.floor((height - 270) / 2));
		items.find('.k-content').css('max-height', Math.floor((height - 270) / 2));
		panelBar.find('#cw_viewer_metric .k-content').height(240);
	},

	openWindow: function (args) {
		let widgetId = 'cw_view_widget' + args.widgetType + args.serviceQualifierId;
		let windowSubscriberId = Utils.guid();

		if (this.clonedWindows[widgetId]) {
			let clonedWindow = this.clonedWindows[widgetId];
			clonedWindow.restore();
			clonedWindow.toFront();
			return;
		}

		$('#widget_wrapper').append('<div data-type="'+ args.widgetType +'" id="' + widgetId + '"><span class="cw_period_toggle"></span><div class="cw_view_widget" class="left w100"></div></div>');
		var windowHandler = $('#' + widgetId);
		var windowOpened = true, highlightObj = {};
		var index;
		var qualifierTitle = args.windowTitle || $(args.event.currentTarget).closest('li.cw_item').find('.smp_sq_name').text();
		var windowActions = ['toggle', 'Minimize', 'Close'];

		if (args.disableToggleAction || args.widgetType === 'statusLog') {
			index = windowActions.indexOf('toggle');
			windowActions.splice(index, 1);
		}
		if (args.disableCloseAction) {
			index = windowActions.indexOf('Close');
			windowActions.splice(index, 1);
		}
		if (args.disableMinimizeAction) {
			index = windowActions.indexOf('Minimize');
			windowActions.splice(index, 1);
		}

		highlightObj = args.highlightObj || {node: null, element: null};

		//addDesignerWindowStateStorage(this.designer,'preview-viewer'

		var scope = this;

		var options = {
			visible: true,
			widget: null, //custom property
			highlightObj: {
				node: highlightObj.node,
				element: highlightObj.element
			},
			widgetType: args.widgetType,
			resizable: false,
			minHeight: 100,
			minWidth: 300,
			title: qualifierTitle,
			appendTo: '#widget_wrapper',
			actions: args.widgetType == 'statusLog' ? ['close'] : windowActions,
			close: $.proxy(function (e) {

				//ReactDOM.unmountComponentAtNode($('#' + widgetId).get(0));
				let settings = e.sender.wrapper.find('.cw_widget_settings');
				if( settings.length ){
					ReactDOM.unmountComponentAtNode(settings[0]);
				}
				if (args.widgetType === 'statusLog') {
					return;
				}
				this.resetPanelWindow(widgetId);

				if (this.windowWidget) {
					delete this.widgetsByWindowID[this.windowWidget.id];
				}
				windowOpened = false;

				if (args.widgetType !== 'qualifierDetails') {
					if (this.widgets.length && this.widgets[widgetId].destroy) {
						//console.log('deleting: ', widgetId);
						try {
							this.widgets[widgetId].destroy();
						} catch (e) {
							//here handle the error
						}
					}
					delete this.widgets[widgetId];
				}

				delete this.clonedWindows[widgetId];

				windowHandler.data("kendoWindow").destroy();
				windowHandler.detach();

				if (args.widgetType === 'statusLog') {
					this.userSettings.serviceLogPanelClosed = true;
				}
			}, this),
			activate: function (e) {
				e.sender.wrapper.find('.k-window-title').addClass('ellipsis');
				e.sender.wrapper.find('.k-window-titlebar').on('dblclick', function (e) {
					e.stopPropagation();
				});

				e.sender.setOptions({
					resizable: args.resizable !== undefined ? args.resizable : true
				});
				/*
				 * 19-10-2016
				 * Proper event is not handled by Kendo
				 * @todo check on later versions for a public Kendo method
				 * */
				//var self = this;
				e.sender.resizing._draggable.userEvents.bind("release", function (e) {
					var target = $(e.sender.currentTarget),
						window = target.closest('.k-window').find('.k-window-content.k-content').data('kendoWindow');
					if (window) {
						if (window.widget && window.widget.onResize) {
							window.widget.onResize();
						}
					}
				});
			},
			open: $.proxy(function (e) {
				$('#' + widgetId).closest('li.cw_item').attr('id', 'window' + widgetId);

				var definedWindowWidget = $.proxy(function (widgetID, serviceQualifierId, e) {
					//Do not try to render the metricsWidget, as window has been closed
					if (!windowOpened)
						return;

					kendo.ui.progress($('#' + widgetID), false);

					switch (args.widgetType) {
						case 'serviceHistory':
							this.renderServiceHistoryWindowContent(e, widgetID);
							this.widgets[widgetID] = this.windowWidget;
							windowHandler.data('kendoWindow').widget = this.windowWidget;
							break;
						case 'metricsWidget':
							this.renderMetricsWidgetWindowContent(e, widgetID, serviceQualifierId, args);
							this.widgets[widgetID] = this.windowWidget;
							this.widgetsByWindowID[this.windowWidget.id] = widgetID;
							windowHandler.data('kendoWindow').widget = this.windowWidget;
							break;
						case 'ruleset':
							this.renderRuleSetWindowContent(widgetID, args);
							break;
						case 'statusLog':
							this.userSettings.serviceLogPanelClosed = false;
							this.renderStatusLogsWindowContent(widgetID, windowHandler, serviceQualifierId);
							break;
						case 'testConfig':
							this.renderTestConfigWindowContent(widgetId, args)
							break;
						case 'elementChart':
							this.renderElementChartWindowContent(e, widgetID, serviceQualifierId, args);
							this.widgets[widgetID] = this.windowWidget;
							this.widgetsByWindowID[this.windowWidget.id] = widgetID;
							windowHandler.data('kendoWindow').widget = this.windowWidget;
							break;
						default: //qualifierDetails
							this.getQualifierConfiguration(args.qualifier, widgetID, windowHandler.data('kendoWindow'));
							break;
					}
				}, this);
				if (args.widgetType === 'statusLog' && this.userSettings.serviceLogPanelClosed) {
					this.renderLogsView();
				}
				kendo.ui.progress($('#' + widgetId), true);
				definedWindowWidget(widgetId, args.serviceQualifierId, e);
			}, this),
			resize: function(e) {
				if (args.widgetType == 'metricsWidget' || args.widgetType == 'elementChart') {
					e.sender.widget?.chart.setSize(e.width, e.height);
				}
				if (args.widgetType === 'qualifierDetails') {
					let windowWidth = e.width;
					let titleContainer = e.sender.wrapper.find('.k-window-title');
					let tooltip = e.sender.wrapper.find('.qualifier_description_tooltip');
					if (windowWidth < 350) {
						//trick to get the title text width
						let titleText = titleContainer.text();
						e.sender.wrapper.find('.k-window-titlebar').append('<span class="fake-title-container">' + titleText + '</span>');
						let fakeTitleContainer = e.sender.wrapper.find('.fake-title-container');
						fakeTitleContainer.remove();
						titleContainer.css('width', windowWidth - 100);
						tooltip.css('left', windowWidth - 90);
					} else {
						let titleText = titleContainer.text();
						e.sender.wrapper.find('.k-window-titlebar').append('<span class="fake-title-container">' + titleText + '</span>');
						let fakeTitleContainer = e.sender.wrapper.find('.fake-title-container');
						let textWidth = fakeTitleContainer.width();
						fakeTitleContainer.remove();
						titleContainer.css('width', textWidth);
						tooltip.css('left', textWidth + 10);
					}
					scope.sqDetailsWindowConfig = {width: windowWidth, height: e.height};
				}
			},
			dragend: $.proxy(function (e) {
				Utils.checkWindowPosition(e, this.viewerArea);
			}, this)
		};

		if(args.widgetType === 'statusLog'){
			options = addDesignerWindowStateStorage(this.designer,'status-log-viewer', options)
		} else if (args.widgetType === 'qualifierDetails') {
			options = addDesignerWindowStateStorage(this.designer,args.widgetType, options)
		} else {
			options = {
				...options,
				...this.calculatePositions(args.widgetType)
			};
		}

		this.clonedWindow = windowHandler.kendoWindow(options).data('kendoWindow');
		this.clonedWindow.wrapper.find('.k-i-toggle').parent().off('click').on('click',  $.proxy(scope.onWindowToggleClick, scope));
		if (args.widgetType === 'statusLog') {
			this.statusLogWindow = this.clonedWindow;
		}

		this.clonedWindows[widgetId] = this.clonedWindow;
	},
	/**
	 * Resets the window ID and keep window info for new windows
	 */
	resetPanelWindow: function (widgetID) {
		var windowsLength = this.windows.length;
		for (var i = 0; i < windowsLength; i++) {
			if (this.windows[i].id === widgetID) {
				this.windows[i].id = 0;
				break;
			}
		}
	},
	/*
	 * add new metric to grid
	 */
	addToWidgetGridDataSource: function (widget, metric) {
		var data = widget.dataSource.data(), length = data.length, dataSource;
		this.onEventWindowGridNoUpdate = true;
		data.push(metric);

		if (length > 9999) {
			data.splice(-1, 1);
		}

		dataSource = new kendo.ceeview.DataSource({
			data: data,
			sort: {
				field: 't',
				dir: 'desc'
			}
		});
		widget.setDataSource(dataSource);
	},

	dirtyCheck: function(){
		return {
			isDirty: false
		}
	},

	reloadStatesLogsDS: function (config) {
		this.reloadNotificationType = config.type;
		var url, startDate, endDate;

		if (this.userSettings.parentModuleSufix === 'ServiceDetails' || this.userSettings.parentModuleSufix === 'IncidentForm') {
			config.timeSelector = this.highlightWidgetSettings.period;
			startDate = new Date(this.userSettings.startDate);
			endDate = new Date(this.userSettings.endDate);
		} else {
			config.timeSelector = config.timeSelector || this.notificationPeriod || 'LASTDAY';

			if (config.timeSelector === 'CUSTOM') {
				startDate = config.period.startDate;
				endDate = config.period.endDate;
			}
		}

		config.period = Utils.getPeriodInterval({
			period: config.timeSelector,
			width: 300,
			startDate: startDate,
			endDate: endDate
		});

		if (this.highlightObj) {
			config.timeSelector = this.highlightObj.timeSelector || config.timeSelector;
			//config.period = config.period || {};
			startDate = this.highlightObj.period?.startDate || startDate;
			endDate = this.highlightObj.period?.endDate || endDate;
		}

		url = this.requestPath + 'services/' + this.id + '/notifications';
		if (config.type === 'element') {
			url += '/elements/' + config.element.customData.id;
		} else if (config.type === 'qualifier') {
			url += '/qualifiers/' + config.qualifierId;
		} else if (config.type === 'service') {
			url += '/search';
		}

		let unAcknowledgedOnly = false;
		let currentUrl = this.logsView?.dataSource.options.transport.read.url;
		if (currentUrl && typeof currentUrl !== 'function') {
			if (currentUrl.indexOf('unAcknowledgedOnly=true') !== -1) {
				unAcknowledgedOnly = true;
			}
		}

		if (config.timeSelector === 'CUSTOM') {
			var urlStartDate, urlEndDate;
			if (typeof(startDate) === 'object' && typeof(endDate) === 'object') {
				urlStartDate = startDate.getTime();
				urlEndDate = endDate.getTime();
			} else if(typeof(startDate) === 'string' && typeof(endDate) === 'string'
				|| typeof(startDate) === 'number' && typeof(endDate) === 'number') {
				urlStartDate = parseInt(startDate);
				urlEndDate = parseInt(endDate);
			}
			url += '?timeZone=' + (this.timezone || Cookies.CeesoftTimezone) + '&interval=' + config.period.interval + '&fromTime=' + urlStartDate + '&toTime=' + urlEndDate + '&unAcknowledgedOnly=' + unAcknowledgedOnly;
		} else {
			url += '?timeZone=' + (this.timezone || Cookies.CeesoftTimezone) + '&interval=' + config.period.interval + '&timeSelector=' + config.timeSelector + '&unAcknowledgedOnly=' + unAcknowledgedOnly;
		}

		if (this.logsView) {
			this.logsView.dataSourceUrl = url;
			this.logsView.dataSource.options.transport.read.url = url;
			this.logsView.dataSource.read();
		} else {
			//initially when we load viewer the logs view in this step doesn't exist
			this.logsViewDataSourceUrl = url;
		}
	},
	/*
	 * Window custom subscribe event
	 */
	windowSubscribe: function (subscriberId, qualifierId, callback) {
		var subscriptionObj = [{
			eventType: 'Metric',
			releaseEvents: true,
			timeZone: this.timezone || Cookies.CeesoftTimezone,
			qualifierId: qualifierId,
			showUnit: true
		}];
		RemoteEventsManager.subscribe(subscriberId, subscriptionObj);

		if (callback) {
			callback.call(this, subscriberId);
		}
	},
	/*
	 * Render content function for service history window
	 * @param e {Object} Event passed from window open event
	 * @param widgetID {String} The widget guid
	 * */
	renderServiceHistoryWindowContent: function (e, widgetID) {
		this.windowWidget = new ServiceHistoryWidget({
			id: Utils.guid(),
			type: 'history',
			title: '',
			noTitleNeeded: true,
			renderTo: widgetID,
			isViewer: true,
			onWidgetPeriodChanged: this.onWidgetPeriodChanged,
			viewerContext: this,
			customControls: {
				target: e.sender.wrapper,
				change: $.proxy(function (e) {
					//e.sender.wrapper.closest('.k-window').find('.k-i-toggle').trigger('click');
				}, this)
			},
			configuration: {
				serviceId: this.id,
				serviceElementId: this.figureId,
				period: this.defaultWindowsTimeSelector || this.userSettings.cachedCategory.period || this.notificationPeriod || 'LASTDAY',
				timezone: this.timezone || Cookies.CeesoftTimezone,
				hideFooter: true
			}
		});
	},

	onWidgetPeriodChanged(context, period, target) {
		let timeSelector = this.instanceConfiguration?.period || period;
		if (this.viewerDefaultWindow) {
			context.defaultWindowsTimeSelector = timeSelector;
		}
		let qualifierId = $(target).closest('.period_multi_toggle').data('qualifierid');
		let elementId = $(target).closest('.period_multi_toggle').data('elementid');
		let timeToggleId, timeToggleType;
		if (qualifierId) {
			timeToggleId = qualifierId;
			timeToggleType = 'qualifier';
		} else if (elementId) {
			timeToggleId = elementId;
			timeToggleType = 'element';
		}
		let isFromWidget = true;
		context.changeLogsTime(timeSelector, isFromWidget, timeToggleId, timeToggleType);
	},

	changeMetricWindowsTime(timeSelector) {
		if (this.widgets) {
			for (let widgetID in this.widgets) {
				this.widgets[widgetID].instanceConfiguration.period = timeSelector;
				if (this.widgets[widgetID].periodMultiToggle) {
					this.widgets[widgetID].periodMultiToggle.setSelectedItem(timeSelector);
				}
				if (this.widgets[widgetID].timeMultiToggle) {
					this.widgets[widgetID].timeMultiToggle.value(timeSelector);
				}
				if (this.widgets[widgetID].getData) {
					this.widgets[widgetID].getData();
				}
			}
		}
	},

	changeLogsTime(timeSelector, isFromWidget, timeToggleId, timeToggleType) {
		this.logsToolbar.onTimePeriodChanged(timeSelector, isFromWidget, timeToggleId, timeToggleType);
	},

	renderMetricsWidgetWindowContent: function (e, widgetID, serviceQualifierId, args) {
		let cell = this.graph.getSelectionCell();
		this.windowWidget = new MetricsWidget({
			id: Utils.guid(),
			type: 'metrics',
			title: '',
			renderTo: widgetID,
			onWidgetPeriodChanged: this.onWidgetPeriodChanged,
			viewerContext: this,
			customControls: {
				target: e.sender.wrapper,
				change: $.proxy(function (e) {
					//e.sender.wrapper.closest('.k-window').find('.k-i-toggle').trigger('click');
					this.userSettings.period = typeof(e.sender.value) === 'function' ? e.sender.value() : e.sender.value;
					this.reloadStatesLogsDS({
						type: this.reloadNotificationType,
						element: cell,
						elementId: args.figureId,
						qualifierId: serviceQualifierId,
						timeSelector: typeof(e.sender.value) === 'function' ? e.sender.value() : e.sender.value,
						period: {
							startDate: new Date(e.startDate),
							endDate: new Date(e.endDate)
						}
					});
				}, this),
				toggleClick: $.proxy(function (value) {
					e.sender.wrapper.closest('.k-window').find('.k-i-toggle').trigger('click');
				}, this),
				zoom: $.proxy(function (args) {
					this.reloadStatesLogsDS({
						type: this.reloadNotificationType,
						element: cell,
						elementId: args.figureId,
						qualifierId: serviceQualifierId,
						timeSelector: args.period,
						period: {
							startDate: new Date(args.startDate),
							endDate: new Date(args.endDate)
						}
					});
				}, this)
			},
			isViewer: true,
			defaultIgnoreMissingData: args.defaultIgnoreMissingData,
			configuration: {
				serviceId: this.id,
				serviceElementId: args.figureId,
				serviceQualifierId: serviceQualifierId,
				timezone: this.timezone || Cookies.CeesoftTimezone,
				period: this.defaultWindowsTimeSelector || this.userSettings.cachedCategory.period || this.notificationPeriod || 'LASTDAY',
				startDate: this.userSettings.cachedCategory.startDate,
				endDate: this.userSettings.cachedCategory.endDate,
				chartType: this.userSettings.cachedCategory.chartType || 'line',
				showThreshold: this.userSettings.cachedCategory.showThreshold,
				showRegression: this.userSettings.cachedCategory.showRegression,
				ignoreMissingData: this.userSettings.cachedCategory.ignoreMissingData,
				aggregationType: this.userSettings.cachedCategory.aggregationType,
				hideErrors: this.userSettings.cachedCategory.hideErrors,
				qualifierType: args.qualifierType,
				qualifierName: args.qualifierName
			},
			onConfigurationChanged: config => this.storeMetricsWidgetConfig(config)
		});
	},

	renderElementChartWindowContent: function (e, widgetID, serviceQualifierId, args) {
		this.multigraphWindowId = Utils.guid();
		$('#' + widgetID).closest('.k-window').attr('id', this.multigraphWindowId);

		this.windowWidget = new MultigraphWidget ({
			id: this.multigraphWindowId,
			type: 'multigraph',
			renderTo: widgetID,
			title: lang.MULTIGRAPH,
			onWidgetPeriodChanged: this.onWidgetPeriodChanged,
			viewerContext: this,
			configuration: {
				qualifiersList: this.currentElementQualifiers,
				accountId: this.account.id,
				timezone: this.timezone || Cookies.CeesoftTimezone,
				period: this.notificationPeriod || 'LASTDAY',
				startDate: this.userSettings.cachedCategory.startDate,
				endDate: this.userSettings.cachedCategory.endDate,
				chartType: 'line',
				showRegression: this.userSettings.cachedCategory.showRegression,
				ignoreMissingData: this.userSettings.cachedCategory.ignoreMissingData,
				aggregationType: this.userSettings.cachedCategory.aggregationType,
				hideErrors: this.userSettings.cachedCategory.hideErrors,
				labelTemplate: ['<Asset>', '<Servicequalifier>'],
				serviceId: this.id,
				serviceElementId: this.figureId
			},
			customControls: {
				target: e.sender.wrapper,
				toggleClick: $.proxy(function (value) {
					e.sender.wrapper.closest('.k-window').find('.k-i-toggle').trigger('click');
				}, this),
			},
			isViewer: true,
			onConfigurationChanged: config => this.storeMultiGraphWidgetConfig(config)
		})
	},

	storeMultiGraphWidgetConfig(config){
		this.userSettings.startDate = config.startDate;
		this.userSettings.endDate = config.endDate;
		this.userSettings.period = config.period;
		this.userSettings.showThreshold = config.showThreshold;
		this.userSettings.chartType = config.chartType;
		this.userSettings.showRegression = config.showRegression;
		this.userSettings.ignoreMissingData = config.ignoreMissingData;
		this.userSettings.aggregationType = config.aggregationType;
		this.userSettings.hideErrors = config.hideErrors;
	},

	storeMetricsWidgetConfig(config){
		this.userSettings.startDate = config.startDate;
		this.userSettings.endDate = config.endDate;
		this.userSettings.period = config.period;
		this.userSettings.showThreshold = config.showThreshold;
		this.userSettings.showRegression = config.showRegression;
		this.userSettings.ignoreMissingData = config.ignoreMissingData;
		this.userSettings.chartType = config.chartType;
		this.userSettings.aggregationType = config.aggregationType;
		this.userSettings.hideErrors = config.hideErrors;
	},

	/*
	 * Render content function for rule set window
	 * @param widgetID {String} The widget guid
	 * @param args {Object} Configuration object
	 * */
	renderRuleSetWindowContent: function (widgetID, args) {
		var ruleType;

		var cell = this.graph.getSelectionCell();
		var node = cell.customData;

		if (!node.rule) {
			ruleType = 'Default';
		} else {
			ruleType = node.rule.type;
		}

		var readableRuleset = '';
		readableRuleset += '<div class="cw_sq_preview_content full">';
		readableRuleset += '<div class="cw_field"><label class="left">' + lang.NAME + '</label><span class="cw_sq_component">' + node.name + '</span></div>';
		if (node.description) {
			readableRuleset += '<div class="cw_field"><label class="left">' + lang.DESCRIPTION + '</label><span class="cw_sq_component">' + node.description + '</span></div>';
		}
		readableRuleset += '<div class="cw_field"><label class="left">' + lang.AGGREGATED + '</label><span class="cw_sq_component">' + (node.type === 'LINK' ? lang.service.LINKED_SERVICE_NA : (node.isAggregated && node.type !== 'LINKED') ? lang.TRUE : lang.FALSE) + '</span></div>';
		readableRuleset += '<div class="cw_field"><label class="left">' + i('SE presentation')
			+ '</label><span class="cw_sq_component">' + getPresentationModeLabelByValue(cell.getAttribute('sd-visualisation')) + '</span></div>';
		readableRuleset += '<div class="cw_field"><label class="left">' + lang.viewer.STATE_EXPRESSION_RULE + '</label><span class="cw_sq_component">' + ruleType + '</span></div>';

		var rule;
		if (ruleType === 'Ruleset') {
			rule = Utils.getReadableRuleset(node);
		} else if (ruleType === 'Weighted') {
			var counterLabel, percentageLabel;
			if (node.isAggregated) {
				counterLabel = lang.designer.MINIMUM_AVAILABLE_SERVICE_ELEMENTS;
				percentageLabel = lang.designer.PERCENTAGE_AVAILABLE_SERVICE_ELEMENTS;
			} else {
				counterLabel = lang.designer.MINIMUM_AVAILABLE_SERVICE_QUALIFIERS;
				percentageLabel = lang.designer.PERCENTAGE_AVAILABLE_SERVICE_QUALIFIERS;
			}
			if (node.rule.weightType === 'Count') {
				rule = counterLabel + ': #' + node.rule.weight;
			} else {
				rule = percentageLabel + ': %' + node.rule.weight;
			}
		}

		if (rule) {
			readableRuleset += '<div class="cw_field"><label class="left">' + lang.viewer.RULE + '</label><span class="cw_sq_component">' + rule + '</span></div>';
		}
		var teamDiv = '', teamLabel;
		if (node.responsibleTeamIds.length) {
			for (let i = 0; length = node.responsibleTeamIds.length, i < length; i++) {
				var currentNodeIdName = node.responsibleTeamNames[node.responsibleTeamIds[i]];
				teamDiv += '<span class="cw_sq_component">' + currentNodeIdName + '</span>';
			}
			if (node.responsibleTeamIds.length > 1) {
				teamLabel = lang.TEAMS;
			} else {
				teamLabel = lang.TEAM;
			}
			readableRuleset += '<div class="cw_field"><label class="left">' + teamLabel + '</label>' + teamDiv + '</div>';
		} else {
			readableRuleset += '<div class="cw_field"><label class="left">' + lang.TEAM + '</label><span class="cw_sq_component">' + lang.RESPONSIBLE_TEAM + '</span></div>';
		}
		readableRuleset += '<div class="cw_field"><label class="left">CVID</label><span class="cw_sq_component">' + node.id + '</span></div>';
		readableRuleset += '</div>';

		$('#' + widgetID).append('<div class="cw_ruleset_text">' + readableRuleset + '</div>');
		this.widgets[widgetID] = '';
	},
	/*
	 * Render content function for status log window
	 * @param {String} widgetID The widget guid
	 * @param {Object} windowHandler The window jQuery element
	 * */
	renderStatusLogsWindowContent: function (widgetID, windowHandler, serviceQualifierId) {
		let widgetHandler = $('#' + widgetID);
		if ($('#cw_service_logs', widgetHandler).length) {
			return;
		}
		let template = '<div id="cw_service_logs"></div> <div id="cw_service_qualifiers_states" class="hide"></div> <div id="cw_service_logs_placeholder"></div>';
		widgetHandler.append(template);

		widgetHandler.before('<div id="cw_servicelog_filters" class="cw_filter_wrapper right pointer" style="position: relative; z-index: 100000; margin-top: -22px; margin-left: auto; margin-right: 200px;"></div>');
		widgetHandler.before('<div id="cw_servicelog_time_selector" class="right"></div>');
		widgetHandler.before('<div class="cw_global_actions cw_viewer_logs_grid_menu" id="cw_logs_grid_menu"></div>');
		widgetHandler.before('<div id="cw_show_acknowledged" class="cw_show_acknowledged_viewer right"></div>');
		widgetHandler.closest('.k-window').find('.k-window-title').addClass('cw_viewer_logs_grid_title')
		let settingsPanelDom = widgetHandler.parent().find('#cw_servicelog_time_selector')[0];

		let onTimePeriodChanged = $.proxy(function (period, isFromWidget, timeToggleId, timeToggleType) {
			this.reloadObject.timeSelector = period;
			if (timeToggleType) {
				this.reloadObject.type = timeToggleType;
			}
			if (timeToggleType === 'qualifier') {
				this.reloadObject.qualifierId = timeToggleId;
			} else if (timeToggleType === 'element') {
				if (this.reloadObject.element) {
					this.reloadObject.element.customData.id = timeToggleId;
				} else {
					this.reloadObject.element = {
						customData: {
							id: timeToggleId
						}
					}
				}

			}
			this.reloadStatesLogsDS(this.reloadObject);
			widgetHandler.closest('.k-window').find('.k-i-toggle').remove();

			if (!isFromWidget) {
				this.changeMetricWindowsTime(period);
			}
		}, this);

		ReactDOM.render(<ChartToolbar onTimePeriodChanged={onTimePeriodChanged}
									  timePeriodEnabled
									  timePeriod={this.defaultWindowsTimeSelector || this.notificationPeriod}
									  ref={logsToolbar => this.logsToolbar = logsToolbar}
									  appearance={"transparent"}/>, settingsPanelDom);
	},
	/*
	 * Render content function for status log window
	 * @param {String} widgetID The widget guid
	 * @param {Object} args Data to be shown
	 * */
	renderTestConfigWindowContent: function (widgetId, args) {
		var result = args.resultData;
		var ul = '<ul id="cw_test_config_results"></ul>';
		var widgetDiv = $('#cw_view_widget' + widgetId);
		var resultDiv = '<div class="cw_configuration_error_content"></div>'
		widgetDiv.append(resultDiv);
		widgetDiv.find('.cw_configuration_error_content').append(ul);
		var configurationContent = widgetDiv.find('#cw_test_config_results');
		configurationContent.empty();
		var html = '', cssClass = '';
		if (!result.success) {
			cssClass = 'remove-circle';
			html += '<li>' + result.message + ' <span class="glyphicons ' + cssClass + ' cw_error_status"></span></li>';
			configurationContent.append(html);
		} else {
			var data = result.data.details;
			if (data.length) {
				for (let i = 0; i < data.length; i++) {
					if (data[i].success) {
						cssClass = 'ok';
					} else {
						cssClass = 'remove-circle';
					}
					html += '<li>' + data[i].message + ' <span class="glyphicons ' + cssClass + ' cw_error_status"></span></li>';
				}
				configurationContent.append(html);
			}
		}
	},
	/*
	 * Handler function for rendering the logs view component
	 * */
	renderLogsView: function (e) {
		var config = {
			serviceId: this.id,
			autoBind: true,
			serviceName: this.serviceName,
			moduleSufix: 'Viewer',
			highlightNotificationId: this.highlightNotificationId,
			timezone: this.timezone,
			startDate: this.configuration?.startDate || null,
			endDate: this.configuration?.endDate || null,
		};

		if (this.highlightObj) {
			config.gridFilter = this.highlightObj.gridFilter;
		}

		if (this.logsViewDataSourceUrl) {
			config.dataSourceUrl = this.logsViewDataSourceUrl;
		}
		this.logsView = new LogsView(config);
		this.statusLogWindow.widget = this.logsView;

		if (e) {
			this.designer.editorUi.addWindowButtonToToolbar(
				"sv-service-logs-widget-toggle",
				i('Show/hide service log window'),
				this.statusLogWindow,
				() =>  $('<a href="javascript:void(0)" class="geButton geToolbar__logs"><i class="glyphicons list"></i></a>')[0]
			);

			this.designer.store.am.addToggleWindowAction({
				id: Actions.ServiceDesignerLogWindowToggle,
				title: i('Show/hide service log window'),
				icon: UnorderedListOutlined
			}, this.statusLogWindow);
		}

		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
	},

	onClearContext: function (e) {
		var serviceId = this.id;
		this.clearUserPreferences = true;
		NavigationStore.pop();
	},

	adjustGridHeight: function (selector) {
		var grid = selector.find('.cw_raw_data_grid');
		var gridHeight = grid.closest('.k-window').height();
		grid.find('.k-grid').css('height', gridHeight);
		grid.find('.k-grid-content').css('height', gridHeight - 29);
	},

	saveUserPreferences: async function () {
		this.serviceSettings.set('zoom', this.graph.view.scale);
		await this.serviceSettings.save();
		this.userSettings.set('sqDetailsWindowConfig', this.sqDetailsWindowConfig);
		await this.userSettings.save();
	},

	/**
	 * Subscribes to service elements state events
	 */
	subscribe: function () {
		let subscriptions = getAdminEvents(this.id)
		RemoteEventsManager.subscribe(this.subscriberId, subscriptions);

		LocalEventsManager.bind("state-changed", (e) => {

			if( e.type == 'service-element' && e.serviceElementId == $('.cw_smp_se_name').attr('data-id') ){
				this.setElementStateInQualifiersList(e.state);
			}
		})
	},
	/**
	 * Destroy method
	 */
	destroy: async function () {
		ReactDOM.unmountComponentAtNode($('.cw_test_window').get(0));

		for (let windowId of [...this.openedRawDataWindowIds]) {
			this.hideSqRawDataWindow(windowId);
		}

		LocalEventsManager.unbind('state-changed');

		this.removeListeners();

		this.designer?.destroy();

		if (this.clearUserPreferences) {
			this.userSettings.clear();
			await this.userSettings.save();
		} else {
			await this.saveUserPreferences();
		}

		if (this.widget) {
			this.widget.destroy();
		}
		if (this.kendoWindowModalPreview) {
			this.kendoWindowModalPreview.destroy();
		}
		if (this.kendoWindowQualifiers) {
			this.kendoWindowQualifiers.destroy();
		}
		if (this.statusLogWindow) {
			this.statusLogWindow.destroy();
		}
		if (this.logsView) {
			this.logsView.destroy();
		}
		if (this.widgets) {
			for (var widgetID in this.widgets) {
				if (this.widgets[widgetID].destroy) {
					this.widgets[widgetID].destroy();
				}
			}
		}
		if (this.historyWidget) {
			this.historyWidget.destroy();
		}
		if ($('#cw_reasons_window').data('kendoWindow')) {
			$('#cw_reasons_window').data('kendoWindow').destroy();
		}
		if (this.clonedWindows) {
			for (var widgetID in this.clonedWindows) {
				if (this.clonedWindows[widgetID].destroy) {
					this.clonedWindows[widgetID].destroy();
				}
			}
		}
		if (this.widgetWrapper) {
			this.widgetWrapper.remove();
		}

		this.unsubscribe();
	}
});
