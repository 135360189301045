/*
 * Language file for the Norwegian translations
 */
let lang = {
	// Words
	ELEMENT: 'Element',
	ACTION: 'Handling',
	ACTION_COUNT: 'Handlingsantall',
	ACTIONS: 'Handlinger',
	ACTION_TRIGGER: 'Handlingsutl\u00F8ser',
	ACTIVE: 'Aktiv',
	ACTIVATE: 'Aktivere',
	AUTOMATION: 'Automatisering',
	INTEGRATION: 'Integrasjon',
	ONLY_IN_PERIOD: 'Bare i periode',
	DEACTIVATE: 'Inaktivere',
	ADMINISTRATION: 'Administrasjon',
	ALL: 'Alle',
	ASSIGN: 'Tildele',
	UNASSIGN: 'Fjern tildeling',
	UNACKNOWLEDGE: 'Ubekreft',
	AUDIT: 'Revisjon',
	INACTIVE: 'Inaktiv',
	INSTALLED: 'Installert',
	NOT_INSTALLED: 'Ikke installert',
	SELECT_ALL: 'Velg alle',
	DESELECT_ALL: '',
	SELECTED: 'Valgt',
	SELECTION: 'Seleksjon',
	SESSION_ID: 'Sesjonsid',
	NO_FILTER: 'Ingen visning',
	CAPTION: 'Caption',
	BEZIER: 'Bezier',
	CHANNELS: 'Kanal',
	MANHATTAN: 'Manhattan',
	MANUAL: 'Manuell',
	CLASSNAME: 'Klassenavn',
	DESCRIPTION: 'Beskrivelse',
	CONFIGURATION_NAME: 'Konfigurasjonsnavn',
	NOTIFICATION: 'Varsling',
	NOTIFICATION_TITLE: 'Varsel',
	DOWNTIME: 'Nedetid',
	DIRECT: 'Direkte',
	DISK: 'Disk',
	DISKS: 'Disker',
	DRIVE: 'Disk',
	EVENT: 'Hendelse',
	FIELD: 'Felt',
	INFO: 'Info',
	ICON: 'Ikon',
	ICONS: 'ikoner',
	INFORMATION: 'Informasjon',
	IDENTIFIER: 'Identifikator',
	KEY: 'N\u00F8kkel',
	NAME: 'Navn',
	UNIT: 'Enhet',
	ACCOUNT_SELECTION: 'Kontovalg',
	ACCOUNT_SUBACCOUNT: 'Konto og underkonto',
	SUBACCOUNT: 'Underkonto',
	PARENT_AGENT: 'Eies av agent',
	FULLNAME: 'Fullt navn',
	TITLE: 'Tittel',
	TEST: 'Test',
	ID: 'Id',
	CVID: 'CVID',
	TYPE: 'Type',
	AGGREGATION_TYPE: 'Aggregeringstype',
	CUSTOM_UNIT: 'Tilpasset enhet',
	COLOR: 'Farge',
	CONVERSION: 'Konvertering',
	ASSETS: 'Ressurser',
	TEST_RULESET: 'Test regelsett',
	ASSET: 'Ressurs',
	SLA: 'SLA',
	ASSET_TARGET: 'Ressursm\u00e5l',
	TAG: 'Etikett',
	TAGS: 'Etiketter',
	SET_TAGS: 'Sett etikett',
	MERGE_ASSETS: 'Sammensl\u00E5 ressurser',
	MERGE_ASSETS_CONFIRMATION: 'Dette vil slå sammen valgte ressursene til ressurs: ASSETNAME. Klikk oppdater for \u00E5 fortsette eller avbryt for å returnere til meny.',
	TARGET: 'M\u00e5l',
	EXTENDED_TYPE: 'Utvidet type',
	TARGET_TYPE: 'M\u00e5ltype',
	TEST_RESULTS: 'Testresultat for ${assetName}',
	RESPONSIBLE: 'Ansvarlig',
	RESPONSIBLE_TEAM: 'Team',
	SERVICE: 'Tjeneste',
	SERVICE_MODEL: 'Tjenestemodell',
	SERVICE_NAME: 'Tjenestenavn',
	SERVICE_ELEMENT: 'Tjenesteelement',
	SERVICE_LINK: 'Tjenestelink',
	SERVICE_LINK_MESSAGE: 'Klikk for \u00E5 g\u00E5 til tjeneste ${serviceName}',
	QUALIFIER: 'Tjenestepunkt',
	SERVICES: 'Tjenester',
	SERVICE_UP: 'Tjenesten er OPPE',
	SERVICE_DOWN: 'Tjenesten er NEDE',
	SERVICE_ERROR: 'Tjenesten feil',
	SERVICE_INACTIVE: 'Tjenesten er inaktiv',
	SERVICE_MAINTENANCE: 'Tjenesten er i vedlikehold',
	SERVICE_OUTOFOPERATION: 'Utenfor driftstid',
	SERVICE_QUALIFIER: 'Tjenestepunkt',
	SERVICE_QUALIFIERS: 'Tjenestepunkt',
	SERVICE_QUALIFIERS_SETTINGS: 'Tjenestepunkt detaljer',
	OPERATING_PERIOD: 'Driftstid',
	INACTIVE_SERVICE: 'Inaktiv tjeneste',
	INACTIVE_SERVICES: 'Inaktive tjenester',
	DYNAMIC_SERVICES: 'Dynamisk tjeneste',
	STATE: 'Tilstand',
	STATE_SUMMARY: 'Tilstand oversikt',
	SHARED: 'Delt',
	ACCOUNT: 'Konto',
	ACCOUNTS: 'Kontoer',
	SUBACCOUNTS: 'Underkontoer',
	ASSET_HEALTH: 'Helse',
	KPI: 'Kpi',
	PERCENTILE: 'Persentil',
	KEYWORD: 'M\u00f8nster',
	AGENTS: 'Agenter',
	AGENT: 'Agent',
	ASSET_ACCOUNT: 'Ressurskonto',
	METRICS: 'Metrikker',
	SQ_METRICS: 'Tjenestepunkt metrikker',
	MONITOR_METRICS: 'Monitor metrikker',
	EXTERNAL_METRICS: 'Eksterne metrikker ',
	AGENT_ACCOUNT: 'Agent konto',
	SERVER: 'Server',
	SERVER_AGENT: 'Server agent',
	SERVER_ASSET: 'Server ressurs',
	SERVER_SERVICES: 'Server tjenester',
	USERS: 'Brukere',
	USER_TAGS: 'Etiketter',
	USER_TAGS_ACTIVE: 'Bruker tagger er aktiv for din bruker',
	USER_TAGS_INACTIVE: 'Bruker tagger er inaktive fordi "team tagger" er satt for din bruker',
	TEAM_TAGS_ACTIVE: 'Team tagger er aktivert for din bruker',
	TEAM_TAGS_INACTIVE: 'Team tagger er ikke aktivert for din bruker',
	ACTIVE_USERS: 'Aktive brukere',
	AGGREGATED: 'Aggregert',
	GROOVY_SCRIPT_EDITOR: 'Groovy skriptredigering',
	ADVANCED: 'Avansert',
	USER_CATEGORY: 'Rolle',
	USER_CATEGORIES: 'Roller',
	VALID_EMAIL: 'Skriv en gyldig epost addresse',
	FILE: 'Fil',
	DOCUMENT: 'Dokument',
	TEXT: 'Tekst',
	PASTE: 'Lim inn',
	FONT_SIZE: 'Skriftstørrelse',
	MAX: 'Maks',
	MIN: 'Min',
	TRUE: 'Sann',
	FALSE: 'Usann',
	AVERAGE: 'Gjennomsnitt',
	PERCENTAGE: 'Prosentandel',
	ACHIEVED_PERCENTAGE: 'Oppn\u00E5dd prosent',
	BREACH_PERCENTAGE: 'Brutt prosent',
	RANGE: 'Omr\u00e5de',
	UNAVAILABLE: 'Ikke tilgjengelig',
	MEDIA_LIBRARY: 'Media bibliotek',
	AUTHENTICATIONS: 'Autentisering',
	ALERT: 'Varsel',
	ERROR: 'Feil',
	RETRY: 'Pr\u00F8v igjen',
	ERROR_CODE: 'Feilkode',
	FAILED: 'Feilet',
	UNKNOWN: 'Ukjent',
	USERNAME: 'Brukernavn',
	PASSWORD: 'Passord',
	PROFILE: 'Profil',
	OLD_PASSWORD: 'Gammelt passord',
	CONFIRM_PASSWORD: 'Bekreft passord',
	FIRST_NAME: 'Fornavn',
	LAST_NAME: 'Etternavn',
	LANGUAGE: 'Spr\u00E5k',
	INFORMATIONAL: 'Informasjon',
	LEGEND: 'Forklaring',
	LOGOUT: 'Logg ut',
	EMAIL: 'Epost',
	AND: 'Og',
	OR: 'Eller',
	NOT: 'Ikke',
	EQ: 'Lik',
	GE: 'St\u00F8rre eller lik',
	GT: 'St\u00F8rre enn',
	LE: 'Mindre eller lik',
	LT: 'Mindre enn',
	NE: 'Ikke lik',
	NEQ: 'Er ikke lik',
	GTE: 'St\u00F8rre enn eller lik',
	LTE: 'Mindre enn eller lik',
	IAE: 'Etter eller lik som',
	IA: 'Etter',
	IBE: 'Er f\u00F8r eller lik som',
	IB: 'Er f\u00F8r',
	ISIN: 'Er med i',
	ISNOTIN: 'Er ikke med i',
	STARTS_WITH: 'Starter med',
	CONTAINS: 'Inneholder',
	TEAM: 'Team',
	TEAMS: 'Team',
	SERVICE_TAGS: 'Tjeneste etiketter',
	GAUGE: 'M\u00E5ler',
	SOLID_GAUGE: 'Solid m\u00E5ler',
	SOURCE: 'Kilde',
	GROUP: 'Gruppe',
	SUBGROUP: 'Subgruppe',
	SELECT_USERS: 'Velg brukere',
	DAY_SELECTION: 'Dag valg',
	NOTIFICATIONS: 'Meldinger',
	TOTAL: 'Total',
	PREVIEW: 'Forh\u00E5ndsvisning',
	PROPERTIES: 'Egenskaper',
	CATEGORY: 'Kategori',
	NEW_CATEGORY: 'Ny kategori',
	ROLE: 'Rolle',
	NEW_ROLE: 'Ny rolle',
	PUBLIC: 'Åpen',
	PRIVATE: 'Privat',
	YES: 'Ja',
	NO: 'Nei',
	NA: 'Ikke tilgjengelig',
	REASON: 'Helse\u{E5}rsak',
	REASONS: 'Helse\u{E5}rsaker',
	REASON_SUMMARY: 'Ressursoversikt',
	REPORT: 'Rapport',
	REPORTS: 'Rapporter',
	RESET_HEALTH_INDEX_PLACEHOLDER: 'Skriv inn helse\u{E5}rsak for tilbakestilling av helseindeks',
	MANAGEMENT: 'System',
	SYSTEM_ERRORS: 'Systemmeldinger',
	INCLUDE: 'Inkluder',
	VALUE: 'Verdi',
	DEFAULT_VALUE: 'Standard verdi',
	DEFAULT: 'Standard',
	NO_VALUE: 'Ingen verdi',
	UP: 'Oppe',
	DOWN: 'Nede',
	IN: 'Inn',
	OUT: 'Ut',
	BREACHED: 'Brudd',
	WARNING: 'Advarsel',
	COMPLIANT: 'Samsvar',
	COMPLIANCE: 'Samsvar',
	INCIDENTS: 'Saker',
	INCIDENTS_NUMBER: 'saksnummer',
	MINOR: 'Advarsel',
	MAJOR: 'Alvorlig',
	CRITICAL: 'Kritisk',
	PROJECTED: 'Prosjektert',
	NONE: 'Ingen',
	USER: 'Bruker',
	SEARCH: 'S\u00F8k',
	SINCE: 'siden',
	STATUS: 'Status',
	TOPIC: 'Emne',
	COMMENT: 'Kommentar',
	COMMNETS: 'Kommentarer',
	READ: 'Les',
	WRITE: 'Skriv',
	MENU: 'Menyfelt',
	CUSTOMIZE: 'Tilpass',
	OPERATION: 'Handling',
	HOME: 'Hjem',
	HOME_PAGE: 'Hjemmeside',
	URL: 'URL',
	DBWATCH: 'DbWatch',
	LAUNCH: 'Kj\u00F8r',
	LAYOUT: 'Oppsett',
	ENTRIES: 'Oppf\u00f8ringer',
	SERVER_VERSION: 'Server versjon',
	PROCESS: 'Prosess',
	SELECTOR: 'Selektor',
	SEPARATOR: 'Skilletegn',
	VERSION: 'Versjon',
	ALLOCATED: 'Allokert',
	MESSAGE: 'Melding',
	USED: 'Brukt',
	UPTIME: 'Oppetid',
	CURRENT: 'N\u00e5v\u00e6rende',
	MULTIGRAPH: 'Multigraf',
	SINGLEGRAPH: 'Graf',
	MULTIPLE_SERVICES: 'Flere tjenester',
	MULTIPLE_ELEMENTS: 'Flere elementer',
	MEMORY: 'Minne',
	MISSING: 'Mangler',
	MONITOR: 'Monitor',
	SELECT_MONITOR: 'Velg monitor',
	SELECT_MONITORS: 'Velg monitorer',
	SELECT_SERVICE_MODEL: 'Velg tjenestemodell',
	TEMPLATES: 'Maler',
	MONITORS: 'Monitorer',
	OUT_OF: 'av',
	PARENT: 'Tilh\u00F8rer',
	DATA: 'Data',
	NO_DATA: 'Ingen data',
	SHOW: 'Vis',
	SHOW_METRICS: 'Vis metrikker',
	SHOW_SINGLE_METRIC_GRAPH: 'Se i single graf',
	SHOW_MULTIGRAPH: 'Se i multigraf',
	SHOW_TABLE: 'Se i tabell',
	SHOW_REGRESSION: 'Vis trend',
	SHOW_THRESHOLD: 'Vis terskelverdi',
	HIDE: 'Skjul',
	IMAGE: 'Bilde',
	LOGO_AREA: 'Logo omr\u00E5de er 180px - 40px',
	HI_THRESHOLD: 'HI terskel',
	WIDGETS: 'Widgeter',
	// Buttons
	ADD: 'Legg til',
	CANCEL: 'Avbryt',
	OPEN: 'Åpen',
	CLOSE: 'Lukk',
	CLOSED: 'Lukket',
	RUNNING: 'Kj\u00F8rer',
	STOPPED: 'Stoppet',
	COLLAPSE_ALL: 'Lukk alle',
	COUNT: 'Antall',
	CONFIGURATION: 'Konfigurasjon',
	CLEAR: 'T\u00F8m',
	FILTER: 'Filter',
	FILTERS: 'Filtere',
	FILTERING: 'Filtrering',
	SORTING: 'Sortering',
	FILTER_TYPE: 'Filtertype',
	OK: 'OK',
	SAVE: 'Lagre',
	SELECT_FILE: 'Velg fil...',
	SELECT: 'Velg...',
	SELECT_FILTER: 'Velg et filter...',
	SELECT_ACCOUNT: 'Velg konto...',
	SELECT_ACCOUNTS: 'Velg kontoer:',
	SELECT_SERVICE: 'Velg tjeneste...',
	SELECT_SERVICE_ELEMENT: 'Velg Tjenesteelement',
	SELECT_SLA: 'Velg SLA...',
	SELECT_APPLICATION: 'Velg applikasjon...',
	SELECT_ASSET_GROUP: 'Velg ressursgruppe...',
	SELECT_ASSET: 'Velg ressurs... ',
	SELECT_ACTION: 'Velg hendelse',
	FINISH: 'Fullf\u00F8rt',
	UPLOAD: 'Last opp...',
	CLICK_TO_UPLOAD: 'Klikk for \u00E5 lastet opp bilde',
	CLICK_TO_VIEW_EDIT_SCRIPT: 'Klikk for \u00E5 se/redigere skript',
	CLICK_TO_EDIT_ASSET_GROUP: 'Klikk for \u00E5 redigere ressursgruppen',
	MARK_READ: 'Merk som lest',
	BACK: 'Tilbake',
	NEXT: 'Neste',
	DELETE: 'Slett',
	DELETED: 'Slettet',
	TIME_DELETED: 'Tid slettet',
	RECALCULATE: 'Rekalkuler',
	RECALCULATION: 'Rekalkulering',
	RECALCULATE_SERVICE_SLA: 'Rekalkuler tjeneste SLA',
	IMPORT: 'Importer ',
	EXPORT: 'Eksporter',
	CONVERT: 'Konverter',
	SHARE: 'Del',
	EDIT: 'Endre',
	RELOAD: 'Last p\u00E5 nytt',
	REDISCOVER_DISK: 'Gjenoppdag disker',
	RESET: 'Tilbakestill',
	RESTORE_DEFAULT: 'Gjenopprett standard',
	DOWNLOAD: 'Last ned',
	DOWNLOAD_LOGVIEW: 'Last ned aktiv logg',
	DOWNLOAD_LOGFOLDER: 'Last ned loggmappe',
	ADD_URL: 'Legg til Url',
	ADD_SCHEDULE: 'Legg til plan',
	ADD_NOTIFICATION: 'Legg til varsel ',
	DELETE_NOTIFICATION: 'Slett varsel',
	GENERATE: 'Generer',
	MODULE: 'Modul',
	DISABLE_SCHEDULES: 'Deaktiver plan',
	CLEAR_SCHEDULES: 'Slett plan',
	DISABLE_ALL: 'Deaktiver alle',
	DISABLED: 'Deaktivert',
	DELETE_ALL: 'Slett alle',
	ENABLE_ALL: 'Aktiver alle',
	ENABLED: 'Aktiv',
	RECENTLY_USED: 'Sist brukte ikoner',
	ENABLE_NOTIFICATION: 'Aktiver varsling',
	DISABLE_NOTIFICATION: 'Deaktiver varsel',
	// Actions
	CREATE: 'Opprett',
	UPDATE: 'Oppdater',
	REMOVE: 'Fjern',
	REMOVE_DISK: 'Fjern disker',
	STRETCH: 'Strekk ut',
	DETAILS: 'Detaljer',
	// Colors
	BLUE: 'Bl\u00E5',
	GREEN: 'Gr\u00F8nn',
	ORANGE: 'Oransje',
	RED: 'R\u00F8d',
	TRANSPARENT: 'Gjennomsiktig',
	WHITE: 'Hvit',
	YELLOW: 'Gul',
	// Week days
	MONDAY: 'Mandag',
	TUESDAY: 'Tirsdag',
	WEDNESDAY: 'Onsdag',
	THURSDAY: 'Torsdag',
	FRIDAY: 'Fredag',
	SATURDAY: 'L\u00F8rdag',
	SUNDAY: 'S\u00F8ndag',
	// Time
	SECOND: 'Sekund',
	SECONDS: 'Sekunder',
	SEC: 'sek',
	MINUTE: 'Minutt',
	MINUTES: 'Minutter',
	HOUR: 'Time',
	HOURS: 'Timer',
	DAY: 'Dag',
	TODAY: 'I dag',
	LIST: 'Liste',
	DAYS: 'Dager',
	DAYS_7: '7 dager',
	DAYS_30: '30 dager',
	QUARTER: 'Kvartal',
	WEEK: 'Uke',
	WEEKS: 'Uker',
	BUSINESSWEEK: 'Arbeidsuke',
	WEEKEND: 'Helg',
	MONTH: 'M\u00E5ned',
	MONTHS: 'M\u00E5neder',
	YEAR: 'År',
	YEARS: 'År',
	TIME: 'Tid',
	REASON_TEXT: 'Helse\u{E5}rsaktekst',
	CREATED_TIME: 'Opprettet tid',
	VISIBILITY: 'Synlighet',
	TIMEZONE: 'Tidssone',
	TIMESETTINGS: 'Tidsinnstillinger',
	CUSTOM: 'Tilpasset',
	LAST: 'Siste',
	PERIOD: 'Periode',
	SHORT_HOUR: 't',
	SHORT_MINUTE: 'm',
	SHORT_SECOND: 's',
	SHORT_MILISECOND: 'ms',
	SHORT_DAY: 'D',
	SHORT_WEEK: 'U',
	SHORT_MONTH: 'M',
	//incident metrics values
	HIGH: 'H\u00F8y',
	LOW: 'Lav',
	NORMAL: 'Normal',
	MEDIUM: 'Medium',
	MINIMUM: 'Minimum',
	PLANNING: 'Planlegging',
	RIGHT: 'H\u00F8yre',
	LEFT: 'Venstre',
	CENTER: 'Senter',
	GRID_VIEW: 'Tabell',
	CARD_VIEW: 'Kort',
	ROWS: 'St\u00F8rrelse',
	MAINTENANCE_PERIOD: 'Vedlikeholdsperiode',
	MAINTENANCE: 'Vedlikehold',
	EXCLUDED: 'Untatt',
	AUTO: 'AUTO',
	MONITOR_QUALIFIER: 'Monitorpunkt',
	PLAY_EVENTS: 'Spill hendelser',
	PAUSE_EVENTS: 'Pause hendelser',
	INCLUDE_SUBACCOUNTS: 'Inkluder underkonto',
	MOVE_TO_SELECTED: 'Flytt til merket',
	ORIGINAL: 'Opprinnelig',
	EVENT_SUMMARY: 'Hendelse oversikt',
	LAST_CHANGES: 'Siste endringer',
	DEFAULT_MONITORING: 'Standard',
	CUSTOM_MONITORING: 'Tilpasset',
	NONE_MONITORING: 'Ingen',
	MONITORING: 'Monitorering',
	REVERT: 'Tilbakestill',
	SERVICE_QUALIFIER_COUNT: 'Tjenestepunkt',
	MONITOR_METRIC_COUNT: 'Monitor metrikkantall',
	EXTERNAL_METRIC_COUNT: 'Ekstern metrikkantall',
	IGNORE_USER_TAGS: 'Ignorer etiketter',
	SHOW_UNTAGGED: 'Vis uten etikett',
	RECIPIENTS: 'Mottaker',
	AVAILABILITY: 'Tilgjengelig',
	SET_AVAILABILITY: 'Sett tilgjengelighet',
	ACTIVE_INACTIVE: 'Aktiv/Inaktiv',
	SERVICE_DOWNTIME: 'Nedetid tjeneste',
	SERVICE_BREACHES: 'Tjenestebrudd',
	STATE_DURATION: 'Tilstandsvarighet',
	SOURCE_ID: 'Kilde id',
	TARGET_ID: 'Mål id',
	HEALTH: 'Helse',
	ASSIGN_EVENT: 'Tildel hendelse',
	SUPPRESSION: 'Undertrykk',
	ASSIGNED_TO: 'Tildelt til',
	ASSIGNED_TO_TYPE: 'Tildelt type',
	IGNORE_ACTIVE_TAGS: 'Ignorer aktive brukeretikketer',
	ASSET_GROUPS: 'Ressursgrupper',
	ACKNOWLEDGE: 'Bekreft',
	ACKNOWLEDGED_BY: 'Bekreftet av',
	CREATED: 'Opprettet',
	EXPORT_CSV: 'Eksporter til CSV',
	DELETE_REASON: 'Slett helse\u00e5rsak',
	ERROR_MESSAGE: 'Feilmelding',
	NOTIFICATION_MESSAGE: 'Melding i tjenestelogg',
	AREA_CHART: 'Area',
	AREASPLINE_CHART: 'Areaspline',
	LINE_CHART: 'Linje',
	RANGE_CHART: 'Range',
	SPLINE_CHART: 'Spline graf',
	APPLY: 'Bruk',
	FROM: 'Fra',
	TO: 'Til',
	SHOW_ADVANCED_INFO: 'Vis avansert informasjon',
	HIDE_ADVANCED_INFO: 'Skjul avansert informasjon',
	ADVANCED_INFO_TITLE: 'Avansert informasjon',
	SET_INFORMATION: 'Sett informasjon',
	SCHEDULE: 'Tidsplan',
	ROOT_CAUSE_TOOLTIP: 'For \u{E5} se andre rothelse\u{E5}rsaker bruk en kortere tidsperiode eller zoom til \u{F8}nsket tid.',
	CHOOSE_LOGO_IMAGE: 'Velg logo bilde',
	GLYPHICON_ICONS: 'Glyphicon ikoner',
	MATERIAL_ICONS: 'Material ikoner',
	COPY: 'Kopier',
	CALENDAR: 'Kalender',
	METRIC: 'Metrikk',
	NOW: 'N\u00e5',
	ASSET_GROUP: 'Ressursgruppe',
	INFRASTRUCTURE: 'Infrastruktur',
	HOLIDAY: 'Helligdag',
	HISTORIC: 'Historikk',
	VENDOR: 'Leverandør',
	INSTALL_DATE: 'Installasjonsdato',
	REGISTER_TIME: 'Registrert tid',
	ACTION_TYPE: 'Handlingstype',
	ACTION_DATA: 'Handlingsdata',
	CALLER_ID: 'Trigger regel',
	SET_IN_MAINTENANCE: 'Sett i vedlikehold',
	END_MAINTENANCE: 'Avslutt vedlikehold',
	APPLICATION: 'Applikasjon',
	SCRIPT: 'Skript',
	SEVERITIES: 'Alvorlighet',
	REQUIRED_FIELD: 'P\u00e5krevd felt',
	FILTERED: 'Filtrert',
	LEGACY: ' (Legacy)',
	ADD_EVENT_TRIGGER: 'Hendelsetrigger',
	ADD_HEALTH_TRIGGER: 'Helsetrigger',
	CREATE_GROOVY_MONITOR: 'Opprett Groovy monitor',
	CREATE_POWERSHELL_MONITOR: 'Opprett PowerShell monitor',
	HEALTH_INDEX: 'Helseindeks',
	PERSIST: 'Vedvare',
	messages: {
		COMMON_DIRTY_CONFIRMATION: 'Du har ulagret innhold, du vil miste endringen hvis du klikker Lukk. Trykk avbryt og lagre endringen hvis du vil beholde endringene.',
		USE_NOTIFICATION_SCHEDULE: 'Bruk varslingskalender...',
		NIMSOFT_INTERVAL_READONLY: 'Ikke tilgjengelig, Nimsoft QoS gir intervallet',
		ACTIVE_TAGS: 'Etiketter er aktive i denne visningen',
		ACTION_SUCCEEDED: 'Handling lyktes',
		ACTION_REMOVE_DISKS: 'Dette vil slette merkede disk(er) fra system monitoren p\u00e5 agenten eller template agenten dersom det er en asset gruppe.',
		CLEAR_CONTEXT_MESSAGE: 'Bruker instilling ble nullstilt for gjeldende side',
		DISABLED_SCHEDULE: 'Varsling er deaktivert',
		RULE_NOT_EDITABLE: 'Denne typen er bare supportert med skript',
		EVENTSOURCE_CONNECTION_LOST: 'Mistet forbindelse med serveren. Pr\u00F8v \u00e5 laste applikasjonen p\u00e5 nytt.',
		SERVER_CONNECTION_LOST: 'Mistet forbindelse med serveren. Pr\u00F8ver \u00e5 gjenopprette forbindelsen.',
		NO_RIGHTS: 'Du har ikke rettigheter til \u00e5 utf\u00F8re denne handlingen.',
		REQUIRED_FIELDS: 'Vennligst fyll ut alle felt',
		FILE_SIZE_LIMIT: 'Filen m\u00e5 v\u00e6re mindre enn 1 MB.',
		GAUGE_DATA_REPRESENTATION: 'Hvis inverter er aktiv s\u00e5 vil verdier under brudd verdi v\u00e6re r\u00f8dt. Inaktiv vil si at verdier over brudd verdi er r\u00f8dt',
		GAUGE_MIN_VALUE: 'Start verdi for m\u00e5ler',
		GAUGE_MAX_VALUE: 'Maksimum verdi for m\u00e5ler',
		GAUGE_BREACH_VALUE: 'Verdier over dette er farget r\u00f8dt',
		SHOW_DESCRIPTION: 'Vis beskrivelse',
		SHOW_INFORMATION: 'Vis informasjon',
		SHOW_ACCOUNT_NAME: 'Vis kontonavn',
		INVALID_TIME: 'Ugyldig tid',
		NO_IE_SUPPORT: 'Ceeview st\u00F8tter ikke Internet Explorer. Produktet vil ikke fungerer optimalt. Klikk OK for og fortsette.',
		TAG_CHOOSE: 'Etikett er satt...',
		TAG_NEW_EXISTING: 'Velg fra tilgjengelige etiketter eller opprett ny...',
		NO_TAGS: 'Velg etikett...',
		PLEASE_INPUT_NAME: 'Skriv inn profilnavn',
		PLEASE_INPUT_ACTION_SCRIPT: 'Skriv inn action skript',
		REASONS_ASSETS_ACKNOWLEDGED: 'Helse\u00c5rsaker er bekreftet',
		REASONS_ASSETS_UNACKNOWLEDGED: 'Helse\u00c5rsaker er ubekreftet',
		REASONS_ACKNOWLEDGED: 'Helse\u00c5rsak(er) for ressurs #=assetName# er bekreftet',
		REASONS_UNACKNOWLEDGED: 'Helse\u00c5rsak(er) for ressurs #=assetName# er ubekreftet',
		TAGS_SUCCESS_MESSAGE: 'Etikett er satt',
		SERVICE_STATE_UP_TITLE: 'Tjenesten er OPPE',
		SERVICES_STATE_UP_TITLE: 'Tjenestene er OPPE',
		SERVICE_STATE_DOWN_TITLE: 'Tjenesten er NEDE',
		SERVICES_STATE_DOWN_TITLE: 'Tjenestene er NEDE',
		SERVICE_STATE_WARNING_TITLE: 'Tjeneste med ADVARSEL',
		SERVICES_STATE_WARNING_TITLE: 'Tjenester med ADVARSEL',
		SERVICE_STATE_INACTIVE_TITLE: 'tjeneste er INAKTIVE',
		SERVICES_STATE_INACTIVE_TITLE: 'tjenester er INAKTIVE ',
		SERVICE_STATE_MAINTENANCE_TITLE: 'Tjenesten er i vedlikehold',
		SERVICES_STATE_MAINTENANCE_TITLE: 'Tjenestene er i vedlikehold',
		SERVICE_WARNING: 'Tjenesteadvarsel',
		GRID_RELOADED: 'Tabellen er lastet p\u00e5 nytt',
		NO_DATA_AVAILABLE: 'Data ikke tilgjengelig',
		SERVER_ERROR: 'Uventet server feil',
		DELAY_GRID: 'Utsett tabelloppdatering med ${interval} sekunder',
		LEADING_TRAILING_WHITESPACES: 'Strengen begynner/avsluttes med mellomrom',
		SERVICES_QUALIFIERS_DOWN: '#=breaches# #= breaches === 1 ? "tjeneste" : "tjenester"# er oppe med 1 eller flere tjenestepunkt i brudd',
		SERVICES_AGENT_DOWN: 'Det er 1 eller flere tjenester som har systemadvarsel. Disse advarslene kan v\u00E7re feil p\u00E5 agent, agent i advarsel, agent/ressurs i vedlikeholds- og tjenestepunktfeil. Klikk for \u00E5 se de ber\u00F8rte tjenestene.',
		ASSET_DOWN: 'Det er 1 eller flere ressurser som har system advarsel. Disse advarslene kan v\u00E7re feil p\u00E5 agent, agent i advarsel og monitorfeil. Klikk for \u00E5 se de ber\u00F8rte ressursene.',
		APPLICATION_DOWN: 'Det er 1 eller flere ressurs/ressursgrupper som har system advarsel. Advarselene kan v\u00E6re ressurs/ressursgrupper i vedlikehold eller monitor error',
		SERVICESUP_AGENTS_MAINTENANCE: '#= services# #= services === 1 ? "tjeneste" : "tjenester"# som er oppe med 1 eller flere agenter i vedlikehold',
		SERVICESW_WITH_BREACHES: '#= breaches# #= breaches === 1 ? "tjeneste" : "tjenester"# med advarsel med 1 eller flere tjenestepunkt i brudd',
		SERVICESW_AGENTS_MAINTENANCE: '#= services# #= services === 1 ? "tjeneste" : "tjenester"# med advarsel som har 1 eller flere agenter i vedlikehold',
		USER_HELPTEXT: 'LDAP type bruker m\u00E5 h\u00E5ndtere passord i AD',
		EVENT_SUCCESSFULLY_ASSIGNED: 'Hendelsen ble tildelt',
		EVENT_SUCCESSFULLY_UNASSIGNED: 'Hendelsen fjernet fra tildeling',
		REASON_SUCCESSFULLY_REMOVED: 'Helse\u00c5rsaken ble fjernet',
		REASONS_SUCCESSFULLY_REMOVED: 'Helse\u00c5rsakene ble fjernet',
		ASSET_IN_MAINTENANCE: 'Ressurs er i vedlikehold',
		AUTHENTICATION_IN_USE: 'Autentiseringen er i bruk av et tjenestepunkt, den kan ikke slettes',
		SLA_CONFIGURATION: 'SLA konfigurasjon',
		INFO_SUCCESSFULLY_UPDATE: 'Informasjonsfelt er oppdatert',
		MAINTENANCE_HISTORIC_NOT_EDITABLE: 'Fra og til tid kan ikke endres for historisk periode',
		MAINTENANCE_HISTORIC_PERIODS: 'Historisk perioder',
		SET_ASSET_ALL_PROFILES: 'Sett ressurs i alle profiler',
		HISTORIC_EVENT: '"Denne hendelsen er historisk',
		CLICK_VIEW_ACTIONS: 'Klikk for \u00e5 se handlinger',
		CLEARED_ACTION_INFORMATION: 'Handlingsinformasjon er slettet grunnet databevarings innstillinger',
		ROOT_CAUSE_NO_RECORDS: "Det er ingen helse\u{E5}rsaksanalyser for tidsperioden."
	},
	// kendo
	kendo: {
		PAGER_DISPLAY: '{0} - {1} av {2} elementer',
		PAGER_EMPTY: 'Ingen elementer \u00e5 vise',
		PAGER_PAGE: 'Side',
		PAGER_OF: 'av {0}',
		PAGER_ITEMS: 'Elementer per side',
		PAGER_FIRST: 'G\u00e5 til f\u00f8rste side',
		PAGER_PREVIOUS: 'G\u00e5 til forrige side',
		PAGER_NEXT: 'G\u00e5 til neste side',
		PAGER_LAST: 'G\u00e5 til siste side',
		PAGER_REFRESH: 'Oppdatere',
		UPLOAD_IMAGE: 'Last opp bilde',
		UPLOAD_SELECT: 'Velg...',
		UPLOAD_STATUS_FAILED: 'Opplasting feilet',
		UPLOAD_UPLOADED: 'Lastet',
		UPLOAD_UPLOADING: 'Laster',
		UPLOAD_SELECTED_FILES: 'Legg til bilde...',
		pager: {
			messages: {
				display: '{0} - {1} av {2} elementer',
				empty: 'Ingen elementer \u00e5 vise',
				page: 'Side',
				of: 'av {0}',
				itemsPerPage: 'Elementer per side',
				first: 'G\u00e5 til f\u00f8rste side',
				previous: 'G\u00e5 til forrige side',
				next: 'G\u00e5 til neste side',
				last: 'G\u00e5 til siste side',
				refresh: 'Oppdatere'
			}
		},
		columnMenu: {
			messages: {
				columns: 'Kolonner',
				filter: 'Filter',
				sortAscending: 'Sorter stigende',
				sortDescending: 'Sorter synkende'
			}
		}
	},
	// account
	account: {
		ACCOUNT_CREATE: 'Lag konto',
		ACCOUNT_NEW: 'Ny konto',
		ACCOUNT_EDIT: 'Rediger konto',
		ACCOUNT_DELETE: 'Slett konto',
		ACCOUNT_DELETE_CURRENT: 'Du kan ikke slette gjeldende konto, det m\u00E5 gj\u00F8res fra konto på nivået over.',
		ACCOUNT_DETAILS: 'Kontodetaljer',
		ACCOUNTS_DELETE: 'Slett konto',
		ACCOUNT_ID: 'Konto id',
		ACCOUNT_NAME: 'Kontonavn',
		ACCOUNT_DESCRIPTION: 'Konto beskrivelse',
		ACCOUNT_STATUS: 'Kontostatus',
		GLYPH_ICONS: 'System ikoner',
		USER_IMAGES: 'Opplastete bilder',
		SYSTEM_TYPE: 'Systemtype',
		SYSTEM_TYPE_STANDARD: 'Standard',
		SYSTEM_TYPE_GENERIC: 'Generisk',
		SYSTEM_TYPE_WINDOWS: 'Windows',
		SYSTEM_TYPE_UNIX: 'Unix',
		TARGETS: 'M\u00e5l',
		AGENT_NAME: 'Skriv inn navn p\u00e5 agent',
		AGENT_DEPLOY: 'Distribuer ny agent',
		AGENT_CREATE: 'Lag ny agent',
		ASSET_NAME: 'Ressurs navn',
		ASSET_TYPE: 'Ressurstype',
		ASSET_DESCRIPTION: 'Ressurs beskrivelse',
		ASSET_DETAILS: 'Ressurs detaljer',
		ASSET_CREATE: 'Lag ressurs',
		ASSET_EDIT: 'Rediger ressurs',
		ASSET_NEW: 'Ny ressurs',
		ASSET_DELETE: 'Slett ressurs',
		ASSET_CREATE_AUTH: 'Lag autentisering',
		ASSET_GROUP_NAME: 'Ressursgruppe navn',
		ASSET_GROUP_DESCRIPTION: 'Beskrivelse',
		ASSET_GROUP_NEW: 'Ny ressursgruppe',
		ASSET_GROUP: 'Ressursgruppe',
		ASSET_GROUPS: 'Ressursgrupper',
		ASSET_GROUP_CREATE: 'Lag ressursgruppe',
		ASSET_GROUP_EDIT: 'Rediger ressursgruppe',
		ASSET_GROUP_DELETE: 'Slett ressursgruppe',
		ASSET_GROUP_DETAILS: 'Ressursgruppe detaljer',
		ASSET_GROUP_ADD_TO: 'Legg til ressursgruppe',
		ASSET_GROUP_REMOVE: 'Fjern fra ressursgruppe',
		ASSET_EXTERNAL_ID: 'Ekstern id',
		ASSET_EXTERNAL_TYPE: 'Ekstern type',
		ASSET_EXTERNAL_OWNER: 'Ekstern eier',
		AUTH_NAME: 'Autentisering navn',
		AUTH_DESCRIPTION: 'Autentiseringsbeskrivelse',
		AUTH_SELECT: 'Velg autentisering...',
		AUTH_SELECT_TYPE: 'Velg autentiseringtype...',
		AUTH_DOMAIN: 'Domene',
		AUTH_PARAM_1: 'Param1',
		AUTH_PARAM_2: 'Param2',
		AUTH_PARAM_3: 'Param3',
		AUTH_PARAM_4: 'Param4',
		AUTH_PARAM_5: 'Param5',
		AUTH_HOST: 'Vert',
		AUTH_PORT: 'Port',
		AUTH_DATABASE: 'Database',
		AUTH_PRIVATE_KEY: 'Privat n\u00f8kkel',
		AUTH_SECURITY_NAME: 'Security navn',
		AUTH_DRIVER: 'Driver',
		AUTH_CONNECTION_STRING: 'Tilkoblingstreng',
		AUTH_CREATE: 'Opprett autentisering',
		AUTH_EDIT: 'Rediger autentisering',
		AUTH_NEW: 'Ny autentisering',
		AUTH_DELETE: 'Slett autentisering',
		AUTH_DETAILS: 'Autentiseringdetaljer',
		USER_EDIT: 'Rediger bruker',
		USER_DELETE: 'Slett bruker(e)',
		USER_ADD: 'Legg til en ny bruker for denne kontoen',
		USER_CREATE: 'Lag bruker',
		USER_NEW: 'Ny bruker',
		USER_SELECT_ACCOUNT: 'Velg konto...',
		USER_SELECT_SERVICEBOARD: 'Velg tjenestetavle',
		USER_SELECT_CATEGORY: 'Velg rolle...',
		USER_SELECT_LANGUAGE: 'Velg spr\u00e5k...',
		USER_SELECT_TIMEZONE: 'velg tidssone...',
		TEAM_NAME: 'Team navn',
		TEAM_DESCRIPTION: 'Team beskrivelse',
		TEAM_USERS: 'Team brukere',
		TEAM_CREATE: 'Opprett team',
		TEAM_UPDATE: 'Oppdater team',
		TEAM_DELETE: 'Slett team',
		TEAM_NEW: 'Nytt team',
		TEAMS: 'team',
		TEAM_EDIT: 'Rediger team',
		CATEGORY_USER_CREATE: 'Opprett rolle',
		CATEGORY_CREATE: 'Opprett rolle',
		SERVICE_MODEL_TEMPLATE_EDIT: 'Endre tjenestemodell mal',
		SERVICE_MODEL_TEMPLATE_DELETE: 'Slett tjenestemodell mal',
		SERVICE_MODEL_TEMPLATE_DETAILS: 'Tjenestemodell mal detaljer',
		SERVICE_ELEMENT_TEMPLATE_EDIT: 'Endre tjenesteelement mal',
		SERVICE_ELEMENT_TEMPLATE_DELETE: 'Slett tjenesteelement mal',
		SERVICE_ELEMENT_TEMPLATE_DETAILS: 'Tjenesteelement mal detaljer',
		SERVICE_QUALIFIER_TEMPLATE_EDIT: 'Endre tjenestepunkt mal',
		SERVICE_QUALIFIER_TEMPLATE_DELETE: 'Slett tjenestepunkt mal',
		SERVICE_QUALIFIER_TEMPLATE_DETAILS: 'Tjenestepunkt mal detaljer',
		SUMMARY: 'Konto oversikt',
		INSTALL_MODULE: 'Installer modul',
		UNINSTALL_MODULE: 'Avinstaller modul',
		NOTIFICATION_POLICY: 'Retningslinjer for melding',
		CREATE_ASSET_GROUP: 'Opprett ny ressursgrupper',
		ALL_ASSETS: 'Alle ressurser',
		SELECTED_ASSETS: 'Valgte ressurser',
		MOVE_TO_SELECTED_ASSETS: 'Legg til valgte ressurser',
		ACCESS_KEY_ID: 'Access Key ID',
		SECRET_ACCESS_KEY: 'Secret Access Key',
		AWS_REGION: 'AWS Region',
		AZURE_SUBSCRIPTION_ID: 'Subscription ID',
		AZURE_TENANT: 'Tenant',
		AZURE_CLIENT: 'Client',
		AZURE_KEY: 'Key',
		GOOGLE_PROJECT_ID: 'Project ID',
		GOOGLE_PRIVATE_KEY: 'Private Key',
		GOOGLE_DATASET: 'Datasett',
		CALENDAR_CREATE: 'Opprett kalender',
		EVENT_CREATE: 'Opprett hendelse',
		EVENT_EDIT: 'Rediger hendelse',
		messages: {
			LOGO_BEST_RESOLUTION: 'Best oppl\u00f8sning er 120 X 40 px for logobilde',
			CHOOSE_TEAMS: 'Velg team...',
			CHANGE_DEFAULT_PASSWORD: 'Du m\u00e5 endre passordet. ',
			YOUR_FIRST_ACCOUNT: 'Sett navn for konto',
			ACCOUNT_ENTER_NAME: 'Fyll inn konto navn.',
			ENTER_TEAM: 'Sett inn navn p\u00e5 team',
			ACCOUNT_TOP_MESSAGE: 'Fyll ut skjemaet for \u00e5 opprette en <strong>ny konto</strong>',
			ACCOUNT_SUCCESS_CREATED: 'Konto opprettet',
			ACCOUNT_SUCCESS_UPDATED: 'Konto informasjon oppdatert',
			ACCOUNT_SUCCESS_DELETED: 'Kontoen er slettet',
			ACCOUNT_REMOVE_CONFIRMATION: 'Vil du slette konto?',
			ACCOUNTS_REMOVE_CONFIRMATION: 'Vil du slette kontoene??',
			NO_SUBACCOUNTS: 'Ingen underkonto',
			NO_SUBACCOUNTS_RIGHT: 'Du har ikke rettigheter til \u00e5 se underkonto',
			NO_ACCOUNTS: 'Ingen konto er opprettet',
			NO_ASSETS_CREATED: 'Ingen ressurser er opprettet',
			NO_ASSET_GROUPS: 'Ingen ressursgrupper er opprettet',
			NO_AUTHENTICATIONS: 'Ingen autentisering er opprettet',
			NO_SERVICES: 'Ingen tjenester er tilgjengelige',
			NO_SERVICE_MODELS: 'Ingen tjenestemodeller er opprettet',
			NO_SERVICE_ELEMENTS: 'Ingen tjenesteelementer er opprettet',
			NO_SERVICE_QUALIFIERS: 'Ingen tjenestepunkt er opprettet',
			NO_TEAMS: 'Ingen team er opprettet',
			NO_USERS: 'Ingen brukerer er opprettet',
			NO_USERS_RIGHT: 'Du har ikke rettigheter til \u00e5 se brukerlisten',
			NO_ASSETS: 'Ingen ressurser funnet',
			NO_ASSETS_RIGHT: 'Du har ikke rettigheter til \u00e5 se ressurslisten',
			NO_ROLES: 'Det er ingen roller opprettet',
			NO_ROLES_RIGHT: 'Du har ikke rettigheter til \u00e5 se brukerroller',
			NO_AGENTS: 'Det er ingen agenter tilknyttet',
			NO_AGENTS_RIGHT: 'Du har ikke rettigheter til \u00e5 se agentlisten',
			NO_AUTH: 'Det er ingen autentisering tilknyttet',
			NO_AUTH_RIGHT: 'Du har ikke rettigheter til \u00e5 se autentiseringslisten',
			NO_RESULTS: 'Ingen treff',
			NO_TEMPLATES_DEFINED: 'Ingen maler er opprettet',
			REQUIRED_FIELDS: 'Vennligst fyll inn alle felt ',
			ASSET_TOP_MESSAGE: 'Fyll ut f\u00f8lgende skjema for \u00e5 lage en <strong>ny ressurs</strong>',
			ASSET_TOP_MESSAGE_UPDATE: 'Fyll ut f\u00f8lgende skjema for \u00e5 oppdatere ressurs innstillinger',
			ASSET_UPLOAD_SELECT: 'Legg til bilde...',
			ASSET_FILE_TYPES: 'Bare .jpg, .jpeg og .png filer kan lastes.',
			TEMPLATE_FILE_TYPES: 'Bare .zip filer kan lastes opp',
			ASSET_ADD_TARGETS: 'Legg til m\u00e5l for din ressurs',
			ASSET_REMOVE_CONFIRMATION: 'Er du sikker p\u00e5 at du vil slette ressursen?',
			ASSETS_REMOVE_CONFIRMATION: 'Vil du slette disse ressursene?',
			ASSET_SUCCESS_DELETED: 'Ressursen er slettet',
			ASSETS_SUCCESS_DELETED: 'Ressursene er slettet',
			ASSET_SUCCESS_CREATED: 'Ressursen er opprettet',
			ASSET_SUCCESS_UPDATED: 'Ressursinnstillinger er lagret',
			ASSET_INPUT_NAME: 'Skriv inn navn p\u00e5 ressurs',
			EVENT_INPUT_NAME: 'Sett inn hendelsenavn',
			CALENDAR_INPUT_NAME: 'Sett inn kalendernavn',
			ASSET_GROUP_TOP_MESSAGE: 'Fyll ut f\u00f8lgende skjema for \u00e5 lage en <strong>Ny ressursgruppe</strong>',
			ASSET_GROUP_TOP_MESSAGE_UPDATE: 'Fyll ut f\u00f8lgende skjema for \u00e5 oppdatere beskrivelsen for ressursgruppen',
			ASSET_GROUP_INPUT_NAME: 'Skriv inn navn p\u00e5 ressursgruppen',
			ASSET_GROUP_NO_ASSETS: 'Det er ingen ressurser i gruppen',
			ASSET_GROUP_ONE_ASSET: 'Det er en ressurs i gruppen',
			ASSET_GROUP_COUNT_FIRST: 'Det er,',
			ASSET_GROUP_COUNT_SECOND: 'ressurser i gruppen',
			ASSET_GROUP_CREATE_NO_RIGHTS: 'Du har ikke rettigheter til \u00e5 opprette ny ressursgruppe',
			ASSET_GROUP_REMOVE_CONFIRMATION: 'Vil du slette ressursgruppen?',
			ASSETS_GROUP_REMOVE_CONFIRMATION: 'Vil du slette disse ressursgruppene?',
			ASSET_GROUP_SUCCESS_DELETED: 'Ressursgruppe er slettet',
			ASSET_GROUPS_SUCCESS_DELETED: 'Ressursgruppe er slettet',
			ASSET_GROUP_SUCCESS_CREATED: 'Ressursgruppe er opprettet',
			ASSET_GROUP_SUCCESS_UPDATED: 'Ressursgruppe er oppdatert',
			ASSET_GROUP_CHOOSE: 'Velg en ressursgruppe... ',
			ASSET_GROUP_NO_GROUPS: 'Det er ingen ressursgruppe',
			ASSET_DELETE_INFO: 'Vi har 3 avhengighetstyper for ressurs<br/>SOFT: Ressurs fjernes fra monitor eller applikasjon(Formen i applikasjonen må fjernes manuelt.)<br/>HARD: Monitor eller tjenestepunkt blir slettet når ressursen.<br/>MANUAL: Ressurs fjernes manuelt av brukeren fra monitorkonfigurasjon',
			ASSET_DELETE_TIED_AGENT: 'Denne ressursen er knyttet til en agent. For \u00E5 slette den m\u00E5 du f\u00F8rst slette agenten',
			USER_DELETE_INFO: 'Bruker ${name} m\u00E5 fjernes fra f\u00F8lgende elementer f\u00F8r den kan slettes',
			ASSET_GROUP_DELETE_INFO: 'For \u{E5} slette denne ressursgruppen m\u{E5} vi fjerne koblingene. For applikasjon vil ressursgruppe ID fjernes, men du m\u{E5} fjerne formen manuelt.',
			AUTH_FORM_NAME: 'Skriv inn navn p\u00e5 autentiseringen',
			AUTH_COMMUNITY_PASSPHRASE: 'Community passord',
			COMMUNITY_PW: 'Community PW',
			PRIVACY: 'Privacy',
			AUTH: 'Auth',
			AUTH_PRIVACY_PASSPHRASE: 'Privacy passord',
			AUTH_PASSPHRASE: 'Auth passord',
			AUTH_REMOVE_CONFIRMATION: 'Vil du slette autentisering',
			AUTHS_REMOVE_CONFIRMATION: 'Vil du slette autentiseringene?',
			AUTH_SUCCESS_DELETED: 'Autentisering er slettet',
			AUTH_SUCCESS_CREATED: 'Autentisering er opprettet',
			AUTH_SUCCESS_UPDATED: 'Autentisering er oppdatert',
			AUTH_UNAVAILABLE: 'Autentisering ikke tilgjengelig for kontoen',
			AUTH_SELECT_AUTH_TYPE: 'Velg autentiseringstype',
			AGENT_SUCCESS_CREATED: 'Agent er opprettet',
			AGENT_SUCCESS_UPDATED: 'Agent er oppdatert',
			AGENT_SUCCESS_DELETED: 'Agent er slettet',
			AGENT_REMOVE_CONFIRMATION: 'Vil du slette agent?',
			AGENT_DOWN: 'Agent er nede',
			AGENT_ERROR: 'Agent Feil',
			AGENT_WARNING: 'Agent har advarsel',
			QUALIFIER_ERROR: 'Tjenestepunkt feil',
			MONITOR_ERROR: 'Monitorfeil',
			METRIC_ERROR: 'Metrikkfeil',
			MONITOR_ERROR_UNAVAILABLE: 'Monitorfeil: Data er ikke tilgjengelig',
			AGENT_MAINTENANCE: 'Agent er i vedlikehold',
			ASSET_MAINTENANCE: 'Ressursen er satt i vedlikehold',
			MONITOR_MAINTENANCE: 'Monitor i vedlikehold',
			MONITOR_DATA_UNAVAILABLE: 'Overv\u00E5k data utilgjengelig',
			AGENT_TOP_MESSAGE: 'Fyll ut f\u00f8lgende skjema for \u00e5 lage en <strong>ny agent</strong>',
			AGENT_TOP_MESSAGE_UPDATE: 'Fyll ut f\u00f8lgende skjema for \u00e5 oppdatere agent innstillinger',
			USER_TOP_MESSAGE: 'Fyll ut f\u00f8lgende skjema for \u00e5 lage en <strong>ny bruker</strong>',
			USER_TOP_MESSAGE_UPDATE: 'Fyll ut f\u00f8lgende skjema for \u00e5 oppdatere brukeren',
			USER_SUCCESS_CREATED: 'Bruker opprettet',
			USER_SUCCESS_SAVED: 'Brukerprofilen oppdatert',
			USER_REMOVE_CONFIRMATION: 'Vil slette bruker',
			USERS_REMOVE_CONFIRMATION: 'Vil du slette brukerene?',
			USER_SUCCESS_DELETED: 'Bruker er slettet',
			USER_INPUT_USERNAME: 'Legg til brukernavn',
			USER_INPUT_FIRST_NAME: 'Legg til fornavn',
			USER_INPUT_LAST_NAME: 'Legg til etternavn',
			USER_PASSWORD_LENGTH: 'Passordet m\u00e5 minst ha 6 bokstaver og ikke v\u00E6ære identisk med forrige passord',
			USER_PASSWORD_DIFF_USERNAME: 'Passordet m\u00e5 v\u00c6re forskjellig fra brukernavnet',
			USER_WRONG_PASSWORD: 'Passordet er feil',
			USER_CHANGE_PASSWORD: 'Her kan du endre passord. <br /> La dette feltet v\u00e6re tomt hvis ikke du vil endre <br/>passordet',
			USER_PASSWORD_SUCCESSFULLY_CHANGED: 'Passord er oppdatert',
			USER_PASSWORD_IDENTICAL: 'Passordene m\u00e5 v\u00e6re identiske',
			USER_PASSWORD_SPACES: 'Passord kan ikke inneholde tomme felter',
			USER_USERNAME_SPACES: 'Brukernavn kan ikke inneholde tomme felter',
			USER_RETYPE_PASSWORD: 'Skriv inn passord igjen for validering',
			USER_INVALID_EMAIL: 'E-post addresse er ikke gyldig',
			USER_COMPLETE_FIELDS: 'Fyll inn alle p\u00e5krevde felt',
			USER_CATEGORY_UPDATE: 'Rolle er oppdatert',
			USER_CATEGORY_UPDATE_NO_RIGHTS: 'Du har ikke rettigheter til \u00e5 oppdatere rolle',
			USER_CATEGORY_READ_NO_RIGHTS: 'Du har ikke rettigheter til \u00e5 lese rolle',
			USER_CATEGORY_REMOVE_CONFIRMATION: 'Vil du slette rolle?',
			USER_CATEGORIES_REMOVE_CONFIRMATION: 'Vil du slette rollene?',
			USER_CATEGORY_SUCCESS_DELETED: 'Rolle er slettet',
			USER_CATEGORIES_SUCCESS_DELETED: 'Rollene er slettet',
			TEAM_TOP_MESSAGE: 'Fyll ut f\u00f8lgende skjema for \u00e5 lage et <strong>nytt team</strong>',
			TEAM_TOP_MESSAGE_UPDATE: 'Fyll ut f\u00f8lgende skjema for \u00e5 oppdatere team',
			TEAM_SUCCESS_CREATED: 'Team er opprettet',
			TEAM_SUCCESS_UPDATED: 'Team er oppdatert',
			TEAM_DELETE_INFO: 'Du m\u00e5 fjerne team ${name} fra f\u00F8lgende elementer f\u00F8r det kan slettes',
			TEAM_REMOVE_CONFIRMATION: 'Vil slette team?',
			TEAMS_REMOVE_CONFIRMATION: 'Vil du slette team?',
			TEAM_SUCCESS_DELETED: 'team er slettet',
			TEAM_NAME_STATUS: 'Skriv inn navn p\u00e5 team',
			TEAM_NO_USERS: 'Det er ingen brukere i team',
			TEAM_ONE_USER: 'Det er en bruker i team',
			TEAM_USER_COUNT_FIRST: 'Det er',
			TEAM_USER_COUNT_SECOND: 'brukere i team',
			CATEGORY_REMOVE_CONFIRMATION: 'Vil slette rollen',
			CATEGORY_ROLES_SUCCESS_UPDATED: 'Roller er oppdatert',
			WARNING_USER_AUTH_CONNECTION_ISSUE: 'Det kan v\u00e6re et problemt med nettverket. Brukerinformasjon er ikke sendt',
			SERVICE_ELEMENT_TEMPLATE_SUCCESS_DELETED: 'Tjenesteelement mal var slettet',
			SERVICE_MODEL_TEMPLATE_REMOVE_CONFIRMATION: 'Vil du slette tjenestemodell mal?',
			SERVICE_MODEL_TEMPLATE_SUCCESS_UPDATED: 'Tjenestemodell mal er oppdatert',
			SERVICE_MODEL_TEMPLATE_SUCCESS_DELETED: 'Tjenestemodell er slettet',
			SERVICE_TEMPLATE_SUCCESS_UPLOADED: 'Fullf\u00F8rt importering. Sjekk nye maler i tabell',
			INPUT_SERVICE_MODEL_TEMPLATE_NAME: 'Skriv inn navn for tjenestemodell mal',
			SERVICE_ELEMENT_TEMPLATE_REMOVE_CONFIRMATION: 'Vil du slette tjenesteelement mal?',
			SERVICE_ELEMENT_TEMPLATE_SUCCESS_UPDATED: 'Tjenesteelement mal oppdatert',
			INPUT_SERVICE_ELEMENT_TEMPLATE_NAME: 'Skriv inn navn for tjenesteelement mal',
			SERVICE_QUALIFIER_TEMPLATE_REMOVE_CONFIRMATION: 'Vil du slette tjenestepunkt mal',
			SERVICE_QUALIFIER_TEMPLATE_SUCCESS_UPDATED: 'Tjenestepunkt mal er oppdatert',
			INPUT_SERVICE_QUALIFIER_TEMPLATE_NAME: 'Skriv inn navn p\u00e5 tjenestepunkt mal',
			MODULE_SUCCESS_INSTALL: 'Komponent er installert',
			MODULE_SUCCESS_UNINSTALL: 'Komponent er avinstallert',
			MODULE_FAIL_INSTALL: 'Komponent ble ikke installert',
			MODULE_FAIL_UNINSTALL: 'Komponent ble ikke avinstallert',
			REASONS_ASSIGNED: 'Helse\u00c5rsakene er tildelt',
			NOTIFICATIONS_REMOVE_CONFIRMATION: 'Vil slette meldingene?',
			DELETE_EVENT_TITLE: 'Slett driftstid',
			DELETE_EVENT_CONFIRMATION: 'Vil slette denne driftstid?',
			DELETE_NOTIFICATION_CONFIRMATION: ' Klikk slett for \u00E5 fjerne varselet',
			MARK_MESSAGES_READ: 'Merk meldinger som lest',
			MARK_MESSAGES_UNREAD: 'Merk meldinger som ulest',
			NON_REMOVABLE_TARGET: 'Dette m\u00e5let er i bruk i sjekkpunkt og kan ikke slettes',
			USER_CATEGORY_PRIVILEGES_INFO: 'Denne gruppen har <strong>${checkedNr}</strong> rettigheter selektert av totalt <strong>${totalNr}</strong>',
			PERMISSION_VERSION_CHECK: 'Valgt endring vil migrere rollen til ny rettighetssystem, sjekk at alle \u00f8nskede menyer er valgt eller kanseller endring',
			ENGINE_ID: 'Engine ID',
			CONTEXT_ENGINE_ID: 'Context engine ID',
			CONTEXT_NAME: 'Context navn',
			SECURITY_LEVEL: 'Sikkerhetsniv\u{E5}'
		}
	},
	// service board
	serviceBoard: {
		CREATE: 'Opprett',
		CREATE_DASHBOARD: 'Opprett dashboard',
		UPDATE: 'Oppdater',
		SERVICE_BOARD: 'Tjenestetavle',
		DASHBOARD: 'Dashboard',
		ADD_WIDGET: 'Legg til widget',
		EDIT_WIDGET: 'Rediger widget',
		EDIT_LAYOUT: 'Endre oppsett',
		CUSTOMIZE_SERVICE_BOARD: 'Egenskaper',
		DESCRIPTION: 'Beskrivelse',
		DETACH: 'Koble fra',
		SERVICE_BOARDS: 'Tjenestetavler',
		SERVICEBOARD_LINK: 'Tjenestetavle lenke',
		DELETE_SERVICE_BOARD: 'Slett tjenestetavle',
		DELETE_SERVICE_BOARDS: 'Slett tjenestetavler',
		CREATE_SERVICE_BOARD: 'Lag tjenestetavle',
		SET_HOMEPAGE: 'Sett som hjemmeside',
		CLEAR_HOMEPAGE: 'Fjern som hjemmeside',
		SET_PUBLIC: 'Sett som felles',
		SET_PRIVATE: 'Sett som privat',
		VISIBILITY: 'Tilgang',
		IGNORE_MISSING_DATA: 'Ignorer manglende data',
		RAW_DATA: 'Raw data',
		ADVANCED_MODE: 'Avansert modus',
		SHOW_MISSING_DATA: 'Vis manglende data',
		TURN_ON_HEADER: 'Skru p\u00e5 overskrift',
		TURN_OFF_HEADER: 'Skru av overskrift',
		LOCKED_RESOLUTION: 'L\u00e5st oppl\u00f8sning',
		MOBILE_RESOLUTION: 'Mobil oppl\u00f8sning',
		MOVE: 'Flytt',
		SETTINGS: 'Instillinger',
		CHART_SETTINGS: 'Graf innstillinger',
		FIT_SCREEN: 'Responsiv oppl\u00F8sning',
		AVAILABLE_METRICS: 'Tilgjengelige metrikker',
		SLA_TIME: 'SLA tid',
		LAST_UPDATE: 'Sist oppdatert: ',
		LAST_UPDATE_SERVICE_BOARD: 'Tjenestetavlen var sist oppdatert: ',
		SERVICE_DETAILS: 'Tjeneste detaljer',
		MODEL_STATE: 'Modelltilstand',
		DATEFORMAT: 'Datoformat',
		TIMEFORMAT: 'Tidsformat',
		ASSET_NAME: 'Ressursnavn',
		ASSET_ID: 'Ressurs id',
		ACCOUNT: 'Konto',
		SOURCENAME: 'Kildenavn',
		AGENT_NAME: 'Agentnavn',
		AGENT_ID: 'Agent id',
		CATEGORY_ID: 'Kategori id',
		CATEGORY_NODE: 'Kategori node',
		CATEGORY_PATH: 'Kategori path',
		INSTANCE_NAME: 'Instansnavn',
		METRIC_ID: 'Metrikk id',
		METRIC_TYPE: 'Metrikk type',
		REGISTRY_IDENTIFIER: 'Register id',
		REGISTRY_OWNER_ID: 'Register eier id',
		REGISTRY_TYPE: 'Registertype',
		UNIT_TYPE: 'Enhetstype',
		UNIT_TYPE_SYMBOL: 'Enhetstype symbol',
		ADD_METRIC: 'Legg til metrikk',
		LOCK_TO_SCREEN: 'Lås til skjerm',
		SLIDE: 'Skyv',
		OFF: 'Av',
		ELEMENTS_DOWN_WARNING: 'Kun nede eller i advarsel',
		QUALIFIERS_BREACHED_WARNING: 'Kun nede eller i advarsel',
		messages: {
			MISSING_DATA_MESSAGE: 'Manglende data vil bli vist med et gr\u00e5tt felt',
			FILL_TAG: 'Sett inn navn for dashboard',
			Fill_NAME: 'Sett inn navn for dashboard',
			SERVICE_BOARD_CREATED: 'dashboard er opprettet',
			SERVICE_BOARD_UPDATED: 'dashboard er oppdatert',
			WIDGET_REMOVE: 'Vil slette widgeten',
			CREATE_SERVICE_BOARD_INFO: 'Lag dashboard for tjeneste',
			DEPLOY_NEW_SERVICE_BOARD: 'Distribuer dashboard',
			SERVICE_BOARD_REMOVE: 'Vil du slette dashboard?',
			SERVICE_BOARD_REMOVE_CONFIRMATION: 'Vil du slette dashboard?',
			SERVICE_BOARDS_REMOVE_CONFIRMATION: 'Vil du slette dashboardene?',
			SERVICE_BOARD_REMOVED: 'Dashboard er slettet',
			SERVICE_BOARD_SUCCESS_DELETED: 'Dashboard er slettet',
			SERVICE_BOARD_SUCCESS_PUBLIC: 'Dashboard er satt som felles dashboard',
			SERVICE_BOARD_SUCCESS_PRIVATE: 'Dashboard er satt som privat dashboard',
			CREATE_YOUR_SERVICE_BOARD: 'Lag dashboard',
			NO_DATA: 'Ingen data er tilgjengelig for valgt periode',
			NO_ITEMS_AVAILABLE: 'Ingen elementer er tilgjengelig',
			NO_SERVICEBOARDS: 'Ingen dashboard er opprettet',
			COPY_LINK: 'Kopier lenken for og \u00e5pne dashboard i en annen instans',
			PROCEED_TO_EXIT: 'Du kan ha endringer som ikke er lagret i dashboard. Er du sikker p\u00e5 at du vil fortsette?',
			EXIT_DIRTY_CONFIRMATION: 'Du har ulagret innhold i dashboard, du vil miste endringen hvis du klikker Lukk. Trykk avbryt og lagre endringen hvis du vil beholde endringene.',
			NO_READ_PERMISSION: 'Manglende rettighet SERVICEBOARD_READ er n\u00f8dvendig for \u00e5 vise dashboard',
			SELECT_ELEMENT: 'Velg minst et element',
			COPY_SERVICEBOARD: 'Dupliser dashboard',
			COPY_SERVICEBOARD_MESSAGE: 'Sett navn og klikk opprett for \u00e5 duplisere dashboardet ',
			COPY_SERVICEBOARD_SUCCESS: 'Dashboardet duplisert',
			CONVERT_SERVICEBOARD: 'Konverter tjenestetavle',
			CONVERT_SERVICEBOARD_MESSAGE: 'Denne handlingen vil konvertere tjenestetavlen ${name} til ${type} oppsett. Gjeldende oppsett vil bli tapt.',
			CONVERT_SERVICEBOARD_SUCCESS: 'Tjenestetavlen ble konvertert',
			DOES_NOT_APPLY_TO_MOBILE: 'Gjelder ikke for mobile tjenestetavler',
			DASHBOARD_SUCCESSFULLY_IMPORTED: 'Dashboard ble importert.',
			DASHBOARD_IMPORT_ERROR: 'Klarte ikke å importere dashboard. Det kan være noe feil med filen.'
		}
	},
	// reports
	reports: {
		ALL_ACCOUNTS: 'Alle kontoer',
		ALL_SERVICES: 'Alle tjenester',
		CREATE_A_NEW_REPORT: 'Lag ny rapport',
		CREATE_REPORT: 'Lag rapport',
		INCLUDE_ALL_SERVICES: 'Inkluder alle tjenester',
		INCLUDE_SATE: 'Tjenestetilstand',
		INCLUDE_SLA: 'SLA n\u00e5v\u00e6rende oversikt',
		INCLUDE_SLA_HISTORY: 'SLA historikk oversikt',
		INCLUDE_SLA_HISTORY_DETAILS: 'SLA historikk detaljer',
		NONE: 'Ingen',
		FIRST_PAGE: 'F\u00F8rste side',
		LAST_PAGE: 'Siste side',
		DATE_FROM: 'Fra',
		DATE_TO: 'Til',
		DELETE_REPORT: 'Slett rapport',
		EDIT_REPORT: 'Endre rapport',
		CREATED: 'Opprettet',
		MODIFIED: 'Endret',
		SERVICE_SUMMARY: 'Tjeneste sammendrag',
		DOWNLOAD_REPORT: 'Last ned rapport',
		SLA_SUMMARY: 'SLA sammendrag',
		SLA_HISTORY: 'SLA historikk',
		SLA_DETAILS: 'SLA detaljer',
		UPDATE_REPORT: 'Oppdater rapport',
		COMMENT_SPOT: 'Kommentarplass',
		GENERATE_REPORT: 'Generer rapport',
		REPORT_TYPE: 'Rapporttype',
		THIS_QUARTER: 'Dette kvartalet',
		THIS_MONTH: 'Denne m\u00E5neden',
		THIS_WEEK: 'Denne uken',
		TODAY: 'I dag',
		LAST_QUARTER: 'Forrige kvartal',
		LAST_MONTH: 'Forrige m\u00E5ned',
		LAST_WEEK: 'Forrige uke',
		LAST_DAY: 'Siste dag',
		YESTERDAY: 'I g\u00E5r',
		LAST_7DAYS: 'Siste 7 dager',
		LAST_30DAYS: 'Siste 30 dager',
		DOWNLOAD_BROWSER: 'Last ned i nettleser',
		SEND_EMAIL: 'Send p\u00E5 epost til',
		WAIT_PREVIEW: 'Vent for visning',
		REPORT_MAIL_SENT: 'N\u00E5r rapporten er ferdig vil den bli sendt til',
		REPORT_DOWNLOAD_BROWSER: 'N\u00E5r rapporten er generert vil den bli lastet ned i nettleseren',
		OVERRIDE_CACHED_REPORT: 'Overstyr bufret rapport',
		CHOOSE_RECIPIENTS: 'Velg mottakere',
		DELIVERY_TYPE: 'Leveringstype',
		DELIVERY_INFORMATION: 'Leveringsinformasjon',
		messages: {
			REPORT_CREATED: 'Rapporten opprettet.',
			REPORT_UPDATED: 'Oppdateringen vellykket.',
			SELECT_ACCOUNT: 'Velg konto...',
			FILTER_SELECT_SERVICE: 'Velg tjeneste for å filtrere rapportdata',
			SELECT_SERVICES: 'Velg tjenester...',
			SELECT_SLAS: 'Velg SLA...',
			SELECT_ASSETS: 'Velg ressurser...',
			REPORT_DELETED: 'Rapport er slettet',
			NO_REPORTS: 'Det er ingen tilgjengelige rapporter',
			REPORT_REMOVE_CONFIRMATION: 'Vil du slette rapporten?',
			REPORTS_REMOVE_CONFIRMATION: 'Vil du slette disse rapportene?',
			SCHEDULE_ENTER_NAME: 'Sett inn et navn',
			NAME_REQUIRED: 'Sett inn tidsplannavn',
			DYNAMIC_SERVICES_SELECTED: 'Dynamiske tjenester er valgt. Alle tjenester inkludert',
			INCORRECT_DATE_FORMAT: 'Feil datoformat'
		},
		dynamicLabels: {
			aggregateLevel: 'Aggregert',
			textColor: 'Skriftfarge',
			pageBreak: 'Sidebrekk',
			textSize: 'Tekstst\u00f8rrelse',
			textFont: 'Teksttype',
			separatorVisible: 'Vis skilletegn',
			textString: 'Tekst',
			pageBreakOnEachItem: 'Sidebrekk p\u00e5 hvert element',
			serviceStateVisible: 'Tjenestetilstand',
			serviceDescriptionVisible: 'Beskrivelse',
			serviceInformationVisible: 'Informasjon',
			assetInformationVisible: 'Informasjon',
			slaSummaryVisible: 'Sammendrag',
			slaExcludePeriodsVisible: 'Eksluderingsperioder',
			slaHistoryVisible: 'Historikk',
			slaHistoryDetailsVisible: 'Historikk detaljer',
			assetHealthThreshold: 'Helseterskel',
			assetHealthReasonLimit: 'Ressurshelse\u00C5rs\u00E5ksgrense',
			assetHealthDetailsVisible: 'Helsedetaljer',
			currentSummaryVisible: 'Gjeldende sammendrag',
			sortOnCompliance: 'Sorter p\u00e5 samsvar',
			showBreachesOnly: 'Bare brudd',
			historicSummaryVisible: 'Historikk sammendrag',
			rootCauseVisible: 'Tjenestebrudd sammendrag',
			dailyAggregateVisible: 'Daglig aggregert',
			breachDetailsVisible: 'Brudd detaljer',
			serviceLogDetailsVisible: 'Tjenestelogg oppf\u00f8ringer',
			breachOverviewVisible: 'Bruddoversikt',
			dateFormat: 'Datoformat',
			chartsWithoutDataVisible: 'Vis grafer uten data',
			healthIndexThreshold: 'Helseterskel',
			automaticUnitScaling: 'Automatisk enhetsskalering',
			assetTitleVisible: 'Ressurstittel',
			multiChart: 'Multigraf',
			rangeLow: 'Lavt omr\u00e5de',
			chartType: 'Graftype',
			chartMode: 'Graftype',
			chartHeight: 'Graf h\u00f8yde',
			rangeHigh: 'H\u00f8yt omr\u00e5de',
			chartWidth: 'Graf bredde',
			showLabel: 'Etikett',
			title: 'Tittel',
			sortAscending: 'Sorter stigende',
			servicePercentageFilter: 'Samsvarsfilter(%)',
			show3D: 'Vis 3D',
			showCurrentState: 'Vis gjeldene status',
			monitorCalculationVisible: 'Vis monitorkalkulering ',
			assetCalculationVisible: 'Vis ressurskalkulering',
			showServiceElementInfo: 'Vis tjeneste element info',
			showServiceQualifierInfo: 'Vis tjenestepunkt info',
			serviceBreachOverviewVisible: 'Tjenestebrudd oversikt',
			serviceDowntimeSummaryVisible: 'Tjenestebrudd sammendrag',
			serviceElementBreachOverviewVisible: 'Tjenesteelementbrudd oversikt',
			headerType: 'Overskrift type',
			slaPrecision: 'Antall desimaler',
			periodSpan: 'Historiske perioder',
			showDescription: 'Tjenestepunkt beskrivelse',
			rootCauseReportMode: 'Rapporttype',
			assetNameFilter: 'Ressursfilter',
			skipIfNoData: 'Ignorere om ingen data',
			assetAccountNameVisible: 'Vis kontonavn',
			showUnacknowledgedOnly: 'Vis bare ubekreftede',
			showUnassignedOnly: 'Vis bare ikke tildelte',
			maxEvents: 'Maks hendelser',
			targetFilter: 'Målfilter',
			typeFilter: 'Type filter',
			showHealthIndex: 'Vis helseindeks',
			healthThreshold: 'Helseterskel verdi',
			healthReasonLimit: 'Helseårsak grense',
			healthReasonsVisible: 'Helseårsak synlig',
			healthDetailsVisible: 'helsedetaljer synlig',
			showDetails: 'Vis detaljer',
			monitorHealthVisible: 'Monitorhelse synlighet',
			severityFilter: 'Alvorlighetsfilter',
			showAsCard: 'Vis som kort',
			showChangesLastHours: 'Siste time endringer',
			messageFilter: 'Meldingsfilter',
			showActionLog: 'Vis handlingslogg',
			includeCurrentEvents: 'Inkluder nåværende hendelser',
			includeHistoricEvents: 'Inkluder historiske hendelser',
			useVerticalLabels: 'Bruk stående tekst',
			categorizedByTime: 'Kategoriser med tid',
			categorizedBySubsystem: 'Kategoriser med subsystem',
			categorizedByTarget: 'Kategoriser med mål',
			categorizedByName: 'Kategoriser med navn',
			categorizedByEventType: 'Kategoriser med hendelsetype',
			categorizedByMonitorType: 'Kategoriser med monitortype',
			nameSelector: 'Navn',
			currency: 'Valuta',
			budgetVisible: 'Vis budsjett',
			costVisible: 'Vis kost',
			totalVisible: 'Vis total',
			listingPriceVisible: 'Vis listerpris',
			monthOrder: 'Månedsrekkef\u00f8lge',
			currentMonthFirst: 'Vis n\u00e5v\u00e6rende m\u00e5ned f\u00f8rst',
			displayDecimals: 'Desimaler ',
			displayAs1000: 'Vis i 1000',
			query: 'Sp\u00f8rring',
			showCurrentYear: 'Vis n\u00e5v\u00e6rende \u00e5r\r',
			topN: 'Topp N',
			showOther: 'Vis andre',
			useListingPrice: 'Listepris',
			estimatedCostVisible: 'Vis estimert kostnad',
			actualCostVisible: 'Vis faktisk kostnad',
			showLegend: 'Vis navneinformasjon',
			showTitle: 'Vis tittel',
			pageBreakOnFirstItem: ' Sidebrekk på f\u00f8rste element'
		}
	},
	// widget
	widget: {
		DECIMALS: 'Vis desimaler',
		CODE: 'Kode',
		MESSAGE: 'Melding',
		DATE: 'Dato',
		STATUS: 'Status',
		TIME: 'Tid',
		MODEL: 'Modell',
		METRIC: 'Metrikk',
		SELECTED_METRICS: 'Valgte metrikker',
		SELECT_ASSET_GROUPS: 'Velg ressursgrupper',
		SELECT_METRIC_CATEGORIES: 'Velg metrikk roller',
		PERIOD: 'Periode',
		DISPLAY_RATE: 'Visning',
		DISPLAY_SETTINGS: 'Visninginstillinger',
		PERIOD_COMPLETION: 'Periode ferdigstillelse',
		SERVICE: 'Tjeneste',
		SERVICE_MODEL: 'Tjenestemodell',
		SERVICE_HISTORY: 'Tjenestehistorie',
		STATE_CHANGE: 'Tilstandsendringer',
		LAST_CHANGE_DURATION: 'Siste endring varighet',
		LAST_CHANGE_TIME: 'Sist endring tidspunkt',
		HIERARCHY: 'Hierarki',
		PREVIEW: 'Forh\u00e5ndsvisning',
		SUMMARY: 'Oversikt',
		ASSET_SUMMARY: 'Ressursoversikt',
		GROUP_SUMMARY: 'Gruppeoversikt',
		SERVICE_LEVEL_AGREEMENT: 'Tjenesteavtale',
		SLA: 'SLA',
		PRESENTATION: 'Presentasjonsdesigner',
		CREATE_PRESENTATION: 'Opprett presentasjon',
		CALCULATION_PERIOD: 'Kalkuleringsperiode',
		COLUMN_VISIBILITY: 'Kolonne visning',
		INCIDENT_VISIBILITY: 'Synlighet for saker',
		HISTORY: 'Historikk',
		UPDATE_WIDGET: 'Oppdater widget',
		SELECT_ACCOUNT: 'Velg konto',
		SELECT_PROFILE: 'Velg profil',
		SELECT_CATEGORY: 'Velg rolle',
		SELECT_RULE_TYPE: 'Velg regeltype',
		SELECT_SAMPLE_RATE: 'Velg samplingsrate',
		SELECT_KPI_ACCOUNTS: 'Velg kontoer (KPI)',
		SELECT_SERVICE_MODEL: 'Velg en tjenestemodell',
		SELECT_SERVICE: 'Velg tjeneste',
		SELECT_SLA: 'Velg tjenesteavtale',
		SELECT_TAGS: 'Velg etiketter',
		SELECT_QUALIFIER: 'Velg tjenestepunkt',
		ADD_QUALIFIER: 'Legg til tjenestepunkt',
		SELECT_MONITOR_QUALIFIER: 'Velg monitor metrikk',
		SELECT_SERVICE_ELEMENT: 'Velg tjenesteelement',
		SELECT_ASSET: 'Velg ressurs',
		SELECT_MONITOR_TYPE: 'Velg monitortype',
		SELECT_MONITOR: 'Velg monitor',
		SELECT_SUBSYSTEM: 'Velg delsystem',
		SELECT_INSTANCE: 'Velg instans',
		SELECT_METRICS: 'Velg metrikk',
		SELECT_MISSING_FIELD: 'Sett verdi i ufullstendig felt',
		SELECT_MISSING_FIELDS: 'Sett verdi i ufullstendige felter',
		SELECT_CUSTOM_PERIOD_DATE: 'Velg dato periode',
		EXPAND_ELEMENTS: 'Ekspander elementer',
		METRIC_TYPE: 'Metrikk type',
		LINE: 'Kurve',
		AREA: 'Omr\u00e5de',
		RANGE: 'Kurve og omr\u00e5de',
		BREACH_VALUE: 'Brudd verdi ',
		MAX_VALUE: 'Maks verdi ',
		MIN_VALUE: 'Min verdi ',
		INVERT: 'Inverter',
		PERIODS: 'Periode',
		IMAGE_TEXT: 'Bilde og tekst',
		SYSTEM_MONITOR_CONSOLE: 'System monitorkonsoll',
		IBM_CONSOLE: 'IBM monitorkonsoll ',
		HIDE_TIME_SELECTOR: 'Skjul tidsvelger',
		SELECT_BY_ASSET: 'Velg fra ressurs',
		SELECT_BY_MONITOR: 'Velg fra monitor',
		HIDE_HEADER: 'Skjul tittel',
		HIDE_BORDER: 'Skjul ramme',
		TRANSPARENT: 'Gjennomsiktig',
		INCIDENT_COUNTER: 'Saksteller',
		SHOW_BREACHES: 'Vis bruddteller',
		SHOW_DOWNTIME: 'Vis nedetidteller',
		DISPLAY_INFORMATION: 'Visningsinformasjon',
		ADDITIONAL_INFORMATION: 'Tilleggsinformasjon',
		STRETCH: 'Tilpass bilde',
		SELECT_IMAGE: 'Klikk for \u00E5 velge et bilde',
		AGGREGATION: 'Aggregering',
		CHART_TYPE: 'Diagramtype',
		HISTORY_SHOW_FOOTER: 'Vis sist endring og varighetstid',
		DYNAMIC_SERVICE_INFO: 'Alle tjenester blir vist',
		errorCodes: {
			OK: 'OK',
			ERROR: 'Feil',
			INFORMATION: 'Informasjon',
			CONNECTION: 'Kobling',
			CONNECTION_LOOKUP: 'Oppkobling oppslag',
			CONNECTION_REFUSED: 'Oppkobling avvist',
			CONNECTION_NO_ROUTE: 'Oppkobling ingen rute',
			CONNECTION_TIMEOUT: 'Oppkobling tidsavbrudd',
			CONNECTION_PORT_OUT_OF_RANGE: 'Oppkoblingsport er utenfor omr\u00E5de',
			CONNECTION_PORT_UNREACHABLE: 'Oppkoblingsport kan ikke n\u00E5es',
			AUTHENTICCATION: 'Autentisering',
			CONFIGURATION: 'Konfigurasjon',
			AGENT: 'Agent',
			AGENT_DOWN: 'Agent nede',
			AGENT_UNKNOWN: 'Ukjent agent',
			METRICSTORE: 'Metrikk lager',
			HEALTHMONITOR: 'Helsemonitor',
			MESSAGEBUS: 'Meldingsbuss',
			SYSTEM: 'System',
			UNKNOWN: 'Ukjent feilkilde'
		},
		ibm: {
			TITLE: 'IBM Monitor Konsoll',
			SEQUENCE_NUMBER: 'Sekvens nummer',
			SAMPLE_TIME: 'Samplings tid',
			OBJECT_NAME: 'Objekt navn',
			EVENT_ID: 'Hendelse id'
		},
		messages: {
			REGRESSION_LINE: 'Regresjonslinje',
			NO_ZOOM_AVAILABLE: 'Videre zoom er ikke mulig',
			CREATE_SERVICE_MODEL_FOR_HISTORY: 'Opprett tjenestemodell for \u00e5 se tjenestehistorikk.',
			CREATE_PREVIEW_WIDGET: 'For \u00e5 opprette tjenestemodell widget m\u00e5 du f\u00F8rst velge tjeneste',
			CREATE_SLA_WIDGET: 'For \u00e5 opprette tjenesteavtale widget m\u00e5 du f\u00F8rst velge tjenesteavtale',
			CREATE_SLA_HISTORY_WIDGET: 'For \u00e5 opprette SLA historikk widget m\u00e5 du f\u00F8rst velge tjenesteavtale',
			CREATE_METRICS_WIDGET: 'For \u00e5 opprette metrikk widget m\u00e5 du fylle inn alle felter',
			CREATE_SUMMARY_WIDGET: 'For \u00e5 opprette hjem widget m\u00e5 du velge tjeneste',
			CREATE_SERVICE_HISTORY_WIDGET: 'For \u00e5 opprette tjenestehistorikk widget m\u00e5 du fylle inn alle felter',
			CREATE_GAUGE_WIDGET: 'For \u00e5 opprette m\u00e5ler widget m\u00e5 du fylle inn alle felter',
			CREATE_MULTIGRAPH_WIDGET: 'For \u00e5 opprette multigraf widget m\u00e5 du fylle inn feltene nedenfor',
			CREATE_SERVICES_WIDGET: 'For \u00e5 opprette tjenestetilstand widget m\u00e5 du fylle inn feltene nedenfor',
			CREATE_ASSET_HEALTH_WIDGET: 'For \u00e5 opprette helsewidget m\u00e5 du velge konto',
			CREATE_ASSET_CONSOLE_WIDGET: 'For \u00e5 opprette ressurskonsoll widget m\u00e5 du fylle inn alle felter',
			CREATE_SERVICE_STATUS_WIDGET: 'For \u00e5 opprette tjenestetilstand widget m\u00e5 du velge konto',
			CREATE_SLA_SUMMARY_WIDGET: 'For \u00e5 opprette SLA oversikt widget m\u00e5 du velge konto',
			CREATE_HOME_GRID_WIDGET: 'Opprett hjem tabell widget',
			CREATE_SERVICE_GRID_WIDGET: 'Opprett tjeneste tabell widget',
			CREATE_HEALTH_GRID_WIDGET: 'Opprett helse tabell widget',
			CREATE_SLA_GRID_WIDGET: 'Opprett SLA tabell  widget',
			CREATE_TEXT_WIDGET: 'Opprett tekst widget',
			CREATE_IMAGE_WIDGET: 'Opprett bilde widget',
			FILL_IN_NUMERIC_MIN: 'Sett inn numerisk verdi for minimum',
			FILL_IN_NUMERIC_BREACH: 'Sett inn numerisk verdi for brudd',
			FILL_IN_NUMERIC_MAX: 'Sett inn numerisk verdi for maksimum',
			FILL_IN_NUMERIC_PERIODS: 'Sett inn numerisk verdi for period',
			BREACH_VALUE_MAX: 'Brudd verdien kan ikke v\u00e6re st\u00f8rre enn maksimum verdien',
			MIN_VALUE_MAX: 'Minimum verdi m\u00e5 ikke v\u00e6re st\u00F8rre enn maksimum',
			ASSETS_WITHOUT_HEALTHINDEX: 'Ressurser uten helseindeks:',
			TOTAL_CRITICAL_ASSETS: 'Totalt antall ressurser i kritisk tilstand:',
			TOTAL_MAJOR_ASSETS: 'Totalt antall ressurser i alvorlig tilstand:',
			TOTAL_MINOR_ASSETS: 'Totalt antall ressurser i advarsel tilstand:',
			TOTAL_OK_ASSETS: 'Totalt antall ressurer i OK tilstand:',
			TOTAL_MAINTENANCE_ASSETS: 'Antall ressurser i vedlikehold:',
			TOTAL_CRITICAL_APPLICATIONS: 'Totalt antall applikasjoner i kritisk tilstand: ',
			TOTAL_MAJOR_APPLICATIONS: 'Totalt antall applikasjoner i alvorlig tilstand: ',
			TOTAL_MINOR_APPLICATIONS: 'Totalt antall applikasjoner i advarsel tilstand: ',
			TOTAL_OK_APPLICATIONS: 'Totalt antall applikasjoner i OK tilstand: ',
			TOTAL_INACTIVE_APPLICATIONS: 'Totalt antall ressurser i INAKTIV tilstand: ',
			ASSET_GROUPS_WITHOUT_HEALTHINDEX: 'Ressursgrupper uten helseindeks',
			TOTAL_CRITICAL_ASSET_GROUPS: 'Antall ressursgrupper i KRITISK tilstand:',
			TOTAL_MAJOR_ASSET_GROUPS: 'Antall ressursgrupper i ALVORLIG tilstand:',
			TOTAL_MINOR_ASSET_GROUPS: 'Antall ressursgrupper i ADVARSEL tilstand:',
			TOTAL_OK_ASSET_GROUPS: 'Antall ressursgrupper i OK tilstand:',
			TOTAL_HIGH_INCIDENTS: 'Totalt antall saker i H\u00D8Y tilstand:',
			TOTAL_MEDIUM_INCIDENTS: 'Totalt antall saker i MEDIUM tilstand:',
			TOTAL_LOW_INCIDENTS: 'Totalt antall saker i LAV tilstand:',
			TOTAL_OK_INCIDENTS: 'Totalt antall saker i OK tilstand:',
			TOTAL_ASSETS: 'Totalt antall ressurser:',
			TOTAL_APPLICATION: 'Totalt antall applikasjoner',
			TOTAL_BREACHED_SLA: 'Totalt antall SLA i brudd tilstand:',
			TOTAL_WARNING_SLA: 'Totalt antall SLA i advarsel tilstand:',
			TOTAL_OK_SLA: 'Totalt antall SLA i samsvar tilstand:',
			TOTAL_SLA: 'Totalt antall SLA:',
			TOTAL_BREACHED_LINKEDSERVICE: 'Tilkoblede tjenester i bruddtilstand',
			TOTAL_WARNING_LINKEDSERVICE: 'Tilkoblede tjenester i advarseltilstand',
			TOTAL_OK_LINKEDSERVICE: 'Tilkoblede tjenester i OK tilstand',
			TOTAL_LINKEDSERVICE: 'Tilkoblede tjenester',
			WARNING_QUALIFIERS_DOWN: 'Vis tjeneste som advarsel (gul) hvis sjekkpunkt(er) er nede',
			SHOW_COUNTER: 'Vis teller for saker per tjeneste',
			SHOW_OVERVIEW: 'Vis saksoversikt p\u00e5 klikk',
			SHOW_CLOSED_INCIDENTS: 'Vis lukkede saker',
			OPEN_INCIDENTS: '\u00E5pne saker',
			CLOSED_INCIDENTS: 'vsluttede saker',
			COUNTER_CLICK: 'Klikk teller for \u00E5 \u00E5pne gjeldende saker',
			TREND: 'Vis trend',
			MISSING_DATA: 'Ignorer manglende data',
			HIDE_ERRORS: 'Skjul feil',
			THRESHOLD: 'Vis terskelverdi',
			MAXIMIZE_SETTINGS: 'Klikk her eller maksimer ikonet for \u00E5 se innstillingene',
			SELECT_NEW_METRICS: 'Klikk her for \u00E5 velge metrikker',
			MAXIMUM_PERIODS_NUMBER: 'Maks antall perioder er',
			AGGREGATION_MOUSEOVER: 'Aggregering: Et datapunkt kan best\u00E5 av flere metrikker avhengig av tidsskala. Som standard viser vi høyeste verdi av disse beregningene'
		}
	},
	// service
	service: {
		STATE_QUALIFIER: 'Tjenestepunkt tilstand',
		STATE_MODEL: 'Modell tilstand',
		STATE_ELEMENT: 'Element tilstand',
		SERVICE_INCIDENT: 'Tjenestesak',
		START_SERVICE: 'Start tjeneste',
		STOP_SERVICE: 'Stopp tjeneste',
		CREATE_SERVICE: 'Lag tjeneste',
		CREATE_A_NEW_SERVICE: 'Lag en ny tjeneste',
		CREATE_NEW_SERVICE: 'Lag en ny tjeneste',
		DOCUMENTS: 'Dokument',
		HAS_ATTACHMENTS: 'Har vedlegg',
		HAVE_DOCUMENTS: 'Har dokument',
		HAVE_NO_DOCUMENTS: 'Har ikke dokument',
		ESTIMATED_COST: 'Estimert kostnad',
		ESTIMATED_REVENUE: 'Estimert inntekt',
		RESPONSIBLE_TEAM: 'Team',
		BREACHED: 'Brudd',
		IN_COMPLIANCE: 'Samsvar',
		SERVICE_INCIDENTS: 'Tjenestesak',
		STACK_TRACE: 'Stack Trace',
		CRITICAL: 'Kritisk',
		CAUSE: '\u00c5rsak',
		OTHERS: 'Andre',
		WARNINGS: 'Advarsel',
		CREATE_DOCUMENT: 'Opprett dokument',
		EDIT_DOCUMENT: 'Rediger dokument',
		ECONOMY: 'Tjeneste \u00f8konomi',
		TIME: 'Tid',
		TIME_FROM: 'Tid fra',
		TIME_TO: 'Tid til',
		DURATION: 'Varighet',
		SERVICE_TIME: 'Tjeneste tid',
		CURRENT_TIME: 'Gjeldende tid',
		CURRENT_STATE: 'N\u00e5v\u00e6rende tilstand',
		STATUS: 'Status',
		MESSAGE: 'Melding',
		MODEL_ERROR: 'Modellfeil',
		MODEL_LOCKED: 'Modellen er l\u00e5st',
		NOTE: 'Notat',
		SERVICE_LOG: 'Tjenestelogg',
		LOG: 'Logg',
		SYSTEM_ERROR: 'Systemmelding',
		MESSAGE_TEXT: 'Meldingstekst',
		MESSAGE_TOKEN: 'Meldingsymbol',
		DETAIL_TYPE: 'Type',
		EVENT_TYPE: 'Hendelse',
		ICON: 'Ikon',
		TYPE: 'Type',
		SIZE: 'St\u00f8rrelse',
		SLA_NO_TEAM: '< Team >',
		SET_TEAM: 'Klikk for \u00E5 velge et team',
		COMPLIANCE_GOAL: 'Samsvarsm\u00e5l',
		ACTUAL_COMPLIANCE: 'Faktisk samsvar',
		CREATE_SLA: 'Lag SLA',
		DELETE_SLA: 'Slett tjenesteavtale',
		SLA: 'Tjenesteavtale',
		SLA_FULL: 'Tjenesteavtale',
		PERIOD: 'Periode',
		SLA_ALL_DAY: 'Full dag',
		SLA_EXCLUDE: 'Ekskluder',
		SLA_CUSTOM: 'Egendefinert',
		SERVICE_EDITOR: 'Editor',
		ADD_NOTE: 'Legg til loggmelding',
		SHOW_ALL_LOGS: 'Vis alle ',
		SHOW_UNREAD_LOGS: 'Vis ubekreftede',
		ACKNOWLEDGED: 'Bekreftet',
		ACKNOWLEDGED_DATE: 'Bekreftet dato',
		ACKNOWLEDGED_BY: 'Bekreftet av',
		DELETE_SERVICE: 'Slett tjeneste',
		SERVICE_LOG_DETAILS: 'Tjenestelogg detaljer',
		INCIDENT: 'Sak',
		AGENT_INVALID: 'Agenten er ugyldig',
		QUALIFIER_INVALID: 'Tjenestepunkt er ugyldig',
		QUALIFIER_BREACHED: 'Tjenestepunkt er brutt',
		STATE_CHANGE: 'Tilstands endring',
		CREATE_MAINTENANCE_PERIOD: 'Opprett vedlikeholdsperiode',
		UPDATE_MAINTENANCE_PERIOD: 'Oppdater vedlikeholdsperiode',
		DELETE_MAINTENANCE_PERIOD: 'Slett vedlikeholdsperiode',
		OPERATING_EDITOR_TITLE: 'Velg driftsperiode for denne tjeneste',
		OPERATING_PERIOD_FOR_SERVICE: 'Velg driftstid for denne tjeneste',
		IN_MAINTENANCE: 'Vedlikehold',
		ADD_EXCLUDE_PERIODS: 'Legg til ekskluderingsperiode for tjenesten\'s SLA',
		ADD_EXCLUDE_PERIODS_TOOLTIP: 'Eksluderingsperioden er automatisk satt av vedlikeholdsperiode i tjeneste:  ',
		OPERATIONAL: 'Operativ',
		NON_OPERATIONAL: 'Ikke operativ',
		OUTSIDE_OPERATION_PERIOD: 'Utenfor driftstid',
		OPERATIONAL_STATE: 'Aktiv',
		MODE: 'Modus',
		QUALIFIER: 'Tjenestepunkt',
		COVERAGE: 'Dekning',
		NO_QUALIFIERS: 'Ingen punkter',
		NO_SERVICE_QUALIFIERS_SELECTED: 'Ingen tjenestepunkt er valgt',
		ACKNOWLEDGE_ALL_SERVICE_LOGS: 'Bekreft alle tjenestelogger',
		TEAM_VALIDATE: 'Velg et team',
		RECALCULATE_SERVICES: 'Rekalkulerer tjeneste ${i} av ${length}. Det kan ta litt tid.',
		RECALCULATE_SERVICE: 'Rekalkulerer tjeneste ${i} av ${length}. Det kan ta litt tid.',
		RECALCULATE_DONE: '${i} av ${length} tjenester ble rekalkulert',
		LINKED_SERVICE_NA: 'N/A - Tjenestelink',
		PERIOD_IS_EXCLUDED: 'Utenfor SLA nåværende kalkuleringsperiode.',
		SERVICE_PERIOD_IS_EXCLUDED: 'Utenfor tjenestens nåværende kalkuleringsperiode.',

		sharedQualifiers: {
			DELETE_POPUP_TITLE: 'Tjenestepunkt slett/avkoble',
			CONVERT_POPUP_TITLE: 'Tjenestepunkt konverter',
			SHARE_POPUP_TITLE: 'Tjenestepunkt del',
			EDIT_SHARED_QUALIFIER: 'Dette tjenestepunktet er delt. Endring vil bli reflektert i alle plasser hvor tjenestepunktet er i bruk. Er du sikker p\u00E5 at du vil fortsette?',
			READ_ONLY_SHARED_QUALIFIER: 'For \u00E5 redigere det nye delte tjenestepunktet m\u00E5 du oppdatere tjenestemodellen og g\u00E5 inn i designer igjen'
		},
		messages: {
			SHARED_SERVICE: 'N\u00e5r tjenesten er delt kan den velges som linkobjekt i en underkonto sin tjenestemodell',
			SERVICE_CREATE_SELECTING: 'Lag en ny tjeneste ved \u00e5 velge en tjenestemodell og tilknyttete tjenesteavtale',
			SERVICE_MODEL_READ_NO_RIGHTS: 'Du har ikke rettigheter til \u00e5 se tjenestemodellen',
			SERVICE_MODEL_CREATE_NO_RIGHTS: 'Du har ikke rettigheter til \u00e5 opprette en tjenestemodell',
			ENTER_NAME: 'Sett inn navn p\u00e5 tjeneste',
			ENTER_TEAM: 'Sett inn navn p\u00e5 team',
			ENTER_MESSAGE: 'Sett inn melding',
			SERVICE_TOP_MESSAGE_UPDATE: 'Fyll inn f\u00f8lgende skjema for \u00e5 oppdatere tjeneste innstillinger',
			SERVICE_REMOVE_CONFIRMATION: 'Er du sikker p\u00e5 at du vil slette denne tjenesten?',
			SERVICES_REMOVE_CONFIRMATION: 'Vil du slette disse tjenestene?',
			SERVICE_SUCCESS_DELETED: 'Tjenesten er slettet',
			SERVICE_SUCCESS_CREATED: 'Tjenesten er opprettet',
			SERVICE_SUCCESS_UPDATED: 'tjenesten er oppdatert',
			DOCUMENT_SHOW: 'Dokument vises i tjeneste detalj',
			DOCUMENT_HIDE: 'Dokumentene ble skjult i tjeneste detalj',
			DOCUMENT_TOP_MESSAGE_UPDATE: 'Fyll inn f\u00f8lgende skjema for \u00e5 oppdatere dokument instillinger',
			DOCUMENT_CREATE_NEW: 'Opprett et nytt dokument ved \u00e5 laste opp en fil',
			DOCUMENT_TEMP_SAVED: 'Dokumentet er lagret',
			DOCUMENT_TEMP_NOT_SAVED: 'Opplastingen feilet',
			DOCUMENT_SUCCESS_CREATED: 'Dokument er opprettet',
			DOCUMENT_SUCCESS_UPDATED: 'Dokument er oppdatert',
			DOCUMENT_SUCCESS_DELETED: 'Dokument er slettet',
			DOCUMENT_DELETE_CONFIRMATION: 'Vil du slette dokumentet',
			SLA_TOP_MESSAGE: 'Vennligst fyll ut f\u00f8lgende skjema for \u00e5 opprette en <strong>Ny tjenesteavtale</strong>',
			SLA_TOP_MESSAGE_UPDATE: 'Vennligst fyll ut f\u00f8lgende skjema for \u00e5 oppdatere tjenesteavtalen',
			FILL_IN_VALUES: 'Fyll inn alle p\u00e5krevde felter',
			CREATE_NEW_SLA: 'Opprett ny tjenesteavtale',
			CREATE_SLA: 'Lag en tjenesteavtale',
			DEPLOY_NEW_SLA: 'Distribuer ny tjenesteavtale',
			EDIT_SLA: 'Rediger tjenesteavtale',
			SLA_REMOVE_CONFIRM: 'Er du sikker p\u00e5 at du vil slette tjenesteavtalen',
			SLA_DELETE_CONFIRM: 'Vil du slette tjenesteavtalen?',
			SLA_DELETED: 'Tjenesteavtale er slettet',
			SLA_CREATED: 'Tjenesteavtale er opprettet',
			SLA_UPDATED: 'Tjenesteavtale er oppdatert',
			SLA_NOT_DEFINED: 'Ingen tjenesteavtaler definert.',
			SERVICE_ASSETS_DISPLAYED: 'Tjenesteressurser vil bli tilgjengelig n\u00e5r en tjenestemodell er opprettet',
			SERVICE_MODEL_CREATE_PERMISIONS: 'Du har ikke rettigheter til \u00e5 opprette en tjenestemodell.',
			SERVICE_MODEL_UPDATE_PERMISIONS: 'Du har ikke rettigheter til \u00e5 oppdatere en tjenestemodell.',
			SERVICE_SAVE_FIRST: 'Du m\u00e5 lagre tjenesten f\u00f8rst.',
			SERVICE_MODEL_DELETE_CONFIRMATION: 'Vil du slette tjenestemodellen?',
			SERVICE_MODEL_DELETE_CONFIRMATION_DETAIL: 'Tilh\u00F8rende SLA vil bli slettet',
			SERVICE_MODEL_SUCCESS_DELETED: 'Tjenestemodell er slettet.',
			SERVICE_MODEL_RECALCULATE_CONFIRMATION_DETAIL: 'Velg tiden du vil rekalkulere tjenesten fra. Dette vil endre tjeneste og element tilstand hvis det er lagt til eller slettet tjenestepunkt.',
			SLA_CREATE_PERMISIONS: 'Du har ikke rettigheter til \u00e5 opprette en tjenesteavtale',
			SLA_UPDATE_PERMISIONS: 'Du har ikke rettigheter til \u00e5 oppdatere en tjenesteavtale',
			SLA_DELETE_PERMISIONS: 'Du har ikke rettigheter til \u00e5 slette en tjenesteavtale',
			CLICK_TO_EDIT: 'Klikk her for \u00e5 endre',
			NOT_ESTIMATED: 'Ikke estimert',
			UPLOAD_FILE: 'Last opp fil',
			PLEASE_SELECT_SERVICE: 'Velg en tjeneste',
			MAINTENANCES_SUCCESS_DELETED: 'Vedlikeholdsperiode(r) er slettet.',
			MAINTENANCES_SUCCESS_ENDED: 'Vedlikeholdsperiode(r) er avsluttet.',
			MAINTENANCES_NOT_HISTORIC_SUCCESS_ENDED: 'N\u00e5v\u00e6rende og framtidige vedlikeholdsperioder er avsluttet',
			MAINTENANCE_SUCCESS_SAVED: 'Vedlikeholdsperioden er lagret.',
			MAINTENANCE_PERIOD_IN_THE_PAST: 'Vedlikeholdsperioden kan ikke v\u00e6re historisk.',
			NO_SLA: 'Tjenesten har ingen tjenesteavtaler',
			NO_SERVICE_ASSETS: 'Tjenesten har ingen ressurser',
			NO_SERVICE_INCIDENTS: 'Det finnes ingen tjenestesaker',
			NO_SERVICE_DOCUMENTS: 'Tjenesten har ingen dokumenter',
			NO_SERVICE_LOGS: 'Tjenesten har ingen tjenestelogg elementer',
			DELETE_PERIOD_CONFIRMATION: 'Er du sikker p\u00e5 at du vil slette denne perioden?',
			DELETE_PERIOD: 'Slett perioden',
			ENTER_LOG_ENTRY: 'Skriv inn loggmeldingen her...',
			NO_RECORDS: "Ingen tjenester er opprettet for denne kontoen. Klikk her eller i menyen for \u{E5} opprette en tjeneste.",
			NO_DOCUMENTS: "Det er ikke noe dokument i denne tjenesten. Klikk her eller i menyen for \u{E5} legge til et dokument.",
			NO_MAINTENANCE_PERIODS: "Det er ingen vedlikeholdsperiode for denne tjenesten. Klikk her eller i menyen for \u{E5} lage en vedlikeholdsperiode.",
			NO_OVERRIDES: "Det er ingen tilstandsoverstyringer for denne tjenesten. Klikk her eller i menyen for \u{E5} lage en tilstandsoverstyring.",
			NO_LOGS: "Det er ingen tjenestelogg helse\u{E5}rsaker for gitt intervall.",
			NO_SERVICE_REVISIONS: "Det er ingen revisjoner for denne tjenesten. En revisjon blir opprett om du oppdaterer tjenestemodellen"
		}
	},
	// service states
	selectiveservices: {
		tip: {
			SHOW_DESCRIPTION: 'Vis tjeneste beskrivelse i widgeten',
			SHOW_INFORMATION: 'Vis informasjon',
			SHOW_ACCOUNT_NAME: 'Vis kontonavn i widgeten',
			ALL_ACCOUNTS: 'Alle kontoer p\u00e5 n\u00e5v\u00e6rende tid er lagt til',
			ALL_SERVICES: 'Alle tjenester p\u00e5 n\u00e5v\u00e6rende tid er lagt til',
			DYNAMIC_SERVICES: 'Dynamisk tjeneste: Ny/slettet tjeneste fra valgt konto(er) blir automatisk lagt til/fjernet fra widget'
		}
	},
	//monitors
	monitor: {
		description: 'Valgfritt, gi en fornuftig beskrivelse av hva monitoren skal gj\u00F8re',
		FOLDER_SELECTION_TITLE: 'Mappe',
		CREATE_NEW_FORMAT: 'Opprett et nytt format',
		LINE: 'Linje',
		system: {
			REMOVE_DISK_ASSET: 'Fjern disk fra ressurs',
			REMOVE_DISK_ASSET_MSG: 'Disken ble fjernet',
			DISK_AVAILABLE: 'Disk tilgjengelig',
			DISK_LATENCY: 'Disk gjennomsnitt ventetid',
			DISK_READ_LATENCY: 'Disk les ventetid',
			DISK_WRITE_LATENCY: 'Disk skriv ventetid',
			DISK_QUEUE_LENGTH: 'Disk gjennomsnitt k\u00f8lengde',
			DISK_READ_QUEUE_LENGTH: 'Disk les k\u00f8lengde',
			DISK_WRITE_QUEUE_LENGTH: 'Disk skriv k\u00f8lengde',
			DISK_USAGE_MB: 'Diskbruk MB',
			DISK_USAGE_PERCENT: 'Diskbruk %',
			DISK_FREE_MB: 'Tilgjengelig MB',
			DISK_FREE_PERCENT: 'Tilgjengelig %',
			DISK_INODES_FREE: 'Inodes ledig',
			DISK_INODES_USED: 'Inodes brukt',
			DISK_INODES_USED_PERCENT: 'Inodes brukt %',
			DISK_INODES_FREE_PERCENT: 'Inodes ledig %',
			DISK_SIZE: 'Diskst\u00f8rrelse'
		}
	},
	// designer
	designer: {
		SHOW_TEST_RESULTS: 'Vis testresultat',
		CREATE_NEW_ASSET: 'Opprett ny ressurs',
		CREATE_NEW_AUTHENTICATION: 'Opprett ny autentisering',
		CREATE_NEW_ASSET_NOT_AVAILABLE: 'Opprett ny ressurs er ikke tilgjengelig i oppdatering modus',
		CREATE_NEW_TARGET_NOT_AVAILABLE: 'Opprett ny ressursm\u00e5l er ikke tilgjengelig i oppdatering modus',
		ADD_MULTIPLE_ASSSETS: 'Legg til mange ressurser',
		CREATE_NEW_TARGET: 'Opprett nytt ressursm\u00E5l',
		COPY_ELEMENT: 'Kopier element',
		PASTE_ELEMENT: 'Lim inn element',
		CREATE_TEMPLATE: 'Opprett mal',
		CREATE_MULTIPLE_QUALIFIERS: 'Opprett multiple tjenestepunkt',
		MULTIPLE_QUALIFIERS: 'Multiple tjenestepunkt',
		DEFAULT: 'Standard',
		RULESET: 'Regelsett',
		STATE_EXPRESSION_RULESET: 'Tilstand regelsett',
		WEIGHTED: 'Vektet',
		COUNTER: 'Teller',
		TOP_LEFT: '\u00d8verst til venstre',
		NEW_SERVICE_MODEL: 'Ny tjenestemodell',
		SERVICES: 'Tjenester',
		SERVICE_MODEL_TEMPLATE: 'Tjenestemodell mal',
		SERVICE_MODEL_TEMPLATES: 'Tjenestemodell maler',
		SERVICE_ELEMENT_TEMPLATES: 'Tjenesteelement maler',
		SERVICE_QUALIFIER_TEMPLATES: 'Tjenestepunkt maler',
		SERVICE_QUALIFIER_WIZARD: 'tjenestepunkt veiviser',
		SERVICE_ELEMENT_RULE: 'Tjenesteelement regel',
		SERVICE_ELEMENT: 'Tjenesteelement',
		SERVICE_ELEMENT_BLANK: 'Opprett tomt element',
		SERVICE_ELEMENT_TEMPLATE: 'Opprett element fra mal',
		NEW_SERVICE_ELEMENT: 'Nytt tjenesteelement',
		SERVICE_MODEL_LINK: 'Tjenestemodell link',
		SERVICE_QUALIFIERS: 'Tjenestepunkter',
		SHARED_QUALIFIERS: 'Delte tjenestepunkt',
		SHARED_SERVICE_QUALIFIERS: 'Delte tjenestepunkt',
		SHARED_SERVICE_QUALIFIER: 'Delt tjenestepunkt',
		SERVICE_QUALIFIER: 'Tjenestepunkt',
		SERVICE_QUALIFIERS_SETTINGS_GENERAL: 'Generelt',
		SERVICE_QUALIFIERS_SETTINGS_MEASURING_AGENT: 'M\u00E5leagent',
		SERVICE_QUALIFIERS_SETTINGS_MEASURED_TARGET: 'M\u00E5lepunkt',
		SERVICE_QUALIFIERS_SETTINGS_TIME: 'Tid',
		SERVICE_QUALIFIERS_SETTINGS_BREACH_THRESHOLD: 'Bruddterskel',
		SERVICE_QUALIFIERS_SETTINGS_PARAMETERS: 'Parametere',
		SERVICE_QUALIFIERS_SETTINGS_AUTHENTICATION: 'Autentisering',
		SERVICE_QUALIFIERS_SETTINGS_WARNING_THRESHOLD_LINK: 'Klikk for \u00E5 legge til en terskelverdi for advarsel',
		SERVICE_QUALIFIERS_SETTINGS_WARNING_THRESHOLD: 'Advarselterskel',
		SERVICE_QUALIFIERS_SETTINGS_WARNING_CONDITION: 'Advarsel tilstand',
		SERVICE_QUALIFIERS_SETTINGS_WARNING_OPERATOR: 'Advarseloperator',
		SAVE_AS_TEMPLATE: 'Lagre som mal',
		SAVE_MODEL_AS_TEMPLATE: 'Lagre tjenestemodell som mal',
		SAVE_ELEMENTS_AS_TEMPLATE: 'Lagre tjenesteelement som mal',
		SAVE_SM_AS_TEMPLATE: 'Lagre tjenestemodell som mal',
		SAVE_SQ_AS_TEMPLATE: 'Tjenestepunktmal',
		SAVE_SE_AS_TEMPLATE: 'Lagre tjenesteelement som mal',
		DRAG_LOCK: 'L\u00e5s elementer',
		CLICK_HERE: 'Klikk her',
		OPERATION_AND_GROUPING: 'Gruppering',
		SELECT_AGENT: 'Velg agent... ',
		SELECT_ASSET_TARGET: 'Velg ressursm\u00e5l...',
		SELECT_ASSET: 'Velg ressurs... ',
		SELECT_ASSET_GROUP: 'Velg ressursgruppe...',
		SELECT_AUTHENTICATION: 'Velg autentisering',
		CHANGE_TYPE: 'Endre type',
		AVAILABLE: 'Tilgjengelig',
		STATIC_THRESHOLD: 'Statisk terskelverdi',
		DYNAMIC_THRESHOLD: 'Dynamisk terskelverdi',
		RANGE_THRESHOLD: 'Omr\u00e5de terskelverdi',
		DELTA_THRESHOLD: 'Delta terskel',
		PERCENTILE_THRESHOLD: 'Prosent terskelverdi',
		NONE: 'Ingen',
		OPERATOR: 'Operator',
		OPERAND: 'Operand',
		THRESHOLD: 'Terskelverdi',
		FACTOR: 'Faktor ',
		INTERVAL: 'Intervall ',
		ALGORITHM: 'Algoritme ',
		CUSTOM_TEXT: 'Egendefinert tekst',
		INCLUDE_CHILDREN: 'Inkluder underliggende tjenesteelementer',
		INCLUDE_BACKGROUND: 'Inkluder bakgrunn',
		INCLUDE_ICONS: 'Inkluder ikoner',
		INCLUDE_SERVICE_QUALIFIERS: 'Inkluder tjenestepunkter',
		INCLUDE_STATE_EXPRESSION_RULES: 'inkluder regelsett',
		ADD_CATEGORY: 'Legg til rolle',
		ACCESS: 'Tilgang',
		BREACHES: 'Brudd',
		CONSECUTIVE_BREACHES: 'Sammenhengende brudd',
		DATA_REPRESENTATION: 'Data representasjon',
		MINIMUM_AVAILABLE_SERVICE_ELEMENTS: 'Minimum antall tjenesteelementer med TILGJENGELIG /gr\u00F8nn) tilstand for at hovedelementet skal v\u00e6re TILGJENGELIG',
		MINIMUM_AVAILABLE_SERVICE_QUALIFIERS: 'Minimum antall tjenestepunkter med TILGJENGELIG (gr\u00F8nn) tilstand for at tjenesteelementet skal v\u00e6re TILGJENGELIG',
		PERCENTAGE_AVAILABLE_SERVICE_ELEMENTS: 'N\u00F8dvendig prosentdel tjenesteelementer med TILGJENGELIG /gr\u00F8nn) tilstand for at hovedelementet skal v\u00e6re TILGJENGELIG',
		PERCENTAGE_AVAILABLE_SERVICE_QUALIFIERS: 'N\u00F8dvendig prosentdel tjenestepunkter med TILGJENGELIG (gr\u00F8nn) tilstand for at tjenesteelementet skal v\u00e6re TILGJENGELIG',
		AGGREGATE_FROM: 'Aggreger tilstand fra',
		SERVICE_ELEMENTS: 'Tjenesteelement',
		COVERAGE_WARNING: 'Vis advarsel',
		COVERAGE_WARNING_MOUSEOVER: 'Vis advarsel: Dette gjelder bare hvis aggreger tilstand er fra tjenestepunkt og regelsett er angitt. Hvis satt vil elementet\n' +
			'bli satt til advarsel (oransje) om 1:m tjenestepunkt brytes, men p\u00E5 grunn av regelsett ikke medf\u00F8rer et brudd p\u00E5 tjeneste elementet',
		EXIT_DIRTY_CONFIRMATION: 'Du har ulagret innhold i designer, du vil miste endringen hvis du klikker Lukk. Trykk avbryt og lagre endringen hvis du vil beholde endringene.',
		INPUT_NAME: 'Skriv inn navn...',
		messages: {
			SQ_ASSETNEW_BUTTON: 'Opprett ny ressurs',
			SQ_ASSETNEWTARGET_BUTTON: 'Opprett nytt ressursm\u00E5l for valgt ressurs',
			SQ_ASSETNEWAUTH_BUTTON: 'Opprett autentisering',
			MULTIPLE_ASSETS_CLOSE: 'Lukkes vinduet vil alle valgte ressurser fjernes fra denne tjenestepunkt konfigurasjonen',
			MULTIPLE_ASSETS_ENABLED: 'Klikk for \u00E5 opprette tjenestepunkt for multiple ressurser',
			MULTIPLE_ASSETS_DISABLED: 'Du m\u00E5 velge et ressursm\u00E5l ovenfor for \u00E5 kunne opprette multiple tjenestepunkt. Ressursm\u00E5let blir brukt for \u00E5 kj\u00F8re test validering.',
			MULTIPLE_ASSETS_GRID: 'Velg 1:m ressurser/ressursm\u00E5l fra listen nedenfor for \u00E5 inkludere dem i tjenestepunktkonfigurasjonen. For hvert valgte ressursm\u00E5l opprettes et identisk tjenestepunkt.',
			LOCKED_MODEL: 'Tjenestemodell er l\u00e5st av annen bruker. Endringene dine kan ikke lagres, klikk OK for og g\u00e5 til tjeneste detaljer.',
			TEMPLATE_ENTER_NAME: 'Sett inn navn p\u00e5 mal',
			TEMPLATE_CATEGORY_ENTER_NAME: 'sett inn navn p\u00e5 kategori',
			INPUT_SERVICE_MODEL_NAME: 'Navn for tjenestemodell: ',
			INPUT_SERVICE_MODEL_TEMPLATE_NAME: 'Navn for tjenestemodell mal:',
			INPUT_SERVICE_ELEMENT_NAME: 'Navn for tjenesteelement:',
			INPUT_CONNECTION_NAME: 'Navn for kobling',
			INPUT_NAME_PLACEHOLDER: 'Sett inn navn',
			LOADING_SERVICE_MODEL: 'Laster tjenestemodell',
			WARNING_CYCLE_DETECTED: 'Koblingen er ikke tillatt',
			WARNING_DUPLICATE_CONNECTION: 'Det er allerede en kobling mellom tjenesteelementene',
			WARNING_SERVICE_MODEL_PATH_TO_ROOT: 'Det er tjeneste element som ikke har kobling til rotelement',
			WARNING_SERVICE_MODEL_CHANGES: 'Det er gjort endringer p\u00e5 tjenestemodellen. Husk \u00e5 lagre endringer',
			WARNING_SERVICE_MODEL_NAME: 'Du m\u00e5 sette et navn for tjenestemodellen.',
			INVALID_STATE_EXPRESSION_RULE: 'Den egendefinerte reglen for det valgte element er ugyldig',
			LEAVE_PAGE_CONFIRMATION: 'Er du sikker p\u00e5 at du vil forlate siden? Du vil miste alle endringer som ikke er lagret.',
			STATE_AGGREGATED_CONFIRMATION: 'Vil du endre tilstand til aggregert element? Alle tjenestepunkter og regler for tjenesteelementet vil bli slettet.',
			STATE_NON_AGGREGATED_CONFIRMATION: 'Vil du endre tilstand til ikke-aggregert element. Alle regler vil bli slettet.',
			WARNING_SERVICE_ELEMENT_AGGREGATED: 'Tjenesteelementet er aggregert. Det er ikke tillatt med tjenestepunkt p\u00e5 dette tjenesteelementet.',
			WARNING_CLEAR_AGGREGATE: 'Dette vil fjerne aggregeringen og reglesettet vil bli nullstilt',
			WRONG_PARAMETER_ORDER: 'Ugyldig regel. Feil rekkef\u00F8lge p\u00e5 parameterene',
			UNEVEN_CLOSIG_BRACKETS: 'Ugyldig regel. Paranteser er ikke riktig satt',
			SERVICE_MODEL_NOT_FOUND: 'Tjenestemodell ble ikke funnet.',
			QUALIFIER_TEMPLATE_SAVED: 'Tjenestepunkt mal er lagret',
			NO_TEMPLATES_DEFINED: 'Det er ingen maler. Lag tjenestemodell fra tom mal.',
			QUALIFIER_TEMPLATE_ENTER_CATEGORY: 'Velg kategori',
			SELECT_PARENT_CATEGORY: 'Velg hovedkategori',
			TEMPLATE_SAVED: 'Mal er lagret',
			TEMPLATE_WARNING: 'Tjenestepunktet er modifisert og må lagres før tjenestemal kan opprettes. Klikk OK for å lagre tjenestepunktet (og alle andre endringer som utført på tjenestemodellen) for \u00E5 fortsette. Klikk på Avbryt for å returnere ikke designer og ikke opprett mal.',
			INVALID_ASSET_TARGET: 'Ressursm\u00e5l mangler',
			INVALID_ASSET: 'Ressurs mangler',
			CHANGE_ACCOUNT_FOR_SQ: 'Du m\u00e5 endre konto for \u00e5 opprette tjenestepunkt',
			DELETED_SERVICEQUALIFIER: 'Tjenestepunkt er slettet',
			SHARED_SERVICEQUALIFIER: 'Tjenestepunkt er delt',
			ACCOUNT_CONTEXT_CHANGED: 'Konto kontekst er endret for og kunne redigere',
			CONVERTED_SERVICEQUALIFIER: 'Tjenestepunktet konvertert',
			UNLINKED_SERVICEQUALIFIER: 'Tjenestepunktet frakoblet',
			ROOT_NODE_CAN_NOT_BE_DELETED: 'Det er ikke mulig \u00e5 slette rot noden.',
			VALID_CONFIGURATION: 'Konfigurasjon er gyldig',
			INVALID_CONFIGURATION: 'Konfigurasjon er ikke gyldig',
			EXIT_DIRTY_CONFIRMATION: 'Du har ulagret innhold i tjenestemodell, du vil miste endringen hvis du klikker Lukk. Trykk avbryt og lagre endringen hvis du vil beholde endringene.',
			PROCEED_TO_EXIT: 'Du kan ha endringer som ikke er lagret i tjenestemodell. Er du sikker p\u00e5 at du vil fortsette?',
			SQ_REMOVED_CHECK_RULE: 'Sjekk at regel er korrekt. Et tjenestepunkt er fjernet fra den'
		},

		validation: {
			ROOT_CANT_BE_A_TARGET: 'Rotnoden kan ikke være m\u00E5l',
			NODE_CAN_HAVE_ONLY_1_INPUT: 'Element kan bare ha 1 inngang',
			SERVICE_LINK_CANT_BE_A_SOURCE: 'Tjenestekoblingen kan ikke ha subnoder',
			ELEMENT_SHOULD_HAVE_PATH_TO_ROOT: 'Element har ingen vei til roten',
			THERE_ARE_ERRORS: 'Det er feil i modellen.',
			THERE_IS_AN_INVALID_RULESET: 'Et element {0} inneholder ugyldig regelsett'
		},

		labels: {
			ICON_COLOR: 'ikonfarge',
			PROPERTIES: 'Egenskaper',
			DESCRIPTPON: 'Beskrivelse',
			CREATE_TEMPLATE: 'Mal',
			WINDOWS: 'Vindu',
			NEW: 'Ny',
			CREATE: 'Opprettelse',
			REVERT_DEFAULT: 'Tilbakestill vinduposisjon'
		},

		newElementsToolbar: {
			TITLE: 'Verkt\u00F8ylinje',
			TITLE_SELECTED: 'Verkt\u00F8ylinje {0}',
			BLANK_ELEMENT: 'Tomt element',
			TEMPLATE_ELEMENT: 'Malelement',
			LINK: 'Tjenestelink',
			QUALIFIER: 'Tjenestepunkt',
			SHARED_QUALIFIER: 'Delt tjenestepunkt',
		},

		qualifiersWindow: {
			OPEN: 'Tjeneste element',
			LIST_IS_EMPTY: 'Det er ingen Tjenestepunkt',
			OPEN_IN_A_SEPARATE_WINDOW: 'Åpne i eget vindu',
			TITLE_NO_SELECTION: 'Tjeneste element',
			TITLE_SELECTION: 'Tjeneste element {0}',
			CREATE_NEW: 'Opprett ny',
			ADD_SHARED: 'Legg til delt',
		},

		propertiesToolbar:{
			TITLE_NO_SELECTION: 'Egenskaper',
			TITLE_SELECTION: 'Egenskaper {0}'
		}
	},

	//dashboard
	dashboard: {
		EXIT_DIRTY_CONFIRMATION: 'Du har ulagret innhold i dashboardet. Du vil miste endringene om du klikker Lukk. klikk avbryt for å gå tilbake å lagre innstillingene om du vil beholde dem',
		SELECT_METRICS: 'Velg metrikk',
		SHOW_ADVANCED: 'Vis avansert',
		HIDE_ADVANCED: 'Skjul avansert'
	},

	// summary
	summary: {
		ACCOUNT_HEALTH: 'Kontohelse',
		NAME: 'Navn',
		HEALTH: 'Helse',
		SERVICE_STATE: 'Tilstand',
		SERVICE_STATES: 'Tjenestetilstander',
		SERVICE_QUALIFIER_STATE: 'Tjenestepunkt tilstand',
		SERVICE_HEALTH: 'Tjenestehelse',
		SERVICE_HEALTH_BAR: ' Tjenestehelse indikator',
		SERVICE_INCIDENTS: 'Tjenestesak',
		SLA_STATUS: 'SLA status',
		HEALTH_STATUS: 'Helse',
		NO_SLA_AVAILABLE: 'Ingen SLA',
		REMOVE_FILTER: 'Fjern filter',
		SET_AS_DEFAULT: 'Sett som standard',
		ASSIGN: 'Tildel',
		ASSIGN_IN_PROGRESS: 'Tildeling p\u00e5g\u00e5r',
		AGENTS_DOWN: 'agent nede',
		AGENTS_WARNING: 'agent har advarsel',
		ALLAGENTS_UP: 'Alle agenter er oppe',
		messages: {
			SELECT_ACCOUNTS: 'Velg konto',
			SELECT_FILTER: 'Velg filter...',
			NO_SERVICES: 'Ingen tjenester tilgjengelig',
			NO_ASSETS: 'Ingen ressurser tilgjengelig',
			NO_SERVICE_QUALIFIERS_STATES: 'Fant ingen tjenestepunkt tilstander',
			FILTER_REMOVE_CONFIRMATION: 'Vil du slette filteret?',
			INPUT_FILTER_NAME: 'Skriv inn filternavn'
		}
	},
	assethealth: {
		LAST_VALUE: 'Siste verdi',
		CREATE_GROUP: 'Opprett profil',
		MONITOR_TYPE: 'Monitortype',
		MONITOR_CLASS: 'Monitorklasse',
		SYSTEM: 'System',
		NAGIOS: 'Nagios',
		NIMSOFT: 'Nimsoft',
		EXTERNAL: 'Ekstern',
		PRTG: 'PRTG',
		VMWARE: 'VMware',
		VMM: 'VMM',
		HYPERV: 'Hyper-V',
		FILESEARCH: 'Filesearch',
		SNMP: 'SNMP',
		EATON_UPS: 'Eaton UPS',
		MODBUS: 'Modbus',
		SNMPTRAP: 'SNMP trap',
		MSCLUSTER: 'MS cluster',
		IBMSVC: 'IBM SVC',
		LOG: 'Logg',
		CONTAINER: 'Kontainer',
		XENSERVER: 'XenServer',
		REMOTEISERIES: 'Ekstern IBM iSeries',
		REMOTE_SYSTEM: 'Eksternt system',
		GROOVY: 'Groovy',
		GROOVY_LEGACY: 'Denne monitoren vil miste support i framtidig Ceeview versjoner. Bruk Skript monitor.',
		SQL_RESPONSE: 'SQL respons',
		EVENT_LOG: 'Tjenestelogg',
		LOGMONITOR: 'Logg monitor',
		SOLARWINDS: 'SolarWinds',
		MAILGTW: 'Epost mottak',
		WINEVENTS: 'Hendelselogg',
		WINSERVICE: 'Tjenester',
		SYSTEM_MONITOR: 'System monitor',
		PROCESS_MONITOR: 'Prosess monitor',
		SERVICE_MONITOR: 'Tjeneste monitor',
		NAGIOS_MONITOR: 'Nagios monitor',
		NIMSOFT_MONITOR: 'Nimsoft monitor',
		EXTERNAL_MONITOR: 'Ekstern monitor',
		EVENT_LOG_MONITOR: 'Hendelselogg monitor',
		PRTG_MONITOR: 'PRTG monitor',
		VMWARE_MONITOR: 'VMware monitor',
		HYPERV_MONITOR: 'Hyperv monitor',
		VMM_MONITOR: 'VMM monitor',
		MSCLUSTER_MONITOR: 'MScluster monitor',
		SNMP_MONITOR: 'SNMP monitor',
		XENSERVER_MONITOR: 'XenServer monitor',
		REMOTEISERIES_MONITOR: 'Ekstern IBM iSeries monitor',
		DATABASE_MONITOR: 'SQL respons monitor',
		RSM_MONITOR: 'RSM monitor',
		IBMSVC_MONITOR: ' IBM SVC monitor',
		MAILGATEWAY_MONITOR: 'Epost mottak',
		PROCESS: 'Prosess',
		PROCESSES: 'Prosesser',
		SERVICE: 'Tjeneste',
		SERVICES: 'Tjenester',
		SEVERITY: 'Alvorlighetsgrad',
		SUBSYSTEM: 'Delsystem',
		INSTANCE: 'Instans',
		CREATE_MONITOR: 'Opprett monitor',
		DETELE_MONITOR: 'Slett monitor',
		EDIT_MONITOR: 'Oppdater monitor',
		REASONS_LIST: 'Hendelse liste',
		STARTED: 'Kj\u00F8rer',
		REMOVE_SERVICE: 'Slett tjeneste(r)',
		MOVE_RIGHT: 'Legg til valgt monitor gruppe',
		MOVE_RIGHT_TO: 'Flytt til...',
		PENALTY: 'Reduksjon',
		EXPECTED_STATE: 'Forventet tilstand',
		BACK_TO_ASSET_SELECTION: 'Tilbake til ressurs',
		PRIORITY: 'Prioritet',
		IMPACT: 'Innvirkning',
		CONNECTIVITY: 'Tilkobling',
		CLICK_URL: 'Klikk her for URL',
		MONITOR_NAME: 'Monitornavn',
		AGENT_NAME: 'Agentnavn',
		AGENT_STATE: 'Agenttilstand',
		TARGET_NAME: 'M\u00e5lnavn',
		CLICK_TO_HIDE: 'Klikk for og skjule',
		SELECT_ASSET: 'Velg ressurs',
		FREE_SPACE: 'Ledig diskplass',
		USED_SPACE: 'Brukt diskplass',
		PHYSICAL_MEMORY: 'Fysisk minne',
		SYSTEM_HEALTH: 'System helse',
		MEMORY_USAGE: 'Brukt minne',
		CPU_TOTAL: 'Total CPU',
		RESET_HI: 'Tilbakestill helseindeks',
		RESET_HI_MOUSEOVER: 'Dette vil resette helse for alle årsåker for monitor',
		LAST_CHANGE: 'Siste endring',
		UPDATE_ASSET_GROUP: 'Oppdater ressursgruppe',
		OPERAND: 'Operand',
		EVENTLOG: 'Hendelselogg',
		PERFORMANCE_COUNTER: 'Ytelsesteller',
		TARGET_POINT: 'M\u00e5lpunkt',
		NO_MONITORS: 'Ingen monitorer',
		NO_LABELS: 'Ingen forklaring',
		IDENTIFIER_NAME: 'Identifikator navn',
		IDENTIFIER_INSTANCE: 'Identifikator instans',
		REASON_FILTER: 'Helse\u00c5rsaksfilter',
		CREATE_FILTER: 'Opprett filter',
		EXPECTED_VALUE: 'Forventet verdi',
		NEW_FRAGMENT: 'Nytt fragment',
		CREATE_FRAGMENT: 'Opprett monitor fragment',
		ACKNOWLEDGE_ASSET_REASON: 'Bekreft helse\u00e5rsak',
		SYSTEM_TYPE: 'Systemtype',
		EXTERNAL_ASSETS: 'Eksterne ressurser',
		NON_STATIC_GROUP_ONE: 'Denne ressursegrupper er av typen',
		NON_STATIC_GROUP_TWO: 'og kan ikke fjernes manuellt',
		EXTERNAL_TYPE: 'Ekstern type',
		LAST_UPDATE: 'Sist oppdatert',
		COMMAND_LINE: 'Kommandolinje',
		METRIC_MONITOR: 'Metrikk monitor',
		CONTAINER_MONITOR: 'Kontainer monitor',
		HISTORIC_MODE: 'Historisk visning',
		messages: {
			ASSETS_SUCCESS_ADDED_TO_GROUP: 'Ressurser lagt til gruppe',
			FILTER_SUCCESS_SAVED: 'Filter er opprettet',
			MONITOR_SUCCESS_CREATED: 'Monitor er opprettet',
			MONITOR_SUCCESS_DELETED: 'Monitor er slettet',
			FILTER_SUCCESS_DELETED: 'Filter er slettet',
			MONITOR_SUCCESS_UPDATED: 'Monitor er oppdatert',
			MONITOR_REMOVE_CONFIRMATION: 'Vil du slette monitor?',
			MONITORS_REMOVE_CONFIRMATION: 'Vil du slette monitor?',
			FILTER_REMOVE_CONFIRMATION: 'Vil du slette filter?',
			FILTERS_REMOVE_CONFIRMATION: 'Vil du slette filterene?',
			NON_DELETABLE_ASSET_MESSAGE: 'Dette er en agent ressurs og kan ikke slettes. Fjern agenten f\u00F8rst og pr\u00F8v igjen...',
			AGENT_CONFIG_SUCCESS_UPDATED: 'Agent konfigurasjon oppdatert',
			PLEASE_SELECT_ASSET: 'Velg en ressurs',
			PLEASE_COMPLETE_ONE_FIELD: 'Fyll inn felt',
			PLEASE_INPUT_MONITOR_NAME: 'Skriv inn monitornavn',
			PLEASE_INPUT_MONITOR_FRAGMENT_NAME: 'Skriv inn fragment navn',
			PLEASE_INPUT_TAGS: 'Velg etiketter',
			NO_REASONS: 'Alt er OK',
			NO_STATES: 'Det finnes ingen tilstander',
			AGENT_DOWN: 'Ingen kontakt med agent, klarte ikke å hente siste helse\u00e5rsaker',
			HEALTH_NO_DATA: 'Ingen helse data',
			MEMORY_NO_DATA: 'Ingen minnebruk data',
			CPU_NO_DATA: 'Ingen CPU data',
			DISK_NO_DATA: 'Ingen disk data',
			REASON_FOR_RESET: '\u00c5rsak til tilbakestilling',
			HI_SUCCES_RESET: 'Tilbakestilling av helseindeksen for valgt monitor er sendt til agenten',
			ENTER_GROUP_NAME: 'Sett inn navn p\u00e5 gruppe',
			GROUP_NAME_IN_USE: 'Sett inn navn p\u00e5 gruppe',
			SELECT_ASSETS: 'Velg alvorlighet:',
			RULE_EXISTS: 'Regelnavn er i bruk',
			NO_FILTERS: 'Det finnes ingen filtere opprettet.',
			FILTERS_ACCOUNT_SUBACCOUNT_TITLE: 'Hvis valgt s\u00e5 vil gjeldende konto og underkontoer bli kalkulert i filter profilen.',
			FILTERS_SUBACCOUNT_TITLE: 'Bare underkontoer for gjeldende konto vil bli kalkulert i filter profilen',
			FILTERS_CUSTOM_TITLE: 'Bare valgte kontoer vil bli kalkulert i filter profilen',
			SELECT_STATE: 'Velg tilstand',
			MONITOR_DELETE_INFO: 'Monitor ${name} m\u00e5 fjernes fra f\u00F8lgende elementer f\u00F8r den kan slettes',
			NO_KEY_TYPES: 'Fant ingen n\u00F8kkel',
			FILTER_MANDATORY: 'En dynamisk ressursgruppe tillatter ikke tomt filter. Sett inn filterkriterie'
		},
		monitor: {
			list: {
				ALL: 'Alle monitorer',
				CONNECTIVITY: 'Tilkobling',
				EVENTLOG: 'Hendelselogg',
				EXTERNAL: 'Ekstern',
				HYPERV: 'Hyper-V',
				IBM: 'IBM SVC',
				SOLARWINDS: 'SolarWinds',
				MAILGATEWAY: 'Epost mottak',
				MSCLUSTER: 'MS cluster',
				NIMSOFT: 'Nimsoft',
				SYSTEM: 'System',
				PROCESS: 'Prosess',
				SERVICE: 'Tjeneste',
				PRTG: 'PRTG',
				VMWARE: 'VMware',
				VMM: 'VMM',
				NAGIOS: 'Nagios',
				FILESEARCH: 'Filesearch',
				SNMP: 'SNMP',
				LOG: 'Logg',
				DUMMY: 'Dummy',
				EVENTS: 'Events',
				SNMPTRAP: 'Snmptrap',
				FILECHANGE: 'Filechange',
				AMAZON: 'Amazon',
				GOOGLECLOUD: 'Google cloud',
				GROOVY: 'Groovy',
				AZURE: 'Azure'
			}
		}
	},
	viewer: {
		SQ_CHART: 'Tjenestepunkt diagram',
		SE_SUMMARY: 'Tjenesteelement sammendrag',
		SERVICE_HISTORY: 'Tjenestehistorikk',
		METRICS: 'Metrikker',
		STATUS_LOGS: 'Statuslogg',
		SERVICE_QUALIFIERS: 'Tjenestepunkt',
		METRICS_PREVIEW: 'Metrikk',
		METRICS_DATA: 'Metrikk data',
		DATASOURCE: 'Datakilde',
		STATE_EXPRESSION_RULE: 'Tilstandsregel',
		RULE: 'Regel',
		SQ_EINFO: 'Tjenesteelement info',
		SQ_HISTORY: 'Tjenestehistorikk',
		SQ_METRICDATA: 'Metrikkdata',
		SQ_INFO: 'Tjenestepunkt info',
		REASONS_FOR: '\u{C5}rs\u{E5}ker for',
		SQ_TYPE: 'Tjenestepunkt type',
		SQ_NAME: 'Tjenestepunkt navn',
		FIXED_LAYOUT: 'Fast vinduposisjon',
		FIXED_LAYOUT_MOUSEOVER: 'Hvis dette er valgt, vil vi sette standardvinduene tilbake til utgangsposisjon. Hvis ikke valgt, beholdes nåværende posisjon for tjeneste element, history og logg vindu.',
		messages: {
			NO_SERVICE_QUALIFIERS: 'Tjenesteelement har ikke tjenestepunkt',
			GOTO_VIEWER: '\u00c5pne denne tjenesten i tjenestevisning',
			GOTO_SERVICEDETAILS: '\u00c5pne denne tjenesten i tjeneste detaljer',
			NOACCESS_VIEWER: 'Ingen tilgang til tjenestevisning',
			NOACCESS_SERVICEDETAILS: 'Ingen tilgang til tjenestedetaljer',
			NO_RAW_METRICS: 'Ingen metrikk data',
			METRIC_DATA_TITLE: 'Vis metrikk data for ${sqName}',
			METRIC_TITLE: 'Vis metrikk graf for ${sqName}',
			SERVICE_QUALIFIER_TITLE: 'Vis tjenestepunkt informasjon for ${sqName}',
			SQ_SERVICEHISTORY_TITLE: 'Vis tjenestehistorikk for ${sqName}',
			RULSET_TITLE: 'Vis regelsett for ${sqName}',
			SERVICE_DELETED: 'Tjeneste er slettet.'
		}
	},
	mscenter: {
		MESSAGE_CENTER: 'Meldingsenter',
		COMPOSE: 'Skriv ny',
		messages: {
			MESSAGE_SENT: 'Meldingen er sendt',
			SEND_TO_EMPTY: 'Sendt til kan ikke v\u00e6re tomt',
			NO_MESSAGES: 'Du har ingen uleste meldinger.',
			MESSAGE_CENTER_GO: 'G\u00e5 til meldingsenter',
			MESSAGE_REMOVE_CONFIRMATION: 'Vil du slette melding(ene)?',
			MESSAGE_SUCCESS_DELETED: 'Meldingen er slettet'
		}
	},
	kpi: {
		CREATE_KPI: 'Opprett',
		RULE_TYPE: 'Regeltype',
		KPI_ACCOUNTS: 'Kpi kontoer',
		KPI_METRICS: 'Kpi metrikker',
		REPORT_PERIOD: 'Rapport periode',
		SAMPLE_RATE: 'Samplingsrate',
		TREND: 'Trend',
		messages: {
			ACCOUNT_SUBACCOUNT_TITLE: 'Hvis valgt s\u00e5 vil gjeldende konto og underkontoer bli kalkulert i KPI profil.',
			KPI_REMOVE_CONFIRMATION: 'Er du sikker p\u00e5 at du vil slette KPI profilen',
			RULE_SUCCESS_DELETED: 'Reglen er slettet',
			NO_ACCOUNTS: 'Ingen kontoer er opprettet',
			NO_KPIS: 'Ingen KPI er opprettet',
			SUCCESS_DELETED: 'KPI profilen er slettet',
			SUBACCOUNT_TITLE: 'Bare underkonto for gjeldende konto vil bli kalkulert i KPI profilen',
			CUSTOM_TITLE: 'Bare valgte kontoer vil bli kalkulert i KPI profilen',
			SELECTED_ACCOUNTS_SUB: 'Alle underkontoer og gjeldende konto er valgt',
			SELECTED_SUBACCOUNTS: 'Alle underkontoer er valgt',
			SELECT_ACCOUNT: 'Du m\u00E5 legge til en konto'
		}
	},
	automation: {
		CREATE_AUTOMATION: 'Opprett automatisering',
		TRIGGER_TYPE: 'Triggertype',
		STATE_SERVICE_QUALIFIER: 'Tjenestepunkt tilstand',
		STATE_SERVICE_ELEMENT: 'Tjenestelement tilstand',
		HEALTH: 'Helse',
		TIMED: 'Tid',
		INCIDENT: 'Hendelse',
		PROFILE_SUCCESS_CREATED: 'Profil ble opprettet',
		PROFILE_SUCCESS_UPDATED: 'Profil ble oppdatert',
		POSTSCRIPT_TEST: 'Skript har ikke test mulighet',
		PROFILES_REMOVE_CONFIRMATION: 'Vil du slette profilene',
		PROFILE_REMOVE_CONFIRMATION: 'Vil du slette profilen',
		PROFILE_SUCCESS_DELETED: 'Profil er slettet',
		PROFILES_SUCCESS_DELETED: 'Profilene er slettet',
		RETURNED_DATA: 'Returnert data',
		NO_DATA: 'Ingen data ble returnert',
		NO_SERVICE_ELEMENTS: 'Ingen tjeneste element er valgt',
		ACTION_SCRIPT_TEST: 'For \u00E5 teste actionskriptet velg en agent og klikk test knappen',
		DATASOURCE_SCRIPT_TEST: 'For \u00E5 teste datakildeskript velg en agent og klikk test knappen',
		SUMMARY_RUN_TEXT: 'Klikk en rad i sammendragstabellen for \u00E5 vise returnert data',
		SCRIPT_TYPE: 'Skript: ',
		MESSAGE: 'Melding: ',
		DETAILS: 'Detaljer: ',
		TEST_RESULTS: 'Testresultat fra ',
		EXIT_DIRTY_CONFIRMATION: 'Du har ulagret innhold i profilen, du vil miste endringen hvis du klikker Lukk. Trykk avbryt og lagre endringen hvis du vil beholde endringene.',
		ACTION_SCRIPT_ACTIVE: 'Actionskript aktivt',
		DATASOURCE_SCRIPT_ACTIVE: 'Datakildeskript aktivt',
		POST_SCRIPT_ACTIVE: 'Postskript aktivt',
		SHARE_SCRIPT: 'Del skript',
		LINK_SCRIPT: 'Link skript',
		UNLINK_SCRIPT: 'Avlink skript',
		EDIT_SHARED_SCRIPT: 'Rediger delt skript',
		ENTER_NAME: 'Sett inn navn p\u00E5 skriptet',
		SHARED: 'Delt',
		SCRIPT: 'Skript',
		ACTION_SCRIPT: 'handlingsskript',
		DATASOURCE_SCRIPT: 'datakildeskript',
		RUN_DETAILS: 'Detaljer fra kj\u00f8ring',
		SHARED_TOOLTIP: 'Dette er et delt skript og kun lesbart. For \u00E5 redigere det m\u00E5 du klikke p\u00E5 “Rediger delt skript“ i menyen. Endringer vil gjelde all profiler hvor dette skriptet er brukt. For \u00E5 kun redigere dette skriptet klikk “Avlink script”. Det medf\u00F8rer at dette skriptet ikke er delt i andre profiler og kan redigeres for gjeldende profil.',
		FILTER_TYPE_PLACEHOLDER: 'Velg filtertype...',
		SELECT_OPTION: 'Velg innstillinger...',
		SELECT_INCIDENT_TYPE: 'Velg status...',
		SERVICE_STATE: 'Tjenestetilstand',
		NO_SERVICES: 'Ingen tjenester',
		FILTER_OPTIONS_HEALTH: 'Klikk for vise flere filtreringsmuligheter for valg av monitor',
		FILTER_OPTIONS_SQ: 'Klikk her for \u00E5 velge fler tjenestepunkt',
		FILTER_OPTIONS_SE: 'Klikk her for \u00E5 velge fler tjenesteelementer',
		FILTER_OPTIONS_SERVICE: 'Klikk her for \u00E5 velge fler tjenester',
		FILTER_OPTIONS_TAGS: 'Klikk her for \u00E5 endre utvalgstype',
		ANY: 'Alle'
	},
	rulegenerator: {
		REPEAT: 'Gjenta',
		EVERY: 'Hver',
		SECONDLY: 'Sekund',
		MINUTELY: 'Minutt',
		HOURLY: 'Time',
		DAILY: 'Daglig',
		WEEKLY: 'Ukentlig',
		MONTHLY: 'M\u00e5nedlig',
		YEARLY: 'Årlig',
		ON_DAY: 'P\u00e5 dag',
		RRULE: 'Gjentagelse',
		SECONDS: 'andre(r)',
		MINUTE: 'minutte(r)',
		HOUR: 'Time(r)',
		DAY: 'dag(er)',
		WEEK: 'Uke(r)',
		MONTH: 'm\u00e5ned(er)',
		YEAR: 'År',
		START: 'Starttid',
		END: 'Slutt',
		NEVER: 'Aldri',
		ON_DATE: 'P\u00E5 dato',
		ON_THE: 'P\u00E5 den',
		FIRST: 'F\u00F8rste',
		SECOND: 'Andre',
		THIRD: 'Tredje',
		FOURTH: 'Fjerde',
		LAST: 'Siste',
		WEEKDAY: 'Ukedag',
		WEEKEND_DAY: 'Helgedag',
		ON: 'P\u00E5',
		ERROR: 'Kan ikke vise denne RRULE, den kan være ugyldig eller en tilpasset RRULE. Klikk Oppdater for å gå tilbake til standard, eller Avbryt for å beholde den.',
		SCHEDULES_REMOVE_CONFIRMATION: 'Er du sikker p\u00E5 at du vil slette planene',
		SCHEDULE_REMOVE_CONFIRMATION: 'Er du sikker p\u00E5 at du vil slette planen',
		SCHEDULE_SUCCESS_DELETED: 'Plan er slettet',
		SCHEDULES_SUCCESS_DELETED: 'Planene er slettet'
	},
	months: {
		JANUARY: 'Jan',
		FEBRUARY: 'Feb',
		MARCH: 'Mar',
		APRIL: 'Apr',
		MAY: 'Mai',
		JUNE: 'Jun',
		JULY: 'Jul',
		AUGUST: 'Aug',
		SEPTEMBER: 'Sep',
		OCTOBER: 'Okt',
		NOVEMBER: 'Nov',
		DECEMBER: 'Des'
	},
	fullMonths: {
		JANUARY: 'Januar',
		FEBRUARY: 'Februar',
		MARCH: 'Mars',
		APRIL: 'April',
		MAY: 'Mai',
		JUNE: 'Juni',
		JULY: 'Juli',
		AUGUST: 'August',
		SEPTEMBER: 'September',
		OCTOBER: 'Oktober',
		NOVEMBER: 'November',
		DECEMBER: 'Desember'
	},
	integration: {
		PROFILES_REMOVE_CONFIRMATION: 'Vil du slette profilene',
		PROFILE_REMOVE_CONFIRMATION: 'Vil du slette profilen',
		PROFILE_SUCCESS_DELETED: 'Profil er slettet',
		PROFILES_SUCCESS_DELETED: 'Profilene er slettet'
	},
	incidents: {
		TIME_OF_FLAG: 'Bekreftet tid',
		CREATE_INCIDENT: 'Lag sak',
		DELETE_INCIDENT: 'Slett sak',
		MARK_AS_SEEN: 'Bekreft',
		MARK_ALL_AS_SEEN: 'Bekreft alle',
		ASSET_HISTORY: 'Ressurshistorikk',
		REASON_DETAILS: 'Helse\u00c5rsaksdetalj',
		SUBJECT: 'Emne',
		ASSIGNED_TO: 'Tildelt',
		OWNER: 'Eier',
		NUMBER: 'Nummer',
		REPORTED_BY: 'Rapportert av',
		CREATED: 'Opprettet',
		MODIFIED: 'Oppdatert',
		ACKNOWLEDGED_BY: 'Bekreftet av',
		CREATED_BY: 'Opprettet av',
		CREATE_COMMENT: 'Legg til kommentar',
		DELETE_COMMENT: 'Slett kommentar',
		EDIT_COMMENT: 'Oppdater kommentar',
		SELECT_STATUS: 'Endre status',
		ACTIVITY_BY_USER: 'Brukeraktivitet',
		CHANGE_STATUS: 'Endre status',
		CHANGE_INCIDENT_STATUS: 'Endre saksstatus',
		AFFECTED_SERVICES_TOOLTIP: 'Tjenestene som valgte ressurser er tilknyttet',
		CONTENT_TYPE: 'Innholdstype',
		PRIORITY_NAME: 'Prioritet',
		messages: {
			PLEASE_SELECT_ASSIGNED: 'Velg bruker for sak',
			PLEASE_SELECT_OWNER: 'Velg eier for sak',
			PLEASE_SELECT_REASON: 'Velg helse\u00e5rsak for sak',
			PLEASE_SELECT_STATUS: 'Velg status for sak',
			PLEASE_ENTER_SUBJECT: 'Skriv inn tittel for sak',
			SUCCESS_SAVED: 'Sak er opprettet',
			SUCCESS_UPDATED: 'Sak er oppdatert',
			SUCCESS_DELETED: 'Sak er slettet',
			NO_INCIDENTS: 'Ingen saker er opprettet',
			COMMENT_SUCCESS_CREATED: 'Kommentar er opprettet',
			COMMENT_SUCCESS_UPDATED: 'Kommentar er oppdatert',
			COMMENT_SUCCESS_DELETED: 'Kommentar er slettet',
			COMMENT_EMPTY: 'Kommentarfeltet er tomt. Skriv inn kommentar for denne saken.',
			INCIDENT_REMOVE_CONFIRMATION: 'Dette vil slette sak?',
			INCIDENTS_REMOVE_CONFIRMATION: 'Vil du slette sakene?',
			INVALID_INCIDENT: 'sak er ikke gyldig da tjeneste er slettet.',
			STATUS_CHANGED: 'Status er endret',
			PRIVATE_COMMENT_DISABLED: 'Eier (Rapportert av) kan ikke sette privat kommentar'
		}
	},
	grid: {
		FILTER_SELECT_VALUE: 'Velg verdi...',
		NO_RECORDS: 'Ingen data tilgjengelig.',
		filter: {
			SHOW_ITEMS: 'Vis elementer med verdi som: ',
			STARTS_WITH: 'Starter med',
			CONTAINS: 'Inneholder',
			EQ: 'Er lik',
			NEQ: 'Er ikke lik',
			GTE: 'St\u00F8rre enn eller lik',
			GT: 'St\u00F8rre enn',
			LTE: 'Mindre enn eller lik',
			LT: 'Mindre enn',
			IAE: 'Etter eller lik som',
			IA: 'Etter',
			IBE: 'Er f\u00F8r eller lik som',
			IB: 'Er f\u00F8r',
			SELECT_VIEW: 'Velg visning...',
			SEE_VIEW: 'Se visning',
			CREATE_VIEW: 'Lag visning',
			REMOVE_VIEW: 'Fjern visning',
			UPDATE_VIEW: 'Oppdater visning',
			CLEAR_VIEW: 'T\u00F8m visning',
			OPEN_VIEW: '\u00c5pne visning',
			FILTER_RESET: 'Fjern filter og sortering',
			FILTER_PREFERENCES: 'Filter preferanser',
			SHOW_HIDE_COLUMNS: 'Vis/skjul kolonne',
			GRID_COLUMNS: 'Tabell Kolonne',
			COLUMNS_SORTING: 'Kolonne sortering',
			ENTER_NEW_FILTER: 'Nytt filternavn',
			VIEW_UPDATED: 'Visning oppdatert',
			ISIN: 'Er med i',
			ISNOTIN: 'Er ikke med i'
		},
		events: {
			PAUSE: 'Pause hendelse',
			START: 'Start hendelse',
			LOAD: 'Last hendelse',
			UPDATE_INTERVAL: 'Oppdateringintervall'
		}
	},
	shortDateFormat: {
		dd_MM: 'dd.MM'
	},
	agents: {
		AUTO_UPDATE: 'Automatisk oppdatering',
		ALLOW_SUBAGENTS: 'Tillat underagent',
		ALLOW_DATA_COLLECTION: 'Tillat datainnsamling',
		UPDATE_AGENT: 'Oppdater agent',
		CONNECT_AGENT: 'Tilkoble ny agent',
		INITIATE_AGENT: 'Initier agent',
		RUNTIME_VERSION: 'Runtime versjon',
		LAST_STARTED: 'Sist startet',
		LAST_CONNECTED: 'Sist tilkoblet',
		CONSOLE_URL: 'Konsoll url',
		LOG_LEVEL: 'Loggniv\u00e5',
		THREAD: 'Tr\u00e5d',
		LOGGER: 'Kilde',
		CONNECTION_STATE: 'Tilkoblingsstatus',
		CONNECTION_STATUS: 'Tilkoblingsstatus',
		AGENT_VERSION: 'Agentversjon',
		RESTART: 'Varmstart',
		RESTART_CACHE: 'Kaldstart',
		QUEUE: 'K\u00F8',
		SHARED: 'Delte agenter',
		RUN_SCRIPT: 'Kj\u00F8r skript',
		UPLOAD_BUNDLES: 'Last opp',
		UPLOAD: 'Last opp',
		REPOSITORY: ' Pakkearkiv',
		INSTALL_BUNDLES: 'Installer pakker',
		UNINSTALL_BUNDLES: 'Avinstaller pakker',
		LICENSE_USAGE: 'Lisensbruk',
		NO_LICENSE_FOUND: 'Ingen lisens er innstallert p\u00e5 Ceeview instansen. Kontakt support.',
		DATA_REGISTRY: 'Dataregister',
		messages: {
			AGENTS_REQUEST: 'Melding sendt til agent. Systemagent og agenter som er nede blir ignorert.',
			CONNECTION_STATE_SHUTDOWN: 'Kontrollert stans',
			CONNECTION_STATUS_SHUTDOWN: 'Kontrollert avslutning',
			CONNECTION_STATUS_HOST_SHUTDOWN: 'Kontrollert vertsavslutning',
			CONNECTION_COLD_RESTART: 'Kaldstart',
			CONNECTION_WARM_RESTART: 'Varmstart',
			CONNECTION_UPDATE: 'Oppdater',
			CONNECTION_SAFE_MODE: 'Safe mode',
			CONNECTION_STATE_FAILURE: 'Tilkobling feil',
			CONNECTION_STATE_UP: 'Koblingen er oppe',
			DELETE_INFO: 'Agent ${name} m\u00e5 fjernes fra f\u00F8lgende elementer f\u00F8r den kan slettes',
			NO_QUEUES: 'Ingen k\u00F8er er tilgjengelig',
			LOG_SETUP_SAVED: 'Konfigurasjon er lagret',
			LOG_RESET_MESSAGE: 'Logg konfigurajon er tilbakestilt til standard instilling',
			RESET_DEFAULT_CONFIRMATION: 'Dette vil tilbakestille konfigurasjon for agent',
			INSTALL_BUNDLES_INFO: 'Pakkene vil bli lastet opp p\u00e5 valgte agenter',
			UNINSTALL_BUNDLES_INFO: 'Pakkene vil bli avinstallert p\u00e5 valgte agenter',
			INSTALL_BUNDLES_FOR_AGENT: 'Pakkene vil bli lastet opp p\u00e5: ',
			UNINSTALL_BUNDLES_FOR_AGENT: 'Pakkene vil bli avinstallert p\u00e5: ',
			BUNDLE_INSTALLED: 'Pakke ble installert',
			BUNDLES_INSTALLED: 'Pakkene ble installert',
			BUNDLE_UNINSTALLED: 'Pakke ble avinstallert',
			BUNDLES_UNINSTALLED: 'Pakkene ble avinstallert',
			BUNDLES_INSTALL_FAILED: 'Llarte ikke å laste opp til ressurs: ',
			BUNDLES_UNINSTALL_FAILED: 'Vi klarte ikke avinstaller på ressurs: ',
			BUNDLES_SUCCESSFULLY_UPLOADED: 'Pakkene ble lastet opp',
			BUNDLES_FAILED_UPLOADED: 'Klarte ikke laste opp pakker',
			BUNDLES_WERE_DELETED: 'Pakker ble slettet',
			BUNDLES_DELETE_FAILED: 'Pakker ble ikke slettet: ',
			JAR_FILE_TYPES: 'Bare filer med filtype .jar er tillatt',
			MAINTENANCES_NO_FUTURE_PERIODS: 'Bare framtidige vedlikeholdsperioder ble slettet. Aktiv eller historiske vedlikeholdsperiode blir ikke slettet',
			MAINTENANCES_SUCCESS_DELETED: 'Bare framtidige vedlikeholdsperioder er slettet',
			SELECT_PROTOCOL: 'Velg protokoll'
		}
	},
	profile: {
		DEFAULT_SERVICEBOARD: '- Ceeview hjem skjerm',
		DEFAULT_DATE_FORMAT: 'Standard dato format',
		INTERNATIONAL_STANDARD: 'Internasjonal standard (YYYY-MM-DD)',
		NORWEGIAN_SHORT: 'Norsk kortform (dd.MM.yy)',
		SWEDISH_SHORT: 'Svensk kortform (yyyy-mm-dd)',
		PASSWORD_STRENGTH_MESSAGE: 'Passordet m\u00e5 inneholde minst 8 bokstaver og tall',
		SERVICEBOARDS: '- Dashboard -'
	},
	slas: {
		NO_DOWNTIME: 'Ingen nedetid',
		NO_EXCLUSION: 'Ingen eksludering',
		RUNNING_247: 'Kj\u00F8rer 24/7',
		CREATED_BY: 'Opprettet av',
		SET_OPERATING_TIME: 'Sett driftstid',
		CREATE_EXCLUDE_PERIOD: 'Opprett ekskludering periode',
		ALL_PERIODS: 'Alle perioder',
		CURRENT_PERIOD: 'N\u00e5v\u00e6rende periode',
		DELETE_EXCLUDE_PERIOD: 'Slett ekskludering periode',
		DOWNTIME: 'N\u00e5v\u00e6rende nedetid',
		STATE_PERIOD: 'Tilstandsperiode',
		MAX_DOWNTIME: 'Total tillatt nedetid',
		OPERATING_EDITOR_TITLE: 'Opprett driftstid for SLA',
		DOWN_TIME: 'Nedetid',
		SERVICE_QUALIFIER: 'Tjenestepunkt',
		DOWN_BEFORE_BREACHED: 'Nedetid f\u00F8r brudd',
		HISTORIC_NOTES: 'Historiske notater',
		CURRENT_NOTES: 'N\u00e5v\u00e6rende notater',
		FUTURE_NOTES: 'Framtidige notater',
		messages: {
			INVALID_START_TIME: 'Ugyldig starttid',
			INVALID_END_TIME: 'Ugyldig slutttid',
			INVALID_PERIOD: 'Ugyldig periode',
			PERIOD_IN_USE: 'Perioden er i bruk',
			NO_SLAS: 'Det er ingen tilgjengelige tjenesteavtaler',
			SLA_REMOVE_CONFIRMATION: 'Vil du slette tjenesteavtale?',
			SLA_RECALCULATE_CONFIRMATION_DETAIL: 'Velg tid du vil beregne SLA på nytt fra. Eventuelle tilstandsendringer i tjenesten fra denne perioden vil bli brukt.',
			SLAS_REMOVE_CONFIRMATION: 'Vil du slette tjenesteavtalene?',
			EXCLUDE_DELETED: 'Eksluderingsperiod er slettet',
			NOTE_DELETED: 'Merknad er slettet',
			NO_STATE_CHANGES: 'Det er ingen tilstandsendringer',
			SLA_COMPLIANCE_HISTORY: 'SLA tjenestehistorikk'
		}
	},

	serviceDesigner: {
		TITLE: 'Tjeneste designer'
	},

	presentation: {
		messages: {
			REMOVE_CONFIRMATION_1: 'Er du sikker p\u00E5 at du vil fjerne presentasjonen?',
			REMOVE_CONFIRMATION_MANY: 'Er du sikker p\u00E5 at du vil fjerne presentasjonene?',
			DELETED: 'Tjenestepresentasjon slettet.'
		},

		designer: {
			DESIGNER: 'Designer',
			NAME_WATERMARK: 'Skriv inn navn...',
			DESCRIPTION_WATERMARK: 'Skriv inn beskrivelse...',
			BACKGROUND_IMAGE: 'Bakgrunnsbilde',
			DATASOURCE: "Datakilde",
			SET_AN_IMAGE: "Sett bilde",
			CLEAR_IMAGE: 'Slett bilde',
			EXIT_DIRTY_CONFIRMATION: 'Du har ulagret innhold i presentasjon, du vil miste endringen hvis du klikker Lukk. Trykk avbryt og lagre endringen hvis du vil beholde endringene.',
			AGGREGATED_ASSET: 'Aggregert HI',
			shapes: {
				ROOT: 'Tjeneste rot node',
				LINKED: 'Link',
				SERVICE_ELEMENT: 'Tjeneste element',
				HEALTH_INDEX: 'Helseindeks',
				STANDARD: 'Ceeview standard form',
				SERVICE_MODEL: 'Tjenestemodell'
			}
		},
	},
	administration: {
		CALENDAR_REMOVE_CONFIRMATION: 'Er du sikker p\u00e5 at du vil slette kalender',
		CALENDARS_REMOVE_CONFIRMATION: 'Er du sikker p\u00e5 at du vil slette kalenderene',
		EXTERNAL_ASSET_GROUP_TOOLTIP: 'Denne ressursen er EXTERNAL. Den kan ikke fjernes manuelt, men m\u{E5} fjernes via gruppen\'s monitoren.',
		DYNAMIC_ASSET_GROUP_TOOLTIP: 'Denne ressursen er DYNAMIC. Den kan ikke fjernes manuelt, men m\u{E5} fjernes via gruppen\'s filterinnstillinger.',
		CALENDARS_DELETED: 'Kalenderene er slettet',
		CALENDARS_DEPENDENCIES_MESSAGE: 'Kalenderen ${calendarName} m\u00E5 fjernes fra f\u00F8lgende elementer f\u00F8r den kan slettes.',
		CALENDARS_REMOVE_DEPENDENCIES: 'Fjern fra alle',
		DELETE_INFO: 'Autenisering ${name} må fjernes fra følgende elementer før den kan slettes.',
	},
	management: {
		LAST_COMMAND: 'Siste kommando',
		LAST_LOGIN: 'Siste innlogging',
		ERROR_CODE: 'Feilkode',
		ACKNOWLEDGE: 'Bekreft',
		ACKNOWLEDGE_ALL: 'Bekreft alle',
		SHOW_ALL: 'Vis alle',
		SHOW_NOT_ACKNOWLEDGED: 'Ikke vis bekreftede',
		SUPPRESSION_COUNT: 'Antall undertrykt',
		SUPPRESSION_TIME: 'Undertrykk tid',
		DELETE_MESSAGEBUS: 'Slett ugyldig melding',
		PEEK_MESSAGEBUS: 'Se melding',
		START_MESSAGEBUS: 'Start meldingsk\u00f8',
		CLEAR_MESSAGEBUS: 'Slett meldingsk\u00f8',
		DELETE_STATUS: 'F\u00f8rste melding er slettet i valgt meldingsk\u00f8',
		PEEK_STATUS: 'Se melding kommando sent til meldingsk\u00f8',
		START_STATUS: 'Start kommando sendt til meldingsk\u00f8',
		CLEAR_STATUS: 'Nullstill kommando sendt til meldingsk\u00f8',
		PEEK_NO_DATA: 'Ingen data i meldingsk\u00f8',
		messages: {
			LOG_REMOVE_CONFIRMATION: ' Vil du slette systemmelding?',
			LOGS_REMOVE_CONFIRMATION: 'Vil du slette systemmeldingene?',
			LOGS_DELETED: 'Systemmeldingene er slettet.',
			END_MAINTENANCE_SUCCEEDED: 'Vedlikeholdsperiode avsluttet',
			GENERIC_PUBLIC_USERS: 'Dette er en felles \u00E5pen bruker og har ingen detaljer.'
		}
	},
	file_type: {
		IMAGE: 'Bilde',
		PDF: 'Pdf',
		TXT: 'Tekst'
	},
	regionalSchedulerDays: {
		MONDAY: 1,
		TUESDAY: 2,
		WEDNESDAY: 3,
		THURSDAY: 4,
		FRIDAY: 5,
		SATURDAY: 6,
		SUNDAY: 7
	},
	favorites: {
		SERVICE: 'Tjeneste',
		ASSET: 'Ressurs',
		ASSET_GROUP: 'Ressursgruppe',
		REASON: 'Helse\u00c5rsak',
		FILTER: 'Filter',
		MONITOR: 'Monitor',
		// Used for old items in favorites
		// To be removed in time
		ASSET_SUMMARY: 'Ressurs',
		GROUP_SUMMARY: 'Ressursgruppe',
		REASONS: 'Helse\u00c5rsaker',
		FILTERS: 'Filter',
		MONITORS: 'Monitor',
		//
		CONNECTIVITY: 'Tilkobling',
		EVENTLOG: 'Hendelselogg',
		EXTERNAL: 'Ekstern',
		HYPERV: 'Hyper-V',
		IBMSVC: 'IBM SVC',
		MSCLUSTER: 'MSCluster',
		MAILGATEWAY: 'Epost mottak',
		SOLARWINDS: 'SolarWinds',
		NAGIOS: 'Nagios',
		NIMSOFT: 'Nimsoft',
		PRTG: 'PRTG',
		FILESEARCH: 'Filesearch',
		SNMP: 'SNMP',
		VMM: 'VMM',
		VMWARE: 'VMware',
		SERVICE_MONITOR: 'Tjeneste monitor',
		SYSTEM_MONITOR: 'System monitor',
		LOG: 'Logg',
		PROCESS: 'Prosess',
		SLA: 'SLA',
		SERVICE_BOARD: 'Tjenestetavle',
		KPI: 'KPI',
		INCIDENT: 'Sak',
		REPORT: 'Rapport',
		AUTOMATION: 'Automatisering',
		INTEGRATION: 'Integrasjon',
		ACCOUNTS: 'Kontoer',
		ASSETS: 'Ressurser',
		ASSET_GROUPS: 'Ressursgrupper',
		AUTHENTICATIONS: 'Autentisering',
		SERVICE_MODEL_TEMPLATE: 'Tjenestemodell maler',
		SERVICE_ELEMENT_TEMPLATE: 'Tjenesteelement maler',
		SERVICE_QUALIFIER_TEMPLATE: 'Tjenestepunkt maler',
		TEAMS: 'Team',
		USERS: 'Brukere',
		ROLES: 'Roller',
		SERVER: 'Server',
		REPOSITORY: 'Pakkearkiv',
		MODULES: 'Komponenter',
		AUDIT: 'Revisjon',
		ACTIVE_USERS: 'Aktive brukere',
		SYSTEM_MESSAGES: 'Systemmeldinger',
		LAST_CHANGES: 'Siste endringer',
		AGENTS: 'Agenter',
		APPLICATION: 'Applikasjon',
		DATAREGISTRY: 'Data register',
		COST: 'Kost',
		CALENDAR: 'Kalender',
		DASHBOARD: 'Dashboard',
		MODULE: 'Modul'
	},
	periodMultiToggle: {
		HOUR: 'Time',
		DAY: '24t',
		WEEK: '7d',
		MONTH: '30d'
	},
	momentsLangCode: 'nb',
	imageUploader: {
		DELETE_CONFIRMATION: 'Bildet vil bli fjernet fra hvert sted det brukes. Er du sikker p\u00E5 at du vil slette den?'
	}
};

lang.targetType = {
	ACCOUNT_CHILDREN: lang.ACCOUNT_SUBACCOUNT,
	CHILDREN: lang.SUBACCOUNT,
	SELECTED: lang.CUSTOM
};

export default lang;
