import React, {useEffect, useState} from "react"
import {translator} from "core";
import {Button, TextArea, FormEntry, NumericTextBox} from "controls/react/form";
import {Window, Section, Toolbar, ToolbarItemPosition, FormLayout} from 'controls/react/layout';
import CustomNotification from 'controls/customNotification';
import Utils from 'tools/utils';
import {ManagementApi} from "areas/management/api";

import './conversionWindow.less';
import {apiFetch} from "framework/api";
import {validateConvertion} from "./dataSources/MetricSettingsApi";

const b = require('b_').with('conversion-window')

let i = translator({
  "Formula": {
    "en": "Formula",
    "no": "Formel"
  },
  "Decimals": {
    "en": "Decimals",
    "no": "Desimal"
  },
  "Valid formula": {
    "en": "Formula is valid",
    "no": "Formelen er gyldig"
  },
  "Valid formula output": {
    "en": "Formula is valid. Output is: ",
    "no": "Formelen er gyldig. resultatet er : "
  },
  "Not valid formula": {
    "en": "Formula is not valid",
    "no": "Formelen er ikke gyldig"
  },
  "Formula placeholder": {
    "en": "Expressions must use 'metric' to represent metric value. Example: metric*100.",
    "no": "Uttrykket må bruke 'metric' for å representere metrikkverdi. Eksempel: metric*100."
  },
  "Conversion formula": {
    "en": "Conversion formula",
    "no": "Konverteringsformel"
  },
  "Formula tooltip": {
    "en": "Formula editor support normal operators (+-/*%^) and functions (cos, sin, log). Check https://www.objecthunter.net/exp4j/#Built-in_functions for more examples",
    "no": "Formel editor støtter normale operatorer (+ - / *% ^) og funksjoner (cos, sin, log). Se https://www.objecthunter.net/exp4j/#Built-in_functions for flere eksempler"
  },
  "Validate": {
    "no": "Validering"
  }
});

export class ConversionWindow extends React.PureComponent {
	constructor(props) {
		super(props);
		let formula;
		if (props.formula) {
			formula = props.formula.trim();
		} else {
			formula = '';
		}
		this.state = {
			formula: props.formula ? props.formula.trim() : '',
			decimals: parseInt(props.decimals) || 2
		};
	}

	render() {
		return (
			<div>
				<Window height={220}
						width={400}
						modal
						top={this.props.offset.top + 30}
						left={this.props.offset.left + 5}
						title={i('Conversion formula')}
						ref={window => this.window = window}
						onResize={this.onResize}
						onClose={this.props.onClose}>
					<div className="notification_container" current-conversion-id={this.props.metricId}></div>
					<Section>
						<FormLayout>
							<MetricConversionForm formula={this.state.formula}
												  metricId={this.props.metricId}
												  decimals={this.state.decimals}
												  onFormulaChange={this.onFormulaChange}
												  onDecimalsChange={this.onDecimalsChange}
												  onValidate={this.onValidate} />
						</FormLayout>
					</Section>
					<Toolbar containerClass="conversion_actions">
						<Button title={'Update'}
								primary={true}
								onClick={() => this.onUpdate()} />
						<Button title={i('Cancel')}
								primary={false}
								onClick={() => this.props.onClose()} />
					</Toolbar>
				</Window>
			</div>
		)
	}

	async componentDidMount() {
		setTimeout(() => {
			$('.c-textarea').focus();
		}, 50);
		this.fetchLastValue();
		this.actionNotification = new CustomNotification({
			appendToElement: '.notification_container',
		});
	}

	async fetchLastValue() {
		let lastInfoResult = await ManagementApi.getQualifiersLastValue([{metricId: this.props.metricId}]);
		let lastValueData = lastInfoResult[0];
		if (lastValueData && lastValueData.found && lastValueData.hasMetrics) {
			this.lastValue = lastValueData.data[0].v;
		}
	}

	onFormulaChange = (value) => {
		this.setState({
			formula: value
		});
	}

	onDecimalsChange = (value) => {
		this.setState({
			decimals: value
		});
	}

	onValidate = async () => {
		const result = await validateMetricConversion(this.state.formula, this.state.decimals, this.lastValue);
		this.actionNotification.setOptions(result).show();
	}

	onUpdate = () => {
		let formula = this.state.formula.trim();
		let decimals = this.state.decimals;
		this.props.onUpdate(formula, decimals);
	}

	onResize() {
		let newHeight = this.wrapper.height() - 50;
		let content = $('.notification_container').next('.section');
		content.css('height', newHeight);
		let textAreaRows = parseInt((newHeight - 100) / 20);
		content.find('textarea').attr('rows', textAreaRows);
	}
}

export const MetricConversionForm = (props) => {
	const [placeholder, setPlaceholder] = useState(i('Formula placeholder'));

	useEffect(() => {
		setPlaceholder([i('Formula placeholder'), props.additionalPlaceholder].filter(x => x?.length).join(' '));
	}, [props.additionalPlaceholder]);

	return <>
			<FormEntry vertical label={i('Formula')}>
				<span className="glyphicons question-sign conversion_formula_tooltip" title={i("Formula tooltip")}></span>
				<Button title={i('Validate')}
						  className={b('validate-button')}
						  primary={true}
						  disabled={!props.formula?.length || props.readOnly || props.disableFields}
						  onClick={() => props.onValidate?.()} />
				<TextArea value={props.formula}
						  placeholder={placeholder}
						  onChange={props.onFormulaChange}
						  readOnly={props.readOnly || props.disableFields} />
			</FormEntry>
			<FormEntry label={i('Decimals')}>
				<NumericTextBox value={props.decimals}
								spinners={true}
								onChange={props.onDecimalsChange}
								onSpin={props.onDecimalsChange}
								enabled={!props.disableFields}
				/>
			</FormEntry>
		</>
}

export const validateMetricConversion = async (formula, decimals, lastValue) => {
	const response = await apiFetch(validateConvertion(formula, lastValue));
	const result = {};
	if (!response.success) {
		result.status = 'error';
		result.message = i('Not valid formula');
		return result;
	}
	result.status = 'success';
	if (!response.data) {
		result.message = i('Valid formula');
		return result;
	}
	result.message = i('Valid formula output') + Utils.truncateDecimals(response.data, decimals);
	return result;
}
