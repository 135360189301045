import React from "react";
import {makeObservable, observable} from "mobx"

import { ActionsManager } from "./actionsManager/actionsManager"
import {Designer as DesignerLegacy} from 'controls/designer/designer'
import {DesignerConfig} from "controls/designer/designerConfig"
import { Settings } from "./settings"
import {MobxManager} from "framework/mobx-integration"

export let DesignerContext = React.createContext<DesignerStore>(null);

export class ToolbarFeature{
	store: DesignerStore;

	enabled: boolean = false;
	items: string;

	constructor(store: DesignerStore) {
		this.store = store;

		this.items = store.config.toolbar ?? "";
	}
}

export class DesignerStore {
	legacyDesigner: DesignerLegacy;

	config: DesignerConfig;

	toolbar: ToolbarFeature;

	scale = 100;
	settings: Settings;

	am: ActionsManager;

	featuresLoaded: boolean = false;

	resetWindowsPositionsTrigger: string;

	mobx = new MobxManager()

	get graph() {
		return this.legacyDesigner.graph;
	}

	get editor() {
		return this.legacyDesigner.editor;
	}

	constructor(designer: DesignerLegacy) {
		this.legacyDesigner = designer
		this.config = designer.config

		this.am = new ActionsManager(this)
		this.toolbar = new ToolbarFeature(this)

		makeObservable(this, {
			//toolbar: observable,
			scale: observable,
			featuresLoaded: observable,
			settings: observable,
			resetWindowsPositionsTrigger: observable
		})
	}

	init() {
		this.mobx.reaction(() => this.scale, () => {
			if (this.scale / 100 != this.graph.view.scale) {
				//this.graph.zoom( (this.scale/100)/this.graph.view.scale)
				this.graph.view.setScale(this.scale / 100)
			}
		})

		this.graph.view.addListener('scale', () => {
			if (this.scale != this.graph.view.scale * 100) {
				this.scale = Math.round(this.graph.view.scale * 100)
			}
		})
	}

	destroy() {
		this.mobx.destroy()
	}
}
