import './mainCalendar.less'

import React from 'react'
import moment from 'moment'
import {DeleteOutlined, ClockCircleOutlined} from '@ant-design/icons'
import {RRule} from 'rrule'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import rrulePlugin from '@fullcalendar/rrule'
import ICAL from 'ical.js'
import icalendar from 'icalendar'

import {CalendarEventForm} from '../calendarEventForm'
import ModalWindow from 'controls/modalWindow'
import CalendarsAPI from "./api"
import {Utils} from 'tools/utils'
import DeleteRecurringEventWindow from './deleteRecurringEventWindow'
import {Cookies} from 'core';
import {apiFetch} from 'framework/api'
import {getUser, UserDataLite} from '../UsersApi'
import {convertTimezone} from 'tools/dateTimeUtils';
import {newGuid} from 'tools/guid'
import {cloneDeep, groupBy} from 'lodash'


const i = require('core/localization/localization').translator({
  "Recurring event": {
    "no": "Gjentatt hendelse",
    "en": "Recurring event"
  },
  "Create event": {
    "no": "Opprett hendelse",
    "en": "Create event"
  },
  "Delete recurring event": {
    "no": "Slett gjentakende aktivitet",
    "en": "Delete recurring event"
  }
});

const b = require('b_').with('calendar-event')

export class CalendarEntry {
	start
	end
	rruleObject
	exdate
	timezone
	color
	extendedProps
	id
	organizer
	created
	category
	lastModified
	tillTheEndDay

	constructor() {
		//makeAutoObservable(this)
	}

	get duration() {
		if(this.rruleObject == null)
			return null

		return this.end.getTime() - this.start.getTime();
	}

	get rrule(){
		if(this.rruleObject == null)
			return null

		let until = null
		if(this.rruleObject.until){
			until = moment(this.rruleObject.until)
				.tz(moment.tz.guess())
				.format("YYYY-MM-DD HH:mm:ss")
		}

		return {
			...this.rruleObject,
			until,
			dtstart: this.start.toISOString()
		}
	}
}

const eventTimeFormat = {
	hour: '2-digit',
	minute: '2-digit',
	meridiem: false,
	hour12: false
}

const slotLabelFormat = {
	hour: 'numeric',
	minute: '2-digit',
	meridiem: false,
	hour12: false
}

const plusIconStyle = {
	"position": "absolute",
	"marginLeft": "150px",
	"marginTop": "3px",
	"fontSize" : "25px",
	"cursor" : "pointer"
};

const availableColors = ['#57e395', '#3a94bf', '#eb54d8', '#bac93c', '#c98a3c', '#3cc99b', '#b65fed', '#5fedcc'];

export class MainCalendar extends React.Component {
	calendar = null
	calendarComponentRef = React.createRef();
	state = {
		calendarEvents: [],
		calendar: null,
		dataFirstLoaded: true,
		showDeleteRecurringEventWindow: false
	}

	displayedYears = [];
	loadedYears = []
	loadedYearsData = []
	colors = new Map();

	get disableDateFrom() {
		if (!this.props.disableDateFrom) {
			return undefined;
		}
		if (typeof this.props.disableDateFrom == 'function' && !!this.props.disableDateFrom()) {
			const result = new Date(this.props.disableDateFrom().getTime());
			result.setSeconds(0);
			result.setMilliseconds(0);
			return result;
		}
		if (!this._disableDateFrom && typeof this.props.disableDateFrom != 'function') {
			this._disableDateFrom = new Date(this.props.disableDateFrom.getTime());
			this._disableDateFrom.setSeconds(0);
			this._disableDateFrom.setMilliseconds(0);
		}
		return this._disableDateFrom;
	}


	get disableDateTo() {
		if (!this.props.disableDateTo) {
			return undefined;
		}
		if (typeof this.props.disableDateTo == 'function' && !!this.props.disableDateTo()) {
			const result = new Date(this.props.disableDateTo().getTime());
			result.setSeconds(0);
			result.setMilliseconds(0);
			return result;
		}
		if (!this._disableDateTo && typeof this.props.disableDateTo != 'function') {
			this._disableDateTo = new Date(this.props.disableDateTo.getTime());
			this._disableDateTo.setSeconds(0);
			this._disableDateTo.setMilliseconds(0);
		}
		return this._disableDateTo;
	}

	_disableDateFrom;
	_disableDateTo;

	get readOnly(){
		return this.props.config.calendarType === 'holiday' || this.props.readOnlyView
	}

	render() {
		let height = this.props.height;
		let calendarSection = $('.section').last();
		if (calendarSection && calendarSection.height() > 0) {
			height = calendarSection.height() - 70;
		}

		return (
			<div>
				{this.props.config.calendarType === 'generic'
					&& <span className="glyphicons plus-sign" style={plusIconStyle} title={i('Create event')}
					         onClick={this.handlePlusClick}></span>
				}
				<div>
					<FullCalendar
						headerToolbar={{
							start: 'prev,next today',
							center: 'title',
							end: 'dayGridMonth,timeGridWeek,timeGridDay listYear'
						}}
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
						eventDisplay={"block"}
						ref={this.calendarComponentRef}
						weekends={true}
						events={this.state.calendarEvents}
						dateClick={this.handleDateClick}
						eventClick={this.handleEventClick}
						editable={this.props.config.calendarType === 'generic'}
						height={height || 700}
						eventResizableFromStart={true}
						displayEventTime={this.props.config.calendarType === 'generic'}
						eventTimeFormat={eventTimeFormat}
						slotLabelFormat={slotLabelFormat}
						eventDrop={this.onEventDrop}
						eventContent={this.onEventContent}
						eventAllow={this.isDropAllowed}
						datesSet={this.onMonthChanged}
						firstDay={1}
						buttonText={{
							today: lang.TODAY,
							month: lang.MONTH,
							week: lang.WEEK,
							day: lang.DAY,
							list: lang.LIST
						}}
						locale={lang.momentsLangCode}
						timeZone={this.props.config.timeZone}
					/>
				</div>
				{this.state.showDeleteRecurringEventWindow &&
					<DeleteRecurringEventWindow
						onDeleteEvent={() => this.deleteEvent(this.state.eventIdToDelete)}
						onDeleteOccurence={this.deleteEventOccurence}
						onClose={this.closeDeleteRecurringEventWindow}/>
				}
			</div>
		)
	}

	onMonthChanged = (payload) => {
		if (this.props.config.calendarType != 'generic') {
			this.loadYearData([payload.start.getFullYear(), payload.end.getFullYear()]);
		}
	}

	onEventContent = (arg) => {
		const isGlobal = arg.event.extendedProps?.categories?.endsWith('_GLOBAL');
		if (arg.timeText.startsWith('24')) {
			arg.timeText = `00:${arg.timeText.split(':')[1]}`;
		}
		const start = moment(convertTimezone(arg.event.start, moment.tz.guess(), 'UTC'));
		const end = moment(convertTimezone(arg.event.end, moment.tz.guess(), 'UTC'));
		const title = isGlobal && arg.event.extendedProps?.name
			? `Global: ${start.format('HHmm')}:${end.format('HHmm')} ${arg.event.extendedProps?.name}`
			: '';
		return <div className={b()} title={title}>
			<div className={b('time')}>{arg.timeText}</div>
			<div className={b('title')}>{arg.event.title}</div>
			{arg.event.extendedProps.rruleObject &&
				<div className={b('reccurent-event-icon')} title={i('Recurring event')}>
					<ClockCircleOutlined />
				</div>
			}
			{!this.readOnly && !isGlobal && this.allowDelete(arg.event) &&
				<div className={b('remove-event-button') + " _do-not-trigger-editing"} onClick={(e) => this.deleteEventClicked(e, arg.event)}>
					<DeleteOutlined />
				</div>
			}
		</div>
	}

	componentDidMount() {
		if (this.props.config.calendarType == 'generic') {
			let calendarData = this.props.config.icalendar;
			this.setCalendarEvents(calendarData);
		}
	}

	componentDidUpdate(prev){
		if(prev.selectedDate != this.props.selectedDate){
			this.calendarComponentRef.current.getApi().gotoDate(this.props.selectedDate);
		}
		if(prev.config.icalendar != this.props.config.icalendar && this.props.config.calendarType == 'generic') {
			this.setState({
				dataFirstLoaded: true
			}, () => {
				let calendarData = this.props.config.icalendar;
				this.setCalendarEvents(calendarData);
			});
		}
	}

	dateTimeToDate = (dateTime) => {
		const date = new Date(dateTime.getTime());
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		return date;
	}

	setCalendarEvents(data) {
		if (!data) {
			this.updateCalendarEvents([]);
			return;
		}

		if (!Array.isArray(data)) {
			data = [data];
		}
		let vEvents = data.reduce((result, current) => {
			let comp = new ICAL.Component(ICAL.parse(current))
			return [...result, ...comp.getAllSubcomponents('vevent')];
		}, [])

		let calendarEvents = [];
		for (let vEvent of vEvents) {
			let calendarEntry = new CalendarEntry()
			calendarEvents.push(calendarEntry);

			let dtStartEntry = vEvent.jCal[1].find(x => x[0] == 'dtstart')
			calendarEntry.timezone = dtStartEntry[1].tzid ?? this.props.config.timeZone ?? moment.tz.guess();

			for (let property of vEvent.getAllProperties()) {
				if (property.name == 'dtstamp')
					continue

				let value = property.jCal[3]

				if (property.type == 'date-time') {
					if (property.name == 'exdate') {
						value = property.jCal.slice(3).map(x => convertDateToUserTimezone(x).toISOString());
					} else if (property.name == 'created' || property.name == 'last-modified') {
						value = convertDateToUserTimezone(property.jCal.slice(3)[0]).toISOString();
					} else {
						value = convertTimezone(value, 'UTC', moment.tz.guess());
					}
				}

				if (property.type == 'date') {
					value = new Date(moment(value).format('YYYY-MM-DDTHH:mm:ss'));
				}

				if (property.jCal[0] == 'rrule') {
					if (value.byday) {
						value.byweekday = value.byday

						//if only one day is in the list then parser returns is as string
						if(!Array.isArray(value.byweekday)){
							value.byweekday = [value.byweekday]
						}
						delete value.byday
					}
				}

				if (property.name == 'extendedprops' && !!value) {
					value = JSON.parse(value);
				}

				calendarEntry[convertName(property.name)] = value
			}

			const eventIsAllDay = calendarEntry.start.getHours() === 0
				&& calendarEntry.start.getMinutes() === 0
				&& calendarEntry.start.getSeconds() === 0 &&
				(calendarEntry.end.getTime() - calendarEntry.start.getTime()) % 86400000 === 0;
			calendarEntry.tillTheEndDay = moment(calendarEntry.end).isSame(calendarEntry.start, 'day') && moment(calendarEntry.end).utc().format('HHmm') == '0000';
			if (calendarEntry.categories?.endsWith('_GLOBAL')) {
				calendarEntry.allDay = calendarEntry.categories.startsWith('HOLIDAY_') || eventIsAllDay;
				calendarEntry.color = this.getColor(calendarEntry.categories);
				calendarEntry.isHoliday = calendarEntry.categories.startsWith('HOLIDAY_') || calendarEntry.id.indexOf('@ceeview.com') !== -1;
			} else if (calendarEntry.categories === 'HOLIDAY') {
				calendarEntry.allDay = true;
				calendarEntry.color = '#ff3333';
				calendarEntry.isHoliday = true;
			} else if (eventIsAllDay) {
				if (calendarEntry.id.indexOf('@ceeview.com') !== -1) {
					//only way to differentiate holidays from all day events before 2.10
					calendarEntry.color = '#ff3333';
					calendarEntry.isHoliday = true;
				}
				calendarEntry.allDay = true;
			}
		}
		this.updateCalendarEvents(calendarEvents)
	}

	getColor = (category) => {
		let color = this.colors.get(category);
		if (color) {
			return color;
		}

		let newColor = availableColors.find(x => !Array.from(this.colors.values()).includes(x));
		if (newColor) {
			this.colors.set(category, newColor);
			return newColor;
		}
		newColor = availableColors[Math.floor(Math.random() * availableColors.length)];
		this.colors.set(category, newColor);
		return newColor;
	}

	generateICalendar = () => {
		let calendar = new icalendar.iCalendar();

		for(let calendarEntry of this.state.calendarEvents) {
			let event = new icalendar.VEvent(calendarEntry.id);
			calendar.addComponent(event);
			if (calendarEntry.categories == 'HOLIDAY') {
				const addHolidayDate = (name, date) => {
					let prop = new icalendar.CalendarProperty(name, moment(date).format('YYYYMMDD'), {VALUE: `DATE`});
					prop.type = 'TEXT';
					event.setProperty(prop);
				}
				addHolidayDate('DTSTART', calendarEntry.start);
				addHolidayDate('DTEND', calendarEntry.end);
			} else {
				setDateProperty(event, 'DTSTART', calendarEntry.start, calendarEntry.timezone)
				setDateProperty(event, 'DTEND', calendarEntry.end, calendarEntry.timezone)
			}

			event.setSummary(calendarEntry.title);

			let rruleString = getRRuleString(calendarEntry.rruleObject);

			if (rruleString) {
				event.setProperty('RRULE', rruleString)
			}

			if(calendarEntry.exdate){
				event.setProperty('EXDATE', calendarEntry.exdate
					.map(x => moment(x).tz(moment.tz.guess(), true).utc().format()))
			}

			if (calendarEntry.color) {
				event.setProperty('COLOR', calendarEntry.color);
			}

			if (calendarEntry.categories) {
				event.setProperty('CATEGORIES', calendarEntry.categories);
			}
			if (calendarEntry.created) {
				event.setProperty('CREATED', moment(calendarEntry.created).tz(moment.tz.guess(), true).utc().format());
			}
			if(calendarEntry.lastModified){
				event.setProperty('LAST-MODIFIED', moment(calendarEntry.lastModified).tz(moment.tz.guess(), true).utc().format());
			}

			if (calendarEntry.organizer) {
				event.setProperty('ORGANIZER', calendarEntry.organizer);
			}

			event.setProperty('EXTENDEDPROPS', JSON.stringify(calendarEntry.extendedProps));
		}

		return calendar.toString().replaceAll('mailto:', '');
	}

	updateCalendarEvents(events){
		const firstChange = this.state.dataFirstLoaded
		this.setState({
			calendarEvents: events,
			dataFirstLoaded: false,
			showDeleteRecurringEventWindow: false,
			eventIdToDelete: null,
			eventOccurenceDateToDelete: null
		}, () => {
			if (!firstChange) {
				this.props.icalendarChanged(this.generateICalendar());
			}
		});
	}

	async loadYearData(years) {
		years = Object.keys(groupBy(years, x => x));
		if (years.every(x => this.displayedYears.includes(x))) {
			return;
		}
		for(let year of years.filter(x => this.loadedYears.indexOf(x) === -1)) {
			const result = await CalendarsAPI.generateHolidayCalendar(this.props.config.id, year);
			if (result.success) {
				this.loadedYears.push(year);
				this.loadedYearsData.push({
					year: year,
					data: result.data
				});
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}
		const data = this.loadedYearsData
			.filter(x => years.includes(x.year))
			.map(x => x.data);
		this.setCalendarEvents(data);
	}

	handlePlusClick = () => {
		this.handleDateClick( {
			date: new Date()
		})
	}

	handleDateClick = async (dateInfo) => {
		if(this.readOnly) {
			return;
		}

		if (this.disableDateTo && this.dateTimeToDate(this.disableDateTo) > dateInfo.date) {
			return;
		}
		if (this.disableDateFrom && this.dateTimeToDate(this.disableDateFrom) < dateInfo.date) {
			return;
		}

		const organizer = (await apiFetch<UserDataLite>(getUser(Cookies.CeesoftUserId))).data;
		const organizerName = organizer ? `${organizer.firstName ?? ''} ${organizer.lastName ?? ''}`.trim() : Cookies.CeesoftUsername;

		let modalWindow = new ModalWindow({
			title: lang.account.EVENT_CREATE,
			width: 500,
			minWidth: 500,
			height: 600,
			url: 'include/Administration/CalendarEventForm.jsp',
			refresh: $.proxy(function () {
				const disableDateFrom = this.disableDateFrom;
				const disableDateTo = this.disableDateTo;
				if (dateInfo.date < disableDateTo) {
					// +1000ms necessary to be sure that start date more than disableTo
					dateInfo.date = new Date(disableDateTo.getTime() + 1000);
				} else {
					// calendar sends date in local tz need to be moved to utc
					dateInfo.date = convertTimezone(dateInfo.date, moment.tz.guess(), 'UTC');
				}
				const startTime = this.getStartTime(dateInfo.date);
				let startDate = new Date(dateInfo.date);
				startDate.setHours(startTime[0]);
				startDate.setMinutes(startTime[1]);
				startDate.setSeconds(startTime[2]);

				let endDate = new Date(dateInfo.date);
				endDate.setHours(startTime[0] + 1);
				endDate.setMinutes(0);
				endDate.setSeconds(0);

				this.calendarEventForm = new CalendarEventForm({
					id: null,
					mode: 'create',
					event: {
						start: startDate,
						end: endDate,
						timezone: this.props.config.timeZone,
						disableDateFrom: disableDateFrom,
						disableDateTo: disableDateTo,
						created: new Date(),
						organizer: organizerName,
						categories: this.props.categories
					},
					onSave: this.addEvent
				});
			}, this)
		});
		modalWindow.open();
	}

	getStartTime = (date) => {
		if (!this.props.startTimeFrom || this.props.startTimeFrom < date) {
			return [date.getHours() || 8, date.getMinutes(), date.getSeconds()];
		}

		return [this.props.startTimeFrom.getHours(), this.props.startTimeFrom.getMinutes(), this.props.startTimeFrom.getSeconds()];
	}

	handleEventClick = async (eventInfo) => {
		const isGlobal = eventInfo.event.extendedProps?.categories?.endsWith('_GLOBAL');
		if(this.readOnly)
			return

		if (eventInfo.jsEvent.target.closest('._do-not-trigger-editing') != null) {
			return
		}
		let organizer;
		if(eventInfo.event.extendedProps.organizer)
			organizer = (await apiFetch<UserDataLite>(getUser(eventInfo.event.extendedProps.organizer.replace('mailto:', '')))).data;
		const organizerName = organizer ? `${organizer.firstName} ${organizer.lastName}` : eventInfo.event.extendedProps.organizer;
		let modalWindow = new ModalWindow({
			title: lang.account.EVENT_EDIT,
			width: 500,
			minWidth: 500,
			resizable: false,
			height: 620,
			url: 'include/Administration/CalendarEventForm.jsp',
			refresh: () => {
				const event = cloneDeep(this.state.calendarEvents.find(x => x.id == eventInfo.event.id));
				event.organizer = organizerName;
				event.disableDateFrom = this.disableDateFrom;
				event.disableDateTo = this.disableDateTo;
				event.start = convertTimezone(event.start, moment.tz.guess(), 'UTC');
				event.end = convertTimezone(event.end, moment.tz.guess(), 'UTC');
				this.calendarEventForm = new CalendarEventForm({
					mode: 'update',
					event: event,
					disabled: isGlobal,
					enableName: !isGlobal,
					tillTheEndDay: eventInfo.event.extendedProps?.tillTheEndDay,
					onEdit: this.updateEvent,
					onDelete: () => {
						this.deleteEvent(event.id)
						modalWindow.close()
					}
				});
			}
		});
		modalWindow.open();
	}

	addEvent = eventData => {
		let event = new CalendarEntry();
		eventData.start = convertTimezone(eventData.start, 'UTC', moment.tz.guess());
		eventData.end = convertTimezone(eventData.end, 'UTC', moment.tz.guess());
		Object.assign(event, eventData)
		event.organizer = Cookies.CeesoftUserId;
		const events = [...this.state.calendarEvents]
		events.push(event)
		this.updateCalendarEvents(events)
	}

	updateEvent = eventData => {
		const events = [...this.state.calendarEvents];
		eventData.start = convertTimezone(eventData.start, 'UTC', moment.tz.guess());
		eventData.end = convertTimezone(eventData.end, 'UTC', moment.tz.guess());
		eventData.lastModified = new Date();
		const disableTo = this.disableDateTo ? convertTimezone(this.disableDateTo, 'UTC', moment.tz.guess()) : this.disableDateTo;
		if (!!eventData.rruleObject && disableTo && moment(eventData.start).isSameOrBefore(moment(disableTo), 'minute')) {
			this.deleteEvent(eventData.id);
			eventData.id = newGuid();
			const diff = moment(eventData.start).diff(disableTo, 'd');
			eventData.start = moment(eventData.start).add(diff + 1, 'd');
			eventData.start = convertTimezone(eventData.start, moment.tz.guess(), 'UTC');
			eventData.end = convertTimezone(eventData.end, moment.tz.guess(), 'UTC');
			Promise.resolve().then(() => this.addEvent(eventData));
			return;
		}
		const indexToReplace = events.findIndex(x => x.id == eventData.id);
		eventData.organizer = events[indexToReplace].organizer;
		events[indexToReplace] = new CalendarEntry();
		Object.assign(events[indexToReplace], eventData);
		this.updateCalendarEvents(events);
	}

	deleteEventClicked(e, eventInfo) {
		e.stopPropagation()
		e.preventDefault()

		let eventToDelete = this.state.calendarEvents.find(x => x.id == eventInfo.id);
		if (eventToDelete?.rrule) {
			this.setState({
				showDeleteRecurringEventWindow: true,
				eventIdToDelete: eventInfo.id,
				eventOccurenceDateToDelete: eventInfo.start
			});
		} else {
			if (eventToDelete == null || !this.onEventDelete(eventToDelete))
				return;

			this.deleteEvent(eventInfo.id);
		}
	}

	closeDeleteRecurringEventWindow = () => {
		this.setState({
			showDeleteRecurringEventWindow: false,
			eventIdToDelete: null,
			eventOccurenceDateToDelete: null
		});
	}

	deleteEvent = id => {
		const eventsCopy = [...this.state.calendarEvents];
		const eventToDelete = eventsCopy.find(x => x.id == id);
		if (eventToDelete == null || !this.onEventDelete(eventToDelete))
		{
			this.closeDeleteRecurringEventWindow();
			return;
		}

		const indexToDelete = eventsCopy.findIndex(x => x.id == id);
		eventsCopy.splice(indexToDelete, 1);
		this.updateCalendarEvents(eventsCopy);
	}

	deleteEventOccurence = () => {
		let events = [...this.state.calendarEvents]
		let event = events.find( x => x.id == this.state.eventIdToDelete);
		const disableTo = convertTimezone(this.disableDateTo, 'UTC', moment.tz.guess());
		if (event?.rrule && moment(this.state.eventOccurenceDateToDelete).isSameOrBefore(disableTo, 'day') && moment(event.start).isSameOrBefore(disableTo, 'minute')) {
			this.deleteEvent(event.id);
			const eventData = {...event};
			eventData.id = newGuid();
			const diff = moment(eventData.start).diff(disableTo, 'd');
			eventData.start = moment(eventData.start).add(diff + 1, 'd');
			Promise.resolve().then(() => this.addEvent(eventData));
			return;
		}
		const date = this.state.eventOccurenceDateToDelete.toISOString();
		if (!this.onEventDelete(event, date)) {
			this.closeDeleteRecurringEventWindow()
			return;
		}
		if(event.exdate == null) {
			event.exdate = []
		}
		event.exdate.push(date)
		this.updateCalendarEvents(events)
	}

	isDropAllowed = (dropInfo, draggedEvent) => {
		return draggedEvent.extendedProps.rruleObject == null
			&& this.allowEdit(draggedEvent)
			&& this.allowEdit(dropInfo);
	}

	onEventDrop = (eventInfo) => {
		let events = [...this.state.calendarEvents]
		let event = events.find(x => x.id == eventInfo.event.id)

		event.start = eventInfo.event.start;
		event.end = eventInfo.event.end;

		this.updateCalendarEvents(events)
	}

	allowEdit = (ev) => {
		const start = convertTimezone(ev.start, moment.tz.guess(), 'UTC');
		const end = convertTimezone(ev.end, moment.tz.guess(), 'UTC');
		return (!this.disableDateTo || moment(start).isAfter(moment(this.disableDateTo), 'minute'))
			&& (!this.disableDateFrom || moment(end).isBefore(moment(this.disableDateFrom), 'minute'));
	}

	allowDelete = (ev) => {
		const end = convertTimezone(ev.end, moment.tz.guess(), 'UTC');
		return (!this.disableDateTo || moment(end).isAfter(moment(this.disableDateTo), 'minute'))
			&& (!this.disableDateFrom || moment(end).isBefore(moment(this.disableDateFrom), 'minute'));
	}

	onEventDelete = (ev, date) => {
		if (!this.props.onEventDelete)
			return true;

		ev.start = convertTimezone(ev.start, moment.tz.guess(), 'UTC');
		ev.end = convertTimezone(ev.end, moment.tz.guess(), 'UTC');
		const result = this.props.onEventDelete(ev, date);
		ev.start = convertTimezone(ev.start, 'UTC', moment.tz.guess());
		ev.end = convertTimezone(ev.end, 'UTC', moment.tz.guess());
		return result;
	}
}

function convertName(name){
	if(name == 'uid')
		return 'id'

	if(name == 'summary')
		return 'title'

	if(name == 'dtstart')
		return 'start'

	if(name == 'dtend')
		return 'end'

	if(name == 'rrule')
		return 'rruleObject'

	if(name == 'extendedprops')
		return 'extendedProps'

	if(name == 'last-modified')
		return 'lastModified'

	return name
}

function setDateProperty(event, name, date, targetTimezone) {
	let prop = new icalendar.CalendarProperty(name, moment(convertTimezone(date, moment.tz.guess(), 'UTC')).format('YYYYMMDDTHHmmss'), {
		TZID: targetTimezone
	})

	prop.type = 'TEXT'

	event.setProperty(prop)
}

function convertDateToUserTimezone(dateString, currentTimezone){
	let date = null
	if (currentTimezone) {
		date = moment.tz(dateString, currentTimezone)
	} else {
		date = moment.utc(dateString)
	}

	return new Date(date.tz(moment.tz.guess()).format("YYYY-MM-DDTHH:mm:ss"))
}

export function convertDateToSelectedTimezone(date, targetTimezone){
	return moment(date).tz(moment.tz.guess(), true).tz(targetTimezone).format('YYYYMMDDTHHmmss')
}

function parseFrequencyToRrule(frequency) {
	return RRule[frequency.toUpperCase()]
}

function getRRuleString(rruleObject){
	if(rruleObject == null)
		return null

	let ruleObj = {
		freq: parseFrequencyToRrule(rruleObject.freq.toLowerCase()),
		interval: rruleObject.interval,
		byweekday: rruleObject.byweekday?.map(x => RRule[x]) ?? [],
		bymonthday: rruleObject.bymonthday,
		bymonth: rruleObject.bymonth,
	}

	if (rruleObject.until) {
		ruleObj.until = new Date(rruleObject.until);
	}

	let rule = new RRule(ruleObj);

	return rule.toString().split('RRULE:')[1]
}
