import {observer} from "mobx-react"
import React from "react"
import {MenuInfo} from 'rc-menu/lib/interface'

import {DesignerStore} from "controls/designer/designerStore"
import {AntMenu, AntMenuItem, AntMenuSubmenu} from "controls/react/ant/antMenu";

export const ScaleSelector = observer(({store}: { store: DesignerStore }) => {
	const onClick = React.useCallback((info: MenuInfo) => {
		store.scale = Number.parseInt(info.key);
	}, []);

	return <AntMenu mode={"horizontal"}
	             selectable={false}
	             className={"scale-selector"}

	             onClick={onClick}>
		<AntMenuSubmenu title={`${store.scale}% ▼`} key={'root'}>
			<AntMenuItem key={"25"}>25%</AntMenuItem>
			<AntMenuItem key={"50"}>50%</AntMenuItem>
			<AntMenuItem key={"75"}>75%</AntMenuItem>
			<AntMenuItem key={"100"}>100%</AntMenuItem>
		</AntMenuSubmenu>
	</AntMenu>
});
