import React from 'react'

import {
	EventsSummaryStore,
	EventsSummaryStoreProps,
	getFilterByEntityId
} from "areas/summary/events/eventsSummaryStore";
import {ModuleProps} from 'framework/modules';
import {EventsSummaryPageArgs} from '../bundleDescription';
import {tryGetLegacyGridState} from 'controls/grid/legacyGridState';
import {GridStore} from 'controls/grid/gridStore';
import {RemoteDataProvider} from 'controls/grid/remoteDataProvider';
import {getEventSummaryFilters} from './api';
import {EventsSummaryEntry} from './eventsSummaryEntry';
import {StateOverridePlugin} from 'controls/grid/plugins/stateOverridePlugin';
import {GroupConjunction, RuleDefinition} from 'controls/queryBuilder/ruleDefinition';
import {TimePeriodType} from 'controls/react/form/timePeriodSelector';
import {GridSelectionType} from 'controls/grid/gridConfiguration';
import {UserSettingsStateProvider} from "controls/grid/stateProviders";
import {apiFetch} from 'framework/api';

const i18n = require('core/localization/localization').translator({
})

export let EventsSummaryPageContext = React.createContext<EventsSummaryPageStore>(null)


type EventsSummaryPageStoreProps = ModuleProps & EventsSummaryPageArgs

export class EventsSummaryPageStore extends EventsSummaryStore {
	props: EventsSummaryPageStoreProps

	constructor(props: EventsSummaryPageStoreProps) {
		super()
		this.props = props

		this.init()
	}

	async init() {
		const filtersResult = await apiFetch(getEventSummaryFilters())
		if (!filtersResult.success) {
			return
		}
		const filtersConfiguration = filtersResult.data

		await tryGetLegacyGridState<EventsSummaryEntry>(
			'event-summary-grid',
			'EventSummary',
			{
				search: 'eventSummarySearchPhrase',
				sort: 'eventSummarySort',
				refreshTimer: 'eventsStatus',
				filters: 'eventSummaryFilter',
				columns: 'eventSummaryColumns',
				timePeriod: 'timePeriod',
				views: 'eventSummaryFSViews',
				defaultView: 'defaultEventSummaryFsView'
			},
			this.getColumns(),
			((settings, state) => {
				state.customPayload.showHistoricEvents = settings.showHistoric === true
			}),
			filtersConfiguration,
			{accountName: 'accountId'} as Record<string, string>
		)

		this.gridStore = new GridStore<EventsSummaryEntry>({
			columns: this.getColumns(),
			stateProviders: [
				new UserSettingsStateProvider({category: 'event-summary-grid'}),
			],
			dataProvider: new RemoteDataProvider({
				dataSource: this.getDefaultDatasource(this.props),
				filtersSource: getEventSummaryFilters()
			}),
			plugins: [
				this.getAutoReloadPlugin(this.props),
				new StateOverridePlugin<EventsSummaryPageStoreProps, EventsSummaryEntry>({
					fields: ['tags', 'severity', 'type', 'assetGroupIds'],
					getFilters: (filters: RuleDefinition) => {
						if (this.props.entityId) {
							let group = getFilterByEntityId(this.props.entityId)

							filters.addOrUpdateRule(group)
						}
					},
					dataObject: this.props,
					customPayloadDefaults: {
						timePeriod: {
							period: TimePeriodType.All
						},
						showHistoricEvents: false
					}
				})
			],
			defaults: {
				payload: this.getCustomPayloadDefaults()
			},
			selection: GridSelectionType.Many
		})

		this.props.initialized({
			title: this.title
		})
	}
}
