import 'vendor-init/jquery';
import 'vendor-init/kendo/kendo';
import 'vendor-init/jquery.bind-first';
import 'vendor-init/mobx';
import 'antd/dist/antd.less';
import 'styles/override-ant.less';

import 'ol/ol.css'

import "styles/glyphicons.css";
import 'styles/style.less';
import 'vendor-init/kendo/overrides.less';
import 'material-icons/iconfont/material-icons.css';

require('es6-promise').polyfill();
require('isomorphic-fetch');

require('jquery.maskedinput/src/jquery.maskedinput');

import 'mxgraph/javascript/src/css/common.css';

import './app.less'


import Cookies from 'core/cookies';

import HighchartsInit from 'vendor-init/highcharts';

import MxGraphInit from 'vendor-init/mxGraph';
import State from 'tools/state'
import Renderer from "./tools/renderer";
import Utils from 'tools/utils';
import Constants from 'tools/constants';
import Lang from "./core/localization/lang";
import moment from 'moment';
import 'reflect-metadata';

import jss from 'jss'
import preset from 'jss-preset-default'

import LoginModule, {createCookies} from './login';
import WebLogin from './weblogin';
import ChangePassword from './changePassword';
import Wizard from './wizard';
import {marketOffer} from './marketOffer';
import {marketOfferManage} from './marketOfferManage';

import React from 'react';
import ReactDOM from 'react-dom';
import {ReactApp} from 'reactApp';
import {AppStore} from "./appStore";
import {Api, UrlBuilder} from "tools";
import {newGuid} from "tools/guid";
import Settings from "settings";
import {getUrlBuilderForCurrentHash, retaienSessionId} from "tools/urlBuilder";
import {ApplicationState, initFromCookies} from "framework/applicationState";
import ShutDownPage from "./areas/db-shutdown/shutdownPage";

jss.setup(preset())

document.addEventListener('DOMContentLoaded', async function () {
	HighchartsInit.init();
	MxGraphInit.init();
	checkForIsolatedSession();

	switch (page) {
		case 'wizard':
			Wizard();
			break;

		case 'changePassword':
			ChangePassword();
			break;

		case 'login':
			LoginModule();
			break;

		case 'weblogin':
			WebLogin();
			break;

		case 'marketOffer':
			marketOffer();
			break;

		case 'marketOfferManage':
			marketOfferManage();
			break;

		case 'index':
		default:
			const url = window.location.href;
			const match = url.match(/\/dashboards\/(.*)\?.*guestMode=1.*/);
			if(match != null){
				const dashboardId = match[1];
				await loginToDashboard(dashboardId);
			}

			const dbShutdownMatch = url.match(/\/db-shutdown/);
			const isDbShutdown = localStorage.getItem("isDbShutdown");
			if(dbShutdownMatch && isDbShutdown === "true") {
				localStorage.setItem("isDbShutdown", "false");
				ReactDOM.render(<ShutDownPage/>, document.getElementById('reactApp'));
				break;
			}

			const urlBuilder = getUrlBuilderForCurrentHash();
			const standalone = urlBuilder.params.standalone  == 1 || Cookies.CeesoftUsername == 'public';

			if(Cookies.CeesoftUserId == null){
				//we are clearing sessionId or we might get into infinite login loop because
				//guest user might be logged in for guest service board
				Cookies.erase('sessionId');
				Cookies.erase('guestSessionId');
				let loginUrl = Settings.defaultLogoutPage + "?forceLogin=1";
				Utils.redirectTo(loginUrl, true);
				return;
			}

			ApplicationState.initFromCookies();
			ApplicationState.standalone = standalone

			ReactDOM.render(<AppStore><ReactApp standalone={standalone}/></AppStore>,
				document.getElementById('reactApp'));

			break
	}
});

function checkForIsolatedSession(){
	const urlBuilder = getUrlBuilderForCurrentHash();
	const sessionId = urlBuilder.params.sessionId;
	if(sessionId != null){
		Cookies.useSessionStorage(sessionId);
	}
}

async function loginToDashboard(dashboardId){
	if(Cookies.CeesoftLoggedIntoDashboardId == dashboardId)
		return;

	const result = await Api.fetch(Api.server + 'accounts/dashboards/' + dashboardId + '/login');
	if(!result.success)
		return false;

	createCookies(result);

	Cookies.create("CeesoftLoggedIntoDashboardId", dashboardId, Settings.COOKIE_TIMEOUT);
}

//this is so kendo templates could work
window.Renderer = Renderer;
window.Utils = Utils;
window.State = State;
window.Constants = Constants;
window.moment = moment;
window.lang = Lang;



if(module.hot){
	module.hot.accept('core/mainApplication', function(){
		State.mainApp.reloadCurrentModule();
	})
}
