import React from "react";
import {DropDownList} from "controls/react/kendoWrappers/dropDownList";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {AgentAPI} from "tools/apis/agentAPI";
import {AssetAPI} from "tools/apis/assetAPI";
import {ErrorHandler} from "core/errorHandler";
import {translator} from "core/localization";
import {AddAsset} from "controls/react/windows/addAsset";
import {inputWithAction} from 'controls/react/form/common';
import withRequired from 'controls/react/dropdowns/withRequired';
import Configuration from 'configuration'

const i = translator({
  "Select Asset...": {
    "no": "Velg ressurs..."
  },
	"Select...": {
    "no": "Velg..."
  }
});

export class AssetDropDown extends React.PureComponent {
	constructor(props) {
		super(props);
		const sharedAgents = this.props.sharedAgents ?? Configuration.installedMonitorSettings[this.props.monitorType]?.sharedAgents;
		this.config = {
			dataSource: AssetDropDown.getDataSource(this.props.accountId, this.props.includeSubaccounts, this.props.isTypeAgent, this.props.systemType, sharedAgents),
			dataTextField: 'name',
			dataValueField: 'id',
			suggest: true,
			placeholder: this.props.isTypeAgent ? i('Select...') : i('Select Asset...'),
			autoBind: true,
		};
	}

	static getDataSource(accountId, includeSubaccounts, isTypeAgent, systemType, sharedAgents) {
		return new CeeViewDataSource({
			transport: {
				read: {
					url: isTypeAgent ? AssetAPI.getAssetAgentsUrl(accountId, includeSubaccounts, systemType, sharedAgents) : AssetAPI.getAssetsUrl(accountId, includeSubaccounts),
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			error: ErrorHandler.kendoServerError
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.accountId !== this.props.accountId) {
			this.kendoRef.setDataSource(AssetDropDown.getDataSource(this.props.accountId, this.props.includeSubaccounts, this.props.isTypeAgent, this.props.systemType, this.props.sharedAgents))
		}
	}
	onCreateNew = (newAssetId) => {
		this.kendoRef.dataSource.read().then(()=>{
			this.kendoRef.value(newAssetId);
			this.props.onChange(newAssetId);
		})
	}
	render() {
		let {config, combo, canAdd, isTypeAgent, onInfoChanged, ...others} = this.props;

		canAdd = !isTypeAgent && (canAdd ?? true);

		return <div className={inputWithAction()}>
			<DropDownList config={this.config}
			              containerClass={inputWithAction('input')}
			              kendoRef={(ref) => this.kendoRef = ref}
			              combo={true}
			              autoBind={true}
						  type='asset'
						  onInfoChanged={onInfoChanged}
						  {...others}/>
			{canAdd && <AddAsset onClose={() => this.kendoRef.dataSource.read()}
			          containerClass={inputWithAction('action')}
			          disabled={this.props.disabled}
					  identifier={this.props.identifier}
					  onCreateNew={this.onCreateNew}
			/>}
		</div>

	}
}

export default withRequired(AssetDropDown);
