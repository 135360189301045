import React from 'react';
import ReactDOM from "react-dom";
import {CeeViewDataSource, State, Utils, Api, Renderer} from "tools";
import {Application, Cookies, ErrorHandler, LocalEventsManager, translator} from "core";
import {CustomNotification, ExpandableTextarea, Switch, Tags} from "controls";
import {Settings} from "settings";
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";

const i = translator({
  "Reset health index message": {
    "en": "Reset as filter {0} was created {1}",
    "no": "Tilbakestill siden filter {0} ble opprettet {1}"
  }
});

export default function FiltersConfigurationView(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'AssetHealthFiltersConfiguration',
		initialConfig: config
	};

	this.accountContext = this.accountId || Cookies.CeesoftCurrentAccountId;
	this.accountNameContext = this.accountName || Cookies.CeesoftCurrentAccountName;

	this.initComponent();
}

jQuery.extend(FiltersConfigurationView.prototype, Application.prototype, {
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	/**
	 * Main init function
	 */
	initComponent: async function () {
		Application.prototype.initComponent.call(this);

		if (!State.mainApp.session.hasRole('HEALTHFILTER_UPDATE')) {
			this.isReadOnlyMode = true;
		}

		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: '.cw_filters_settings',
			status: 'success',
			style: 'top:55px;',
			hideOnClick: false
		});
		this.modalNotification = new CustomNotification({
			appendToElement: '.cw_section_content .left.form',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			type: 'icon',
			hideOnClick: true
		});
		this.trimNotification = new CustomNotification({
			appendToElement: '.cw_filters_details_configuration',
			status: 'warning',
		});
		this.accountsStatusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: '.cw_accounts_container',
			hideOnClick: false
		});
		this.activeControl = new Switch({
			renderTo: $('#cw_filter_switch'),
			isEnabled: !this.isReadOnlyMode,
			change: function (response) {
				$('#cw_filters_active').find('.label').text(lang.ACTIVE);
				if (response.value) {
					$('#cw_filters_active').find('.label').removeClass('opacity');
				} else {
					$('#cw_filters_active').find('.label').addClass('opacity');
				}
			}
		});

		await this.loadAccountsList()

		this.descriptionFilter = new ExpandableTextarea({
			label: lang.assethealth.REASON_FILTER,
  			renderTo: $('.cw_description_filter')
  		});

		this.isGroupSelected = false;
		this.removeListeners();
		this.attachListeners();
		if (this.mode === 'edit' || this.mode === 'update') {
			$('#cw_save').text(lang.UPDATE);
			this.load();
		} else {
			$('.cw_created').addClass('hide');
			$('.cw_filtered').addClass('hide');
			this.initKendoComponents();
			this.activeControl.setValue(true).trigger('change');
			if (this.populate) {
				this.populateCreateForm();
			}
		}
		this.removeMask();
	},

	async loadAccountsList() {
		const url = Settings.serverPath + 'accounts/' + this.accountContext + '/subaccounts/allLevels/lite';
		this.accountsList = await Api.fetch(url);
	},

	makeInputsReadOnly() {
		$('#cw_asset_filters').find('input').attr('readonly', true);
		$('#cw_asset_filters').find('textarea').attr('readonly', true);
		this.accountsMenuList.enable(false);
		this.monitorTypeCombo.enable(false);
		this.subsystemCombo.enable(false);
		this.severityList.enable(false);
		this.fromTimeSelector.enable(false);
		this.toTimeSelector.enable(false);
		this.userLocale.enable(false);
		$('#cw_save').addClass('hide');
		$('#cw_asset_filters').find('.cw_multi_toggle').css('pointer-events', 'none');
	},

	renderTags() {
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical>
			<TagsSelect
				mode={'tags'}
				accountId={this.accountContext}
				includeSubaccounts={false}
				disabled={this.isReadOnlyMode}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>, $('.cw_tags').parent().get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_cancel').off();
		$('#cw_save').off();
		$('#cw_switch').off();
		$('#cw_filters_idname, #cw_filters_idinstance, #cw_filters_reason_description').off();

	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_cancel').on('click', $.proxy(this.onCancel, this));
		$('#cw_save').on('click', $.proxy(this.onSave, this));
		$('#cw_filters_name').on('keyup', $.proxy(this.onNameKeyUp, this));
		$('#cw_switch').on('click', 'li', $.proxy(this.onMonitorTargetSwitch, this));
		$('#cw_filters_idname, #cw_filters_idinstance, #cw_filters_reason_description .cw_inline').on('blur', $.proxy(this.onInputBlur, this));
	},
	/**
	 * Initialize Kendo UI controls
	 */
	initKendoComponents: async function () {
		this.renderTags();
        this.accountsMenuList = $("#cw_accounts_menu").kendoDropDownList({
			dataTextField: "text",
			dataValueField: "value",
			dataSource: [{
				value: "ACCOUNT_CHILDREN",
				text: lang.ACCOUNT_SUBACCOUNT
			},{
				value: "CHILDREN",
				text: lang.SUBACCOUNTS
			},{
				value: "SELECTED",
				text: lang.CUSTOM
			}],
			value: 'ACCOUNT_CHILDREN',
			template: '#= text#',
			change: $.proxy(this.onAccountsToggle, this)
		}).data('kendoDropDownList');
		if (this.mode === 'create') {
			if (this.populate?.assetId) {
				this.assetIds = [this.populate.assetId];
			}
			this.accountsMenuList.trigger('change', {
				sender: this.accountsMenuList
			});
		}

		this.monitorsFirstLoad = true;
		var monitorTypesDataSource = new CeeViewDataSource({
			change: $.proxy(function (e) {
				if (this.monitorsFirstLoad) {
					this.monitorsFirstLoad = false;
					e.sender.add({
						text: lang.ALL,
						value: null
					});
					//e.sender.value(null);
				}
			}, this),
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/types',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
                    cache: false
				}
			},
			sort: [{
					field: 'text',
					dir: 'asc'
				}],
			error: ErrorHandler.kendoServerError
		});


		this.monitorTypeCombo = $('#cw_filters_monitor_type').kendoDropDownList({
			autoBind: true,
			dataSource: monitorTypesDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			value: null,
			template: '<span data-id="${ data.value }">${ data.text }</span>'
		}).data('kendoDropDownList');

		var subsystemDataSource = new CeeViewDataSource({
			data: [{
					text: lang.ALL,
					value: null
				}, {
					text: 'DISK',
					value: 'DISK'
				}, {
					text: 'CPU',
					value: 'CPU'
				}, {
					text: 'MEMORY',
					value: 'MEMORY'
				}, {
					text: 'PROCESS',
					value: 'PROCESS'
				}, {
					text: 'SERVICE',
					value: 'SERVICE'
				}, {
					text: 'NETWORK',
					value: 'NETWORK'
				}, {
					text: 'APPLICATION',
					value: 'APPLICATION'
				}, {
					text: 'DATABASE',
					value: 'DATABASE'
				}, {
					text: 'EVENT',
					value: 'EVENT'
				}, {
					text: 'VM',
					value: 'VM'
				}, {
					text: 'EXTERNAL',
					value: 'EXTERNAL'
				}, {
					text: 'FILE',
					value: 'FILE'
				}, {
					text: 'HOST',
					value: 'HOST'
				}],
			error: ErrorHandler.kendoServerError,
			sort: [{
					field: 'value',
					dir: 'asc'
				}]
		});

		this.subsystemCombo = $('#cw_filters_monitor_subsystem').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: subsystemDataSource,
			template: '<span data-id="${ data.value }">${ data.text }</span>'
					//text: lang.SELECT
		}).data('kendoDropDownList');
		this.severityDataSource = [{
				text: lang.ALL,
				color: 6,
				value: null
			}, {
				text: lang.CRITICAL,
				color: 1,
				value: 'CRITICAL'
			}, {
				text: lang.MAJOR,
				color: 2,
				value: 'MAJOR'
			}, {
				text: lang.MINOR,
				color: 3,
				value: 'MINOR'
			}, {
				text: lang.NONE,
				color: 5,
				value: 'NONE'
			}];

		this.severityList = $('#cw_filters_severity').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: this.severityDataSource,
			change: $.proxy(function (e) {
				this.severity = e.sender.value();
			}, this),
			autoBind: true,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			dataBound: $.proxy(function (e) {
				if (this.mode === 'create') {
					e.sender.select(0);
					e.sender.trigger('change');
				} else if (this.mode === 'edit') {
					var index = Utils.getSeverityIndex(this.severity, this.severityDataSource);
					$('#cw_filters_severity').data('kendoDropDownList').select(index);
				}
			}, this)
		}).data('kendoDropDownList');
		this.severityList.list.addClass('cw_indicator_dropdown');

		const daySelectorOptions = [{
			name: lang.MONDAY,
			id: 'MONDAY'
		}, {
			name: lang.TUESDAY,
			id: 'TUESDAY'
		}, {
			name: lang.WEDNESDAY,
			id: 'WEDNESDAY'
		}, {
			name: lang.THURSDAY,
			id: 'THURSDAY'
		}, {
			name: lang.FRIDAY,
			id: 'FRIDAY'
		}, {
			name: lang.SATURDAY,
			id: 'SATURDAY'
		}, {
			name: lang.SUNDAY,
			id: 'SUNDAY'
		}];

		ReactDOM.render(<FormEntry label={lang.DAY_SELECTION} vertical={true}>
			<AntSelect
				dataList={daySelectorOptions}
				mode={"multiple"}
				sortValues={true}
				disabled={this.isReadOnlyMode}
				onChange={value => this.onDaysChange(value)}
				defaultValue={this.selectedDays}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_filters_days').parent().get()[0]);

		this.fromTimeSelector = $('#cw_filters_fromtime').kendoTimePicker({
			format: "HH:mm",
			parseFormats: ["HH:mm", "HHmm"]
		}).data('kendoTimePicker');
		this.toTimeSelector = $('#cw_filters_totime').kendoTimePicker({
			format: "HH:mm",
			parseFormats: ["HH:mm", "HHmm"]
		}).data('kendoTimePicker');
		this.userLocale = $('#cw_user_language').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/locales/',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
                        cache: false
					}
				}
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			autoBind: true,
			value: Cookies.CeesoftUserLocale
		}).data('kendoDropDownList');
	},
	populateCreateForm: function () {
		this.subsystemCombo.value(this.populate.reasonSubsytem);
		this.monitorTypeCombo.value(this.populate.monitorType);
		this.descriptionFilter.value(this.populate.reasonDescription || '');
		$('#cw_filters_idname').val(this.populate.identifierName || '');
		$('#cw_filters_idinstance').val(this.populate.identifierInstance || '');
		this.severityList.value(this.populate.severity);
		this.accountIds = [this.populate.accountId];
	},
	onMonitorTargetSwitch: function (e) {
		var target = $(e.currentTarget);
		if (target.closest('.cw_multi_toggle').hasClass('is_readonly')) {
			return;
		}
		if (!target.hasClass('disabled')) {
			if (!target.hasClass('is_selected')) {
				target.parent().find('li').removeClass('is_selected');
				target.addClass('is_selected');
				var input = target.find('input[type=radio]');
				input.prop('checked', !input.is(':checked'));
				if (target.find('input[value=ASSET_GROUP]').length) {
					$('#cw_select_asset').addClass('hide');
					$('#cw_select_asset_groups').removeClass('hide');
					this.isGroupSelected = true;
				} else {
					this.isGroupSelected = false;
					$('#cw_select_asset_groups').addClass('hide');
					$('#cw_select_asset').removeClass('hide');
				}
			}
		}
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: function () {
		var loadUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/hifilters/' + this.id + '?update=true';
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var data = result.data;
				this.configuration = data;
				Utils.apply(this, result.data);
				this.tagsList = data.tags || [];
				$('#cw_filters_name').val(result.data.name || '');
				this.descriptionFilter.value(result.data.reasonDescription || '');
				this.selectedDays = result.data.days;
				this.initKendoComponents();
				this.monitorTypeCombo.value(result.data.monitorType);
				this.subsystemCombo.value(result.data.reasonSubsystem);
				$('#cw_filters_idname').val(result.data.identifierName);
				$('#cw_filters_idinstance').val(result.data.identifierInstance);
				$('#created').val(result.data.created == 0 ? '' : Renderer.browserDateRenderer(result.data.created, Constants.DATETIME));
				$('#filtered').val(result.data.filtered == 0 ? '' : Renderer.browserDateRenderer(result.data.filtered, Constants.DATETIME));
				this.severityList.value(result.data.reasonSeverity);
				this.fromTimeSelector.value(result.data.fromTime);
				this.toTimeSelector.value(result.data.toTime);
				this.activeControl.setValue(result.data.active).trigger('change');
				if (result.data.monitorTargetType === 'ASSET_GROUP') {
					this.assetGroupIds = result.data.monitorTargetIds;
					$('#cw_switch').find('ul').find('li:last').trigger('click');
				} else {
					this.assetIds = result.data.monitorTargetIds;
					$('#cw_switch').find('ul').find('li:first').trigger('click');
				}
				this.userLocale.value(result.data.locale);
				$('#cw_filters_idname, #cw_filters_idinstance, #cw_filters_reason_description .cw_inline').trigger('blur');
				$('#cw_filters_name').focus();
				this.accountsMenuList.value(data.targetType);
				this.accountsMenuList.trigger('change');

				if (this.isReadOnlyMode) {
					this.makeInputsReadOnly();
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));

	},
	onInputBlur: function (e) {
		var inputEl = $(e.currentTarget);
		var value = inputEl.val();
		if (value !== value.trim()) {
			if (!inputEl.hasClass('status_warning')) {
				this.trimNotification.setOptions({
					message: lang.messages.LEADING_TRAILING_WHITESPACES,
					status: 'warning',
					icon: 'glyphicons exclamation-sign',
					animationTime: 4000
				}).show();
			}
			inputEl.addClass('status_warning');
		} else {
			inputEl.removeClass('status_warning');
		}
	},
	/**
	 * Handler function for the Cancel button
	 */
	onCancel: function () {
		State.mainApp.loadModule('AssetHealthFilters');
	},
	/*
	 * Handler function for name key up
	 * @param {Object} e The key up event
	 */
	onNameKeyUp: function (e) {
		this.setSave();
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: async function (result) {
		if (result.success) {
			if (this.resetHealthIndex) {
				let filterName = $('#cw_filters_name').val().trim();
				let time = Utils.customDateToString(new Date());
				let data = {
					user: Cookies.CeesoftUsername,
					description: i('Reset health index message', filterName, time),
					monitors: [{
						assetId: this.populate.assetId,
						monitorId: this.populate.monitorId
					}]
				};
				let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/resetHealthIndexes';
				let result = await Api.fetchPost(url, data);
			}
			State.mainApp.loadModule('AssetHealthFilters');
			setTimeout(function () {
				LocalEventsManager.trigger('hifiltersaved');
			}, 200);
		} else {
			this.statusNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
		$('#cw_cancel').removeAttr('disabled');
		$('#cw_save').removeAttr('disabled');
	},
	/**
	 * Handler function e Update button
	 */
	onSave: async function () {
		var name = $('#cw_filters_name').val();
		if(name.trim() !== '') {
			let url = Api.accountRoot() + 'hifilters/lite?name=';
			let nameExists = await Utils.checkIfNameExists(url, name, this.modalNotification, true, this.name);
			if(nameExists) {
				return;
			}
		}
		else {
			this.modalNotification.setOptions({
				message: lang.account.messages.REQUIRED_FIELDS,
				status: 'error'
			}).show();
			return;
		}
		var data = {};
		data.id = this.mode === 'edit' ? this.id : null;
		data.name = $('#cw_filters_name').val().trim();
		data.reasonDescription = this.descriptionFilter.value();
		data.accountId = this.populate?.accountId || this.accountContext;
		var accountsMenuValue = this.accountsMenuList.value();
		if (accountsMenuValue === 'SELECTED') {
			data.accountIds = this.accountIds;
		}
		data.targetType = accountsMenuValue;
		data.days = this.selectedDays;
		data.identifierName = $('#cw_filters_idname').val();
		data.identifierInstance = $('#cw_filters_idinstance').val();
		data.reasonSubsystem = this.subsystemCombo.value().trim();
		data.monitorType = this.monitorTypeCombo.value();
		data.reasonSeverity = this.severityList.value();
		data.monitorType = data.monitorType === '' ? null : data.monitorType;
		data.reasonSeverity = data.reasonSeverity === '' ? null : data.reasonSeverity;
		data.reasonSubsystem = data.reasonSubsystem === '' ? null : data.reasonSubsystem;
		data.tags = this.tagsList;
		//data.monitorId = null;
		data.fromTime = kendo.toString(this.fromTimeSelector.value(), 'HH:mm');
		data.toTime = kendo.toString(this.toTimeSelector.value(), 'HH:mm');
		data.active = this.activeControl.getValue();
		data.locale = this.userLocale.value();
		if (this.isGroupSelected) {
			data.monitorTargetIds = this.assetGroupIds;
			data.monitorTargetType = 'ASSET_GROUP';
		} else {
			data.monitorTargetIds = this.assetIds;
			data.monitorTargetType = 'ASSET';
		}
		if (!data.monitorTargetIds?.length) {
			data.monitorTargetType = 'NONE';
		}
		var saveUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/hifilters';
		Utils.ajax(saveUrl, 'POST', JSON.stringify(data), $.proxy(this.onDataSuccessfullySaved, this));
	},
	renderAssetsList: function() {
		if (this.assetIds) {
			let assetDataSourceIds = [];
			for (let i = 0; i < this.assetsList.length; i++) {
				assetDataSourceIds.push(this.assetsList[i].id);
			}
			for (let i = 0; i < this.assetIds.length; i++) {
				if (assetDataSourceIds.indexOf(this.assetIds[i]) === -1) {
					this.assetIds.splice(i, 1);
				}
			}
		}

		ReactDOM.render(<FormEntry>
			<AntSelect
				dataList={this.assetsList}
				mode={"multiple"}
				sortValues={true}
				disabled={this.isReadOnlyMode}
				onChange={value => this.onAssetsChange(value)}
				defaultValue={this.assetIds}
				placeholder={lang.SELECT}
			/>
		</FormEntry>,  $('#cw_filter_asset').parent().get()[0]);
		if (!$('#cw_filter_asset').length) {
			$('#cw_select_asset').find('.cw_dropdown_container').append('<input id="cw_filter_asset" class="hide" type="text"/>');
		}
	},
	renderAssetGroupsList: function() {
		if (this.assetGroupIds) {
			let assetGroupDataSourceIds = [];
			for (let i = 0; i < this.assetGroupsList.length; i++) {
				assetGroupDataSourceIds.push(this.assetGroupsList[i].id);
			}
			for (let i = 0; i < this.assetGroupIds.length; i++) {
				if (assetGroupDataSourceIds.indexOf(this.assetGroupIds[i]) === -1) {
					this.assetGroupIds.splice(i, 1);
				}
			}
		}

		ReactDOM.render(<FormEntry>
			<AntSelect
				dataList={this.assetGroupsList}
				mode={"multiple"}
				sortValues={true}
				onChange={value => this.onAssetGroupsChange(value)}
				defaultValue={this.assetGroupIds}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_filter_asset_groups').parent().get()[0]);
		if (!$('#cw_filter_asset_groups').length) {
			$('#cw_select_asset_groups').find('.cw_dropdown_container').append('<input id="cw_filter_asset_groups" class="hide" type="text"/>');
		}
	},

	renderAccounts: async function () {
		ReactDOM.render(<FormEntry>
			<AntSelect
				dataList={this.accountsList}
				mode={"multiple"}
				sortValues={true}
				disabled={this.accountsMenuList.value() != 'SELECTED'}
				onChange={value => this.onAccountsChange(value)}
				defaultValue={this.accountIds || []}
				placeholder={lang.kpi.messages.SELECT_ACCOUNT} />
		</FormEntry>, document.querySelector('.cw_accounts_container'));

		if(this.accountsMenuList.value() != 'SELECTED') {
			$('.cw_accounts_container').find('.ant-select-selector').css({height: '60px'});
		}else{
			$('.cw_accounts_container').find('.ant-select-selector').css({height: 'auto'});
		}
	},

	onAssetsChange: function(value) {
		this.assetIds = value || [];
	},
	onAssetGroupsChange: function(value) {
		this.assetGroupIds = value || [];
	},
	onAccountsChange: async function(value) {
		this.accountIds = value || [];

		const assetsUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/assets/lite';
		const assets = await Api.fetchPost(assetsUrl, this.accountIds);
		this.assetsList = assets;
		this.renderAssetsList();

		const assetGroupsUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/assetGroups/lite';
		const assetGroups = await Api.fetchPost(assetGroupsUrl, this.accountIds);
		this.assetGroupsList = assetGroups;
		this.renderAssetGroupsList();

		this.setSave();
	},
	onDaysChange: function(value) {
		this.selectedDays = value || [];
	},
	/*
	 * Handler function for accounts toggle
	 * @param {Object} e The change event object
	 */
	onAccountsToggle: async function (e) {
		var value = e.sender.value();
		var accounts = $('#cw_accounts');
		if (value === 'SELECTED') {
			accounts.removeClass('hide');
			this.accountsStatusNotification.hide();
			var accountIds = this.accountIds || [];

			const assetsUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/assets/lite';
			const assets = await Api.fetchPost(assetsUrl, accountIds);
			this.assetsList = assets;

			const assetGroupsUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/assetGroups/lite';
			const assetGroups = await Api.fetchPost(assetGroupsUrl, accountIds);
			this.assetGroupsList = assetGroups;
		} else if (value === 'CHILDREN') {
			const assetsUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/assets/lite?filterTargetType=CHILDREN';
			const assets = await Api.fetch(assetsUrl);
			this.assetsList = assets;

			const assetGroupsUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/assetGroups/lite?filterTargetType=CHILDREN';
			const assetGroups = await Api.fetch(assetGroupsUrl);
			this.assetGroupsList = assetGroups;
		} else if (value === 'ACCOUNT_CHILDREN') {
			const assetsUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/assets/lite?filterTargetType=ACCOUNT_CHILDREN';
			const assets = await Api.fetch(assetsUrl);
			this.assetsList = assets;

			const assetGroupsUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/assetGroups/lite?filterTargetType=ACCOUNT_CHILDREN';
			const assetGroups = await Api.fetch(assetGroupsUrl);
			this.assetGroupsList = assetGroups;
		}

		this.renderAccounts();
		this.renderAssetsList();
		this.renderAssetGroupsList();
		this.setSave();

		if (value === 'CHILDREN') {
			accounts.addClass('hide');
			this.accountsStatusNotification.setOptions({
				message: lang.kpi.messages.SELECTED_SUBACCOUNTS
			}).show();
		}
		if (value === 'ACCOUNT_CHILDREN') {
			accounts.addClass('hide');
			this.accountsStatusNotification.setOptions({
				message: lang.kpi.messages.SELECTED_ACCOUNTS_SUB
			}).show();
		}
	},
	/*
	 * Handler function for setting save button availability
	 */
	setSave: function () {
		var name = $('#cw_filters_name').val();
		Utils.setInvalidField(name, $('#cw_filters_name'));
		Utils.setPlaceholder($('#cw_filters_name'), i('Please input Name...'));
		if (name.trim() === '' || (this.accountsMenuList.value() === 'SELECTED' && !this.accountIds.length)) {
			$('#cw_save').prop('disabled', true);
		} else {
			$('#cw_save').prop('disabled', false);
		}
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		//grid in summary/health tab
		var sectionBottom = $('.cw_tier_bottom:visible');
		var sectionBottomHeight = sectionBottom.height() - 1;
		sectionBottom.find('.cw_section_content').css('height', sectionBottomHeight - 42);
		sectionBottom.find('.k-grid-content').css('height', sectionBottomHeight - 42 - 30);
	},
	/**
	 * Destroy method
	 */
	destroy: function () {
		Application.prototype.destroy.call(this);

	}
});
