import './viewSelector.less'

import React from "react"
import {observer} from "mobx-react"
import {Divider, Typography} from "antd"
import {EditOutlined, PlusOutlined, ReloadOutlined, SaveOutlined} from "@ant-design/icons"
import {AntSelect} from "controls/react/ant/antSelect"
import {linkModel} from "framework/mobx-integration"
import {GridStore} from "controls/grid/gridStore"
import {Section} from 'controls/react/layout/section'
import {AntButton} from 'controls/react/ant/antButton'
import {AntPopover} from "controls/react/ant/antPopover"
import {GridDataItem} from "controls/grid/gridDataItem"
import {ViewManagerStore} from "controls/grid/viewManager/viewManagerStore";
import {SaveViewButton, ViewManager} from "controls/grid/viewManager/viewManager";


export type GridViewSelectorProps<T extends GridDataItem> = {
	store: GridStore<T>
}

const i = require('core/localization/localization').translator({
	'Add View': {no: 'Legg visning'},
	'Open view settings': {no: 'Åpne visning innstillinger'},
	'Reset to Defaults': {no: 'Tilbakestill'},
	'Select View...': {no: 'Velg Visning...'},
})

const b = require('b_').with('grid-view-selector')
export const ViewSelector = observer(<T extends GridDataItem, >(props: GridViewSelectorProps<T>) => {
	const [store, setStore] = React.useState<ViewManagerStore<T>>()

	React.useEffect(() => {
		setStore(new ViewManagerStore<T>(props.store))
	}, [])

	if(props.store?.selfInitialized != true)
		return null

	if (!store)
		return null

	const gridStore = store.gridStore

	const dropdownRender = (menu: React.ReactElement) => {
		return <>
			{menu}

			<Divider className={b('divider')}/>

			<Typography.Link className={b('add-button')} onClick={store.addView}>
				<PlusOutlined/> {i('Add View')}
			</Typography.Link>
		</>
	}

	return <Section appearance={"none"} direction={"row"} containerClass={b()}>
		<AntSelect dataList={props.store.state.views}
		           placeholder={i('Select View...')}
		           className={b('views-list')}
		           size={"small"}
		           {...linkModel(props.store.state, "currentViewId")}
		           nameField={'nameEffective'}
		           dropdownRender={dropdownRender}/>
		<AntPopover trigger={"click"}
		            visible={store.showDetails}
		            placement={"bottomRight"}
		            onVisibleChange={(newVisible) => store.showDetails = newVisible}
		            content={<ViewManager store={store}/>}>
			<AntButton icon={<EditOutlined/>}
			           size={"small"}
			           onClick={() => store.showDetails = !store.showDetails}
			           title={i('Open view settings')}/>
		</AntPopover>

		<SaveViewButton store={store} size={"small"}/>

		<AntButton icon={<ReloadOutlined/>}
		           size={"small"}
		           title={i('Reset to Defaults')}
		           onClick={() => gridStore.state.reset(gridStore)}/>
	</Section>
})
