import {makeAutoObservable} from "mobx";
import {newGuid} from "tools/guid";
import {createModelSchemaWrapper, optionalExt} from "framework/serializr-integration";
import {list, object, map, primitive} from "serializr";
import {GridDataItem} from "controls/grid/gridDataItem";

export enum RuleDefinitionType {
	Rule = 'rule',
	Group = 'group'
}

export enum GroupConjunction {
	And = 'AND',
	Or = 'OR'
}

export class RuleProperties {
	field: string
	operator: string
	value: string[] | number[] = []
	valueSrc: string[]
	valueType: string[]
	conjunction: GroupConjunction
	not: boolean

	constructor() {
		makeAutoObservable(this)
	}
}

type iterateRulesCallbackParameter = {
	filter: RuleDefinition
	level: number
	parent: RuleDefinition
}

export class RuleDefinition {
	id: string;
	type: RuleDefinitionType;
	children1?: { [key: string]: RuleDefinition };
	properties?: RuleProperties;
	order: string[]

	constructor() {
		makeAutoObservable(this, {
			iterateRules: false
		})
	}

	get valid(): boolean {
		if (this.type == RuleDefinitionType.Rule) {
			return this.properties.field
				&& this.properties.operator
				&& this.properties.value?.length > 0
				&& this.properties.value[0] !== undefined
				&& this.properties.value[0] != ''
		} else {
			return Object.values(this.children1).every(x => x.valid)
		}
	}

	getSingleValue() {
		if (this.properties.value.length == 0)
			return null

		return this.properties.value[0]
	}

	removeInvalidRules = () => {
		if (this.type == RuleDefinitionType.Rule)
			return

		Object.values(this.children1).forEach(rule => {
			if (rule.type == RuleDefinitionType.Rule) {
				if (!rule.valid) {
					this.removeRule(rule)
				}
			} else {
				rule.removeInvalidRules()
			}
		})
	}

	addEmptyRule = () => {
		let rule = RuleDefinition.emptyRule("", "")
		this.addOrUpdateRule(rule)
		return rule
	}

	addEmptyGroup = () => {
		let group = RuleDefinition.emptyGroup()
		this.addOrUpdateRule(group)
		return group
	}

	addOrUpdateRule(rule: RuleDefinition) {
		if (this.children1[rule.id] == null) {
			this.order.push(rule.id)
		}
		this.children1[rule.id] = rule
	}

	removeRule(rule: string | RuleDefinition) {
		if (rule == null)
			return

		if (typeof rule != 'string') {
			rule = rule.id
		}

		const index = this.order.indexOf(rule)
		this.order.splice(index, 1)
		delete this.children1[rule]
	}

	removeRuleByFieldName(fieldName: string) {
		const rule = Object.values(this.children1).find(x => x.properties.field == fieldName)
		if (rule) {
			this.removeRule(rule)
		}
	}

	empty() {
		return this.properties.value.length == 0 || this.properties.value.length == 1 && !this.properties.value[0]
	}

	static emptyGroup(conjunction: GroupConjunction = GroupConjunction.And) {
		const result = new RuleDefinition()
		result.id = newGuid()
		result.type = RuleDefinitionType.Group
		result.properties = new RuleProperties()
		result.properties.conjunction = conjunction
		result.properties.not = false;
		result.order = []
		result.children1 = {}
		return result
	}

	static emptyRule(field: string, operator: string) {
		const result = new RuleDefinition()
		result.id = newGuid()
		result.type = RuleDefinitionType.Rule
		result.properties = new RuleProperties()
		result.properties.field = field
		result.properties.operator = operator
		return result
	}

	* iterateRules(callback?: (entry: iterateRulesCallbackParameter) => boolean, level: number = 1): IterableIterator<RuleDefinition> {
		if (this.type == RuleDefinitionType.Rule)
			return

		for (const filterId of Object.keys(this.children1)) {
			const filter = this.children1[filterId]
			if (filter.type == RuleDefinitionType.Rule) {
				if (callback == null || callback({filter, level, parent: this}))
					yield filter
			} else {
				yield* filter.iterateRules(callback, level + 1)
			}
		}
	}

	find(callback?: (entry: iterateRulesCallbackParameter) => boolean) {
		for (let filter of this.iterateRules(callback)) {
			return filter
		}

		return null
	}
	//
	// satisfy(data: Record<string, any>, rules: RulesConfiguration): boolean {
	// 	if (this.type == RuleDefinitionType.Rule) {
	// 		return this.satisfyRule(data, rules: RulesConfiguration)
	// 		// const predicate = predicatesMap.get(this.properties.operator);
	// 		// if (!predicate) {
	// 		// 	console.warn('Operator not found', this.properties.operator)
	// 		// 	return false;
	// 		// }
	// 		// return predicate(data?.[this.properties.field], this.properties.value)
	// 	} else {
	// 		return this.satisfyGroup(data)
	// 	}
	// }
	//
	// satisfyRule(data: Record<string, any>, rules: R){
	// 	switch(this.type){
	//
	// 	}
	// }
	//
	// satisfyGroup(data: Record<string, any>){
	// 	let groupResult = this.properties.conjunction != GroupConjunction.Or
	//
	// 	for (const filterId of Object.keys(this.children1)) {
	// 		const filter = this.children1[filterId]
	//
	// 		const ruleResult = filter.satisfy(data)
	// 		if (this.properties.conjunction == GroupConjunction.Or) {
	// 			groupResult = groupResult || ruleResult
	// 			if (groupResult) {
	// 				break
	// 			}
	// 		} else {
	// 			groupResult = groupResult && ruleResult
	// 			if (!groupResult) {
	// 				break
	// 			}
	// 		}
	// 	}
	//
	// 	return this.properties.not ? !groupResult : groupResult;
	// }
}

type DataEntry = Record<string, any>

export class RulesHandler{
	constructor(public rules: RulesConfiguration) {
	}

	filter<T extends GridDataItem>(data: T[], filter: RuleDefinition){
		return data.filter(x => this.satisfy(x, filter))
	}

	satisfy = (entry: DataEntry, filter: RuleDefinition) => {
		if(!filter.valid)
			return true

		if (filter.type == RuleDefinitionType.Rule) {
			return this.satisfyRule(entry,filter)
		} else {
			return this.satisfyGroup(entry, filter)
		}
	}

	satisfyRule(entry: DataEntry, filter: RuleDefinition){
		const configuration = this.rules[filter.properties.field]
		if(!configuration){
			console.warn('There is no configuration for field', filter.properties.field)
			return true
		}

		switch (configuration.type){
			case FieldType.Text:
				const predicate = stringPredicates.get(filter.properties.operator)
				const filterValue = (filter.properties.value[0] as string ?? "").toLocaleLowerCase()
				const fieldValue = (entry[filter.properties.field] as string ?? "").toLocaleLowerCase()
				return predicate(fieldValue, filterValue)

			case FieldType.MultiSelect:
				console.warn('not implemented')
				return true;

			case FieldType.Select:
				console.warn('not implemented')
				return true;

			case FieldType.Boolean:
				console.warn('not implemented')
				return true

			case FieldType.Number:
				console.warn('not implemented')
				return true

			case FieldType.Date:
				console.warn('not implemented')
				return true
		}
	}


	getEntryValue(field: string){

	}

	satisfyGroup(entry: DataEntry, filter: RuleDefinition) {
		let groupResult = filter.properties.conjunction != GroupConjunction.Or

		for (const filterId of Object.keys(filter.children1)) {
			const subFilter = filter.children1[filterId]

			const ruleResult = this.satisfy(entry, subFilter)
			if (filter.properties.conjunction == GroupConjunction.Or) {
				groupResult = groupResult || ruleResult
				if (groupResult) {
					break
				}
			} else {
				groupResult = groupResult && ruleResult
				if (!groupResult) {
					break
				}
			}
		}

		return filter.properties.not ? !groupResult : groupResult;
	}
}

const stringPredicates = new Map<string, ((lhv: string, rhv: string) => boolean)>([
	['begins_with', (x: string, y: string) => x.startsWith(y)],
	['not_begins_with', (x: string, y: string) => !x.startsWith(y)],
	['contains', (x: string, y: string) => x.includes(y)],
	['not_contains', (x: string, y: string) => x.includes(y)],
	['ends_with',(x: string, y: string) => x.endsWith(y)],
	['not_ends_with', (x: string, y: string) => !x.endsWith(y)],
	['like', (x: string, y: string) => x.includes(y)],
])

const predicatesMap = new Map<string, any>([
	['equal', <T,>(x: T, y: T) => x == y],
	['not_equal', <T,>(x: T, y: T) => x != y],
	['in', <T,>(x: T[], y: T) => x.includes(y)],
	['not_in', <T,>(x: T[], y: T) => !x.includes(y)],
	['less', <T,>(x: T, y: T) => x < y],
	['less_or_equal', <T,>(x: T, y: T) => x <= y],
	['greater', <T,>(x: T, y: T) => x > y],
	['greater_or_equal', <T,>(x: T, y: T) => x >= y],
	['between', <T,>(x: T, y: [T, T]) => x >= y[0] && x <= y[1]],
	['not_between', <T,>(x: T, y: [T, T]) => x < y[0] || x > y[1]],

	['is_empty', <T extends Array<T1>, T1>(x: T, y: T) => x.length == 0],
	['is_not_empty', <T extends Array<T1>, T1>(x: T, y: T) => x.length > 0],
	['is_null', <T,>(x: T, y: T) => x == null],
	['is_not_null', <T,>(x: T, y: T) => x != null],
	['like', (x: string, y: string[]) => {
		if(x.toLocaleLowerCase == null){
			return false
		}
		return x.toString().toLocaleLowerCase().includes(y[0].toLocaleLowerCase())
	}],
	['multiselect_not_equals', <T,>(x: T, y: T[]) => !y.includes(x)],
	['multiselect_equals', <T,>(x: T, y: T[]) => y.includes(x)],
	['time_greater', <T,>(x: T, y: T) => x > y],
	['time_less', <T,>(x: T, y: T) => x > y]
]);

export class RulesConfiguration {
	[index: string]: RulesConfigurationEntry
}

export enum FieldType {
	Text = 'text',
	MultiSelect = 'multiselect',
	Select = 'select',
	Boolean = 'boolean',
	Number = 'integer',
	Date = 'date'
}

export class RulesConfigurationEntry {
	attributes: [];
	label: string;
	type: FieldType;
	operators: {
		value: string;
		title: string;
	}[];
	fieldSettings: {
		allowCustomValues: boolean;
		listValues: {
			value: string;
			title: string;
		}[]
	}

	customMultiSelectRenderer?: (item: any) => React.ReactNode
}

export class MultiSelectEntry{
	value: string
	title: string
}

export function getTitleForValue(configuration: RulesConfiguration, name: string, value: string|number) {
	if (configuration[name] == undefined)
		return value;

	const valueEntry = configuration[name].fieldSettings.listValues.find(x => x.value == value);
	if (valueEntry == null)
		return value;

	return valueEntry.title
}

export class RuleConfigurationBuilder {
	private configuration = new RulesConfiguration();

	static create() {
		return new RuleConfigurationBuilder();
	}

	build() {
		return this.configuration;
	}

	addText = (key: string, label: string, filterBy?: string) => {
		const rule = new RulesConfigurationEntry();
		rule.label = label;
		rule.type = FieldType.Text;
		rule.attributes = [];
		rule.fieldSettings = {
			allowCustomValues: true,
			listValues: []
		};
		rule.operators = [
			{value: 'like', title: 'Matches'},
			{value: 'equal', title: 'Equal'},
			{value: 'not_equal', title: 'Not Equal'},
			{value: 'starts_with', title: 'Begins With'},
			{value: 'ends_with', title: 'Ends With'}
		];
		this.configuration[key] = rule;
		return this;
	}

	addMultiSelect = (key: string, label: string, listValues: {value: string, title: string}[], filterBy?: string) => {
		const rule = new RulesConfigurationEntry();
		rule.label = label;
		rule.type = FieldType.MultiSelect;
		rule.attributes = [];
		rule.fieldSettings = {
			allowCustomValues: false,
			listValues: listValues
		};
		rule.operators = [
			{value: "multiselect_equals", title: "In"},
			{value: "multiselect_not_equals", title: "Not in"},
			{value: "like", title: "Matches"}
		];
		this.configuration[key] = rule;
		return this;
	}

	addNumber = (key: string, label: string, filterBy?: string) => {
		const rule = new RulesConfigurationEntry();
		rule.label = label;
		rule.type = FieldType.Number;
		rule.attributes = [];
		rule.fieldSettings = {
			allowCustomValues: true,
			listValues: []
		};
		rule.operators = [
			{value: "equal", title: "Equal"},
			{value: "not_equal", title: "Not Equal"},
			{value: "less", title: "Less Than"},
			{value: "less_equal", title: "Less Or Equal"},
			{value: "greater", title: "Greater Than"},
			{value: "greater_equal", title: "Greater Or Equal"}
		];
		this.configuration[key] = rule;
		return this;
	}

	addDate = (key: string, label: string, listValues: {value: string, title: string}[], filterBy?: string) => {
		const rule = new RulesConfigurationEntry();
		rule.label = label;
		rule.type = FieldType.Date;
		rule.attributes = [];
		rule.fieldSettings = {
			allowCustomValues: true,
			listValues: []
		};
		rule.operators = [
			{value: "time_greater", title: "Time Greater"},
			{value: "time_less", title: "Time Less"}
		];
		this.configuration[key] = rule;
		return this;
	}

	addBool = (key: string, label: string) => {
		const rule = new RulesConfigurationEntry();
		rule.label = label;
		rule.type = FieldType.Boolean;
		rule.attributes = [];
		rule.fieldSettings = {
			allowCustomValues: true,
			listValues: []
		};
		rule.operators = [
			{value: "equal", title: "Equal"},
			{value: "not_equal", title: "Not Equal"}
		];
		this.configuration[key] = rule;
		return this;
	}
}

createModelSchemaWrapper(RuleDefinition, {
	id: optionalExt(primitive()),
	type: primitive(),
	children1: optionalExt(map(object(RuleDefinition))),
	properties: optionalExt(object(RuleProperties)),
	order: list(primitive()),
})

createModelSchemaWrapper(RuleProperties, {
	field: primitive(),
	operator: primitive(),
	value: list(primitive()),
	valueSrc: list(primitive()),
	valueType: list(primitive()),
	conjunction: primitive(),
	not: primitive()
});
