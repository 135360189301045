import {Moment} from "moment";
import {months} from "areas/cost/budget/month";

export enum BudgetSplit {
	EVEN = 'EVEN',
	NONE = 'NONE'
}

export enum CostType {
	COST_GROUP = 'COST_GROUP',
	COST_RESOURCE = 'COST_RESOURCE',
	COST_MODEL = 'COST_MODEL',
	METRIC = 'METRIC',
	EXPRESSION = 'EXPRESSION',
	NONE = 'NONE',
	ASSET = 'ASSET'
}

export enum ModelType {
	MANUAL = 'MANUAL',
	CLOUD = 'CLOUD'
}

export interface CostItemInfo {
	accountId: string,
	accountName: string,
	profileId: string,
	profileName: string,
	costModelId: string,
	costItemId: string,
	costItemName: string[],
}

//todo: we need to remove this type, use CostBudget instead
export interface Budget {
	id: string,
	budgetId: string,
	profileId: string,
	accountId: string,
	description: string,
	name: string,
	currency: string,
	startDate: Moment | string,
	items: BudgetEntry[],
	showCost: boolean,
	showBudget: boolean,
	showEstimate: boolean,
	showListingPrice: boolean,
	showSplit: boolean,
	modelType: ModelType,
	displayDecimals: number,
	displayAsThousands: boolean
}

export interface CostModelOld {
	id: string,
	name: string,
	description: string,
	modelType: string,
	costProfileId: string,
	costBudget: Budget
}

export interface BudgetGroupOld extends BudgetValues {
	id: string,
	name: string,
	split: BudgetSplit,
	items: BudgetEntry[],
	costType: CostType
}

export interface BudgetYearValues {
	editable: boolean,
	jan?: number,
	feb?: number,
	mar?: number,
	apr?: number,
	may?: number,
	jun?: number,
	jul?: number,
	aug?: number,
	sep?: number,
	oct?: number,
	nov?: number,
	dec?: number,
	total?: number
}

export type BudgetYearValuesKey = keyof BudgetYearValues;
export type BudgetYearValuesNumberKey = Exclude<BudgetYearValuesKey, 'editable'>;
export const budgetYearValuesNumberKeys = <BudgetYearValuesNumberKey[]>['total', ...months];

export interface BudgetValues {
	cost: BudgetYearValues,
	costRate: BudgetYearValues,
	budget: BudgetYearValues,
	listingPrice: BudgetYearValues
}

export type BudgetValuesKey = keyof BudgetValues;
export const budgetValuesKeys = <BudgetValuesKey[]>['budget', 'cost', 'costRate', 'listingPrice'];

export interface BudgetResource extends BudgetValues {
	id: string,
	name: string,
	split: BudgetSplit,
	costType: CostType
}
export interface BudgetLink extends BudgetResource {
	costTargetId: string,
	costTargetType: string,
	costModelId: string,
	targetAccountId: string,
	targetProfileId: string,
	hasHierarchy: string
}

export type BudgetEntry = BudgetLink | BudgetGroupOld | BudgetResource;
