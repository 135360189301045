import {Api} from "tools";
import Settings from "../../settings";

class ReportsApi {
	getBaseUrl() {
		return `${Api.accountRoot()}reports/`;
	}

	getReportUrl(reportId){
		return this.getBaseUrl() + reportId + '/';
	}

	copy(reportId, newAccountId, newReportName){
		return Api.fetch(`${Settings.serverPath}accounts/${newAccountId}/reports/${reportId}/copy?reportName=${newReportName}`);
	}
	
	copyReports (payload) {
		return Api.fetchPost(`${Settings.serverPath}reports/copy`, payload);
	}

	read(reportId, update = false){
		return Api.fetch(this.getReportUrl(reportId)+ '?update=' + update)
	}

	deploy(jobId, deployObj) {
		return Api.fetchPost(`${Api.accountRoot()}reports/jobs/${jobId}/deploy`, deployObj)

	}

	getExistingReportsUrl(accountId, includeSubaccounts) {
		return Api.serverRoot() + 'accounts/' + accountId + '/reports?includeSubaccounts=' + includeSubaccounts;
	}

	getMsTeams(msAuthId){
		return Api.fetchPost(`${Api.accountRoot()}reports/msteams`, {
			deliveryType: 'AS_TEAMS_DOCUMENT',
			msAuthId
		})
	}

	getMsDrives(msAuthId, msTeamId, msDriveItemId){
		return Api.fetchPost(`${Api.accountRoot()}reports/msdrives`, {
			deliveryType: 'AS_TEAMS_DOCUMENT',
			msAuthId,
			msTeamId,
			msDriveItemId
		})
	}
}

let api = new ReportsApi();
export default api;
