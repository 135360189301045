import { observer } from "mobx-react"
import React from "react"

import {GridDataItem} from "controls/grid/gridDataItem"
import {ViewManagerProps} from "controls/grid/viewManager/viewManagerStore"
import {GridColumn} from "controls/grid/gridColumnConfig"
import {Section, Toolbar, ToolbarItemPosition} from "controls/react/layout"
import {AntButton} from "controls/react/ant/antButton"
import {AntTabPane, AntTabs} from "controls/react/ant/antTabs"
import {SortingEditor} from "controls/grid/viewManager/sortingEditor"
import {DeleteOutlined, EditOutlined, SaveOutlined, StarFilled, StarOutlined } from "@ant-design/icons"
import { ColumnsList } from "./columnsList"
import { FiltersEditor } from "./filtersEditor"
import { NameEditor } from "./nameEditor"
import {SizeType} from "antd/es/config-provider/SizeContext";


 const i18n = require('core/localization/localization').translator({
	'Delete View': {no: 'Slett visning'},
 	'Edit name': {no: 'Endre navn'},
	'Filters': {no: 'Filter'},
 	'Left Frozen Columns': {no: 'Venstre låst kolonne'},
 	'Right Frozen Columns': {no: 'Høyre låst kolonne'},
 	'Columns': {
		no: 'Kolonner'
	},
	'Sorting':  {no: 'Sortering'},
 	'Set Favorite': {no: 'Favoritt'},
	'Current view is Favorite': {no: 'Nåværende visning er favoritt'},
	'Update Filter': {
		no: 'Oppdater Filter'
	}
 })


const b = require('b_').with('grid-view-manager')

export const ViewManager = observer(<T extends GridDataItem, >(props: ViewManagerProps<T>) => {
	const gridStore = props.store.gridStore

	const columns = React.useMemo(() => {
		let columnsCopy = [...gridStore.columns.all] .filter(x => x.field != 'id')

		columnsCopy.sort((a, b) => a.config.title.localeCompare(b.config.title))

		const idColumn = gridStore.columns.all.find(x => x.field == 'id')
		if(idColumn) {
			columnsCopy.splice(0, 0, idColumn)
		}

		return columnsCopy
	}, [gridStore.columns.all])

	const isVisible = React.useCallback((c: GridColumn<T>) => {
		return c.state.visible
	}, [])

	const toggleVisible = React.useCallback((column: GridColumn<T>) => {
		column.state.visible = !column.state.visible
		if(!column.state.visible){
			column.state.fixed = 'none'
		}
	}, [])

	let favoriteButton: React.ReactNode = null

	if(!gridStore.state.currentView.isTemporary()) {
		if (props.store.isFavorite) {
			favoriteButton = <AntButton icon={<StarFilled/>}
			                            title={i18n('Current view is Favorite')}
			                            type={"primary"}/>
		}else {
			favoriteButton = <AntButton icon={<StarOutlined/>}
			                            title={i18n('Set Favorite')}
			                            onClick={props.store.setFavorite}
			/>
		}
	}

	return <Section className={b('properties')}>
		<Toolbar title={props.store.nameEditing ? null : gridStore.state.currentView.name}>
			<NameEditor store={props.store} position={ToolbarItemPosition.AFTER_TITLE}/>
			{!gridStore.state.currentView.isDefault() && !props.store.nameEditing &&
				<AntButton icon={<EditOutlined/>}
				           title={i18n('Edit name')}
				           type={"text"}
				           position={ToolbarItemPosition.AFTER_TITLE}
				           onClick={props.store.editName}/>
			}

			{favoriteButton}

			<SaveViewButton store={props.store}/>

			{!gridStore.state.currentView.isDefault() &&
				<AntButton icon={<DeleteOutlined/>}
				           title={i18n('Delete View')}
				           onClick={props.store.deleteView}/>
			}

		</Toolbar>
		<AntTabs activeKey={props.store.currentTab} onChange={(key) => props.store.currentTab = key}>
			<AntTabPane tab={i18n('Filters')} key={"filters"}>
				<FiltersEditor store={props.store}/>
			</AntTabPane>
			<AntTabPane tab={i18n('Left Frozen Columns')} key={"frozen-columns-left"}>
				<ColumnsList columns={columns}
				             toggle={gridStore.columns.fixOnLeft}
				             isChecked={gridStore.columns.isFixedOnLeft}/>
			</AntTabPane>

			<AntTabPane tab={i18n('Columns')} key={"enabled-columns"}>
				<ColumnsList columns={columns}
				             toggle={toggleVisible}
				             isChecked={isVisible}/>
			</AntTabPane>
			<AntTabPane tab={i18n('Right Frozen Columns')} key={"frozen-columns-right"}>
				<ColumnsList columns={columns}
				             toggle={gridStore.columns.fixOnRight}
				             isChecked={gridStore.columns.isFixedOnRight}/>
			</AntTabPane>
			<AntTabPane tab={i18n('Sorting')} key={'sorting'}>
				<SortingEditor store={props.store}/>
			</AntTabPane>
		</AntTabs>
	</Section>
})

export const SaveViewButton = observer(<T extends GridDataItem, >(props: ViewManagerProps<T> & {size?: SizeType}) => {
	const gridStore = props.store.gridStore
	if(gridStore.state.currentView.isDefault())
		return null

	return <AntButton icon={<SaveOutlined />}
	                  size={props.size}
	                  title={i18n('Update Filter')}
	                  disabled={!gridStore.state.currentView.isDirty}
	                  onClick={gridStore.flushCurrentViewChanges}
	/>
})
