import {makeAutoObservable} from 'mobx'

import {GridDataItem} from "controls/grid/gridDataItem"
import {GridPlugin} from "controls/grid/gridConfiguration"
import {GridStore} from "controls/grid/gridStore"
import {MobxManager} from "framework/mobx-integration"
import { GridColumn } from '../gridColumnConfig'

export class AutoWidthPlugin<DataItem extends GridDataItem> implements GridPlugin<DataItem> {
	store: GridStore<DataItem>

	initialized: boolean = false

	mobx = new MobxManager()
	_injectedViewsList: string[] = []

	constructor() {
		makeAutoObservable(this)
	}

	attach(store: GridStore<DataItem>) {
		this.store = store

		store.registerInitializationDoneSource(() => this.initialized)

		this.mobx.reaction(() => ({
			width: this.store.width,
			viewId: this.store.state.currentViewId
		}), (value) => {
			this.store.width && this.calculateAutoWidth()
		}, {
			fireImmediately: true
		})

		//that probably looks weird but here we just replace reset function with our one which basically
		// calls width recalculation reset is done. The complication here is that 'reset' method is a method of a GridViewState class.
		//We can have multiple instances of that class stored - one per view and we need to make sure that we replace 'reset' method on all of them
		this.mobx.reaction(() => this.store.state.currentView, (view) => {
			if(this._injectedViewsList.indexOf(view.id) == -1){
				const originalReset = view.resetToDefault
				const plugin = this
				view.resetToDefault = function(store) {
					originalReset.apply(this, [store])
					plugin.calculateAutoWidth()
				}
				this._injectedViewsList.push(view.id)
			}
		}, {
			fireImmediately: true
		})
	}

	calculateAutoWidth = () => {
		//const columns = this.store.columns.all
		const defaultWidth = 150

		this.store.columns.all.forEach(x => {
			//in case neither width nor minWidth is set we just fix column size to a default value
			if(!x.state.width && !x.config.width && !x.config.minWidth){
				x.state.width = defaultWidth
			//copy width from config if set
			}else if(!x.state.width && x.config.width){
				x.state.width = x.config.width
			// if a column is not visible we just set its width to either minimum or default and exclude from calculations
			}else if(!x.state.width && !x.state.visible){
				x.state.width = x.config.minWidth || defaultWidth
			}
		})

		const columnsWithoutFixedWidth = this.store.columns.visible
			.filter(x => !x.config.width)

		if(columnsWithoutFixedWidth.length != 0) {
			columnsWithoutFixedWidth.forEach(x => x.state.width = x.config.minWidth || defaultWidth); // we need this to proper width calculation
			const extraWidth = this.store.width - getTotalDefinedWidth(this.store.columns.visible) - 22 //22 is a scrollbar size
			let extraWidthLeft = extraWidth % columnsWithoutFixedWidth.length

			columnsWithoutFixedWidth
				.forEach(x => {
					if(extraWidth > 0) {
						x.state.width += Math.floor(extraWidth / columnsWithoutFixedWidth.length)
						if(extraWidthLeft > 0){
							x.state.width ++
							extraWidthLeft --
						}
					}
				})
		}

		this.initialized = true
	}

	destroy(){
		this.mobx.destroy()
	}
}

const getTotalDefinedWidth = <DataItem extends GridDataItem>(columns: GridColumn<DataItem>[]) => {
	return columns
		.filter(x => x.state.visible)
		.reduce((prev, x) => {
			return prev + (x.state.width ?? x.config.minWidth)
		}, 0)
}
