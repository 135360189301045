import './gridSearchInput.less'

import React from "react"
import {observer} from "mobx-react"
import classNames from "classnames"

import {getStore, GridStore, GridStoreHolder} from "controls/grid/gridStore"
import {AntInput} from "controls/react/ant/antInput"
import {linkModel} from "framework/mobx-integration"
import {GridDataItem} from "controls/grid/gridDataItem"

const b = require('b_').with('grid-search-input')

const i = require('core/localization/localization').translator();

type GridSearchInputProps<T extends GridDataItem> = {
	store: GridStoreHolder<T>
}

export const GridSearchInput = observer(<T extends GridDataItem, >(props: GridSearchInputProps<T>) => {
	const store = getStore(props.store)

	if (!store?.selfInitialized)
		return null

	const classes = classNames(
		b({'active': !!store.state.searchString}),
		'_bypass-dirty-check'
	)

	return <AntInput placeholder={i('Search...')}
	                 size={"small"}
	                 bounced={1000}
	                 className={classes}
	                 {...linkModel(store.state, "searchString")}
	/>
})
