import './columnsList.less'

import { observer } from "mobx-react"
import React from "react"

import {GridDataItem} from "controls/grid/gridDataItem"
import {GridColumn} from "controls/grid/gridColumnConfig"
import {AntCheckbox} from "controls/react/ant/antCheckbox"


const b = require('b_').with('grid-view-manager-columns-list')

type ColumnsListProps<DataItem extends GridDataItem> = {
	columns: GridColumn<DataItem>[]
	isChecked: (c: GridColumn<DataItem>) => boolean
	toggle: (c: GridColumn<DataItem>) => void
}

export const ColumnsList = observer(<DataItem extends GridDataItem>(props: ColumnsListProps<DataItem>) => {
	return <div className={b()}>
		{props.columns.map(c => <Column column={c}
		                                key={c.field}
		                                toggle={props.toggle}
		                                isChecked={props.isChecked}/>)}
	</div>
})

type ColumnProps<DataItem extends GridDataItem> = {
	column: GridColumn<DataItem>
	isChecked: (c: GridColumn<DataItem>) => boolean
	toggle: (c: GridColumn<DataItem>) => void
}

const Column = observer(<DataItem extends GridDataItem>(props: ColumnProps<DataItem>) => {
	return <div key={props.column.field} className={b('column', {hidden: !props.column.state.visible})}>
		<AntCheckbox value={props.isChecked(props.column)}
		             onChange={() => props.toggle(props.column)}>
			{props.column.config.title}
		</AntCheckbox>
	</div>
})
