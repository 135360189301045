import './reactApp.less'
import React from 'react';
import {AppContext} from "./appStore";

import {Breadcrumb} from "./layout/breadcrumb";
import {addContextActions} from "./core/react/store";
import {State} from "tools/state";
import MainApplication from "core/mainApplication";
import {MonitorsRouter} from "areas/assets/monitors/bundleDescription";
import {ServicesRouter} from "areas/services/bundleDescription";
import {Link} from "controls/react/link";
import {NavigationAction} from "framework/navigationStore";
import Settings from "settings";
import {UserTags} from 'layout/userTags/userTags';
import {KpiRouter} from "./areas/kpi/bundleDescription";
import {SummaryRouter} from "./areas/summary/bundleDescription";
import {ApplicationsRouter} from "./areas/application/bundleDescription";
import {AdministrationRouter} from "./areas/administration/bundleDescription";
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import {PageHelpButton} from "./layout/helpButton";

const i = require('core/localization').translator({
  "Only account": {
    "no": "Brukerkonto",
    "en": "Only Account"
  },
  "Include Subaccount": {
    "no": "Inkluder underkonto"
  },
  "System Messages": {
    "no": "Systemmeldinger"
  },
  "Event Summary": {
    "no": "Hendelse oversikt"
  },
  "Last Changes": {
    "no": "Siste endringer"
  },
  "Clear user settings": {
    "no": "Slett brukerpreferanse",
    "en": "Clear User Settings"
  },
  "Logout": {
    "no": "Logg ut"
  },
  "Active": {
    "no": "Aktiv"
  },
  "User tags": {
    "no": "Brukeretiketter",
    "en": "User Tags"
  },
  "Ignore tags": {
    "no": "Ignorer etiketter",
    "en": "Ignore Tags"
  },
  "Team tags": {
    "no": "Teametiketter",
    "en": "Team Tags"
  },
  "Data Registry": {
    "no": "Dataregister"
  },
  "Message Center": {
    "no": "Meldingsenter"
  },
  "Cost": {
    "no": "Kostnad"
  },
  "Cost Model": {
    "no": "Kostnad Modell"
  },
  "Cost Store": {
    "no": "Kostnad Base"
  },
  "Cost Gateway": {
    "no": "Kost Gateway"
  }
});

@addContextActions
export class ReactApp extends React.Component {
	static contextType = AppContext;

	constructor(props, context) {
		super(props, context);

		this.state = {};
	}

	render() {
		return (
			<>
				{!this.props.standalone && <LegacyApp/>}
				{this.props.standalone && <StandaloneApp/>}
			</>
		)
	}

	async componentDidMount(){
		State.mainApp = new MainApplication()
		State.mainApp.standalone = this.props.standalone
		State.mainApp.setReactRef(this)
		State.mainApp.run()
	}

	get appContext(){
		return this.context;
	}
}

const StandaloneApp = props => {
	return (
		<div id="content_area"></div>
	)
}

const LegacyApp = props => {
	return (
		<>
			<div id="main_loading_mask"></div>
			<div className="toolbar site-header">
				<div className="toolbar__entry logo"><a href={Settings.httpRoot} title="Ceeview Logo"></a></div>
				<div className="toolbar__entry cw_logo_custom is_hidden"><a href={Settings.httpRoot}></a></div>

				<div className="toolbar__entry site-header__navigation">
					<ul className="cw_multi_toggle cw_include_subaccounts site-header__include-subaccount"
							data-enable="enable">
						<li className="cw_item is_selected" data-include="true"
								title={i('Include Subaccount')}>
							<span className="cw_icon"></span>
						</li>
						<li className="cw_item" data-include="false" title={i('Only account')}>
							<span className="cw_icon"></span>
						</li>
					</ul>
					<div className="cw_account_context">
						<b className="caret"></b>
						<span className="cw_name"></span>
					</div>
					<div>
						<div className="cw_account_drop"></div>
					</div>
					<Breadcrumb/>
				</div>
				<div className="toolbar__entry cw_statuses"></div>
				<ul className="toolbar__entry cw_userinfo">
					<li className="cw_item cw_current_user">
						<span className="cw_logged_user"></span>
						<b className="caret"></b>
					</li>
					<li className="cw_item cw_page_help">
						<PageHelpButton />
					</li>
					<li className="cw_item cw_notifications">
						<span className="glyphicons envelope" title={i('Message Center')}></span>
						<b id="notify_bubble" className="cw_value">0</b>
					</li>
					<li className="cw_item cw_systemerror_notifications">
						<span className="glyphicons cogwheels" title={i('System Messages')}></span>
						<b id="syserrors_notify_bubble" className="cw_value">0</b>
					</li>
					<li className="cw_item cw_event_summary_notifications">
						<span className="glyphicons article" title={i('Event Summary')}></span>
						<b id="event_summary_notify_bubble" className="cw_value">0</b>
					</li>
					<li className="cw_item cw_last_changes_notifications">
						<span className="glyphicons heat" title={i('Last Changes')}></span>
						<b id="last_changes_notify_bubble" className="cw_value">0</b>
					</li>
					<li className="cw_item cw_usertags user-tags__panel-item">
						<UserTags />
					</li>
				</ul>
				<div className="toolbar__entry cw_open_panels">

					<ul className="cw_user_actions">
						<li className="cw_item" id="menu_about"><a href="#" onClick={e => e.preventDefault()}>{i('About')}</a></li>
						<li className="cw_item" id="menu_profile"><a href="#" onClick={e => e.preventDefault()}>{i('Profile')}</a></li>
						<li className="cw_item" id="menu_advanced"><a href="#" onClick={e => e.preventDefault()}>{i('Advanced')}</a>
							<ul>
								<li className="cw_item" id="menu_clear_context"><a href="#" onClick={e => e.preventDefault()}>{i('Clear user settings')}</a></li>
							</ul>
						</li>
						<li className="cw_item" id="menu_logout"><a href="#" onClick={e => e.preventDefault()}>{i('Logout')}</a></li>
					</ul>

					<ul className="cw_user_notifications"></ul>

					<div className="cw_usertags_section hide">
						<fieldset className="cw_usertag">
							<legend>{i('User tags')}<span>{i('Active')}</span>
							</legend>
							<div className="cw_white_mask hide"></div>
							<div className="cw_ignore_tags right">
								<input type="checkbox" className="k-checkbox" id="cw_ignore_tags"/>
								<label htmlFor="cw_ignore_tags"
											 className="k-checkbox-label">{i('Ignore tags')}</label>
							</div>
							<div className="cw_show_untagged go_left">
								<input type="checkbox" className="k-checkbox" id="cw_show_untagged"/>
								<label htmlFor="cw_show_untagged"
								       className="k-checkbox-label">{i('Show Untagged')}</label>
							</div>
							<div className="left w100 cw_usertags_list"></div>
						</fieldset>
						<fieldset className="cw_teamtags">
							<legend>{i('Team tags')}<span>{i('Active')}</span>
							</legend>
							<div className="cw_white_mask hide"></div>
							<div className="left w100 cw_teamtags_list"></div>
						</fieldset>
						<div className="cw_actions">
							<button className="k-button right"
											id="cancel_tags">{i('Cancel')}</button>
							<button className="k-button k-primary right"
											id="save_tags">{i('Update')}</button>
						</div>
					</div>
				</div>
			</div>

			<div className="main_content row">
				<div className="aside">
					<div className="cw_logo_negative"></div>
					<div className="cw_multi_content">
						<div className="btn_group">
							<button className="cw_context_action pointer">{i('Create')}</button>
							<button className="cw_multi_caret">
								<span className="glyphicons play"></span>
							</button>
						</div>
						<div className="cw_multi_options clearfix">
							<div className="cw_multi_current">
								<ul>
									<li className="cw_item">Edit details</li>
									<li className="cw_item">Create Incident</li>
									<li className="cw_item">Create log entry</li>
									<li className="cw_item">Create document</li>
									<li className="cw_item">Create maintenance period</li>
								</ul>
							</div>
							<ul className="cw_multi_half">
								<li id="cw_multi_service" className="cw_item"><span
									className="glyphicons git-merge"></span>{i('Service')}</li>
								<li id="cw_multi_sla" className="cw_item"><span
									className="glyphicons record"></span>{i('SLA')}</li>
								<li id="cw_multi_account" className="cw_item"><span
									className="glyphicons book"></span>{i('Account')}</li>
								<li id="cw_multi_user" className="cw_item"><span
									className="glyphicons user"></span>{i('User')}</li>
								<li id="cw_multi_team" className="cw_item"><span
									className="glyphicons group create-team"></span>{i('Team')}</li>
								<li id="cw_multi_dashboard" className="cw_item"><span
									className="glyphicons picture"></span>{i('Dashboard')}</li>
								<li id="cw_multi_calendar" className="cw_item"><span
									className="glyphicons calendar"></span>{i('Calendar')}</li>
								<li id="cw_multi_application" className="cw_item"><span
									className="glyphicons artificial-intelligence"></span>{i('Application')}</li>
							</ul>
							<ul className="cw_multi_half">
								<li id="cw_multi_asset" className="cw_item"><span
									className="glyphicons file"></span>{i('Asset')}</li>
								<li id="cw_multi_asset_group" className="cw_item"><span
									className="glyphicons folder-open"></span>{i('Asset Group')}
								</li>
								<li id="cw_multi_auth" className="cw_item"><span
									className="glyphicons lock"></span>{i('Authentication')}</li>
								<li id="cw_multi_report" className="cw_item"><span
									className="glyphicons align-left"></span>{i('Report')}</li>
								<li id="cw_multi_automation" className="cw_item"><span
									className="glyphicons cogwheels"></span>{i('Automation')}</li>
								<li id="cw_multi_filter" className="cw_item"><span
									className="glyphicons filter"></span>{i('Filter')}</li>
								<li id="cw_multi_role" className="cw_item"><span
									className="glyphicons user-group"></span>{i('Role')}</li>
								<li id="cw_multi_cost" className="cw_item"><span
									className="glyphicons euro"></span>{i('Cost')}</li>
							</ul>
						</div>
					</div>

					<ul id="nav" className="cw_main_nav">
						<li id="service_summary" className="cw_item" title={i('Home')}>
							<Link url={SummaryRouter.home()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons home"></span>
								<span className="label">{i('Home')}</span>
							</Link>
						</li>
						<li id="service" className="cw_item" title={i('Service')}>
							<Link url={ServicesRouter.list()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons git-merge"></span>
								<span className="label">{i('Service')}</span>
							</Link>
						</li>
						<li id="nav_applications" className="cw_item" title={i('Application')}>
							<Link url={ApplicationsRouter.list()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons artificial-intelligence"></span>
								<span className="label">{i('Application')}</span>
							</Link>
						</li>
						<li id="asset_health" className="cw_item" title={i('Infrastructure')}
								data-state="closed">
							<span className="glyphicons heartbeat"></span>
							<span className="label">{i('Infrastructure')}</span>
						</li>
						<li id="slas" className="cw_item" title={i('SLA')}>
							<span className="glyphicons record"></span>
							<span className="label">{i('SLA')}</span>
						</li>
						<li id="nav_costs" className="cw_item" title={i('Cost')}>
							<a navigation-action="clear">
								<span className="glyphicons euro"></span>
								<span className="label">{i('Cost')}</span>
							</a>
						</li>
						<li id="board" className="cw_item" title={i('Dashboard')}>
							<a href="#/dashboards" navigation-action="clear">
								<span className="glyphicons dashboard"></span>
								<span className="label">{i('Dashboard')}</span>
							</a>
						</li>
						<li id="kpi" className="cw_item" title={i('KPI')}>
							<Link url={KpiRouter.list()} navigationAction="clear">
								<span className="glyphicons equalizer"></span>
								<span className="label">{i('KPI')}</span>
							</Link>
						</li>
						<li id="incidents" className="cw_item" title={i('Incident')}>
							<Link url={IncidentsRouter.list()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons construction-cone"></span>
								<span className="label">{i('Incident')}</span>
							</Link>
						</li>
						<li id="reports" className="cw_item" title={i('Report')}>
							<span className="glyphicons newspaper"></span>
							<span className="label">{i('Report')}</span>
						</li>
						<li id="automation" className="cw_item" title={i('Automation')}
								data-state="closed">
							<span className="glyphicons cogwheels"></span>
							<span className="label">{i('Automation')}</span>
						</li>
						<li id="administration" className="cw_item" title={i('Administration')}
								data-state="closed">
							<Link url={AdministrationRouter.listAccounts()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons show-thumbnails"></span>
								<span className="label">{i('Administration')}</span>
							</Link>
						</li>
						<li id="management" className="cw_item" title={i('Management')}
								data-state="closed">
							<span className="glyphicons settings"></span>
							<span className="label">{i('Management')}</span>
						</li>
						<li className="cw_favorites_menu_item cw_item">
							<span className="glyphicons star"></span>
							<span className="label">{i('Favorites')}</span>
						</li>
					</ul>
					<ul id="administration_submenu" className="cw_nav_secondary" data-parent="administration">
						<li className="nav_header" title={i('Administration')}>
							<span className="label">{i('Administration')}</span>
							<span className="glyphicons remove main"></span>
						</li>
						<li id="nav_administration_accounts" className="cw_item" title={i('Accounts')}>
							<Link url={AdministrationRouter.listAccounts()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons book"></span>
								<span className="label">{i('Accounts')}</span>
							</Link>
						</li>
						<li id="administration_authentications" className="cw_item" data-minimized="autohide">
							<span className="glyphicons keys"></span>
							<span className="label">{i('Authentications')}</span>
						</li>
						<li id="administration_calendar" className="cw_item" title={i('Calendar')}>
							<Link url={AdministrationRouter.listCalendars()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons calendar"></span>
								<span className="label">{i('Calendar')}</span>
							</Link>
						</li>
						<li id="administration_usercategories" className="cw_item" title={i('Roles')}>
							<Link url={AdministrationRouter.listRoles()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons user-group"></span>
								<span className="label">{i('Roles')}</span>
							</Link>
						</li>
						<li id="administration_templates" className="cw_item" data-state="closed">
							<span className="glyphicons file"></span>
							<span className="label">{i('Templates')}</span>
						</li>
						<li id="administration_teams" className="cw_item" title={i('Teams')}>
							<Link url={AdministrationRouter.listTeams()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons parents"></span>
								<span className="label">{i('Teams')}</span>
							</Link>
						</li>
						<li id="administration_users" className="cw_item" title={i('Users')}>
							<Link url={AdministrationRouter.listUsers()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons user"></span>
								<span className="label">{i('Users')}</span>
							</Link>
						</li>
						<li className="cw_favorites_menu_item cw_item">
							<span className="glyphicons star"></span>
							<span className="label">{i('Favorites')}</span>
						</li>
					</ul>

					<ul id="templates_submenu" className="cw_nav_secondary" data-parent="administration_templates">
						<li className="nav_header" title={i('Templates')}>
							<span className="label">{i('Templates')}</span>
							<span className="glyphicons arrow-left back"></span>
							<span className="glyphicons remove main"></span>
						</li>
						<li id="administration_servicemodel_templates" className="cw_item"
								data-minimized="autohide">
							<span className="glyphicons file"></span>
							<span className="label">{i('Service Model')}</span>
						</li>
						<li id="administration_serviceelement_templates" className="cw_item"
								data-minimized="autohide">
							<span className="glyphicons file"></span>
							<span className="label">{i('Service Element')}</span>
						</li>
						<li id="administration_servicequalifier_templates" className="cw_item"
								data-minimized="autohide">
							<span className="glyphicons file"></span>
							<span className="label">{i('Service Qualifier')}</span>
						</li>
						<li className="cw_favorites_menu_item cw_item">
							<span className="glyphicons star"></span>
							<span className="label">{i('Favorites')}</span>
						</li>
					</ul>

					<ul id="assethealth_submenu" className="cw_nav_secondary" data-parent="asset_health">
						<li className="nav_header" title={i('Infrastructure')}>
							<span className="label">{i('Infrastructure')}</span>
							<span className="glyphicons remove main"></span>
						</li>
						<li id="assethealth_summary" className="cw_item" title={i('Asset')}
								data-minimized="autohide">
							<span className="glyphicons heart"></span>
							<span className="label">{i('Asset')}</span>
						</li>
						<li id="assethealth_group_summary" className="cw_item"
						    title={i('Asset Group')}
								data-minimized="autohide">
							<span className="glyphicons folder-heart"></span>
							<span className="label">{i('Asset Group')}</span>
						</li>
						<li id="assethealth_reasons" className="cw_item" data-minimized="autohide">
							<span className="glyphicons align-left"></span>
							<span className="label">{i('Health Reason')}</span>
						</li>
						<li id="assethealth_monitors" className="cw_item" data-state="closed">
							<Link url={MonitorsRouter.list()} navigationAction={NavigationAction.Clear}>
								<span className="glyphicons cardio"></span>
								<span className="label">{i('Monitor')}</span>
							</Link>
						</li>
						<li id="assethealth_filters" className="cw_item" data-minimized="autohide">
							<span className="glyphicons filter"></span>
							<span className="label">{i('Filter')}</span>
						</li>
						<li className="cw_favorites_menu_item cw_item">
							<span className="glyphicons star"></span>
							<span className="label">{i('Favorites')}</span>
						</li>
					</ul>

					<ul id="monitors_submenu" className="cw_nav_secondary" data-parent="assethealth_monitors">
						<li className="nav_header" title={i('Monitors')}>
							<a href={'#' + MonitorsRouter.list()}>
							<span className="label">{i('Monitors')}</span>
							<span className="glyphicons arrow-left back"></span>
							<span className="glyphicons remove main"></span>
							</a>
						</li>
					</ul>

					<ul id="management_submenu" className="cw_nav_secondary" data-parent="management">
						<li className="nav_header" title={i('Management')}>
							<span className="label">{i('Management')}</span>
							<span className="glyphicons remove main"></span>
						</li>
						<li id="management_agents" className="cw_item" data-minimized="autohide">
							<span className="glyphicons macbook"></span>
							<span className="label">{i('Agents')}</span>
						</li>
						<li id="management_server" className="cw_item">
							<span className="glyphicons server"></span>
							<span className="label">Server</span>
							<ul>
								<li id="data_registry" className="cw_item" data-minimized="autohide">
									<div>
										<a href="#/data-registry" navigation-action="clear">
											<i data-type="icon" data-icon-pack="material" data-id="table_chart"
												 className="cw_item material-icons" style={{width: "34px"}}>tab</i>
											<span className="label">{i('Data Registry')}</span>
										</a>
									</div>
								</li>
								<li id="integration" className="cw_item" data-minimized="autohide">
									<span className="glyphicons disk-import"></span>
									<span className="label">{i('Integration')}</span>
								</li>
								<li id="management_repository" className="cw_item" data-minimized="autohide">
									<span className="glyphicons database"></span>
									<span className="label">{i('Repository')}</span>
								</li>
								<li id="management_modules" className="cw_item" data-minimized="autohide">
									<span className="glyphicons stop"></span>
									<span className="label">{i('Modules')}</span>
								</li>
							</ul>
						</li>
						<li id="management_audit" className="cw_item" data-minimized="autohide">
							<span className="glyphicons cogwheel"></span>
							<span className="label">{i('Audit')}</span>
						</li>
						<li id="management_users" className="cw_item" data-minimized="autohide">
							<span className="glyphicons user"></span>
							<span className="label">{i('Active Users')}</span>
						</li>
						<li id="management_system_errors" className="cw_item" data-minimized="autohide">
							<span className="glyphicons cogwheels"></span>
							<span className="label">{i('System Messages')}</span>
						</li>
						<li className="cw_favorites_menu_item cw_item">
							<span className="glyphicons star"></span>
							<span className="label">{i('Favorites')}</span>
						</li>
					</ul>

					<ul id="cost_submenu" className="cw_nav_secondary" data-parent="nav_costs">
						<li className="nav_header" title={i('Cost')}>
							<span className="label">{i('Cost')}</span>
							<span className="glyphicons remove main"></span>
						</li>
						<li id="cost-model" className="cw_item" data-minimized="autohide">
							<a href="#/cost-model" navigation-action="clear" type="model">
								<span className="glyphicons coins"></span>
								<span className="label">{i('Cost Model')}</span>
							</a>
						</li>
						<li id="cost-store" className="cw_item" data-minimized="autohide">
							<a href="#/cost-store" navigation-action="clear" type="store">
								<span className="glyphicons database"></span>
								<span className="label">{i('Cost Store')}</span>
							</a>
						</li>
						<li id="cost-gateway" className="cw_item" data-minimized="autohide">
							<a href="#/cost/gateways" navigation-action="clear">
								<span className="glyphicons database"></span>
								<span className="label">{i('Cost Gateway')}</span>
							</a>
						</li>
					</ul>
					<div className="cw_toggle_nav glyphicons chevron-left"></div>
				</div>

				<div className="cw_favorites_modules hide">
					<span className="cw_no_fav hide">No favorites</span>
					<ul className="cw_list cw_user_favs"></ul>
				</div>

				<div id="content_area"></div>
			</div>
			<div id="modal"></div>
			<div className="cw_status_message"></div>
		</>
	)
}
