import { AntCheckbox } from "controls/react/ant/antCheckbox";
import { FormEntry } from "controls/react/form";
import { Section } from "controls/react/layout";
import { translator } from "core/localization";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'core/react/links';
import { AntSelect } from 'controls/react/ant/antSelect';
import { TimePeriodType, TimePeriodSelector } from "controls/react/form/timePeriodSelector";

const i = translator({
	'Trend': {},
	'Calculation period': {},
	'None': {},
	'1h': {},
	'1d': {},
	'7d': {},
	'30d': {}
});

export const MetricTrendProperty = (props: {datasourceLink: Link<any>}) => {
	const showTrandLink = props.datasourceLink.get('datasourceLink');
	const metricTrendPeriod = props.datasourceLink.get('metricTrendPeriod');

	const periods = useMemo(() => {
		return [
			TimePeriodType.None,
			TimePeriodType.LastHour,
			TimePeriodType.LastDay,
			TimePeriodType.Last7Days,
			TimePeriodType.Last30Days
		]
	}, []);

	useEffect(() => {
		if (!metricTrendPeriod.value) {
			metricTrendPeriod.update({period: TimePeriodType.None, startDate: null, endDate: null});
		}
	}, []);

	return <>
		<Section title={i('Trend')}
				margin="top"
				appearance="frame-top-only"
				childrenPadding={true}>
			<FormEntry>
				<AntCheckbox valueLink={showTrandLink}>{i('Enabled')}</AntCheckbox>
			</FormEntry>
			{showTrandLink.value && <FormEntry label={i('Calculation period')}>
				<TimePeriodSelector periods={periods} {...metricTrendPeriod.props}/>
			</FormEntry>}
		</Section>
	</>
}
