import React, {useState, useMemo, useRef, useEffect} from "react";
import PropTypes from "prop-types";

import {DropDownList} from "controls/react/kendoWrappers/dropDownList";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {AuthenticationAPI} from "tools/apis/authenticationAPI";
import {ErrorHandler} from "core/errorHandler";
import {AddAuthentication} from "controls/react/windows/addAuthentication";
import {inputWithAction} from 'controls/react/form/common';
import {translator} from "core/localization";
import withRequired from 'controls/react/dropdowns/withRequired';

const i = translator({
  "Select Authentication...": {
    "no": "Velg autentisering...",
    "en": "Select authentication..."
  },
  "NONE": {
    "no": "INGEN"
  }
});

export const AuthenticationDropDown = props => {
	const authTypes = useMemo(() => {
		const result = JSON.parse(JSON.stringify(props.authTypes));
		let snmpIndex = result.findIndex(x => x == 'SNMP');
		if (snmpIndex != -1) {
			result.splice(snmpIndex, 1, 'SNMPv1', 'SNMPv2c', 'SNMPv3');
		}
		return result;
	}, [props.authTypes.join()]);

	const [authValue, setAuthValue] = useState(props.value === null ? '' : props.value);

	useEffect(() => {
		setAuthValue(props.value);
	}, [props.value]);

	const dataSource = useMemo(() => {
		return new CeeViewDataSource({
			transport: {
				read: {
					url: AuthenticationAPI.getUrl(props.accountId, authTypes),
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			schema: {
				parse: function (result) {
					if (props.authToBeRemoved) {
						for (let i = 0; i < result.length; i++) {
							if (result[i].id === props.authToBeRemoved) {
								result.splice(i, 1);
							}
						}
					}

					if (props.noneOption) {
						result.unshift({id: "", name: i("NONE")})
					}

					return result;
				}
			},
			error: ErrorHandler.kendoServerError
		});
	}, [props.authTypes.join()]);

	const kendoRef = useRef();
	return <div className={inputWithAction()}>
		<DropDownList dataSource={dataSource}
		              dataTextField={'name'}
		              dataValueField={'id'}
		              suggest
		              autoBind
		              onDataBound={props.onDataBound}
		              onChange={(value, e) => {
			              setAuthValue(value)
			              props.onChange(value, {e})
		              }}

		              placeholder={i('Select Authentication...')}
		              kendoRef={ref => kendoRef.current = ref}
		              containerClass={inputWithAction('input')}
		              value={authValue}
		              combo={true}
		/>

		<AddAuthentication authTypes={authTypes}
		                   containerClass={inputWithAction('action')}
		                   newAuthModalWindowId={props.newAuthModalWindowId}
		                   onCreated={(auth) => {
			                   kendoRef.current.dataSource.read();
			                   setAuthValue(auth.id)
			                   props.onChange(auth.id)
		                   }}
		/>
	</div>

}

AuthenticationDropDown.propTypes = {
	authTypes: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func,
	onDataBound: PropTypes.func,
	value: PropTypes.any,
	noneOption: PropTypes.bool
}

AuthenticationDropDown.defaultProps = {
	authTypes: [],
	noneOption: false
}

export default withRequired(AuthenticationDropDown);
