import {Cookies, ErrorHandler} from "core";
import Utils from "tools/utils";
import Settings from "settings";
import ModalWindow from 'controls/modalWindow';
import {apiFetch} from "framework/api";
import {assignLegacy} from "areas/summary/events/api";

export let AssignReasonsWindow = function (config) {
	Utils.apply(this, config);
	this.initComponents();
};

export default AssignReasonsWindow;

AssignReasonsWindow.prototype = {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the control is rendered
	 */
	renderTo: null,

	initComponents: function () {
		this.assignWindow = new ModalWindow({
			title: lang.ASSIGN_EVENT,
			width: 400,
			resizable: true,
			minHeight: 200,
			height: 200,
			position: this.position,
			modal: true,
			actions: [],
			url: 'include/Summary/AssignEventForm.jsp',
			refresh: $.proxy(function () {
				this.onAssignWindowLoaded();
				$('#cw_assign_event_close').on('click', $.proxy(this.onAssignWindowClose, this));
				$('#cw_assign_event_update').on('click', $.proxy(this.onAssignWindowUpdate, this));
			}, this)
		});
		this.assignWindow.open();
	},
	/**
	 * Handler function for actions required to be performed when the assigned window was loaded
	 */
	onAssignWindowLoaded: function () {
		let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/recipients';
		this.assignSelector = $('#cw_assign_event_selector').kendoDropDownList({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: url,
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					data: function (response) {
						let result, userTeamArray = [];
						if (response.success) {
							result = response.data;
						} else if (response.length) {
							result = response;
						} else {
							result = [];
						}
						for (let i = 0, length = result.length; i < length; i++) {
							result[i] = {
								text: result[i].name,
								value: result[i].id,
								type: result[i].type
							};
							switch (result[i].type) {
								case 'TEAM':
									result[i].optionGlyphClass = 'parents';
									userTeamArray.push(result[i]);
									break;
								case 'USER':
									result[i].optionGlyphClass = 'user';
									userTeamArray.push(result[i]);
									break;
								case 'EMAIL':
									break;
							}
						}
						return userTeamArray;
					}
				},
				group: {
					field: 'type'
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: ' ',
			optionLabelTemplate: '<span id="cw_assign_placeholder">User/team...</span>',
			valueTemplate: '<span class="glyphicons ${optionGlyphClass}" style="padding-top: 3px"></span><span style="padding-left: 10px">${text}</span>',
			open: $.proxy(function () {
				//overwrite kendo groupable questionable functionality (show different first group; moreover, show groupable row when no data)
				var recipientsOpenedContainer = $('#cw_assign_event_selector-list');
				//hide option label from selection area
				recipientsOpenedContainer.find('.k-list-optionlabel').addClass('hide');
				var recipientsGroupHeader = recipientsOpenedContainer.find('.k-group-header');
				var recipientsNoDataContainer = recipientsOpenedContainer.find('.k-nodata');
				if (recipientsNoDataContainer.attr('style')) {//check for style display:none property put by kendo
					//case when multiselect has data; transform the grouping header row into a normal grouping element
					recipientsGroupHeader.addClass('hide');
					var firstItem = recipientsOpenedContainer.find('li').first();
					firstItem.append('<div class="k-group">' + recipientsGroupHeader.html() + '</div>');
				} else {
					//case when multiselect has no data; hide the grouping header, fix for poor kendo functionality
					recipientsGroupHeader.addClass('hide');
				}
			}, this),
			dataBound: function () {
				$('#cw_assign_placeholder').css('color', '#b8b8b8');
			}
		}).data('kendoDropDownList');
	},
	/**
	 * Handler function called when closing the assigned window
	 */
	onAssignWindowClose: function () {
		this.assignWindow.close();
	},
	/**
	 * Handler function called when completing the assigned form
	 */
	async onAssignWindowUpdate () {
		const selectedRecipient = this.assignSelector.value();
		const allRecipients = this.assignSelector.dataSource.data();
		const recipient = allRecipients.find( x => x.value == selectedRecipient);
		const result = await apiFetch(assignLegacy({
			selection: {
				mode: 'INCLUDE',
				ids: this.selectedItems,
			},
			targetId: recipient.value,
			type: recipient.type
		}));

		if (result.success) {
			if (this.actionNotification) {
				this.actionNotification.setOptions({
					message: lang.messages.EVENT_SUCCESSFULLY_ASSIGNED,
					status: 'success'
				}).show();
			}
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}
		this.assignWindow.close();
		if (this.onAssigned) {
			this.onAssigned();
		}
	},
};
