import './antSwitch.less'

import React, {ChangeEventHandler} from "react"
import { observer } from 'mobx-react'
import {Switch, SwitchProps} from 'antd'

import {addEnterEscHandlers, captureValueLink, ChangeEventHandlerWrapped, wrapOnChange} from "controls/react/ant/utils";


interface AntSwitchProps extends SwitchProps{ //extends Omit<InputProps, 'onChange'>{
	// valueLink?: any,
	// invalid?: boolean,
	// errors?: Array<any>,
	// onChange?: ChangeEventHandlerWrapped<HTMLInputElement>
	// ref?: any,
	// onPressEnter?: () => void,
	// onPressEsc?: () => void,
	value?: boolean
}

const b = require('b_').with('ant-switch-wrapper')

export const AntSwitch = observer( (props: AntSwitchProps) => {
	let {value, ...antNativeProps} = props
	if(value !== undefined){
		antNativeProps.checked = value
	}

	//const wrappedPropes = wrapOnChange(antNativeProps);

	//addEnterEscHandlers(wrappedPropes);

//	return <Input ref={ref} {...wrappedPropes}/>

	return <Switch className={b()} {...antNativeProps}/>
})
