import {makeAutoObservable, toJS, trace} from "mobx"
import {ListOnItemsRenderedProps, VariableSizeList} from "react-window"
import React from "react"
import {deserialize, serialize} from "serializr"
import {debounce} from "lodash";

import {linkModel, MobxManager} from "framework/mobx-integration"
import {GridState} from "controls/grid/gridState"
import {GridConfiguration, GridPlugin} from "controls/grid/gridConfiguration"
import {GridSelection} from "controls/grid/gridSelection"
import {GridDataItem} from "controls/grid/gridDataItem"
import {ColumnsManager} from "controls/grid/columnsManager"
import {AutoWidthPlugin} from "controls/grid/plugins/autoWidthPlugin"
import {GroupConjunction, RuleDefinition, RuleDefinitionType} from "controls/queryBuilder/ruleDefinition"
import {canApplyStringFilter} from "controls/queryBuilder/utils"
import {RemoteDataProvider} from "controls/grid/remoteDataProvider"
import {ArrayDataProvider} from "controls/grid/arrayDataProvider"
import {GridStateProvider} from "controls/grid/stateProviders"
import {copyViaSerializr} from "framework/serializr-integration"
import {GridViewState} from "controls/grid/gridViewState"


export class GridStore<DataItem extends GridDataItem> {
	config: GridConfiguration<DataItem>

	width: number

	state: GridState<DataItem>

	selection = new GridSelection(this)

	plugins: GridPlugin<DataItem>[] = []

	headerRef = React.createRef<HTMLDivElement>()

	columns: ColumnsManager<DataItem>

	initializationCallbacks: (() => boolean)[] = []

	selfInitialized: boolean = false

	listControl: VariableSizeList<GridStore<DataItem>>
	rowsCustomHeight: Record<string, number> = {}

	//this property is used to destroy layout when <Grid/> component receives a new store
	initialRenderHappened: boolean = false

	private stateProvider: GridStateProvider<DataItem>

	mobx = new MobxManager()

	constructor(config: GridConfiguration<DataItem>) {
		this.config = config

		makeAutoObservable(this, {})

		this.init()
	}

	async init() {
		this.columns = new ColumnsManager<DataItem>(this)

		await this.initState()

		this.plugins = this.config.plugins ? [...this.config.plugins] : []
		this.plugins.push(new AutoWidthPlugin())

		await this.dataProvider.attach(this)

		this.plugins.forEach(x => x.attach(this))

		this.selfInitialized = true
	}

	_height: number
	get height() {
		if(this.config.heightByContent !== true)
			return this._height

		const customHeights = Object.values(this.rowsCustomHeight)
		return (this.dataProvider.data.length - customHeights.length) * 30
			+ customHeights.reduce((total, height) => total + (height == -1 ? 30 : height), 0)
	}

	set height(value: number) {
		this._height = value
	}

	get dataProvider() {
		return this.config.dataProvider
	}

	get arrayDataProvider() {
		return this.dataProvider as ArrayDataProvider<DataItem>
	}

	get remoteDataProvider() {
		return this.dataProvider as RemoteDataProvider<DataItem>
	}

	get initialized() {
		return this.selfInitialized && this.initializationCallbacks.every(x => x())
	}

	get filtered() {
		return !!this.state?.searchString || Object.keys(this.state?.filters.children1 ?? {}).length > 0
	}

	get filtersConfigurationEffective() {
		let result = toJS(this.dataProvider.filtersConfiguration)

		this.columns.config.forEach(x => {
			if (x.filterDropDownRenderer && result[x.field]) {
				result[x.field].customMultiSelectRenderer = x.filterDropDownRenderer
			}
		})
		return result
	}

	get customFiltering() {
		const f = this.state.filters

		const fieldsCount: Record<string, boolean> = {}

		return Object.values(f.children1).some(x => {
			if (!x.properties.field)
				return true

			if (x.type == RuleDefinitionType.Group)
				return true

			if (fieldsCount[x.properties.field]) {
				return true
			}

			fieldsCount[x.properties.field] = true
		})
	}

	async loadState() {
		for (const stateProvider of this.config.stateProviders ?? []) {
			const state = await stateProvider.getState(this)

			if (state) {
				state.validate(this)
				this.stateProvider = stateProvider
				return state
			}
		}

		return null
	}

	async initState(){
		this.state = await this.loadState()

		if (!this.state) {
			this.state = new GridState<DataItem>()
			this.state.ensureDefaultViewExists()
			this.state.views[0].resetToDefault(this)
		}

		this.state.createViewsSnapshot()

		this.mobx.reaction(() => serialize(GridViewState<DataItem>, this.state.defaultView), this.flushCurrentViewChangesDebounced )
		this.mobx.reaction(() => ({
				initialViewId: this.state.initialViewId,
				deletedViewCount: this.state.deletedViews.length
			}),
			this.flushOtherChangesDebounced
		)
	}

	clearFilters = () => {
		this.state.searchString = ''
		this.state.filters = RuleDefinition.emptyGroup()
		this.config.onFiltersCleared?.(this)
	}

	itemsRendered = (props: ListOnItemsRenderedProps) => {
		this.dataProvider.informVisibleRangeChanged(props.overscanStartIndex, props.overscanStopIndex)
	}

	get actualFilter() {
		let filters = this.state.filters

		if (this.state.searchString) {
			filters = deserialize(RuleDefinition, serialize(filters))

			let searchStringGroup = filters.addEmptyGroup()
			searchStringGroup.properties.conjunction = GroupConjunction.Or

			for (let field of Object.keys(this.filtersConfigurationEffective)) {
				if (!canApplyStringFilter(this.filtersConfigurationEffective, field))
					continue

				let rule = searchStringGroup.addEmptyRule()
				rule.properties.value[0] = this.state.searchString
				rule.properties.field = field
				rule.properties.operator = 'like'
			}
		}

		return filters
	}

	get actualSorting() {
		return this.state.sortingOrder
			.map(field => this.state.columns.find(c => c.field == field))
			.filter(x => x?.sorting != null)
			.map(x => ({
				field: x.field,
				dir: x.sorting
			}))
	}

	listScrolled = (e: React.UIEvent<HTMLDivElement>) => {
		const {scrollLeft} = e.currentTarget

		if (this.headerRef.current) {
			this.headerRef.current.style.marginLeft = -scrollLeft + 'px'
		}
	}

	getSelectionApiRequest<T>(args: { url: string, payload?: Record<string, any> }) {
		if (!(this.dataProvider instanceof RemoteDataProvider)) {
			console.warn('Selection api request works only with Remote data provider')
			return
		}

		let request = this.dataProvider.getBaseApiRequest<T>()
		request.payload.selection = {
			mode: this.selection.mode,
			ids: this.selection.ids
		}

		delete request.responseType
		delete request.payload.sort

		request.url = args.url

		if (args.payload) {
			Object.assign(request.payload, args.payload)
		}

		return request
	}

	registerInitializationDoneSource(done: () => boolean) {
		this.initializationCallbacks.push(done)
	}

	updateRowHeight(item: DataItem, index: number, element: HTMLDivElement) {
		if (this.rowsCustomHeight[item.id] != -1)
			return

		this.rowsCustomHeight[item.id] = element.getBoundingClientRect().height
		this.listControl?.resetAfterIndex(index)
	}

	getRowHeight(index: number) {
		const item = this.dataProvider.get(index)

		if (item && this.rowsCustomHeight[item.id]) {
			const height = this.rowsCustomHeight[item.id]
			return height == -1 || !height ? 30 : height //when we click on a row we remove height constraint and put -1 as a custom vaule.
			//then on a resize event we put a real row height there and use it later. But it might be that a real height row == 30 so resize event
			//is not triggered and value remains -1. So on the next rerender the list component will calculate wrong offset
		}
		return 30
	}

	flushCurrentViewChanges = async () => {
		await this.flushStateChanges((actualState) => {
			const view = copyViaSerializr<GridViewState<DataItem>>(this.state.currentView)
			const index = actualState.views.findIndex(x => x.id == view.id)
			if (index == -1) {
				actualState.views.push(view)
			} else {
				actualState.views[index] = view
			}

			actualState.initialViewId = this.state.initialViewId

			this.state.currentView.calculateHash()
		})
	}

	flushCurrentViewChangesDebounced = debounce(this.flushCurrentViewChanges, 1000)

	flushOtherChanges = async () => {
		await this.flushStateChanges((actualState) => {
			actualState.initialViewId = this.state.currentViewId
		})
	}

	flushOtherChangesDebounced = debounce(this.flushOtherChanges, 1000)
	flushStateChanges = async (callback: (state: GridState<DataItem>) => void) => {
		if(!this.stateProvider)
			return

		let actualState = await this.stateProvider.getState(this)

		this.state.deletedViews.forEach(id => {
			let index = actualState.views.findIndex(v => v.id == id)
			if(index != -1){
				actualState.views.splice(index, 1)
			}
		})

		await callback(actualState)

		await this.stateProvider.saveState(actualState, this)
	}

	isSelectionDisabled(item: DataItem): {disabled: boolean, reason?: string} {
		let callbackResult = this.config.customization?.isSelectionDisabled(item, this)
		if(!callbackResult){
			return {
				disabled: false
			}
		}

		if(callbackResult === true){
			return {
				disabled: callbackResult
			}
		}

		return callbackResult
	}

	destroy() {
		this.mobx.destroy()
		this.plugins.forEach(x => x.destroy && x.destroy())
		this.state?.destroy()
		this.dataProvider?.destroy()
		this.columns?.destroy()
	}
}

export type ApiRequestPayload = {
	[p: string]: any,
	filter: RuleDefinition,
	sort: {field: string, dir: "asc" | "desc"}[]
}

export function linkGridAdditionalPayload(holder: GridStoreHolder<any>, name: string){
	const store = getStore(holder)
	return linkModel(store.state.customPayload, name)
}

export type GridStoreHolder<T extends GridDataItem> = GridStore<T> | {gridStore: GridStore<T>}
export function getStore<T extends GridDataItem>(holder: GridStoreHolder<T>) {
	if (holder == null)
		return null

	return 'gridStore' in holder ? holder.gridStore : holder
}
