import './userTags.less';
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {UserTagsContext, UserTagsStore} from './userTagsStore';
import {QuestionCircleOutlined, TagsOutlined} from '@ant-design/icons';
import {AntModal} from 'controls/react/ant/antModal';
import {Toolbar, ToolbarItemPosition} from 'controls/react/layout/toolbar';
import {FormEntry} from 'controls/react/form';
import {Section} from 'controls/react/layout';
import {AntButton} from 'controls/react/ant/antButton';
import {translator} from 'core/localization';
import {AntSwitch} from 'controls/react/ant/antSwitch';
import {AntPopover} from 'controls/react/ant/antPopover';
import {AntAlert} from 'controls/react/ant/antAlert';
import {AntCheckbox} from 'controls/react/ant/antCheckbox';
import {AntSelect} from 'controls/react/ant/antSelect';
import {useRef} from 'react';
import {ApplicationState} from "framework/applicationState";

const i = translator({
	'ON': {no: 'ON'},
	'OFF': {no: 'OFF'},
	'MODAL_INFO_ROW': {
		en: 'Tags are used to filter content in Ceeview.\r\nUser tags are set per user and is controlled by that user.\r\nTeam tags are set per team. If user has checked Force Team Tags the tags will be applied on login.\r\nUser can’t change Tags from this form. It is changed from Team or by removing Force Team tags.',
		no: 'Etiketter brukes til å filtrere innholdet i Ceeview.\r\nBrukeretiketter settes per bruker og kontrolleres av brukeren.\r\nTeametiketter settes per Team. Hvis brukeren har Påtving Teametiketter valgt, vil de bli brukt ved pålogging. Brukeren kan ikke endre Teametiketter fra dette vinduet. Må gjøres via teams eller ved å fjerne Påtving Teametiketter.'
	},
	'TEAM_ALERT': {
		en: 'Tags are inherited from user`s teams and override User Tags.',
		no: 'Etiketter arves fra brukerens team og overstyrer bruker etiketter.'
	},
	'Include Untagged': {no: 'Vis uten etikett'},
	'Tags': {
		no: 'Etiketter'
	},
	'Team Tags': { no: 'Team Etikett'}
});

const b = require('b_').with('user-tags');

export const UserTags = observer(() => {
	const storeRef = useRef(null);
	const [modalLeft, setModalLeft] = useState(0);

	useEffect(() => {
		storeRef.current = new UserTagsStore();
		const {clientWidth} = window.document.documentElement;
		setModalLeft((clientWidth - 320) - clientWidth/2);
		storeRef.current.init();
	}, []);

	if(storeRef.current == null)
		return null;

	return <UserTagsContext.Provider value={storeRef.current}>
			<div className={b('icon-wrapper')} onClick={storeRef.current.onWindowToggle} title={i('Tags')}>
				<TagsOutlined className={b('icon', {active: ApplicationState.tagsEnabled})} />
			</div>
			<AntModal
				title={<ModalTitle />}
				visible={storeRef.current.displayWindow}
				closable={true}
				destroyOnClose={true}
				footer={false}
				width={300}
				style={{ top: 60, left: modalLeft }}
				onCancel={storeRef.current.onCancel}
				className={b('modal')}>
				<PopoverContent />
			</AntModal>
		</UserTagsContext.Provider>
});

const PopoverContent = observer(() => {
	const context = React.useContext(UserTagsContext);
	return <Section footer={true} direction={'column'}>
			{context.mode == 'user' && context.model && <UserTagsContent />}
			{context.mode == 'team' && context.model && <TeamTagsContent />}
			{context.mode == 'user' && context.model && <Toolbar appearance="transparent" containerClass={b('modal-footer')}>
				<FormEntry position={ToolbarItemPosition.AT_THE_END}>
					<AntButton type={"primary"} onClick={context.onOk}>{i('Update')}</AntButton>
				</FormEntry>
				<FormEntry position={ToolbarItemPosition.AT_THE_END}>
					<AntButton onClick={context.onCancel}>{i('Cancel')}</AntButton>
				</FormEntry>
			</Toolbar>}
		</Section>
})

const ModalTitle = observer(() => {
	const context = React.useContext(UserTagsContext);
	return <Toolbar appearance="transparent" containerClass={b('modal-header')} title={context.mode == 'user' ? i('Tags') : i('Team Tags')}>
		{context.mode == 'user' && <AntSwitch checkedChildren={i('ON')} unCheckedChildren={i('OFF')} value={context.active} size="small" onChange={context.changeActive} />}
		<AntPopover content={<Section><PopupInfoText /></Section>}  placement="bottom">
			<QuestionCircleOutlined />
		</AntPopover>
	</Toolbar>
})

const PopupInfoText = () => {
	return <>{i('MODAL_INFO_ROW').split('\r\n').map((x: string, index: number) => <span key={index}>{x}</span>)}</>;
}

const UserTagsContent = observer(() => {
	const context = React.useContext(UserTagsContext);
	return <Section direction="column" appearance="none" childrenPadding={true}>
		<FormEntry>
			<AntSelect
				options={context.tagList}
				disabled={!context.active}
				mode="multiple"
				value={context.model.tags}
				onChange={context.onUserTagsChanged}
				nameField={'label'}
				valueField={'value'}
				filterOption={(input: string, option: {label: string, value: string}) => {
						return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
				  }}></AntSelect>
		</FormEntry>
		<FormEntry>
			<AntCheckbox disabled={!context.active} value={context.model.showUntagged} onChange={context.onChangeUntagged}>{i('Include Untagged')}</AntCheckbox>
		</FormEntry>
	</Section>
})

const TeamTagsContent = observer(() => {
	const context = React.useContext(UserTagsContext);
	return <AntAlert message={i('TEAM_ALERT')} type="info" />
})
