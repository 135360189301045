import {UrlBuilder} from './../../tools/urlBuilder';

export function getDescriptions(){
	return [{
		url: '/cost-:type',
		getModuleImport: () => import('./list'),
		react: true,
		routeId: 'cost_list'
	}, {
		url: '/costs/:id',
		getModuleImport: () => import('./costProfileDetails'),
		react: true,
		routeId: 'cost_model'
	}, {
		url: '/costs-by-profile/:profileId',
		getModuleImport: () => import('./costProfileDetails'),
		react: true,
		routeId: 'cost_profile'
	}, {
		url: '/cost/gateways',
		getModuleImport: () => import('./gateway/costGatewayList'),
		react: true,
		routeId: 'cost_gateway'
	}, {
		url: '/cost/gateway/:id',
		getModuleImport: () => import('./gateway/costGatewayDetails'),
		react: true,
		routeId: 'cost_gateway_details'
	}];
}

export class CostsRouter {
	static list() {
		return '/costs'
	}

	static listByType(type) {
		return '/' + type;
	}

	static details(costId, expandToRecord, recordModelId) {
		return new UrlBuilder(`/costs/${costId}`)
			.add('expandToRecord', expandToRecord, !!expandToRecord)
			.add('recordModelId', recordModelId, !!recordModelId)
			.build();
	}

	static detailsByProfile(profileId, expandToRecord) {
		return new UrlBuilder(`/costs-by-profile/${profileId}`)
			.add('expandToRecord', expandToRecord, !!expandToRecord)
			.build();
	}

	static gatewayList() {
		return '/cost/gateways'
	}

	static gatewayDetails(id) {
		return `/cost/gateway/${id}`
	}

	static gatewayCreate() {
		return CostsRouter.gatewayDetails('new')
	}
}
