import React from 'react';

import {Api} from "tools/api";
import {ApiRequest} from "../../framework/api";

export class PresentationApi {

	static baseUrl(accountId, sessionId) {
		return Api.sessionAndAccountRoot(accountId, sessionId) + 'dashboards';
	}

	static list(accountId) {
		return Api.fetch(PresentationApi.baseUrl(accountId));
	}

	static read(id, accountId, sessionId) {
		return Api.fetch(PresentationApi.baseUrl(accountId, sessionId) + "/" + id);
	}

	static save(presentation, accountId) {
		return Api.fetchPost(PresentationApi.baseUrl(accountId), presentation);
	}

	static delete(ids, accountId) {
		return Api.fetchPost(PresentationApi.baseUrl(accountId) + "/delete", ids);
	}

	static getDatasourceName(dataSource) {
		return Api.fetchPost(PresentationApi.root(dataSource.accountId) + "/dataSourceName", dataSource);
	}

	static getSearchUrl() {
		return Api.accountRoot() + 'dashboards/lite?name=';
	}
}

export function useDashboards(accountId){
	const [list, setList] = React.useState([]);

	React.useEffect(() => {
		PresentationApi.list(accountId)
			.then(response => {
				if( response.success !== false){
					setList(response)
				}
			})
	}, [accountId])

	return list;
}

export function getDashboardsLite(accountId, includeSubaccounts = true) {
	return new ApiRequest({
		url: `dashboards/lite`,
		accountId,
		includeSubaccounts,
		accountBased: true
	})
}
