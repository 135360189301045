import './boxView.less'

import React from 'react';
import classes from 'classnames';
import PropTypes from 'prop-types';


let b = require('b_').with('box-view');

export interface BoxViewPops{
	type: "info" | "info-2" | "warning" | "warning-error" | "error";
	rounded?: boolean;
	border?: boolean;
	children: React.ReactNode;
	containerClass?: string;
	onClick?: () => void;
	title?: string;
	margin?: 'vertical' | 'horizontal' | 'both' | 'none';
	contentRef?: React.Ref<HTMLDivElement>
}

export const BoxView = (props:BoxViewPops) => {
	const blockClasses = b({
		type: props.type,
		clickable: props.onClick != null,
		border: props.border,
		rounded: props.rounded,
		margin: props.margin
	});

	return (
		<div className={classes(blockClasses, props.containerClass)}
		     onClick={e => props.onClick && props.onClick()}
			 title={props.title}
			 style={props.style}
			 ref={props.contentRef}>
			{props.children}
		</div>
	);
};

BoxView.propTypes = {

}

BoxView.defaultProps = {
	rounded: false,
	border: false,
	type: 'info',
	margin: 'none'
}
