import React from "react"
import {observer} from "mobx-react"

import {GridDataItem} from "controls/grid/gridDataItem"
import {ViewManagerProps} from "controls/grid/viewManager/viewManagerStore"
import {QueryBuilder} from "controls/queryBuilder/queryBuilder"


export const FiltersEditor = observer(<DataItem extends GridDataItem>(props: ViewManagerProps<DataItem>) => {
	const gridStore = props.store.gridStore

	return <QueryBuilder rule={gridStore.state.filters}
	                     config={gridStore.filtersConfigurationEffective}/>
})
