import React from "react"

import {Store} from "./core/react/store"
import {State} from "./tools/state"
import {getUrlBuilderForCurrentHash} from "tools/urlBuilder"
import {getLastEntry} from "tools/helpers/array"
import {NavigationAction, NavigationStore} from "framework/navigationStore"


export const AppContext = React.createContext();

export class AppStore extends Store {
	constructor(props) {
		super(props, AppContext);

		let context = {};
		this.setInitialContext(context);
	}
}
