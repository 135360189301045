import {RedirectType} from "controls/designer/features/redirectOnClick/redirectType";
import {RedirectModifier, RedirectPopup} from "tools/ceeviewNavigator";

export type RedirectConfig = {
	type: RedirectType
	modifier?: RedirectModifier
	link?: string
	accountId?: string
	dashboardId?: string
	popup: RedirectPopup
}


export const defaultRedirectConfig = {
	type: RedirectType.Default,
	modifier: RedirectModifier.None
}

export const noRedirectConfig = {
	type: RedirectType.None
}

export function convertLegacyConfig(config: RedirectConfig & {linkInNewTab?: boolean}) {
	let result = {...config}

	if (result.modifier == null) {
		result.modifier = config.linkInNewTab
			? RedirectModifier.NewTab
			: RedirectModifier.None
		delete result.linkInNewTab
	}
	return result
}
