import React from "react"
import {translator} from "core";
import {Button, TextArea} from "controls/react/form";
import {Window, Section, Toolbar, ToolbarItemPosition} from 'controls/react/layout';

let i = translator({
  "Reset health index placeholder": {
    "en": "Type in the reason for resetting the healthindex. This will reset the health index of all health reasons for this monitor",
    "no": "Skriv inn helseårsak for tilbakestilling av helseindeks. Dette vil resette helse for alle årsåker fra denne monitoren"
  },
  "Reset health index": {
    "no": "Tilbakestill helseindeks",
    "en": "Reset Health Index"
  }
});

export class ResetHealthIndexWindow extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			resetReason: ''
		};
	}

	render() {
		return (
			<Window height={230}
					width={500}
					minHeight={230}
					modal
					title={i('Reset health index')}
					ref={window => this.window = window}
					onClose={this.props.onClose}
					top={this.props.top}
					left={this.props.left}>
				<TextArea containerClass='cw_reset_health_index'
						  placeholder={i('Reset health index placeholder')}
						  value={this.state.resetReason}
						  onChange={(value) => this.onResetReasonChange(value)}
				/>
				<Toolbar>
					<Button title={i('Reset')}
							primary={true}
							onClick={() => this.onReset()}
							position={ToolbarItemPosition.AT_THE_END} />
					<Button title={i('Cancel')}
							primary={false}
							onClick={() => this.props.onClose()}
							position={ToolbarItemPosition.AT_THE_END} />
				</Toolbar>
			</Window>
		)
	}

	onResetReasonChange(value) {
		this.setState({
			resetReason: value
		})
	}

	onReset() {
		this.props.onReset(this.state.resetReason);
	}
}

