import Application from 'core/application';
import CustomNotification from 'controls/customNotification';
import GridMenu from 'controls/gridMenu';
import PreferencesPanel from 'controls/preferencesPanel';
import Settings from 'settings';
import Cookies from 'core/cookies';

import UserPrefs from 'tools/userPrefs';

/**
 * @class ceeview.management.RepositoryView
 * This is the View class for the repository view
 * @namespace ceeview.management
 * @extends Application
 * @constructor
 * @param {Object} config The config object
 */
export default function RepositoryView (config) {
    Utils.apply(this, config);
    this.module = {
        name: 'ManagementRepository',
        initialConfig: config
    };

    this.initComponent();
};

jQuery.extend(RepositoryView.prototype, Application.prototype, {
    /**
     * Main init function
     */
    initComponent: function () {
        Application.prototype.initComponent.call(this);


        this.showGridView = true;
        this.actionNotification = new CustomNotification({
            appendToElement: '#cw_repository_list',
        });

        this.removeListeners();
        this.attachListeners();
        this.loadUserPreferences();
        this.removeMask();
    },
    initKendoComponents: function () {
        var repoFilter, repoSort, filterMessages = lang.grid.filter, url;
        var defaultFsView = UserPrefs.get('defaultFsView');

        this.defaultColumns = {
            id: {
                hidden: false,
                width: 40
            },
            presentationName: {
                hidden: false,
                width: 350
            },
            version: {
                hidden: false
            }
        };
        this.gridMessages = {
            clear: lang.CLEAR,
            info: lang.grid.filter.SHOW_ITEMS,
            filter: lang.FILTER
        };

        if (Utils.isGuid(defaultFsView)) {
            var pref = JSON.parse(UserPrefs.get(defaultFsView));
            if (pref) {
                repoSort = pref.sort;
                repoFilter = pref.filter;
                this.repoColumns = pref.columns;
            } else {
                repoSort = [];
                repoFilter = [];
            }

        } else {
            if (UserPrefs.get('repoSort')) {
                repoSort = JSON.parse(UserPrefs.get('repoSort'));
            } else {
                repoSort = [{
                    field: 'presentationName',
                    dir: 'asc',
                    compare: null
                }];
            }
            if (UserPrefs.get('repoFilter')) {
                repoFilter = JSON.parse(UserPrefs.get('repoFilter'));
            } else {
                repoFilter = [];
            }
        }
        var repoColumns = this.repoColumns || JSON.parse(UserPrefs.get('repoColumns')) || this.defaultColumns;
        repoColumns = Utils.completeColumns(repoColumns, this.defaultColumns);
        url = Settings.serverPath + 'agents/bundles';

        this.repoDataSource = new kendo.ceeview.DataSource({
            transport: {
                read: {
                    url: url,
                    dataType: 'json',
                    contentType: 'application/json; charset=utf-8',
                    type: 'GET',
                    cache: false
                }
            },
            sort: repoSort,
            filter: repoFilter,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        presentationName: {
                            type: 'string'
                        },
                        version: {
                            type: 'string'
                        }
                    }
                },
                parse: function (data) {
                    for (var i = 0, length = data.length; i < length; i++) {
                        data[i].id = Utils.guid();
                    }
                    return data;
                }
            }
        });
        this.grid = $('#cw_repository_list').kendoCustomGrid({
            dataSource: this.repoDataSource,
            resizable: true,
            reorderable: true,
            selectable: 'row',
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            filterable: {
                extra: false,
                operators: {
                    string: {
                        startswith: filterMessages.STARTS_WITH,
                        neq: filterMessages.NEQ,
                        eq: filterMessages.EQ,
                        contains: filterMessages.CONTAINS
                    },
                    number: {
                        eq: filterMessages.EQ,
                        neq: filterMessages.NEQ
                    }
                },
                messages: this.gridMessages
            },
            columns: Utils.rearrangeColumns([{
                field: 'id',
                title: lang.SELECTOR,
                sortable: false,
                filterable: false,
                template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
                headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
                attributes: {
                    'class': 'text_center'
                },
                headerAttributes: {
                    'class': 'text_center'
                },
                hidden: repoColumns.id.hidden,
                width: repoColumns.id.width
            }, {
                field: 'presentationName',
                title: lang.NAME,
                hidden: repoColumns.presentationName.hidden,
                width: repoColumns.presentationName.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'version',
                title: lang.VERSION,
                hidden: repoColumns.version.hidden,
                width: repoColumns.version.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }], repoColumns),
            columnMenu: true,
	        change: $.proxy(this.onRowExpand, this),
            dataBound: $.proxy(this.onDataBound, this)
        }).data('kendoCustomGrid')
        // Add Kendo tooltip to the header of the columns
        Utils.gridColumnHeaderTooltip(this.grid);
        this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
        this.gridMenu = new GridMenu({
            renderTo: 'cw_repo_grid_menu',
            items: [{
                id: 'cw_repo_upload',
                icon: 'cloud-upload',
                text: lang.agents.UPLOAD,
                fn: this.onUpload,
                role: 'OBR_MANAGEMENT',
                scope: this,
                disabled: Utils.isAccessDenied(this.id) ? true : false
            }, {
                id: 'cw_repo_delete',
                icon: 'bin',
                text: lang.DELETE,
                role: 'OBR_MANAGEMENT',
                fn: this.onDelete,
                scope: this,
                disabled: true,
				deleteFromGrid: 'cw_repository_list'
            }]
        });
        this.adjustSectionHeight();
        var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('repoSearchPhrase') || '');
        this.gridFilterPanel = new PreferencesPanel({
            renderTo: 'cw_repo_filters',
            grid: this.grid,
            modulePrefName: 'Repo',
            defaultPrefViewKey: 'defaultFsView',
            prefListKey: 'repoFSViews',
            userPref: this.userPref,
            onRemove: $.proxy(this.saveUserPreferences, this),
            searchFields: ['presentationName', 'version'],
            searchValue: searchValue,
            defaultColumns: this.defaultColumns
		});

        var oThis = this;
        $('.cw_resource_upload').kendoUpload({
            async: {
                autoUpload: true,
                saveUrl: Settings.serverPath + 'agents/addResources',
                saveField: 'file'
            },
            multiple: true,
            showFileList: false,
            localization: {
                select: lang.kendo.UPLOAD_SELECT,
                statusFailed: lang.kendo.UPLOAD_STATUS_FAILED,
                statusUploaded: lang.kendo.UPLOAD_UPLOADED,
                statusUploading: lang.kendo.UPLOAD_UPLOADING,
                uploadSelectedFiles: lang.kendo.UPLOAD_SELECTED_FILES
            },
            upload: function (e) {
                var xhr = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", function (e) {
                        if (xhr.readyState === 1 /* OPENED */) {
                            xhr.setRequestHeader("Auth-Token", Cookies.sessionId);
                        }
                    });
                }
                var files = e.files;
                // Check the extension of each file and abort the upload if it is not .jpg, .jpeg or .png
                $.each(files, function () {
                    if (this.extension.toLowerCase() !== ".jar") {
                        kendo.ui.progress($('#cw_repository_list'), false);
                        oThis.actionNotification.setOptions({
                            message: lang.agents.messages.JAR_FILE_TYPES,
                            status: 'error'
                        }).show();
                        e.preventDefault();
                    }
                });
            },
            select: function () {
                kendo.ui.progress($('#cw_repository_list'), true);
            },
            success: $.proxy(function (e) {
                kendo.ui.progress($('#cw_repository_list'), false);
                this.repoDataSource.read();
                this.actionNotification.setOptions({
                    animationTime: 4000,
                    message: lang.agents.messages.BUNDLES_SUCCESSFULLY_UPLOADED,
                    status: 'success'
                }).show();
            }, this),
            error: $.proxy(function (e) {
                kendo.ui.progress($('#cw_repository_list'), false);
                this.actionNotification.setOptions({
                    animationTime: 4000,
                    message: lang.agents.messages.BUNDLES_FAILED_UPLOADED,
                    status: 'error'
                }).show();
            }, this)
        });
        $('.cw_resource_upload').attr('accept', '.jar');
        this.removeMask();
    },
    /**
     * Removes listeners
     */
    removeListeners: function () {
        $('#cw_repository_list').off();
    },
    /**
     * Attaches listeners
     */
    attachListeners: function () {
        $('#cw_repository_list').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
        $('#cw_repository_list').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
        $(window).off().on('resize', $.proxy(this.adjustSectionHeight, this));
    },
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_repository_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
    /**
     * Handle for databound event
     */
    onDataBound: function () {
	    $('#cw_repository_list').find('.k-grid-content').kendoTooltip({
		    filter: "td.tooltip:not(:empty)",
		    content: function (event) {
			    return event.target.text().split("\n").join("<br />");
		    }
	    });
        if (Utils.isAccessDenied(this.id)) {
            $('.cw_grid_check').prop('disabled', 'disabled');
            $('.cw_grid_check_all').prop('disabled', 'disabled');
        }
    },
    /**
     * Handler for the checkbox click
     * @param {Object} e The click event
     */
    onCheck: function (e) {
        var checkboxes = $('.cw_grid_check:checked');
        if (checkboxes.length) {
            this.gridMenu.enableItem('cw_repo_delete');
        } else {
            this.gridMenu.disableItem('cw_repo_delete');
        }
    },
    /**
     * Handler for the check all checkbox click
     * @param {Object} e The click event
     */
    onCheckAll: function (e) {
        var checkAll = $('.cw_grid_check_all').is(':checked');
        var checkboxes = $('.cw_grid_check');
        var i, length = checkboxes.length;
        for (i = 0; i < length; i++) {
            $(checkboxes[i]).prop('checked', checkAll);
        }
        this.onCheck();
    },
    /**
     * Clears all checkboxes
     */
    clearCheckboxes: function () {
        var checkAll = $('#cw_repository_list').find('.cw_grid_check_all'),
            checkboxes = $('#cw_repository_list').find('.cw_grid_check'),
            i, length = checkboxes.length;
        checkAll.prop('checked', false);
        for (i = 0; i < length; i++) {
            $(checkboxes[i]).prop('checked', false);
        }
    },
    /**
     * Handler function for the Delete menu item
     */
    onDelete: function () {
        var names = [];
        var checkboxes = $('#cw_repository_list').find('.cw_grid_check:checked');
        for (var i = 0, length = checkboxes.length; i < length; i++) {
            names.push(this.repoDataSource.getByUid($(checkboxes[i]).closest('tr').data('uid')).name);
        }
        var url = Settings.serverPath + 'agents/deleteResources';
        Utils.ajax(url, 'POST', JSON.stringify(names), $.proxy(function (result) {
            if (result.success) {
                this.actionNotification.setOptions({
                    message: lang.agents.messages.BUNDLES_WERE_DELETED,
                    status: 'success'
                }).show();
                this.repoDataSource.read();
            } else {
                this.actionNotification.setOptions({
                    message: result.message + ' ' + lang.agents.messages.BUNDLES_DELETE_FAILED + result.data.join(','),
                    status: 'error'
                }).show();
            }
        }, this));
    },
    /**
     * Handler function for the upload menu item

     */
    onUpload: function () {
        $('.cw_resource_upload').trigger('click');
    },
    /**
     * Saves user preferences
     */
    saveUserPreferences: function () {
        var preferences = [];
        preferences.push({
            key: 'repoFilter',
            value: JSON.stringify(this.grid.dataSource.filter() || [])
        });
        this.saveUserPrefs({
            category: 'Repo',
            filterPanel: this.gridFilterPanel,
            grid: this.grid,
            eventsToolbar: this.eventsToolbar,
            preferences: preferences,
            keys: {
                searchPhrase: 'repoSearchPhrase',
                columns: 'repoColumns',
                sort: 'repoSort',
                FsViews: 'repoFSViews'
            }
        });
    },
    /**
     * Loads user preferences
     */
    loadUserPreferences: function () {
        this.userPref = [];
        UserPrefs.load('Repo', $.proxy(function (result) {
            if (result.success) {
                this.userPref = result.data;
                this.initKendoComponents();
            } else {
                Utils.showInfo(lang.ALERT, result.message, result.details);
            }
        }, this));
    },
    /**
     * Called when application is unloaded/destroyed
     */
    destroy: function () {
        this.saveUserPreferences();
    },
    /**
     * Method by Andy
     *
     */
    adjustSectionHeight: function () {
        var section = $('.cw_section_full:visible'),
            sectionHeight = section.height();
        section.find('.cw_section_content').css('height', sectionHeight);
        section.find('.k-grid-content').css('height', sectionHeight - 40);
    },
    dirtyCheck: function () {
        return {isDirty: false}
    }
});
