import './antPopover.less';

import React from 'react';
import {observer} from 'mobx-react';
import {Popover, PopoverProps} from 'antd';
import b_ from 'b_';
import classnames from 'classnames';
import {popoverZIndex, thingsOnModalZIndex} from "controls/react/ant/zIndexes";

const b = b_.with('ant-popover-wrapper');

export interface AntPopoverProps extends PopoverProps {
	onModal?: boolean,
	overlayPadding?: boolean,
}

export const AntPopover = observer((props: AntPopoverProps) => {
	const {overlayClassName, overlayPadding, ...rest} = props;

	const withoutPadding = !overlayPadding;
	const fullOverlayClassName = classnames(b('overlay', {'without-padding': withoutPadding}), props.overlayClassName);

	return <Popover className={b()}
	                overlayClassName={fullOverlayClassName} {...rest}
	                overlayStyle={{zIndex: props.onModal ? thingsOnModalZIndex : popoverZIndex}}/>
});
