import {i} from "core/localization/localization";


let entityDataSource = null;
export function getEntityDataSource() {
	if (entityDataSource == null) {
		entityDataSource = [
			{
				text: i('Asset'),
				value: 'asset'
			},
			{
				text: i('Asset Group'),
				value: 'assetGroup'
			},
			{
				text: i('Service'),
				value: 'service'
			},
			{
				text: i('Sla'),
				value: 'sla'
			},
			{
				text: i('Metric'),
				value: 'metric'
			},
			{
				text: i('Kpi'),
				value: 'kpi'
			},
			{
				text: i('Cost'),
				value: 'cost'
			}
		];
	}

	return entityDataSource;
}
