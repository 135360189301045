import UrlBuilder from "tools/urlBuilder";

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Automation/Details.jsp',
			bodyCssClass: 'cw_automation_module',
			url: '/automations/:id',
			getModuleImport: () => import('./details'),
			routeId: 'automation_details'
		}
	];
}

export class AutomationRouter {
	static details(automationId) {
		return `/automations/${automationId}`;
	}

	static createNew(){
		return '/automations/new';
	}
}
