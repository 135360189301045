import './gridHeader.less'

import React from "react"
import {observer} from "mobx-react"
import {runInAction} from "mobx"
import classnames from 'classnames'

import {GridColumn} from "controls/grid/gridColumnConfig"
import {GridHeaderFilter} from "controls/grid/gridHeaderFilter"
import {getRenderingResult} from "controls/grid/itemRenderer"
import {GridDataItem} from "controls/grid/gridDataItem";
import {GridStore} from './gridStore'
import {SortingIndicator} from "controls/grid/sortingIndicator";
import {addDragAndDrop} from "tools/helpers/dom";

const b = require('b_').with('ceeview-grid-header')

type GridHeaderProps<DataItem extends GridDataItem> = {
	store: GridStore<DataItem>
	scrollable?: boolean
}

const i18n = require('core/localization/localization').translator({
	'Drag to resize': {
		no: 'Dra for å endre størrelse'
	},
	'Open filter editor': {
		no: 'Åpne filter innstillinger'
	},
	'{0}. Click to change sorting': {
		no: '{0}. Klikk for å endre sortering'
	}
})

export const GridHeader = observer(React.forwardRef<HTMLDivElement, GridHeaderProps<any>>((props, ref) => {
	return <div className={b()}>
		<div className={b('container', {scrollable: props.scrollable})} ref={ref}>
			{props.store.columns.visible.map(x =>
				<GridHeaderCell key={x.config.field}
				                store={props.store}
				                column={x}
				                resizable={props.scrollable} />
			)}
		</div>
	</div>
}))

type GridHeaderColumnProps<DataItem extends GridDataItem> = {
	store: GridStore<DataItem>
	column: GridColumn<DataItem>
	resizable: boolean
}

const bCell = require('b_').with(b() + "-cell")

const GridHeaderCell = observer(
	class GridHeaderColumnInner<DataItem extends GridDataItem> extends React.Component<GridHeaderColumnProps<DataItem>> {
		index: number

		ref: HTMLDivElement

		get columnState(){
			return this.props.column.state
		}

		get config(){
			return this.props.column.config
		}

		render() {
			const store = this.props.store;

			const rendererResult = getRenderingResult(() => this.config.rendererHeader?.() ?? this.config.title)

			const blockClasses = bCell( {
				draggable: this.props.resizable,
				sorted: this.columnState.sorting != null,
				filtered: this.props.column.filter != null,
				fixed: this.props.column.state.fixed ?? 'none'
			})

			const title = this.config.getHeaderTitle?.() ?? this.config.title

			return(
				<div className={classnames(blockClasses, rendererResult.className)}
				     draggable={this.props.resizable}
				     onClick={this.onHeaderClicked}
				     title={i18n('{0}. Click to change sorting', title)}
				     style={this.props.column.styles}
				     ref={this.onRef}>
					<div className={bCell('title')}>
						{rendererResult.content}
					</div>

					{this.config.sortable !== false && <>
						<SortingIndicator column={this.props.column}/>
						{this.props.column.sortingIndex != -1 &&
							<div className={bCell('sorter-index')}>{this.props.column.sortingIndex + 1}</div>
						}
					</>}

					{this.props.column.filteringEnabled &&
						<div className={bCell('filter', {active: this.props.column.isFilterApplied(store)})} title={i18n('Open filter editor')}>
							<GridHeaderFilter column={this.props.column}/>
						</div>
					}

					{this.props.resizable &&
						<div className={bCell('resize-handler')}
						     title={i18n('Drag to resize')}
						     onMouseDown={this.onMouseDown}
						     onClick={e => e.stopPropagation()}/>
					}
				</div>)
		}

		componentDidMount() {
			window.addEventListener('mousemove', this.onMouseMove)
			window.addEventListener('mouseup', this.onMouseUp)
		}

		componentWillUnmount() {
			window.removeEventListener('mousemove', this.onMouseMove)
			window.removeEventListener('mouseup', this.onMouseUp)
		}

		initialPosition: number
		onMouseDown = (e: React.MouseEvent) => {
			e.stopPropagation()
			e.preventDefault()

			this.initialPosition = e.nativeEvent.clientX
			this.index = this.props.store.columns.visible.findIndex(x => x.config.field == this.config.field)
		}

		onMouseUp = (e: MouseEvent) => {
			if(this.initialPosition == null)
				return

			this.initialPosition = null

			if(this.columnState.resizingWidth == null)
				return

			this.columnState.width = this.columnState.resizingWidth
			this.columnState.resizingWidth = null
		}

		onMouseMove = (e: MouseEvent) => {
			if(this.initialPosition == null)
				return

			let newWidth = this.props.column.actualWidth + e.clientX - this.initialPosition;
			if(newWidth <= 30)
				return

			this.ref.style.width  = (newWidth) + 'px'
			this.columnState.resizingWidth = newWidth
		}

		onHeaderClicked = () => {
			if(this.config.sortable === false)
				return

			this.props.column.triggerSorting()
		}

		onRef = (element: HTMLDivElement) => {
			if(!this.props.resizable)
				return;

			const overClassName = bCell() + '_over'

			if( element != null ){
				this.ref = element

				addDragAndDrop(this.ref, {
					overClassName: bCell() + '_over',
					onDrop: columnKey => {
						if(columnKey == null)
							return;

						this.props.store.columns.moveColumnAfter(this.columnState.field, columnKey)
					},
					data: this.columnState.field
				})
			}
		}
	}
)


