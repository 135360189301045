import {MaintenanceSubject, Severity} from "framework/entities/healthData";

export enum AssigneeType{
	None = 'ASSIGN_NONE',
	User = 'ASSIGN_USER',
	Team = 'ASSIGN_TEAM'
}

export enum EntryType{
	Service = 'SERVICE',
	ServiceQualifier = 'SERVICE_QUALIFIER',
	ServiceError = 'SERVICE_ERROR',
	ServiceElement = 'SERVICE_ELEMENT',
	ServiceLink = 'SERVICE_LINK',
	ServiceModel = 'SERVICE_MODEL',
	ServiceWarning = 'SERVICE_WARNING',
	ServiceQualifierWarning = 'SERVICE_QUALIFIER_WARNING',
	MonitorError = 'MONITOR_ERROR',
	AssetHealthReason = 'ASSET_HEALTH_REASON',
	AgentState = 'AGENT_STATE',
	Sla = 'SLA',
	SystemError = 'SYSTEM_ERROR',
	AgentWarning = 'AGENT_WARNING',
	Cost = 'COST'
}

export class EventsSummaryEntry {
	accountId: string
	accountName: string
	acknowledged: boolean
	acknowledgedAt: number
	acknowledgedBy: string
	action: boolean
	actionCount: number
	agentDown: boolean
	agentId: string
	agentName: string
	assignedTime: number
	assignedTo: string
	assignedToId: string
	assignedToType: AssigneeType
	details: string
	externalId: string
	externalOwner: string
	groups: string
	historic: boolean
	id: string
	identifierInstance: string
	identifierName: string
	inMaintenance: boolean
	information: string
	key: string
	maintenanceDetails: MaintenanceSubject[]
	message: string
	monitorClassText: string
	monitorError: boolean
	monitorType: string
	monitorTypeText: string
	severity: Severity
	severityIndex: number
	sourceId: string
	assetId: string
	sourceName: string
	subsystem: string
	suppressionCount: number
	tags: string[]
	targetId: string
	targetName: string
	time: number
	timeCreated: number
	timeDeleted: number
	type: EntryType
	typeText: string
}
