import './maintenanceView.less';
import CustomNotification from 'controls/customNotification';
import Utils from 'tools/utils';
import React from "react";
import ReactDOM from 'react-dom';
import {MaintenanceCalendar} from './maintenanceCalendar';
import {CalendarSelectorBlock} from 'controls/react/calendarSelector';
import {apiFetch} from 'framework/api';
import {getAgentCalendarsByAssetId, getCalendars} from './calendarsApi';
import {ApplicationState} from 'framework/applicationState';
import {translator} from "core/localization";

export const i = translator({
  "Already set in Asset's Agent maintenance": {
    "no": "Allerede satt i ressurs agent vedlikeholdstabell",
    "en": "Already set in asset agent maintenance"
  }
});

/**
 * @class ceeview.management.agent.views.MaintenanceView
 * @namespace ceeview.management.agent.views
 * @constructor
 * This class is used for listing the maintenance periods for a given agent
 * @param {Object} config The config object
 */
export default function MaintenanceView (config) {
    Utils.apply(this, config);
    if ((!this.agentId && !this.assetId) || !this.accountId) {
        return;
    }
	this.icalendar = config.icalendar ?? '';
	this.calendars = config.calendars ?? [];
    if (this.agentId) {
        this.currentId = this.agentId;
        this.currentType = 'agent';
        this.currentUrlType = 'agents';
        this.roleType = 'AGENT';
    } else if (this.assetId) {
        this.currentId = this.assetId;
        this.currentType = 'asset';
        this.currentUrlType = 'assets';
        this.roleType = 'ASSET';
    }

    this.initComponent();
};

MaintenanceView.prototype = {
    /**
     * @cfg {String} agentId The id of the agent
     */
    agentId: '',
    /**
     * @cfg {String} assetId The id of the asset
     */
    assetId: '',
    /**
     * @cfg {String} accountId The id of the account that contains the agent (mandatory)
     */
    accountId: '',

    height: 700,

	icalendar: '',

	calendars: [],

	calendarList: [],

	agentsCalendars: [],

	agentsAdditionalCalendars: [] = [],

	fullCalendarList: [],

	agentsCalendarData: undefined,
    /**
     * Main init method
     */
    initComponent: async function () {
		this.adjustMaintenanceGridHeight();
        await this.attachCalendarSelector();
        this.attachCalendar();
        this.maintenanceNotification = new CustomNotification({
			animationTime: 3000,
            appendToElement: '#cw_maintenance_periods_placeholder',
            type: 'plain',
            style: 'top:40px; right:27px',
            hideOnClick: true
        });
    },
    /**
     * Attaches Calendar
     */
    attachCalendar: function () {
        this.removeCalendar();
		ReactDOM.render( <MaintenanceCalendar
			onCalendarDataChanged={this.updateCalendarData.bind(this)}
			icalendar={this.icalendar}
			additionalCalendars={this.calendarList}
            height={this.height - 10}
			protectedCalendars={this.agentsCalendars} />,
			document.getElementById('cw_maintenance_periods'));
    },

	attachCalendarSelector: async function () {
		this.fullCalendarList = (await apiFetch(getCalendars(this.accountId))).data;
		if (this.assetId) {
			this.agentsCalendarData = (await apiFetch(getAgentCalendarsByAssetId(this.accountId, this.assetId))).data;
			const agentCalendar = {
				accountId: this.accountId,
				calendarType: 'generic',
				description: '',
				icalendar: this.agentsCalendarData?.calendar ?? '',
				id: '',
				name: ''
			};

			this.agentsAdditionalCalendars = this.fullCalendarList.filter(x => (this.agentsCalendarData?.maintenanceCalendars ?? []).includes(x.id));
			this.agentsCalendars = [agentCalendar, ...this.agentsAdditionalCalendars];
		}
		this.calendarList = this.fullCalendarList.filter(x => this.calendars.includes(x.id) && this.agentsAdditionalCalendars.every(y => y.id != x.id));
		this.renderCalendarSelector();
    },

	renderCalendarSelector: function() {
		this.removeCalendarSelector();
		const selectorContainer = $('<div id="global-calendars-selector-wrapper"></div>');
		$('#cw_agent_maintenance_window .cw_section_titlebar').append(selectorContainer);
		ReactDOM.render(<CalendarSelectorBlock accountId={this.accountId} value={this.calendarList}
				onChange={this.onCalendarsListChanged.bind(this)}
				disabledItemTitle={i("Already set in Asset's Agent maintenance")}
				disabledItems={this.agentsAdditionalCalendars} />,
		document.getElementById('global-calendars-selector-wrapper'));
	},

	updateCalendarData: function (data) {
		this.icalendar = data;
		this.onChange?.(this.icalendar)
	},

	onCalendarsListChanged: async function (value, e) {
		value ??= [];
		this.calendarList = this.fullCalendarList.filter(x => value.includes(x.id) && this.agentsAdditionalCalendars.every(y => y.id != x.id));
		this.calendars = this.calendarList.map(x => x.id);
		this.attachCalendar();
		this.renderCalendarSelector();
	},
    /**
     * Removes Calendar
     */
    removeCalendar: function () {
		ReactDOM.unmountComponentAtNode(document.getElementById('cw_maintenance_periods'));
    },

	removeCalendarSelector: function () {
		const calendarSelector = $('#global-calendars-selector-wrapper');
		if (calendarSelector.length) {
			ReactDOM.unmountComponentAtNode(document.getElementById('global-calendars-selector-wrapper'));
			calendarSelector.remove();
		}
    },

    onMaintenanceCancel: function () {
        $('.cw_maintenance').addClass('is_hidden');
        this.adjustMaintenanceGridHeight();
    },

    adjustMaintenanceGridHeight: function(mode) {
        var section = $('.cw_tabs_absolute');
        var sectionHeight = section.height() - 42;
        if (mode === 'create') {
            this.height = sectionHeight - 180;
            section.find('#cw_maintenance_periods').css('margin-top', 180);
        } else {
            this.height = sectionHeight;
            section.find('#cw_maintenance_periods').attr('style', '');
        }
        section.find('#cw_maintenance_periods .cw_section_content').css('height', this.height);
        section.find('#cw_maintenance_periods .k-grid-content').css('height', this.height - 29);
    }
};
