import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import produce from 'immer';

import PropTypes from 'prop-types';
import {MenuButton} from 'controls/react';
import {GridNative} from 'controls/react/kendoWrappers/gridNative';
import {Section} from 'controls/react/layout/section';
import {Toolbar, ToolbarItemPosition} from 'controls/react/layout/toolbar';
import {translator} from 'core';
import {metricSelectorGridColumns} from 'areas/service-boards/widgets/common/dataSources/metricSelectorGridColumns';
import {MetricWindow} from './../common/metricWindow';
import {ConversionWindow} from '../common/conversionWindow';
import {newGuid} from 'tools/guid';
import Settings from 'settings';
import Cookies from 'core/cookies';
import Lang from 'core/localization/lang';
import Button from 'controls/react/form/button';
import IconButton from 'controls/react/form/iconButton';
import {withContextData} from 'areas/service-boards/widgets/customMetrics/withContextData';

const b = require('b_').with('metric-selector');

const i = translator({
  "Select metrics": {
    "no": "Velg metrikker",
    "en": "Select metrics"
  },
  "Show advanced": {
    "no": "Vis avansert",
    "en": "Show advanced"
  },
  "Hide advanced": {
    "no": "skjul avansert",
    "en": "Hide advanced"
  }
});

MetricSelector.propTypes = {
	selectedMetrics: PropTypes.array,
	onSelectedMetrics: PropTypes.func,
	onDelete: PropTypes.func,
	accountId: PropTypes.string,
	singleMetricSelection: PropTypes.bool
}

/**
 * @return {null}
 */
export function MetricSelector(props) {
	const [openWindow, setOpenWindow] = useState(false);
	const [openConversionWindow, setOpenConversionWindow] = useState(false);
	const [conversionMetricId, setConversionMetricId] = useState();
	const [conversionWindowOffset, setConversionWindowOffset] = useState();
	const [conversionFormula, setConversionFormula] = useState();
	const [conversionDecimals, setConversionDecimals] = useState();
	const [allColumns, setAllColumns] = useState(false);
	const checkedRowsRef = useRef([]);
	const [selectedMetrics, setSelectedMetrics] = useState([])

	useEffect(()=>{
		if(props.selectedMetrics)
			fetchSelectedItems(props.selectedMetrics.map((x)=>x.metricId)).then((items)=>{
				setSelectedMetrics(items)
			})
	},[props.selectedMetrics])

	const refreshGridToken = useMemo(() => newGuid(), [allColumns]);

	const plainSelectedIds = React.useMemo(() => {
		if (props.selectedMetrics) {
			return props.selectedMetrics.map((metric) => metric.metricId)
		}
	}, [props.selectedMetrics]);

	const menuItems = useMemo(() => {
		return [{
			icon: 'plus',
			text: i('Add'),
			fn: () => {
				setOpenWindow(true);
			}
		}, {
			icon: 'bin',
			text: i('Delete'),
			fn: () => {
				props.onDelete(checkedRowsRef);
			}
		}];
	}, []);

	const openMetricWindow = useCallback(() => {
		if (props.accountId) {
			setOpenWindow(true);
		}
	}, [props.accountId]);

	const onClose = useCallback(() => {
		setOpenWindow(false);
	});

	const onConversionClose = useCallback(() => {
		setOpenConversionWindow(false);
	});

	const onConversionUpdate = useCallback((formula, decimals) => {
		const row = $('[metric-id=' + conversionMetricId + ']').closest('tr');
		const grid = row.closest('.k-grid').data('kendoGrid');
		const dataItem = grid.dataItem(row);

		dataItem.set('conversion', formula);
		dataItem.set('decimals', decimals);

		const newMetrics = produce(props.selectedMetrics, (draft) => {
			const foundMetric = draft.find((metric) => metric.metricId === conversionMetricId);
			foundMetric.conversion = formula;
			foundMetric.decimals = decimals;
		});

		props.onSelectedMetrics(newMetrics);
		setOpenConversionWindow(false);
	}, [props.onSelectedMetrics]);

	const onAdd = useCallback((items) => {
		fetchSelectedItems(items).then((items)=>{
			props.onSelectedMetrics(items.map(m => extractMetricFields(m)))
		})
		setOpenWindow(false);
	}, [props.accountId, props.onSelectedMetrics]);

	const onGridChanged = useCallback((e) => {
		const {values, model} = e;

		const updatedItem = model.toJSON();

		const newMetrics = produce(props.selectedMetrics, (draft) => {
			const index = draft.findIndex((i) => i.metricId === updatedItem.metricId);

			if (index === -1) {
				return;
			}

			draft.splice(index, 1, {...updatedItem, ...values});
		});

		props.onSelectedMetrics(newMetrics);
	}, [props.selectedMetrics]);

	const onMetricItemDeleteRef = React.useRef();
	onMetricItemDeleteRef.current = React.useCallback((e) => {
		const metricId = $(e.target).closest('tr').find('.cw_grid_check').attr('metric-id');

		const newMetrics = produce(props.selectedMetrics, (draft) => {
			return draft.filter((metric) => metric.metricId !== metricId);
		})

		props.onSelectedMetrics(newMetrics);
	}, [props.selectedMetrics, props.onSelectedMetrics]);

	const onConversion = React.useCallback((e) => {
		const target = $(e.target);
		const formula = target.text();
		const decimals = target.attr('decimals');
		const targetOffset = target.offset();
		const metricId = target.closest('tr').find('.cw_grid_check').attr('metric-id');

		setConversionMetricId(metricId);
		setConversionWindowOffset(targetOffset);
		setConversionFormula(formula);
		setConversionDecimals(decimals);
		setOpenConversionWindow(true);
	}, []);

	const onDataBound = useCallback((e) => {
		$('.cw_conversion').closest('td').addClass('pointer').on('click', (e) => {
			onConversion(e);
		});
		$('.cw_metric_item_delete').on('click', (e) => {
			onMetricItemDeleteRef.current(e);
		});
		let grid = $(e.sender.wrapper).data('kendoGrid');
		let gridDataSource = grid.dataSource.data().toJSON();
		if (!gridDataSource.length) {
			if (!grid.wrapper.find('.k-grid-norecords').length) {
				grid.wrapper.find('.k-grid-content-expander').before('<div class="k-grid-norecords cw_no_records_container pointer"><span class="cw_no_records_info">' + lang.widget.messages.SELECT_NEW_METRICS + '<span></span></div>');
				grid.wrapper.find('.k-grid-content').css('overflow-y', 'hidden');
				grid.wrapper.find('.k-grid-norecords').on('click', setOpenWindow);
			}
		} else {
			grid.wrapper.find('.k-grid-norecords').remove();
			grid.wrapper.find('.k-grid-content').css('overflow-y', 'scroll');
		}
	}, []);

	const onAdvancedToggle = React.useCallback((e) => {
		setAllColumns(!allColumns);
	}, [allColumns]);

	const handleRowSelected = useCallback((ids) => {
		console.log('ids', ids)

		checkedRowsRef.current = ids;
	});

	const advancedToggleContainerClass = allColumns ? 'advanced_metrics_toggle selected_advanced_metrics_toggle' : 'advanced_metrics_toggle';
	const advancedToggleMouseover = allColumns ? i('Hide advanced') : i('Show advanced');

	let gridHeight = 200;
	$('#cw_metrics_form .k-grid-content').css('height', gridHeight);

	const columns = React.useMemo(() => {
		return metricSelectorGridColumns(allColumns);
	}, [allColumns])

	async function fetchSelectedItems(items) {
		const url = `${Settings.serverPath}accounts/${props.accountId}/metrics/registeredMetrics/search`;

		const options = {
			method: 'POST',
			body: JSON.stringify({ skip: 0, take: 1000, filter: {
				"logic": "and",
				"filters": [{
					"logic": "or",
					"filters": items.map((id) => ({
						"operator": "eq",
						"value": id,
						"field": "metricId"
					}))
				 }]
			}}),
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				"Auth-Token": Cookies.sessionId
			},
			throwOnError: false,
		};

		const response = await window.fetch(url, options);

		const result = await response.json();

		return result.items;
	}
	return (
		<Section containerClass={b()} contentPadding={false}>
			<Toolbar title={Lang.widget.SELECTED_METRICS}>
				<MenuButton items={menuItems} />
				<IconButton iconName="list"
							embedded
							containerClass={advancedToggleContainerClass}
							onClick={onAdvancedToggle}
							title={advancedToggleMouseover}
							position={ToolbarItemPosition.AT_THE_END}
				/>
				<Button title={i('Select metrics')}
						primary
						onClick={openMetricWindow}/>
			</Toolbar>
			<GridNative
				key={refreshGridToken}
				selectionField={"metricId"}
				onRowsSelected={handleRowSelected}
				columns={columns}
				dataSourceArray={selectedMetrics}
				resizable={true}
				editable={true}
				height={gridHeight}
				dataBound={onDataBound}
				onSave={onGridChanged} />
			{openWindow && props.accountId && <MetricWindow
				open={openWindow}
				onClose={onClose}
				onAdd={onAdd}
				accountId={props.accountId}
				singleMetricSelection={props.singleMetricSelection}
				defaultSelectedMetricIds={plainSelectedIds} />}
			{openConversionWindow && <ConversionWindow
				metricId={conversionMetricId}
				onUpdate={onConversionUpdate}
				onClose={onConversionClose}
				offset={conversionWindowOffset}
				formula={conversionFormula}
				decimals={conversionDecimals}
			/>}
		</Section>
	);
}

export default withContextData(MetricSelector);



export function extractMetricFields(metric){
	const {metricId, conversion, customUnit, conversionUnit, unitType, agentName, assetName, assetId, categoryNode, instanceName} = metric;
	return {metricId, customUnit, conversion, conversionUnit, unitType, agentName: agentName || assetName, unitType, assetName, assetId, categoryNode, instanceName}
}

export function extractMetricSubscriptionFields(metrics){
	return metrics.map(({metricId, conversion, customUnit, conversionUnit, unitType}) => {
		return {metricId, conversion, customUnit, conversionUnit, unitType}
	})
}

export function extractQualifierSubscriptionFields(qualifier){
	const {conversion, customUnit, conversionUnit, unitType} = qualifier
	return {conversion, customUnit, conversionUnit, unitType};
}
