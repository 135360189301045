export function getDescriptions(){
	return [{
		url: '/data-registry',
		getModuleImport: () => import('./dataRegistry'),
		react: true,
		routeId: 'data_registry'
	}, {
		jspUrl: 'include/Management/AgentsView.jsp',
		url: '/agents',
		getModuleImport: () => import('./agentsView'),
		routeId: 'management_agents'
	}, {
		jspUrl: 'include/Management/AgentsView.jsp',
		url: '/agents/configuration',
		getModuleImport: () => import('./agentConfiguration'),
		routeId: 'management_agents_configuration'
	}, {
		jspUrl: 'include/Management/BundleConfiguration.jsp',
		url: '/agents/:agentId/bundle-configuration/:bundleId',
		getModuleImport: () => import('./bundleConfiguration'),
		routeId: 'bundle_configuration'
	}];
}

export class ManagementRouter {
	static agentsList() {
		return '/agents'
	}

	static dataRegistryList() {
		return '/data-registry'
	}

	static bundleConfiguration(bundleId, agentId, configurationId, bundleName) {
		return `/agents/${agentId}/bundle-configuration/${bundleId}?configurationId=${configurationId}&bundleName=${bundleName}`
	}
}
