import produce from "immer";

export const ACTIONS = {
	SET_SELECTION: 'SET_SELECTION',
	SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
	SET_FILTER_BY: 'SET_FILTER_BY',
	TOGGLE_CHECKED: 'TOGGLE_CHECKED',
	TOGGLE_ALL_CHECKED: 'TOGGLE_ALL_CHECKED',
	TOGGLE_SORTING: 'TOGGLE_SORTING',
	SET_FILTER: 'SET_FILTER',
	SET_ALL_IDS: 'SET_ALL_IDS',
	SET_SORTING_CONFIG: 'SET_SORTING_CONFIG',
	SET_FILTER_CONFIG: 'SET_FILTER_CONFIG',
	RESET_FILTER_SORTING: 'RESET_FILTER_SORTING',
	SET_PAGE: 'SET_PAGE'
}

export const VARS = {
	CHECKED_ROWS: 'checkedRows',
	SORTING: 'sorting',
	FILTERS: 'filters',
	MULTIPLE_SORTING: 'multipleSorting',
	FILTER_OPTIONS: 'filterOptions',
	INDEX_KEY: 'indexKey',
	ALL_IDS: 'allIds',
	FILTER_BY: 'filterBy',
	PAGE: 'page',
	MANUAL_SELECTION: 'manualSelection'
};

export const INITIAL_STATE = {
	[VARS.CHECKED_ROWS]: undefined,
	[VARS.SORTING]: {},
	[VARS.FILTERS]: {},
	[VARS.MULTIPLE_SORTING]: false,
	[VARS.FILTER_OPTIONS]: {},
	[VARS.PAGE]: 0,
	[VARS.MANUAL_SELECTION]: false
};

function toggleChecked(draft, {id}) {
	const checkedSet = new Set(draft[VARS.CHECKED_ROWS]);

	if (checkedSet.has(id)) {
		checkedSet.delete(id)
	}
	else {
		checkedSet.add(id);
	}

	draft[VARS.MANUAL_SELECTION] = true;
	draft[VARS.CHECKED_ROWS] = Array.from(checkedSet);
}

function toggleSorting(draft, {key}) {
	const currentState = draft[VARS.SORTING][key];

	if (currentState === 'asc') {
		draft[VARS.SORTING][key] = 'desc'
	}
	else if (currentState === 'desc') {
		delete draft[VARS.SORTING][key];
	}
	else {
		draft[VARS.SORTING][key] = 'asc'
	}
}

function setFilterOptions(draft, {value}) {
	draft[VARS.FILTER_OPTIONS] = value;
}

function setFilter(draft, {key, filter}) {
	if (filter) {
		draft[VARS.FILTERS][key] = filter;
	} else if (draft[VARS.FILTERS][key]) {
		delete draft[VARS.FILTERS][key];
	}
	draft[VARS.PAGE] = 0;
}

function setSelection(draft, {value}) {
	draft[VARS.CHECKED_ROWS] = value || [];
}

function setAllIds(draft, {value}) {
	draft[VARS.ALL_IDS] = value;
}

function toggleAllChecked(draft, {value}) {
	if (value) {
		draft[VARS.CHECKED_ROWS] = draft[VARS.ALL_IDS];
	}
	else {
		draft[VARS.CHECKED_ROWS] = [];
	}
}

function setSortingConfig(draft, {sorting}) {
	draft[VARS.SORTING] = sorting;
}

function setFilterConfig(draft, {filters}) {
	draft[VARS.FILTERS] = filters;
}

function setFilterBy(draft, {value}) {
	draft[VARS.FILTER_BY] = value
}

function setPage(draft, {value}) {
	draft[VARS.PAGE] = value
}

function resetAllSiltersSorting(draft) {
	if (draft[VARS.FILTERS]) 
		draft[VARS.FILTERS] = {};
	if(draft[VARS.SORTING])
		draft[VARS.SORTING] = {}
}

function _reducer(draft, action) {
	switch(action.type) {
		case ACTIONS.TOGGLE_CHECKED:
			toggleChecked(draft, action);
			break;
		case ACTIONS.TOGGLE_ALL_CHECKED:
			toggleAllChecked(draft, action);
			break;
		case ACTIONS.TOGGLE_SORTING:
			toggleSorting(draft, action);
			break;
		case ACTIONS.SET_FILTER_OPTIONS:
			setFilterOptions(draft, action);
			break;
		case ACTIONS.SET_FILTER:
			setFilter(draft, action);
			break;
		case ACTIONS.SET_SELECTION:
			setSelection(draft, action);
			break;
		case ACTIONS.SET_ALL_IDS:
			setAllIds(draft, action);
			break;
		case ACTIONS.SET_SORTING_CONFIG:
			setSortingConfig(draft, action);
			break;
		case ACTIONS.SET_FILTER_BY:
			setFilterBy(draft, action);
			break;
		case ACTIONS.SET_FILTER_CONFIG:
			setFilterConfig(draft, action);
			break;
		case ACTIONS.RESET_FILTER_SORTING:
			resetAllSiltersSorting(draft);
			break;
		case ACTIONS.SET_PAGE:
			setPage(draft, action);
			break;
	}
}

export const reducer = produce(_reducer);

export const reducerWithLogger = produce((draft, action) => {
	const state = _reducer(draft, action);
	// console.log(draft, action);
	console.log(JSON.stringify(draft, null, 4), action)
});
