import './timePeriodSelector.less'

import { observer } from 'mobx-react';
import React from 'react';
import moment from 'moment';
import {ClockCircleOutlined} from '@ant-design/icons'

import {AntSelect} from "controls/react/ant/antSelect";
import {DataListEntry} from "framework/entities/dataList";
import {AntDatePicker, AntRangePicker} from "controls/react/ant/antDatePicker";
import {Toolbar} from "controls/react/layout/toolbar";
import UrlBuilder from "tools/urlBuilder";
import {AntPopover} from "controls/react/ant/antPopover";
import { AntButton } from '../ant/antButton';
import { makeAutoObservable } from 'mobx';
import {createModelSchemaWrapper} from "framework/serializr-integration";
import { primitive } from 'serializr';
import {AntSegmented} from "controls/react/ant/antSegmented";
import {AntRadioGroup} from "controls/react/ant/antRadio";
import {AntSizeType} from "controls/react/ant/utils";
import {getStylesForThings, styleForThingsOnPopover, styleForThingsOnPopoverOnModal} from "controls/react/ant/zIndexes";

const i = require('core/localization/localization').translator({
	'LAST30DAYS': {
		en: 'Last 30 days',
		no: 'Siste 30 dager',
	},
	'LAST30DAYS_short':{
		en: '30d',
		no: '30d'
	},
	'LAST7DAYS':{
		en: 'Last 7 days',
		no: 'Siste 7 dager'
	},
	'LAST7DAYS_short': {
		en: '7d',
		no: '7d'
	},
	'LASTDAY': {
		en: 'Yesterday',
		no: 'Siste dag'
	},
	'LASTDAY_short':{
		en: '24h',
		no: '24h'
	},
	'LASTHOUR': {
		en: 'Hour'
	},
	'LASTHOUR_short':{
		en: '1h',
		no: '1h'
	},
	'CURRENT_MONTH':{
		en: 'Current month',
		no: 'Nåværende måned'
	},
	'LAST_MONTH': {
		en: 'Last month',
		no: 'Nåværende måned'
	},
	'CURRENT_YEAR': {
		en: 'Current year',
		no: 'Nåværende år'
	},
	'CUSTOM': {
		en: 'Custom',
		no: 'Tilpasset'
	},
	'CUSTOM_short':{
		en: 'Custom',
		no: 'Tilpasset'
	},
	'CURRENT_WEEK': {
		en: 'Current week',
		no: 'Nåværende uke'
	},
	'LAST_YEAR': {
		en: 'Last year',
		no: 'Forrige år'
	},
	'None': {
		no: 'Ingen'
	},
	'YEAR': {
		en: 'Year',
		no: 'År'
	},
	'MONTH': {
		en: 'Month',
		no: 'Måned'
	},
	'ALL':{
		en: 'All',
		no: 'Alle'
	},
	'ALL_short': {
		en: 'All',
		no: 'Alle'
	}
});

export enum TimePeriodType{
	Last30Days = 'LAST30DAYS',
	Last7Days = 'LAST7DAYS',
	LastDay = 'LASTDAY',
	LastHour = 'LASTHOUR',
	CurrentMonth = 'CURRENT_MONTH',
	CurrentWeek = 'CURRENT_WEEK',
	CurrentYear = 'CURRENT_YEAR',
	LastMonth = 'LAST_MONTH',
	LastYear = 'LAST_YEAR',
	Month = 'MONTH',
	Year = 'YEAR',
	Custom = 'CUSTOM',
	None = 'None',
	All = 'ALL'
}

export class TimePeriod {
	period: TimePeriodType
	startDate?: number
	endDate?: number
	year?: number
	months?: number

	constructor() {
		makeAutoObservable(this)
	}
}

createModelSchemaWrapper(TimePeriod, {
	period: primitive(),
	startDate: primitive(),
	endDate: primitive(),
	year: primitive(),
	months: primitive()
})

export type TimeSelectionString = {
	period: TimePeriodType;
	startDate?: string;
	endDate?: string;
	year?: number;
	months?: number;
	month?: number;
}

export enum CustomSelectorMode{
	Embedded,
	Popup
}

export enum TimePeriodAppearance{
	DropDown,
	Buttons
}

export class TimePeriodSelectorProps{
	periods: TimePeriodType[]
	value: TimePeriod
	onChange: (selection: TimePeriod) => void
	customSelectorMode?: CustomSelectorMode
	appearance?: TimePeriodAppearance
	size?: AntSizeType
	onModal?: boolean
}

export function toStringTimeSelection(period: TimePeriod) : TimeSelectionString{
	return {
		period: period.period,
		startDate: period.startDate != null ? moment(period.startDate).format("YYYY-MM-DD") : null,
		endDate: period.endDate != null ? moment(period.endDate).format("YYYY-MM-DD") : null,
		year: period.year,
		months: period.months
	}
}

export function timePeriodToUrl(period: TimePeriod){
	if(period.period != TimePeriodType.Custom) {
		return "timeSelector=" + period.period
	}else{
		return `fromTime=${period.startDate}&toTime=${period.endDate}`;
	}
}

export class TimePeriodSelectorState{
	dataList: Array<{label: string, value: string}>
	showTimeRangeSelector?: boolean
}

const b = require('b_').with('time-period-selector');

export class TimePeriodSelector extends React.Component<TimePeriodSelectorProps, TimePeriodSelectorState>{
	constructor(props: TimePeriodSelectorProps) {
		super(props);

		this.state = {
			dataList: this.getDataListFromPeriodsList(props.periods)
		}
	}

	get appearance(){
		return this.props.appearance ?? TimePeriodAppearance.DropDown
	}

	get startDate(){
		return this.props.value.startDate != null ? moment(this.props.value.startDate) : null
	}

	get endDate(){
		return this.props.value.endDate != null ? moment(this.props.value.endDate) : null
	}

	render(){
		const rangePicker = <AntRangePicker value={[this.startDate, this.endDate]}
		                                    clearIcon={false}
		                                    popupStyle={getStylesForThings(this.props.onModal)}
		                                    onChange={this.dateRangeChanged}/>

		if(this.appearance == TimePeriodAppearance.DropDown) {
			const periodSelect = <AntSelect dataList={this.state.dataList}
			                                className={b('selector')}
			                                nameField={'label'}
			                                valueField={'value'}
			                                size={this.props.size}
			                                value={this.props.value.period}
			                                onChange={this.periodChanged}/>

			return <div className={b({'custom-selector-mode': this.getCustomSelectorModeModificator()})}>

				{/*showing dropdown & timerang selector*/}
				{this.state.showTimeRangeSelector &&
					<AntPopover placement={"bottomLeft"}
					            defaultVisible={true}
					            content={rangePicker}
					            onModal={true}
					            trigger={"none"}>
						{periodSelect}
						<AntButton icon={<ClockCircleOutlined/>} onClick={this.hideTimeRangeSelector}/>
					</AntPopover>
				}

				{/*showing dropdown && a button to show timerange selector if mode == popup*/}
				{!this.state.showTimeRangeSelector &&
					<>
						{periodSelect}
						{this.props.value.period == TimePeriodType.Custom && this.props.customSelectorMode == CustomSelectorMode.Popup &&
							<AntButton icon={<ClockCircleOutlined/>} onClick={this.showTimeRangeSelector}/>
						}
					</>
				}

				{/*showing timerange selector if mode == inline*/}
				{this.props.value.period == TimePeriodType.Custom && this.props.customSelectorMode != CustomSelectorMode.Popup &&
					<>{rangePicker}</>
				}
			</div>
		}else{

			const periodSelect = <AntRadioGroup options={this.state.dataList}
			                                    optionType={'button'}
			                                    buttonStyle={'solid'}
			                                    size={this.props.size}
			                                    value={this.props.value.period}
			                                    onChange={this.periodChanged}/>

			return <div className={b({'custom-selector-mode': this.getCustomSelectorModeModificator()})}
			            onClick={this.checkIfNeedToShowTimeSelector}>

				<AntPopover placement={"bottomLeft"}
				            content={rangePicker}
				            trigger={"click"}
				            onModal={true}
				            visible={this.state.showTimeRangeSelector}
				            onVisibleChange={v => this.setState({showTimeRangeSelector: v})}>
					{periodSelect}
				</AntPopover>

				{/*showing timerange selector if mode == inline*/}
				{this.props.value.period == TimePeriodType.Custom && this.props.customSelectorMode != CustomSelectorMode.Popup &&
					<>{rangePicker}</>
				}
			</div>
		}
	}

	showTimeRangeSelector = () => {
		this.setState({showTimeRangeSelector: true})
	}

	hideTimeRangeSelector = () => {
		this.setState({showTimeRangeSelector: false})
	}

	getCustomSelectorModeModificator(){
		return this.props.customSelectorMode == CustomSelectorMode.Popup ? 'popup' : 'embedded'
	}


	checkIfNeedToShowTimeSelector = (e: React.MouseEvent<HTMLDivElement>) => {
		//we need to show a time selector when a user clicks on a 'custom' when 'custom' is already selected.

		//we checking that current selected is custom and
		if (this.props.value.period != TimePeriodType.Custom)
			return

		const target = e.target as HTMLElement
		const radioEntry = target.closest('.ant-radio-button-wrapper')
		if (radioEntry == null)
			return

		//that we clicked also on custom
		if (radioEntry.querySelector('input').value != TimePeriodType.Custom)
			return

		this.setState({showTimeRangeSelector: true})
	}


	periodChanged = (period: TimePeriodType) => {
		if(period == TimePeriodType.Custom && this.props.customSelectorMode == CustomSelectorMode.Popup) {
			this.showTimeRangeSelector()
		}else{
			this.hideTimeRangeSelector()
			this.triggerOnChanged({period, startDate: null, endDate: null})
		}
	}

	dateRangeChanged = (values: moment.Moment[]) => {
		let initialStartDate = new Date(values[0].valueOf());
		let initialEndDate = new Date(values[1].valueOf());
		initialStartDate.setHours(0,0,0,0);
		initialEndDate.setHours(24,0,0,0);
		this.triggerOnChanged({period: TimePeriodType.Custom, startDate: initialStartDate.getTime(), endDate: initialEndDate.getTime() - 1})
		this.hideTimeRangeSelector()
	}

	triggerOnChanged(value: Partial<TimePeriod>)
	{
		this.props.onChange({
			period: value.period ?? this.props.value.period,
			startDate: value.startDate === undefined ? this.props.value.startDate : value.startDate,
			endDate: value.endDate === undefined ? this.props.value.endDate : value.endDate
		});
	}

	componentDidUpdate(prevProps: Readonly<TimePeriodSelectorProps>) {
		if (prevProps.periods != this.props.periods) {
			this.setState({
				dataList: this.getDataListFromPeriodsList(this.props.periods)
			})
		}
	}

	getDataListFromPeriodsList(periods: TimePeriodType[]) {
		return periods.map(x => ({
			label: i(this.getPeriodLabelName(x)),
			value: x
		}));
	}

	getPeriodLabelName(period: TimePeriodType){
		if(this.appearance == TimePeriodAppearance.Buttons){
			return period + '_short'
		}else{
			return period
		}
	}
}
