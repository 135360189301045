import { ApiRequest } from 'framework/api';

export interface UnitType {
	description: string
	text: string
	value: string
}

export function getMetricUnits(unitType: string) {
	return new ApiRequest<UnitType[]>({
		url: `metrics/units/${unitType}/list`
	});
}

export function validateConvertion(formula: string, lastValue: string) {
	let url = `metrics/validateConversion?expression=${formula}`;
	if (lastValue) {
		url += `&value=${lastValue}`;
	}
	return new ApiRequest({
		url: url
	});
}
