/*
 * Language file for the English translations
 */
let lang = {
	// Words
	ELEMENT: 'Element',
	ACTION: 'Action',
	ACTION_COUNT: 'Action count',
	ACTIONS: 'Actions',
	ACTION_TRIGGER: 'Action trigger',
	ACTIVE: 'Active',
	ACTIVATE: 'Activate',
	AUTOMATION: 'Automation',
	INTEGRATION: 'Integration',
	ONLY_IN_PERIOD: 'Only in period',
	DEACTIVATE: 'Deactivate',
	ADMINISTRATION: 'Administration',
	ALL: 'All',
	ASSIGN: 'Assign',
	UNASSIGN: 'Unassign',
	UNACKNOWLEDGE: 'Unacknowledge',
	AUDIT: 'Audit',
	INACTIVE: 'Inactive',
	INSTALLED: 'Installed',
	NOT_INSTALLED: 'Not installed',
	SELECT_ALL: 'Select all',
	DESELECT_ALL: 'Deselect all',
	SELECTED: 'Selected',
	SELECTION: 'Selection',
	SESSION_ID: 'Session Id',
	NO_FILTER: 'No View',
	BEZIER: 'Bezier',
	CAPTION: 'Caption',
	CHANNELS: 'Channels',
	MANHATTAN: 'Manhattan',
	MANUAL: 'Manual',
	CLASSNAME: 'Classname',
	DESCRIPTION: 'Description',
	CONFIGURATION_NAME: 'Configuration name',
	NOTIFICATION: 'Notification',
	NOTIFICATION_TITLE: 'Notification',
	DOWNTIME: 'Downtime',
	DIRECT: 'Direct',
	DISK: 'Disk',
	DRIVE: 'Drive',
	EVENT: 'Event',
	FIELD: 'Field',
	INFO: 'Info',
	ICON: 'Icon',
	ICONS: 'Icons',
	INFORMATION: 'Information',
	IDENTIFIER: 'Identifier',
	INSTANCE: 'Instance',
	KEY: 'Key',
	NAME: 'Name',
	UNIT: 'Unit',
	ACCOUNT_SELECTION: 'Account selection',
	ACCOUNT_SUBACCOUNT: 'Account/subaccount',
	SUBACCOUNT: 'Subaccount',
	PARENT_AGENT: 'Parent agent',
	FULLNAME: 'Full name',
	TITLE: 'Title',
	TEST: 'Test',
	ID: 'ID',
	CVID: 'CVID',
	TYPE: 'Type',
	AGGREGATION_TYPE: 'Aggregation type',
	CUSTOM_UNIT: 'Custom unit',
	COLOR: 'Color',
	CONVERSION: 'Conversion',
	ASSETS: 'Assets',
	TEST_RULESET: 'Test ruleset',
	ASSET: 'Asset',
	SLA: 'SLA',
	ASSET_TARGET: 'Asset target',
	TAG: 'Tag',
	TAGS: 'Tags',
	SET_TAGS: 'Set tags',
	MERGE_ASSETS: 'Merge assets',
	MERGE_ASSETS_CONFIRMATION: 'This will merge all the selected assets into this asset: ASSETNAME. Click update to continue the merge or cancel to return to the merge asset form.',
	TARGET: 'Target',
	EXTENDED_TYPE: 'Extended type',
	TARGET_TYPE: 'Target type',
	TEST_RESULTS: 'Test results for ${assetName}',
	RESPONSIBLE: 'Responsible',
	RESPONSIBLE_TEAM: 'Team',
	SERVICE: 'Service',
	SERVICE_MODEL: 'Servicemodel',
	SERVICE_NAME: 'Service name',
	SERVICE_ELEMENT: 'Service element',
	SERVICE_LINK: 'Servicelink',
	SERVICE_LINK_MESSAGE: 'Click to redirect to service ${serviceName}',
	QUALIFIER: 'Servicequalifier',
	SERVICES: 'Services',
	SERVICE_UP: 'Service is UP',
	SERVICE_DOWN: 'Service is DOWN',
	SERVICE_ERROR: 'Service error',
	SERVICE_INACTIVE: 'Service is INACTIVE',
	SERVICE_MAINTENANCE: 'Service in maintenance',
	SERVICE_OUTOFOPERATION: 'Outside operating period',
	SERVICE_QUALIFIER: 'Servicequalifier',
	SERVICE_QUALIFIERS: 'Servicequalifiers',
	SERVICE_QUALIFIERS_SETTINGS: 'Servicequalifier settings',
	OPERATING_PERIOD: 'Operating period',
	INACTIVE_SERVICE: 'inactive service',
	INACTIVE_SERVICES: 'inactive services',
	DYNAMIC_SERVICES: 'Dynamic services',
	STATE: 'State',
	STATE_SUMMARY: 'State summary',
	SHARED: 'Shared',
	ACCOUNT: 'Account',
	ACCOUNTS: 'Accounts',
	SUBACCOUNTS: 'Subaccount',
	ASSET_HEALTH: 'Health',
	KPI: 'Kpi',
	PERCENTILE: 'Percentile',
	KEYWORD: 'Pattern',
	AGENTS: 'Agents',
	AGENT: 'Agent',
	ASSET_ACCOUNT: 'Asset account',
	METRICS: 'Metrics',
	SQ_METRICS: 'SQ metrics',
	MONITOR_METRICS: 'Monitor metrics',
	EXTERNAL_METRICS: 'External metrics',
	AGENT_ACCOUNT: 'Agent account',
	AGGREGATED: 'Aggregated',
	GROOVY_SCRIPT_EDITOR: 'Groovy script editor',
	ADVANCED: 'Advanced',
	SERVER: 'Server',
	SERVER_AGENT: 'Server agent',
	SERVER_ASSET: 'Server asset',
	SERVER_SERVICES: 'Server services',
	USERS: 'Users',
	USER_TAGS: 'User tags',
	USER_TAGS_ACTIVE: 'User tags are active for your user',
	USER_TAGS_INACTIVE: 'User tags are inactive due to "force team tags" is checked in your user profile',
	TEAM_TAGS_ACTIVE: 'Team tags are active for your user',
	TEAM_TAGS_INACTIVE: 'Team tags are not active for your user',
	VALID_EMAIL: 'Please input a valid email address',
	ACTIVE_USERS: 'Active users',
	USER_CATEGORY: 'Role',
	USER_CATEGORIES: 'Roles',
	FILE: 'File',
	DOCUMENT: 'Document',
	TEXT: 'Text',
	PASTE: 'Paste',
	FONT_SIZE: 'Font size',
	MAX: 'Max',
	MIN: 'Min',
	TRUE: 'True',
	FALSE: 'False',
	AVERAGE: 'Average',
	PERCENTAGE: 'Percentage',
	ACHIEVED_PERCENTAGE: 'Achieved percentage',
	BREACH_PERCENTAGE: 'Breached percentage',
	RANGE: 'Range',
	UNAVAILABLE: 'Unavailable',
	MEDIA_LIBRARY: 'Media library',
	AUTHENTICATIONS: 'Authentications',
	ALERT: 'Alert',
	ERROR: 'Error',
	RETRY: 'Retry',
	ERROR_CODE: 'Error code',
	FAILED: 'Failed',
	UNKNOWN: 'Unknown',
	USERNAME: 'Username',
	PASSWORD: 'Password',
	PROFILE: 'Profile',
	OLD_PASSWORD: 'Old password',
	CONFIRM_PASSWORD: 'Confirm password',
	FIRST_NAME: 'First name',
	LAST_NAME: 'Last name',
	LANGUAGE: 'Language',
	LEGEND: 'Legend',
	INFORMATIONAL: 'Informational',
	LOGOUT: 'Logout',
	EMAIL: 'Email',
	AND: 'And',
	OR: 'Or',
	NOT: 'Not',
	EQ: 'Equal',
	GE: 'Greater or equal than',
	GT: 'Greater than',
	LE: 'Less or equal than',
	LT: 'Less than',
	NE: 'Not equal',
	NEQ: 'Is not equal to',
	GTE: 'Greater than or equal to',
	LTE: 'Less than or equal to',
	IAE: 'Is after or equal to',
	IA: 'Is after',
	IBE: 'Is before or equal to',
	ISIN: 'Is in',
	ISNOTIN: 'Is not in',
	IB: 'Is before',
	STARTS_WITH: 'Starts with',
	CONTAINS: 'Contains',
	TEAM: 'Team',
	TEAMS: 'Teams',
	SERVICE_TAGS: 'Service tags',
	GAUGE: 'Gauge',
	SOLID_GAUGE: 'Solid gauge',
	SOURCE: 'Source',
	GROUP: 'Group',
	SUBGROUP: 'Subgroup',
	SELECT_USERS: 'Select users',
	DAY_SELECTION: 'Day selection',
	NOTIFICATIONS: 'Messages',
	TOTAL: 'Total',
	PREVIEW: 'Preview',
	PROPERTIES: 'Properties',
	CATEGORY: 'Category',
	NEW_CATEGORY: 'New category',
	ROLE: 'Role',
	NEW_ROLE: 'New role',
	PUBLIC: 'Public',
	PRIVATE: 'Private',
	YES: 'Yes',
	NO: 'No',
	NA: 'Not available',
	REASON: 'Health reason',
	REASONS: 'Health reasons',
	REASON_SUMMARY: 'Health reason summary',
	REPORT: 'Report',
	REPORTS: 'Reports',
	RESET_HEALTH_INDEX_PLACEHOLDER: 'Type in reason for resetting healthindex',
	MANAGEMENT: 'Management',
	SYSTEM_ERRORS: 'System message',
	INCLUDE: 'Include',
	VALUE: 'Value',
	DEFAULT: 'Default',
	DEFAULT_VALUE: 'Default value',
	NO_VALUE: 'NO VALUE',
	UP: 'Up',
	DOWN: 'Down',
	IN: 'In',
	OUT: 'Out',
	BREACHED: 'Breached',
	WARNING: 'Warning',
	COMPLIANT: 'Compliant',
	COMPLIANCE: 'Compliance',
	INCIDENTS: 'Incidents',
	INCIDENTS_NUMBER: 'Incidents number',
	MINOR: 'Minor',
	MAJOR: 'Major',
	CRITICAL: 'Critical',
	PROJECTED: 'Projected',
	NONE: 'None',
	USER: 'User',
	SEARCH: 'Search',
	SINCE: 'since',
	STATUS: 'Status',
	TOPIC: 'Topic',
	COMMENT: 'Comment',
	COMMNETS: 'Comments',
	READ: 'Read',
	WRITE: 'Write',
	MENU: 'Menu',
	CUSTOMIZE: 'Customize',
	OPERATION: 'Operation',
	HOME: 'Home',
	HOME_PAGE: 'Home page',
	URL: 'URL',
	DBWATCH: 'DbWatch',
	LAUNCH: 'Launch',
	LAYOUT: 'Layout',
	ENTRIES: 'Entries',
	SERVER_VERSION: 'Server version',
	VERSION: 'Version',
	ALLOCATED: 'Allocated',
	PROCESS: 'Process',
	SELECTOR: 'Selector',
	SEPARATOR: 'Separator',
	MESSAGE: 'Message',
	USED: 'Used',
	UPTIME: 'Uptime',
	CURRENT: 'Current',
	MEMORY: 'Memory',
	MISSING: 'Missing',
	SINGLEGRAPH: 'Single graph',
	MULTIGRAPH: 'Multigraph',
	MULTIPLE_SERVICES: 'Multiple services',
	MULTIPLE_ELEMENTS: 'Multiple elements',
	MONITOR: 'Monitor',
	SELECT_MONITOR: 'Select monitor',
	SELECT_MONITORS: 'Select monitors',
	SELECT_SERVICE_MODEL: 'Select servicemodel',
	TEMPLATES: 'Templates',
	MONITORS: 'Monitors',
	OUT_OF: 'out of',
	PARENT: 'Parent',
	DATA: 'Data',
	NO_DATA: 'No data',
	SHOW: 'Show',
	SHOW_METRICS: 'Show metrics',
	SHOW_SINGLE_METRIC_GRAPH: 'View in single graph',
	SHOW_MULTIGRAPH: 'View in multigraph',
	SHOW_TABLE: 'View in table',
	SHOW_REGRESSION: 'Show trend',
	SHOW_THRESHOLD: 'Show threshold',
	HIDE: 'Hide',
	IMAGE: 'Image',
	LOGO_AREA: 'Logo area is 180px - 40 px',
	HI_THRESHOLD: 'HI threshold',
	WIDGETS: 'Widgets',
	// Buttons
	ADD: 'Add',
	CANCEL: 'Cancel',
	OPEN: 'Open',
	CLOSE: 'Close',
	CLOSED: 'Closed',
	RUNNING: 'Running',
	STOPPED: 'Stopped',
	COLLAPSE_ALL: 'Collapse all',
	CONFIGURATION: 'Configuration',
	COUNT: 'Count',
	CLEAR: 'Clear',
	FILTER: 'Filter',
	FILTERS: 'Filters',
	FILTERING: 'Filtering',
	SORTING: 'Sorting',
	FILTER_TYPE: 'Filter type',
	OK: 'OK',
	SAVE: 'Save',
	SELECT_FILE: 'Select file...',
	get SELECT(){
		return 'Select...';
	},
	//SELECT: 'Selecoeioeit...',
	SELECT_FILTER: 'Select a filter...',
	SELECT_ACCOUNT: 'Select account...',
	SELECT_ACCOUNTS: 'Select accounts:',
	SELECT_ACTION: 'Select action',
	SELECT_SERVICE_ELEMENT: 'Select service element',
	SELECT_SLA: 'Select SLA...',
	SELECT_SERVICE: 'Select service...',
	SELECT_APPLICATION: 'Select application...',
	SELECT_ASSET_GROUP: 'Select Assetgroup...',
	SELECT_ASSET: 'Select asset...',
	FINISH: 'Finish',
	UPLOAD: 'Upload...',
	CLICK_TO_UPLOAD: 'Click to upload image',
	CLICK_TO_VIEW_EDIT_SCRIPT: 'Click to view/edit script',
	CLICK_TO_EDIT_ASSET_GROUP: 'Click to edit assetgroup',
	MARK_READ: 'Mark as read',
	BACK: 'Back',
	NEXT: 'Next',
	DELETE: 'Delete',
	DELETED: 'Deleted',
	TIME_DELETED: 'Time deleted',
    RECALCULATE: 'Recalculate',
	RECALCULATION: 'Recalculation',
	RECALCULATE_SERVICE_SLA: 'Recalculate service SLA',
	IMPORT: 'Import',
	EXPORT: 'Export',
	CONVERT: 'Convert',
	SHARE: 'Share',
	EDIT: 'Edit',
	RELOAD: 'Reload',
	REDISCOVER_DISK: 'Rediscover disks',
	RESET: 'Reset',
	RESTORE_DEFAULT: 'Restore default',
	DOWNLOAD: 'Download',
	DOWNLOAD_LOGVIEW: 'Download logview',
	DOWNLOAD_LOGFOLDER: 'Download log folder',
	ADD_URL: 'Add url',
	ADD_SCHEDULE: 'Add schedule',
	ADD_NOTIFICATION: 'Add notification',
	DELETE_NOTIFICATION: 'Delete notification',
	GENERATE: 'Generate',
	MODULE: 'Module',
	DISABLE_SCHEDULES: 'Disable schedules',
	CLEAR_SCHEDULES: 'Clear schedules',
	DISABLE_ALL: 'Disable all',
	DISABLED: 'Disabled',
	DELETE_ALL: 'Delete all',
	ENABLE_ALL: 'Enable all',
	ENABLED: 'Enabled',
	RECENTLY_USED: 'Recently used icons',
	ENABLE_NOTIFICATION: 'Enable notification',
	DISABLE_NOTIFICATION: 'Disable notification',
	// Actions
	CREATE: 'Create',
	UPDATE: 'Update',
	REMOVE: 'Remove',
	REMOVE_DISK: 'Remove disks',
	STRETCH: 'Stretch',
	DETAILS: 'Details',
	// Colors
	BLUE: 'Blue',
	GREEN: 'Green',
	ORANGE: 'Orange',
	RED: 'Red',
	TRANSPARENT: 'Transparent',
	WHITE: 'White',
	YELLOW: 'Yellow',
	// Week days
	MONDAY: 'Monday',
	TUESDAY: 'Tuesday',
	WEDNESDAY: 'Wednesday',
	THURSDAY: 'Thursday',
	FRIDAY: 'Friday',
	SATURDAY: 'Saturday',
	SUNDAY: 'Sunday',
	// Time
	SECOND: 'Second',
	SECONDS: 'Seconds',
	SEC: 'sec',
	MINUTE: 'Minute',
	MINUTES: 'Minutes',
	HOUR: 'Hour',
	HOURS: 'Hours',
	DAY: 'Day',
	TODAY: 'Today',
	LIST: 'List',
	DAYS: 'Days',
	QUARTER: 'Quarter',
	WEEK: 'Week',
	WEEKS: 'Weeks',
	DAYS_7: '7 days',
	DAYS_30: '30 days',
	BUSINESSWEEK: 'Businessweek',
	WEEKEND: 'Weekend',
	MONTH: 'Month',
	MONTHS: 'Months',
	YEAR: 'Year',
	YEARS: 'Years',
	TIME: 'Time',
	REASON_TEXT: 'Healthreason text',
	CREATED_TIME: 'Created time',
	VISIBILITY: 'Visbility',
	TIMEZONE: 'Time zone',
	TIMESETTINGS: 'Time settings',
	CUSTOM: 'Custom',
	LAST: 'Last',
	PERIOD: 'Period',
	SHORT_HOUR: 'h',
	SHORT_MINUTE: 'm',
	SHORT_SECOND: 's',
	SHORT_MILISECOND: 'ms',
	SHORT_DAY: 'D',
	SHORT_WEEK: 'W',
	SHORT_MONTH: 'M',
	//incident metrics values
	HIGH: 'High',
	LOW: 'Low',
	NORMAL: 'Normal',
	MEDIUM: 'Medium',
	MINIMUM: 'Minimum',
	PLANNING: 'Planning',
	RIGHT: 'Right',
	LEFT: 'Left',
	CENTER: 'Center',
	GRID_VIEW: 'Grid',
	CARD_VIEW: 'Card',
	ROWS: 'Rows',
	MAINTENANCE_PERIOD: 'Maintenance period',
	MAINTENANCE: 'Maintenance',
	EXCLUDED: 'Excluded',
	AUTO: 'AUTO',
	MONITOR_QUALIFIER: 'Monitor metric',
	PLAY_EVENTS: 'Play events',
	PAUSE_EVENTS: 'Pause events',
	INCLUDE_SUBACCOUNTS: 'Include subaccounts',
	MOVE_TO_SELECTED: 'Move to selected',
	ORIGINAL: 'Original',
	EVENT_SUMMARY: 'Event summary',
	LAST_CHANGES: 'Last changes',
	DEFAULT_MONITORING: 'Default',
	CUSTOM_MONITORING: 'Custom',
	NONE_MONITORING: 'None',
	MONITORING: 'Monitoring',
	REVERT: 'Revert',
	SERVICE_QUALIFIER_COUNT: 'Servicequalifier count',
	MONITOR_METRIC_COUNT: 'Monitor metric count',
	EXTERNAL_METRIC_COUNT: 'External metric count',
	IGNORE_USER_TAGS: 'Ignore active user tags',
	SHOW_UNTAGGED: 'Show untagged',
	RECIPIENTS: 'Recipients',
	AVAILABILITY: 'Availability',
	SET_AVAILABILITY: 'Set availability',
	ACTIVE_INACTIVE: 'Active/inactive',
	SERVICE_DOWNTIME: 'Service downtime',
	SERVICE_BREACHES: 'Service breaches',
	STATE_DURATION: 'State duration',
	SOURCE_ID: 'Source id',
	TARGET_ID: 'Target id',
	HEALTH: 'Health',
	ASSIGN_EVENT: 'Assign event',
	SUPPRESSION: 'Suppression',
	ASSIGNED_TO: 'Assigned to',
	ASSIGNED_TO_TYPE: 'Assigned type',
	IGNORE_ACTIVE_TAGS: 'Ignore active user tags',
	ASSET_GROUPS: 'Assetgroups',
	ACKNOWLEDGE: 'Acknowledge',
	ACKNOWLEDGED_BY: 'Acknowledged by',
	CREATED: 'Created',
	EXPORT_CSV: 'Export to CSV',
	DELETE_REASON: 'Delete healthreason',
	ERROR_MESSAGE: 'Error message',
	NOTIFICATION_MESSAGE: 'Servicelog message',
	AREA_CHART: 'Area',
	AREASPLINE_CHART: 'Area spline',
	LINE_CHART: 'Line',
	RANGE_CHART: 'Range',
	SPLINE_CHART: 'Spline chart',
	APPLY: 'Apply',
	FROM: 'From',
	TO: 'To',
	SHOW_ADVANCED_INFO: 'Show advanced information',
	HIDE_ADVANCED_INFO: 'Hide advanced information',
	ADVANCED_INFO_TITLE: 'Advanced information',
	SET_INFORMATION: 'Set Information',
	SCHEDULE: 'Schedule',
	ROOT_CAUSE_TOOLTIP: 'In order to see other rootcauses please use a smaller timeselection or zoom to the given time',
	CHOOSE_LOGO_IMAGE: 'Choose logo image',
	GLYPHICON_ICONS: 'Glyphicon icons',
	MATERIAL_ICONS: 'Material icons',
	COPY: 'Copy',
	CALENDAR: 'Calendar',
	METRIC: 'Metric',
	NOW: 'Now',
	ASSET_GROUP: 'Assetgroup',
	INFRASTRUCTURE: 'Infrastructure',
	HOLIDAY: 'Holiday',
	HISTORIC: 'Historic',
	VENDOR: 'Vendor',
	INSTALL_DATE: 'Install date',
	REGISTER_TIME: 'Register time',
	ACTION_TYPE: 'Action type',
	ACTION_DATA: 'Action data',
	NOTIFICATION_TRIGGER: 'Notificationtrigger',
	SET_IN_MAINTENANCE: 'Set in maintenance',
	END_MAINTENANCE: 'End maintenance',
	APPLICATION: 'Application',
	SCRIPT: 'Script',
	SEVERITIES: 'Severities',
	REQUIRED_FIELD: 'Required field',
	FILTERED: 'Filtered',
	LEGACY: ' (Legacy)',
	ADD_EVENT_TRIGGER: 'Add event actiontrigger',
	ADD_HEALTH_TRIGGER: 'Add health actiontrigger',
	CREATE_GROOVY_MONITOR: 'Create Groovy monitor',
	CREATE_POWERSHELL_MONITOR: 'Create PowerShell monitor',
	HEALTH_INDEX: 'Healthindex',
	PERSIST: 'Persist',
	messages: {
		COMMON_DIRTY_CONFIRMATION: 'You have unsaved content. You will lose the changes if you click close. Click cancel and save the changes if you want to keep the changes.',
		USE_NOTIFICATION_SCHEDULE: 'Use notification schedule...',
		NIMSOFT_INTERVAL_READONLY: 'Not available as Nimsoft QoS provides the interval',
		ACTIVE_TAGS: 'Tags are active in this view',
		ACTION_SUCCEEDED: 'Action succeeded',
		ACTION_REMOVE_DISKS: 'This will remove the selected disk(s) from the agent or template agent if it is an asset group.',
		CLEAR_CONTEXT_MESSAGE: 'User settings have been successfully cleared',
		DISABLED_SCHEDULE: 'Notification is disabled',
		RULE_NOT_EDITABLE: 'This type is only supported via script',
		EVENTSOURCE_CONNECTION_LOST: 'The connection with the server was lost. Please, reload the application.',
		SERVER_CONNECTION_LOST: 'The connection with the server was lost. Trying to re-establish the connection...',
		NO_RIGHTS: 'You don\'t have enough permissions to perform this action.',
		REQUIRED_FIELDS: 'Please fill the required fields',
		FILE_SIZE_LIMIT: 'The file must be less than 1 MB.',
		GAUGE_DATA_REPRESENTATION: 'When enable the breach value will render the area below breach value red. If disabled the area above breach value will be rendered red.',
		GAUGE_MIN_VALUE: 'Start value for gauge',
		GAUGE_MAX_VALUE: 'Maximum value for gauge',
		GAUGE_BREACH_VALUE: 'Values above is rendered red',
		SHOW_DESCRIPTION: 'Show description',
		SHOW_INFORMATION: 'Show information',
		SHOW_ACCOUNT_NAME: 'Show account name',
		INVALID_TIME: 'Invalid time',
		NO_IE_SUPPORT: 'Ceeview does not support Internet Explorer. The application will not work properly. Click OK continue into the application.',
		TAG_CHOOSE: 'Choose a tag...',
		TAG_NEW_EXISTING: 'Select from available tags or create new...',
		NO_TAGS: 'There are no tags',
		TAGS_SUCCESS_MESSAGE: 'Tags has been successfully set',
		PLEASE_INPUT_NAME: 'Please fill in profile name',
		PLEASE_INPUT_ACTION_SCRIPT: 'Please fill in action script',
		REASONS_ASSETS_ACKNOWLEDGED: 'Healthreason are acknowledged',
		REASONS_ASSETS_UNACKNOWLEDGED: 'Healthreason are unacknowledged',
		REASONS_ACKNOWLEDGED: 'Healthreason for asset #=assetName# are acknowledged',
		REASONS_UNACKNOWLEDGED: 'Healthreason for asset #=assetName# are unacknowledged',
		SERVICE_STATE_UP_TITLE: 'service is UP',
		SERVICES_STATE_UP_TITLE: 'services are UP',
		SERVICE_STATE_DOWN_TITLE: 'service is DOWN',
		SERVICES_STATE_DOWN_TITLE: 'services are DOWN',
		SERVICE_STATE_WARNING_TITLE: 'Service with WARNING',
		SERVICES_STATE_WARNING_TITLE: 'Services with WARNING',
		SERVICE_STATE_INACTIVE_TITLE: 'service are INACTIVE',
		SERVICES_STATE_INACTIVE_TITLE: 'services are INACTIVE',
		SERVICE_STATE_MAINTENANCE_TITLE: 'service is in MAINTENANCE',
		SERVICES_STATE_MAINTENANCE_TITLE: 'services are in MAINTENANCE',
		SERVICE_WARNING: 'Service warning',
		GRID_RELOADED: 'The grid has been reloaded',
		NO_DATA_AVAILABLE: 'No data available',
		SERVER_ERROR: 'Unexpected server error',
		DELAY_GRID: 'Delay grid update with ${interval} seconds',
		LEADING_TRAILING_WHITESPACES: 'Leading/trailing whitespace(s) in string',
		SERVICES_AGENT_DOWN: 'There are 1 or more services that have a system warning. These warnings can be agent in error, agent in warning, agent/asset in maintenance and qualifier error. Click to see the affected services.',
		ASSET_DOWN: 'There are 1 or more assets that have a system warning. These warnings can be agent in error, agent in warning and monitor error. Click to see the affected assets.',
		APPLICATION_DOWN: 'There are 1 or more assets/assetgroups that have a system warning. The warnings can be asset/assetgroup in maintenance or monitor error.',
		SERVICESUP_AGENTS_MAINTENANCE: '#= services# #= services === 1 ? "service" : "services"# in up state with 1 or more agents in maintenance',
		SERVICESW_WITH_BREACHES: '#= breaches# #= breaches === 1 ? "service" : "services"# in warning state with 1 or more servicequalifier that are breached',
		SERVICESW_AGENTS_MAINTENANCE: '#= services# #= services === 1 ? "service" : "services"# in warning state with 1 or more agents in maintenance',
		USER_HELPTEXT: 'LDAP type user must handle password in AD',
		EVENT_SUCCESSFULLY_ASSIGNED: 'Event was successfully assigned',
		EVENT_SUCCESSFULLY_UNASSIGNED: 'Event was successfully unassigned',
		REASON_SUCCESSFULLY_REMOVED: 'Healthreason was successfully removed',
		REASONS_SUCCESSFULLY_REMOVED: 'Healthreasons were successfully removed',
		ASSET_IN_MAINTENANCE: 'Asset is in maintenance',
		AUTHENTICATION_IN_USE: 'Authentication is used by servicequalifier, it can not be deleted',
		SLA_CONFIGURATION: 'SLA Configuration',
		INFO_SUCCESSFULLY_UPDATE: 'Information field successfully updated',
		MAINTENANCE_HISTORIC_NOT_EDITABLE: 'From and To time cannot be edited in a historic period',
		MAINTENANCE_HISTORIC_PERIODS: 'Historic periods',
		SET_ASSET_ALL_PROFILES: 'Please set asset in all profiles',
		HISTORIC_EVENT: 'This event is historic',
		CLICK_VIEW_ACTIONS: 'Click to view actions',
		CLEARED_ACTION_INFORMATION: 'Action information is cleared due to data retention setting',
		ROOT_CAUSE_NO_RECORDS: "There are no root cause for the time period."
	},
	// kendo
	kendo: {
		PAGER_DISPLAY: '{0} - {1} of {2} items',
		PAGER_EMPTY: 'No items to display',
		PAGER_PAGE: 'Page',
		PAGER_OF: 'of {0}',
		PAGER_ITEMS: 'items per page',
		PAGER_FIRST: 'Go to the first page',
		PAGER_PREVIOUS: 'Go to the previous page',
		PAGER_NEXT: 'Go to the next page',
		PAGER_LAST: 'Go to the last page',
		PAGER_REFRESH: 'Refresh',
		UPLOAD_IMAGE: 'Upload image',
		UPLOAD_SELECT: 'Select...',
		UPLOAD_STATUS_FAILED: 'Upload failed',
		UPLOAD_UPLOADED: 'Added',
		UPLOAD_UPLOADING: 'Uploading',
		UPLOAD_SELECTED_FILES: 'Add image',
		pager: {
			messages: {
				display: "{0} - {1} of {2} items",
				empty: "No items to display",
				page: "Page",
				of: "of {0}",
				itemsPerPage: "items per page",
				first: "Go to the first page",
				previous: "Go to the previous page",
				next: "Go to the next page",
				last: "Go to the last page",
				refresh: "Refresh"
			}
		},
		columnMenu: {
			messages: {
				columns: 'Columns',
				filter: 'Filter',
				sortAscending: 'Sort ascending',
				sortDescending: 'Sort descending'
			}
		}
	},
	// account
	account: {
		ACCOUNT_CREATE: 'Create account',
		ACCOUNT_NEW: 'New account',
		ACCOUNT_EDIT: 'Edit account',
		ACCOUNT_DELETE: 'Delete account',
		ACCOUNT_DELETE_CURRENT: 'You are not able to delete current account, it must be done from an account above current account context',
		ACCOUNT_DETAILS: 'Account detail',
		ACCOUNTS_DELETE: 'Delete account',
		ACCOUNT_ID: 'Account id',
		ACCOUNT_NAME: 'Account name',
		ACCOUNT_DESCRIPTION: 'Account description',
		ACCOUNT_STATUS: 'Account status',
		GLYPH_ICONS: 'System icons',
		USER_IMAGES: 'Uploaded images',
		SYSTEM_TYPE: 'System type',
		SYSTEM_TYPE_STANDARD: 'Standard',
		SYSTEM_TYPE_GENERIC: 'Generic',
		SYSTEM_TYPE_WINDOWS: 'Windows',
		SYSTEM_TYPE_UNIX: 'Unix',
		TARGETS: 'Targets',
		AGENT_NAME: 'Please, input the name of agent.',
		AGENT_DEPLOY: 'Deploy new agent',
		AGENT_CREATE: 'Create agent',
		ASSET_NAME: 'Asset name',
		ASSET_TYPE: 'Asset type',
		ASSET_DESCRIPTION: 'Asset description',
		ASSET_DETAILS: 'Asset details',
		ASSET_CREATE: 'Create asset',
		ASSET_EDIT: 'Edit asset',
		ASSET_NEW: 'New asset',
		ASSET_DELETE: 'Delete asset',
		ASSET_CREATE_AUTH: 'Create authentication',
		ASSET_GROUP_NAME: 'Assetgroup name',
		ASSET_GROUP_DESCRIPTION: 'Assetgroup description',
		ASSET_GROUP_NEW: 'New assetgroup',
		ASSET_GROUP: 'Assetgroup',
		ASSET_GROUPS: 'Assetgroups',
		ASSET_GROUP_CREATE: 'Create assetgroup',
		ASSET_GROUP_EDIT: 'Edit assetgroup',
		ASSET_GROUP_DELETE: 'Delete assetgroup',
		ASSET_GROUP_DETAILS: 'Assetgroup detail',
		ASSET_GROUP_ADD_TO: 'Add to assetgroup',
		ASSET_GROUP_REMOVE: 'Remove from assetgroup',
		ASSET_EXTERNAL_ID: 'External id',
		ASSET_EXTERNAL_TYPE: 'External type',
		ASSET_EXTERNAL_OWNER: 'External owner',
		AUTH_NAME: 'Authentication name',
		AUTH_DESCRIPTION: 'Authentication description',
		AUTH_SELECT: 'Select authentication...',
		AUTH_SELECT_TYPE: 'Select Authentication type...',
		AUTH_DOMAIN: 'Domain',
		AUTH_PARAM_1: 'Param1',
		AUTH_PARAM_2: 'Param2',
		AUTH_PARAM_3: 'Param3',
		AUTH_PARAM_4: 'Param4',
		AUTH_PARAM_5: 'Param5',
		AUTH_HOST: 'Host',
		AUTH_PORT: 'Port',
		AUTH_DATABASE: 'Database',
		AUTH_PRIVATE_KEY: 'Private key',
		AUTH_SECURITY_NAME: 'Security name',
		AUTH_DRIVER: 'Driver',
		AUTH_CONNECTION_STRING: 'Connection string',
		AUTH_CREATE: 'Create authentication',
		AUTH_EDIT: 'Edit authentication',
		AUTH_NEW: 'New authentication',
		AUTH_DELETE: 'Delete authentication',
		AUTH_DETAILS: 'Authentication detail',
		USER_EDIT: 'Edit user',
		USER_DELETE: 'Delete user',
		USER_ADD: 'Add new user to current account',
		USER_CREATE: 'Create user',
		USER_NEW: 'New user',
		USER_SELECT_ACCOUNT: 'Select account...',
		USER_SELECT_SERVICEBOARD: 'Select serviceboard...',
		USER_SELECT_CATEGORY: 'Select role...',
		USER_SELECT_LANGUAGE: 'Select user language...',
		USER_SELECT_TIMEZONE: 'Select time zone...',
		TEAM_NAME: 'Team name',
		TEAM_DESCRIPTION: 'Team description',
		TEAM_USERS: 'Team users',
		TEAM_CREATE: 'Create team',
		TEAM_UPDATE: 'Update team',
		TEAM_DELETE: 'Delete team(s)',
		TEAM_NEW: 'New team',
		TEAMS: 'Teams',
		TEAM_EDIT: 'Edit team',
		CATEGORY_USER_CREATE: 'Create role',
		CATEGORY_CREATE: 'Create role',
		SERVICE_MODEL_TEMPLATE_EDIT: 'Edit servicemodel template',
		SERVICE_MODEL_TEMPLATE_DELETE: 'Delete servicemodel template',
		SERVICE_MODEL_TEMPLATE_DETAILS: 'Servicemodel template details',
		SERVICE_ELEMENT_TEMPLATE_EDIT: 'Edit service element template',
		SERVICE_ELEMENT_TEMPLATE_DELETE: 'Delete service element template',
		SERVICE_ELEMENT_TEMPLATE_DETAILS: 'Service element template details',
		SERVICE_QUALIFIER_TEMPLATE_EDIT: 'Edit servicequalifier template',
		SERVICE_QUALIFIER_TEMPLATE_DELETE: 'Delete servicequalifier template',
		SERVICE_QUALIFIER_TEMPLATE_DETAILS: 'Servicequalifier template details',
		SUMMARY: 'Account summary',
		INSTALL_MODULE: 'Install module',
		UNINSTALL_MODULE: 'Uninstall module',
		NOTIFICATION_POLICY: 'Notification policy',
		CREATE_ASSET_GROUP: 'Create new assetgroup',
		ALL_ASSETS: 'All assets',
		SELECTED_ASSETS: 'Selected assets',
		MOVE_TO_SELECTED_ASSETS: 'Move to selected assets',
		ACCESS_KEY_ID: 'Access key id',
		SECRET_ACCESS_KEY: 'Secret access key',
		AWS_REGION: 'AWS region',
		AZURE_SUBSCRIPTION_ID: 'Subscription id',
		AZURE_TENANT: 'Tenant',
		AZURE_CLIENT: 'Client',
		AZURE_KEY: 'Key',
		GOOGLE_PROJECT_ID: 'Project id',
		GOOGLE_PRIVATE_KEY: 'Private key',
		GOOGLE_DATASET: 'Dataset',
		CALENDAR_CREATE: 'Create calendar',
		EVENT_CREATE: 'Create event',
		EVENT_EDIT: 'Edit event',
		messages: {
			LOGO_BEST_RESOLUTION: 'Best resolution for logo is 120px width by 40px height',
			CHOOSE_TEAMS: 'Choose teams...',
			CHANGE_DEFAULT_PASSWORD: 'Please set a strong password.',
			YOUR_FIRST_ACCOUNT: 'This will be your first account, set a name for it.',
			ACCOUNT_ENTER_NAME: 'Please, input the name of the account',
			ENTER_TEAM: 'Please, input a team name',
			ACCOUNT_TOP_MESSAGE: 'Please complete the following form in order to create a <strong>new account</strong>.',
			ACCOUNT_SUCCESS_CREATED: 'Account successfully created.',
			ACCOUNT_SUCCESS_UPDATED: 'Account information successfully updated.',
			ACCOUNT_SUCCESS_DELETED: 'Account successfully deleted.',
			ACCOUNT_REMOVE_CONFIRMATION: 'Are you sure you want to remove account?',
			ACCOUNTS_REMOVE_CONFIRMATION: 'Are you sure you want to remove accounts?',
			NO_SUBACCOUNTS: 'There are no child accounts.',
			NO_SUBACCOUNTS_RIGHT: 'You have insufficient rights to view subaccount list.',
			NO_ACCOUNTS: 'There are no accounts created.',
			NO_ASSETS_CREATED: 'There are no assets created.',
			NO_ASSET_GROUPS: 'There are no assetgroups created.',
			NO_AUTHENTICATIONS: 'There are no authentications created.',
			NO_SERVICES: 'There are no services available',
			NO_SERVICE_MODELS: 'There are no servicemodels created.',
			NO_SERVICE_ELEMENTS: 'There are no service elements created.',
			NO_SERVICE_QUALIFIERS: 'There are no servicequalifiers created.',
			NO_TEAMS: 'There are no teams created.',
			NO_USERS: 'There are no users created.',
			NO_USERS_RIGHT: 'You have insufficient rights to view user list.',
			NO_ASSETS: 'No assets found.',
			NO_ASSETS_RIGHT: 'You have insufficient rights to view asset list.',
			NO_ROLES: 'There are no roles created.',
			NO_ROLES_RIGHT: 'You have insufficient rights to view user roles list.',
			NO_AGENTS: 'There are no agents attached.',
			NO_AGENTS_RIGHT: 'You have insufficient rights to view agent list.',
			NO_AUTH: 'There are no authentications attached.',
			NO_AUTH_RIGHT: 'You have insufficient rights to view authentication list.',
			NO_RESULTS: 'No results matched',
			NO_TEMPLATES_DEFINED: 'There are currently no templates defined.',
			REQUIRED_FIELDS: 'Please complete all required fields.',
			ASSET_TOP_MESSAGE: 'Please complete the following form in order to create a <strong>new asset</strong>.',
			ASSET_TOP_MESSAGE_UPDATE: 'Please complete the following form in order update the asset\'s settings.',
			ASSET_UPLOAD_SELECT: 'Select an image to upload...',
			ASSET_FILE_TYPES: 'Only .jpg, .jpeg and .png files can be uploaded',
			TEMPLATE_FILE_TYPES: 'Only .zip files can be uploaded',
			ASSET_ADD_TARGETS: 'Please add target to your asset.',
			ASSET_REMOVE_CONFIRMATION: 'Are you sure you want to remove asset?',
			ASSETS_REMOVE_CONFIRMATION: 'Are you sure you want to remove assets?',
			ASSET_SUCCESS_DELETED: 'Asset successfully deleted.',
			ASSETS_SUCCESS_DELETED: 'Assets successfully deleted.',
			ASSET_SUCCESS_CREATED: 'Asset successfully created.',
			ASSET_SUCCESS_UPDATED: 'Asset information successfully saved.',
			ASSET_INPUT_NAME: 'Please, input the asset name.',
			EVENT_INPUT_NAME: 'Please, input the event name.',
			CALENDAR_INPUT_NAME: 'Please, input the calendar name.',
			ASSET_GROUP_TOP_MESSAGE: 'Please complete the following form in order to create a <strong>new assetgroup</strong>.',
			ASSET_GROUP_TOP_MESSAGE_UPDATE: 'Please complete the following form in order to update this assetgroup details.',
			ASSET_GROUP_INPUT_NAME: 'Please, input the name of the assetgroup.',
			ASSET_GROUP_NO_ASSETS: 'There are no assets in group.',
			ASSET_GROUP_ONE_ASSET: 'There are currently 1 asset in group.',
			ASSET_GROUP_COUNT_FIRST: 'There are currently,',
			ASSET_GROUP_COUNT_SECOND: 'assets in group.',
			ASSET_GROUP_CREATE_NO_RIGHTS: 'You don\'t have permissions to create a new assetgroup.',
			ASSET_GROUP_REMOVE_CONFIRMATION: 'Are you sure you want to remove assetgroup?',
			ASSETS_GROUP_REMOVE_CONFIRMATION: 'Are you sure you want to remove assetgroups?',
			ASSET_GROUP_SUCCESS_DELETED: 'Assetgroup successfully deleted.',
			ASSET_GROUPS_SUCCESS_DELETED: 'Assetgroups successfully deleted.',
			ASSET_GROUP_SUCCESS_CREATED: 'Assetgroup successfully created.',
			ASSET_GROUP_SUCCESS_UPDATED: 'Assetgroup successfully updated.',
			ASSET_GROUP_CHOOSE: 'Choose an assetgroup...',
			ASSET_GROUP_NO_GROUPS: 'There are no assetgroups',
			ASSET_DELETE_INFO: 'We have 3 dependency types for asset <br/>SOFT: Asset will be removed from monitor or application (Shape is left in application and can be manually removed)<br/>HARD: Monitor or servicequalifier is deleted when asset is deleted.<br/>MANUAL: Asset must manually be removed by user from monitor configuration.',
			ASSET_DELETE_TIED_AGENT: 'This asset belong to an agent. In order to delete first delete the agent',
			USER_DELETE_INFO: 'Please remove user ${name} from the following items before it can be deleted',
			ASSET_GROUP_DELETE_INFO: 'To delete assetgroup remove its dependencies. For application assetgroup will be removed, but you have to manually remove its shape.',
			AUTH_FORM_NAME: 'Please, input the name of the authentication.',
			AUTH_COMMUNITY_PASSPHRASE: 'Community passphrase',
			COMMUNITY_PW: 'Community PW',
			AUTH_PRIVACY_PASSPHRASE: 'Privacy passphrase',
			PRIVACY: 'Privacy',
			AUTH: 'Authentication',
			AUTH_PASSPHRASE: 'Auth passphrase',
			AUTH_REMOVE_CONFIRMATION: 'Are you sure you want to remove authentication?',
			AUTHS_REMOVE_CONFIRMATION: 'Are you sure you want to remove authentications?',
			AUTH_SUCCESS_DELETED: 'Authentication successfully deleted',
			AUTH_SUCCESS_CREATED: 'Authentication successfully created',
			AUTH_SUCCESS_UPDATED: 'Authentication successfully updated',
			AUTH_UNAVAILABLE: 'Authentications unavailable. Create a valid authentication before proceeding',
			AUTH_SELECT_AUTH_TYPE: 'Please, select an authentication type',
			AGENT_SUCCESS_CREATED: 'Agent successfully created',
			AGENT_SUCCESS_UPDATED: 'Agent successfully updated',
			AGENT_SUCCESS_DELETED: 'Agent successfully deleted',
			AGENT_REMOVE_CONFIRMATION: 'Are you sure you want to remove agent?',
			AGENT_DOWN: 'Agent is down',
			AGENT_ERROR: 'Agent Error',
			AGENT_WARNING: 'Agent in warning',
			QUALIFIER_ERROR: 'Qualifier error',
			MONITOR_ERROR: 'Monitor error',
			METRIC_ERROR: 'Metric error',
			MONITOR_ERROR_UNAVAILABLE: 'Monitor error: data unavailable',
			AGENT_MAINTENANCE: 'Agent in maintenance',
			ASSET_MAINTENANCE: 'Asset in maintenance',
			MONITOR_MAINTENANCE: 'Monitor in maintenance',
			MONITOR_DATA_UNAVAILABLE: 'Monitor data unavailable',
			AGENT_TOP_MESSAGE: 'Please complete the following form in order to create a <strong>new agent</strong>',
			AGENT_TOP_MESSAGE_UPDATE: 'Please complete the following form in order to update agent information',
			USER_TOP_MESSAGE: 'Please complete the following form in order to create a new user',
			USER_TOP_MESSAGE_UPDATE: 'Please complete the following form in order update the user profile',
			USER_SUCCESS_CREATED: 'User successfully created',
			USER_SUCCESS_SAVED: 'User profile successfully saved',
			USER_REMOVE_CONFIRMATION: 'Are you sure you want to remove user?',
			USERS_REMOVE_CONFIRMATION: 'Are you sure you want to remove users?',
			USER_SUCCESS_DELETED: 'User successfully deleted',
			USER_INPUT_USERNAME: 'Please, input username',
			USER_INPUT_FIRST_NAME: 'Please, input first name',
			USER_INPUT_LAST_NAME: 'Please, input last name',
			USER_PASSWORD_LENGTH: 'Password must have minimum 6 characters and not be identical to previous password',
			USER_PASSWORD_DIFF_USERNAME: 'The password must differ from username',
			USER_WRONG_PASSWORD: 'Confirmation password is wrong',
			USER_CHANGE_PASSWORD: 'Here you can change your password. <br /> Leave this field blank if you don\'t want to change<br/>your password',
			USER_PASSWORD_SUCCESSFULLY_CHANGED: 'Your password has been successfully updated',
			USER_PASSWORD_IDENTICAL: 'The two passwords must be identical',
			USER_PASSWORD_SPACES: 'The password must not contain blank spaces',
			USER_USERNAME_SPACES: 'The username must not contain blank spaces',
			USER_RETYPE_PASSWORD: 'Retype your password for verification',
			USER_INVALID_EMAIL: 'The email address is invalid',
			USER_COMPLETE_FIELDS: 'Please complete all required fields',
			USER_CATEGORY_UPDATE: 'role is updated',
			USER_CATEGORY_UPDATE_NO_RIGHTS: 'You don\'t have permissions to update roles',
			USER_CATEGORY_READ_NO_RIGHTS: 'You don\'t have permissions to read roles',
			USER_CATEGORY_REMOVE_CONFIRMATION: 'Are you sure you want to remove role?',
			USER_CATEGORIES_REMOVE_CONFIRMATION: 'Are you sure you want to remove roles?',
			USER_CATEGORY_SUCCESS_DELETED: 'Role was successfully deleted',
			USER_CATEGORIES_SUCCESS_DELETED: 'Roles were successfully deleted',
			TEAM_TOP_MESSAGE: 'Please complete form in order to create a <strong>new team</strong>',
			TEAM_TOP_MESSAGE_UPDATE: 'Please complete form in order to update the team information',
			TEAM_SUCCESS_CREATED: 'Team successfully created',
			TEAM_SUCCESS_UPDATED: 'Team successfully updated',
			TEAM_DELETE_INFO: 'Please remove team ${name} from the following items before it can be deleted',
			TEAM_REMOVE_CONFIRMATION: 'Are you sure you want to remove team?',
			TEAMS_REMOVE_CONFIRMATION: 'Are you sure you want to remove teams?',
			TEAM_SUCCESS_DELETED: 'Team successfully deleted',
			TEAM_NAME_STATUS: 'Please, input team name',
			TEAM_NO_USERS: 'There are no users in team',
			TEAM_ONE_USER: 'There is currently, 1 user in team',
			TEAM_USER_COUNT_FIRST: 'There are currently,',
			TEAM_USER_COUNT_SECOND: 'users in this team',
			CATEGORY_REMOVE_CONFIRMATION: 'Are you sure you want to remove role?',
			CATEGORY_ROLES_SUCCESS_UPDATED: 'Roles was updated',
			WARNING_USER_AUTH_CONNECTION_ISSUE: 'There might be a problem with your internet connection. Credentials not sent',
			SERVICE_ELEMENT_TEMPLATE_SUCCESS_DELETED: 'Service element template deleted',
			SERVICE_MODEL_TEMPLATE_REMOVE_CONFIRMATION: 'Are you sure you want to remove servicemodel template?',
			SERVICE_MODEL_TEMPLATE_SUCCESS_UPDATED: 'Servicemodel template successfully updated',
			SERVICE_MODEL_TEMPLATE_SUCCESS_DELETED: 'Servicemodel template was deleted',
			SERVICE_TEMPLATE_SUCCESS_UPLOADED: 'The import was successful. Please verify new templates in grid',
			INPUT_SERVICE_MODEL_TEMPLATE_NAME: 'Please input servicemodel template name',
			SERVICE_ELEMENT_TEMPLATE_REMOVE_CONFIRMATION: 'Are you sure you want to remove service element template?',
			SERVICE_ELEMENT_TEMPLATE_SUCCESS_UPDATED: 'Service element template successfully updated',
			INPUT_SERVICE_ELEMENT_TEMPLATE_NAME: 'Please input service element template name',
			SERVICE_QUALIFIER_TEMPLATE_REMOVE_CONFIRMATION: 'Are you sure you want to remove servicequalifier template?',
			SERVICE_QUALIFIER_TEMPLATE_SUCCESS_UPDATED: 'Servicequalifier template successfully updated',
			INPUT_SERVICE_QUALIFIER_TEMPLATE_NAME: 'Please input servicequalifier template name',
			MODULE_SUCCESS_INSTALL: 'Module successfully installed',
			MODULE_SUCCESS_UNINSTALL: 'Module uninstalled',
			MODULE_FAIL_INSTALL: 'Failed to install module',
			MODULE_FAIL_UNINSTALL: 'Failed to uninstall module',
			REASONS_ASSIGNED: 'The selected health reasons were successfully assigned',
			NOTIFICATIONS_REMOVE_CONFIRMATION: 'Are you sure you want to remove notifications?',
			DELETE_EVENT_TITLE: 'Delete operating time',
			DELETE_EVENT_CONFIRMATION: 'Are you sure you want to delete operating time?',
			DELETE_NOTIFICATION_CONFIRMATION: ' Click delete to delete notification',
			MARK_MESSAGES_READ: 'Mark messages as read',
			MARK_MESSAGES_UNREAD: 'Mark messages as unread',
			NON_REMOVABLE_TARGET: 'This target is used in one or more qualifiers and cannot be deleted',
			USER_CATEGORY_PRIVILEGES_INFO: 'This group has checked <strong>${checkedNr}</strong> out of <strong>${totalNr}</strong> permissions',
			PERMISSION_VERSION_CHECK: 'Requested change will migrate role to new permission scheme, please ensure that required menue items have been selected of cancel change',
			ENGINE_ID: 'Engine id',
			CONTEXT_ENGINE_ID: 'Context engine id',
			CONTEXT_NAME: 'Context name',
			SECURITY_LEVEL: 'Security level'
		}
	},
	// service board
	serviceBoard: {
		CREATE: 'Create',
		CREATE_DASHBOARD: 'Create dashboard',
		UPDATE: 'Update',
		SERVICE_BOARD: 'Serviceboard',
		DASHBOARD: 'Dashboard',
		ADD_WIDGET: 'Add widget',
		EDIT_WIDGET: 'Edit widget',
		EDIT_LAYOUT: 'Edit layout',
		CUSTOMIZE_SERVICE_BOARD: 'Properties',
		DESCRIPTION: 'Description',
		DETACH: 'Detach',
		SERVICE_BOARDS: 'Serviceboards',
		SERVICEBOARD_LINK: 'Serviceboard link',
		DELETE_SERVICE_BOARD: 'Delete serviceboard',
		DELETE_SERVICE_BOARDS: 'Delete serviceboard(s)',
		CREATE_SERVICE_BOARD: 'Create serviceboard',
		SET_HOMEPAGE: 'Set as homepage',
		CLEAR_HOMEPAGE: 'Clear as homepage',
		SET_PUBLIC: 'Set as public',
		VISIBILITY: 'Visibility',
		SET_PRIVATE: 'Set as private',
		IGNORE_MISSING_DATA: 'Ignore missing data',
		RAW_DATA: 'Raw data',
		ADVANCED_MODE: 'Advanced mode',
		SHOW_MISSING_DATA: 'Show missing data',
		TURN_ON_HEADER: 'Turn on header',
		TURN_OFF_HEADER: 'Turn off header',
		LOCKED_RESOLUTION: 'Locked resolution',
		FIT_SCREEN: 'Responsive resolution',
		MOBILE_RESOLUTION: 'Mobile resolution',
		MOVE: 'Move',
		SETTINGS: 'Settings',
		CHART_SETTINGS: 'Chart settings',
		AVAILABLE_METRICS: 'Available metrics',
		SLA_TIME: 'SLA time',
		LAST_UPDATE: 'Last update: ',
		LAST_UPDATE_SERVICE_BOARD: 'Serviceboard was last updated: ',
		SERVICE_DETAILS: 'Service details',
		MODEL_STATE: 'Model state',
		DATEFORMAT: 'Date format',
		TIMEFORMAT: 'Time format',
		ASSET_NAME: 'Asset name',
		ASSET_ID: 'Asset id',
		ACCOUNT: 'Account',
		SOURCENAME: 'Source name',
		AGENT_NAME: 'Agent name',
		AGENT_ID: 'Agent id',
		CATEGORY_ID: 'Category id',
		CATEGORY_NODE: 'Category node',
		CATEGORY_PATH: 'Category path',
		INSTANCE_NAME: 'Instance name',
		METRIC_ID: 'Metric id',
		METRIC_TYPE: 'Metric type',
		REGISTRY_IDENTIFIER: 'Registry identifier',
		REGISTRY_OWNER_ID: 'Registry owner id',
		REGISTRY_TYPE: 'Registry type',
		UNIT_TYPE: 'Unit type',
		UNIT_TYPE_SYMBOL: 'Unit type symbol',
		ADD_METRIC: 'Add metric',
		LOCK_TO_SCREEN: 'Lock to screen',
		SLIDE: 'Slide',
		OFF: 'Off',
		ELEMENTS_DOWN_WARNING: 'Elements down or in warning',
		QUALIFIERS_BREACHED_WARNING: 'Breached or in warning',
		messages: {
			MISSING_DATA_MESSAGE: 'Missing data will be represented with a grey field',
			FILL_TAG: 'Please, fill in a tag for serviceboard.',
			Fill_NAME: 'Please, fill in a name for the serviceboard.',
			SERVICE_BOARD_CREATED: 'Serviceboard successfully created.',
			SERVICE_BOARD_UPDATED: 'Serviceboard successfully updated',
			WIDGET_REMOVE: 'Are you sure you want to remove widget?',
			CREATE_SERVICE_BOARD_INFO: 'Create a new serviceboard to in order to monitor you servicemodels',
			DEPLOY_NEW_SERVICE_BOARD: 'Deploy new serviceboard',
			SERVICE_BOARD_REMOVE: 'Are you sure you want to delete this serviceboard?',
			SERVICE_BOARD_REMOVE_CONFIRMATION: 'Are you sure you want to delete dashboard?',
			SERVICE_BOARDS_REMOVE_CONFIRMATION: 'Are you sure you want to delete dashboards?',
			SERVICE_BOARD_REMOVED: 'Serviceboard(s) successfully deleted.',
			SERVICE_BOARD_SUCCESS_DELETED: 'Serviceboard(s) successfully deleted.',
			SERVICE_BOARD_SUCCESS_PUBLIC: 'Serviceboard(s) successfully set as public',
			SERVICE_BOARD_SUCCESS_PRIVATE: 'Serviceboard(s) successfully set as private',
			CREATE_YOUR_SERVICE_BOARD: 'Create your serviceboard',
			NO_DATA: 'No data available',
			NO_ITEMS_AVAILABLE: 'No items available.',
			NO_SERVICEBOARDS: 'There are no serviceboard created',
			COPY_LINK: 'Copy the link to open the serviceboard in another instance.',
			PROCEED_TO_EXIT: 'You may have unsaved changes to serviceboard layout. Are you sure you want to proceed?',
			EXIT_DIRTY_CONFIRMATION: 'You have unsaved content in the serviceboard. You will lose the changes if you click Close. Click Cancel and save the changes if you want to keep the changes.',
			NO_READ_PERMISSION: 'Missing permission. SERVICEBOARD_READ is required in order to view serviceboards',
			SELECT_ELEMENT: 'Please select at least one element',
			COPY_SERVICEBOARD: 'Duplicate dashboard',
			COPY_SERVICEBOARD_MESSAGE: 'Set a name and click create to create a duplicate of dashboard',
			COPY_SERVICEBOARD_SUCCESS: 'Dashboard was successfully duplicated',
			CONVERT_SERVICEBOARD: 'Convert serviceboard',
			CONVERT_SERVICEBOARD_MESSAGE: 'This operation will convert serviceboard ${name} to ${type} layout. The current layout will be lost.',
			CONVERT_SERVICEBOARD_SUCCESS: 'Serviceboard was successfully converted',
			DOES_NOT_APPLY_TO_MOBILE: 'Does not apply to mobile serviceboards',
			DASHBOARD_SUCCESSFULLY_IMPORTED: 'Dashboard was successfully imported.',
			DASHBOARD_IMPORT_ERROR: 'Not able to import dashboard. There might be an issue with the file.'
		}
	},
	// reports
	reports: {
		ALL_ACCOUNTS: 'All accounts',
		ALL_SERVICES: 'All services',
		CREATE_A_NEW_REPORT: 'Create a new report',
		CREATE_REPORT: 'Create Report',
		INCLUDE_ALL_SERVICES: 'Include all services',
		INCLUDE_SATE: 'Service state',
		INCLUDE_SLA: 'Sla current summary',
		INCLUDE_SLA_HISTORY: 'Sla Historic Summary',
		INCLUDE_SLA_HISTORY_DETAILS: 'Sla Historic Details',
		NONE: 'None',
		FIRST_PAGE: 'First page',
		LAST_PAGE: 'Last page',
		DATE_FROM: 'From',
		DATE_TO: 'To',
		DELETE_REPORT: 'Delete report(s)',
		EDIT_REPORT: 'Edit report',
		CREATED: 'Created',
		MODIFIED: 'Modified',
		SERVICE_SUMMARY: 'Service summary',
		DOWNLOAD_REPORT: 'Download report',
		SLA_SUMMARY: 'Sla summary',
		SLA_HISTORY: 'Sla history',
		SLA_DETAILS: 'Sla details',
		UPDATE_REPORT: 'Update report',
		COMMENT_SPOT: 'Comment spot',
		GENERATE_REPORT: 'Generate report',
		REPORT_TYPE: 'Report type',
		THIS_QUARTER: 'This quarter',
		THIS_MONTH: 'This month',
		THIS_WEEK: 'This week',
		TODAY: 'Today',
		LAST_QUARTER: 'Last quarter',
		LAST_MONTH: 'Last month',
		LAST_WEEK: 'Last week',
		LAST_DAY: 'Last day',
		YESTERDAY: 'Yesterday',
		LAST_7DAYS: 'Last 7 days',
		LAST_30DAYS: 'Last 30 days',
		DOWNLOAD_BROWSER: 'Download in browser',
		SEND_EMAIL: 'Send as email to',
		WAIT_PREVIEW: 'Wait for preview',
		REPORT_MAIL_SENT: 'When the report is generated it will be sent to',
		REPORT_DOWNLOAD_BROWSER: 'When the report is generated it will be downloaded in browser',
		OVERRIDE_CACHED_REPORT: 'Override cached report',
		CHOOSE_RECIPIENTS: 'Choose recipients...',
		DELIVERY_TYPE: 'Delivery type',
		DELIVERY_INFORMATION: 'Delivery information',
		messages: {
			REPORT_CREATED: 'The report is created.',
			REPORT_UPDATED: 'The report is updated.',
			SELECT_ACCOUNT: 'Please select an account...',
			FILTER_SELECT_SERVICE: 'Select service to filter report data...',
			SELECT_SERVICES: 'Please select services...',
			SELECT_SLAS: 'Please select SLA...',
			SELECT_ASSETS: 'Please select assets...',
			REPORT_DELETED: 'Report(s) successfully deleted.',
			NO_REPORTS: 'There are no reports available.',
			REPORT_REMOVE_CONFIRMATION: 'Are you sure you want to remove report?',
			REPORTS_REMOVE_CONFIRMATION: 'Are you sure you want to remove reports?',
			SCHEDULE_ENTER_NAME: 'Please input schedule name',
			NAME_REQUIRED: 'Please input schedule name',
			DYNAMIC_SERVICES_SELECTED: 'Dynamic services selected. All services are included',
			INCORRECT_DATE_FORMAT: 'Incorrect date format'
		},
		dynamicLabels: {
			aggregateLevel: 'Aggregated',
			textColor: 'Font color',
			pageBreak: 'Pagebreak',
			textSize: 'Font size',
			textFont: 'Font type',
			separatorVisible: 'Show separator',
			textString: 'Text string',
			pageBreakOnEachItem: 'Page break on each item',
			serviceStateVisible: 'Service state',
			serviceDescriptionVisible: 'Description',
			serviceInformationVisible: 'Information',
			assetInformationVisible: 'Information',
			slaSummaryVisible: 'Summary',
			slaExcludePeriodsVisible: 'Exclude periods',
			slaHistoryVisible: 'History',
			slaHistoryDetailsVisible: 'History details',
			assetHealthThreshold: 'Health threshold',
            assetHealthReasonLimit: 'Health reason limit',
			assetHealthDetailsVisible: 'Health details',
			currentSummaryVisible: 'Current summary',
			sortOnCompliance: 'Sort on compliance',
			showBreachesOnly: 'Breaches only',
			historicSummaryVisible: 'History summary',
			rootCauseVisible: 'Service root cause',
			dailyAggregateVisible: 'Daily aggregate',
			breachDetailsVisible: 'Breach details',
			serviceLogDetailsVisible: 'Servicelog entries',
			breachOverviewVisible: 'Breach overview',
			dateFormat: 'Date format',
			chartsWithoutDataVisible: 'Display charts with no data',
			automaticUnitScaling: 'Automatic unit scaling',
			healthIndexThreshold: 'Health threshold',
			assetTitleVisible: 'Asset title',
			multiChart: 'Multichart',
			rangeLow: 'Low range',
			chartType: 'Graph type',
			chartMode: 'Chart mode',
			chartHeight: 'Graph height',
			rangeHigh: 'High range',
			chartWidth: 'Graph width',
			showLabel: 'Label',
			title: 'Title',
			sortAscending: 'Sort ascending',
			servicePercentageFilter: 'Compliance filter(%)',
			show3D: 'Show 3D',
			showCurrentState: 'Show current state',
			monitorCalculationVisible: 'Show monitor calculation',
			assetCalculationVisible: 'Show asset calculation',
			showServiceElementInfo: 'Show service element info',
			showServiceQualifierInfo: 'Show servicequalifier info',
			serviceBreachOverviewVisible: 'Service breach overview',
			serviceDowntimeSummaryVisible: 'Service breach summary',
			serviceElementBreachOverviewVisible: 'Service element breach overview',
			headerType: 'Header type',
			slaPrecision: 'Number Of decimals',
			periodSpan: 'Historic periods',
			showDescription: 'Servicequalifier description',
			rootCauseReportMode: 'Report mode',
			assetNameFilter: 'Asset filter',
			skipIfNoData: 'Skip if no data',
			assetAccountNameVisible: 'Show account name',
			showUnacknowledgedOnly: 'Show unacknowledged only',
			showUnassignedOnly: 'Show unassigned only',
			maxEvents: 'Max events',
			targetFilter: 'Target filter',
			typeFilter: 'Type filter',
			showHealthIndex: 'Show healthindex',
			healthThreshold: 'Health threshold',
			healthReasonLimit: 'Health reason Limit',
			healthReasonsVisible: 'Health reasons visible',
			healthDetailsVisible: 'Health detail visible',
			showDetails: 'Show detail',
			monitorHealthVisible: 'Monitor health visible',
			severityFilter: 'Severity filter',
			showAsCard: 'Show as card',
			showChangesLastHours: 'Last hour changes',
			messageFilter: 'Message filter',
			showActionLog: 'Show actionlog',
			includeCurrentEvents: 'Include current events',
			includeHistoricEvents: 'Include historic events',
			useVerticalLabels: 'Use vertical Labels',
			categorizedByTime: 'Categorize by time',
			categorizedBySubsystem: 'Categorize by subsystem',
			categorizedByTarget: 'Categorize by target',
			categorizedByName: 'Categorize by name',
			categorizedByEventType: 'Categorize by eventtype',
			categorizedByMonitorType: 'Categorize by monitortype',
			nameSelector: 'Name',
			currency: 'Currency',
			budgetVisible: 'Show budget',
			costVisible: 'Show cost',
			totalVisible: 'Show total',
			listingPriceVisible: 'Show listprice',
			monthOrder: 'Ordering month',
			currentMonthFirst: 'Current month first',
			displayDecimals: 'Decimals',
			displayAs1000: 'Show in 1000',
			query: 'Query',
			showCurrentYear: 'Show current year',
			topN: 'Top N',
			showOther: 'Show other',
			useListingPrice: 'Listing price',
			estimatedCostVisible: 'Show estimated cost',
			actualCostVisible: 'Show actual cost',
			showLegend: 'Show legend',
			showTitle: 'Show title',
			pageBreakOnFirstItem: 'Page break on first item'
		}
	},
	// widget
	widget: {
		DECIMALS: 'Display decimal',
		CODE: 'Code',
		MESSAGE: 'Message',
		DATE: 'Date',
		STATUS: 'Status',
		TIME: 'Time',
		MODEL: 'Model',
		METRIC: 'Metric',
		SELECTED_METRICS: 'Selected metrics',
		PERIOD: 'Period',
		DISPLAY_RATE: 'Display rate',
		DISPLAY_SETTINGS: 'Display settings',
		PERIOD_COMPLETION: 'Period completion',
		SERVICE: 'Service',
		SERVICE_MODEL: 'Servicemodel',
		SERVICE_HISTORY: 'Servicehistory',
		STATE_CHANGE: 'State change',
		LAST_CHANGE_DURATION: 'Last change duration',
		LAST_CHANGE_TIME: 'Last change time',
		HIERARCHY: 'Hierarchy',
		PREVIEW: 'Preview',
		SUMMARY: 'Summary',
		ASSET_SUMMARY: 'Asset summary',
		GROUP_SUMMARY: 'Group summary',
		SERVICE_LEVEL_AGREEMENT: 'Service level agreement',
		SLA: 'SLA',
		PRESENTATION: 'Presentation',
		CREATE_PRESENTATION: 'Create presentation',
		CALCULATION_PERIOD: 'Calculation period',
		COLUMN_VISIBILITY: 'Column visibility',
		INCIDENT_VISIBILITY: 'Incident visiblity',
		HISTORY: 'History',
		UPDATE_WIDGET: 'Update widget',
		SELECT_ACCOUNT: 'Select account',
		SELECT_PROFILE: 'Select profile',
		SELECT_CATEGORY: 'Select category',
		SELECT_RULE_TYPE: 'Select rule type',
		SELECT_SAMPLE_RATE: 'Select samplerate',
		SELECT_KPI_ACCOUNTS: 'Select kpi Accounts',
		SELECT_SERVICE_MODEL: 'Select servicemodel',
		SELECT_SERVICE: 'Select service',
		SELECT_SLA: 'Select service level agreement',
		SELECT_TAGS: 'Select tags',
		SELECT_QUALIFIER: 'Select servicequalifier',
		ADD_QUALIFIER: 'Add servicequalifier',
		SELECT_MONITOR_QUALIFIER: 'Select monitor metric',
		SELECT_SERVICE_ELEMENT: 'Select service element',
		SELECT_ASSET: 'Select asset',
		SELECT_MONITOR_TYPE: 'Select monitortype',
		SELECT_MONITOR: 'Select monitor',
		SELECT_SUBSYSTEM: 'Select subsystem',
		SELECT_INSTANCE: 'Select instance',
		SELECT_METRICS: 'Select metric',
		SELECT_ASSET_GROUPS: 'Select assetgroup',
		SELECT_METRIC_CATEGORIES: 'Select metric categories',
		SELECT_MISSING_FIELD: 'Select value in missing field',
		SELECT_MISSING_FIELDS: 'Select value in missing fields',
		SELECT_CUSTOM_PERIOD_DATE: 'Select custom period date',
		EXPAND_ELEMENTS: 'Expand elements',
		METRIC_TYPE: 'Metric type',
		LINE: 'Line',
		AREA: 'Area',
		RANGE: 'Range',
		BREACH_VALUE: 'Breach value',
		MAX_VALUE: 'Max value',
		MIN_VALUE: 'Min value',
		INVERT: 'Invert',
		PERIODS: 'Periods',
		IMAGE_TEXT: 'Image and text',
		SYSTEM_MONITOR_CONSOLE: 'System monitor console',
		IBM_CONSOLE: 'IBM monitor console',
		HIDE_TIME_SELECTOR: 'Hide time selector',
		SELECT_BY_ASSET: 'Select by asset',
		SELECT_BY_MONITOR: 'Select by monitor',
		HIDE_HEADER: 'Hide header',
		HIDE_BORDER: 'Hide border',
		TRANSPARENT: 'Transparent',
		INCIDENT_COUNTER: 'Incident counter',
		SHOW_BREACHES: 'Show breach counter',
		SHOW_DOWNTIME: 'Show downtime counter',
		DISPLAY_INFORMATION: 'Display information',
		ADDITIONAL_INFORMATION: 'Additional information',
		STRETCH: 'Fit image',
		SELECT_IMAGE: 'Click to select image',
		AGGREGATION: 'Aggregation',
		CHART_TYPE: 'Chart type',
		HISTORY_SHOW_FOOTER: 'Show last change and duration time',
		DYNAMIC_SERVICE_INFO: 'All services will be displayed',
		errorCodes: {
			OK: 'OK',
			ERROR: 'Error',
			INFORMATION: 'Information',
			CONNECTION: 'Connection',
			CONNECTION_LOOKUP: 'Connection lookup',
			CONNECTION_REFUSED: 'Connection refused',
			CONNECTION_NO_ROUTE: 'Connection no route',
			CONNECTION_TIMEOUT: 'Connection timeout',
			CONNECTION_PORT_OUT_OF_RANGE: 'Connection port out of range',
			CONNECTION_PORT_UNREACHABLE: 'Connection port unreachable',
			AUTHENTICCATION: 'Authentication',
			CONFIGURATION: 'Configuration',
			AGENT: 'Agent',
			AGENT_DOWN: 'Agent down',
			AGENT_UNKNOWN: 'Agent unknown',
			METRICSTORE: 'MetricDB',
			HEALTHMONITOR: 'Health monitor',
			MESSAGEBUS: 'Messagebus',
			SYSTEM: 'System',
			UNKNOWN: 'Unknown'
		},
		ibm: {
			TITLE: 'IBM Monitor Console',
			SEQUENCE_NUMBER: 'Sequence number',
			SAMPLE_TIME: 'Sample time',
			OBJECT_NAME: 'Object name',
			EVENT_ID: 'Event id'
		},
		messages: {
			REGRESSION_LINE: 'Regression line',
			NO_ZOOM_AVAILABLE: 'Further zoom is not possible',
			CREATE_SERVICE_MODEL_FOR_HISTORY: 'Create servicemodel to see service history',
			CREATE_PREVIEW_WIDGET: 'To create preview widget, select a service',
			CREATE_SLA_WIDGET: 'To create a service level agreement widget, select a service level agreement',
			CREATE_SLA_HISTORY_WIDGET: 'To create a service level agreement history widget, select a service level agreement',
			CREATE_METRICS_WIDGET: 'To create metric widget, fill in the form below',
			CREATE_SUMMARY_WIDGET: 'To create summary widget, select services to represent',
			CREATE_SERVICE_HISTORY_WIDGET: 'To create service history widget, fill in the form below',
			CREATE_GAUGE_WIDGET: 'To create gauge widget, fill in the form below',
			CREATE_MULTIGRAPH_WIDGET: 'To create multigraph widget, fill in the form below',
			CREATE_SERVICES_WIDGET: 'To create services state widget, fill in the form below',
			CREATE_ASSET_HEALTH_WIDGET: 'to create health widget, select an account',
			CREATE_ASSET_CONSOLE_WIDGET: 'To create asset console widget, fill in the form below',
			CREATE_SERVICE_STATUS_WIDGET: 'To create service status widget, select an account',
			CREATE_SLA_SUMMARY_WIDGET: 'To create SLA summary widget, select an account',
			CREATE_HOME_GRID_WIDGET: 'Create home grid widget',
			CREATE_SERVICE_GRID_WIDGET: 'Create service grid widget',
			CREATE_HEALTH_GRID_WIDGET: 'Create health grid widget',
			CREATE_SLA_GRID_WIDGET: 'Create SLA grid widget',
			CREATE_TEXT_WIDGET: 'Create text widget',
			CREATE_IMAGE_WIDGET: 'Create image widget',
			FILL_IN_NUMERIC_MIN: 'Input a numeric value for min',
			FILL_IN_NUMERIC_BREACH: 'Input a numeric value for breach',
			FILL_IN_NUMERIC_MAX: 'Input a numeric value for max',
			FILL_IN_NUMERIC_PERIODS: 'Input a numeric value for periods',
			BREACH_VALUE_MAX: 'Breach value should not be greater than maximum value',
			MIN_VALUE_MAX: 'Min value should not be greater than maximum value',
			ASSETS_WITHOUT_HEALTHINDEX: 'Asset without healthindex:',
			TOTAL_CRITICAL_ASSETS: 'Total asset in CRITICAL state:',
			TOTAL_MAJOR_ASSETS: 'Total asset in MAJOR state:',
			TOTAL_MINOR_ASSETS: 'Total asset in MINOR state:',
			TOTAL_OK_ASSETS: 'Total asset in OK state:',
			TOTAL_MAINTENANCE_ASSETS: 'Total application in MAINTENANCE state:',
			TOTAL_CRITICAL_APPLICATIONS: 'Total application in CRITICAL state:',
			TOTAL_MAJOR_APPLICATIONS: 'Total application in MAJOR state:',
			TOTAL_MINOR_APPLICATIONS: 'Total application in MINOR state:',
			TOTAL_OK_APPLICATIONS: 'Total application in OK state:',
			TOTAL_INACTIVE_APPLICATIONS: 'Total application in INACTIVE state:',
			ASSET_GROUPS_WITHOUT_HEALTHINDEX: 'Asset groups without healthindex:',
			TOTAL_CRITICAL_ASSET_GROUPS: 'Total asset groups in CRITICAL state:',
			TOTAL_MAJOR_ASSET_GROUPS: 'Total asset groups in MAJOR state:',
			TOTAL_MINOR_ASSET_GROUPS: 'Total asset groups in MINOR state:',
			TOTAL_OK_ASSET_GROUPS: 'Total asset groups in OK state:',
			TOTAL_HIGH_INCIDENTS: 'Total incidents in HIGH state:',
			TOTAL_MEDIUM_INCIDENTS: 'Total incidents in MEDIUM state:',
			TOTAL_LOW_INCIDENTS: 'Total incidents in LOW state:',
			TOTAL_OK_INCIDENTS: 'Total incidents in OK state:',
			TOTAL_ASSETS: 'Total number of asset:',
			TOTAL_APPLICATION: 'Total number of application:',
			TOTAL_BREACHED_SLA: 'Total SLA in BREACHED state:',
			TOTAL_WARNING_SLA: 'Total SLA in WARNING state:',
			TOTAL_OK_SLA: 'Total SLA in COMPLIANT state:',
			TOTAL_SLA: 'Total SLA:',
			TOTAL_BREACHED_LINKEDSERVICE: 'Total linked services in BREACHED state:',
			TOTAL_WARNING_LINKEDSERVICE: 'Total linked services in WARNING state:',
			TOTAL_OK_LINKEDSERVICE: 'Total linked services in OK state:',
			TOTAL_LINKEDSERVICE: 'Total linked services:',
			WARNING_QUALIFIERS_DOWN: 'Display service in warning (yellow) if qualifier(s) are down',
			SHOW_COUNTER: 'Show incident counter per service',
			SHOW_OVERVIEW: 'Show incident overview on click',
			SHOW_CLOSED_INCIDENTS: 'Show closed incidents',
			OPEN_INCIDENTS: 'open incidents',
			CLOSED_INCIDENTS: 'closed incidents',
			COUNTER_CLICK: 'Click counter to see corresponding incidents',
			TREND: 'Show trend',
			MISSING_DATA: 'Ignore missing data',
			HIDE_ERRORS: 'Hide errors',
			THRESHOLD: 'Show threshold',
			MAXIMIZE_SETTINGS: 'Click here or maximize icon to see setting',
			SELECT_NEW_METRICS: 'Click here to select new metric',
			MAXIMUM_PERIODS_NUMBER: 'Maximum number of periods is ',
			AGGREGATION_MOUSEOVER: 'Aggregation: A data point in the chart can consist of multiple metrics depending on the timescale. By default we show the highest values'
		}
	},
	// service
	service: {
		STATE_QUALIFIER: 'State qualifier',
		STATE_MODEL: 'State model',
		STATE_ELEMENT: 'State element',
		SERVICE_INCIDENT: 'Service incident',
		START_SERVICE: 'Start service',
		STOP_SERVICE: 'Stop service',
		CREATE_SERVICE: 'Create Service',
		CREATE_A_NEW_SERVICE: 'Create a new service',
		CREATE_NEW_SERVICE: 'Create new service',
		DOCUMENTS: 'Documents',
		HAS_ATTACHMENTS: 'Has attachments',
		HAVE_DOCUMENTS: 'have documents',
		HAVE_NO_DOCUMENTS: 'does not have documents',
		ESTIMATED_COST: 'Cost',
		ESTIMATED_REVENUE: 'Revenue',
		RESPONSIBLE_TEAM: 'Team',
		BREACHED: 'Breached',
		IN_COMPLIANCE: 'In compliance',
		SERVICE_INCIDENTS: 'Service incidents',
		STACK_TRACE: 'Stack trace',
		CRITICAL: 'Critical',
		CAUSE: 'Cause',
		OTHERS: 'Others',
		WARNINGS: 'Warnings',
		CREATE_DOCUMENT: 'Create document',
		EDIT_DOCUMENT: 'Edit document',
		ECONOMY: 'Service economy',
		TIME: 'Time',
		TIME_FROM: 'Time from',
		TIME_TO: 'Time to',
		DURATION: 'Duration',
		SERVICE_TIME: 'Service time',
		CURRENT_TIME: 'Current time',
		CURRENT_STATE: 'Current state',
		STATUS: 'Status',
		MESSAGE: 'Message',
		MODEL_ERROR: 'Modelerror',
		MODEL_LOCKED: 'Model locked',
		NOTE: 'Note',
		SERVICE_LOG: 'Servicelog',
		LOG: 'Log',
		SYSTEM_ERROR: 'System message',
		MESSAGE_TEXT: 'Message',
		MESSAGE_TOKEN: 'Message token',
		DETAIL_TYPE: 'Type',
		EVENT_TYPE: 'Event type',
		ICON: 'Icon',
		TYPE: 'Type',
		SIZE: 'Size',
		SLA_NO_TEAM: '< Team >',
		SET_TEAM: 'Click here to set team',
		COMPLIANCE_GOAL: 'Compliance goal',
		ACTUAL_COMPLIANCE: 'Actual compliance',
		CREATE_SLA: 'Create SLA',
		DELETE_SLA: 'Delete SLA',
		SLA: 'SLA',
		SLA_FULL: 'Service level agreement',
		PERIOD: 'Period',
		SLA_ALL_DAY: 'All day',
		SLA_EXCLUDE: 'Exclude',
		SLA_CUSTOM: 'Custom',
		SERVICE_EDITOR: 'Editor',
		ADD_NOTE: 'Add log entry',
		SHOW_ALL_LOGS: 'Show all',
		SHOW_UNREAD_LOGS: 'Show not acknowledged',
		ACKNOWLEDGED: 'Acknowledged',
		ACKNOWLEDGED_DATE: 'Acknowledged date',
		ACKNOWLEDGED_BY: 'Acknowledged by',
		DELETE_SERVICE: 'Delete service',
		SERVICE_LOG_DETAILS: 'Servicelog details',
		INCIDENT: 'Incident',
		AGENT_INVALID: 'Agent is invalid',
		QUALIFIER_INVALID: 'Servicequalifier is invalid',
		QUALIFIER_BREACHED: 'Servicequalifier is breached',
		STATE_CHANGE: 'State change',
		CREATE_MAINTENANCE_PERIOD: 'Create maintenance period',
		UPDATE_MAINTENANCE_PERIOD: 'Update maintenance period',
		DELETE_MAINTENANCE_PERIOD: 'Delete maintenance period',
		OPERATING_EDITOR_TITLE: 'Create operating time for service',
		OPERATING_PERIOD_FOR_SERVICE: 'Create operating period for service',
		IN_MAINTENANCE: 'In maintenance',
		ADD_EXCLUDE_PERIODS: 'Add exclude period for affected SLA',
		ADD_EXCLUDE_PERIODS_TOOLTIP: 'Exclude period is automatically set by maintenance period in service: ',
		OPERATIONAL: 'Operational',
		NON_OPERATIONAL: 'Non-operational',
		OUTSIDE_OPERATION_PERIOD: 'Outside operating period',
		OPERATIONAL_STATE: 'Active',
		MODE: 'Mode',
		QUALIFIER: 'Qualifier',
		COVERAGE: 'Coverage',
		NO_QUALIFIERS: 'No qualifiers',
		NO_SERVICE_QUALIFIERS_SELECTED: 'No servicequalifiers selected',
		ACKNOWLEDGE_ALL_SERVICE_LOGS: 'Acknowledge all servicelogs',
		TEAM_VALIDATE: 'Please select a team',
		RECALCULATE_SERVICES: 'Recalculating services ${i} of ${length}. Be patient this might take some time.',
		RECALCULATE_SERVICE: 'Recalculating service ${i} of ${length}. Be patient this might take some time.',
		RECALCULATE_DONE: '${i} of ${length} services have been recalculated.',
		LINKED_SERVICE_NA: 'N/A - Service link',
		PERIOD_IS_EXCLUDED: 'Outside current SLA calculation period.',
		SERVICE_PERIOD_IS_EXCLUDED: 'Outside current service calculation period.',
		sharedQualifiers: {
			DELETE_POPUP_TITLE: 'Servicequalifier delete/unlink',
			CONVERT_POPUP_TITLE: 'Servicequalifier convert',
			SHARE_POPUP_TITLE: 'Servicequalifier share',
			EDIT_SHARED_QUALIFIER: 'Servicequalifier is shared. Changes will affect all places servicequalifier is referred. Are you sure you want to continue?',
			READ_ONLY_SHARED_QUALIFIER: 'In order to edit new shared servicequalifer you have to update servicemodel first.'
		},
		messages: {
			SHARED_SERVICE: 'When service is shared it is available as link object in a subaccount servicemodel.',
			SERVICE_CREATE_SELECTING: 'Create a new service by selecting a servicemodel and associated SLA',

			SERVICE_MODEL_READ_NO_RIGHTS: 'You don\'t have permissions to view servicemodel',
			SERVICE_MODEL_CREATE_NO_RIGHTS: 'You don\'t have permissions to create servicemodel',
			ENTER_NAME: 'Input service name',
			ENTER_TEAM: 'Input team name',
			ENTER_MESSAGE: 'Enter message',
			SERVICE_TOP_MESSAGE_UPDATE: 'Complete form to update service setting',
			SERVICE_REMOVE_CONFIRMATION: 'Are you sure you want to remove service?',
			SERVICES_REMOVE_CONFIRMATION: 'Are you sure you want to remove services?',
			SERVICE_SUCCESS_DELETED: 'Service successfully deleted',
			SERVICE_SUCCESS_CREATED: 'Service successfully created',
			SERVICE_SUCCESS_UPDATED: 'Service successfully updated',
			DOCUMENT_SHOW: 'Document set to display in configuration',
			DOCUMENT_HIDE: 'Document removed from display in configuration',
			DOCUMENT_TOP_MESSAGE_UPDATE: 'Complete form in order to update document setting',
			DOCUMENT_CREATE_NEW: 'Create a new document by uploading a file',
			DOCUMENT_TEMP_SAVED: 'Document is temporary saved',
			DOCUMENT_TEMP_NOT_SAVED: 'Upload failed',
			DOCUMENT_SUCCESS_CREATED: 'Document successfully created',
			DOCUMENT_SUCCESS_UPDATED: 'Document successfully updated',
			DOCUMENT_SUCCESS_DELETED: 'Document successfully deleted',
			DOCUMENT_DELETE_CONFIRMATION: 'Are you sure you want to delete attached document?',
			SLA_TOP_MESSAGE: 'Complete form to create a <strong>new service level agreement</strong>',
			SLA_TOP_MESSAGE_UPDATE: 'Complete form to update service level agreement setting',
			FILL_IN_VALUES: 'Fill in the correct values in field',
			CREATE_NEW_SLA: 'Create new service level agreement',
			CREATE_SLA: 'Create service level agreement',
			DEPLOY_NEW_SLA: 'Deploy new service level agreement',
			EDIT_SLA: 'Edit service level agreement',
			SLA_REMOVE_CONFIRM: 'Are you sure you want to remove SLA?',
			SLA_DELETE_CONFIRM: 'Are you sure you want to delete the attached SLA?',
			SLA_DELETED: 'SLA successfully deleted',
			SLA_CREATED: 'SLA successfully created',
			SLA_UPDATED: 'SLA successfully updated',
			SLA_NOT_DEFINED: 'There are no SLA. Click the + button to create SLA',
			SERVICE_ASSETS_DISPLAYED: 'Service assets will be displayed when a service model is created',
			SERVICE_MODEL_CREATE_PERMISIONS: 'You do not have permission to create a new service model',
			SERVICE_MODEL_UPDATE_PERMISIONS: 'You do not have permission to update a service model',
			SERVICE_SAVE_FIRST: 'Update the service first.',
			SERVICE_MODEL_DELETE_CONFIRMATION: 'Are you sure you want to delete the attached servicemodel?',
			SERVICE_MODEL_DELETE_CONFIRMATION_DETAIL: 'This will also delete the related SLAs.',
			SERVICE_MODEL_SUCCESS_DELETED: 'Servicemodel successfully deleted.',
            SERVICE_MODEL_RECALCULATE_CONFIRMATION_DETAIL: 'Select time you want to recalculate service from. This will change service and service element state if servicequalifiers has been added or deleted.',
			SLA_CREATE_PERMISIONS: 'You do not have permission to create a SLA',
			SLA_UPDATE_PERMISIONS: 'You do not have permission to update a SLA',
			SLA_DELETE_PERMISIONS: 'You do not have permission to delete a SLA',
			CLICK_TO_EDIT: 'Click here to edit',
			NOT_ESTIMATED: 'Not estimated',
			UPLOAD_FILE: 'Please, upload a file.',
			PLEASE_SELECT_SERVICE: 'Please select service',
			MAINTENANCES_SUCCESS_DELETED: 'Maintenance period successfully deleted.',
			MAINTENANCES_SUCCESS_ENDED: 'Maintenance period successfully ended.',
			MAINTENANCES_NOT_HISTORIC_SUCCESS_ENDED: 'Current and future maintenance period successfully ended.',
			MAINTENANCE_SUCCESS_SAVED: 'Maintenance period successfully saved.',
			MAINTENANCE_PERIOD_IN_THE_PAST: 'Maintenance period can not be historic.',
			NO_SLA: 'There are no service level agreements',
			NO_SERVICE_ASSETS: 'There are no service asset',
			NO_SERVICE_INCIDENTS: 'There are no service incident',
			NO_SERVICE_DOCUMENTS: 'There are no Service document',
			NO_SERVICE_LOGS: 'There are no servicelog element',
			DELETE_PERIOD_CONFIRMATION: 'Are you sure you want to delete period?',
			DELETE_PERIOD: 'Delete period',
			ENTER_LOG_ENTRY: 'Enter the log entry message here...',
			NO_RECORDS: "No services are created for account. Click here or in menu to create service.",
			NO_DOCUMENTS: "No documents are created in service. Click here or in menu to add a document.",
			NO_MAINTENANCE_PERIODS: "No maintenance periods created in service. Click here or in menu to create a maintenance period.",
			NO_OVERRIDES: "No State Overrides created in service. Click here or in menu to create a state override.",
			NO_LOGS: "No servicelog entries for the given interval.",
			NO_SERVICE_REVISIONS: "No Revisions created in service. A revision is created when you update servicemodel."
		}
	},
	// service states
	selectiveservices: {
		tip: {
			SHOW_DESCRIPTION: 'Show service description in widget',
			SHOW_INFORMATION: 'Show information',
			SHOW_ACCOUNT_NAME: 'Show account name in widget',
			ALL_ACCOUNTS: 'All accounts at current time is added to the selection',
			ALL_SERVICES: 'All services at current time is added to the selection',
			DYNAMIC_SERVICES: 'New/deleted service from the selected account(s)will automatically be added/removed from the widget'
		}
	},
	//monitors
	monitor: {
		description: 'Optional, provide a meaningful description for the purpose of the monitor',
		FOLDER_SELECTION_TITLE: 'Folder',
		CREATE_NEW_FORMAT: 'Create new format',
		LINE: 'Line',
		system: {
			REMOVE_DISK_ASSET: 'Remove disk from asset',
			REMOVE_DISK_ASSET_MSG: 'Disk was successfully removed from asset',
			DISK_AVAILABLE: 'Disk available',
			DISK_LATENCY: 'Disk average latency',
			DISK_READ_LATENCY: 'Disk read latency',
			DISK_WRITE_LATENCY: 'Disk write latency',
			DISK_QUEUE_LENGTH: 'Disk average queue length',
			DISK_READ_QUEUE_LENGTH: 'Disk read queue length',
			DISK_WRITE_QUEUE_LENGTH: 'Disk write queue length',
			DISK_USAGE_MB: 'Disk usage MB',
			DISK_USAGE_PERCENT: 'Disk usage %',
			DISK_FREE_MB: 'Disk free MB',
			DISK_FREE_PERCENT: 'Disk free %',
			DISK_INODES_FREE: 'Inodes free',
			DISK_INODES_USED: 'Inodes used',
			DISK_INODES_USED_PERCENT: 'Inodes used %',
			DISK_INODES_FREE_PERCENT: 'Inodes free %',
			DISK_SIZE: 'Disk size'
		}
	},
	// designer
	designer: {
		SHOW_TEST_RESULTS: 'Show test results',
		CREATE_NEW_ASSET: 'Create new asset',
		CREATE_NEW_AUTHENTICATION: 'Create new authentication',
		CREATE_NEW_ASSET_NOT_AVAILABLE: 'Create new asset is not available in update mode',
		CREATE_NEW_TARGET_NOT_AVAILABLE: 'Create new target is not available in update mode',
		ADD_MULTIPLE_ASSSETS: 'Add multiple assets',
		CREATE_NEW_TARGET: 'Create new target',
		COPY_ELEMENT: 'Copy element',
		PASTE_ELEMENT: 'Paste element',
		CREATE_TEMPLATE: 'Create template',
		CREATE_MULTIPLE_QUALIFIERS: 'Create multiple servicequalifiers',
		MULTIPLE_QUALIFIERS: 'Multiple servicequalifiers',
		DEFAULT: 'Default',
		RULESET: 'Ruleset',
		WEIGHTED: 'Weighted',
		STATE_EXPRESSION_RULESET: 'State expression ruleset',
		COUNTER: 'Counter',
		TOP_LEFT: 'top left',
		NEW_SERVICE_MODEL: 'New servicemodel',
		SERVICES: 'Services',
		SERVICE_MODEL_TEMPLATE: 'Servicemodel template',
		SERVICE_MODEL_TEMPLATES: 'Servicemodel templates',
		SERVICE_ELEMENT_TEMPLATES: 'Service element templates',
		SERVICE_QUALIFIER_TEMPLATES: 'Servicequalifier templates',
		SERVICE_QUALIFIER_WIZARD: 'Servicequalifier wizard',
		SERVICE_ELEMENT_RULE: 'Service element rule',
		SERVICE_ELEMENT: 'Service Element',
		SERVICE_ELEMENT_BLANK: 'Create blank element',
		SERVICE_ELEMENT_TEMPLATE: 'Create template element',
		NEW_SERVICE_ELEMENT: 'Create service element',
		SERVICE_MODEL_LINK: 'Servicemodel link',
		SERVICE_QUALIFIERS: 'Servicequalifiers',
		SHARED_QUALIFIERS: 'Shared servicequalifiers',
		SHARED_SERVICE_QUALIFIERS: 'Shared servicequalifiers',
		SHARED_SERVICE_QUALIFIER: 'Shared servicequalifier',
		SERVICE_QUALIFIER: 'Servicequalifier',
		SERVICE_QUALIFIERS_SETTINGS_GENERAL: 'General',
		SERVICE_QUALIFIERS_SETTINGS_MEASURING_AGENT: 'Measuring agent',
		SERVICE_QUALIFIERS_SETTINGS_MEASURED_TARGET: 'Measured target',
		SERVICE_QUALIFIERS_SETTINGS_TIME: 'Time',
		SERVICE_QUALIFIERS_SETTINGS_BREACH_THRESHOLD: 'Breach threshold',
		SERVICE_QUALIFIERS_SETTINGS_WARNING_THRESHOLD_LINK: 'Click to add a warning threshold',
		SERVICE_QUALIFIERS_SETTINGS_WARNING_THRESHOLD: 'Warning threshold',
		SERVICE_QUALIFIERS_SETTINGS_WARNING_CONDITION: 'Warning condition',
		SERVICE_QUALIFIERS_SETTINGS_WARNING_OPERATOR: 'Warning operator',
		SERVICE_QUALIFIERS_SETTINGS_PARAMETERS: 'Parameters',
		SERVICE_QUALIFIERS_SETTINGS_AUTHENTICATION: 'Authentication',
		SAVE_AS_TEMPLATE: 'Save as template',
		SAVE_MODEL_AS_TEMPLATE: 'Save model as template',
		SAVE_ELEMENTS_AS_TEMPLATE: 'Save element as template',
		SAVE_SM_AS_TEMPLATE: 'Save servicemodel as template',
		SAVE_SQ_AS_TEMPLATE: 'Servicequalifier template',
		SAVE_SE_AS_TEMPLATE: 'Save service element as template',
		DRAG_LOCK: 'Drag lock',
		CLICK_HERE: 'Click here',
		OPERATION_AND_GROUPING: 'Operation and grouping',
		SELECT_AGENT: 'Select agent...',
		SELECT_ASSET_TARGET: 'Select assettarget...',
		SELECT_ASSET: 'Select asset...',
		SELECT_ASSET_GROUP: 'Select assetgroup...',
		SELECT_AUTHENTICATION: 'Select authentication',
		CHANGE_TYPE: 'Change type',
		AVAILABLE: 'Available',
		STATIC_THRESHOLD: 'Static threshold',
		DYNAMIC_THRESHOLD: 'Dynamic threshold',
		RANGE_THRESHOLD: 'Range threshold',
		DELTA_THRESHOLD: 'Delta threshold',
		PERCENTILE_THRESHOLD: 'Percentile threshold',
		NONE: 'None',
		OPERATOR: 'Operator',
		OPERAND: 'Operand',
		THRESHOLD: 'Threshold',
		FACTOR: 'Factor',
		INTERVAL: 'Interval',
		ALGORITHM: 'Algorithm',
		CUSTOM_TEXT: 'Custom text',
		INCLUDE_CHILDREN: 'Include children service element',
		INCLUDE_BACKGROUND: 'Include background',
		INCLUDE_ICONS: 'Include icon',
		INCLUDE_SERVICE_QUALIFIERS: 'Include servicequalifier',
		INCLUDE_STATE_EXPRESSION_RULES: 'Include state expression rule',
		ADD_CATEGORY: 'Add category',
		ACCESS: 'Access',
		BREACHES: 'Breaches',
		CONSECUTIVE_BREACHES: 'Consecutive breaches',
		DATA_REPRESENTATION: 'Data representation',
		//MINIMUM_AVAILABLE_SERVICE_ELEMENTS: 'Minimum number of service elements with AVAILABLE (green) state, for the parent element to be AVAILABLE',
		MINIMUM_AVAILABLE_SERVICE_ELEMENTS: 'Number of service elements with AVAILABLE (green) state, for the parent element to be AVAILABLE',
		//MINIMUM_AVAILABLE_SERVICE_QUALIFIERS: 'Minimum number of service qualifiers with AVAILABLE (green) state, for the element to be AVAILABLE',
		MINIMUM_AVAILABLE_SERVICE_QUALIFIERS: 'Number of servicequalifiers with AVAILABLE (green) state, for the element to be AVAILABLE',
		//PERCENTAGE_AVAILABLE_SERVICE_ELEMENTS: 'Required percentage of service elements with AVAILABLE (green) state, for the parent element to be AVAILABLE',
		PERCENTAGE_AVAILABLE_SERVICE_ELEMENTS: 'Required percentage of service elements with AVAILABLE (green) state, for the parent element to be AVAILABLE',
		//PERCENTAGE_AVAILABLE_SERVICE_QUALIFIERS: 'Required percentage of service qualifiers with AVAILABLE (green) state, for the element to be AVAILABLE',
		PERCENTAGE_AVAILABLE_SERVICE_QUALIFIERS: 'Required percentage of servicequalifiers with AVAILABLE (green) state, for the element to be AVAILABLE',
        AGGREGATE_FROM: 'Aggregate state from',
        SERVICE_ELEMENTS: 'Service elements',
		COVERAGE_WARNING: 'Show warning',
		COVERAGE_WARNING_MOUSEOVER: 'Show warning: This only applies if aggregate state is from servicequalifiers and ruleset is set. If checked the element\n' +
		'will be in warningstate (orange) if 1:m servicequalifier is breached, but due to ruleset does not trigger a breached state in the \n' +
		'serviceelement. ',
		EXIT_DIRTY_CONFIRMATION: 'You have unsaved content in designer. You will lose changes if you click Close. Click Cancel and save the changes if you want to keep the changes.',
		INPUT_NAME: 'Please input name...',
		messages: {
			SQ_ASSETNEW_BUTTON: 'Create new asset',
			SQ_ASSETNEWTARGET_BUTTON: 'Create new target for selected asset',
			SQ_ASSETNEWAUTH_BUTTON: 'Create new authentication',
			MULTIPLE_ASSETS_CLOSE: 'Closing the window will clear selected assets from the servicequalifier configuration',
			MULTIPLE_ASSETS_ENABLED: 'Click to create multiple servicequalifiers. Select multiple assets from list',
			MULTIPLE_ASSETS_DISABLED: 'Set a target above in order to create multiple servicequalifiers. Target will be used in test configuration',
			MULTIPLE_ASSETS_GRID: 'Select 1:m asset/targets below to include them in the servicequalifier configuration. Note that each selected asset creates identical servicequalifier.',
			LOCKED_MODEL: 'Servicemodel is locked by another user. You can not save your changes. Click OK to exit to service details.',
			TEMPLATE_ENTER_NAME: 'Input template name',
			TEMPLATE_CATEGORY_ENTER_NAME: 'Input category name',
			INPUT_SERVICE_MODEL_NAME: 'Input servicemodel name: ',
			INPUT_SERVICE_MODEL_TEMPLATE_NAME: 'Input servicemodel template name:',
			INPUT_SERVICE_ELEMENT_NAME: 'Input service element name',
			INPUT_CONNECTION_NAME: 'Input connection name',
			INPUT_NAME_PLACEHOLDER: 'input name here',
			LOADING_SERVICE_MODEL: 'Loading servicemodel',
			WARNING_CYCLE_DETECTED: 'There was a cycle detected. Connection is not possible. Please review your servicemodel.',
			WARNING_DUPLICATE_CONNECTION: 'There already exists a connection between the service elements',
			WARNING_SERVICE_MODEL_PATH_TO_ROOT: 'There are service elements not connected to the root element. Please update your connections.',
			WARNING_SERVICE_MODEL_CHANGES: 'You have made changes to the current servicemodel. Please make sure to save your changes.',
			WARNING_SERVICE_MODEL_NAME: 'Input servicemodel name in property form.',
			INVALID_STATE_EXPRESSION_RULE: 'The custom state expression rule for the selected element is invalid, please change the rule to reflect servicequalifier belonging to element.',
			LEAVE_PAGE_CONFIRMATION: 'Are you sure you want to leave this page? You will lose any unsaved changes.',
			STATE_AGGREGATED_CONFIRMATION: 'Are you sure you want to change the state to aggregated for this element? All servicequalifiers will be removed and state rule will be cleared from this element.',
			STATE_NON_AGGREGATED_CONFIRMATION: 'Are you sure you want to change the state to non-aggregated? The state rule will be cleared',
			WARNING_SERVICE_ELEMENT_AGGREGATED: 'The service element is aggregated and does not allow servicequalifiers',
			WARNING_CLEAR_AGGREGATE: 'This will remove aggregation and state rule',
			WRONG_PARAMETER_ORDER: 'Invalid rule. Wrong parameter order',
			UNEVEN_CLOSIG_BRACKETS: 'Invalid rule. Uneven closing brackets',
			SERVICE_MODEL_NOT_FOUND: 'Servicemodel not found',
			QUALIFIER_TEMPLATE_SAVED: 'Servicequalifier template saved',
			NO_TEMPLATES_DEFINED: 'There are no templates defined. Create a new servicemodel using a blank template',
			QUALIFIER_TEMPLATE_ENTER_CATEGORY: 'Select a category',
			SELECT_PARENT_CATEGORY: 'Select a parent category',
			TEMPLATE_SAVED: 'Template saved',
			TEMPLATE_WARNING: 'Servicequalifier is modified and must be saved before creating a template. Click OK to save servicequalifer (and all other changes done in servicemodel) to continue. Click cancel to return not designer and not create the template.',
			INVALID_ASSET_TARGET: 'Target not found',
			INVALID_ASSET: 'Asset not found',
			CHANGE_ACCOUNT_FOR_SQ: 'Change account in order to create a servicequalifier',
			DELETED_SERVICEQUALIFIER: 'Servicequalifier is deleted',
			SHARED_SERVICEQUALIFIER: 'Servicequalifier is shared',
			ACCOUNT_CONTEXT_CHANGED: 'Account context has been changed in order to edit',
			CONVERTED_SERVICEQUALIFIER: 'Servicequalifier is converted',
			UNLINKED_SERVICEQUALIFIER: 'Servicequalifier is unlinked',
			ROOT_NODE_CAN_NOT_BE_DELETED: 'It is not possible to delete the root node.',
			VALID_CONFIGURATION: 'The configuration is valid.',
			INVALID_CONFIGURATION: 'The configuration is not valid.',
			EXIT_DIRTY_CONFIRMATION: 'You have unsaved content in servicemodel. You will lose the changes if you click Close. Click Cancel and save the changes if you want to keep the changes.',
			PROCEED_TO_EXIT: 'You may have unsaved changes in servicemodel. Are you sure you want to proceed?',
			SQ_REMOVED_CHECK_RULE: 'Verify that rule is correct as a servicequalifier has been removed from it'
		},

		validation: {
			ROOT_CANT_BE_A_TARGET: 'Root node can\'t be a target',
			NODE_CAN_HAVE_ONLY_1_INPUT: 'Service element can only have 1 input',
			SERVICE_LINK_CANT_BE_A_SOURCE: 'Service link can\'t have subnodes',
			ELEMENT_SHOULD_HAVE_PATH_TO_ROOT: 'A service element do not have path to the root',
			THERE_ARE_ERRORS: 'There are errors in servicemodel. Fix before saving',
			THERE_IS_AN_INVALID_RULESET: 'Service element {0} contains invalid ruleset'
		},

		labels: {
			ICON_COLOR: 'Icon color',
			PROPERTIES: 'Properties',
			DESCRIPTPON: 'Description',
			PROPERTIES_PANEL_TITLE: 'Properties',
			CREATE_TEMPLATE: 'Template',
			WINDOWS: 'Window',
			NEW: 'New',
			CREATE: 'Create',
			REVERT_DEFAULT: 'Revert windows position'
		},

		qualifiersWindow: {
			OPEN: 'Service element',
			LIST_IS_EMPTY: 'There are no servicequalifiers',
			OPEN_IN_A_SEPARATE_WINDOW: 'Open in a separate window',
			TITLE_NO_SELECTION: 'Service element',
			TITLE_SELECTION: 'Service element {0}',
			CREATE_NEW: 'Create new',
			ADD_SHARED: 'Add shared',
		},

		newElementsToolbar: {
			TITLE: 'Toolbar',
			TITLE_SELECTED: 'Toolbar {0}',
			BLANK_ELEMENT: 'Blank element',
			TEMPLATE_ELEMENT: 'Template element',
			LINK: 'Servicelink',
			QUALIFIER: 'Servicequalifier',
			SHARED_QUALIFIER: 'Shared servicequalifier'
		},

		propertiesToolbar:{
			TITLE_NO_SELECTION: 'Properties',
			TITLE_SELECTION: 'Properties {0}'
		}
	},

	//dashboard
	dashboard: {
		EXIT_DIRTY_CONFIRMATION: 'You have unsaved content in the dashboard. You will lose the changes if you click Close. Click Cancel and save the changes if you want to keep the changes.',
		SELECT_METRICS: 'Select metric',
		SHOW_ADVANCED: 'Show advanced',
		HIDE_ADVANCED: 'Hide advanced'
	},

	// summary
	summary: {
		ACCOUNT_HEALTH: 'Account health',
		NAME: 'Name',
		HEALTH: 'Health',
		SERVICE_STATE: 'Service state',
		SERVICE_STATES: 'Service states',
		SERVICE_QUALIFIER_STATE: 'Servicequalifier state',
		SERVICE_HEALTH: 'Service health',
		SERVICE_HEALTH_BAR: 'Service health indicator',
		SERVICE_INCIDENTS: 'Service incidents',
		SLA_STATUS: 'SLA Status',
		HEALTH_STATUS: 'Health',
		NO_SLA_AVAILABLE: ' No SLA Available',
		REMOVE_FILTER: 'Remove filter',
		SET_AS_DEFAULT: 'Set as default',
		ASSIGN: 'Assign',
		ASSIGN_IN_PROGRESS: 'Assign in progress',
		AGENTS_DOWN: 'agent are down',
		AGENTS_WARNING: 'agent are in warning',
		ALLAGENTS_UP: 'All agents are up',
		messages: {
			SELECT_ACCOUNTS: 'Select your accounts',
			SELECT_FILTER: 'Select filter...',
			NO_SERVICES: 'No services found.',
			NO_ASSETS: 'No assets found',
			NO_SERVICE_QUALIFIERS_STATES: 'No servicequalifiers states found.',
			FILTER_REMOVE_CONFIRMATION: 'Are you sure you want to remove filter?',
			INPUT_FILTER_NAME: 'Input filter name'
		}
	},
	assethealth: {
		LAST_VALUE: 'Last value',
		CREATE_GROUP: 'Create profile',
		MONITOR_TYPE: 'Monitortype',
		MONITOR_CLASS: 'Monitorclass',
		SYSTEM: 'System',
		NAGIOS: 'Nagios',
		NIMSOFT: 'Nimsoft',
		EXTERNAL: 'External',
		PRTG: 'PRTG',
		VMWARE: 'VMware',
		VMM: 'VMM',
		HYPERV: 'Hyper-V',
		MSCLUSTER: 'MS Cluster',
		SNMP: 'SNMP',
		EATON_UPS: 'Eaton UPS',
		MODBUS: 'Modbus',
		FILESEARCH: 'Filesearch',
		SNMPTRAP: 'SNMP trap',
		IBMSVC: 'IBM SVC',
		LOG: 'Log',
		XENSERVER: 'XenServer',
		REMOTEISERIES: 'AS 400',
		CONTAINER: 'Container',
		REMOTE_SYSTEM: 'Remote system',
		GROOVY: 'Groovy',
		GROOVY_LEGACY: 'This monitor will not be support in future release . Use Script monitor instead.',
		SQL_RESPONSE: 'SQL response',
		EVENT_LOG: 'Eventlog',
		LOGMONITOR: 'Log monitor',
		SOLARWINDS: 'SolarWinds',
		MAILGTW: 'Mail gateway',
		WINEVENTS: 'Eventlog',
		WINSERVICE: 'Services',
		SYSTEM_MONITOR: 'System monitor',
		PROCESS_MONITOR: 'Process monitor',
		SERVICE_MONITOR: 'Service monitor',
		NAGIOS_MONITOR: 'Nagios monitor',
		NIMSOFT_MONITOR: 'Nimsoft monitor',
		EXTERNAL_MONITOR: 'External monitor',
		EVENT_LOG_MONITOR: 'Eventlog monitor',
		PRTG_MONITOR: 'PRTG monitor',
		VMWARE_MONITOR: 'VMWARE monitor',
		HYPERV_MONITOR: 'Hyperv monitor',
		VMM_MONITOR: 'VMM monitor',
		MSCLUSTER_MONITOR: 'MScluster monitor',
		SNMP_MONITOR: 'SNMP monitor',
		XENSERVER_MONITOR: 'XenServer monitor',
		REMOTEISERIES_MONITOR: 'Remote iSeries monitor',
		DATABASE_MONITOR: 'SQL response monitor',
		RSM_MONITOR: 'RSM monitor',
		IBMSVC_MONITOR: ' IBM SVC monitor',
		MAILGATEWAY_MONITOR: 'Mail gateway',
		PROCESS: 'Process',
		PROCESSES: 'Processes',
		SERVICE: 'Service',
		SERVICES: 'Services',
		SEVERITY: 'Severity',
		SUBSYSTEM: 'Subsystem',
		INSTANCE: 'Instance',
		CREATE_MONITOR: 'Create monitor',
		DETELE_MONITOR: 'Delete monitor',
		EDIT_MONITOR: 'Edit monitor',
		REASONS_LIST: 'Health reason list',
		STARTED: 'Started',
		REMOVE_SERVICE: 'Remove service',
		MOVE_RIGHT: 'Add to selected monitorgroup',
		MOVE_RIGHT_TO: 'Move to...',
		PENALTY: 'Severity',
		EXPECTED_STATE: 'Expected state',
		BACK_TO_ASSET_SELECTION: 'Back to asset',
		PRIORITY: 'Priority',
		IMPACT: 'Impact',
		CONNECTIVITY: 'Connectivity',
		CLICK_URL: 'Click here for URL',
		MONITOR_NAME: 'Monitor name',
		AGENT_NAME: 'Agent name',
		AGENT_STATE: 'Agent state',
		TARGET_NAME: 'Target name',
		CLICK_TO_HIDE: 'Click to hide',
		SELECT_ASSET: 'Select asset',
		FREE_SPACE: 'Free space',
		USED_SPACE: 'Used space',
		PHYSICAL_MEMORY: 'Physical memory',
		SYSTEM_HEALTH: 'System health',
		MEMORY_USAGE: 'Memory usage',
		CPU_TOTAL: 'CPU total',
		RESET_HI: 'Reset healthindex',
		RESET_HI_MOUSEOVER: 'This will reset healthindex for all reasons to this monitor',
		LAST_CHANGE: 'Last change',
		UPDATE_ASSET_GROUP: 'Update assetgroup',
		OPERAND: 'Operand',
		EVENTLOG: 'Event log',
		PERFORMANCE_COUNTER: 'Performance counter',
		TARGET_POINT: 'Target point',
		NO_MONITORS: 'No monitors',
		NO_LABELS: 'No labels',
		IDENTIFIER_NAME: 'Identifier name',
		IDENTIFIER_INSTANCE: 'Identifier instance',
		REASON_FILTER: 'Healthreason filter',
		CREATE_FILTER: 'Create filter',
		EXPECTED_VALUE: 'Expected value',
		NEW_FRAGMENT: 'New fragment',
		CREATE_FRAGMENT: 'Create monitor fragment',
		ACKNOWLEDGE_ASSET_REASON: 'Acknowledge healthreason',
		SYSTEM_TYPE: 'System type',
		EXTERNAL_ASSETS: 'External assets',
		NON_STATIC_GROUP_ONE: 'Assetgroup is of type',
		NON_STATIC_GROUP_TWO: 'and cannot be removed manually',
		EXTERNAL_TYPE: 'External type',
		LAST_UPDATE: 'Last update',
		COMMAND_LINE: 'Command line',
		METRIC_MONITOR: 'Metric monitor',
		CONTAINER_MONITOR: 'Container monitor',
		HISTORIC_MODE: 'Historic mode',
		messages: {
			ASSETS_SUCCESS_ADDED_TO_GROUP: 'Assets successfully added to assetgroup',
			FILTER_SUCCESS_SAVED: 'Filter successfully updated.',
			MONITOR_SUCCESS_CREATED: 'Monitor successfully created',
			MONITOR_SUCCESS_DELETED: 'Monitor successfully deleted',
			FILTER_SUCCESS_DELETED: 'Filter successfully deleted',
			MONITOR_SUCCESS_UPDATED: 'Monitor successfully updated',
			MONITOR_REMOVE_CONFIRMATION: 'Are you sure you want to remove monitor?',
			MONITORS_REMOVE_CONFIRMATION: 'Are you sure you want to remove monitors?',
			FILTER_REMOVE_CONFIRMATION: 'Are you sure you want to remove filter?',
			FILTERS_REMOVE_CONFIRMATION: 'Are you sure you want to remove filters?',
			NON_DELETABLE_ASSET_MESSAGE: 'This is an agent asset and can not be deleted. Remove the agent and try again...',
			AGENT_CONFIG_SUCCESS_UPDATED: 'Agent configuration was successfully updated',
			PLEASE_SELECT_ASSET: 'Select asset',
			PLEASE_COMPLETE_ONE_FIELD: 'Complete at least one field',
			PLEASE_INPUT_MONITOR_NAME: 'Input monitor name',
			PLEASE_INPUT_MONITOR_FRAGMENT_NAME: 'Input monitor fragment name',
			PLEASE_INPUT_TAGS: 'Select tag',
			NO_REASONS: 'All is OK',
			NO_STATES: 'There are no states',
			AGENT_DOWN: 'No connection to agent, unable to fetch latest healthreason',
			HEALTH_NO_DATA: 'No health data',
			MEMORY_NO_DATA: 'No memory usage data',
			CPU_NO_DATA: 'No CPU total data',
			DISK_NO_DATA: 'No data for disk',
			REASON_FOR_RESET: 'Reason for reset',
			HI_SUCCES_RESET: 'Request for resetting healthindex for selected monitor is sent to agent',
			ENTER_GROUP_NAME: 'Enter group name',
			GROUP_NAME_IN_USE: 'Group name is already in use',
			SELECT_ASSETS: 'Select severity:',
			RULE_EXISTS: 'Rule name already exists',
			NO_FILTERS: 'There are no filters created.',
			FILTERS_ACCOUNT_SUBACCOUNT_TITLE: 'If selected this will dynamically add current account and subaccount will be calculated in filter profile.',
			FILTERS_SUBACCOUNT_TITLE: 'Only subaccounts of current account context will be calculated in filter profile',
			FILTERS_CUSTOM_TITLE: 'Only selected account will be calculated in filter profile',
			SELECT_STATE: 'Select state',
			MONITOR_DELETE_INFO: 'Please remove the monitor ${name} from the following items before deleting',
			NO_KEY_TYPES: 'No key types found',
			FILTER_MANDATORY: 'A dynamic assetgroup does not allow a blank dynamic filter. Input filter criteria in one of the above inputs',
		},
		monitor: {
			list: {
				ALL: 'All monitors',
				CONNECTIVITY: 'Connectivity',
				EVENTLOG: 'Eventlog',
				EXTERNAL: 'External',
				HYPERV: 'Hyper-V',
				IBM: 'IBM SVC',
				SOLARWINDS: 'SolarWinds',
				MAILGATEWAY: 'Mail gateway',
				MSCLUSTER: 'MS cluster',
				NIMSOFT: 'Nimsoft',
				SYSTEM: 'System',
				PROCESS: 'Process',
				SERVICE: 'Service',
				PRTG: 'PRTG',
				VMWARE: 'VMWARE',
				VMM: 'VMM',
				NAGIOS: 'Nagios',
				FILESEARCH: 'Filesearch',
				SNMP: 'SNMP',
				LOG: 'Log',
				DUMMY: 'Dummy',
				EVENTS: 'Events',
				SNMPTRAP: 'SNMP trap',
				FILECHANGE: 'Filechange',
				AMAZON: 'Amazon',
				GOOGLECLOUD: 'Google cloud',
				GROOVY: 'Groovy',
				AZURE: 'Azure'
			}
		}
	},
	viewer: {
		SQ_CHART: 'Servicequalifier chart',
		SE_SUMMARY: 'Service element summary',
		SERVICE_HISTORY: 'Servicehistory',
		METRICS: 'Metrics',
		STATUS_LOGS: 'Status logs',
		SERVICE_QUALIFIERS: 'Servicequalifiers',
		METRICS_PREVIEW: 'Metrics',
		METRICS_DATA: 'Metric data',
		DATASOURCE: 'Datasource',
		STATE_EXPRESSION_RULE: 'State expression rule',
		RULE: 'Rule',
		SQ_EINFO: 'Service element info',
		SQ_HISTORY: 'Service history',
		SQ_METRICDATA: 'Metric data',
		SQ_INFO: 'Servicequalifier Info',
		REASONS_FOR: 'Reasons for',
		SQ_TYPE: 'SQ type',
		SQ_NAME: 'SQ name',
		FIXED_LAYOUT: 'Fixed window position',
		FIXED_LAYOUT_MOUSEOVER: 'If checked we will revert default windows to default position when exiting the viewer. If unchecked windows position is persisted.',
		messages: {
			NO_SERVICE_QUALIFIERS: 'Service element has no qualifiers',
			GOTO_VIEWER: 'Open service in <strong>Service Viewer</strong>.',
			GOTO_SERVICEDETAILS: 'Open service in <strong>Service Details</strong>.',
			NOACCESS_VIEWER: 'No access to go to ${serviceName} Service Viewer.',
			NOACCESS_SERVICEDETAILS: 'No access to go to service detail',
			NO_RAW_METRICS: 'No raw data metrics',
			METRIC_DATA_TITLE: 'Show metric data for ${sqName}',
			METRIC_TITLE: 'Show metric graph for ${sqName}',
			SERVICE_QUALIFIER_TITLE: 'Show servicequalifier information for ${sqName}',
			SQ_SERVICEHISTORY_TITLE: 'Show service history for ${sqName}',
			RULSET_TITLE: 'Show ruleset for ${sqName}',
			SERVICE_DELETED: 'Service is deleted'
		}
	},
	mscenter: {
		MESSAGE_CENTER: 'Messagecenter',
		COMPOSE: 'Compose',
		messages: {
			MESSAGE_SENT: 'Message sent.',
			SEND_TO_EMPTY: 'Send to cannot be empty',
			NO_MESSAGES: 'Currently, you have no unread messages',
			MESSAGE_CENTER_GO: 'Go to message center',
			MESSAGE_REMOVE_CONFIRMATION: 'Are you sure you want to remove message?',
			MESSAGE_SUCCESS_DELETED: 'Message successfuly deleted'
		}
	},
	kpi: {
		CREATE_KPI: 'Create KPI',
		RULE_TYPE: 'Rule type',
		KPI_ACCOUNTS: 'Kpi accounts',
		KPI_METRICS: 'Kpi metrics',
		REPORT_PERIOD: 'Report period',
		SAMPLE_RATE: 'Sample rate',
		TREND: 'Trend',
		messages: {
			ACCOUNT_SUBACCOUNT_TITLE: 'If selected this will add current account and subaccount to be calculated in KPI profile.',
			KPI_REMOVE_CONFIRMATION: 'Are you sure you want to remove KPI?',
			RULE_SUCCESS_DELETED: 'Rule successfully deleted',
			NO_ACCOUNTS: 'No accounts',
			NO_KPIS: 'No KPI created',
			SUCCESS_DELETED: 'KPI successfully deleted',
			SUBACCOUNT_TITLE: 'Only subaccounts of current account context will be calculated in KPI profile',
			CUSTOM_TITLE: 'Only selected account will be calculated in KPI profile',
			SELECTED_ACCOUNTS_SUB: 'All subaccounts and current account selected',
			SELECTED_SUBACCOUNTS: 'All subaccounts selected',
			SELECT_ACCOUNT: 'You must add an account'
		}
	},
	automation: {
		CREATE_AUTOMATION: 'Create automation',
		TRIGGER_TYPE: 'Trigger type',
		STATE_SERVICE_QUALIFIER: 'State servicequalifier',
		STATE_SERVICE_ELEMENT: 'State service element',
		HEALTH: 'Health',
		TIMED: 'Timed',
		INCIDENT: 'Incident',
		PROFILE_SUCCESS_CREATED: 'Profile successfully created',
		PROFILE_SUCCESS_UPDATED: 'Profile successfully updated',
		POSTSCRIPT_TEST: 'Script do not have test possibility',
		PROFILES_REMOVE_CONFIRMATION: 'Are you sure you want to remove these profiles?',
		PROFILE_REMOVE_CONFIRMATION: 'Are you sure you want to remove this profile?',
		PROFILE_SUCCESS_DELETED: 'Profile successfully deleted',
		PROFILES_SUCCESS_DELETED: 'Profiles successfully deleted',
		RETURNED_DATA: 'Returned data',
		NO_DATA: 'No data returned',
		NO_SERVICE_ELEMENTS: 'No service elements selected',
		ACTION_SCRIPT_TEST: 'To test action script select agent in dropdown and click test button',
		DATASOURCE_SCRIPT_TEST: 'To test datasource script select agent in dropdown and click test button',
		SUMMARY_RUN_TEXT: 'Click a row in summary grid to display returned data',
		SCRIPT_TYPE: 'Script type: ',
		MESSAGE: 'Message: ',
		DETAILS: 'Details: ',
		TEST_RESULTS: 'Test results from ',
		EXIT_DIRTY_CONFIRMATION: 'You have unsaved content in script editor. You will lose the changes if you click Close. Click Cancel and save the changes if you want to keep the changes.',
		ACTION_SCRIPT_ACTIVE: 'Action script active',
		DATASOURCE_SCRIPT_ACTIVE: 'Datasource script active',
		POST_SCRIPT_ACTIVE: 'Postscript active',
		SHARE_SCRIPT: 'Share script',
		LINK_SCRIPT: 'Link script',
		UNLINK_SCRIPT: 'Unlink script',
		EDIT_SHARED_SCRIPT: 'Edit shared script',
		ENTER_NAME: 'Input script name',
		SHARED: 'Shared',
		SCRIPT: 'script',
		ACTION_SCRIPT: 'actionscript',
		DATASOURCE_SCRIPT: 'datasource script',
		RUN_DETAILS: 'Run details',
		SHARED_TOOLTIP: 'This is a shared script and is readonly by default. In order to edit the shared script click “Edit Shared script” in the menu. The modification will affect all places script is used. To edit the script within this configuration. Click “Unlink script”. This will make the script in static and editable in this configuration.',
		FILTER_TYPE_PLACEHOLDER: 'Select filter type...',
		SELECT_OPTION: 'Select option...',
		SELECT_INCIDENT_TYPE: 'Select status...',
		SERVICE_STATE: 'Service state',
		NO_SERVICES: 'No services',
		FILTER_OPTIONS_HEALTH: 'Click to show additional filtering options for monitor selection',
		FILTER_OPTIONS_SQ: 'Click here to select more servicequalifiers',
		FILTER_OPTIONS_SE: 'Click here to select more service elements',
		FILTER_OPTIONS_SERVICE: 'Click here to select more services',
		FILTER_OPTIONS_TAGS: 'Click here to change selection type',
		ANY: 'Any'
	},
	rulegenerator: {
		REPEAT: 'Repeat',
		EVERY: 'every',
		SECONDLY: 'Secondly',
		MINUTELY: 'Minutely',
		HOURLY: 'Hourly',
		DAILY: 'Daily',
		WEEKLY: 'Weekly',
		MONTHLY: 'Monthly',
		YEARLY: 'Yearly',
		ON_DAY: 'on day',
		RRULE: 'RRULE',
		SECONDS: 'second(s)',
		MINUTE: 'minute(s)',
		HOUR: 'hour(s)',
		DAY: 'day(s)',
		WEEK: 'week(s)',
		MONTH: 'month(s)',
		YEAR: 'year(s)',
		START: 'Start time',
		END: 'End',
		NEVER: 'Never',
		ON_DATE: 'On date',
		ON_THE: 'on the',
		FIRST: 'First',
		SECOND: 'Second',
		THIRD: 'Third',
		FOURTH: 'Fourth',
		LAST: 'Last',
		WEEKDAY: 'Weekday',
		WEEKEND_DAY: 'Weekend day',
		ON: 'On',
		ERROR: 'We are unable to display this RRULE, it might be invalid or a custom RRULE. Click Update to revert to default or Cancel to keep it.',
		SCHEDULES_REMOVE_CONFIRMATION: 'Are you sure you want to remove schedule?',
		SCHEDULE_REMOVE_CONFIRMATION: 'Are you sure you want to remove schedule?',
		SCHEDULE_SUCCESS_DELETED: 'Schedule successfully deleted',
		SCHEDULES_SUCCESS_DELETED: 'Schedules successfully deleted'
	},
	months: {
		JANUARY: 'Jan',
		FEBRUARY: 'Feb',
		MARCH: 'Mar',
		APRIL: 'Apr',
		MAY: 'May',
		JUNE: 'Jun',
		JULY: 'Jul',
		AUGUST: 'Aug',
		SEPTEMBER: 'Sep',
		OCTOBER: 'Oct',
		NOVEMBER: 'Nov',
		DECEMBER: 'Dec'
	},
	fullMonths: {
		JANUARY: 'January',
		FEBRUARY: 'February',
		MARCH: 'March',
		APRIL: 'April',
		MAY: 'May',
		JUNE: 'June',
		JULY: 'July',
		AUGUST: 'August',
		SEPTEMBER: 'September',
		OCTOBER: 'October',
		NOVEMBER: 'November',
		DECEMBER: 'December'
	},
	integration: {
		PROFILES_REMOVE_CONFIRMATION: 'Are you sure you want to remove profiles?',
		PROFILE_REMOVE_CONFIRMATION: 'Are you sure you want to remove profile?',
		PROFILE_SUCCESS_DELETED: 'Profile successfully deleted',
		PROFILES_SUCCESS_DELETED: 'Profiles successfully deleted'
	},
	incidents: {
		TIME_OF_FLAG: 'Acknowledged at',
		CREATE_INCIDENT: 'Create incident',
		DELETE_INCIDENT: 'Delete incident',
		MARK_AS_SEEN: 'Acknowledge',
		MARK_ALL_AS_SEEN: 'Acknowledge all',
		ASSET_HISTORY: 'Asset history',
		REASON_DETAILS: 'Healthreason details',
		SUBJECT: 'Subject',
		ASSIGNED_TO: 'Assigned to',
		OWNER: 'Owner',
		NUMBER: 'Number',
		REPORTED_BY: 'Reported by',
		CREATED: 'Created',
		MODIFIED: 'Modified',
		ACKNOWLEDGED_BY: 'Acknowledged by',
		CREATED_BY: 'Created by',
		CREATE_COMMENT: 'Add comment',
		DELETE_COMMENT: 'Delete comment',
		EDIT_COMMENT: 'Edit comment',
		SELECT_STATUS: 'Change incident status...',
		ACTIVITY_BY_USER: 'Activity by user',
		CHANGE_STATUS: 'Change status',
		CHANGE_INCIDENT_STATUS: 'Change incident status',
		AFFECTED_SERVICES_TOOLTIP: 'Services selected assets belong to',
		CONTENT_TYPE: 'Content type',
		PRIORITY_NAME: 'Priority',
		messages: {
			PLEASE_SELECT_ASSIGNED: 'Assign incident to user',
			PLEASE_SELECT_OWNER: 'Select owner for incident',
			PLEASE_SELECT_REASON: 'Select healthreason for incident',
			PLEASE_SELECT_STATUS: 'Select status for incident',
			PLEASE_ENTER_SUBJECT: 'input subject message',
			SUCCESS_SAVED: 'Incident successfully created',
			SUCCESS_UPDATED: 'Incident successfully updated',
			SUCCESS_DELETED: 'Incident(s) successfully deleted',
			NO_INCIDENTS: 'No incidents created',
			COMMENT_SUCCESS_CREATED: 'Comment successfully added',
			COMMENT_SUCCESS_UPDATED: 'Comment successfully updated',
			COMMENT_SUCCESS_DELETED: 'Comment successfully deleted',
			COMMENT_EMPTY: 'Comment is empty. Please type in a comment for this incident.',
			INCIDENT_REMOVE_CONFIRMATION: 'Are you sure you want to remove incident?',
			INCIDENTS_REMOVE_CONFIRMATION: 'Are you sure you want to remove Incidents?',
			INVALID_INCIDENT: 'Incident is not valid as the service is deleted from account.',
			STATUS_CHANGED: 'Status successfully changed',
			PRIVATE_COMMENT_DISABLED: 'Reporter can not set private comment'
		}
	},
	grid: {
		FILTER_SELECT_VALUE: 'Select a value...',
		NO_RECORDS: 'There is no data available.',
		filter: {
			SHOW_ITEMS: 'Show items with value that: ',
			STARTS_WITH: 'Starts with',
			CONTAINS: 'Contains',
			EQ: 'Is equal to',
			NEQ: 'Is not equal to',
			GTE: 'Greater than or equal to',
			GT: 'Greater than',
			LTE: 'Less than or equal to',
			LT: 'Less than',
			IAE: 'Is after or equal to',
			IA: 'Is after',
			IBE: 'Is before or equal to',
			IB: 'Is before',
			SELECT_VIEW: 'Select view...',
			SEE_VIEW: 'See view',
			CREATE_VIEW: 'Create view',
			REMOVE_VIEW: 'Remove view',
			UPDATE_VIEW: 'Update view',
			CLEAR_VIEW: 'Clear view',
			OPEN_VIEW: 'Open view',
			FILTER_RESET: 'Clear filter and sorting',
			SHOW_HIDE_COLUMNS: 'Show/hide columns',
			GRID_COLUMNS: 'Grid Columns',
			COLUMNS_SORTING: 'Columns Sorting',
			FILTER_PREFERENCES: 'Filter Preferences',
			ENTER_NEW_FILTER: 'Enter new filter name',
			VIEW_UPDATED: 'View updated',
			ISIN: 'Is in',
			ISNOTIN: 'Is not in'
		},
		events: {
			PAUSE: 'Pause events',
			START: 'Start events',
			LOAD: 'Load events',
			UPDATE_INTERVAL: 'Update interval'
		}
	},
	shortDateFormat: {
		dd_MM: 'dd.MM'
	},
	agents: {
		AUTO_UPDATE: 'Auto update',
		ALLOW_SUBAGENTS: 'Allow subagent',
		ALLOW_DATA_COLLECTION: 'Allow data collection',
		UPDATE_AGENT: 'Update agent',
		CONNECT_AGENT: 'Connect new agent',
		INITIATE_AGENT: 'Initiate agent',
		RUNTIME_VERSION: 'Runtime version',
		LAST_STARTED: 'Last started',
		LAST_CONNECTED: 'Last connected',
		CONSOLE_URL: 'Console URL',
		LOG_LEVEL: 'Log level',
		THREAD: 'Thread',
		LOGGER: 'Logger',
		CONNECTION_STATE: 'Connection state',
		CONNECTION_STATUS: 'Connection status',
		AGENT_VERSION: 'Agent version',
		RESTART: 'Warm start',
		RESTART_CACHE: 'Cold start',
		QUEUE: 'Queue',
		SHARED: 'Shared agent',
		RUN_SCRIPT: 'Run script',
		UPLOAD_BUNDLES: 'Upload bundles',
		UPLOAD: 'Upload',
		REPOSITORY: 'Repository',
		INSTALL_BUNDLES: 'Install bundles',
		UNINSTALL_BUNDLES: 'Uninstall bundles',
		LICENSE_USAGE: 'License usage',
		NO_LICENSE_FOUND: 'No licenses are installed in Ceeview instance. Contact support.',
		DATA_REGISTRY: 'Data registry',
		messages: {
			AGENTS_REQUEST: 'Request sent to agent. Note that system agent and agents that are down will be ignored in the request.',
			CONNECTION_STATE_SHUTDOWN: 'Controlled shutdown',
			CONNECTION_STATUS_SHUTDOWN: 'Controlled shutdown',
			CONNECTION_STATUS_HOST_SHUTDOWN: 'Controlled host shutdown',
			CONNECTION_COLD_RESTART: 'Cold restart',
			CONNECTION_WARM_RESTART: 'Warm restart',
			CONNECTION_UPDATE: 'Update',
			CONNECTION_SAFE_MODE: 'Safe mode',
			CONNECTION_STATE_FAILURE: 'Connection failure',
			CONNECTION_STATE_UP: 'Connection up',
			DELETE_INFO: 'Remove agent ${name} from following places before deleting agent',
			NO_QUEUES: 'No queues available',
			LOG_SETUP_SAVED: 'Setup successfully saved',
			LOG_RESET_MESSAGE: 'Log configuration reset to default settings',
			RESET_DEFAULT_CONFIRMATION: 'This will revert all log configuration settings for agent',
			INSTALL_BUNDLES_INFO: 'Bundles will be installed for selected agents.',
			UNINSTALL_BUNDLES_INFO: 'Bundles will be uninstalled from selected agents.',
			INSTALL_BUNDLES_FOR_AGENT: 'Bundles will be installed for: ',
			UNINSTALL_BUNDLES_FOR_AGENT: 'Bundles will be uninstalled from: ',
			BUNDLE_INSTALLED: 'Bundle was successfully installed',
			BUNDLES_INSTALLED: 'Bundles were successfully installed',
			BUNDLE_UNINSTALLED: 'Bundle was successfully uninstalled',
			BUNDLES_UNINSTALLED: 'Bundles were successfully uninstalled',
			BUNDLES_INSTALL_FAILED: 'We were unable to deploy to resources: ',
			BUNDLES_UNINSTALL_FAILED: 'We were unable to uninstall on resources: ',
			BUNDLES_SUCCESSFULLY_UPLOADED: 'Bundles were successfully uploaded',
			BUNDLES_FAILED_UPLOADED: 'Unable to upload bundles',
			BUNDLES_WERE_DELETED: 'Bundles were successfully deleted',
			BUNDLES_DELETE_FAILED: 'The following bundles could not be deleted: ',
			JAR_FILE_TYPES: 'Only the files with the extension .jar are allowed',
			MAINTENANCES_NO_FUTURE_PERIODS: 'Only future maintenance periods are deleted. Active or historic maintenance period are not deleted.',
			MAINTENANCES_SUCCESS_DELETED: 'Only future maintenance periods are deleted',
			SELECT_PROTOCOL: 'Select protocol...'
		},
	},
	profile: {
		DEFAULT_SERVICEBOARD: '- Ceeview home view - ',
		DEFAULT_DATE_FORMAT: 'Default date format',
		INTERNATIONAL_STANDARD: 'International standard (YYYY-MM-DD)',
		NORWEGIAN_SHORT: 'Norwegian short (dd.MM.yy)',
		SWEDISH_SHORT: 'Swedish short (yyyy-mm-dd)',
		PASSWORD_STRENGTH_MESSAGE: 'The password should contain min 8 characters and numbers',
		SERVICEBOARDS: '- Dashboards -'
	},
	slas: {
		NO_DOWNTIME: 'No downtime',
		NO_EXCLUSION: 'There are no exclude period',
		RUNNING_247: '24/7',
		CREATED_BY: 'Created by',
		SET_OPERATING_TIME: 'Set operating time',
		CREATE_EXCLUDE_PERIOD: 'Create exclude period',
		ALL_PERIODS: 'All periods',
		CURRENT_PERIOD: 'Current period',
		DELETE_EXCLUDE_PERIOD: 'Delete exclude period',
		DOWNTIME: 'Current downtime',
		STATE_PERIOD: 'State period',
		MAX_DOWNTIME: 'Max allowed downtime',
		OPERATING_EDITOR_TITLE: 'Please create operating time for SLA',
		DOWN_TIME: 'Downtime',
		SERVICE_QUALIFIER: 'Servicequalifier',
		DOWN_BEFORE_BREACHED: 'Downtime before breached',
		HISTORIC_NOTES: 'Historic notes',
		CURRENT_NOTES: 'Current notes',
		FUTURE_NOTES: 'Future notes',
		messages: {
			INVALID_START_TIME: 'Invalid start time',
			INVALID_END_TIME: 'Invalid end time',
			INVALID_PERIOD: 'Invalid period',
			PERIOD_IN_USE: 'Period already in use',
			NO_SLAS: 'There are no service level agreements available',
			SLA_REMOVE_CONFIRMATION: 'Are you sure you want to remove SLA?',
			SLA_RECALCULATE_CONFIRMATION_DETAIL: 'Select the time you want to recalculate SLA from.  Any state changes in service from this period is used.',
			SLAS_REMOVE_CONFIRMATION: 'Are you sure you want to remove SLA?',
			EXCLUDE_DELETED: 'Exclude period deleted',
			NOTE_DELETED: 'Note deleted',
			NO_STATE_CHANGES: 'There are no state changes',
			SLA_COMPLIANCE_HISTORY: 'SLA service history'
		}
	},

	serviceDesigner: {
		TITLE: 'Service designer'
	},

	presentation: {
		messages: {
			REMOVE_CONFIRMATION_1: 'Are you sure you want to remove presentation?',
			REMOVE_CONFIRMATION_MANY: 'Are you sure you want to remove presentations?',
			DELETED: 'Presentation deleted.'
		},

		designer: {
			DESIGNER: 'Designer',
			NAME_WATERMARK: 'Enter a name...',
			DESCRIPTION_WATERMARK: 'Enter a description...',
			BACKGROUND_IMAGE: 'Background image',
			DATASOURCE: "Datasource",
			CLEAR_IMAGE: "Clear image",
			EXIT_DIRTY_CONFIRMATION: 'You have unsaved content in presentation. You will lose the changes if you click close. Click cancel and save the changes if you want to keep the changes.',
			AGGREGATED_ASSET: 'Aggregated HI',
			shapes: {
				ROOT: 'Service root node',
				LINKED: 'Linked',
				SERVICE_ELEMENT: 'Service element',
				HEALTH_INDEX: 'Healthindex',
				STANDARD: 'Ceeview standard shape',
				SERVICE_MODEL: 'Servicemodel'
			}
		},
	},
	administration: {
		CALENDAR_REMOVE_CONFIRMATION: 'Are you sure you want to remove calendar?',
		CALENDARS_REMOVE_CONFIRMATION: 'Are you sure you want to remove calendars?',
		EXTERNAL_ASSET_GROUP_TOOLTIP: 'Assetgroup is EXTERNAL.  It cannot be removed manually. It is deleted when its monitor is deleted.',
		DYNAMIC_ASSET_GROUP_TOOLTIP: 'Assetgroup is DYNAMIC.  It cannot be removed manually. It has to be removed by filtersetting in the assetgroup.',
		CALENDARS_DELETED: 'Calendar deleted',
		CALENDARS_DEPENDENCIES_MESSAGE: 'The ${calendarName} calendar must be removed from the following items before it can be deleted.',
		CALENDARS_REMOVE_DEPENDENCIES: 'Remove from all',
		DELETE_INFO: 'Authentication ${name} must be replaced in the below components before it can be deleted. Click replace all button to continue.',
	},
	management: {
		LAST_COMMAND: 'Last command',
		LAST_LOGIN: 'Last login',
		ERROR_CODE: 'Errorcode',
		ACKNOWLEDGE: 'Acknowledge',
		ACKNOWLEDGE_ALL: 'Acknowledge all',
		SHOW_ALL: 'Show all',
		SHOW_NOT_ACKNOWLEDGED: 'Show not acknowledge',
		SUPPRESSION_COUNT: 'Suppression count',
		SUPPRESSION_TIME: 'Suppression time',
		DELETE_MESSAGEBUS: 'Delete invalid message',
		PEEK_MESSAGEBUS: 'View message',
		START_MESSAGEBUS: 'Start message queue',
		CLEAR_MESSAGEBUS: 'Clear message queue',
		DELETE_STATUS: 'First message in selected message queue is removed',
		PEEK_STATUS: 'View message commmand sent to message queue',
		START_STATUS: 'Start command sent to message queue',
		CLEAR_STATUS: 'Clear command sent to messages queue',
		PEEK_NO_DATA: 'No data in message queue',
		messages: {
			LOG_REMOVE_CONFIRMATION: 'Are you sure you want to remove system message?',
			LOGS_REMOVE_CONFIRMATION: 'Are you sure you want to remove system messages?',
			LOGS_DELETED: 'System messages deleted.',
			END_MAINTENANCE_SUCCEEDED: 'Maintenance period ended',
			GENERIC_PUBLIC_USERS: 'This is a generic public users and has no significant details.'
		}
	},
	file_type: {
		IMAGE: 'Image',
		PDF: 'Pdf',
		TXT: 'Text'
	},
	regionalSchedulerDays: {
		MONDAY: 1,
		TUESDAY: 2,
		WEDNESDAY: 3,
		THURSDAY: 4,
		FRIDAY: 5,
		SATURDAY: 6,
		SUNDAY: 7
	},
	favorites: {
		SERVICE: 'Service',
		ASSET: 'Asset',
		ASSET_GROUP: 'Assetgroup',
		REASON: 'Healthreason',
		FILTER: 'Filter',
		MONITOR: 'Monitor',
		// Used for old items in favorites
		// To be removed in time
		ASSET_SUMMARY: 'Asset',
		GROUP_SUMMARY: 'Assetgroup',
		REASONS: 'Healthreason',
		FILTERS: 'Filter',
		MONITORS: 'Monitor',
		//
		CONNECTIVITY: 'Connectivity',
		EVENTLOG: 'Eventlog',
		EXTERNAL: 'External',
		HYPERV: 'Hyper-V',
		IBMSVC: 'IBM SVC',
		MSCLUSTER: 'MSCluster',
		MAILGATEWAY: 'Mail gateway',
		SOLARWINDS: 'SolarWinds',
		NAGIOS: 'Nagios',
		NIMSOFT: 'Nimsoft',
		PRTG: 'PRTG',
		FILESEARCH: 'Filesearch',
		SNMP: 'SNMP',
		VMM: 'VMM',
		VMWARE: 'VMWARE',
		SERVICE_MONITOR: 'Service monitor',
		SYSTEM_MONITOR: 'System monitor',
		LOG: 'Log',
		PROCESS: 'Process',
		SLA: 'SLA',
		SERVICE_BOARD: 'Serviceboard',
		KPI: 'KPI',
		INCIDENT: 'Incident',
		REPORT: 'Report',
		AUTOMATION: 'Automation',
		INTEGRATION: 'Integration',
		ACCOUNTS: 'Accounts',
		ASSETS: 'Assets',
		ASSET_GROUPS: 'Assetgroups',
		AUTHENTICATIONS: 'Authentications',
		SERVICE_MODEL_TEMPLATE: 'Servicemodel templates',
		SERVICE_ELEMENT_TEMPLATE: 'Service element templates',
		SERVICE_QUALIFIER_TEMPLATE: 'Servicequalifier templates',
		TEAMS: 'Teams',
		USERS: 'Users',
		ROLES: 'Roles',
		SERVER: 'Server',
		REPOSITORY: 'Repository',
		MODULES: 'Modules',
		AUDIT: 'Audit',
		ACTIVE_USERS: 'Active users',
		SYSTEM_MESSAGES: 'System messages',
		LAST_CHANGES: 'Last changes',
		AGENTS: 'Agents',
		APPLICATION: 'Application',
		DATAREGISTRY: 'Data registry',
		COST: 'Cost',
		CALENDAR: 'Calendar',
		DASHBOARD: 'Dashboard',
		MODULE: 'Module'
	},
	periodMultiToggle: {
		HOUR: 'Hour',
		DAY: '24h',
		WEEK: '7d',
		MONTH: '30d'
	},
	momentsLangCode: 'en',
	imageUploader: {
		DELETE_CONFIRMATION: 'The image will be removed from all places it is used. Are you sure you want to delete?'
	},
};

lang.targetType = {
	ACCOUNT_CHILDREN: lang.ACCOUNT_SUBACCOUNT,
	CHILDREN: lang.SUBACCOUNT,
	SELECTED: lang.CUSTOM
};

export default lang;
