import {Api} from "tools";
import {ApiRequest} from "framework/api";
import {ApiDocumentationSection} from "../../controls/react/apiDocumentation";

export class AutomationApi {
	static getAutomationUrl(accountId: string, id: string, update: boolean) {
		return Api.accountRoot(accountId) + `automations/${id}/?update=${update}`;
	}
}

export function activateAutomations(ids: string[]){
	return new ApiRequest({
		url: 'automations/setActive/true',
		accountBased: true,
		method: "POST",
		payload: ids
	})
}

export function deactivateAutomations(ids: string[]){
	return new ApiRequest({
		url: 'automations/setActive/false',
		accountBased: true,
		method: "POST",
		payload: ids
	})
}

export function getApiDocumentation(accountId: string = null){
	return new ApiRequest<ApiDocumentationSection[]>({
		url: 'automations/apiHelp',
		accountId: accountId,
		accountBased: true,
		responseTypeArray: ApiDocumentationSection
	})
}
