import { ApplicationState } from "./applicationState";
import {deserialize, serialize} from "serializr";
import {runInAction, makeAutoObservable} from "mobx";
import UrlBuilder from "tools/urlBuilder";
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition";
import {InterfaceMonitorInterface} from "areas/assets/monitors/interface-traffic/models/interfaceMonitorInterface";

export const serverRoot = '/rest/';

export declare type Constructor<T> = new (...args: any[]) => T;

export class ApiRequest<T> {
	url: string
	payload?: any
	method?: 'POST' | 'GET' | 'PUT' | 'DELETE'

	accountId?: string
	accountBased?: boolean = false

	includeSubaccounts: boolean
	subaccountsFilter?: boolean

	responseType?: Constructor<T>
	responseTypeArray?: Constructor<any>
	deserializationCallback: (data: T) => T

	public constructor(init?: Partial<ApiRequest<T>>) {
		Object.assign(this, init);
	}
}

export type FilterOption = {
	value: string;
	text: string;
}

export type PagedListFilterOptions = Record<string, FilterOption[]>

export type PagedList<T> = {
	filterOptions: PagedListFilterOptions
	items: T[]
	page: number
	total: number
	visible: number
}

export type PagedListPayloadSortingEntry = {
	field: string;
	dir: 'desc' | 'asc'
}

export type PagedListFilterEntry = {
	filters: PagedListFilterEntry[]
	logic: "and" | "or"
} | {
	field: string
	operator: string
	value: string
}

export class PagedListPayloadFilter {
	filters: PagedListFilterEntry[] = []
	isCustom: boolean = true
	logic: string = "and"

	constructor() {
		makeAutoObservable(this)
	}
}

export type PagedListPayload = {
	skip: number
	take: number
	sort?: PagedListPayloadSortingEntry[]
	filter?: RuleDefinition
}

export enum HttpStatus{
	Ok = 200,
	Unauthorized= 401,
	Forbidden = 403,
	InternalServerError= 500
}

export class ApiResponse<T> {
	data?: T;
	code?: number = 0;
	status: HttpStatus = HttpStatus.Ok;
	message?: string;
	details?: string[]

	get success(){
		return this.status == HttpStatus.Ok;
	}

	public constructor(init?: Partial<ApiResponse<T>>) {
		Object.assign(this, init);
	}
}

export async function apiFetch<TResult>(apiRequest: ApiRequest<TResult>, init?: RequestInit): Promise<ApiResponse<TResult>> {
	let request = buildRequest(apiRequest, init)

	let response = await fetch(request);

	let text = await response.text();
	try {
		const resultTemp = JSON.parse(text);

		if (resultTemp.success === false || !response.ok) {
			return new ApiResponse<TResult>({
				status: HttpStatus.InternalServerError,
				message: resultTemp.message,
				data: resultTemp.data,
				details: resultTemp.details
			});
		}

		if(resultTemp.success === true){
			resultTemp.status = HttpStatus.Ok;
			delete resultTemp.success;
		}

		if (resultTemp.data === undefined) { // in case response is just plain data
			return new ApiResponse<TResult>({
				data: resultTemp as TResult,
				status: HttpStatus.Ok
			})
		} else {
			if(apiRequest.responseType != null || apiRequest.deserializationCallback || apiRequest.responseTypeArray ) {
				try {
					runInAction(() => {
						if (apiRequest.deserializationCallback) {
							resultTemp.data = apiRequest.deserializationCallback(resultTemp.data);
						} else if (apiRequest.responseType) {
							resultTemp.data = deserialize(apiRequest.responseType, resultTemp.data);
						} else if (apiRequest.responseTypeArray) {
							//@ts-ignore
							resultTemp.data = resultTemp.data.map(x => deserialize(apiRequest.responseTypeArray, x));
						}
					});
				}catch(e: unknown){
					let message =  e instanceof Error ? e.message : e?.toString()

					console.log(message)

					return new ApiResponse<TResult>({
						status: HttpStatus.InternalServerError,
						message: 'There was an error deserializing response:' + message
					})
				}
			}
			return new ApiResponse<TResult>(resultTemp);
		}
	} catch(e) {
		return new ApiResponse<TResult>({
			status: HttpStatus.InternalServerError,
			message: text,
		});
	}
}


function buildRequest<TResult>(apiRequest: ApiRequest<TResult>, requestInit?: RequestInit) {
	const init = {
		...(requestInit ?? {}),
		credentials: 'include' as const,
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			"Auth-Token": ApplicationState.apiToken
		}
	} as RequestInit;

	if(apiRequest.payload){
		init.method = 'POST';
		let payload = apiRequest.payload;
		//we are checking if payload is configured for serialization and serialize it automatically
		if(payload.serializeInfo || payload.constructor?.serializeInfo){
			payload = serialize(payload);
		}
		init.body = JSON.stringify(payload);
	}

	if(apiRequest.method){
		init.method = apiRequest.method;
	}

	let url = ApplicationState.apiUrl;
	if (apiRequest.accountBased) {
		url += 'accounts/' + (apiRequest.accountId ?? ApplicationState.accountId) + '/';
	}

	url += apiRequest.url;

	if(apiRequest.subaccountsFilter == true || apiRequest.includeSubaccounts != null){
		url = new UrlBuilder(url)
			.add("includeSubaccounts", apiRequest.includeSubaccounts ?? ApplicationState.includeSubaccounts)
			.build()
	}

	return new Request(url, init);
}
