import React from 'react';
import ReactDOM from "react-dom";
import {Application, LocalEventsManager, Cookies, translator} from "core";
import {CustomNotification, ExpandableTextarea, ImageUploader, Tags} from "controls";
import {CeeViewDataSource} from "tools";
import ErrorHandler from "core/errorHandler";
import Settings from "settings";
import {Utils, Api} from "tools";
import {TagsSelect} from "controls/react/tagsSelect";
import FormEntry from "controls/react/form/formEntry";
import {AssetsApi} from "api";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AssetTargetsEditor} from "controls/assetTargetsEditor";

const i = translator();

export let AssetForm = function (config) {
	Utils.apply(this, config);
	this.recentlyUsedIcons = [];
	this.initComponent();
};

export default AssetForm;

jQuery.extend(AssetForm.prototype, Application.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: async function () {
		this.showAccounts = !!(this.topAccountId && this.accountId);

		this.accountId = this.accountId || Cookies.CeesoftCurrentAccountId;

		this.update = this.mode !== 'view';
		this.tagsDataSource = [];
		this.imageId = '';
		this.formEl = $('#cw_asset_form');

		this.descriptionHandler = new ExpandableTextarea({
			renderTo: this.formEl.find('.cw_description')
		});

		this.selected = false;

		let actionButtonText = lang.CREATE;
		if (this.mode === 'update') {
			actionButtonText = lang.UPDATE;
		}
		$('#save_asset').text(actionButtonText);
		this.assetTargetsList = []
		this.initKendoComponents();

		this.removeListeners();
		this.attachListeners();
		//this.renderUploaderImagesControl();
		//
		// set listeners for  live controls
		Utils.onLiveChange('.js_asset_name', '#card_name', lang.account.ASSET_NAME);
		Utils.onLiveChange('.cw_description textarea', '#card_description', lang.account.ASSET_DESCRIPTION);
		//Utils.onLiveChange(this.descriptionHandler.textarea, lang.account.ASSET_DESCRIPTION);
		if (this.mode === 'update' || this.mode === 'view') {
			await this.load();
		} else {
			this.formEl.find('#cw_cvid').parent().addClass('hide');
			this.renderAssetTargets();
			this.tagsList = [];
			this.renderTags();
		}

		this.validate();
		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
		if (this.mode === 'view') {
			this.removeListeners();
			this.enableViewMode();
		}
		Utils.setPlaceholder($('.js_asset_name'), i('Please input Name...'));
	},
	/**
	 * Handler function for making form readonly if user does not have update permission
	 */
	enableViewMode: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		this.descriptionHandler.readonly(true);
		$('.js_asset_name').addClass('is_readonly');
		$('.js_asset_type').addClass('is_readonly');
		$('#save_asset').addClass('hide');
		$('.is_readonly').attr('disabled', true);
	},
	/**
	 * Launches Media Library.
	 */
	onMediaLibraryButton: function () {
		if ($('.cw_media_library_content').hasClass('hide')) {
			if (!this.uploaderImagesControl) {
				this.renderUploaderImagesControl();
				//TODO: not sure if this is still relevant because the way of getting image is changed
				// if (this.imageId) {
				// 	this.uploaderImagesControl.setSelectedImageId(this.imageId);
				// }
			}
			var modalWindow = $('#modal').data("kendoWindow");
			/*$('.form_content').slideUp(1, function(){
			 $('.form_content_minimal').fadeIn(1);
			 });*/
			this.formEl.find('.form_content').addClass('hide');
			this.formEl.find('.form_content_minimal').show();
			this.formEl.parent().find('.cw_media_library_content').removeClass('hide');
			this.formEl.parent().parent().find('.actions button').hide();
			$('#back').removeClass('hide');
			this.formEl.parent().find('.cw_media_library').addClass('hide');
		}
	},
	/**
	 * Handler function for the click event on the form expand button
	 */
	onFormExpandButton: function () {
		this.formEl.find('.form_content_minimal').hide();
		var modalWindow = $('#modal').data("kendoWindow");
		this.formEl.parent().find('.cw_media_library_content').addClass('hide');
		this.formEl.find('.form_content').removeClass('hide');
		this.formEl.parent().find('.cw_media_library').removeClass('hide');
		this.formEl.parent().parent().find('.actions button').show();
		$('#back').addClass('hide');
	},
	/**
	 * Renders the uploader images control.
	 */
	renderUploaderImagesControl: function () {
		this.uploaderImagesControl = new ImageUploader({
			id: 'asset_glyph_icons',
			context: 'assets',
			livePreviewSelector: '#cw_asset_card_avatar',
			height: '344px'
		});
	},
	renderTags() {
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical>
			<TagsSelect
				mode={'tags'}
				accountId={this.accountId}
				disabled={this.mode === 'view'}
				includeSubaccounts={false}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList}
				placeholder={lang.messages.TAG_CHOOSE} />
		</FormEntry>,  $('.cw_asset_tags').parent().get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},
	/**
	 * Initialize Kendo UI controls
	 */
	initKendoComponents: function () {
		let typeDefault = 'GENERIC';
		let typeData = [{
			text: lang.account.SYSTEM_TYPE_GENERIC,
			value: 'GENERIC'
		}, {
			text: lang.account.SYSTEM_TYPE_WINDOWS,
			value: 'WINDOWS'
		}, {
			text: lang.account.SYSTEM_TYPE_UNIX,
			value: 'UNIX'
		}];

		if (this.systemType === 'WINDOWS') {
			typeDefault = 'WINDOWS';
			typeData = [{
				text: lang.account.SYSTEM_TYPE_WINDOWS,
				value: 'WINDOWS'
			}];
		}
		else if (this.systemType === 'UNIX') {
			typeDefault = 'UNIX';
			typeData = [{
				text: lang.account.SYSTEM_TYPE_UNIX,
				value: 'UNIX'
			}];
		}

		this.systemTypeControl = this.formEl.find('.js_asset_system_type').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			value: typeDefault,
			dataSource: typeData,
			enable: this.mode === 'view' ? false : true
		}).data('kendoDropDownList');

		if (this.showAccounts) {
			$('.cw_accounts').removeClass('hide');

			this.accountDataSource = new CeeViewDataSource({
				transport: {
					read: {
						url: () => {
							return Settings.serverPath + 'accounts/' + this.topAccountId + '/summary/accounts?includeSubaccounts=true'
						},
						contentType: 'application/json; charset=utf-8',
						type: 'GET',
						dataType: 'json',
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			});

			this.accountList = $('#cw_accounts').kendoDropDownList({
				dataSource: this.accountDataSource,
				autoBind: true,
				dataTextField: 'name',
				dataValueField: 'id',
				dataBound: () => {
					this.accountList.value(this.accountId);
				},
				change: () => {
					this.assetGroupControl.refresh();
				}
			}).data('kendoDropDownList');
		}

		this.assetGroupDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: () => {
						return Settings.serverPath + 'accounts/' + this.getSelectedAccount() + '/assetGroups'
					},
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			filter: { field: "groupType", operator: "equals", value: "STATIC" },
			error: ErrorHandler.kendoServerError
		});

		let scope = this;
		this.assetGroupControl = $('#js_asset_group_control').kendoSortedMultiSelect({
			dataSource: this.assetGroupDataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			enable: this.mode === 'view' ? false : true,
			init: (instance) => {
				const originalTagTemplate = instance.tagTemplate;
				instance.tagTemplate = (data) => {
					const html = originalTagTemplate(data);
					let tooltip = data.groupType === 'EXTERNAL' ? lang.administration.EXTERNAL_ASSET_GROUP_TOOLTIP : data.groupType === 'DYNAMIC' ? lang.administration.DYNAMIC_ASSET_GROUP_TOOLTIP : '';
					if (data.groupType !== 'STATIC') {
						const stripped = $(html);
						stripped.attr('title', tooltip).find('span.k-i-close').hide();
						return stripped;
					}
					return html;
				};
			},
			dataBound: function () {
				let placeholder, data = this.dataSource.data();
				if (data.length) {
					placeholder = lang.account.messages.ASSET_GROUP_CHOOSE;
				} else {
					placeholder = lang.account.messages.ASSET_GROUP_NO_GROUPS;
					this.enable(false);
				}
				this.options.placeholder = placeholder;

				if (scope.assetGroupId) {
					for (let assetGroup of data) {
						if (assetGroup.id === scope.assetGroupId && assetGroup.groupType === 'STATIC') {
							this.value([assetGroup.id]);
							break;
						}
					}
				}
			},
			deselect: (e) => {
				if (e.dataItem.groupType !== 'STATIC') {
					e.preventDefault();
				}
			}
		}).data('kendoSortedMultiSelect');
	},

	renderAssetTargets() {
		this.assetTargetContainer = this.assetTargetContainer || this.formEl.find('.js_asset_target').parent().get()[0];
		ReactDOM.render(<FormEntryNew label={lang.ASSET_TARGET} vertical={true}>
			<AssetTargetsEditor onChange={(v) => this.assetTargetsList = v}
			                    targets={this.assetTargetsList}
			/>
		</FormEntryNew>, this.assetTargetContainer);
	},

	getSelectedAccount() {
		if (this.accountList) {
			return this.accountList.value() || this.accountId;
		}
		else {
			return this.accountId;
		}
	},

	onItemsSetChanged(e){
		if( e.type == 'add' ){
			this.lastAddedTarget = e.element;
		}

		if( e.type == 'remove' && this.lastAddedTarget && this.lastAddedTarget.value == e.element.value)
		{
			this.lastAddedTarget = null;
		}
	},

	removeListeners: function () {
		$('#save_asset').off();
		$('#cancel').off();
		$('.cw_media_library').off();
		$('#back').off();
		LocalEventsManager.unbind('loadRecentIcons');
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_asset').on('click', $.proxy(this.onSaveButton, this));
		$('.cw_avatar_asset').find('.close_minibtn').on('click', $.proxy(this.onRemovePicture, this));
		$('.cw_media_library').on('click', $.proxy(this.onMediaLibraryButton, this));
		$('#back').on('click', $.proxy(this.onFormExpandButton, this));
		$('#cw_asset_form').on('keyup', '.js_asset_name', $.proxy(this.onNameKeyUp, this));
		LocalEventsManager.bind('loadRecentIcons', $.proxy(this.loadRecentIcons, this));
	},
	onNameKeyUp: function () {
		this.validate();
	},
	validate: function () {
		const name = this.formEl.find('.js_asset_name').val();
		Utils.setInvalidField(name, this.formEl.find('.js_asset_name'), true);
		$('#save_asset').attr('disabled', name.trim() == '');
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		this.formEl.find('.js_asset_name').parent().removeClass('required_form');
	},
	/**
	 * Handler function for the click event on image remove button
	 */
	onRemovePicture: function (e) {
		if (this.uploaderImagesControl && this.uploaderImagesControl.getSelectedImage().id) {
			//TODO: not sure if this is still relevant because the way of getting image is changed
			//this.uploaderImagesControl.setSelectedImageId('');
		}
		this.imageId = null;
		var assetModal = $(e.target).parents().eq(6);
		var id = assetModal.find('.assetId').attr('id');
		// remove on modal
		var previewDiv = assetModal.find('.cw_avatar_asset');
		previewDiv.find('.close_minibtn').addClass('hide');
		previewDiv.removeClass('no_bg');
		previewDiv.find('img, span').remove();
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: async function () {
		const loadUrl = Settings.serverPath + 'accounts/' + this.getSelectedAccount() + '/assets/' + this.id + '/?update=' + this.update;
		const result = await Utils.ajax(loadUrl, 'GET', {});
		if (!result.success) {
			Utils.showInfo(lang.ALERT, result.message, result.details);
			return;
		}

		this.initialName = result.data.name;
		this.formEl.find('#cw_cvid').val(this.id);
		this.formEl.find('.js_asset_name').val(this.initialName || '');
		this.clearInvalid();
		this.descriptionHandler.value(result.data.description || '');
		this.formEl.find('.js_asset_type').val(result.data.assetType || '');
		$('.js_asset_type_line').removeClass('hide');
		this.systemTypeControl.value(result.data.systemType || '');
		this.assetGroupControl.value(result.data.assetGroups);

		let tags = [];
		if (result.data.tags) {
			for (let i = 0, length = result.data.tags.length; i < length; i++) {
				tags.push(result.data.tags[i]);
			}
		}
		this.tagsList = tags;
		this.renderTags();

		this.imageId = result.data.imageId;
		this.iconPack = result.data.iconPack;
		if (result.data.imageId) {
			var imagePreviewDiv = $('#cw_asset_card_avatar .cw_avatar_asset');
			imagePreviewDiv.addClass('no_bg');
			if (Utils.isGuid(this.imageId)) {
				imagePreviewDiv.prepend('<img src="' + Api.images.urls.image(this.imageId) + '" />');
			} else {
				if (this.iconPack === 'glyph') {
					imagePreviewDiv.prepend('<span class="glyphicons ' + this.imageId + '"></span>');
				} else {
					imagePreviewDiv.prepend('<i class="material-icons">' + this.imageId + '</i>');
				}
			}
			imagePreviewDiv.find('.close_minibtn').removeClass('hide');
			//TODO: not sure if this is still relevant because the way of getting image is changed
			// if (this.uploaderImagesControl) {
			// 	this.uploaderImagesControl.setSelectedImageId(result.data.imageId);
			// }
		}
		this.assetTargetsList = result.data.targets
		this.renderAssetTargets();

		this.fields = {
			asset_name: this.formEl.find('.js_asset_name')
		};
		this.card = {
			asset_name: $('#card_name'),
			asset_description: $('#card_description'),
			default_asset_name: lang.account.ASSET_NAME,
			default_asset_description: lang.account.ASSET_DESCRIPTION,
			default_asset_details: lang.account.ASSET_DETAILS
		};

		// name
		if (this.fields.asset_name.val()) {
			this.card.asset_name.text(this.fields.asset_name.val());
		}
		else {
			this.card.asset_name.text(this.card.default_asset_name);
		}

		// description
		let descriptionValue = this.descriptionHandler.value() || this.card.default_asset_description;
		this.card.asset_description.text(descriptionValue);
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			//close window
			$('#cancel').trigger('click');

			this.pictureId = result.data.imageId;
			this.iconPack = result.data.iconPack;
			if (!Utils.isGuid(this.pictureId)) {
				for (var i = 0; i < this.recentlyUsedIcons.length; i++) {
					if (this.pictureId === this.recentlyUsedIcons[i].id) {
						this.recentlyUsedIcons.splice(i, 1);
					}
				}
				this.recentlyUsedIcons.unshift({
					id: this.pictureId,
					iconPack: this.iconPack
				});
				if (this.recentlyUsedIcons.length === 9) {
					this.recentlyUsedIcons.pop();
				}
				var preferences = [{
					key: 'recentIcons',
					value: JSON.stringify(this.recentlyUsedIcons)
				}];
				this.saveUserPrefs({
					category: 'RecentlyUsedIcons',
					preferences: preferences
				});
			}
			//update status
			var successText = (this.mode === 'create' ? lang.account.messages.ASSET_SUCCESS_CREATED : lang.account.messages.ASSET_SUCCESS_UPDATED);
			this.statusNotification.setOptions({
				message: successText,
				status: 'success'
			}).show();

			var selectedTargets = this.assetTargetsList || [];
			var lastTarget = selectedTargets.pop();

			LocalEventsManager.trigger('assetsaved', {
				mode: this.mode,
				asset: {
					id: result.data.id,
					accountId: this.getSelectedAccount(),
					name: this.formEl.find('.js_asset_name').val()
				},
				identifier: this.identifier,
				targets: result.data.targets,
				lastSelectedTarget: lastTarget != null
					? result.data.targets.find( t => t.address == lastTarget.address)
					: {},
				lastAddedTarget: this.lastAddedTarget != null
					? result.data.targets.find( t => t.address == this.lastAddedTarget.text)
					: null
			});
			if (this.onCreateNew) {
				this.onCreateNew(result.data.id);
			}
		}
		else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: async function (e) {
		this.clearInvalid();
		var name = this.formEl.find('.js_asset_name').val();
		let nameExists = await Utils.checkIfNameExists(AssetsApi.getDynamicSearchUrl('false'), name, this.modalNotification, false, this.initialName);
		if(nameExists) {
			return;
		}
		var description = this.descriptionHandler.value();
		//get groups
		var assetGroupsSelectedItems = this.assetGroupControl.dataItems();
		var groups = [];
		if (assetGroupsSelectedItems.length) {
			for (var i = 0, length = assetGroupsSelectedItems.length; i < length; i++) {
				groups.push(assetGroupsSelectedItems[i].id);
			}
		}
		var imageId, iconPack;
		if (this.uploaderImagesControl && this.uploaderImagesControl.getSelectedImage()) {
			imageId = this.uploaderImagesControl.getSelectedImage().id || '';
			iconPack = this.uploaderImagesControl.getSelectedImage().iconPack || '';
		} else {
			iconPack = this.iconPack;
			imageId = this.imageId;
		}

		this.data = {
			id: (this.id === 'new_asset' ? null : this.id),
			name: name.trim(),
			description: description.trim(),
			systemType: this.systemTypeControl.value(),
			assetGroups: (groups !== null) ? groups : [],
			targets: this.assetTargetsList,
			imageId: imageId,
			iconPack: iconPack,
			tags: this.tagsList
		};
		var valid = true, message;
		var errorCount = 0;
		if (name.trim() === '') {
			valid = false;
			message = lang.account.messages.ASSET_INPUT_NAME;
			this.formEl.find('.js_asset_name').parent().addClass('required_form');
			errorCount++;
		}
		if (errorCount > 1) {
			message = lang.account.messages.REQUIRED_FIELDS;
		}
		if (!valid) {
			this.modalNotification.setOptions({
				message: message,
				status: 'error'
			}).show();
		} else {
			var url = Settings.serverPath + 'accounts/' + this.getSelectedAccount() + '/assets';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	},
	/**
	 * Loads recent icons
	 * @param {Object} e The object sent by event manager
	 */
	loadRecentIcons: function (e) {
		this.recentlyUsedIcons = e.recentIcons;
	}
});
