import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import Highcharts from 'highcharts';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import State from 'tools/state';
import moment from 'moment';
import Constants from 'tools/constants';
import {throttle} from 'lodash'


export function ConsoleWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	if (this.renderTo) {
		this.assetConsoleWidgetId = $('#' + this.renderTo);
	} else {
		this.assetConsoleWidgetId = $('#' + this.id);
	}
	this.initComponent();
};

export {ConsoleWidget as default};

jQuery.extend(ConsoleWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.subscribe();

		if (this.renderTo) {
			this.renderContent();
		} else {
			if (this.size === 1) {
				this.renderWidgetContent();
			} else {
				this.renderContent();
			}
		}
	},
	/**
	 * Renders console content
	 */
	renderContent: function () {
		this.lastRender = 'content';
		var html = '<div class="cw_assetconsole_content">';
		html += '<div class="cw_assetinfo w50">';
		html += '<ul class="cw_list w100">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head"><label class="">' + lang.INFO + ': </label></div>';
		html += '<div class="cw_list_head">' + lang.VALUE + '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item"><label class="">' + lang.ASSET + ': </label></div>';
		html += '<div class="cw_list_item"><span class="value cw_asset_name ellipsis"></span></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item"><label class="">' + lang.DESCRIPTION + ': </label></div>';
		html += '<div class="cw_list_item"><span class="value cw_asset_description ellipsis"></span></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item"><label class="">' + lang.UPTIME + ': </label></div>';
		html += '<div class="cw_list_item"><span class="value cw_asset_uptime"></span></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item"><label class="">CPU: </label></div>';
		html += '<div class="cw_list_item"><span class="value cw_asset_cpu"></span></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item"><label class="">' + lang.assethealth.PHYSICAL_MEMORY + ': </label></div>';
		html += '<div class="cw_list_item"><span class="value cw_asset_memory"></span></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item"><label class="">' + lang.account.TARGETS + ': </label></div>';
		html += '<div class="cw_list_item"><span class="value cw_asset_targets"></span></div>';
		html += '</li>';
		html += '</ul>';
		html += '</div>';
		html += '<div class="cw_assetinfo w50">';
		html += '<ul class="cw_sparklines left w100">';
		html += '<li class="cw_sparkline cw_syshealth_chart_container">';
		html += '<span class="cw_chart_title">' + lang.assethealth.SYSTEM_HEALTH + ':</span>';
		html += '<div class="cw_chart_content cw_syshealth_chart"></div>';
		html += '<span class="cw_chart_current cw_current_health"></span>';
		html += '<span class="cw_assetconsole_notification syshealth_notification"></span>';
		html += '</li>';
		html += '<li class="cw_sparkline cw_memory_chart_container">';
		html += '<span class="cw_chart_title">' + lang.assethealth.MEMORY_USAGE + ':</span>';
		html += '<div class="cw_chart_content cw_memory_chart"></div>';
		html += '<span class="cw_chart_current cw_current_memory"></span>';
		html += '<span class="cw_assetconsole_notification memory_notification"></span>';
		html += '</li>';
		html += '<li class="cw_sparkline cw_cpu_chart_container">';
		html += '<span class="cw_chart_title">' + lang.assethealth.CPU_TOTAL + ':</span>';
		html += '<div class="cw_chart_content cw_cpu_chart"></div>';
		html += '<span class="cw_chart_current cw_current_cpu"></span>';
		html += '<span class="cw_assetconsole_notification cpu_notification"></span>';
		html += '</li>';
		html += '</ul>';
		html += '</div>';
		html += '<div class="cw_asset_console_disk">';
		html += '<span class="cw_assetconsole_notification disk_notification"></span>';
		html += '<div class="cw_piechart_wrapper"></div>';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_widget_mask"><div class="k-loading-image"></div></div>';
		if (this.renderTo) {
			$('#' + this.renderTo).find('.cw_section_content').empty().append(html);
		} else {
			this.assetConsoleWidgetId.find('.cw_section_content').empty().append(html);
		}
		this.populateAssetDetails();
		this.getMetricGroups();
	},
	/**
	 * Renders widget content
	 */
	renderWidgetContent: function () {
		this.lastRender = 'widget';
		var html = '<div class="cw_assetconsole_content cw_one_column">';
		html += '<div class="cw_assetinfo">';
		html += '<ul class="cw_sparklines">';
		html += '<li class="cw_sparkline cw_system_health">';
		html += '<span class="cw_chart_title left w100">' + lang.assethealth.SYSTEM_HEALTH + '</span>';
		html += '<span class="cw_chart_current cw_current_health"></span>';
		html += '<span class="cw_assetconsole_notification syshealth_notification"></span>';
		html += '</li>';
		html += '<li class="cw_sparkline cw_memory_usage">';
		html += '<span class="cw_chart_title left w100">' + lang.assethealth.MEMORY_USAGE + '</span>';
		html += '<span class="cw_chart_current cw_current_memory"></span>';
		html += '<span class="cw_assetconsole_notification memory_notification"></span>';
		html += '</li>';
		html += '<li class="cw_sparkline cw_cpu_total">';
		html += '<span class="cw_chart_title left w100">' + lang.assethealth.CPU_TOTAL + '</span>';
		html += '<span class="cw_chart_current cw_current_cpu"></span>';
		html += '<span class="cw_assetconsole_notification cpu_notification"></span>';
		html += '</li>';
		html += '</ul>';
		html += '</div>';
		html += '<div class="cw_assetinfo">';
		html += '<div class="cw_asset_console_disk">';
		html += '<span class="cw_assetconsole_notification disk_notification"></span>';
		html += '<div class="cw_barchart_wrapper"></div>';
		html += '</div>';
		html += '</div>';
		html += '</div>';
		this.assetConsoleWidgetId.find('.cw_section_content').empty().append(html);
		this.getMetricGroups();
	},
	/**
	 * Populates the details section
	 */
	populateAssetDetails: function () {
		var loadUrl = this.requestPath + 'accounts/' + this.configuration.accountId + '/assets/' + this.configuration.assetId + '';
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.assetConsoleWidgetId.find('.cw_asset_name').text(result.data.name || '');
				var assetConsoleDescription = result.data.description;
				if (assetConsoleDescription.length > 30) {
					assetConsoleDescription = assetConsoleDescription.substring(0, 30) + '...';
				}
				this.assetConsoleWidgetId.find('.cw_asset_description').text(assetConsoleDescription || '').attr('title', result.data.description);
				var targets = result.data.targets,
					trgString = '';
				if (targets.length) {
					for (var i = 0; i < targets.length; i++) {
						trgString += targets[i].address + ', ';
					}
					trgString = trgString.substring(0, trgString.length - 2);
				}

				this.assetConsoleWidgetId.find('.cw_asset_targets').text(trgString.substring(0, 40) || 'N/A');
				this.assetConsoleWidgetId.find('.cw_asset_targets').attr('title', trgString || 'N/A');
			} else {
				//Utils.showInfo(lang.ALERT, result.message, result.details);
				this.showErrorMessage(result.message);
			}
		}, this));
	},
	/**
	 * Populates the details section
	 */
	getMetricGroups: function () {
		var loadUrl = this.requestPath + 'accounts/' + this.configuration.accountId + '/assets/' + this.configuration.assetId + '/monitors/SYSTEM/' + this.configuration.monitorId + '/systemDetails';
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			var cpuName = 'N/A', cpuData, uptime = 'N/A',
				memory = 'N/A', memoryData, data,
				healthData, diskData, healthDataValue, cpuDataValue, memoryValue;
			if (result.success) {
				data = result.data;
				if (data.cpu && data.cpu.name) {
					cpuName = data.cpu.name.substring(0, 30) + '...';
					this.assetConsoleWidgetId.find('.cw_asset_cpu').attr('title', data.cpu.name || 'N/A');
				}
				// set the locale for moment js
				if (data.cpu && data.cpu.uptime) {
					uptime = moment.duration(parseInt(data.cpu.uptime), 's').lang(Cookies.CeesoftUserLocale.substring(0, 2) === 'no' ? 'nb' : 'en').humanize();
				}
				if (data.memory && data.memory.totalMemory) {
					memory = data.memory.totalMemory;
				}
				cpuData = data.cpu ? data.cpu.cpuUsage : null;
				memoryData = data.memory ? data.memory.memoryUsage : null;
				healthData = data.healthIndexes;
				diskData = data.disk ? data.disk.partitions : null;

				this.assetConsoleWidgetId.find('.cw_asset_uptime').text(uptime);
				this.assetConsoleWidgetId.find('.cw_asset_cpu').text(cpuName);
				this.assetConsoleWidgetId.find('.cw_asset_memory').text(memory);

				if (this.size !== 1) {
					this.populateLineCharts(healthData, memoryData, cpuData);
				}

				if (this.renderTo) {
					this.populateDiskCharts(diskData);
				} else {
					if (this.size === 1) {
						this.populateBarCharts(diskData);
						healthDataValue = healthData.pop();
						this.assetConsoleWidgetId.find('.cw_current_health').text(healthDataValue.value.toFixed(0));
						if (cpuData) {
							cpuDataValue = cpuData.values.pop();
							this.assetConsoleWidgetId.find('.cw_current_cpu').text(cpuDataValue.v.toFixed(0) + '%');
						}
						if (memoryData) {
							memoryValue = memoryData.values.pop();
							this.assetConsoleWidgetId.find('.cw_current_memory').text(memoryValue.v.toFixed(0) + '%');
						}
					} else {
						// this.populatePieCharts(diskData);
						this.populateDiskCharts(diskData);
					}
				}
			} else {
				//Utils.showInfo(lang.ALERT, result.message, result.details);
				this.showErrorMessage(result.message);
			}
		}, this));
	},
	/**
	 * Populates the sparkline charts area
	 * @param {Object[]} healthData Array containing the health data
	 * @param {Object[]} memoryData Array containing the memory data
	 * @param {Object[]} cpuData Array containing the cpu data
	 */
	populateLineCharts: function (healthData, memoryData, cpuData) {
		var i, length, seriesData = [], id;
		if (healthData && healthData.length) {
			length = healthData.length;
			this.assetConsoleWidgetId.find('.cw_current_health').text(healthData[length - 1].value.toString());
			for (i = 0; i < length; i++) {
				seriesData.push([healthData[i].timestamp, healthData[i].value]);
			}
			this.createLineChart('cw_syshealth_chart', seriesData, ' ', Constants.CYAN);
			seriesData = [];
		} else {
			this.noHealthNotification = this.assetConsoleWidgetId.find('.syshealth_notification').kendoNotification({
				button: false,
				autoHideAfter: 3000,
				hideOnClick: true,
				appendTo: this.assetConsoleWidgetId.find('.syshealth_notification'),
				height: 40
			}).data('kendoNotification');
			this.noHealthNotification.show(lang.assethealth.messages.HEALTH_NO_DATA);
			this.assetConsoleWidgetId.find('.syshealth_notification').parent().children().not('.syshealth_notification').addClass('hide');
			this.assetConsoleWidgetId.find('.syshealth_notification').find('.k-i-close').remove();
		}

		if (memoryData && memoryData.hasData) {
			length = memoryData.values.length;
			this.assetConsoleWidgetId.find('.cw_current_memory').text(memoryData.values[length - 1].v.toFixed(0) + '%');
			for (i = 0; i < length; i++) {
				seriesData.push([memoryData.values[i].t, memoryData.values[i].v]);
			}
			this.createLineChart('cw_memory_chart', seriesData, '%', Constants.LIGHT_MAGENTA);
			seriesData = [];
		} else {
			this.noMemoryNotification = this.assetConsoleWidgetId.find('.memory_notification').kendoNotification({
				button: false,
				autoHideAfter: 3000,
				hideOnClick: true,
				appendTo: this.assetConsoleWidgetId.find('.memory_notification'),
				height: 40
			}).data('kendoNotification');
			if (memoryData && memoryData.message) {
				this.noMemoryNotification.show(lang.MEMORY + ': ' + memoryData.message);
			} else {
				this.noMemoryNotification.show(lang.assethealth.messages.MEMORY_NO_DATA);
			}
			this.assetConsoleWidgetId.find('.memory_notification').parent().children().not('.memory_notification').addClass('hide');
			this.assetConsoleWidgetId.find('.memory_notification').find('.k-i-close').remove();
		}

		if (cpuData && cpuData.hasData) {
			length = cpuData.values.length;
			this.assetConsoleWidgetId.find('.cw_current_cpu').text(cpuData.values[length - 1].v.toFixed(0) + '%');
			for (i = 0; i < length; i++) {
				seriesData.push([cpuData.values[i].t, cpuData.values[i].v]);
			}
			this.createLineChart('cw_cpu_chart', seriesData, '%', Constants.GREEN);
			seriesData = [];
		} else {
			this.noCpuNotification = this.assetConsoleWidgetId.find('.cpu_notification').kendoNotification({
				button: false,
				autoHideAfter: 3000,
				hideOnClick: true,
				appendTo: this.assetConsoleWidgetId.find('.cpu_notification'),
				height: 40
			}).data('kendoNotification');
			if (cpuData && cpuData.message) {
				this.noCpuNotification.show('CPU: ' + cpuData.message);
			} else {
				this.noCpuNotification.show(lang.assethealth.messages.CPU_NO_DATA);
			}
			this.assetConsoleWidgetId.find('.cpu_notification').parent().children().not('.cpu_notification').addClass('hide');
			this.assetConsoleWidgetId.find('.cpu_notification').find('.k-i-close').remove();
		}
	},
	/**
	 * Populates the pie charts area
	 * @param {Object[]} diskData Array containing the disc partitions data
	 */
	populatePieCharts: function (diskData) {
		var i, length, partitionData, partitionName, html, partitionNotification, freeSpace, usedSpace, totalSpace;
		if (diskData && diskData.length) {
			var containerWidth = this.assetConsoleWidgetId.closest('.cw_widget').width();
			this.assetConsoleWidgetId.find('.cw_piechart_wrapper').empty().css('width', diskData.length * 220);
			for (i = 0, length = diskData.length; i < length; i++) {
				partitionData = diskData[i].diskUsage;
				if (partitionData && partitionData.hasData) {
					totalSpace = Utils.humanizeBytes(diskData[i].sizeInMBytes * 1024 * 1024);
					freeSpace = Utils.humanizeBytes((diskData[i].sizeInMBytes - Math.ceil(partitionData.values[0].v / 100 * diskData[i].sizeInMBytes)) * 1024 * 1024);
					usedSpace = Utils.humanizeBytes(Math.ceil(partitionData.values[0].v / 100 * diskData[i].sizeInMBytes) * 1024 * 1024);
					html = '<div class="cw_piechart">';
					html += '<span class="cw_chart_disk left ellipsis w100" title="' + lang.DISK + ' ' + diskData[i].name + ' ' + freeSpace + ' ' + lang.assethealth.FREE_SPACE + '">' + lang.DISK + ' ' + diskData[i].name + ': <strong>' + freeSpace + ' </strong>' + lang.assethealth.FREE_SPACE + '</span>';
					html += '<span class="cw_chart_d cw_disk_' + i + '"></span>';
					html += '<span class="cw_chart_used">' + lang.USED + ': ' + usedSpace + ', ' + lang.TOTAL + ' ' + totalSpace + '</span>';
					html += '<span class="partition_notification_' + i + ' cw_assetconsole_notification"></span>';
					html += '</div>';
					this.assetConsoleWidgetId.find('.cw_piechart_wrapper').append(html);
					this.createPieChart('cw_disk_' + i, [
						[lang.assethealth.USED_SPACE, partitionData.values[0].v],
						[lang.assethealth.FREE_SPACE, 100 - partitionData.values[0].v]
					], [Constants.LIGHT_CYAN, Constants.LIGHT_GREEN]);
				} else {
					html = '<div class="cw_piechart">';
					html += '<span class="partition_notification_' + i + ' cw_assetconsole_notification"></span>';
					html += '</div>';
					this.assetConsoleWidgetId.find('.cw_piechart_wrapper').append(html);
					partitionNotification = this.assetConsoleWidgetId.find('.partition_notification_' + i).kendoNotification({
						button: false,
						autoHideAfter: 0,
						hideOnClick: false,
						appendTo: this.assetConsoleWidgetId.find('.partition_notification_' + i),
						height: 40
					}).data('kendoNotification');
					if (partitionData && partitionData.message) {
						partitionNotification.show('Disk ' + diskData[i].name + ': ' + partitionData.message);
					} else {
						partitionNotification.show(lang.assethealth.messages.DISK_NO_DATA + ': ' + diskData[i].name);
					}
					this.assetConsoleWidgetId.find('.partition_notification_' + i).find('.k-i-close').remove();
				}
			}
		} else {
			this.diskNotification = this.assetConsoleWidgetId.find('.disk_notification').kendoNotification({
				button: false,
				autoHideAfter: 3000,
				hideOnClick: true,
				appendTo: this.assetConsoleWidgetId.find('.disk_notification'),
				height: 40
			}).data('kendoNotification');
			this.diskNotification.show('No disk data');
			this.assetConsoleWidgetId.find('.disk_notification').find('.k-i-close').remove();
		}
		this.removeMask();
	},
	/**
	 * Populates the pie charts area
	 * @param {Object[]} diskData Array containing the disc partitions data
	 */
	populateDiskCharts: function (diskData) {
		var i, length, partitionData, partitionName, html, partitionNotification, freeSpace, usedSpace, totalSpace;
		this.assetConsoleWidgetId.find('.cw_piechart_wrapper').empty();
		if (diskData && diskData.length) {
			for (i = 0, length = diskData.length; i < length; i++) {
				partitionData = diskData[i].diskUsage;
				if (partitionData && partitionData.hasData) {
					totalSpace = Utils.humanizeBytes(diskData[i].sizeInMBytes * 1024 * 1024);
					freeSpace = Utils.humanizeBytes((diskData[i].sizeInMBytes - Math.ceil(partitionData.values[0].v / 100 * diskData[i].sizeInMBytes)) * 1024 * 1024);
					usedSpace = Utils.humanizeBytes(Math.ceil(partitionData.values[0].v / 100 * diskData[i].sizeInMBytes) * 1024 * 1024);

					html = '<div class="cw_diskchart">';
					html += '<div class="cw_diskchart_label">';
					html += '<span class="label w100 ellipsis" title="' + lang.DISK + ' ' + diskData[i].name + '">' + lang.DISK + ' ' + diskData[i].name + '</span>';
					html += '<span class="label w100 ellipsis" title="' + lang.USED + ': ' + usedSpace + ', ' + lang.TOTAL + ' ' + totalSpace + '">' + lang.USED + ': ' + usedSpace + ', ' + lang.TOTAL + ' ' + totalSpace + '</span>';
					html += '</div>';
					html += '<div class="cw_progress">';
					html += '<span class="cw_progress_slice" title="' + lang.DISK + ' ' + lang.USED + ': ' + usedSpace + ', ' + lang.TOTAL + ' ' + totalSpace + '" style="width:' + partitionData.values[0].v + '%"></span>';
					html += '</div>';
					html += '<div class="cw_diskchart_info ellipsis" title="' + lang.assethealth.FREE_SPACE + ': ' + freeSpace + '">' + lang.assethealth.FREE_SPACE + ': <strong>' + freeSpace + ' </strong></div>';
					html += '</div>';

					this.assetConsoleWidgetId.find('.cw_piechart_wrapper').append(html);
				} else {
					html = '<div class="cw_diskchart">';
					html += '<span class="partition_notification_' + i + ' cw_assetconsole_notification">';
					if (partitionData && partitionData.message) {
						html += 'Disk ' + diskData[i].name + ': ' + partitionData.message;
					} else {
						html += lang.assethealth.messages.DISK_NO_DATA + ': ' + diskData[i].name;
					}
					html += '</span>';
					html += '</div>';

					this.assetConsoleWidgetId.find('.cw_piechart_wrapper').append(html);
				}
			}
		} else {
			this.assetConsoleWidgetId.find('.cw_piechart_wrapper').append(html);

			this.diskNotification = this.assetConsoleWidgetId.find('.disk_notification').kendoNotification({
				button: false,
				autoHideAfter: 3000,
				hideOnClick: true,
				appendTo: this.assetConsoleWidgetId.find('.disk_notification'),
				height: 40
			}).data('kendoNotification');
			this.diskNotification.show('No disk data');
			this.assetConsoleWidgetId.find('.disk_notification').find('.k-i-close').remove();
		}
		this.removeMask();
	},
	/**
	 * Populates the pie charts area
	 * @param {Object[]} diskData Array containing the disc partitions data
	 */
	populateBarCharts: function (diskData) {
		var i, j, length, partitionData, partitionName, html, partitionNotification, freeSpace, usedSpace, totalSpace;
		this.assetConsoleWidgetId.find('.cw_barchart_wrapper').empty();
		if (diskData && diskData.length) {
			for (i = 0, length = diskData.length; i < length; i++) {
				partitionData = diskData[i].diskUsage;
				if (partitionData && partitionData.hasData) {
					totalSpace = Utils.humanizeBytes(diskData[i].sizeInMBytes * 1024 * 1024);
					freeSpace = Utils.humanizeBytes((diskData[i].sizeInMBytes - Math.ceil(partitionData.values[0].v / 100 * diskData[i].sizeInMBytes)) * 1024 * 1024);
					usedSpace = Utils.humanizeBytes(Math.ceil(partitionData.values[0].v / 100 * diskData[i].sizeInMBytes) * 1024 * 1024);
					html = '<div class="cw_barchart">';
					html += '<span class="cw_chart_disk">' + diskData[i].name + ': <strong>' + freeSpace + ' </strong>' + lang.assethealth.FREE_SPACE + ' <strong>' + totalSpace + '</strong> ' + lang.TOTAL + '</span>';
					html += '<div class="left w100 cw_disk_' + i + '"></div>';
					html += '<span class="partition_notification_' + i + ' cw_assetconsole_notification"></span>';
					html += '</div>';
					this.assetConsoleWidgetId.find('.cw_barchart_wrapper').append(html);
					this.createBarChart('cw_disk_' + i, [{
						name: diskData[i].name,
						data: [100 - partitionData.values[0].v]
					}, {
						name: diskData[i].name,
						data: [partitionData.values[0].v]
					}], [Constants.LIGHT_GREEN, Constants.LIGHT_CYAN]);
				} else {
					html = '<div class="cw_barchart">';
					html += '<span class="partition_notification_' + i + ' cw_assetconsole_notification"></span>';
					html += '</div>';
					this.assetConsoleWidgetId.find('.cw_asset_console_disk').append(html);
					partitionNotification = this.assetConsoleWidgetId.find('.partition_notification_' + i).kendoNotification({
						button: false,
						autoHideAfter: 3000,
						hideOnClick: true,
						appendTo: this.assetConsoleWidgetId.find('.partition_notification_' + i),
						height: 40
					}).data('kendoNotification');
					if (partitionData && partitionData.message) {
						partitionNotification.show('Disk ' + diskData[i].name + ': ' + partitionData.message);
					} else {
						partitionNotification.show(lang.assethealth.messages.DISK_NO_DATA + ': ' + diskData[i].name);
					}
					this.assetConsoleWidgetId.find('.partition_notification_' + i).find('.k-i-close').remove();
				}
			}
		} else {
			this.diskNotification = this.assetConsoleWidgetId.find('.disk_notification').kendoNotification({
				button: false,
				autoHideAfter: 3000,
				hideOnClick: true,
				appendTo: this.assetConsoleWidgetId.find('.disk_notification'),
				height: 40
			}).data('kendoNotification');
			this.diskNotification.show('No disk data');
			this.assetConsoleWidgetId.find('.disk_notification').find('.k-i-close').remove();
		}
		this.removeMask();
	},
	/**
	 * Creates a sparkline chart
	 * @param {String} renderTo The id of the html element where the chart will be rendered
	 * @param {Object[]} data The chart series data object
	 * @param {String} [unit=''] Optional unit type, used in chart tooltip
	 * @param {String} [color=Constants.GREEN] Optional color of the series
	 */
	createLineChart: function (renderTo, data, unit, color) {
		unit = unit || '';
		color = color || Constants.GREEN;

		//'SparkLine',
		var lineChart = new Highcharts.Chart({
			chart: {
				renderTo: this.assetConsoleWidgetId.find('.' + renderTo)[0],
				backgroundColor: 'transparent',
				height: 65
			},
			title: {
				text: ' '
			},
			exporting: {
				enabled: false
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			tooltip: {
				crosshairs: true,
				snap: 1,
				backgroundColor: 'white',
				borderWidth: 0,
				shadow:{
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				formatter: function (e) {
					var s = Renderer.browserDateRenderer(this.x, Constants.DATETIME);
					var v = '<br />';
					v += '<span style="font-weight:700">' + lang.VALUE + ':</span>' + Math.round(this.y * 100) / 100 + unit;
					$(e.chart.renderTo).find('.highcharts-tooltip').css('top', '0');
					return s + v;
				}
			},
			xAxis: {
				startOnTick: false,
				endOnTick: false,
				tickPositions: [],
				labels: {
					enabled: false
				}
			},
			yAxis: {
				min: 0,
				max: 100,
				labels: {
					enabled: false
				},
				title: {
					text: null
				},
				startOnTick: false,
				endOnTick: false,
				tickPositions: [0]
			},
			plotOptions: {
				series: {
					marker: {
						radius: 2
					}
				}
			},
			series: [{
				type: 'area',
				name: ' ',
				data: data,
				color: color,
				pointStart: 1
			}]
		});
	},
	/**
	 * Creates a pie chart
	 * @param {String} renderTo The id of the html element where the chart will be rendered
	 * @param {Object[]} data The chart series data object
	 * @param {String[]} colors The colors array
	 */
	createPieChart: function (renderTo, data, colors) {
		this.pieChart = new Highcharts.Chart({
			chart: {
				renderTo: this.assetConsoleWidgetId.find('.' + renderTo)[0],
				backgroundColor: 'transparent',
				plotBackgroundColor: null,
				plotBorderWidth: null,
				width: 150,
				height: 150,
				plotShadow: false
			},
			title: {
				text: ' '
			},
			tooltip: {
				backgroundColor: 'white',
				borderWidth: 0,
				shadow:{
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			exporting: {
				enabled: false
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: false
					},
					colors: colors
				}
			},
			series: [{
				type: 'pie',
				name: ' ',
				data: data
			}]
		});
	},
	/**
	 * Create Bar Charts
	 * @param {String} renderTo The id of the html element where the chart will be rendered
	 * @param {Object[]} data The chart series data object
	 * @param {String[]} colors The colors array
	 */
	createBarChart: function (renderTo, data, colors) {
		this.barChart = new Highcharts.Chart({
			chart: {
				renderTo: this.assetConsoleWidgetId.find('.' + renderTo)[0],
				height: 32,
				spacingBottom: 0,
				spacingTop: 0,
				spacingLeft: 0,
				spacingRight: 0,
				marginBottom: 0,
				marginTop: 0,
				marginLeft: 0,
				marginRight: 0,
				type: 'bar',
				backgroundColor: 'transparent'
			},
			colors: colors,
			title: {
				text: ''
			},
			xAxis: {
				labels: {
					enabled: false
				},
				thickWidth: 0,
				lineWidth: 0
			},
			yAxis: {
				labels: {
					enabled: false
				},
				title: {
					text: ''
				},
				thickWidth: 0,
				lineWidth: 0,
				gridLineColor: 'transparent'

			},
			tooltip: {
				enabled: false
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			exporting: {
				enabled: false
			},
			plotOptions: {
				series: {
					stacking: 'percent'
				}
			},
			series: data
		});
	},
	/**
	 * Removes mask when done rendering
	 */
	removeMask: function () {
		if (this.renderTo) {
			$('#' + this.renderTo).find('.cw_widget_mask').remove();
		} else {
			this.assetConsoleWidgetId.find('.cw_widget_mask').remove();
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Number} max The number of columns/rows of the widget
	 */
	onResize: function (max) {
		this.size = max;
		if (this.size === 1) {
			this.renderWidgetContent();
		} else {
			this.renderContent();
		}
	},
	/**
	 * Handler function for the drop event
	 */
	onDrop: function () {
		this.destroy();
		//this.subscribe();
	},

	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			accountId: this.configuration.accountId,
			assetId: this.configuration.assetId,
			includeSubaccounts: State.includeSubaccounts
		}];
		this.subscriberId = this.id;

		const throttled = throttle(() => {
			if (this.lastRender === 'content') {
				this.renderContent();
			} else if (this.lastRender === 'widget') {
				this.renderWidgetContent();
			}
		}, 30000)

		this.subscriber = RemoteEventsManager.subscribeCallback(subscriptionObj, throttled)
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		this.subscriber?.unsubscribe()
		Widget.prototype.destroy.call(this);

	}
});
