import {orderBy} from "lodash";
import {KeysMatching} from "./types";


export interface SortSettings<T> {
	order: ('asc' | 'desc')[],
	sortFieldActions: ((x: T) => any)[]
}

export function flatTree<T>(
	items: T[],
	getChildren: (x: T) => T[] = (x: any) => x.children,
	expandedNode?: (x: T) => boolean,
	sortOption?: SortSettings<T>
): T[] {
	let result : T[] = [];
	const source = sortOption?.order
		? orderBy(items, sortOption.sortFieldActions, sortOption.order) as T[]
		: items;

	source?.forEach(x => {
		result.push(x);
		if(!expandedNode || expandedNode(x)) {
			result = result.concat(flatTree(getChildren(x), getChildren, expandedNode, sortOption));
		}
	});
	return result;
}

export const getPath = <T, I extends KeysMatching<T, string | number>, C extends KeysMatching<T, T[]>>(
	tree: T[], id: T[I], {idKey, childrenKey}: { idKey: I, childrenKey: C } = {
		idKey: 'id' as I,
		childrenKey: 'items' as C
	}
): T[I][] => {
	const found = tree.find(x => x[idKey] == id);
	if (found) {
		return []; // path not contains end node
	} else {
		for (let node of tree) {
			const childPath = getPath(node[childrenKey], id, {idKey, childrenKey});
			if (childPath) {
				return [node[idKey], ...childPath];
			}
		}
	}
	return null;
}
