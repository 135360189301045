import React from "react";
import ReactDOM from "react-dom";

import Utils from 'tools/utils'
import State from 'tools/state'
import {saveUserPrefs} from "tools/userPrefs";
import {checkFavorite, onFavoritesIcon} from 'tools/favorites'
import {RemoteEventsManager} from "core/remoteEventsManager";
import {AgentsRouter} from 'areas/management/agents/bundleDescription';
import Renderer from 'tools/renderer';
import {AssetGroupRouter} from "areas/assetgroups/bundleDescription";
import {MonitorsRouter} from "areas/assets/monitors/bundleDescription";
import {NavigationStore} from "framework/navigationStore";
import {AssetsRouter} from "../areas/assets/bundleDescription";
import {writeRouteIdLog} from "./mainApplication";

export let Application = function () {
	// set here default general properties if needed
	// this.initComponent();
	this.pagers = [];
};

export default Application;

Application.prototype = {
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	hasEvents: false,

	execute: function (config) {
		Utils.apply(this, config);

		this.initModule();

		if (this.removeListeners != null)
			this.removeListeners();

		if (this.attachListeners != null)
			this.attachListeners();
	},

	/**
	 * Basic application related initializations
	 */
	initComponent: function () {
		this.pagers = [];

		this.addMask();

		if (this.hasEvents) {
			if (this.subscribe) {
				this.subscribe();
			}
		}

		this.checkFavorites();
		$('body').off().on('click', '.cw_favorites', $.proxy(this.onFavoritesIcon, this));

		writeRouteIdLog(State.mainApp._runtime);
	},
	/*
	* Called to handle events when no need to define it in specific views
	* */
	handleEvents: function (events) {
		if (this.eventsToolbar) {
			this.eventsToolbar.addEvents(events);
		} else {
			if (this.onEvent) {
				this.onEvent(events);
			}
		}
	},
	/*
	 * Handler function for adding content mask
	 */
	addMask: function () {
		$('#content_area').append('<div class="cw_white_mask"></div>');
	},
	checkFavorites: function (name) {
		checkFavorite(name)
	},
	/*
	 * Handler function for removing the content mask
	 */
	removeMask: function () {
		State.mainApp?.removeLoadingMask();
	},

	/**
	 * Handler for clicking on add to favorites icon
	 * @param e
	 */
	onFavoritesIcon: function (e, name) {
		onFavoritesIcon(e, name)
	},
	/**
	 * Handler function for the click event on an breadcrumb item
	 * @param {Object} e The click event object
	 */
	onBreadCrumbItemClick: function (e) {
		var item = $(e.currentTarget),
			module = item.attr('data-module'),
			id = item.attr('data-id') || '',
			config = item.data('ceeviewBreadcrumb');// || ceeview.breadcrumb.module.config;

		if (!item.hasClass('current')) {
			State.mainApp.loadModule(module, id, config, e);
		}
	},

	/**
	 * Called after the top toggle is pressed
	 * @param {Boolean} includeSubaccounts
	 */
	onIncludeSubaccounts: function (includeSubaccounts) {
		var control;
		if (this.grid) {
			control = this.grid;
		} else if (this.treeList) {
			control = this.treeList;
		}
		if (control) {
			var url;
			if (control.dataSource.options.transport) {
				if (typeof control.dataSource.options.transport.read.url === 'function') {
					url = control.dataSource.options.transport.read.url();
				} else {
					url = control.dataSource.options.transport.read.url;
				}
			}
			//quickfix for controls which have not the transport option
			if (!url) {
				if (this.treeList) {
					//asset group summary tree list
					url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assetGroups?includeSubaccounts=' + State.includeSubaccounts;
				}
			}
			if (url.indexOf('includeSubaccounts') !== -1) {
				url = url.replace(/includeSubaccounts=(true|false)/, 'includeSubaccounts=' + includeSubaccounts);
			} else {
				url += url.indexOf('?') !== -1 ? '&' : '?';
				url += 'includeSubaccounts=' + includeSubaccounts;
			}

			if (this.showGridView) {
				if (includeSubaccounts) {
					control.showColumn('accountName');
				} else {
					control.hideColumn('accountName');
				}
			}
			if (control.dataSource.options.transport) {
				control.dataSource.options.transport.read.url = url;
				if (this.module && this.module.name === 'AssetGroupSummaryView') {
					this.treeListRefreshed = true;
				}

				//if unchecked, remove accountName from filtering and sorting
				let currentFilter = control.dataSource.filter();
				let currentSort = control.dataSource.sort();
				if (!includeSubaccounts) {
					if (currentFilter && currentFilter.filters) {
						let filters = currentFilter.filters;
						for (let i = 0; i < filters.length; i++) {
							if (filters[i].field === 'accountName') {
								currentFilter.splice(i, 1);
							} else if (filters[i].filters) {
								let subfilters = filters[i].filters;
								for (let j = 0; j < subfilters.length; j++) {
									if (subfilters[j].field === 'accountName' && !State.includeSubaccounts) {
										subfilters.splice(j, 1);
									}
								}
							}
						}
					}
					if (currentSort) {
						for (let i = 0; i < currentSort.length; i++) {
							if (currentSort[i].field === 'accountName') {
								currentSort.splice(i, 1);
							}
						}
					}
				}

				let searchValue = $('.cw_search_box').val();
				let searchFilterFound;
				if (currentFilter && searchValue && includeSubaccounts) {
					//if checked and we have a search value then accountName should be included in grid search filtering
					if (currentFilter.filters) {
						let filters = currentFilter.filters;
						for (let i = 0; i < filters.length; i++) {
							if (filters[i].filters) {
								let subfilters = filters[i].filters;
								for (let j = 0; j < subfilters.length; j++) {
									if (subfilters[j].value === searchValue) {
										searchFilterFound = true;
									}
								}
								if (searchFilterFound) {
									subfilters.push({
										field: 'accountName',
										operator: 'contains',
										value: searchValue
									})
								}
							}
						}
					}
				}

				control.dataSource.read();
			}
			if (this.subscribe) {
				this.subscribe();
			}
		}
	},
	/**
	 * Default method for switching between modules using non-stardand navingation links. Must be overwritten where necessary (mostly application views with widgets)
	 * @param {String} moduleName
	 * @param {String} id
	 * @param {Object} config
	 */
	tryExit: function (moduleName, id, config) {
		if (State.mainApp) {
			State.mainApp.loadModule(moduleName, id, config);
		}
	},
	/*
	 * Handler function for opening opening monitor details, configuration or form
	 * @param {Integer} monitorId Monitor id
	 * @param {String} monitorType Monitor type
	 * */
	openMonitorView: function (monitorId, monitorType) {
		openMonitorView(monitorId, monitorType);
	},


	/*
	 * Handler function for setting tooltip on monitor name
	 * @param {Object} grid The kendo grid object
	 */
	setMonitorNameTooltip: function (grid) {
		var gridContent = $(grid.wrapper).find('.k-grid-content');
		gridContent.kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (e) {
				var description;
				var uid = $(e.target).closest('tr').data('uid'), item = grid.dataSource.getByUid(uid);

				description = item.monitorDescription || item.description;
				return description.toString().split("\n").join("<br />");
			}
		});
	},
	/*
	 * Handler function for setting the highlight item
	 * @param {Object} e The event click object
	 * */
	setHighlightItem: function (e) {
		var target = $(e.currentTarget), itemId = target.closest('tr').find('.cw_grid_check').data('id');

		NavigationStore.updateCurrentItem({
			highlightItemId: itemId
		});
	},
	/*
	 * Handler function for highlighting the item
	 * @param {Object} grid The grid object
	 * */
	highlightItem: function (grid) {
		if (this.highlightItemId) {
			var wrapper = $(grid.wrapper);
			var row = wrapper.find(".cw_grid_check[data-id='" + this.highlightItemId + "']").closest('tr');
			if (row.length) {
				row.removeClass('k-alt');
				grid.select(row);
				var rowPos = row.position();
				wrapper.find('.k-grid-content').scrollTop(rowPos.top);
				//this.highlightItemId = null;
			}
		}
	},
	/*
	 * Handler function for setting highlighted monitor
	 * @param {String} monitorId
	 */
	setHighlightedMonitor: function (monitorId) {
		NavigationStore.updateCurrentItem({
			highlightMonitorId: monitorId
		});
	},
	/*
	 * Handler function for highlighting an monitor item in grid
	 * @param {Object} grid The grid object
	 */
	highlightMonitor: function (grid) {
		if (this.highlightMonitorId) {
			var wrapper = $(grid.wrapper);
			var row = wrapper.find(".cw_grid_link[data-id='" + this.highlightMonitorId + "']").closest('tr');
			row.removeClass('k-alt');
			grid.select(row);

			var rowPos = row.position();
			wrapper.find('.k-grid-content').scrollTop(rowPos.top);

			this.highlightMonitorId = null;
		}
	},
	/*
	 * Handler function for service qualifier agent name click
	 * @param {Object} e The click event
	 * */
	onAgentNameClick: function (e) {
		var target = $(e.currentTarget);
		var agentId = target.data('agentid');
		if (Utils.canEditAgent(agentId)) {
			State.mainApp.navigate(AgentsRouter.details(agentId));
		}
	},

	onTargetNameClick: function (e) {
		let target = $(e.currentTarget);
		let targetId = target.data('targetid');
		let targetType = target.data('targettype');
		if (targetType === 'ASSET') {
			NavigationStore.go(AssetsRouter.details(targetId));
		} else if (targetType === 'ASSET_GROUP') {
			State.mainApp.loadModule('AssetGroupSummaryView', '', {
				idToBeSelected: targetId
			}, e);
		}
	},
	/*
	 * Handler function for service qualifier asset name click
	 * @param {Object} e The click event
	 * */
	onSqAssetNameClick: function (e) {
		const target = $(e.currentTarget);
		const assetId = target.data('id');
		NavigationStore.go(AssetsRouter.details(assetId));
	},
	/*
	 * Handler function for service qualifier asset group name click
	 * @param {Object} e The click event
	 * */
	onSqAssetGroupNameClick: function (e) {
		var target = $(e.currentTarget);
		var assetGroupId = target.data('id');
		State.mainApp.navigate(AssetGroupRouter.details(assetGroupId));
	},
	/*
	 * Handler function for service qualifier monitor name click
	 * @param {Object} e The click event
	 * */
	onSqMonitorNameClick: function (e) {
		var target = $(e.currentTarget), monitorId = target.data('id'), monitorType = target.data('type');
		this.openMonitorView(monitorId, monitorType);
	},
	/*
	* Handler function for setting the error tooltip
	* @param {Object} handler The jQuery handler where to bind the tooltip
	* */
	setErrorToolTip: function (handler) {
		if (!this.tooltipOverview) {
			this.tooltipOverview = Renderer.errorToolTip(handler);
		}
	},
	/*
	 * Save user preferences
	 * @param {Object} config The configuration object
	 * */
	saveUserPrefs: function (config) {
		saveUserPrefs(config, this.eventsToolbar, this.userPref);
	},
	/**
	 * Unsubscribe
	 */
	unsubscribe: function () {
		if (this.subscriberId) {
			RemoteEventsManager.unsubscribe(this.subscriberId);
		}
		this.isDataSourceSubscribed = false;
	},

	destroyWindows() {
		let windows = $('.k-widget.k-window').find('.k-window-content');
		$(windows).each(function() {
			$(this).data('kendoWindow').destroy();
		});
	},

	/**
	 * Abstract. To be overwritten
	 */
	destroy: function () {
		if (this.pagers) {

			while (this.pagers.length) {
				if (this.pagers[0]) {
					this.pagers[0].destroy();
				}
				this.pagers.splice(0, 1);
			}
		}

		if ($(this.indicatorSelector).length) {
			ReactDOM.unmountComponentAtNode($(this.indicatorSelector).get(0));
		}

		// $('.k-animation-container.km-popup').remove();

		$('body > .k-popup').remove();

		if ($('body > .k-animation-container') && $('body > .k-animation-container').length) {
			$('body > .k-animation-container').remove();
		}
		if (this.grid) {
			let tooltip = $('.k-grid-content').data('kendoTooltip');
			if (tooltip) {
				tooltip.destroy();
			}
		}
		if (this.hasEvents) {
			this.unsubscribe();
		}

		if (this.mode === 'update' || this.mode === 'edit') {
			if (this.id) {
				RemoteEventsManager.discard(this.id);
			}
		}
	}
}

export function openMonitorView(monitorId, monitorType) {
	let url = MonitorsRouter.details(monitorType, monitorId);
	if(url){
		State.mainApp.navigate(url);
	}else {
		Utils.showInfo(lang.ALERT, 'No monitor found');
	}
}
