import {translator} from "core";
import Lang from "core/localization/lang";

const i = translator({
  "In order to select a new metric you have to remove the currently selected metric first": {
    "no": "For å velge en ny metrikk må du først fjerne valgt metrikk."
  },
  "Service Qualifier Name": {
    "no": "Tjenestepunkt navn",
    "en": "Servicequalifier name"
  },
  "Service Name": {
    "no": "Tjeneste navn",
    "en": "Service name"
  }
});


const ID_COL = {
	field: 'id',
	width: 50,
	locked: 'true',
	index: true
};
const ASSET_NAME_COL = {
	field: 'assetName',
	title: Lang.serviceBoard.ASSET_NAME,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	width: 200,
	resizable: true
};
const INSTANCE_NAME_COL = {
	field: 'instanceName',
	title: Lang.serviceBoard.INSTANCE_NAME,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const SOURCENAME_COL = {
	field: 'registryOwnerName',
	title: Lang.serviceBoard.SOURCENAME,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	width: 200,
	resizable: true
};
const ACCOUNT_COL = {
	field: 'accountName',
	title: Lang.serviceBoard.ACCOUNT,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const AGENT_NAME_COL = {
	field: 'agentName',
	title: Lang.serviceBoard.AGENT_NAME,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const CATEGORY_NODE_COL = {
	field: 'categoryNode',
	title: Lang.serviceBoard.CATEGORY_NODE,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const CATEGORY_PATH_COL = {
	field: 'categoryPath',
	title: Lang.serviceBoard.CATEGORY_PATH,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const METRIC_ID_COL = {
	field: 'metricId',
	title: Lang.serviceBoard.METRIC_ID,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const METRIC_TYPE_COL = {
	field: 'dataType',
	title: Lang.serviceBoard.METRIC_TYPE,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const REGISTRY_IDENTIFIER_COL = {
	field: 'registryIdentifier',
	title: Lang.serviceBoard.REGISTRY_IDENTIFIER,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const REGISTRY_OWNER_ID_COL = {
	field: 'registryOwnerId',
	title: Lang.serviceBoard.REGISTRY_OWNER_ID,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const REGISTRY_TYPE_COL = {
	field: 'registryType',
	title: Lang.serviceBoard.REGISTRY_TYPE,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};
const UNIT_TYPE_COL = {
	field: 'unitType',
	title: Lang.serviceBoard.UNIT_TYPE,
	sortable: true,
	attributes: {
		"class": "ellipsis to_expand"
	},
	width: 200,
	filterable: {
		cell: {
			operator: "contains",
			showOperators: false
		}
	},
	resizable: true
};

const colunnsLayouts = {
	'asset': () => {
		const assetName = {...ASSET_NAME_COL};
		assetName.locked = 'true';
		return [
			ID_COL,
			assetName,
			CATEGORY_NODE_COL,
			CATEGORY_PATH_COL,
			INSTANCE_NAME_COL,
			SOURCENAME_COL,
			ACCOUNT_COL,
			AGENT_NAME_COL,
			METRIC_ID_COL,
			METRIC_TYPE_COL,
			REGISTRY_IDENTIFIER_COL,
			REGISTRY_OWNER_ID_COL,
			REGISTRY_TYPE_COL,
			UNIT_TYPE_COL
		]
	},
	'service': () => {
		const sourceName = {...SOURCENAME_COL};
		sourceName.locked = 'true';
		sourceName.title = i('Service Name');
		const instanceName = {...INSTANCE_NAME_COL};
		instanceName.title = i('Service Qualifier Name');
		return [
			ID_COL,
			sourceName,
			instanceName,
			ASSET_NAME_COL,
			CATEGORY_NODE_COL,
			CATEGORY_PATH_COL,
			ACCOUNT_COL,
			AGENT_NAME_COL,
			METRIC_ID_COL,
			METRIC_TYPE_COL,
			REGISTRY_IDENTIFIER_COL,
			REGISTRY_OWNER_ID_COL,
			REGISTRY_TYPE_COL,
			UNIT_TYPE_COL
		]
	},
	'monitor': () => {
		const sourceName = {...SOURCENAME_COL};
		sourceName.locked = 'true';
		sourceName.title = i('Monitor Name');
		return [
			ID_COL,
			sourceName,
			ASSET_NAME_COL,
			CATEGORY_NODE_COL,
			CATEGORY_PATH_COL,
			INSTANCE_NAME_COL,
			ACCOUNT_COL,
			AGENT_NAME_COL,
			METRIC_ID_COL,
			METRIC_TYPE_COL,
			REGISTRY_IDENTIFIER_COL,
			REGISTRY_OWNER_ID_COL,
			REGISTRY_TYPE_COL,
			UNIT_TYPE_COL
		]
	}
}

export const getColumns = (metricLayout) => {
	return colunnsLayouts[metricLayout]();
};
