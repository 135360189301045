import './maintenanceCalendar.less';
import {CalendarEntry, MainCalendar} from "areas/administration/calendar/mainCalendar";
import React from "react";
import {Section} from "controls/react/layout/section";
import {BoxView} from "controls/react/layout/boxView";
import {translator} from "core/localization";
import {observer} from "mobx-react";
import {makeAutoObservable} from 'mobx';
import moment from 'moment';
import CalendarsAPI from 'areas/administration/calendar/api';
import {Calendar} from './calendarsApi';
import {ApplicationState} from 'framework/applicationState';
import {convertTimezone} from 'tools/dateTimeUtils';

const b = require('b_').with('maintenance-calendar');

export const i = translator({
	'is too old': {
		en: 'Only future maintenance periods are deleted. Active or historic maintenance period are not deleted.',
		no: 'Bare framtidige vedlikeholdsperioder ble slettet. Aktiv eller historiske vedlikeholdsperiode blir ikke slettet'
	}
});

type MessageType = 'info' | 'info-2' | 'warning' | 'error';

interface MaintenanceCalendarProps {
	onCalendarDataChanged: (data: string) => void;
	icalendar: string | null,
	height?: number | undefined
	additionalCalendars: Calendar[],
	protectedCalendars: Calendar[],
	timeZone: string,
	enableHistoric: boolean
}

interface MaintenanceCalendarConfig {
	calendarType: string,
	icalendar: string,
	timeZone: string
}

class MaintenanceCalendarStore {
	calendarConfig: MaintenanceCalendarConfig = {calendarType: 'generic', icalendar: '', timeZone: (moment as any).tz.guess()};
	message: string | undefined;
	messageType: MessageType;
	config: MaintenanceCalendarProps;

	constructor(data: MaintenanceCalendarProps) {
		makeAutoObservable(this);
		this.config = data;
	}

	setMessage = (message: string, type: MessageType = 'info-2') => {
		this.message = message;
		this.messageType = type;

		setTimeout(() => {
			this.message = undefined;
			this.messageType = undefined;
		}, 3000);
	}

	setCalendarData = (icalendar: string) => {
		this.calendarConfig = {...this.calendarConfig, icalendar, timeZone: this.config.timeZone ?? (moment as any).tz.guess()};
	}

	onCalendarDataChanged = (data: string) => {
		this.setCalendarData(data);
		this.config.onCalendarDataChanged?.(this.getDataToSave(data));
	}

	fixCategoryForGlobal = (config: string, calendar: Calendar) => {
		if (config.includes('CATEGORIES:')) {
			const rg = new RegExp('CATEGORIES:([^\\r\\n]+)\\r\\n', 'ig');
			return config.replaceAll(rg, `CATEGORIES:$1_${calendar.id}_GLOBAL\r\n`);
		}
		const rg = new RegExp('UID:([^\\r\\n]+)\\r\\n', 'ig');
		config = config.replaceAll('END:VEVENT', `EXTENDEDPROPS:{\"name\": \"${calendar.name}\"}\r\nEND:VEVENT`);
		return config.replaceAll(rg, `UID:$1\r\nCATEGORIES:${calendar.calendarType.toUpperCase()}_${calendar.id}_GLOBAL\r\n`);
	}

	mapCalendarData = async (calendar: Calendar): Promise<[string, Calendar]> => {
		if (calendar.calendarType == 'generic') {
			return [calendar.icalendar, calendar];
		}
		if (!calendar.id) {
			return ['', calendar]
		}
		const calendarData = await CalendarsAPI.generateHolidayCalendar(calendar.id, new Date().getFullYear());
		return [calendarData.data, calendar]
	}

	prepareCalendars = async (calendars: Calendar[]) => {
		if (!calendars?.length) {
			return [];
		}
		const promises = calendars.map(async x => await this.mapCalendarData(x));
		const calendarsData = await Promise.all(promises);
		return calendarsData.filter(x => !!x[0]).map(x => this.fixCategoryForGlobal(...x));
	}

	joinCalendars = (calendars: string[]) => {
		let calendarsData = calendars
			.filter(x => x.length)
			.reduce((res: string, current: string, index: number) => {
				if (index == 0) {
					return current.replaceAll('END:VCALENDAR\r\n', '');
				}
				const eventsIndex = current.indexOf('BEGIN:VEVENT');
				if (eventsIndex < 0) {
					return res;
				}
				return res + current.substring(eventsIndex).replaceAll('END:VCALENDAR\r\n', '');
			}, '');
		if (calendarsData.length) {
			calendarsData += 'END:VCALENDAR\r\n';
		}
		return calendarsData;
	}


	loadCalendarData = async () => {
		const additionalCalendars = await this.prepareCalendars(this.config.additionalCalendars);
		const protectedCalendars = await this.prepareCalendars(this.config.protectedCalendars);
		this.setCalendarData(this.joinCalendars([this.config.icalendar, ...additionalCalendars, ...protectedCalendars]) ?? '');
	}

	updateConfig = async (data: MaintenanceCalendarProps) => {
		this.config = data;
		await this.loadCalendarData();
	}

	onEventDelete = (ev: CalendarEntry, disableDateTo?: Date, excludeDate?: string) => {
		if (!disableDateTo) {
			return true;
		}
		if (ev.rrule && excludeDate) {
			if (moment(excludeDate).isSameOrBefore(disableDateTo, 'minute')) {
				this.setMessage(i('is too old'));
				return false;
			}
			if (moment(excludeDate).isSameOrBefore(disableDateTo, 'day') && moment(ev.start).isBefore(disableDateTo, 'minute')) {
				this.setMessage(i('is too old'));
				this.updateUntilDate(ev);
				return false;
			}
			return true;
		}
		if (ev.rrule) {
			if (ev.rrule.until && moment(ev.rrule.until).isSameOrBefore(disableDateTo, 'minute')) {
				this.setMessage(i('is too old'));
				return false;
			}
			if (moment(ev.start).isSameOrBefore(disableDateTo, 'minute')) {
				this.setMessage(i('is too old'));
				this.updateUntilDate(ev);
				return false;
			}
			return true;
		}
		if (moment(ev.end).isSameOrBefore(disableDateTo, 'minute')) {
			this.setMessage(i('is too old'));
			return false;
		}
		if (moment(ev.start).isSameOrBefore(disableDateTo, 'minute')) {
			this.setMessage(i('is too old'));
			this.updateEndDate(ev);
			return false;
		}
		return true;
	}

	updateEndDate = (ev: CalendarEntry) => {
		const data = this.calendarConfig.icalendar
			.split('BEGIN:VEVENT')
			.map((x => {
				if (!x.includes(`UID:${ev.id}`)) {
					return x;
				}
				const rg = new RegExp('DTEND;TZID=([a-zA-Z\/]+)\:\\\d{8}T\\\d{6}\\r\\n', 'ig');
				const replacement = `DTEND;TZID=$1:${moment().format('YYYYMMDDTHHmmss')}\r\n`;
				return x.replace(rg, replacement);
			}))
			.join('BEGIN:VEVENT');
		this.onCalendarDataChanged(data);
	}

	updateUntilDate = (ev: CalendarEntry) => {
		const data = this.calendarConfig.icalendar
			.split('BEGIN:VEVENT')
			.map((x => {
				if (!x.includes(`UID:${ev.id}`)) {
					return x;
				}
				let current = moment().add(1, 'second').toDate();
				if (ev.end >= convertTimezone(current, ev.timezone, (moment as any).tz.guess())) {
					const rgEnd = new RegExp('DTEND;TZID=([a-zA-Z\/]+)\:\\\d{8}T\\\d{6}\\r\\n', 'ig');
					const replacementEnd = `DTEND;TZID=$1:${moment().format('YYYYMMDDTHHmmss')}\r\n`;
					if (rgEnd.test(x)) {
						x = x.replace(rgEnd, replacementEnd);
					}
				}

				let rgUntil = new RegExp('UNTIL=\\\d{8}T\\\d{6}Z', 'ig');
				let replacementUntil = `UNTIL=${moment(convertTimezone(current, ev.timezone, (moment as any).tz.guess())).utc().format('YYYYMMDDTHHmmss')}Z`;
				if (rgUntil.test(x)) {
					x = x.replace(rgUntil, replacementUntil);
				} else {
					rgUntil = new RegExp('RRULE:(.*)\\r\\n', 'ig');
					replacementUntil = `RRULE:$1;UNTIL=${moment(convertTimezone(current, ev.timezone, (moment as any).tz.guess())).utc().format('YYYYMMDDTHHmmss')}Z\r\n`;
					x = x.replace(rgUntil, replacementUntil);
				}
				return x;
			}))
			.join('BEGIN:VEVENT');
		this.onCalendarDataChanged(data);
	}

	getDataToSave = (data: string): string => {
		const events = data.split('BEGIN:VEVENT\r\n');
		let result = events.filter(x => !x.match(/CATEGORIES:.+_GLOBAL/ig)).join('BEGIN:VEVENT\r\n');
		if (!result.endsWith('END:VCALENDAR\r\n')) {
			result += 'END:VCALENDAR\r\n';
		}
		return result;
	}
}

export const MaintenanceCalendar = observer(class MaintenanceCalendar extends React.Component<MaintenanceCalendarProps> {
	currentDate: Date = new Date();
	startTimeFrom: Date = new Date();
	store: MaintenanceCalendarStore;

	constructor(props: MaintenanceCalendarProps) {
		super(props);
		this.store = new MaintenanceCalendarStore(this.props);
	}

	async componentDidMount() {
		this.store.loadCalendarData();
	}

	componentDidUpdate(prevProps: Readonly<MaintenanceCalendarProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if (prevProps.icalendar != this.props.icalendar || prevProps.additionalCalendars != this.props.additionalCalendars) {
			this.store.updateConfig(this.props);
		}
	}

	disableDateTo = (): Date => {
		return this.store.config.enableHistoric ? undefined : new Date();
	}

	render() {
		return <div className={b()}>
			{this.store.message && <BoxView containerClass={'maintenance-calendar__message'} type={this.store.messageType}><Section appearance={'none'} contentPadding={true}>{this.store.message}</Section></BoxView>}
			<MainCalendar
				selectedDate={this.currentDate}
				config={this.store.calendarConfig}
				readOnly={false}
				icalendarChanged={this.store.onCalendarDataChanged}
				disableDateTo={this.disableDateTo}
				startTimeFrom={this.startTimeFrom}
				height={this.props.height - 55}
				onEventDelete={(ev: CalendarEntry, excludeDate: string) => this.store.onEventDelete(ev, this.disableDateTo(), excludeDate)}
				categories={'MAINTENANCE'}/>
		</div>
	}
});

export function convertDateToSelectedTimezone(date: Date | string, targetTimezone: string){
	return (moment(date) as any).tz(ApplicationState.timezone, true).tz(targetTimezone).format('YYYYMMDDTHHmmss')
}
