import './eventsSummary.less'

import React from 'react'
import ReactDOM from 'react-dom'
import {computed, makeAutoObservable, makeObservable, observable} from "mobx"
import {serialize} from "serializr"
import {Observer} from "mobx-react"

import {
	acknowledge,
	assign,
	deleteEvents,
	exportToCsv,
	GetEventsSummary,
	getEventsSummary,
	getRecipients,
	groupRecipients,
	resetHealthIndex,
	serviceCommand,
	setInformation,
	unacknowledge,
	unassign
} from "areas/summary/events/api"
import {TimePeriodType} from "controls/react/form/timePeriodSelector"
import {AssigneeType, EntryType, EventsSummaryEntry} from "areas/summary/events/eventsSummaryEntry"
import {GridStore} from "controls/grid/gridStore"
import {GridColumnConfig} from "controls/grid/gridColumnConfig"
import {SeverityIndicator} from "controls/react/severityIndicator"
import {CheckOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons'
import {AgentSecurity, AgentsRouter} from "areas/management/agents/bundleDescription"
import {dateToString} from "tools/dateTimeUtils"
import {Link} from "controls/react/link"
import {AssetsRouter} from "areas/assets/bundleDescription"
import {ServicesRouter} from "areas/services/bundleDescription"
import {SlaRouter} from "areas/sla/bundleDescription"
import {CostsRouter} from 'areas/cost/bundleDescription'
import {MonitorsRouter} from "areas/assets/monitors/bundleDescription"
import {ApplicationState} from "framework/applicationState"
import {Permission} from "framework/permission"
import {apiFetch, serverRoot} from "framework/api"
import {AutoReloadPlugin} from "controls/grid/plugins/autoReloadPlugin";
import {EventSummaryEvent} from "framework/entities/events";
import State from "tools/state";
import {ModalPosition, openModal} from "controls/react/ant/antModal";
import {AntSelect} from "controls/react/ant/antSelect";
import {linkModel, MobxManager} from "framework/mobx-integration";
import {Section} from "controls/react/layout/section";
import FormEntry from 'controls/react/form/formEntry'
import {AntTextArea} from "controls/react/ant/antInput";
import {createLogEntry} from "areas/services/api";
import {SetInformationWindow} from "controls/setInformationWindow";
import {ResetHealthIndexWindow} from "controls/resetHealthIndexWindow";
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import {DataListEntry} from 'framework/entities/dataList'
import {NavigationStore} from "framework/navigationStore";
import {healthDataToFlags} from 'framework/entities/healthData'
import {showActionsViewer} from "areas/views/actionsViewer";
import {GroupConjunction, RuleDefinition} from "controls/queryBuilder/ruleDefinition";
import {performAction} from "controls/react/ant/antMessage";
import {CeeviewNavigator} from "tools/ceeviewNavigator";


const i18n = require('core/localization/localization').translator({
	'User: {0}': {
		no: 'Bruker: {0}'
	},
	'Team: {0}': {
		no: 'Team: {0}'
	},
	'Create Incident':{
		no: 'Lag sak'
	},
	'Reset Health Index': {
		no: 'Tilbakestill helseindeks'
	},
	'Create Filter': {
		no: 'Opprett filter'
	},
	'Start Service': {
		no: 'Start tjeneste'
	},
	'Stop Service': {
		no: 'Stopp tjeneste'
	},
	'Add Log Entry': {
		no: 'Legg til loggmelding'
	},
	'Export to CSV': {
		no: 'Eksporter til CSV'
	},
	'Set Information': {
		no: 'Sett informasjon'
	},
	'Select user/team to assign event to': {
		no: 'Velg bruker/team som skal tildeles hendelsen'
	},
	"Event Summary": {
		"no": "Hendelseoversikt"
	},
	'Assign to': {
		no: 'Tildel til'
	},
	'Create log entry':	{},
	"Events are paused when historic mode is active": {
		no: "Hendelser er pauset når historiske hendelser er aktiv"
	}
});

export const b = require('b_').with('event-summary')

export type EventsSummaryStoreProps = GetEventsSummary & {
	onFiltersCleared?: (store: GridStore<EventsSummaryEntry>) => void
}

export abstract class EventsSummaryStore{
	gridStore: GridStore<EventsSummaryEntry>

	mobx = new MobxManager()

	protected constructor() {
		makeObservable(this, {
			gridStore: observable,
			hasAReasonSelected: computed({keepAlive: true}),
			menuItems: computed
		})

		this.mobx.when(() => this.gridStore?.initialized,() => {
			this.mobx.reaction(() => this.gridStore.state.customPayload.showHistoricEvents, (showHistoric) => {
				let autoReloadPlugin = this.gridStore.plugins.find(x => x.id == 'auto-reload') as AutoReloadPlugin<EventsSummaryEntry>
				if(autoReloadPlugin){
					autoReloadPlugin.disabled = !!showHistoric
					autoReloadPlugin.disabledReason = i18n('Events are paused when historic mode is active')
				}
			}, {
				fireImmediately: true
			})
		})
	}

	getCustomPayloadDefaults(timePeriod: TimePeriodType = TimePeriodType.LastDay){
		return {
			timePeriod: {
				period: timePeriod
			},
			showHistoricEvents: false
		}
	}

	getDefaultDatasource(props: EventsSummaryStoreProps){
		return getEventsSummary(props)
	}

	getAutoReloadPlugin(props: EventsSummaryStoreProps){
		return new AutoReloadPlugin<EventsSummaryEntry>({
			getSubscriptions: (store) => {
				return [EventSummaryEvent.subscription({
					filter: serialize(store.actualFilter),
					tags: props.tags,
					showUntagged: props.showUntagged,
					includeSubaccounts: props.includeSubaccounts
				})]
			}
		})
	}

	get title(){
		return i18n('Event Summary')
	}

	getColumns(navigator?: CeeviewNavigator){
		if(!this.columns){
			this.columns = [{
				title: i18n('Severity'),
				field: 'severity',
				align: 'center',
				renderer: (item) => <SeverityIndicator className={b({'historic-row': item.historic})} {...healthDataToFlags(item)}/>,
				width: 32
			}, {
				title: i18n('Time'),
				field: 'time',
				renderer: (item) => <span>{dateToString(item.time, 'datetime')}</span>,
				width: 150,
			}, {
				title: i18n('Target'),
				field: 'targetName',
				renderer: (item) => {
					if(!item.targetName)
						return i18n('Not available')

					const [url, enabled] =  getTargetEntryDescription(item)

					return <Link url={url}
					             enabled={enabled}
					             children={item.targetName}
					             navigator={navigator}/>
				},
				width: 150
			}, {
				title: i18n('Message'),
				field: 'message',
				expandOnClick: true,
				renderer: (item) => <span title={item.message}>{item.message}</span>,
				width: 350,
			}, {
				title: i18n('Name'),
				field: 'sourceName',
				renderer: (item) => {
					if(!item.sourceName)
						return i18n('Not available')

					const [url, enabled] =  getSourceEntryDescription(item)

					return <Link url={url}
					             enabled={enabled}
					             children={item.sourceName}
					             navigator={navigator}/>
				},
				width: 150
			}, {
				title: i18n('Suppression'),
				field: 'suppressionCount',
				align: 'right',
				renderer: (item) => item.suppressionCount,
				width: 90
			}, {
				title: i18n('Action Count'),
				field: 'actionCount',
				align: 'right',
				renderer: (item) => <Link
					onClick={(e) => {showActionsViewer(e, item.id)}}
					navigator={navigator}
					enabled={item.actionCount != 0}>
					{item.actionCount}
				</Link>,
				width: 90
			}, {
				title: i18n('Type'),
				field: 'type',
				renderer: (item) => item.typeText,
				width: 150
			}, {
				title: i18n('Acknowledged'),
				field: 'acknowledged',
				align: 'center',
				renderer: (item) => item.acknowledged ? <CheckOutlined/> : null,
				width: 40
			}, {
				title: i18n('Assigned Type'),
				field: 'assignedToType',
				align: 'center',
				className: b('assignee-type'),
				renderer: (item) => {
					switch (item.assignedToType) {
						case AssigneeType.User:
							return <UserOutlined title={i18n('User: {0}', item.assignedTo)}/>;
						case AssigneeType.Team:
							return <TeamOutlined title={i18n('Team: {0}', item.assignedTo)}/>;
						default:
							return null;
					}
				},
				width: 40
			}, {
				title: i18n('Account'),
				field: 'accountId',
				renderer: (item) => <span title={item.accountName}>{item.accountName}</span>,
				width: 150
			}, {
				title: i18n('Historic'),
				field: 'historic',
				align: 'center',
				renderer: (item) => item.historic ? <CheckOutlined/> : null,
				width: 30
			}, {
				field: 'agentName',
				title: i18n('Agent Name'),
				renderer: (item) => item.agentName
					? <Link url={AgentsRouter.details(item.agentId)}
					                 enabled={AgentSecurity.canRead(item.agentId)}
					                 navigator={navigator}
					                 children={item.agentName}/>
					: i18n('Not available'),
				width: 150
			}, {
				title: i18n('Tags'),
				field: 'tags',
				renderer: (item) => {
					const tags = item.tags.join();
					return <span title={tags}>{tags}</span>
				},
				width: 150
			}, {
				title: i18n('Acknowledged By'),
				field: 'acknowledgedBy',
				renderer: (item) => item.acknowledgedBy,
				width: 150
			}, {
				title: i18n('Acknowledged At'),
				field: 'acknowledgedAt',
				renderer: (item) => <span>{dateToString(item.acknowledgedAt, 'datetime')}</span>,
				width: 150
			}, {
				title: i18n('Information'),
				field: 'information',
				renderer: (item) => item.information,
				width: 150
			}, {
				title: i18n('In Maintenance'),
				field: 'inMaintenance',
				renderer: (item) => item.inMaintenance,
				width: 150
			}, {
				title: i18n('Monitor Type'),
				field: 'monitorTypeText',
				renderer: (item) => item.monitorTypeText,
				width: 150
			}, {
				title: i18n('Assigned To'),
				field: 'assignedTo',
				renderer: (item) => item.assignedTo,
				width: 150
			}, {
				title: i18n('Details'),
				field: 'details',
				renderer: (item) => item.details,
				expandOnClick: true,
				width: 150
			}, {
				title: i18n('Created'),
				field: 'timeCreated',
				renderer: (item) => <span>{dateToString(item.timeCreated, 'datetime')}</span>,
				width: 150
			}, {
				title: i18n('Assigned Time'),
				field: 'assignedTime',
				renderer: (item) => <span>{dateToString(item.assignedTime, 'datetime')}</span>,
				width: 150
			}, {
				title: i18n('Asset Group'),
				field: 'groups',
				renderer: (item) => item.groups,
				width: 150
			}, {
				title: i18n('Agent ID'),
				field: 'agentId',
				renderer: (item) => item.agentId,
				visible: false,
				width: 150
			}, {
				title: i18n('External ID'),
				field: 'externalId',
				renderer: (item) => item.externalId,
				visible: false,
				width: 150
			}, {
				title: i18n('External Owner'),
				field: 'externalOwner',
				renderer: (item) => item.externalOwner,
				visible: false,
				width: 150
			}, {
				title: i18n('Identifier Instance'),
				field: 'identifierInstance',
				renderer: (item) => item.identifierInstance,
				visible: false,
				width: 150
			}, {
				title: i18n('Identifier Name'),
				field: 'identifierName',
				renderer: (item) => item.identifierName,
				visible: false,
				width: 150
			}, {
				title: i18n('Monitor Class'),
				field: 'monitorClassText',
				renderer: (item) => item.monitorClassText,
				visible: false,
				width: 150
			}, {
				title: i18n('Subsystem'),
				field: 'subsystem',
				renderer: (item) => item.subsystem,
				visible: false,
				width: 150
			}, {
				title: i18n('Source ID'),
				field: 'sourceId',
				renderer: (item) => item.sourceId,
				visible: false,
				width: 150
			}, {
				title: i18n('Target ID'),
				field: 'targetId',
				renderer: (item) => item.targetId,
				visible: false,
				width: 150
			}, {
				title: i18n('Time Deleted'),
				field: 'timeDeleted',
				renderer: (item) => <span>{dateToString(item.timeDeleted, 'datetime')}</span>,
				visible: false
			}]
		}

		return this.columns
	}


	columns: GridColumnConfig<EventsSummaryEntry>[]

	get hasAReasonSelected() {
		return this.gridStore.selection.some( x => x.type == EntryType.AssetHealthReason)
	}

	get menuItems() {
		return [{
			icon: 'construction-cone',
			text: i18n('Create Incident'),
			fn: this.createIncident,
			role: 'INCIDENT_CREATE',
			isEnabled: () => this.hasAReasonSelected

		}, {
			icon: 'check',
			text: i18n('Acknowledge'),
			fn: this.acknowledge,
			isEnabled: () => this.gridStore.selection.some( x => !x.acknowledged),
		//	role: 'EVENTS_UPDATE',
			categoryIndex: 2
		}, {
			icon: 'unchecked',
			text: i18n('Unacknowledge'),
			fn: this.unacknowledge,
		//	role: 'EVENTS_UPDATE',
			isEnabled: () => this.gridStore.selection.some( x => x.acknowledged),
			categoryIndex: 2
		}, {
			icon: 'restart',
			text: i18n('Reset Health Index'),
			fn: this.resetHealthIndex,
			role: 'MONITOR_UPDATE'
		}, {
			icon: 'filter',
			text: i18n('Create Filter'),
			fn: this.createFilter,
			isEnabled: () => this.isOnlyOneAssetHealthReasonSelected,
			role: 'HEALTHFILTER_CREATE'
		}, {
			icon: 'play',
			text: i18n('Start Service'),
			fn: this.startService,
			isEnabled: () => this.hasAReasonSelected,
			role: 'MONITOR_UPDATE',
			categoryIndex: 4
		}, {
			icon: 'stop',
			text: i18n('Stop Service'),
			fn: this.stopService,
			isEnabled: () => this.hasAReasonSelected,
			role: 'MONITOR_UPDATE',
			categoryIndex: 4
		}, {
			icon: 'plus-sign',
			text: i18n('Add Log Entry'),
			fn: this.addLogEntry,
			role: 'SERVICE_UPDATE',
			isEnabled: () => this.singleServiceEntrySelected != null
		},{
			icon: 'undo',
			text: i18n('Unassign'),
			fn: this.unassign,
			role: 'EVENTS_UPDATE',
			isEnabled: () => this.gridStore.selection.isAnythingSelected,
			categoryIndex: 3
		}, {
			icon: 'object-align-horizontal',
			text: i18n('Assign'),
			fn: this.assign,
			role: 'EVENTS_UPDATE',
			isEnabled: () => this.gridStore.selection.isAnythingSelected,
			categoryIndex: 3
		}, {
			icon: 'file-export',
			text: i18n('Export to CSV'),
			fn: this.exportToCsv
		}, {
			icon: 'remove-circle',
			text: i18n('Delete'),
			fn: this.deleteEvents,
			role: 'EVENTS_DELETE',
			isEnabled: () => this.gridStore.selection.isAnythingSelected,
			categoryIndex: 1
		}, {
			icon: 'info-sign',
			text: i18n('Set Information'),
			fn: this.setInformation,
			role: 'EVENTS_UPDATE',
			isEnabled: () => this.gridStore.selection.isAnythingSelected
		}]
	}

	exportToCsv = async () => {
		const response = await performAction(() => apiFetch(exportToCsv(this.gridStore)))
		if (response.success) {
			window.open(serverRoot + 'sessions/exportCSV/' + response.data)
		}
	}

	createIncident = () => {
		let assets: Record<string, DataListEntry<string>> = {}

		const healthEvents = this.gridStore.selection.dataset.items
			.filter( x => x.type == EntryType.AssetHealthReason)

		const reasonsIds = healthEvents.map( x => x.id)

		healthEvents.reduce((result, event) => {
			result[event.targetId] = {
				name: event.targetName,
				id: event.targetId
			}
			return result
		}, assets)

		NavigationStore.go(IncidentsRouter.createNew('ASSET'), {
			extra: {
				assets: Object.values(assets),
				reasonsIds: reasonsIds
			}
		});
	}

	resetHealthIndex = async () => {
		const container = document.createElement('div')
		document.body.appendChild(container)

		const destroy = () => {
			ReactDOM.unmountComponentAtNode(container)
		}

		const reset = async (value: string) => {
			const result = await performAction(() => apiFetch(resetHealthIndex(this.gridStore, value)))

			if (result.success) {
				this.gridStore.selection.clear()
				this.gridStore.dataProvider.silentReload()
			}
			destroy()
		}

		ReactDOM.render(<ResetHealthIndexWindow
			onClose={destroy}
			onReset={reset}
		/>, container)
	}

	acknowledge = async () => {
		const result = await performAction(() => apiFetch(acknowledge(this.gridStore)))

		if (result.success) {
			this.gridStore.selection.clear()
			this.gridStore.dataProvider.silentReload()
		}
	}

	unacknowledge = async () => {
		const result = await performAction(() => apiFetch(unacknowledge(this.gridStore)))

		if(result.success){
			this.gridStore.selection.clear()
			this.gridStore.dataProvider.silentReload()
		}
	}

	startService = async () => {
		const result = await performAction(() => apiFetch(serviceCommand(this.gridStore, 'START')))

		if (result.success) {
			this.gridStore.selection.clear()
		}
	}

	stopService = async () => {
		const result = await performAction(() => apiFetch(serviceCommand(this.gridStore, 'STOP')))

		if (result.success) {
			this.gridStore.selection.clear()
		}
	}

	addLogEntry = async() => {
		let serviceEntry = this.singleServiceEntrySelected;
		if (serviceEntry == null)
			return

		let store = new LogEntryStore()

		openModal({
			onOk: async () => {
				await performAction( () =>  apiFetch(createLogEntry({
					message: store.message,
					state: store.state,
					serviceId: serviceEntry.targetId
				})))

				this.gridStore.selection.clear()
			},
			positionType: ModalPosition.TopLeft,
			title: i18n('Create log entry')
		}, <Observer>{ () =>
			<Section childrenPadding={true}>
				<FormEntry label={i18n('State')}>
					<AntSelect dataList={this.logEntryStatusesDataList} {...linkModel(store, 'state')}/>
				</FormEntry>
				<FormEntry label={i18n('Message')} vertical={true}>
					<AntTextArea {...linkModel(store, 'message')} height={300}/>
				</FormEntry>
			</Section>
		}</Observer>)
	}

	createFilter = async () => {
		if(!this.isOnlyOneAssetHealthReasonSelected) {
			return
		}

		const record = this.gridStore.selection.dataset.items[0]

		State.mainApp.loadModule('AssetHealthFiltersConfiguration', '', {
			mode: 'create',
			resetHealthIndex: true,
			populate: {
				assetId: record.targetId,
				monitorId: record.sourceId,
				monitorType: record.monitorType,
				severity: record.severity,
				reasonDescription: record.message,
				reasonSubsytem: record.subsystem,
				identifierName: record.identifierName || '',
				identifierInstance: record.identifierInstance || '',
				accountId: record.accountId,
				accountName: record.accountName
			}
		});
	}

	assign = async () => {
		let store = new AssignStore()

		const result = await apiFetch(getRecipients())
		if(!result.success)
			return

		const options = groupRecipients(result.data)
		openModal({
			onOk: async () => {
				const recipient = result.data.find( x => x.id == store.id)

				await performAction(() => apiFetch(assign(this.gridStore, store.id, recipient.type)))

				this.gridStore.selection.clear()
			},
			positionType: ModalPosition.TopLeft,
			title: i18n('Assign to')
		}, <Section childrenPadding={true}>
			<FormEntry label={i18n('Select user/team to assign event to')} vertical={true}>
				<AntSelect
					{...linkModel(store, 'id')}
					showSearch={true}
					options={options}
					optionFilterProp="label"
				/>
			</FormEntry>
		</Section>)
	}

	unassign = async () => {
		const result = await performAction(() => apiFetch(unassign(this.gridStore)))

		if(result.success){
			this.gridStore.selection.clear()
			this.gridStore.dataProvider.silentReload()
		}
	}

	deleteEvents = async () => {
		const result = await performAction( () => apiFetch(deleteEvents(this.gridStore)))

		if(result.success){
			this.gridStore.selection.clear()
			this.gridStore.dataProvider.silentReload()
		}
	}

	setInformation = async () => {
		const container = document.createElement('div')
		document.body.appendChild(container)

		const destroy = () => {
			ReactDOM.unmountComponentAtNode(container)
		}

		const updateInformation = async (value: string) => {
			const result = await performAction( () => apiFetch(setInformation(this.gridStore, value)))

			if(result.success){
				this.gridStore.selection.clear()
				this.gridStore.dataProvider.silentReload()
			}

			destroy()
		}

		ReactDOM.render(<SetInformationWindow
			onClose={destroy}
			onUpdate={updateInformation}
			topLeftCorner={true}
		/>, container)

	}

	get isOnlyOneAssetHealthReasonSelected() {
		return this.gridStore.selection.exactlyOne(x => x.type == EntryType.AssetHealthReason) != null
	}

	get singleServiceEntrySelected (){
		return this.gridStore.selection.exactlyOne( x =>
			[EntryType.ServiceError, EntryType.Service, EntryType.ServiceElement, EntryType.ServiceQualifier].indexOf(x.type) != -1)
	}

	get logEntryStatusesDataList (){
		return  [{
			id: 'OK',
			name: i18n('Ok')
		}, {
			id: 'ERROR',
			name: i18n('Error')
		}, {
			id: 'WARNING',
			name: i18n('Warning')
		}, {
			id: 'FAILED',
			name: i18n('Failed')
		}, {
			id: 'UNKNOWN',
			name: i18n('Unknown')
		}]
	}

	destroy() {
		this.mobx.destroy()
		this.gridStore?.destroy();
	}
}

function getTargetEntryDescription(item: EventsSummaryEntry) : [string, boolean]{
	const has = ApplicationState.hasPermissions

	switch (item.type){
		case EntryType.MonitorError:
		case EntryType.AssetHealthReason:
			return [
				AssetsRouter.details(item.targetId),
				has(Permission.AggregatedHealthAssetRead) || has(Permission.AggregatedHealthMonitorRead)
			]

		case EntryType.AgentState:
			return [
				AgentsRouter.details(item.targetId),
				has(Permission.AggregatedManegementAgentRead) || has(Permission.AggregatedHealthAssetRead)
			]

		case EntryType.Service:
		case EntryType.ServiceWarning:
		case EntryType.ServiceElement:
		case EntryType.ServiceError:
		case EntryType.ServiceLink:
		case EntryType.ServiceModel:
		case EntryType.ServiceQualifier:
		case EntryType.ServiceQualifierWarning:
			let objectToHightlight: Record<string, string> = {}
			if( item.type == EntryType.ServiceQualifier || item.type == EntryType.ServiceQualifierWarning){
				objectToHightlight.element = item.sourceId
			}

			if(item.type == EntryType.ServiceElement || item.type == EntryType.ServiceLink){
				objectToHightlight.node = item.sourceId
			}

			return [
				ServicesRouter.viewer(item.targetId, {highlightObj: objectToHightlight}),
				has(Permission.AggregatedServiceRead)
			]

		case EntryType.Sla:
			return [SlaRouter.details(item.targetId), has(Permission.AggregatedServiceRead)]

		case EntryType.SystemError:
		case EntryType.AgentWarning:
			return [AgentsRouter.details(item.targetId), true]

		case EntryType.Cost:
			return [CostsRouter.detailsByProfile(item.sourceId, item.targetId), true]

		default:
			console.warn('Missing type', item.type, item)
			return ['', false]
	}
}

function getSourceEntryDescription(item: EventsSummaryEntry) : [string, boolean]{
	const has = ApplicationState.hasPermissions

	switch (item.type){
		case EntryType.MonitorError:
		case EntryType.AssetHealthReason:
			return [
				MonitorsRouter.details(item.monitorType, item.sourceId),
				has(Permission.AggregatedHealthAssetRead) || has(Permission.AggregatedHealthMonitorRead)
			]

		case EntryType.AgentState:
			return [
				AgentsRouter.details(item.sourceId),
				has(Permission.AggregatedManegementAgentRead) || has(Permission.AggregatedHealthAssetRead)
			]

		case EntryType.Service:
		case EntryType.ServiceWarning:
		case EntryType.ServiceElement:
		case EntryType.ServiceError:
		case EntryType.ServiceLink:
		case EntryType.ServiceModel:
		case EntryType.ServiceQualifier:
		case EntryType.ServiceQualifierWarning:
			return [ServicesRouter.details(item.targetId), has(Permission.AggregatedServiceRead)]

		case EntryType.Sla:
			return [ServicesRouter.details(item.sourceId), true]

		case EntryType.SystemError:
			return [AssetsRouter.details(item.sourceId), true]

		case EntryType.AgentWarning:
			return [AgentsRouter.details(item.sourceId), true]

		case EntryType.Cost:
			return [CostsRouter.detailsByProfile(item.sourceId), true]

		default:
			console.warn('Missing type', item.type, item)
			return ['', false]
	}
}

export function getFilterByEntityId(entityId: string){
	let group = RuleDefinition.emptyGroup()

	let rule = RuleDefinition.emptyRule('sourceId', "multiselect_equals")
	rule.properties.value = [entityId]
	group.addOrUpdateRule(rule)

	rule = RuleDefinition.emptyRule('targetId', "multiselect_equals")
	rule.properties.value = [entityId]
	group.addOrUpdateRule(rule)

	group.properties.conjunction = GroupConjunction.Or

	return group
}

class LogEntryStore{
	state: string = 'OK'
	message: string

	constructor() {
		makeAutoObservable(this)
	}
}

class AssignStore{
	id: string
}
