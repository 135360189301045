import {Localization, translator} from '../../core';

export const costTranslates = new Localization({
	'cost': {no: 'kostnad'},
	'costSchedule': {en: 'Cost', no: 'kostnad'},
	'advisorSchedule': {en: 'Advisor', no: 'Advisor'},
	'resourcesSchedule': {en: 'Resources', no: 'Ressurser'},
	'budget': {no: 'budsjett'},
	'Cost Profile': {no: 'Kostnadsprofil'},
	'Cost Gateway': {no: 'Kostnad Gateway'},
	'Cost gateway': {no: 'Kostnad gateway'},
	'all': {no: 'alle'},
	'Split': {no: 'Splitt'},
	'Total year': {no: 'Total år'},
	'Import external cost data': {no: 'Importer kostdata'},
	'No Cost Profile is created for this link. It might be from an Cost collector without a Cost Profile.': {
		no: 'Det er ingen kostprofil opprettet for denne linken. Den kan komme fra en Kost collector uten Kost profil.'
	},
	'Single delete confirmation': {
		'en': 'Are you sure you want to remove this cost?',
		'no': 'Vil du fjerne denne kostnad?'
	},
	'Multiple delete confirmation': {
		'en': 'Are you sure you want to remove these costs?',
		'no': 'Vil du fjerne disse kostnad'
	},
	'Hierarchy cost link': {no: 'Hierarki kost link'},
	'Configuration': {no: 'Konfigurasjon'},
	'Show cost': {
		no: 'Vis kostnad'
	},
	'Show budget': {
		no: 'Vis budsjett'
	},
	'Creates a link to a row in another Cost Profile. Note that only the cost and name is linked. Budget is set per Cost Profile.': {
		no: 'Oppretter en link til en rad i en annen kostprofil. Merk at bare kost og navn er lenket. Budsjett styres per kostprofile.'
	},
	'The Cost Item link will include its sub tree of Cost Items if any. Note that this can only be used if created on the first level of a Cost Profile.': {
		no: 'Kost linken vil inkludere sub treet til valgt Kost Element. Dette kan kun gjøres fra nivå 1 i Kost Profilen.'
	},
	'Start date': {
		no: 'Start dato'
	},
	'Currency': {
		no: 'Valuta'
	},
	'Show estimates': {
		no: 'Vis estimater'
	},
	'Year': {
		no: 'År'
	},
	'Year Estimate': {
		no: 'Årsestimat'
	},
	'Current month': {
		no: 'Nåværende måned'
	},
	'Current month estimate': {
		no: 'Estimat nåværende måned'
	},
	"Month order": {no: "Måned rekkefølge"},
	"Current month first": {no: "Nåværende måned først"},
	"Asc": {},
	"Desc": {},
	"Cost Model": {no: "Kostnad Modell"},
	"Cost Store": {no: "Kostnad Base"},
	"Cost Store/Model": {no: "Kostnad Base/Modell"},
	"Cost Sheet": {no: "Kostnad Ark"},
	"Dirty confirmation": {
		"en": "You have unsaved content in the Cost Profile. You will lose the changes if you click Close. Click Cancel and save the changes if you want to keep them.",
		"no": "Du har ulagret innhold i Kostnadsprofilen. Du vil miste endringene hvis du klikker Lukk. Klikk på Avbryt og lagre endringene hvis du vil beholde dem."
	},
	'Costprofile': {
		no: 'Kostnadsprofil'
	},
	'Display Decimals': {no: 'Vis desimaler'},
	'Display as 1000': {no: 'Vises som 1000'},
	'Costmodel settings': {},
	'Costprofile settings': {},
	"Please select": {
		"no": "Velg"
	},
	'Costmodel': {no: 'Kostmodell'},
	'Budget period': {'no': 'Budsjettperiode'},
	'Link name': {no: 'Lenkenavn'},
	'Only Costp Pofiles with same currency can be selected due to issues with currency conversion.': {
		no: 'Bare Kostprofiler med samme valuta kan velges grunnet problemer med valutakonvertering.'
	},
	'Cost report metric': {},
	'Metric registry': {},
	'The link\'s cost profile is deleted. Please remove this link.': {no: 'Lenken\'s kostprofil er slettet. Fjern denne lenken.'},
	'DELETED': {},
	'Numerics': {},
	"Display Settings": {"no": "Visningsinnstillinger"},
	'Columns': {'no': 'Kolonner'},
	'Estimate': {},
	'Event summary': {'no': 'Hendelseoversikt'},
	'Metric': {no: 'Metrikk'},
	'Expression': {no: 'Expression'},
	'Link': {no: 'Link'},
	'Name exists': {
		'en': 'An identical name exist on this account, please use another name.',
		'no': 'Et identisk navn finnes på denne kontoen, bruk et annet navn.'
	},
	'listing price': {
		no: 'listepris'
	},
	'ListingPrice': {
		en: 'Listing price',
		no: 'Listepris'
	},
	'listingPrice': {
		en: 'Listing price',
		no: 'Listepris'
	},
	'Start month': {
		no: 'Start måned'
	},
	'Duplicate last year values': {
		no: 'Dupliser siste års verdier'
	},
	'Use previous year Cost': {
		no: 'Bruk forrige år Kostnad'
	},
	'Use previous year Budget': {
		no: 'Bruk forrige år Budsjett'
	},
	'Use previous year Listing Price': {
		no: 'Bruk forrige år Listepris'
	},
	'Create new year': {
		no: 'Opprett nytt år'
	},
	'Do you really want to delete budget year': {
		no: 'Vil du virkelig slette budsjettår'
	},
	'Delete confirmation': {
		no: 'Slett bekreftelse'
	},
	'Budget year': {
		no: 'Budsjettår'
	},
	'Linked Cost profile item is not reachable. It might be that it belong on a a higher account level.': {
		no: 'Linket Kost profil kan ikke nås. Det kan være fordi den tilhører konto på et høyere nivå.'
	},
	'Month Order': {
		no: 'Måned rekkefølge',
	},
	'Threshold': {
		no: 'Terskelverdi'
	},
	'The Warning threshold cant be equal or larger than the Critical threshold': {
		no: 'Advarselsterskelen kan ikke være lik eller større enn kritisk terskel'
	},
	'Breach of a threshold give an Event.': {
		no: 'Brudd av en terskelverdi gir en Hendelse.'
	},
	'Percentage change': {
		no: 'Prosentvis endring'
	},
	'%': {
		no: '%'
	},
	'Percentage change from previous month': {
		no: 'Prosentvis endring fra forrige måned'
	},
	'Percentage change from previous period': {
		no: 'Prosentvis endring fra forrige periode'
	},
	'decrease from previous month': {
		no: 'nedgang fra forrige måned'
	},
	'increase from previous month': {
		no: 'økning fra forrige måned'
	},
	'No change from previous month': {
		no: 'Ingen endring fra forrige måned'
	},
	'decrease from previous year': {
		no: 'nedgang fra forrige år'
	},
	'increase from previous year': {
		no: 'økning fra forrige år'
	},
	'No change from previous year': {
		no: 'Ingen endring fra forrige år'
	},
	'costRate': {
		en: '%',
		no: '%'
	},
	'Read only, Listing price is only received from Cost store link': {
		no: 'Kun les, listepris hentes kun fra kost lenke'
	}
});

export const i = translator(costTranslates);
