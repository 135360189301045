import {ApiRequest} from 'framework/api';

export class UserDataLite {
	id: string;
	username: string;
	firstName: string;
	lastName: string;
}

export function convertType(userId: string) {
	return new ApiRequest({
		method: 'POST',
		url: `users/${userId}/authType/CEEVIEW`,
		accountBased: true
	});
}

export function getUser(userId: string) {
	return new ApiRequest<UserDataLite>({
		url: `users/${userId}/lite`,
		accountBased: true
	})
}
