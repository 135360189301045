import {Utils, State, Renderer, UserPrefs} from 'tools'
import Application from 'core/application'
import {PreferencesPanel, Dialog, GridMenu, CustomNotification} from 'controls'
import Settings from 'settings'
import Cookies from 'core/cookies'
import {AutomationRouter} from "./bundleDescription";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import EventsToolbar from 'controls/eventsToolbar';
import {activateAutomations, AutomationApi, deactivateAutomations} from "areas/automation/api";
import {topLeftCornerPosition} from 'controls/modalWindow'
import {apiFetch} from "framework/api";
import {getSelectedIds} from "vendor-init/kendo/tools";

const i18n = require('core/localization').translator();

/**
 * @class ceeview.automation.Application This class is used as an entry point in
 * the automation application.
 * @namespace ceeview.automation
 * @extends ceeview.Application
 * @constructor
 * @param {Object} config The config object
 */
export default function Automation(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'Automation',
		initialConfig: config
	};
	this.subscriberId = Utils.guid();
	// initializing data inside this class
	this.initComponent();
};

jQuery.extend(Automation.prototype, Application.prototype, {
	/**
	 * Kpi application related initializations can be done here
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.actionNotification = new CustomNotification({
			appendToElement: '#cw_automation_section .k-grid-content',
			type: 'icon',
			hideOnClick: true
		});

		this.removeListeners();
		this.attachListeners();
		// removes loading mask
		this.removeMask();
		this.loadUserPreferences();
		State.mainApp.contextMenu.setActionButton('AUTOMATION');
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_automation_list').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$(window).on('resize', $.proxy(this.adjustSectionHeight, this));
		$('#cw_automation_list').on('click', '.cw_grid_check', $.proxy(this.onProfileCheck, this));
		$('#cw_automation_list').on('click', '.cw_grid_check_all', $.proxy(this.onProfileCheckAll, this));
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('Automation', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Initializes kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter, url;
		let eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 0,
			playing: true
		};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_automation_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 110
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			triggerType: {
				hidden: false,
				width: 150
			},
			active: {
				hidden: false,
				width: 100
			},
			tags: {
				hidden: false,
				width: 150
			},
			description: {
				hidden: false
			}

		};
		var automationSort, automationFilter;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			automationSort = pref.sort;
			automationFilter = pref.filter;
			this.automationColumns = pref.columns;
		} else {
			if (UserPrefs.get('automationSort')) {
				automationSort = JSON.parse(UserPrefs.get('automationSort'));
			} else {
				automationSort = [{
					field: 'name',
					dir: 'asc',
					compare: null
				}];
			}
			if (UserPrefs.get('automationFilter')) {
				automationFilter = JSON.parse(UserPrefs.get('automationFilter'));
			} else {
				automationFilter = [];
			}
		}
		var automationColumns = this.automationColumns || JSON.parse(UserPrefs.get('automationColumns')) || this.defaultColumns;
		automationColumns = Utils.completeColumns(automationColumns, this.defaultColumns);
		var dontLoadDefault = false;
	 	url =  Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/automations/?includeSubaccounts=' + State.includeSubaccounts.toString() + '&_dc=';

		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: $.proxy(function (results) {
					for (var i = 0, length = results.length; i < length; i++) {
						if (!results[i].trigger) {
							results[i].trigger = {};
						}
						results[i].triggerType = results[i].trigger.type || '';
						results[i].accountName = results[i].accountName || '';
						results[i].isActive = results[i].active ? lang.ACTIVE : lang.INACTIVE;
						if (results[i].tags) {
							results[i].tags = results[i].tags.join(', ');
						}
					}
					return results;
				}, this)
			},
			sort: automationSort,
			filter: automationFilter
		});
		this.grid = $('#cw_automation_list').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			selectable: 'single',
			reorderable: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: automationColumns.id.hidden,
				width: automationColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				template: item => `<a class="cw_grid_link" href="#${AutomationRouter.details(item.id)}">${item.name}</a>`,
				sortable: true,
				filterable: true,
				hidden: automationColumns.name.hidden,
				width: automationColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: !State.includeSubaccounts || automationColumns.accountName.hidden,
				width: automationColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'triggerType',
				title: lang.automation.TRIGGER_TYPE,
				sortable: true,
				filterable: true,
				hidden: automationColumns.triggerType.hidden,
				width: automationColumns.triggerType.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'active',
				title: lang.STATUS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'isActive');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				template: '<span>${data.isActive}</span>',
				hidden: automationColumns.active.hidden,
				width: automationColumns.active.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							contains: lang.grid.filter.ISIN,
							doesnotcontain: lang.grid.filter.ISNOTIN
						}
					}
				},
				hidden: automationColumns.tags.hidden,
				width: automationColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: true,
				filterable: true,
				hidden: automationColumns.description.hidden,
				width: automationColumns.description.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], automationColumns),
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onDataBound, this),
			columnMenu: true
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		this.gridMenu = new GridMenu({
			renderTo: 'cw_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: this.onCreate,
				scope: this,
				disabled: false,
				role: 'AUTOMATION_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onDelete,
				scope: this,
				disabled: true,
				role: 'AUTOMATION_DELETE'
			}, {
				id: 'cw_activate',
				icon: 'play',
				text: i18n('Activate'),
				fn: this.activate,
				scope: this,
				disabled: true,
				role: 'AUTOMATION_CREATE'
			},{
				id: 'cw_inactivate',
				icon: 'stop',
				text: i18n('Deactivate'),
				fn: this.deactivate,
				scope: this,
				disabled: true,
				role: 'AUTOMATION_CREATE'
			},]
		});
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('automationSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_automation_filters',
			grid: this.grid,
			modulePrefName: 'Automation',
			defaultPrefViewKey: 'defaultFsView',
			dontLoadDefault: dontLoadDefault,
			prefListKey: 'automationFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},

	activate: async function(){
		await apiFetch(activateAutomations(getSelectedIds(this.grid)))
		this.grid.dataSource.read()

	},

	deactivate: async function(){
		await apiFetch(deactivateAutomations(getSelectedIds(this.grid)))
		this.grid.dataSource.read()
	},

	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_automation_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the grid data bound event
	 */
	onDataBound: function () {
		this.tooltips = $('#cw_automation_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		}).data('kendoTooltip');
		this.showNotifications();
	},
	showNotifications: function () {
		if (this.savedProfileNotification) {
			this.actionNotification.setOptions({
				message: this.savedProfileNotification,
				status: 'success'
			}).show();
			this.savedProfileNotification = null;
		}
	},
	/*
	 * Handler function for incident create
	 */
	onCreate: function (e) {
		this.app.navigate(AutomationRouter.createNew());
	},
	/**
	 * Handler for the delete incident button
	 */
	onDelete: function (e) {
		this.selectedProfiles = [];
		if (!$(e.currentTarget).hasClass('disabled')) {
			var checkboxes = this.grid.element.find('.cw_grid_check:checked');
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					this.selectedProfiles.push($(checkboxes[i]).data('id'));
				}
			}
			this.selectedProfilesLength = this.selectedProfiles.length;
			if (this.selectedProfilesLength) {
				this.gridMenu.disableItem('cw_create');
				this.gridMenu.disableItem('cw_delete');

				var dialog = new Dialog({
					title: lang.INFO,
					msg: this.selectedProfiles.length > 1 ? lang.automation.PROFILES_REMOVE_CONFIRMATION : lang.automation.PROFILE_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					position: topLeftCornerPosition,
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							kendo.ui.progress($('#cw_automation_list'), true);
							this.checkDependencies();
						}
					}, this),
					deleteFromGrid: this.grid
				});
				dialog.show();
			}
		}
	},
	/**
	 * Handler for checking profile dependencies
	 */
	checkDependencies: function () {
		if (this.selectedProfiles.length === 1) {
			var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/automations/' + this.selectedProfiles[0];
			Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
				if (result.success) {
					this.grid.dataSource.read();
					this.actionNotification.setOptions({
						message: lang.automation.PROFILE_SUCCESS_DELETED,
						status: 'success'
					}).show();
					this.gridMenu.enableItem('cw_create');
					this.gridMenu.enableItem('cw_delete');
					$('.k-loading-mask').remove();
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
					this.gridMenu.enableItem('cw_create');
					this.gridMenu.enableItem('cw_delete');
				}
			}, this));
		} else {
			var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/automations/delete';
			Utils.ajax(deleteUrl, 'POST', JSON.stringify(this.selectedProfiles), $.proxy(function (result) {
				if (result.success) {
					this.grid.dataSource.read();
					this.actionNotification.setOptions({
						message: lang.automation.PROFILES_SUCCESS_DELETED,
						status: 'success'
					}).show();
					this.gridMenu.enableItem('cw_create');
					this.gridMenu.enableItem('cw_delete');
					$('.k-loading-mask').remove();
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
					this.gridMenu.enableItem('cw_create');
					this.gridMenu.enableItem('cw_delete');
				}
			}, this));
		}
	},
	onEventsTimeout: function () {
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
		this.grid.dataSource.read();
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'Automation',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'automationSearchPhrase',
				columns: 'automationColumns',
				sort: 'automationSort',
				filter: 'automationFilter',
				FsViews: 'automationFSViews'
			}
		});
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function () {
		this.eventsToolbar?.destroy();
		this.tooltips?.destroy();
		this.saveUserPreferences();
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onProfileCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		this.onProfileCheck();
	},
	/**
	 * Handler for the asset checkbox click
	 */
	onProfileCheck: function () {
		var checkboxes = $('.cw_grid_check');
		var checked = false;
		var count = 0;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				checked = true;
				count++;
			}
		}
		if (checked) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_activate');
			this.gridMenu.enableItem('cw_inactivate');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_activate');
			this.gridMenu.disableItem('cw_inactivate');
		}
	},
});
