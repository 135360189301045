import React from "react"

import {NavigationStore} from "framework/navigationStore"
import Settings from "settings"
import {ModalPosition, openModal} from "controls/react/ant/antModal"
import {ApplicationState} from "framework/applicationState";


export enum RedirectModifier {
	None,
	NewTab,
	Popup
}

export type RedirectPopup = {
	width: number
	height: number
	position: ModalPosition
	scaleContent?: boolean
}

export type NavigatorGoArgs = {
	action?: (modifier: RedirectModifier) => void
	url?: string
	modifier?: RedirectModifier
	popup?: RedirectPopup
}

export class CeeviewNavigator {
	get forceEnabled(){
		return ApplicationState.standalone && ApplicationState.isGuestUser
	}

	go(props: NavigatorGoArgs) {
		if(props.action){
			props.action(props.modifier)
			return
		}

		if (props.modifier == RedirectModifier.NewTab || props.modifier == RedirectModifier.Popup) {
			let url = props.url

			if (!url.startsWith('http')) {
				if (!url.startsWith('#')) {
					url = '#' + url
				}

				url = Settings.httpRoot + url
			}

			if (props.modifier == RedirectModifier.Popup) {
				openModal({
						title: 'Preview',
						footer: null,
						positionType: props.popup.position,
						width: props.popup.width,
						height: props.popup.height
					},
					<iframe src={url} style={{width: "100%", height: '100%'}}/>
				)
			} else {
				window.open(url, "_blank");
			}
		} else {
			if (props.url.startsWith('http')) {
				window.location.href = props.url;
			} else {
				NavigationStore.go(props.url)
			}
		}
	}

	canDoDefault(){
		return true
	}
}
