import Application from 'core/application';
import GridMenu from 'controls/gridMenu';
import PreferencesPanel from 'controls/preferencesPanel';
import Settings from 'settings';

import ErrorHandler from 'core/errorHandler';
import UserPrefs from 'tools/userPrefs';
import State from 'tools/state';

/**
 * @class ceeview.management.ModulesView
 * This is the View class for the modules
 * @namespace ceeview.management
 * @extends Application
 * @constructor
 */
export default function ModulesView (config) {
    Utils.apply(this, config);
    this.module = {
        name: 'ModulesView',
        initialConfig: config
    };

    this.initComponent();
};
jQuery.extend(ModulesView.prototype, Application.prototype, {
    /**
     * Main init function
     */
    initComponent: function () {
        Application.prototype.initComponent.call(this);


		this.removeListeners();
        this.attachListeners();
        this.loadUserPreferences();
        this.removeMask();
    },
    /**
     * Removes attached listeners
     */
    removeListeners: function () {
        $('#cw_account_modules').off();
    },
    /**
     * Attaches listeners
     */
    attachListeners: function () {
        $('#cw_account_modules').on('click', '.cw_grid_check', $.proxy(this.onModuleCheck, this));
        $('#cw_account_modules').on('click', '.cw_grid_check_all', $.proxy(this.onModuleCheckAll, this));
    },
    /**
     * Initialize kendo components
     */
    initKendoComponents: function () {
        var modulesFilter, modulesSort, filterMessages = lang.grid.filter;
        var defaultFsView = UserPrefs.get('defaultFsView');
        this.defaultColumns = {
            id: {
                hidden: false,
                width: 30
            },
            installed: {
                hidden: false,
                width: 100
            },
            name: {
                hidden: false,
                width: 200
            },
            group: {
                hidden: false,
                width: 130
            },
            description: {
                hidden: false
            }
        };
        this.gridMessages = {
            clear: lang.CLEAR,
            info: lang.grid.filter.SHOW_ITEMS,
            filter: lang.FILTER
        };
        if (Utils.isGuid(defaultFsView)) {
            var pref = JSON.parse(UserPrefs.get(defaultFsView));
            if (pref) {
                modulesSort = pref.sort;
                modulesFilter = pref.filter;
                this.modulesColumns = pref.columns;
            } else {
                modulesSort = [];
                modulesFilter = [];
            }
        } else {
            if (UserPrefs.get('modulesSort')) {
                modulesSort = JSON.parse(UserPrefs.get('modulesSort'));
            } else {
                modulesSort = [{
                    field: 'installed',
                    dir: 'asc',
                    compare: null
                }];
            }
            if (UserPrefs.get('modulesFilter')) {
                modulesFilter = JSON.parse(UserPrefs.get('modulesFilter'));
            } else {
                modulesFilter = [];
            }
        }
        var modulesColumns = this.modulesColumns || JSON.parse(UserPrefs.get('modulesColumns')) || this.defaultColumns;
		modulesColumns = Utils.completeColumns(modulesColumns, this.defaultColumns);
        this.modulesDataSource = new kendo.ceeview.DataSource({
            transport: {
                read: {
                    url: Settings.managementPath + 'modules/',
                    contentType: "application/json; charset=utf-8",
                    type: "GET",
                    dataType: "json",
                    cache: false
                }
            },
            schema: {
                data: function (response) {
                    return response.data;
                },
                model: {
                    fields: {
                        installed: {
                            type: 'string'
                        }
                    }
                }
            },
            sort: modulesSort,
            filter: modulesFilter,
            error: ErrorHandler.kendoServerError
        });
        this.grid = $('#cw_account_modules').kendoCustomGrid({
            dataSource: this.modulesDataSource,
            resizable: true,
			reorderable: true,
            selectable: 'row',
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            filterable: {
                extra: false,
                messages: this.gridMessages
            },
            columns: Utils.rearrangeColumns([{
                field: 'id',
                title: ' ',
                sortable: false,
                filterable: false,
                template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
                headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
                attributes: {
                    'class': 'text_center'
                },
                headerAttributes: {
                    'class': 'text_center'
                },
                hidden: modulesColumns.id.hidden,
                width: modulesColumns.id.width
            }, {
                field: 'installed',
                title: lang.widget.STATUS,
                template: "#= (installed === 'true' ? lang.INSTALLED : lang.NOT_INSTALLED) #",
                attributes: {
                    'class': "#= (installed === 'true' ? 'green' : '') #"
                },
                hidden: modulesColumns.installed.hidden,
                width: modulesColumns.installed.width,
                filterable: {
                    operators: {
                        string: {
                            eq: filterMessages.EQ,
                            neq: filterMessages.NEQ
                        }
                    },
                    ui: function (element) {
                        element.kendoDropDownList({
                            dataSource: [{
                                text: lang.INSTALLED,
                                value: 'true'
                            }, {
                                text: lang.NOT_INSTALLED,
                                value: 'false'
                            }],
                            dataTextField: 'text',
                            dataValueField: 'value',
                            optionLabel: lang.grid.FILTER_SELECT_VALUE,
                            change: Utils.onFilterDropDownChange
                            //template: '#=Renderer.slaStatus(value)# <span>${data.text}</span>',
                        });
                    }
                }
            }, {
                field: 'name',
                title: lang.NAME,
                template: '#= name #',
                hidden: modulesColumns.name.hidden,
                width: modulesColumns.name.width,
                filterable: {
                    operators: {
                        string: {
                            startswith: filterMessages.STARTS_WITH,
                            neq: filterMessages.NEQ,
                            eq: filterMessages.EQ,
                            contains: filterMessages.CONTAINS
                        }
                    }
                },
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'group',
                title: lang.GROUP,
                template: '#= group #',
                hidden: modulesColumns.group.hidden,
                width: modulesColumns.group.width,
                filterable: {
                    operators: {
                        string: {
                            startswith: filterMessages.STARTS_WITH,
                            neq: filterMessages.NEQ,
                            eq: filterMessages.EQ,
                            contains: filterMessages.CONTAINS
                        }
                    }
                },
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'description',
                title: lang.DESCRIPTION,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            },
                hidden: modulesColumns.description.hidden,
                width: modulesColumns.description.width,
                filterable: {
                    operators: {
                        string: {
                            startswith: filterMessages.STARTS_WITH,
                            neq: filterMessages.NEQ,
                            eq: filterMessages.EQ,
                            contains: filterMessages.CONTAINS
                        }
                    }
                }
            }], modulesColumns),
            columnMenu: true,
            change: $.proxy(this.onRowExpand, this),
            dataBound: $.proxy(function () {
				let tooltip = this.grid.content.data('kendoTooltip');
				if (tooltip) {
					tooltip.destroy();
				}
				$('#cw_account_modules').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});

                if (!State.mainApp.session.hasRole('MODULE_INSTALL') && !State.mainApp.session.hasRole('MODULE_REMOVE')) {
                    $('.cw_grid_check').prop('disabled', 'disabled');
                    $('.cw_grid_check_all').prop('disabled', 'disabled');
                }
	            if (!State.mainApp.session.hasRole('MODULE_INSTALL')) {
		            this.gridMenu.disableItem('cw_install_module');
	            }
	            if (!State.mainApp.session.hasRole('MODULE_REMOVE')) {
		            this.gridMenu.disableItem('cw_uninstall_module');
	            }
            }, this)
        }).data('kendoCustomGrid')
        // Add Kendo tooltip to the header of the columns
        Utils.gridColumnHeaderTooltip(this.grid);
        this.adjustSectionHeight();
        this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
        this.gridMenu = new GridMenu({
            renderTo: 'modules_titlebar',
            items: [{
                id: 'cw_install_module',
                icon: 'disk-saved',
                text: lang.account.INSTALL_MODULE,
                fn: this.onInstallModule,
                role: 'MODULE_INSTALL',
                scope: this,
                cssClass: '',
                disabled: true
            }, {
                id: 'cw_uninstall_module',
                icon: 'disk-remove',
                text: lang.account.UNINSTALL_MODULE,
                fn: this.onUninstallModule,
                role: 'MODULE_REMOVE',
                scope: this,
                cssClass: '',
                disabled: true
            }]
        });
        var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('modulesSearchPhrase') || '');
        this.gridFilterPanel = new PreferencesPanel({
            renderTo: 'cw_modules_filters',
            dontLoadDefault: this.accountName ? true : false,
            grid: this.grid,
            modulePrefName: 'Modules',
            defaultPrefViewKey: 'defaultFsView',
            prefListKey: 'modulesFSViews',
            userPref: this.userPref,
            onRemove: $.proxy(this.saveUserPreferences, this),
            searchFields: ['name', 'description', 'group', 'installed'],
            searchValue: searchValue,
            defaultColumns: this.defaultColumns
		});
    },
    /**
     * Handler function for the change(select) event on the grid
     * @param {Object} e The change event object
     */
    onRowExpand: function (e) {
	    var selectedRow = this.grid.select();
	    var myRow = selectedRow[0];
	    var messageEl = $(myRow).find('.to_expand');
	    if ($(messageEl).hasClass('cw_message_expanded')) {
		    $(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
	    } else {
		    $('#cw_account_modules').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
		    $(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
	    }
    },
    /**
     * Handler function for the install menu option
     * @param {Object} e
     */
    onInstallModule: function (e) {
        var url = Settings.managementPath + 'modules/install?';
        var checboxes = $('#cw_account_modules').find('.cw_grid_check');
        var i, length = checboxes.length, modules = [], itemUid, item, status = $('.status'), text;
        for (i = 0; i < length; i++) {
            if ($(checboxes[i]).is(':checked')) {
                itemUid = $(checboxes[i]).closest('tr').attr('data-uid');
                item = $('#cw_account_modules').data('kendoCustomGrid').dataSource.getByUid(itemUid);
                if (item.installed === 'false') {
                    modules.push($(checboxes[i]).attr('data-id'));
                }
            }
        }
        url += modules;
        kendo.ui.progress($('#cw_account_modules'), true);
        Utils.ajax(url, 'POST', JSON.stringify(modules), (result) => {
            if (result.success) {
            	this.grid.dataSource.read();
				const isMonitorManagement = true;
				State.mainApp.setInstalledMonitors(isMonitorManagement);
                status.find('p').addClass('success');
                text = lang.account.messages.MODULE_SUCCESS_INSTALL;
                $('#cw_account_modules').find('.cw_grid_check_all').prop('checked', false);
            }
            else {
                status.find('p').addClass('error');
                text = lang.account.messages.MODULE_FAIL_INSTALL;
            }
            kendo.ui.progress($('#cw_account_modules'), false);
            status.find('p').text(text);
            status.slideDown().delay(2000).slideUp();
        });
    },
    /**
     * Handler function for the uninstall menu option
     * @param {Object} e
     */
    onUninstallModule: function (e) {
        var url = Settings.managementPath + 'modules/uninstall';
        var checboxes = $('#cw_account_modules').find('.cw_grid_check');
        var i, length = checboxes.length, modules = [], itemUid, item, status = $('.status'), text;
        for (i = 0; i < length; i++) {
            if ($(checboxes[i]).is(':checked')) {
                itemUid = $(checboxes[i]).closest('tr').attr('data-uid');
                item = $('#cw_account_modules').data('kendoCustomGrid').dataSource.getByUid(itemUid);
                if (item.installed === 'true') {
                    modules.push($(checboxes[i]).attr('data-id'));
                }
            }
        }
        kendo.ui.progress($('#cw_account_modules'), true);
        Utils.ajax(url, 'POST', JSON.stringify(modules), (result) => {
            if (result.success) {
				this.grid.dataSource.read();
				const isMonitorManagement = true;
				State.mainApp.setInstalledMonitors(isMonitorManagement);
                status.find('p').addClass('success');
                text = lang.account.messages.MODULE_SUCCESS_UNINSTALL;
                $('#cw_account_modules').find('.cw_grid_check_all').prop('checked', false);
            }
            else {
                status.find('p').addClass('error');
                text = lang.account.messages.MODULE_FAIL_UNINSTALL;
            }
            kendo.ui.progress($('#cw_account_modules'), false);
            status.find('p').text(text);
            status.slideDown().delay(2000).slideUp();
        });
    },
    /**
     * Handler for the service checkbox click
     * @param {Object} e The click event
     */
    onModuleCheck: function (e) {
        var checkboxes = $('#cw_account_modules').find('.cw_grid_check');
        var checked = false;
        for (var i = 0, length = checkboxes.length; i < length; i++) {
            if ($(checkboxes[i]).is(':checked')) {
                checked = true;
                break;
            }
        }
        if (checked) {
            this.gridMenu.enableItem('cw_install_module');
            this.gridMenu.enableItem('cw_uninstall_module');
        } else {
            this.gridMenu.disableItem('cw_install_module');
            this.gridMenu.disableItem('cw_uninstall_module');
        }
    },
    /**
     * Handler for the check all checkbox click
     * @param {Object} e The click event
     */
    onModuleCheckAll: function (e) {
        var checkAll = $('#cw_account_modules').find('.cw_grid_check_all').is(':checked');
        var checkboxes = $('#cw_account_modules').find('.cw_grid_check');
        var i, length = checkboxes.length;
        for (i = 0; i < length; i++) {
            $(checkboxes[i]).prop('checked', checkAll);
        }
        if (checkAll && checkboxes.length) {
            this.gridMenu.enableItem('cw_install_module');
            this.gridMenu.enableItem('cw_uninstall_module');
        } else {
            this.gridMenu.disableItem('cw_install_module');
            this.gridMenu.disableItem('cw_uninstall_module');
        }
    },
    /**
     * Method by Andy
     */
    adjustSectionHeight: function () {
        var section = $('.cw_management_module .cw_section_full:visible');
        var sectionHeight = section.height();
        section.find('.cw_section_content').css('height', sectionHeight);
        section.find('.k-grid-content').css('height', sectionHeight - 40);
    },
    /**
     * Saves user preferences
     */
    saveUserPreferences: function () {
        this.saveUserPrefs({
            category: 'Modules',
            filterPanel: this.gridFilterPanel,
            grid: this.grid,
            keys: {
                searchPhrase: 'modulesSearchPhrase',
                columns: 'modulesColumns',
                sort: 'modulesSort',
                filter: 'modulesFilter',
                FsViews: 'modulesFSViews'
            }
        });
    },
    /**
     * Loads user preferences
     */
    loadUserPreferences: function () {
        this.userPref = [];
        UserPrefs.load('Modules', $.proxy(function (result) {
            if (result.success) {
                this.userPref = result.data;
                this.initKendoComponents();
            } else {
                Utils.showInfo(lang.ALERT, result.message, result.details);
            }
        }, this));
    },
    /**
     * Called when application is unloaded/destroyed
     */
    destroy: function () {
        this.saveUserPreferences();
    }
});
