import {Application, LocalEventsManager, Cookies, RemoteEventsManager, ErrorHandler} from 'core';
import {CustomNotification, EventsToolbar, GridMenu, MultiToggle, PreferencesPanel, ModalView} from 'controls';
import {Utils, UserPrefs, State, Api} from 'tools';
import {ServiceModelForm} from "areas/administration/template";

import Renderer from 'tools/renderer';
import ModalWindow from 'controls/modalWindow';
import Settings from 'settings';
import Dialog from 'controls/dialog';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {topLeftCornerPosition} from 'controls/modalWindow'

export let ServiceModelView = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default ServiceModelView;

jQuery.extend(ServiceModelView.prototype, Application.prototype, {
	/**
	 * Service model templates view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.getTags();

		State.mainApp.contextMenu.setActionButton('ASSET');

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});

		this.viewType = 'grid';
		this.gridLoaded = false;
		this.cardLoaded = false;

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 260
			},
			tags: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};
		this.loadUserPreferences();

		// update content button
		this.cardGridToggle = new MultiToggle({
			id: 'cw_smtemplates_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_smtemplates_grid_menu',
			items: [{
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onSMTemplateDelete,
				scope: this,
				disabled: true,
				role: 'SERVICE_MODEL_DELETE'
			},
				{
					id: 'cw_import',
					icon: 'disk-import',
					text: lang.IMPORT,
					fn: this.onSMTemplateImport,
					scope: this,
					disabled: false,
					role: 'SERVICE_MODEL_UPDATE'
				},
				{
					id: 'cw_export',
					icon: 'disk-export',
					text: lang.EXPORT,
					fn: this.onSMTemplateExport,
					scope: this,
					disabled: true,
					role: 'SERVICE_MODEL_UPDATE'
				}]
		});

		this.removeMask();
		this.initKendoComponents();
		this.removeListeners();
		this.attachListeners();
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Initialize Kendo components
	 */
	initKendoComponents: function () {
		$('#cw_smtemplates_upload').kendoUpload({
			async: {
				autoUpload: true,
				saveUrl: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/import',
				saveField: 'file'
			},
			multiple: false,
			showFileList: true,
			withCredentials: false,
			localization: {
				select: lang.kendo.UPLOAD_SELECT,
				statusFailed: lang.kendo.UPLOAD_STATUS_FAILED,
				statusUploaded: lang.kendo.UPLOAD_UPLOADED,
				statusUploading: lang.kendo.UPLOAD_UPLOADING,
				uploadSelectedFiles: lang.kendo.UPLOAD_SELECTED_FILES
			},
			error: function (e) {

			},
			upload: $.proxy(function (e) {
				var xhr = e.XMLHttpRequest;
				if (xhr) {
					xhr.addEventListener("readystatechange", function (e) {
						if (xhr.readyState == 1 /* OPENED */) {
							xhr.setRequestHeader("Auth-Token", Cookies.sessionId);
						}
					});
				}
				var file = e.files[0];
				this.fileType = file.rawFile.type;
				this.fileName = file.rawFile.name;
			}, this),
			success: $.proxy(function (e) {
				this.actionNotification.setOptions({
					message: lang.account.messages.SERVICE_TEMPLATE_SUCCESS_UPLOADED,
					status: 'success'
				}).show();
				this.dataSource.read();
			}, this)
		});
		$('.k-upload').addClass('hide');
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {String} value
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			this.viewType = 'grid';
			$('#cw_smtemplates_list').addClass('hide');
			$('#cw_smtemplates_grid').removeClass('hide');

			if (this.grid) {
				$('.list_content').addClass('list_content_full');
			} else {
				$('.list_content').removeClass('list_content_full');
				this.createDataSource();
				this.gridLoaded = false;
				this.initGridView();
			}
		} else {
			this.viewType = 'card';
			$('#cw_smtemplates_grid').addClass('hide');
			if (this.innerView) {
				$('#cw_smtemplates_list').removeClass('hide');
			} else {
				$('#cw_smtemplates_grid').addClass('hide');
				this.createDataSource();
				this.cardLoaded = false;
				this.initCardView();
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#smtemplates_grid').off();
		this.onSMTemplateSavedHandler = $.proxy(this.onSMTemplateSaved, this);
		LocalEventsManager.unbind('smtemplatesaved', this.onSMTemplateSavedHandler);
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#smtemplates_grid').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#smtemplates_grid').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#smtemplates_grid').on('click', '.cw_grid_link', $.proxy(this.onTemplateNameClick, this));
		LocalEventsManager.bind('smtemplatesaved', this.onSMTemplateSavedHandler);
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		var scope = this;
		var deleteClass = '';
		var updateClass = '';
		var template = '<li><div class="cw_card" id="${id}">';
		template += '<div class="cw_card_avatar">';
		template += '<div class="cw_avatar cw_avatar_sm_template">';
		template += '<img src="' + Api.templates.preview('${id}') + '" onError="this.onerror=null;Renderer.setTemplateDefaultIcon(this, \'model\');" />';
		template += '</div>';
		template += '</div>';
		template += '<div class="cw_card_content">';
		template += '<h2 class="card_title card2"><strong>' + lang.NAME + ':</strong> ${name} </h2>';
		template += '<p class="card_extra card2"><strong>' + lang.DESCRIPTION + ':</strong> ${description}</p>';
		template += '</div>';
		template += '<div class="cw_card_options">';
		template += '<span class="glyphicons bin ' + deleteClass + '"></span>';
		template += '<span class="glyphicons pencil ' + updateClass + '"></span>';
		template += '</div></li>';
		this.showGridView = false;
		$('#cw_smtemplates_toggle').find('li').last().addClass('is_selected');

		// create sm templates view
		this.innerView = new ModalView({
			autoLoad: true,
			createIds: '',
			createButtonText: '',
			viewDataSource: this.dataSource,
			dataBound: $.proxy(this.cardViewDataBound, this),
			template: template,
			id: 'cw_smtemplates_list',
			cssClass: 'sm_templates_ul',
			title: lang.designer.SERVICE_MODEL_TEMPLATES,
			countId: 'count_sm_templates',
			pager: true,
			onEditButton: function (e) {
				var templateId = e.currentTarget.parentNode.parentNode.id;
				var modalWindow = new ModalWindow({
					title: lang.account.SERVICE_MODEL_TEMPLATE_DETAILS,
					width: 400,
					height: 440,
					minHeight: 440,
					actions: [],
					url: 'include/Administration/ServiceModelTemplateForm.jsp',
					onClose: function () {
						RemoteEventsManager.discard(templateId);
					},
					refresh: function () {
						 new ServiceModelForm({
							id: templateId,
							mode: 'update'
						});
					}
				});
				modalWindow.open();
			},
			onDeleteButton: function (e) {
				var id = e.currentTarget.parentNode.parentNode.id;
				let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/' + id + '/servicemodels/';
				var dialog = new Dialog({
					title: lang.INFO,
					msg: lang.account.messages.SERVICE_MODEL_TEMPLATE_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					scope: this,
					actionText: 'DELETE',
					fn: function (value, button) {
						if (button === 'ok') {
							Utils.ajax(url, 'DELETE', {}, $.proxy(function (result) {
								if (result.success) {
									scope.actionNotification.setOptions({
										message: lang.account.messages.SERVICE_MODEL_TEMPLATE_SUCCESS_DELETED,
										status: 'success'
									}).show();

									// reload data source
									scope.dataSource.read();
								} else {
									Utils.showInfo(lang.ALERT, result.message, result.details);
								}
							}, this));
						}
					}
				});
				dialog.show();
			}
		});
		//$('#cw_filter_reset').addClass('inactive');
		//$('#cw_smtemplates_grid').addClass('hide');
		setTimeout(function () {
			$('#cw_smtemplates_list').removeClass('hide');
		}, 300);

	},
	/**
	 * Init grid component
	 */
	initGridView: function () {
		var filterMessages = lang.grid.filter;
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		var smtemplatesColumns = this.smtemplatesColumns || JSON.parse(UserPrefs.get('smtemplatesColumns')) || this.defaultColumns;
		smtemplatesColumns = Utils.completeColumns(smtemplatesColumns, this.defaultColumns);
		this.grid = $('#smtemplates_grid').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			autoBind: !this.gridLoaded,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				hidden: smtemplatesColumns.id.hidden,
				width: smtemplatesColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id #">#= name #</a>',
				hidden: smtemplatesColumns.name.hidden,
				width: smtemplatesColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: smtemplatesColumns.tags.hidden,
				width: smtemplatesColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: false,
				filterable: true,
				template: '#= description #',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: smtemplatesColumns.description.hidden,
				width: smtemplatesColumns.description.width
			}], smtemplatesColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onGridDataBound, this)
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('smtemplatesSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_smtemplates_filters',
			grid: this.grid,
			modulePrefName: 'AdministrationSMTemplates',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'smtemplatesFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	/*
	 * Handler function for card view databound event
	 * @param {Object} e The databound event
	 */
	cardViewDataBound: function () {
		this.cardLoaded = true;
	},
	/**
	 * Handler for the grid Data Bound event
	 * @param {Object} e The dataBoundEvent
	 */
	onGridDataBound: function (e) {
		this.gridLoaded = true;
		$('#smtemplates_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
	},
	/**
	 * Handler function for the change(select) event on the grid
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#smtemplates_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var smtemplates = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				smtemplates.push($(checkboxes[i]).data('id'));
			}
		}
		if (smtemplates.length) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_export');
			if (smtemplates.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
			this.gridMenu.disableItem('cw_export');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_export');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
			this.gridMenu.disableItem('cw_export');
		}
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onSMTemplateDelete: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/servicemodels/delete?dc=' + Utils.timestamp();
			var checkboxes = $('.cw_grid_check');
			var i, length = checkboxes.length, haveSMTemplates = false;
			var ids = [];
			for (i = 0; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var id = $(checkboxes[i]).attr('data-id');
					ids.push(id);
					haveSMTemplates = true;
				}
			}
			if (haveSMTemplates) {
				var dialog = new Dialog({
					title: lang.INFO,
					msg: lang.account.messages.SERVICE_MODEL_TEMPLATE_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					position: topLeftCornerPosition,
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							this.gridMenu.disableItem('cw_delete');
							this.gridMenu.disableItem('cw_update');
							Utils.ajax(url, 'POST', JSON.stringify(ids), $.proxy(function (result) {
								if (result.success) {
									this.actionNotification.setOptions({
										message: lang.account.messages.SERVICE_MODEL_TEMPLATE_SUCCESS_DELETED,
										status: 'success'
									}).show();

									$('.cw_grid_check_all').prop('checked', false);
									// reload data source
									this.dataSource.read();
								} else {
									Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
								}
							}, this));
						}
					}, this),
					deleteFromGrid: this.grid
				});
				dialog.show();
			}
		}
	},
	/**
	 * Handler function for the click event on the sm template update menu item
	 * @param {Object} e The click event object
	 */
	onTemplateNameClick: function (e) {
		var templateId = $(e.currentTarget).data('id');
		var mode = 'view';
		if (State.mainApp.session.hasRole('SERVICE_MODEL_UPDATE')) {
			mode = 'update';
		}
		var modalWindow = new ModalWindow({
			title: lang.account.SERVICE_MODEL_TEMPLATE_DETAILS,
			width: 400,
			height: 440,
			minHeight: 440,
			position: topLeftCornerPosition,
			actions: [],
			url: 'include/Administration/ServiceModelTemplateForm.jsp',
			onClose: function () {
				if (mode === 'update') {
					RemoteEventsManager.discard(templateId);
				}
			},
			refresh: function () {
				 new ServiceModelForm({
					id: templateId,
					mode: mode
				});
			}
		});
		modalWindow.open();
		e.stopPropagation();
	},
	/**
	 * Handler function for the click event on the sm template update menu item
	 * @param {Object} e The click event object
	 */
	onSMTemplateUpdate: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var templateId = $('.cw_grid_check:checked').attr('data-id');
			if (State.mainApp.session.hasRole('SERVICE_MODEL_UPDATE') || State.mainApp.session.hasRole('SERVICE_MODEL_READ')) {
				var modalWindow = new ModalWindow({
					title: lang.account.SERVICE_MODEL_TEMPLATE_DETAILS,
					width: 400,
					height: 440,
					minHeight: 440,
					position: topLeftCornerPosition,
					actions: [],
					url: 'include/Administration/ServiceModelTemplateForm.jsp',
					onClose: function () {
						RemoteEventsManager.discard(templateId);
					},
					refresh: function () {
						 new ServiceModelForm({
							id: templateId,
							mode: 'update'
						});
					}
				});
				modalWindow.open();
			}
		}
	},
	/**
	 * Handler function for the click event on the sm template import menu item
	 * @param {Object} e The click event object
	 */
	onSMTemplateImport: function (e) {
		$("#cw_smtemplates_upload").click();
	},
	/*
	 * Handler function for reloading sections which are using tags
	 * */
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Handler function for the click event on the sm template export menu item
	 * @param {Object} e The click event object
	 */
	onSMTemplateExport: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/servicemodels/export';
		var checkboxes = $('#cw_smtemplates_grid').find('.cw_grid_check');
		var docs = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				docs.push($(checkboxes[i]).data('id'));
			}
		}
		Utils.ajax(url, 'POST', JSON.stringify(docs), function (result) {
			var sessionTemplateId, downloadUrl;
			if (result.success) {
				sessionTemplateId = result.data.id;
				downloadUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/download/' + sessionTemplateId;
				window.open(downloadUrl);
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		});
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'viewType',
			value: this.viewType
		}];

		var config = {
			category: 'AdministrationSMTemplates',
			preferences: preferences
		};

		if (this.viewType === 'grid') {
			config.filterPanel = this.gridFilterPanel;
			config.grid = this.grid;
			config.keys = {
				searchPhrase: 'smtemplatesSearchPhrase',
				columns: 'smtemplatesColumns',
				sort: 'smtemplatesSort',
				filter: 'smtemplatesFilter',
				FsViews: 'smtemplatesFSViews'
			};
		}

		this.saveUserPrefs(config);
	},
	createDataSource: function () {
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: function () {
						return Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/templates/servicemodels/private?dc=' + Utils.timestamp();
					},
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function (result) {
					for (let i = 0; i < result.length; i++) {
						if (result[i].tags) {
							result[i].tagsArray = result[i].tags;
							result[i].tags = result[i].tags.join(', ');
						}
					}
					return result;
				}
			},
			sort: this.smtemplatesSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.smtemplatesFilter || [],
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, pref;
		UserPrefs.load('AdministrationSMTemplates', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.viewType = UserPrefs.get('viewType') || 'grid';
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
						sort: [],
						filter: []
					};
					this.smtemplatesSort = pref.sort;
					this.smtemplatesFilter = pref.filter;
					this.smtemplatesColumns = pref.columns;
				} else {
					if (UserPrefs.get('smtemplatesSort')) {
						this.smtemplatesSort = JSON.parse(UserPrefs.get('smtemplatesSort'));
					} else {
						this.smtemplatesSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('smtemplatesFilter')) {
						this.smtemplatesFilter = JSON.parse(UserPrefs.get('smtemplatesFilter'));
					} else {
						this.smtemplatesFilter = [];
					}
				}

				this.cardGridToggle.select(this.viewType === 'grid' ? 'cw_grid_view' : 'cw_card_view');

				this.createDataSource();
				if (this.viewType === 'grid') {
					$('#cw_filter_reset').removeClass('hide');
					$('#cw_smtemplates_list').addClass('hide');
					$('#cw_smtemplates_grid').removeClass('hide');
					this.initGridView();
				} else {
					this.initCardView();
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the sm template saved event
	 */
	onSMTemplateSaved: function () {
		this.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Application.prototype.destroy.call(this);
		this.saveUserPreferences();
	}
});
