import {Clazz,
	ClazzOrModelSchema, createModelSchema, createSimpleSchema, deserialize, ModelSchema, PropDef, PropSchema, raw,
	serialize, SKIP} from "serializr";
import Context from "serializr/lib/core/Context";
import {omit} from "lodash";

export declare type PropsPartial<T = any> = {
	[propName in keyof T]?: PropDef;
};

//changing signature over a default one so it checks property names
export function createModelSchemaWrapper<T extends Object>(clazz: Clazz<T>, props: PropsPartial<T>, factory?: (context: Context) => T): ModelSchema<T>{
	return createModelSchema(clazz, props, factory);
}

//the original 'optional' tries to seriailize field and if the result is undefined then does not add it to the string.
// That causes map() to faile because it does not support null values
//Ours does not try to seriailize if source value is undefined.
export function optionalExt(propSchema: PropSchema): PropSchema{
	var {serializer, deserializer} = propSchema;

	let serializerNew = (sourcePropertyValue: any, key: string | number | symbol, sourceObject: any) => {
		if(sourcePropertyValue === undefined)
			return SKIP;

		var result = serializer(sourcePropertyValue, key, sourceObject);
		if (result === undefined) {
			return SKIP;
		}

		return result;
	}

	return Object.assign({}, propSchema, {
		serializer: serializerNew,
		deserializer: deserializer
	});
}

export function withParent(propSchema: PropSchema): PropSchema {
	const {deserializer} = propSchema;

	const deserializerNew = (jsonValue: any, callback: (err?: any, targetPropertyValue?: any | typeof SKIP) => void, context: Context, currentPropertyValue?: any) => {
		const done = (err?: any, targetPropertyValue?: any | typeof SKIP) => {
			if(!err) {
				targetPropertyValue.parent = context.target;
			}
			callback(err, targetPropertyValue)
		}
		deserializer(jsonValue, done, context, currentPropertyValue);
	};


	return Object.assign({}, propSchema, {
		deserializer: deserializerNew
	});
}



export declare type PropSerializer = (sourcePropertyValue: any, key: string | number | symbol, sourceObject: any) => any | typeof SKIP;
export declare type PropDeserializer = (jsonValue: any, callback: (err?: any, targetPropertyValue?: any | typeof SKIP) => void, context: Context, currentPropertyValue?: any) => void;


export function rawCopy(options?: {excludeFields?: string[]}){
	return {
		serializer: function (value: any) {
			return omit(value, options?.excludeFields)
		},
		deserializer: function (jsonValue: any, callback: (err?: any, targetPropertyValue?: any | typeof SKIP) => void): void {
			return void callback(null, JSON.parse(JSON.stringify(jsonValue)))
		},
	}
}

export function copyViaSerializr<T>(value: T) : T{
	if ('serializeInfo' in value.constructor) {
		//@ts-ignore
		return deserialize(value.constructor.serializeInfo as ClazzOrModelSchema<T>, serialize(value))
	} else {
		console.error('Provided instance does not have a default schema')
		throw "Provided instance does not have a default schema"
	}
}
