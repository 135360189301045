import "./deleteRecurringEventWindow.less"

import React from "react";
import {translator} from "core";
import {Button, TextBox, Grid, FormEntry, ActionButtons} from "controls/react/form";
import {Window, Section, Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import Settings from 'settings';
import RadioButtons from "controls/react/form/radioButtons";
import {AntButton} from "controls/react/ant/antButton";

let i = translator({
  "Delete recurring event": {
    "no": "Slett gjentakende aktivitet",
    "en": "Delete recurring event"
  },
  "This event only": {
    "no": "Bare denne aktivitet"
  },
  "All events": {
    "no": "Alle aktiviteter",
    "en": "All events"
  }
});

export default class DeleteRecurringEventWindow extends React.PureComponent {
	render() {
		return (
			<Window width={300}
					modal={true}
					title={i('Delete recurring event')}
					onClose={this.props.onClose}>
				<Section appearance={"none"} direction={"row"} contentPadding={true} childrenPadding={true}>
					<AntButton type={"primary"} onClick={this.props.onDeleteOccurence}>{i('This event only')}</AntButton>
					<AntButton type={"primary"} onClick={this.props.onDeleteEvent}>{i('All events')}</AntButton>
				</Section>
			</Window>
		)
	}
}

