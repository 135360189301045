import './formEntryNew.less'

import React, {CSSProperties} from 'react'
import classnames from 'classnames'
import {observer} from 'mobx-react'

import {linkModel, ValidatableModel, ValidationState} from "framework/mobx-integration"
import {AntTooltip} from "controls/react/ant/antTooltip"
import IconButton from "controls/react/form/iconButton";

let b = require('b_').with('form-entry');

const i = require('core/localization/').translator();

export interface  FormEntryProps<TModel extends object, TModelField extends keyof TModel> {
	label?: React.ReactNode
	title?: 'string'
	children: React.ReactNode
	vertical?: boolean
	width?: 'default' | 'fit' | 'half' | number

	containerClass?: string

	id?: string
	style?: CSSProperties

	model?: TModel,
	modelField?: TModelField,
	onChange?: (value: TModel[TModelField]) => void

	labelAdditionalContent?: React.ReactNode

	//if you do not want Form entry to bind the first children to a provided model but only want to show validation result.
	// This might be useful when there are several inputs inside form entry
	validationOnly?: boolean
}

type Options = {
	hasValidation?: boolean
	required?: boolean
	validationState?: ValidationState
	errors?: string[]
	children?: ReturnType<typeof React.Children.toArray>
}

export const FormEntryNew = observer(<TModel extends ValidatableModel<TModel>|object, TModelField extends keyof TModel>(props: FormEntryProps<TModel, TModelField>) => {
	const blockClasses: { [index: string]: any } = {
		vertical: props.vertical,
		horizontal: !props.vertical,
		width: Number.isInteger(props.width) ? null : props.width
	}

	const containerStyle = React.useMemo(() => {
		const result: CSSProperties = {
			...(props.style ?? {} )
		}

		if(Number.isInteger(props.width)){
			result.width = props.width + 'px'
		}

		return result
	}, [props.width, props.style])

	let options: Options = {}

	if (props.model && props.modelField) {
		if ("validator" in props.model) {
			let fieldValidator = props.model.validator.getFieldValidator(props.modelField)
			if (fieldValidator) {
				options.hasValidation = true
				options.required = fieldValidator.requiredValidationState != ValidationState.Valid
				options.validationState = fieldValidator.revalidated ? fieldValidator.validationState : ValidationState.Valid
			}
		}

		const linkingResult = linkModel(props.model, props.modelField, {validation: true})

		options.errors = linkingResult.errors

		blockClasses['has-validation'] = options.hasValidation
		blockClasses['required'] = options.required
		blockClasses["validation-state"] = options.validationState

		if (props.validationOnly !== true) {

			props.onChange && (linkingResult.onChange = props.onChange);

			delete linkingResult.invalid; //form entry takes care of validation so we dont need an input to act on validation
			delete linkingResult.errors;
			delete linkingResult.validationState

			//We are assuming there that the first child element is an input so we can pass value and onChange
			options.children = React.Children.toArray(props.children)

			if (React.isValidElement(options.children[0])) {
				options.children[0] = React.cloneElement(options.children[0], linkingResult);
			}
		}
	}

	const rootClasses = classnames(
		b(blockClasses),
		props.containerClass
	);

	const validationMessage = options.validationState != ValidationState.Valid
		? options.errors?.join()
		: ""

	return (
		<div id={props.id} className={rootClasses} style={containerStyle} title={props.title}>
			{props.label &&
				<FormEntryLabel label={props.label}>{props.labelAdditionalContent}</FormEntryLabel>
			}

			<AntTooltip className={b('field')}
			            title={validationMessage}
			            placement={"rightTop"}
			            color={"red"}
			            trigger={options.errors?.length ? 'hover' : 'none'}
			            zIndex={11000}
			>
				{options.children ?? props.children}
			</AntTooltip>
		</div>
	);
})

export type FormEntryLabelProps = {
	label: React.ReactNode
	children?: React.ReactNode
}

export const FormEntryLabel = observer((props: FormEntryLabelProps) => {
	return <label className={b('label')}>
		<span className={b('label-text')}>
			{props.label}
		</span>
		{props.children &&
			<div className={b('label-additional-content')}>
				{props.children}
			</div>
		}
	</label>
})


export type QuestionMarkProps = {
	tooltip: string
}

export const QuestionMark = observer((props: QuestionMarkProps) => {
	return <IconButton
		iconName={'question-sign'}
		title={props.tooltip}
		embedded={true}
	/>
})
