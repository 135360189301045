import './antDatePicker.less';

import React from "react";
import { DatePicker, DatePickerProps } from "antd";
import {observer} from "mobx-react";
import {RangePickerProps} from "antd/lib/date-picker";
import classnames from "classnames";
import enLocale from "antd/es/date-picker/locale/en_US"
import noLocale from "antd/es/date-picker/locale/nb_NO"

import {ApplicationState} from "framework/applicationState";

const datePickerB = require('b_').with('ant-date-picker');

type AntDatePickerProps = DatePickerProps & {}

export const AntDatePicker = observer((props: AntDatePickerProps) => {
	const className = classnames(datePickerB(), props.className);
	return <DatePicker {...props}
					   className={className}
					   locale={ApplicationState.locale == 'no' ? noLocale : enLocale}
	/>;
});

const {RangePicker} = DatePicker;

type AntRangePickerProps = RangePickerProps & {};

export const AntRangePicker = (props: AntRangePickerProps) => {
	return <RangePicker {...props}/>
}
