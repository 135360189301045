import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import State from 'tools/state';


export default function SelectAccountForm(config) {
	//initializing data inside this class
	Utils.apply(this, config);
	this.initComponent();
};

jQuery.extend(SelectAccountForm.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		$('#select_button').attr("disabled", true);
		this.firstLoad = true;
		this.getAccounts();
		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#sa_cancel').off();
		$('#select_button').off();
		$('#cw_default_account').off();
		$('#sa_filter_input').off();
		$('#sa_refresh').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		if (Cookies.CeesoftAccountId !== Cookies.CeesoftCurrentAccountId) {
			$('#cw_default_account').on('click', $.proxy(this.onSwitchToMainAccount, this));
			$('#cw_default_account').removeAttr('disabled');
		} else {
			$('#cw_default_account').attr('disabled', 'disabeld');
		}
		$('#sa_cancel').on('click', $.proxy(this.onCancel, this));
		$('#select_button').on('click', $.proxy(this.onSelect, this));
		$('#sa_filter_input').on('keyup', $.proxy(this.onFilterAccounts, this));
		$('#sa_refresh').on('click', $.proxy(this.onAccountsRefresh, this));

		$.extend($.expr[":"], {
			"containsIN": function (elem, i, match, array) {
				return (elem.textContent || elem.innerText || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
			}
		});
	},
	/**
	 * Gets the accounts to be displayed
	 */
	getAccounts: function () {
		var dataSource = State.mainApp.accountList, dataItem, treeItem;
		if (this.accountsTree) {
			this.accountsTree.dataSource.data([State.mainApp.accountList]);
		} else {
			this.accountsTree = $('#accounts_tree').kendoTreeView({
				dataSource: {
					data: [State.mainApp.accountList],
					schema: {
						model: {
							id: 'id',
							hasChildren: true,
							children: 'items',
							text: 'name'
						}
					}
				},
				//dataTextField: 'name',
				loadOnDemand: false,
				template: '<span data-id="${item.id}">${item.name}</span>',
				select: function (e) {
					State.mainApp.closePopups(e, true);
					$('#select_button').attr('disabled', false);
				},
				dataBound: $.proxy(function (e) {
					if (this.firstLoad) {
						if (!e.node) {
							this.firstLoad = false;
							var accountsTree = $('#accounts_tree').data('kendoTreeView');
							var selectedAccountItem = accountsTree.dataSource.get(Cookies.CeesoftCurrentAccountId);
							var selectedAccountUid = selectedAccountItem.uid;
							var selectedAccountTreeItem = accountsTree.findByUid(selectedAccountUid);
							var dataItem = accountsTree.dataItem(selectedAccountTreeItem);
							this.treeItem = selectedAccountTreeItem;
							accountsTree.expandTo(dataItem.id);
							accountsTree.expand(selectedAccountTreeItem);
						}
					}
				}, this),
				expand: $.proxy(function () {
					if (this.treeItem) {
						var accountsTree = $('#accounts_tree').data('kendoTreeView');
						accountsTree.select(this.treeItem);
						this.treeItem = null;
					}
				}, this)
			}).data('kendoTreeView');
		}
	},
	/**
	 * Handler for the filter input
	 * @param {Object} e The key press event object
	 */
	onFilterAccounts: function (e) {
		var filterText = $(e.currentTarget).val(), treeview = $("#accounts_tree").data("kendoTreeView"), clone;

		if (filterText !== "") {
			$("#accounts_tree .k-group .k-group .k-in").closest("li").hide();
			$("#accounts_tree .k-group .k-group .k-in:containsIN(" + filterText + ")").each(function () {
				$(this).parents("ul, li").each(function () {
					$(this).show();
				});
			});
		} else {
			$("#accounts_tree .k-group").find("ul").show();
			$("#accounts_tree .k-group").find("li").show();
		}
	},
	/**
	 * Handler for the refresh button
	 * @param {Object} e The click event object
	 */
	onAccountsRefresh: function (e) {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftAccountId + '/picker';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				State.mainApp.accountList = result.data;
				this.getAccounts();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler for the Switch to Main Account button
	 * @param {Object} e The click event object
	 */
	onSwitchToMainAccount: function (e) {
		e.stopPropagation();
		if (Cookies.CeesoftAccountId !== Cookies.CeesoftCurrentAccountId) {
			Cookies.create('CeesoftCurrentAccountId', Cookies.CeesoftAccountId, Settings.COOKIE_TIMEOUT);
			Cookies.create('CeesoftCurrentAccountName', Cookies.CeesoftAccountName, Settings.COOKIE_TIMEOUT);
			Cookies.erase("CeesoftParentAccountId");
			Cookies.erase("CeesoftParentAccountName");
			$(".current_account").html('<span></span>' + Cookies.CeesoftAccountName); // TODO: Check if this still works or is needed
			$('#sa_cancel').trigger('click');
			Utils.redirectTo(Settings.httpRoot);
		}
	},
	/**
	 * Handler for the Cancel button
	 * @param {Object} e The click event object
	 */
	onCancel: function (e) {
		e.stopPropagation();
		$('.current_account').removeClass('active');
		$('.cw_account_drop').removeClass('is_open');
	},
	/**
	 * Handler for the Select button
	 * @param {Object} e The click event object
	 */
	onSelect: function (e) {
		e.stopPropagation();
		const treeView = $("#accounts_tree").data("kendoTreeView");
		const selectedNode = treeView.select();
		const dataItem = treeView.dataItem(selectedNode);
		const selectedAccountId = dataItem.id;
		const selectedAccountName = dataItem.name;
		const isChild = !!dataItem.parentNode();
		const timeout = Settings.COOKIE_TIMEOUT;

		if (isChild) {
			Cookies.create('CeesoftParentAccountId', Cookies.CeesoftCurrentAccountId, timeout);
			Cookies.create('CeesoftParentAccountName', Cookies.CeesoftCurrentAccountName, timeout);
		} else {
			if (selectedAccountId === Cookies.CeesoftParentAccountId) {
				Cookies.create('CeesoftParentAccountId', '', timeout);
				Cookies.create('CeesoftParentAccountName', '', timeout);
			}
		}
		Cookies.create('CeesoftCurrentAccountId', selectedAccountId, timeout);
		Cookies.create('CeesoftCurrentAccountName', selectedAccountName, timeout);
		$(".current_account").html('<span></span>' + Cookies.CeesoftCurrentAccountName);
		$('#sa_cancel').trigger('click');
		Utils.redirectTo(Settings.httpRoot);
	}
});
