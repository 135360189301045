import React from 'react';
import ReactDOM from "react-dom";
import {Application, Cookies, ErrorHandler, LocalEventsManager, RemoteEventsManager, StaticVars} from "core";
import {Settings} from "settings";
import {CustomNotification, Dialog, GridMenu, ImageUploader, MultiToggle, Switch} from "controls";
import {CeeViewDataSource} from "tools";
import {Api, State, Utils} from "tools";
import moment from "moment";
import {UserNotificationPolicy} from "areas/administration/notifications/policy";
import {translator} from "core";
import {AntSelect} from "controls/react/ant/antSelect";
import {TagsSelect} from "controls/react/tagsSelect";
import FormEntry from "controls/react/form/formEntry";
import {UserRouter} from "./bundleDescription";
import ActionTriggerSwitcher from './actionTriggerSwitcher';
import {NavigationAction} from "framework/navigationStore"
import {DashboardsRouter} from "areas/dashboards/bundleDescription";
import {NavigationStore} from "framework/navigationStore";
import {AdministrationRouter} from "areas/administration/bundleDescription";
import {SummaryRouter} from "../../summary/bundleDescription";

const t = new translator({
  "You are not allowed to change this setting for yourself": {
    "no": "Du har ikke lov til å endre denne innstillingen for din bruker",
    "en": "You are not allowed to change this setting for your user"
  },
  "Name exists": {
    "en": "An identical name exist on this account, please use another name.",
    "no": "Et identisk navn finnes på denne kontoen, bruk et annet navn."
  }
});

const invalidatePasswordField = (fieldSelector) => () => {
	setInvalidField(fieldSelector, 'required_password')
}

const invalidateField = (fieldSelector) => () => {
	setInvalidField(fieldSelector, 'required_textbox');
}

let setInvalidField = function (selector, className) {
	const field = document.querySelector(selector);
	if (field.value?.trim() === '') {
		field.parentElement.closest('.cw_field').classList.add(className);
	} else {
		field.parentElement.closest('.cw_field').classList.remove(className);
	}
}

let UserDetails = function (config) {
	Utils.apply(this, config);
	this.recentlyUsedIcons = [];
	this.userPref = [];
	this.module = {
		name: 'UserDetails',
		initialConfig: config
	};
	this.needReload = false;
	this.isUserProfile = this.id === Cookies.CeesoftUserId;

	this.accountContext = this.isUserProfile ? Cookies.CeesoftAccountId : Cookies.CeesoftCurrentAccountId;

	if (this.id === '00000000-0000-0000-0000-000000000001') {
		this.isAdmin = true;
	}

	this.langLocale = lang.momentsLangCode;
	moment.locale(this.langLocale);
	this.initComponent();
};

export default UserDetails;

jQuery.extend(UserDetails.prototype, Application.prototype, {
	initComponent: async function () {
		this.update = true;
		if (this.mode === 'view') {
			this.update = false;
		}

		if(!this.id) {
			this.onCreateMode();
			$('#save_user').text(t('Create'));
		}

		if (this.isUserProfile) {
			$('#cw_force_team_tags').attr('disabled', 'disabled');
			$('#cw_force_team_container').attr('title', t('You are not allowed to change this setting for yourself'));
		}

		Application.prototype.initComponent.call(this);

		this.isEditWindowOpen = false;
		this.imageId = '';
		this.teamsLoaded = false;
		this.weekdays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
		this.isMiniButtonVisible = false;
		this.isSchedulerEnabled = true;
		this.markInboxAsRead = false;
		this.cachedTabs = {
			schedule: false,
			policy: false
		};

		if (this.id) {
			var timeout = Settings.COOKIE_TIMEOUT;
			Cookies.create("CeesoftViewUserId", this.id, timeout);
		}

		this.dateTimeFormat = Cookies.CeesoftUserDateTimeFormat;
		this.dateFormat = Cookies.CeesoftUserDateFormat;
		this.timeFormat = Cookies.CeesoftUserTimeFormat;

		// set listeners for live controls
		Utils.onLiveChange('#first_name', '#card_firstname', lang.FIRST_NAME);
		Utils.onLiveChange('#last_name', '#card_lastname', lang.LAST_NAME);
		Utils.onLiveChange('#email', '#card_email', lang.EMAIL);
		Utils.onLiveChange('#username', '#card_username', lang.USERNAME);

		$('#cw_change, #cw_password_reset').addClass('hide');

		if (this.mode === 'update' || this.mode === 'view') {
			this.getSchedulerPeriods();
		} else {
			var input = $('#cw_change_password');
			input.prop('checked', false);
			input.parent().removeClass('cw_checked');
			$('#cw_password_reset').removeClass('hide');
			$('#cw_autogenerate_password').parent().removeClass('is_disabled');
			$('#cw_autogenerate_password').removeAttr('disabled');
		}

		if (!this.isAdmin) {
			this.getUserCategories();
		} else {
			$('#user_category').closest('.cw_field').addClass('hide');
		}
		this.getAccounts();
		this.getServiceBoards();
		this.getUserLanguages();
		this.getUserTimezones();
		this.getDateFormats();
		this.removeListeners();
		this.attachListeners();

		if (Cookies.CeesoftUserId !== this.id) {
			this.disableUser = new Switch({
				renderTo: $('#cw_lock_user'),
				isEnabled: true,
				change: $.proxy(this.onDisableUserChange, this)
			});
			if (!this.id) {
				this.disableUser.setValue(true);
				this.disableUser.enable(false);
				$('#cw_change').removeClass('hide');
			}
		}

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			style: 'top:50px;left:10px;right:10px',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		this.tabStrip = $('#cw_profile_notifications').kendoTabStrip({
			animation: false,
			activate: $.proxy(this.onTabActivate, this),

		}).data('kendoTabStrip');

		let initialTab = $('.cw_profile_tab');
		if(this.selectedTab) {
			initialTab = $(`[data-tab=${this.selectedTab}]`);
		}

		this.tabStrip.activateTab(initialTab);

		this.removeMask();
		if (this.mode === 'view') {
			$('.cw_mask').removeClass('hide');
			$('#save_user').addClass('hide');
		}

		const actionTrigger = $('<div id="user_details__action_trigger" class="cw_field"></div>')
		$('#cw_phone_container').after(actionTrigger);

		if(this.id) {
			this.load();
		}

		this.actionTriggerNode = actionTrigger[0];

		ReactDOM.render(<ActionTriggerSwitcher type={"users"} id={this.id} />, actionTrigger[0]);
		this.checkRequiredFields(this, true);
	},
	/**
	 * Handler function for tab activating
	 * @param {Object} e The activate event
	 */
	onTabActivate: function (e) {
		var tab = $(e.item).data('tab');
		switch (tab) {
			case 'rules':
				this.destroy();
				$('#content_area').empty();
				State.mainApp.navigate(UserRouter.ruleset(this.id), {
					navigationAction: NavigationAction.PopOne
				});
				return;
		}
	},
	/*
	 * Handler function for init the schedule tab
	 * */
	initScheduleTab: function () {

		var periods = this.getPeriods(this.schedulerData);
		this.createScheduler(periods);
		this.renderSeverityThresholdPreview();
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#cancel').off();
		$('.cw_avatar_user').find('.close_minibtn').off();
		$('.cw_media_library').off();
		$('.cw_checkbox').off();
		$('#save_user').off();
		$('#save_avatar').off();
		$('#cw_filters_grid').off();
		LocalEventsManager.unbind('loadRecentIcons');
	},
	/**
	 * Attaches the listeners
	 */
	attachListeners: function () {
		$('#save_user').on('click', $.proxy(this.onSaveButton, this));
		$('#cancel').on('click', $.proxy(this.onDetailsCancel, this));
		$('.cw_avatar_user').find('.close_minibtn').on('click', $.proxy(this.onRemovePicture, this));
		$('.cw_media_library').on('click', $.proxy(this.onMediaLibraryButton, this));
		$('.cw_checkbox').on('click', $.proxy(this.onCheckboxClick, this));
		$('.cw_content_section').on('keyup', '#username', this.onUsernameKeyUp);
		$('.cw_content_section').on('keyup', '#password', this.onPasswordKeyUp);
		$('.cw_content_section').on('keyup', '#password_confirm', this.onPasswordConfirmKeyUp);
		$('.cw_content_section').on('keyup', '#first_name', this.onFirstNameKeyUp);
		$('.cw_content_section').on('keyup', '#last_name', this.onLastNameKeyUp);
		$('.cw_content_section').on('keyup', '#email', this.onEmailKeyUp);
		$('.cw_content_section').on('keyup', () => this.checkRequiredFields(this, false));

		LocalEventsManager.bind('loadRecentIcons', $.proxy(this.loadRecentIcons, this));
		$(window).off().on('resize', $.proxy(this.adjustFiltersSize, this));
	},
	onUsernameKeyUp: invalidateField("#username"),
	onPasswordKeyUp: invalidatePasswordField('#password'),
	onPasswordConfirmKeyUp: invalidatePasswordField('#password_confirm'),
	onFirstNameKeyUp: invalidateField("#first_name"),
	onLastNameKeyUp: invalidateField("#last_name"),
	onEmailKeyUp: invalidateField("#email"),
	/**
	 * Generates a random 6 characters password
	 * @return {String} password The generated password string
	 */
	randomPassword: function () {
		var i, n, length = 6, charset = 'abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=',
			password = '';
		for (i = 0, n = charset.length; i < length; ++i) {
			password += charset.charAt(Math.floor(Math.random() * n));
		}
		return password;
	},
	checkRequiredFields: function (scope, firstEnter) {
		let username, firstName, lastName, email, password, confirmPassword;
		if(scope.id) {
			if(firstEnter) {
				firstName = scope?.userDetails?.firstName;
				lastName = scope?.userDetails?.lastName;
				email = scope?.userDetails?.email;
			}
			else {
				firstName = $('#first_name').val().trim();
				lastName = $('#last_name').val().trim();
				email = $('#email').val().trim();
			}
			if(username !== '' && lastName !== '' && firstName !== '' && email !== '') {
				$('#save_user').attr('disabled', false);
			}
			else {
				$('#save_user').attr('disabled', true);
			}
		}
		else {
			username = $('#username').val().trim();
			firstName = $('#first_name').val().trim();
			lastName = $('#last_name').val().trim();
			email = $('#email').val().trim();
			password = $('#password').val().trim();
			confirmPassword = $('#password_confirm').val().trim();
			if(username !== '' && lastName !== '' && firstName !== '' && email !== '' && password !== ''&& confirmPassword !== '') {
				$('#save_user').attr('disabled', false);
			}
			else {
				$('#save_user').attr('disabled', true);
			}
		}
	},
	onCreateMode: function () {
		$('.cw_notification_rules_tab').addClass('hide');
		$('#cw_current_pass').addClass('hide');
		$('#cw_auth_type').parent().addClass('hide');
		$('#username').attr('disabled', false);
		this.populateTeamsList();
		this.onUsernameKeyUp();
		this.onFirstNameKeyUp();
		this.onLastNameKeyUp();
		this.onEmailKeyUp();
		this.onPasswordKeyUp();
		this.onPasswordConfirmKeyUp();
	},
	onDisableUserChange: function (data) {
		if (data.value) {
			$('#cw_change, #cw_password_reset').removeClass('hide');
		} else {
			$('#cw_change, #cw_password_reset').addClass('hide');
		}
	},
	/**
	 * Handler function for the click event on the checkboxes
	 * @param {Object} e The click event object
	 */
	onCheckboxClick: function (e) {
		var input = $(e.currentTarget).find('input');
		if (!$(e.currentTarget).hasClass('is_disabled')) {
			input.prop('checked', !input.is(':checked'));
			$(e.currentTarget).toggleClass('cw_checked');
		}
		if ($(input).is('#cw_autogenerate_password')) {
			if ($(input).is(':checked')) {
				var password = this.randomPassword();
				$('#password').val(password);
				$('#password_confirm').val(password);
			} else {
				$('#password').val('');
				$('#password_confirm').val('');
			}
		}
	},
	/**
	 * Launches Media Library.
	 */
	onMediaLibraryButton: function () {
		if ($('.cw_media_library_content').hasClass('hide')) {
			if (!this.uploaderImagesControl) {
				this.renderUploaderImagesControl();
				//TODO: not sure if this is still relevant because the way of getting image is changed
				// if (this.pictureId) {
				// 	this.uploaderImagesControl.setSelectedImageId(this.pictureId);
				// }
			}
			$('.form_content_minimal').show();
			$('.cw_media_library_content').removeClass('hide');
			$('.cw_media_library').addClass('hide');
		}
	},
	/**
	 * Renders the uploader images control.
	 */
	renderUploaderImagesControl: function () {
		this.uploaderImagesControl = new ImageUploader({
			id: 'user_glyph_icons',
			livePreviewSelector: '#cw_user_card_avatar',
			width: '100%',
			height: '344px',
		});
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		$('#username').removeClass('invalid');
		$('#password').removeClass('invalid');
		$('#password_confirm').removeClass('invalid');
		$('#current_pass').removeClass('invalid');
		$('#first_name').removeClass('invalid');
		$('#last_name').removeClass('invalid');
		$('#email').removeClass('invalid');
		$('#user_language').prev().removeClass('invalid');
		$('#user_category').prev().removeClass('invalid');
		$('#user_timezone').prev().removeClass('invalid');
	},
	/**
	 * Defines and retrives the user category list
	 */
	getUserCategories: function () {
		this.categoryDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + this.accountContext + '/usercategories/lite/',
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			sort: [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			error: ErrorHandler.kendoServerError
		});
		this.categoryDataSource.read();
		$('#user_category').kendoDropDownList({
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: this.mode === 'update',
			text: (this.mode === 'update') ? undefined : lang.account.USER_SELECT_CATEGORY,
			dataSource: this.categoryDataSource
		});
	},
	/*
	 * Handler function for getting accounts
	 * */
	getAccounts: function () {
		let url = Settings.serverPath + 'accounts/' + this.accountContext + '/subaccounts/firstLevel/?ignoreTags=true';

		this.loginAccountList = $('.cw_user_summary #user_login_account').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: url,
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: true,
			value: this.accountContext,
			text: (this.mode === 'update') ? undefined : lang.account.USER_SELECT_ACCOUNT
		}).data('kendoDropDownList');
	},
	/*
	* Handler function for getting service boards
	* */
	getServiceBoards: function () {
		const homeView = 'HOME_VIEW';

		var url = Settings.serverPath + 'accounts/' + this.accountContext + '/dashboards/lite?includeSubaccounts=true';
		this.serviceBoardsList = $('#user_serviceboard').kendoMultiSelect({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: url,
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: $.proxy(function (response) {
						this.sbLayouts = [];
						for (var i = 0; i < response.length; i++) {
							if (response[i].accountId !== this.accountContext) {
								response[i].tag = response[i].tag + ' (' + response[i].accountName + ')';
							}
							response[i].type = 'DB';
							this.sbLayouts.push({
								id: response[i].id,
								layout: response[i].layout
							});
						}
						response.unshift({
							id: homeView,
							tag: lang.profile.DEFAULT_SERVICEBOARD,
							type: 'HOME'
						}, {
							id: null,
							tag: lang.profile.SERVICEBOARDS,
							type: 'DB',
							unselectable: true
						});
						return response;
					}, this)
				},
				sort: [{
					field: 'tag',
					dir: 'asc'
				}],
				group: {
					field: 'type',
					dir: 'desc'
				}
			}),
			dataTextField: 'tag',
			dataValueField: 'id',
			autoBind: true,
			select: function(e) {
				if (e.dataItem.unselectable) {
					e.preventDefault();
				}
			},
			open: $.proxy(function () {
				//overwrite kendo groupable questionable functionality (show different first group; moreover, show groupable row when no data)
				var recipientsOpenedContainer = $('#user_serviceboard-list');
				var recipientsGroupHeader = recipientsOpenedContainer.find('.k-group-header');
				var recipientsNoDataContainer = recipientsOpenedContainer.find('.k-nodata');
				if (recipientsNoDataContainer.attr('style')) {//check for style display:none property put by kendo
					//case when multiselect has data; transform the grouping header row into a normal grouping element
					recipientsGroupHeader.addClass('hide');
					var firstItem = recipientsOpenedContainer.find('li').first();
					firstItem.append('<div class="k-group">' + recipientsGroupHeader.html() + '</div>');
				} else {
					//case when multiselect has no data; hide the grouping header, fix for poor kendo functionality
					recipientsGroupHeader.addClass('hide');
				}
			}, this)
		}).data('kendoMultiSelect');

		if (this.mode === 'create') {
			this.serviceBoardsList.value(homeView);
		}
	},
	/**
	 * Defines and retrives the languages list
	 */
	getUserLanguages: function () {
		$('#user_language').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/locales/',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				}
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			autoBind: true,
			value: (this.mode === 'update') ? undefined : Cookies.CeesoftUserLocale
		});
	},
	/**
	 * Defines and retrives the timezoen list
	 */
	getUserTimezones: function () {
		$('#user_timezone').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				}
			}),
			autoBind: true,
			value: (this.mode === 'create') ? Cookies.CeesoftTimezone : undefined
		});
	},
	/**
	 * Defines and retrives the date format lists
	 */
	getDateFormats: function () {
		$('#datetime_format').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/dateTimeFormats',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: function (response) {
						var now = (new Date()).getTime(),
							dates = [], i;
						for (i = 0; i < response.length; i++) {
							var date = {
								format: response[i],
								text: Renderer.browserDateRenderer(now, "datetime", response[i])
							};
							dates.push(date);
						}
						return dates;
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'format',
			autoBind: true
		});

		$('#date_format').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/dateFormats',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: function (response) {
						var now = (new Date()).getTime(),
							dates = [], i;
						for (i = 0; i < response.length; i++) {
							var date = {
								format: response[i],
								text: Renderer.browserDateRenderer(now, StaticVars.DATE, response[i])
							};
							dates.push(date);
						}
						return dates;
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'format'
		});

		$('#time_format').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timeFormats',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: function (response) {
						var now = (new Date()).getTime(),
							dates = [], i;
						for (i = 0; i < response.length; i++) {
							var date = {
								format: response[i],
								text: Renderer.browserDateRenderer(now, "time", response[i])
							};
							dates.push(date);
						}
						return dates;
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'format'
		});
	},
	/**
	 * Defines and retrieves the teams list
	 */
	populateTeamsList: async function () {
		const url = Settings.serverPath + 'accounts/' + this.accountContext + '/teams/lite';
		const result = await Api.fetch(url);
		this.teamsList = result;

		this.antSelectorNode = $('#user_team').parent().get()[0];

		ReactDOM.render(<FormEntry>
			<AntSelect
				dataList={this.teamsList}
				mode={"multiple"}
				sortValues={true}
				onChange={value => this.onTeamsChange(value)}
				defaultValue={this.teams}
				placeholder={lang.TEAMS} />
		</FormEntry>, this.antSelectorNode);

		$('.cw_accounts_container').find('.ant-select-selection').css({height: '60px'});
	},
	onTeamsChange(value) {
		this.teams = value || [];
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: function () {
		var loadUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/users/' + this.id + '/?update=' + this.update,
			oThis = this;
		$('#save_user').text(lang.UPDATE);
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(async function (result) {
			if (result.success) {
				let rolesDropwDownEnabled = true;
				if (!this.isAdmin && this.isUserProfile) {
					rolesDropwDownEnabled = false;
				}
				if (!this.isAdmin) {
					var userCategoryField = $("#user_category").data("kendoDropDownList");
					userCategoryField.value(result.data.userCategoryId);
					userCategoryField.enable(rolesDropwDownEnabled);
				}
				this.userDetails = result.data;
				this.loginAccountList.value(result.data.loginAccountId || this.accountContext);
				this.serviceBoardsList.value(result.data.landingPages);
				this.teams = result.data.teams;
				this.teamsLoaded = true;
				await this.populateTeamsList();

				$("#user_language").data("kendoDropDownList").value(result.data.locale);
				$("#user_timezone").data("kendoDropDownList").value(result.data.timeZone);
				$('#first_name').val(result.data.firstName || '');
				$('#last_name').val(result.data.lastName || '');
				$('#email').val(result.data.email || '');
				$('#username').val(result.data.username);
				$('#twitter').val(result.data.twitterAccountName || '');
				$('#mobile').val(result.data.phoneNumber || '');
				$('#card_firstname').text(result.data.firstName || '');
				$('#card_lastname').text(result.data.lastName || '');
				$('#card_email').text(result.data.email || '');
				$('#card_username').text(result.data.username);
				$('#password').val('');
				$('#password_confirm').val('');
				$('#datetime_format').data('kendoDropDownList').value(result.data.dateTimeFormat || this.dateTimeFormat);
				$('#date_format').data('kendoDropDownList').value(result.data.dateFormat || this.dateFormat);
				$('#time_format').data('kendoDropDownList').value(result.data.timeFormat || this.timeFormat);
				if (result.data.authType) {
					$('#auth_type').val(result.data.authType);
				}
				if (result.data.id) {
					$('#cw_cvid').val(result.data.id);
				}

				if (result.data.authType === 'LDAP') {
					$('#cw_uf_password_form').find('#password').prop('readonly', 'readonly');
					$('#cw_uf_password_form').find('#password_confirm').prop('readonly', 'readonly');
					$('#cw_autogenerate_password').parent().addClass('is_disabled');
					$('#cw_change_password').parent().addClass('is_disabled');
					var helpText = '<span class="cw_info">* ' + lang.messages.USER_HELPTEXT + '</span>';
					$('.cw_password_panel').append(helpText);
				}

				if (result.data.lockUser === true) {
					$('#cw_change_password').prop('checked', false);
					$('#cw_change_password').attr('disabled', 'disabled');
					$('#cw_change_password').parent().addClass('is_disabled');
				} else {
					$('#cw_change_password').prop('checked', result.data.changePassword);
					if (result.data.changePassword) {
						$('#cw_change_password').parent().addClass('cw_checked');
					} else {
						$('#cw_change_password').parent().removeClass('cw_checked');
					}
				}

				$('#cw_force_team_tags').prop('checked', result.data.forceTeamTags);

				if (this.id === Cookies.CeesoftUserId) {
					$('#cw_change, #cw_password_reset').removeClass('hide');
					$('#cw_change').addClass('hide');
					$('.cw_disable_user').addClass('hide');
				} else {
					$('#cw_current_pass').addClass('hide');
					this.disableUser.setValue(!result.data.lockUser).trigger('change');
					$('.cw_disable_user').removeClass('hide');
				}
				this.selected = false;
				this.pictureId = result.data.pictureId;
				this.iconPack = result.data.iconPack;
				var imagePreviewDiv = $('#cw_user_card_avatar .cw_avatar_user');
				if (Utils.isGuid(this.pictureId)) {
					this.isMiniButtonVisible = true;
					imagePreviewDiv.addClass('no_bg');
					imagePreviewDiv.prepend('<img src="' + Api.users.urls.avatar(oThis.id, this.pictureId, this.accountContext) + '" />');
				} else if (this.pictureId) {
					this.isMiniButtonVisible = true;
					imagePreviewDiv.addClass('no_bg');
					if (this.iconPack === 'glyph') {
						imagePreviewDiv.prepend('<span class="glyphicons ' + this.pictureId + '">');
					} else {
						imagePreviewDiv.prepend('<i class="material-icons">' + this.pictureId + '</i>');
					}
				}
				//imagePreviewDiv.find('.close_minibtn').removeClass('hide');
				//TODO: not sure if this is still relevant because the way of getting image is changed
				// if (this.uploaderImagesControl && this.pictureId) {
				// 	this.uploaderImagesControl.setSelectedImageId(this.pictureId);
				// }
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/*
	 * Handler function for updating the cookies
	 * */
	updateCookies: function () {
		var timeout = Settings.COOKIE_TIMEOUT;
		Cookies.create("CeesoftTimezone", this.timeZone, timeout);
		Cookies.create("CeesoftUserLocale", this.locale, timeout);
		Cookies.erase("CeesoftUserDateTimeFormat");
		Cookies.erase("CeesoftUserDateFormat");
		Cookies.erase("CeesoftUserTimeFormat");
		Cookies.erase("CeesoftUserPictureId");
		Cookies.create("CeesoftUserDateTimeFormat", this.dateTimeFormat, timeout);
		Cookies.create("CeesoftUserDateFormat", this.dateFormat, timeout);
		Cookies.create("CeesoftUserTimeFormat", this.timeFormat, timeout);
		Cookies.create("CeesoftUserPictureId", this.pictureId, timeout);
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		var successText;
		if (result.success) {
			var timeout = Settings.COOKIE_TIMEOUT;
			var email = $('#email').val();
			if (email) {
				Cookies.erase("CeesoftUserEmail");
				Cookies.create("CeesoftUserEmail", email, timeout);
			}
			$('#current_pass').val('');
			$('#password').val('');
			$('#password_confirm').val('');
			this.pictureId = result.data.pictureId;
			this.iconPack = result.data.iconPack;
			if (this.id === Cookies.CeesoftUserId) {
				this.updateCookies();
				State.mainApp.setUserIcon();
			}
			if (!Utils.isGuid(result.data.pictureId)) {
				for (var i = 0; i < this.recentlyUsedIcons.length; i++) {
					if (this.pictureId === this.recentlyUsedIcons[i].id) {
						this.recentlyUsedIcons.splice(i, 1);
					}
				}
				this.recentlyUsedIcons.unshift({
					id: this.pictureId,
					iconPack: this.iconPack
				});
				if (this.recentlyUsedIcons.length === 9) {
					this.recentlyUsedIcons.pop();
				}
				var preferences = [{
					key: 'recentIcons',
					value: JSON.stringify(this.recentlyUsedIcons)
				}];
				this.saveUserPrefs({
					category: 'RecentlyUsedIcons',
					preferences: preferences
				});
			}

			if (this.isFromUsersGrid) {
				NavigationStore.go(AdministrationRouter.listUsers());
			} else if (this.isFromManagementUsersGrid) {
				State.mainApp.loadModule('ManagementUsers');
			} else {
				let breadcrumbLinkItems = $('a[navigation-action="' + NavigationAction.RewindToTheSame + '"]');
				let lastBreadcrumbLinkItem = breadcrumbLinkItems[breadcrumbLinkItems.length - 1];
				if (lastBreadcrumbLinkItem) {
					lastBreadcrumbLinkItem.click();
				} else  {
					State.mainApp.navigate('#/');
				}
			}
			if (this.needReload) {
				window.location.reload();
			}

		} else {
			this.statusNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},

	/**
	 * Handler function for the click event on image remove button
	 */
	onRemovePicture: function (e) {
		var userModal, id, previewDiv;
		if (this.uploaderImagesControl.getSelectedImage().id) {
			//TODO: not sure if this is still relevant because the way of getting image is changed
			//this.uploaderImagesControl.setSelectedImageId('');
			userModal = $(e.target).parents().eq(6);
			id = userModal.find('.userId').attr('id');
			// remove on modal
			previewDiv = userModal.find('.cw_avatar_user');
			previewDiv.find('.close_minibtn').addClass('hide');
			previewDiv.removeClass('no_bg');
			previewDiv.find('img, span, i').remove();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event
	 */
	onSaveButton: async function (e) {
		let name = $('#username').val().trim();
		if(name !== '') {
			name = name.toUpperCase();
			let searchUrl = Api.accountRoot() + 'users/lite?name=';
			let nameExists = await Utils.checkIfNameExists(searchUrl, name, this.statusNotification, true, this.userDetails?.username);
			if(nameExists) {
				return;
			}
		}
		//profile
		this.clearInvalid();
		var valid, re, urlCreate, urlUpdate, url, validation, lockUser, message,
			firstName = $('#first_name').val().trim(),
			lastName = $('#last_name').val().trim(),
			email = $('#email').val().trim(),
			teams = this.teams,
			twitterAccountName = $('#twitter').val(),
			phoneNumber = $('#mobile').val().trim(),
			userLanguageField = $('#user_language').data('kendoDropDownList'),
			userTimezoneField = $('#user_timezone').data('kendoDropDownList'),
			username = $('#username').val().trim(),
			changePassword = $('#cw_change_password').is(':checked') ? true : false;

		if (!this.isAdmin) {
			var userCategoryField = $('#user_category').data('kendoDropDownList');
			var userCategoryId = userCategoryField.value();
		}

		this.timeZone = userTimezoneField.value();
		this.locale = userLanguageField.value();
		this.needReload = Cookies.CeesoftUserLocale != this.locale;
		this.dateTimeFormat = $('#datetime_format').data('kendoDropDownList').value();
		this.dateFormat = $('#date_format').data('kendoDropDownList').value();
		this.timeFormat = $('#time_format').data('kendoDropDownList').value();

		this.data = {
			id: (this.id === 'new_user' ? null : this.id),
			username: username,
			locale: this.locale,
			timeZone: this.timeZone,
			dateTimeFormat: this.dateTimeFormat,
			dateFormat: this.dateFormat,
			timeFormat: this.timeFormat,
			firstName: firstName,
			lastName: lastName,
			email: email,
			twitterAccountName: twitterAccountName,
			phoneNumber: phoneNumber,
			teams: teams || [],
			loginAccountId: this.loginAccountList.value() || this.accountContext,
			landingPages: this.serviceBoardsList.value()
		};

		this.data.forceTeamTags = $('#cw_force_team_tags').is(':checked');

		var oldPassword = $('#current_pass').val();
		var password = $('#password').val();
		var passwordConfirm = $('#password_confirm').val();

		if (this.id === Cookies.CeesoftUserId) {
			this.data.oldPassword = oldPassword;
			this.data.lockUser = null;
		} else {
			this.data.lockUser = !this.disableUser.getValue();
			this.data.changePassword = changePassword;
		}

		this.data.password = password;

		if (!this.isAdmin) {
			this.data.userCategoryId = userCategoryId;
		}

		if (this.uploaderImagesControl) {
			this.data.pictureId = this.uploaderImagesControl.getSelectedImage().id || '';
			this.data.iconPack = this.uploaderImagesControl.getSelectedImage().iconPack || '';
		} else {
			this.data.pictureId = this.pictureId;
			this.data.iconPack = this.iconPack;
		}

		validation = this.validate();
		if (validation && !validation.isValid) {
			this.statusNotification.setOptions({
				message: validation.message,
				status: 'error'
			}).show();
		} else {
			urlCreate = Settings.serverPath + 'accounts/' + this.accountContext + '/users/createUser';

			if (this.id === Cookies.CeesoftUserId) {
				urlUpdate = Settings.serverPath + 'accounts/' + this.accountContext + '/users/updateProfile';
			} else {
				urlUpdate = Settings.serverPath + 'accounts/' + this.accountContext + '/users/updateUser';
			}
			url = (this.mode === 'create' ? urlCreate : urlUpdate);
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * Validation function for the
	 * @return {Object} validation
	 */
	validate: function () {
		this.clearInvalid();

		var userCategoryField, userCategoryId = '';
		if (!this.isAdmin) {
			userCategoryField = $("#user_category").data("kendoDropDownList");
			userCategoryId = userCategoryField.value();
		}

		var createButton = $('#save_user'),
			firstName = $('#first_name').val(),
			lastName = $('#last_name').val(),
			email = $('#email').val(),
			userLanguageField = $("#user_language").data("kendoDropDownList"),
			userTimezoneField = $("#user_timezone").data("kendoDropDownList"),
			username = $('#username').val(),
			pass = $('#password').val(),
			timezone = userTimezoneField.value(),
			locale = userLanguageField.value(),
			isValid = true,
			message,
			validation,
			re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (firstName.trim() === '') {
			isValid = false;
			message = lang.account.messages.USER_INPUT_FIRST_NAME;
			$('#first_name').addClass('invalid');
		}
		if (lastName.trim() === '') {
			isValid = false;
			message = lang.account.messages.USER_INPUT_LAST_NAME;
			$('#last_name').addClass('invalid');
		}
		if (!re.test(email)) {
			isValid = false;
			message = lang.account.messages.USER_INVALID_EMAIL;
			$('#email').addClass('invalid');
		}
		if (username.trim() === '') {
			isValid = false;
			message = lang.account.messages.USER_INPUT_USERNAME;
			$('#username').addClass('invalid');
		}

		var oldPassword = $('#current_pass').val();
		var password = $('#password').val();
		var passwordConfirm = $('#password_confirm').val();

		if (oldPassword || password || passwordConfirm) {
			if (password.length < 6 && password.length !== 0) {
				isValid = false;
				message = lang.account.messages.USER_PASSWORD_LENGTH;
				$('#cw_user_pass').find('#password').addClass('invalid');
			}
			if (password.length === 0 && this.mode === 'create') {
				isValid = false;
				message = lang.account.messages.USER_PASSWORD_LENGTH;
				$('#cw_user_pass').find('#password').addClass('invalid');
			}
			if (password === username) {
				isValid = false;
				message = lang.account.messages.USER_PASSWORD_DIFF_USERNAME;
				$('#cw_user_pass').find('#password').addClass('invalid');
			}

			if (this.id === Cookies.CeesoftUserId) {
				if (!$.trim(oldPassword)) {
					isValid = false;
					message = lang.account.messages.USER_COMPLETE_FIELDS;
					$('#current_pass').addClass('invalid');
				} else if ($.trim(password) !== '' && password !== passwordConfirm) {
					isValid = false;
					message = lang.account.messages.USER_PASSWORD_IDENTICAL;
					$('#cw_user_pass').find('#password').addClass('invalid');
					$('#cw_user_pass_confirm').find('#password_confirm').addClass('invalid');
				}
			} else {
				if ($.trim(password) !== '' && $.trim(passwordConfirm) !== '' && password !== passwordConfirm) {
					isValid = false;
					message = lang.account.messages.USER_PASSWORD_IDENTICAL;
					$('#cw_user_pass').find('#password').addClass('invalid');
					$('#cw_user_pass_confirm').find('#password_confirm').addClass('invalid');
				}
			}
		}
		else if (this.mode === 'create' && $.trim(password) === '' && $.trim(passwordConfirm) === '') {
			isValid = false;
			message = lang.account.messages.USER_COMPLETE_FIELDS;
			$('#cw_user_pass').find('#password').addClass('invalid');
			$('#cw_user_pass_confirm').find('#password_confirm').addClass('invalid');
		}

		if (!this.isAdmin && userCategoryId === '') {
			isValid = false;
			message = lang.account.USER_SELECT_CATEGORY;
			$('#user_category').prev().addClass('invalid');
		}
		if (locale.trim() === '') {
			isValid = false;
			message = 'Please, select user language';
			$('#user_language').prev().addClass('invalid');
		}
		if (timezone.trim() === '') {
			isValid = false;
			message = lang.account.USER_SELECT_TIMEZONE;
			$('#user_timezone').prev().addClass('invalid');
		}

		//message = lang.account.messages.USER_COMPLETE_FIELDS;


		validation = {
			isValid: isValid,
			message: message
		};

		return validation;
	},
	/**
	 * Handler for the cancel button in the expanded summary section
	 */
	onDetailsCancel: function (e) {
		this.clearInvalid();
		if (this.isFromUsersGrid) {
			NavigationStore.go(AdministrationRouter.listUsers());
		} else if (this.isFromManagementUsersGrid) {
			State.mainApp.loadModule('ManagementUsers');
		} else {
			let breadcrumbLinkItems = $('a[navigation-action="' + NavigationAction.RewindToTheSame + '"]');
			let lastBreadcrumbLinkItem = breadcrumbLinkItems[breadcrumbLinkItems.length - 1];
			if (lastBreadcrumbLinkItem) {
				lastBreadcrumbLinkItem.click();
			} else {
				NavigationStore.go(SummaryRouter.home())
			}
		}
	},
	/**
	 * Handler for the cancel cw_avatar button click
	 */
	onAvatarCancel: function (e) {
		$('.form_content_minimal').hide();
		$('.cw_media_library_content').addClass('hide');
	},
	/**
	 * Gets the notifications data
	 */
	getSchedulerPeriods: function () {
	},
	/*
	 * Handler function for rendering severity threshold
	 * */
	renderSeverityThresholdPreview: function () {
		$('.cw_severity_threshold_preview').empty();
		if (this.severityThreshold) {
			var severity = {
				'CRITICAL': {
					text: lang.CRITICAL,
					indicator: 1,
					value: 'CRITICAL'
				},
				'MAJOR': {
					text: lang.MAJOR,
					indicator: 2,
					value: 'MAJOR'
				},
				'MINOR': {
					text: lang.MINOR,
					indicator: 3,
					value: 'MINOR'
				},
				'NONE': {
					text: lang.OK,
					indicator: 5,
					value: 'NONE'
				}
			};
			var html = '<span class="cw_inline_status_indicator cw_status_widget_color cw_color' + severity[this.severityThreshold].indicator + '"></span> <span class="label">' + severity[this.severityThreshold].text + '</span>';
			$('.cw_severity_threshold_preview').removeClass('hide').empty().append(html);
			if (this.notificationTypes.length) {
				var typesDataSource = new CeeViewDataSource({
					change: $.proxy(function (e) {
						var title = '';
						var length = this.notificationTypes.length;
						for (var i = 0; i < length; i++) {
							for (var j = 0; j < e.items.length; j++) {
								if (this.notificationTypes[i] === e.items[j].value) {
									title += e.items[j].text;
								}
							}
							if (i < length - 1) {
								title += ', ';
							}
						}
						$('.cw_severity_threshold_preview').attr('title', title);
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + this.accountContext + '/users/' + Cookies.CeesoftUserId + '/notificationPolicy/notificationTypes',
							contentType: 'application/json; charset=utf-8',
							type: 'GET',
							dataType: 'json',
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				typesDataSource.read();
			}
		}
	},
	/**
	 * Updates the notifications data
	 */
	updateSchedulerDataSource: function () {
		var loadUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/users/' + this.id + '/notificationPolicy?update=' + this.update;
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			var data, periods;
			if (result.success) {
				data = result.data;
				this.schedulerId = data.id;
				this.isSchedulerEnabled = data.isEnabled;
				this.markInboxAsRead = data.markInboxAsRead;
				if (data && data.periods) {
					this.schedulerData = data.periods;
					if (this.scheduler) {
						periods = this.getPeriods(data.periods);
						this.scheduler.dataSource.data(periods);
						this.scheduler.refresh();
					}
				}
			}
		}, this));
	},
	/**
	 * Handler function for the enable all periods button
	 */
	onNotificationsEnable: function () {
		this.isSchedulerEnabled = true;
		$('.cw_severity_threshold_preview').removeClass('is_disabled');
		this.enableNotifications(true);
		//this.saveScheduler();
	},
	/*
	 * Handler function for updating scheduler menu
	 * */
	destroy: function () {
		Application.prototype.destroy.call(this);
		if (this.mode === 'update' || this.mode === 'edit') {
			if (this.schedulerId) {
				RemoteEventsManager.discard(this.schedulerId);
			}
		}

		if (this.antSelectorNode) {
			ReactDOM.unmountComponentAtNode(this.antSelectorNode);
		}
		if (this.actionTriggerNode) {
			ReactDOM.unmountComponentAtNode(this.actionTriggerNode);
		}
	},
	/**
	 * Loads recent icons
	 * @param {Object} e The object sent by event manager
	 */
	loadRecentIcons: function (e) {
		this.recentlyUsedIcons = e.recentIcons;
	}
});
