import React, {useEffect, useState} from "react";
import {FormEntry} from "../../../controls/react/form/formEntry";
import {AntSelect} from "../../../controls/react/ant/antSelect";
import {AntDatePicker} from "../../../controls/react/ant/antDatePicker";
import {Section} from "../../../controls/react/layout";
import {AntInput, AntTextArea} from "../../../controls/react/ant/antInput";
import {translator} from "../../../core";
import {observer} from "mobx-react";
import {NewBudgetModel} from "./newBudgetModel";
import {AccountsApi} from "api";
import {ApplicationState} from "framework/applicationState";
import {Api} from "tools";
import {styleForThingsOnPopover} from "controls/react/ant/zIndexes";
import {CurrencyDropDown} from "../../../controls/designer/features/widgets/cost/currencyDropDown";
import {ModelType} from "./budgetTypes";
import {costTranslates} from "../translations";
import {IconButton} from "../../../controls/react/form";

interface BudgetConfigurationFormProps {
	store: NewBudgetModel,
	nameRef: React.RefObject<typeof AntInput>
}

const i = translator({
	'Name': { no: 'Navn' },
	'Description': {},
	'Currency': {
		no: 'Valuta'
	},
	'Cost Model': {no: 'Kostnad Modell'},
	'Start month': {
		no: 'Start måned'
	},
	'Duplicate budget values': {no: 'Duplikat budsjett verdier'},
	'Duplicate cost values': {no: 'Duplikat kost verdier'},
	'Display Settings': {no: 'Skjerminnstillinger'},
	'Display decimals': {no: 'Vis desimaler'},
	'Display as 1000': {no: 'Vises som 1000'},
	'NEW': {no: 'Ny', en: 'New'},
	'AUTO': {no: 'Auto', en: 'Auto'},
	'MANUAL': {no: 'Manuell', en: 'Manual'},
	'DUPLICATE': {en: 'Duplicate'},
	'COST_COLLECTOR': {en: 'Cloud connection', no: 'Skykobling'},
	'Costmodel': {no: 'Kostmodell'},
	'Cost Profile': {no: 'Kostprofil'},
	'Duplicate from': {no: 'Duplikat fra'},
	'Create from': {no: 'Opprett fra'},
	'Connection': {no: 'Tilkobling'},
	'Cost gateway can only be selected from current account context.': {no: 'Kost gateway kan bare velges fra gjeldende konto.'}
}, costTranslates);

export const AccountSelect = observer((props: {target: NewBudgetModel, modelField: keyof NewBudgetModel, disabled?: boolean, disabledMessage?: string}) => {
	const [accounts, setAccounts] = useState([]);
	useEffect(() => {
		(async () => {
			const url = AccountsApi.getLiteUrl(ApplicationState.accountId);
			const response = await Api.fetch(url);
			setAccounts(response);
		})()
	}, [])

	return <FormEntry label={i('Account')} model={props.target} modelField={props.modelField}>
		{props.disabled && props.disabledMessage &&
			<IconButton
				iconName={'question-sign'}
				title={props.disabledMessage}
				embedded
			/>
		}
		<AntSelect dataList={accounts} disabled={props.disabled}/>
	</FormEntry>
});

const CreateFromSelect = observer(({target, modelField, values}: {target: NewBudgetModel, modelField: keyof NewBudgetModel, values: string[]}) => {
	const dataList = values.map((x) => ({name: i(x), id: x}));
	return <FormEntry label={i('Create from')} model={target} modelField={modelField}>
		<AntSelect dataList={dataList}/>
	</FormEntry>
});

const CostModelSelect = observer(({target, dataList}: {target: NewBudgetModel, dataList: any[]}) => {
	return <FormEntry label={i('Duplicate from')} model={target} modelField={'costModelId'}>
		<AntSelect dataList={dataList} sortDataList={true}/>
	</FormEntry>
});

const CostConnectionSelect = observer(({target, dataList}: {target: NewBudgetModel, dataList: any[]}) => {
	return <FormEntry label={i('Cost gateway')} model={target} modelField={'cloudConnection'}>
		<AntSelect dataList={dataList}/>
	</FormEntry>
});

const ImportSettings = observer(({store}: {store: NewBudgetModel}) => {
	return <>
		{ store.modelType === 'MANUAL' && <CreateFromSelect target={store} values={store.creationTypes} modelField={'creationType'}/> }
		{ store.creationType === 'DUPLICATE' &&
		<>
			<AccountSelect target={store} modelField={'linkAccountId'} />
			<CostModelSelect target={store} dataList={store.filteredCostModelsDataList}/>
		</>}
		{ store.creationType === 'COST_COLLECTOR' &&
		<Section appearance={'frame'} title={i('Connection')} childrenPadding={true}>
			<AccountSelect target={store} modelField={'linkAccountId'} disabled={true} disabledMessage={i('Cost gateway can only be selected from a current account context.')}/>
			<CostConnectionSelect target={store} dataList={store.costConnectionModelsDataList}/>
		</Section>
		}
	</>
});

export const BudgetConfigurationForm = observer(
class Inner extends React.Component<BudgetConfigurationFormProps> {
	constructor(props: BudgetConfigurationFormProps) {
		super(props);
	}

	render() {
		const { store } = this.props;
		return (<>
			{store.modelType == ModelType.MANUAL && <ImportSettings store={store}/>}
			<Section appearance={'frame'} title={i('Name')} childrenPadding={true}>
				<FormEntry label={i('Name')} model={store} modelField={"name"}>
					<AntInput ref={this.props.nameRef}/>
				</FormEntry>
				{store?.creationType !== 'COST_COLLECTOR' && <AccountSelect target={store} modelField={'accountId'}/>}
				<FormEntry label={i('Description')} vertical={true} model={store} modelField={"description"}>
					<AntTextArea/>
				</FormEntry>
			</Section>
			{store.modelType == ModelType.CLOUD && <ImportSettings store={store}/>}
			<Section appearance={'frame'} title={i('Cost Model')} childrenPadding={true}>
				<FormEntry label={i('Start month')} model={store} modelField={"startDate"}>
					<AntDatePicker
						picker="month"
						format="MMM YYYY"
						allowClear={false}
						popupStyle={styleForThingsOnPopover}
					/>
				</FormEntry>
				<FormEntry label={i('Currency')} model={store} modelField={"currency"}>
					<CurrencyDropDown accountId={store.accountId} />
				</FormEntry>
			</Section>
		</>);
	}
})
