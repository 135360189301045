import ReactDOM from 'react-dom';
import React from 'react';
import {throttle} from 'lodash'
import {reaction} from 'mobx'

import {Utils, State, UserPrefs, Favorites, CustomTheme} from 'tools';

import {Cookies, Session, RoutesTable, RemoteEventsManager, LocalEventsManager, translator} from 'core';

import {
	CustomNotification, ContextMenu, Breadcrumb, ModalWindow,
	Dialog, ModuleStatusIndicator
} from 'controls'
import {topLeftCornerPosition} from 'controls/modalWindow'

import Settings from 'settings'
import Configuration from 'configuration'
import Monitors from 'tools/monitors'
import Automation from 'areas/automation/automation'
import Incidents from 'areas/incidents/incidents'

import Sla from 'areas/sla/sla'
import {
	AccountForm,
	AssetForm,
	CalendarForm,
	AuthenticationForm,
	AuthenticationsView,
	ServiceElementView,
	ServiceModelView,
	ServiceQualifierView,
	TeamDetails,
	TeamForm,
	UserDetails
} from 'areas/administration'
import {RoleDetails, RoleView} from "areas/administration/roles";
import Reports from 'areas/reports/reports';

import {
	NewServiceWindow,
	ServiceLogDetailsView,
	ServiceQualifierStatesView,
	ServicesList
} from 'areas/services';
import LastChangesView from 'areas/views/lastChangesView';
import MessageCenter from 'areas/views/messageCenter';
import ReasonsView from 'areas/views/reasonsView';
import {AssetGroupSummaryView} from "areas/assetgroups";
import {
	AssetHealthReasons,
	AssetHealthSummary,
	FiltersConfigurationView,
	FiltersView
} from "areas/assets";


import Integration from 'areas/integration/integration';
import IntegrationDetails from 'areas/integration/integrationDetails';
import BundleConfiguration from 'areas/management/bundleConfiguration';
import ManagementAudit from 'areas/management/managementAudit';
import ModulesView from 'areas/management/modulesView';
import RepositoryView from 'areas/management/repositoryView';
import ServerDetails from 'areas/management/serverDetails';
import SystemErrors from 'areas/management/systemErrorsView';
import ManagementUsersView from 'areas/management/usersView';
import {AgentConfiguration} from "areas/management";
import lang from "./localization/lang";
import {Api} from "tools/api";
import {GenericDirtyCheck} from 'tools/genericDirtyCheck';
import {SlaRouter} from 'areas/sla/bundleDescription';
import {ReportsRouter} from "areas/reports/bundleDescription";
import {DashboardsRouter} from "areas/dashboards/bundleDescription";
import {AutomationRouter} from "areas/automation/bundleDescription";
import {AssetGroupRouter} from 'areas/assetgroups/bundleDescription';
import SelectAccountForm from "controls/selectAccountForm";
import {getPromiseWithResolver} from 'tools/promise';
import {newGuid} from "tools/guid";
import {openInNewWindow} from "tools/navigation";
import {UserRouter} from "../areas/administration/users/bundleDescription";
import NewApplicationWindow from "../areas/application/newApplicationWindow";
import {ManagementRouter} from "areas/management/bundleDescription";
import { CostsRouter } from 'areas/cost/bundleDescription';
import {NewBudgetWindow} from "areas/cost/budget/newBudgetWindow"
import {IncidentsRouter} from "../areas/incidents/bundleDescription";
import {ApplicationState} from "../framework/applicationState";
import {MonitorsRouter} from "areas/assets/monitors/bundleDescription";
import {NavigationAction, NavigationStore} from "framework/navigationStore";
import {SummaryRouter} from "areas/summary/bundleDescription";
import {loadFavorites, removeFromFavorites} from "../tools/favorites";
import {AgentsRouter} from "../areas/management/api";
import {AdministrationRouter} from "../areas/administration/bundleDescription";
import {isOneOf} from "../tools/utils";
import contextualHelpMap from "../layout/helpMapping";
import {addUnknownTimeZonesToMoment} from "../vendor-init/momentTimezonesInit";

let i = translator();

export const writeRouteIdLog = (runtime) => {
	const routeId = runtime.routeId ?? runtime.moduleDescription?.routeId ?? State.mainApp._currentModuleConfig?.routeId ?? '';
	$('#content_area')[0].setAttribute('data-route-id', routeId);
	if (window.CEEVIEW_LOGGING_ROUTEID_ENABLED) {
		console.log('---------------------- Route Id ----------------------');
		console.log('route id: ', routeId);
		console.log('current help route: ', contextualHelpMap.get(routeId) ?? '');
		console.log('------------------------------------------------------');
	}
}

function setBodyClass(className) {
	$('body').removeClass().addClass(`custom-theme ${className}`);
}


export class MainApplication {
	_navigation = null;
	_runtime = {};
	_genericDirtyCheck = null;
	_navigationEnabled = true;


	constructor() {
		this.getStatusesCount = throttle(this.getStatusesCount, 10000)
	}

	run() {
		setBodyClass("");
		CustomTheme.apply();
		addUnknownTimeZonesToMoment();
		this._routesTable = new RoutesTable();
		this._genericDirtyCheck = new GenericDirtyCheck();

		//We need to handle clicks on all <a> on a page so we subscribe to document and
		//check event.target there
		document.addEventListener('click', this.onClicked, true);

		window.addEventListener('popstate', e => {
			if (State.isUnloading) {
				window.location.reload();
				return;
			}

			this.navigate(document.location.href, {
				doNotPushState: true,
				navigationAction: NavigationAction.RewindToTheSame
			});
		});

		Utils.getConfig(() => {
			this.createSocketConnection();
		});
	}

	onClicked = async (e) => {
		let aTag = e.target.tagName == 'a'
			? e.target
			: e.target.closest('a');

		if (aTag == null) {
			return;
		}

		const url = aTag.getAttribute('href');
		if( !url || url === '#' || url.startsWith('javascript:') || url.indexOf('#') == -1 || aTag.getAttribute('target') == '_blank')
			return;

		const additional = {
			navigationAction: aTag.getAttribute("navigation-action")
		};

		e.preventDefault();

		await this.navigate(url, additional);
	}


	async navigate(url, additional) {
		if(!this._navigationEnabled)
			return;

		if(this.standalone && this._runtime.module != null) {
			if (Cookies.CeesoftUsername == 'public') {
				openInNewWindow(url, additional);
				return;
			}

			this._navigationEnabled = false
			window.sessionStorage.setItem('storageAdditional', JSON.stringify(additional?.extra));
			window.location.hash = url + "?sessionId=" + newGuid();
			window.location.reload();
		}
		if(additional?.newTab){
			openInNewWindow(url, additional?.extra ? {extra: additional.extra} : undefined);
			return;
		}

		this.executeOnNavigateCallbacks();

		additional = additional || {};
		url = url.split('#').pop();

		let route = this._routesTable.find(url);
		if(route == null) {
			route = this._routesTable.find('/');
		}

		if(additional.doNotPushState !== true){
			window.history.pushState(null, null, '#' + url);
		}

		if( route.description == null ){
			//TODO: legacy route, handler does not return description, it navigates itself instead
			return;
		}

		const loaded = await this.setMainModule(route.description,
			route.params,
			additional
		);
	}

	get context(){
		return this.reactRef.appContext;
	}

	setReactRef(ref){
		this.reactRef = ref;
	}

	initComponent() {
		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
		});
		this.updateUsername();

		this.reloadCurrentApp = false;
		this.accountList = {};
		this.tempUserTagsWindow = {
			clickedElement: null,
			close: function () {
			}
		};

		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
		this.initCustomComponents();
		this.isVisitedModule = false;
		this.isRoutedModule = false;

		this.kendoLocaleLoaded = false;
		this.localeLoaded = false;

		kendo.culture(Cookies.CeesoftUserLocale);
		// set monday as first weekday for all locales
		kendo.culture().calendars.standard.firstDay = 1;
		this.kendoLocaleLoaded = true;

		this.localeLoaded = true;
		this.session = new Session();
		this.session.getUserRoles(this.onRolesRetrieve, this);
		Utils.getServerId();

		if (!this.standalone) {
			Utils.checkLicenseStatus();
		}
	}

	updateUsername() {
		if (Cookies.CeesoftUsername) {
			// update the username
			$('.cw_logged_user').text(Cookies.CeesoftUsername);
			// update the account name in breadcrumbs
			$('.cw_account_context').find('.cw_name').text(Cookies.CeesoftCurrentAccountName);
			$('title').text('Ceeview - ' + Cookies.CeesoftCurrentAccountName);
		} else {
			Utils.redirectTo(Settings.defaultLogoutPage, true);
		}
	}

	createSocketConnection() {
		this.subscriberId = Utils.guid();
		this.eventSourceId = Utils.guid();

		RemoteEventsManager.connect({
			sessionId: Cookies.sessionId,
			subscriberId: this.subscriberId,
			eventSourceId: this.eventSourceId,
			handleEvents: $.proxy(this.handleEvents, this),
			handleConfigEvents: $.proxy(this.handleConfigEvents, this),
			onInitialized: $.proxy(function () {
				this.initComponent();
			}, this),
			onSessionExpired: function () {
				Utils.redirectTo(Settings.defaultLogoutPage, true);
			}
		});
	}

	updateLangMessages() {
		$.fn.kendoCustomTreeList.widget.fn.options.messages.noRows = lang.grid.NO_RECORDS;
		$.fn.kendoCustomGrid.widget.fn.options.noRecords = {
			template: lang.grid.NO_RECORDS
		};
		if (kendo.ui.Pager) {
			Object.assign(kendo.ui.Pager.fn.options.messages, lang.kendo.pager.messages)
		}
		if (kendo.ui.ColumnMenu) {
			Object.assign(kendo.ui.ColumnMenu.fn.options.messages, lang.kendo.columnMenu.messages)
		}
	}

	loadUserPreferences() {
		var scope = this;
		UserPrefs.load('Ceeview', $.proxy(function (result) {
			if (result.success) {
				var prefs = result.data;
				for (let i = 0; i < prefs.length; i++) {
					UserPrefs.set(prefs[i].key, prefs[i].value);
				}
				// interpret preferences - include subAccounts
				var is = UserPrefs.get('includeSubaccounts');
				if (is !== null && is !== undefined) {
					State.includeSubaccounts = (is === 'true');
				}
				// set default values if not loaded
				else {
					State.includeSubaccounts = true;
				}
				if (!scope.session.hasRole('ACCOUNT_TRAVERSE')) {
					State.includeSubaccounts = false;
				}

				ApplicationState.includeSubaccounts = State.includeSubaccounts;

				var target, control = $('.cw_include_subaccounts');
				control.find('li').removeClass('is_selected');
				if (State.includeSubaccounts) {
					target = control.find('li').first();
				} else {
					target = control.find('li').last();
				}
				$(target).addClass('is_selected');
				var input = $(target).find('input[type=radio]');
				input.prop('checked', !input.is(':checked'));

				UserPrefs.load('LastChanges', $.proxy(function (result) {
					if (result.success) {
						for (let i = 0; i < result.data.length; i++) {
							UserPrefs.set(result.data[i].key, result.data[i].value);
						}
						State.lastChangesTimestamp = UserPrefs.get('lastChangesTimestamp');
					}
					scope.subscribe();
					scope.setNavState();
					scope.start();
				}, this));
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}


	loadAccountDetails() {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/logoId';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var timeout = Settings.COOKIE_TIMEOUT;
				Cookies.create("CeesoftAccountLogoId", result.data, timeout);
				if (result.data) {
					Renderer.setAccountLogo(result.data);
				}
			}
		}, this));
	}

	/**
	 * Saves user preferences
	 */
	saveUserPreferences() {
		if(ApplicationState.isGuestUser)
			return

		var preferences = [{
			key: 'minimizedNav',
			value: Boolean($('.is_minimized_nav').length)
		}, {
			key: 'includeSubaccounts',
			value: State.includeSubaccounts
		}, {
			key: 'collapsedShortcuts',
			value: Boolean($(".cw_nav_tier2:hidden").length)
		}, {
			key: 'lastChangesTimestamp',
			value: State.lastChangesTimestamp
		}];

		UserPrefs.save('Ceeview', preferences);
	}

	setNavState() {
		var isMinimized = UserPrefs.get('minimizedNav');
		if (isMinimized === 'true') {
			$('.cw_toggle_nav').removeClass('chevron-left').addClass('chevron-right');
			$('.main_content').addClass('is_minimized_nav');
		} else {
			$('.cw_toggle_nav').removeClass('chevron-right').addClass('chevron-left');
			$('.main_content').removeClass('is_minimized_nav');
		}
		var isMinimizedShortcuts = UserPrefs.get('collapsedShortcuts');
		if (isMinimizedShortcuts === 'true') {
			$(".cw_nav_tier2").addClass("hide");
		} else {
			$(".cw_nav_tier2").removeClass("hide");
		}
	}

	transformToObject(parametersAsString) {
		var params = {};
		var paramArray = parametersAsString.split("&");
		for (let  i = 0; i < paramArray.length; i++) {
			var tmp = paramArray[i].split("=");
			params[tmp[0]] = decodeURIComponent(tmp[1]);
		}
		return params;
	}

	loadFirstMenuItem() {
		$('#nav li').first().trigger('click');
	}

	start() {
		//console.profileEnd();
		var module = {};
		// create breadcrumb
		this.breadcrumb = new Breadcrumb({reactRef: this.reactRef});

		this.setInstalledMonitors();


		if (this.kendoLocaleLoaded && this.localeLoaded) {
			let redirectModule = sessionStorage.getItem('redirectModule');
			if (redirectModule) {
				let redirectConfig = '';
				try {
					if (sessionStorage.getItem('redirectConfig'))
						redirectConfig = JSON.parse(sessionStorage.getItem('redirectConfig'));
				}catch(e){
					console.error(e)
				}

				let redirectId = '';
				if(sessionStorage.getItem('redirectId'))
					redirectId = sessionStorage.getItem('redirectId');

				this.loadModule(redirectModule, redirectId, redirectConfig);

				this.getStatusesCount();
				sessionStorage.removeItem('redirectModule');
				sessionStorage.removeItem('redirectConfig');
				return;
			}

			//check for GET parameters
			var prmstr = window.location.search.substr(1);
			var params = (prmstr !== null && prmstr !== "") ? this.transformToObject(prmstr) : {};
			if (params.moduleName) {
				params.id = params.id || null;
				this.loadModule(params.moduleName, params.id, params);
				this.getStatusesCount();
				return;
			}

			this.navigate(window.location.hash);
			this.getStatusesCount();

			reaction(() => ApplicationState.includeSubaccounts, () => {
				if (!State.currentApp.isHomeView) {
					this.reloadCurrentModule()
				}
			})
		}
	}

	setMainModule = async (moduleDescription, routeParams, additional) => {
		let error = moduleDescription.checkAccess && moduleDescription.checkAccess(this, routeParams);
		if (error) {
			Utils.showInfo(lang.INFO, error);
			return false;
		}

		const containerSelector = '#content_area';
		const unloaded = await this.unloadModule(containerSelector);
		if (!unloaded)
			return false;

		if (moduleDescription.loadingIndicator !== false) {
			this.addLoadingMask();
		}

		this._runtime = {
			moduleDescription,
			configuration: routeParams
		};

		const container = document.querySelector(containerSelector);
		this.cleanUpContainer(container);

		if (moduleDescription.jspUrl) {
			await Api.loadContent(moduleDescription.jspUrl, container);
		}

		let stringStorageAdditional = window.sessionStorage.getItem('storageAdditional');
		if (stringStorageAdditional && stringStorageAdditional != 'undefined') {
			let storageAdditional = JSON.parse(stringStorageAdditional);
			if (!additional){
				additional = {};
			}

			if (!additional.extra){
				additional.extra = {};
			}

			Object.assign(additional.extra, storageAdditional);
		}

		const combinedParams = {...routeParams, ...moduleDescription.params, ...additional?.extra};
		combinedParams.standalone = this.standalone;
		if (combinedParams.id === 'null') {
			combinedParams.id = null;
		}

		const jsModule = await moduleDescription.getModuleImport(routeParams);
		const [initializationCallback, moduleLoadedPromise] = getPromiseWithResolver()

		if (moduleDescription.react || jsModule.default.prototype == null || jsModule.default.prototype.render) {
			const ModuleComponent = jsModule.default;

			if(jsModule.Store) {
				if(jsModule.Store.prototype.render ) {
					const ModuleStore = jsModule.Store;

					ReactDOM.render(
						<ModuleStore  {...combinedParams}
						              ref={ref => this._runtime.module = ref}
						              app={this.context}
						              initialized={initializationCallback}>
							<ModuleComponent/>
						</ModuleStore>,
						container
					);
				}else{
					const storeInstance = new jsModule.Store({
						...combinedParams,
						initialized: initializationCallback
					})

					this._runtime.module = storeInstance
					const Context = jsModule.Context

					ReactDOM.render(
						<Context.Provider value={storeInstance}>
							<ModuleComponent/>
						</Context.Provider>,
						container
					)
				}
			}else{
				ReactDOM.render(
					<ModuleComponent {...combinedParams}
					                 ref={ref => this._runtime.module = ref}
					                 app={this.context}
					                 initialized={initializationCallback}/>,
					container
				);
			}
		} else {
			const module = this._runtime.module = new jsModule.default();
			Object.assign(module, combinedParams);
			module.initialized = initializationCallback;
			module.app = this;
			module.container = container;
			module.init();
		}

		const initializationResult = await moduleLoadedPromise;

		if (initializationResult.subscriptions) {
			const subscriberId = Utils.guid();
			this._runtime.subscription = RemoteEventsManager.subscribe(subscriberId, initializationResult.subscriptions);
		}

		State.currentApp = this._runtime.module
		writeRouteIdLog(this._runtime);

		NavigationStore.push({
			title: initializationResult.title || 'Module did not provide title',
			url: window.location.hash
		}, {
			action: additional?.navigationAction,
			defaultItems: initializationResult.defaultBreadcrumbItems
		});

		setBodyClass(moduleDescription.bodyCssClass);

		this.removeLoadingMask();

		window.sessionStorage.removeItem('storageAdditional');

		return true;
	}

	updateLoadedModulesIformationForLegacyModules(){
		if(State.currentApp == null) {
			this._runtime = null;
			return;
		}

		if(this._runtime?.module == State.currentApp)
			return;

		this._runtime = {
			module: State.currentApp,
			configuration: this._currentModuleConfig,
			routeId: this._runtime.moduleDescription?.routeId ?? this._currentModuleConfig.routeId
		};

		State.currentApp.app = this;
	}

	//this method try 2 algorythms of unloading of a module
	//The new one with promises which  not need redirectModule and waitForConfirmation and additional loadModule call
	//and the old one with all of those
	async unloadModule(){
		if(!this._runtime.module)
			return true;

		try {
			let dirtyCheck = null;
			if (this._runtime.module.dirtyCheck) {
				dirtyCheck = await this._runtime.module.dirtyCheck();
			} else {
				dirtyCheck = await this._genericDirtyCheck.dirtyCheck();
			}

			if (dirtyCheck?.isDirty) {
				if (!await this.showDirtyCheckQuestion(dirtyCheck))
					return;
			}
		}catch(e){
			console.log(e);
		}


		let destroyed = true;
		if (!!this._runtime.module && !this._runtime.module.wasDestroyed && this._runtime.module.destroy) {
			try {
				destroyed = await this._runtime.module.destroy();
			}catch(e){
				console.error(e)
			}

			if (destroyed == null) { //legacy implementations does not return anything from destroy()
				if (this._runtime.module.waitForConfirmation) {
					this._runtime.module.redirectModule = {
						application: this._currentModuleConfig.moduleName,
						id: this._currentModuleConfig.id,
						config: this._currentModuleConfig
					};
					destroyed = false;
				} else {
					destroyed = true;
				}
			}
		}

		if(destroyed){
			this._runtime.subscription?.unsubscribe();
			this._genericDirtyCheck.reset();
			this._runtime = {};
		}

		return destroyed;
	}

	showDirtyCheckQuestion(dirtyCheckResult) {
		return new Promise(resolve => {
			const dialog = new Dialog({
				title: lang.INFO,
				msg: dirtyCheckResult.message,
				icon: 'INFO',
				actionText: 'CLOSE',
				buttons: {
					ok: true,
					cancel: true
				},
				fn: (value, button) => {
					if (button === 'ok') {
						resolve(true);
					} else {
						resolve(false)
					}
				}
			});
			dialog.show();
		});
	}

	cleanUpContainer(container){
		ReactDOM.unmountComponentAtNode(container);
		container.innerHTML = '';
	}

	loadModule = async (application, id, config, event, isFromBreadcrumb, loadInNewTab) => {
		if(!this._navigationEnabled)
			return;

		if(loadInNewTab){
			sessionStorage.setItem('redirectModule', application);
			if(config)
				sessionStorage.setItem('redirectConfig', JSON.stringify(config));
			if(id)
				sessionStorage.setItem('redirectId', id);
			openInNewWindow('')
			return;
		}

		config = config || application;

		if( typeof application === 'object' && application != null ){
			this._currentModuleConfig = application;
		}else{
			this._currentModuleConfig = {
				moduleName: application,
				isFromBreadcrumb,
				id,
				...config
			};
		}

		const monitorModule = Monitors.isModule(application);
		if(monitorModule && Configuration.installedMonitors.includes(monitorModule.type)) {
			this._currentModuleConfig.monitorType = monitorModule.type;
			this._currentModuleConfig.sharedAgents = Configuration.installedMonitorSettings[monitorModule.type].sharedAgents;
		}

		if (isOneOf('ManagementAgents', [application, this._currentModuleConfig.application])) {
			return this.navigate(AgentsRouter.list(), config);
		}

		// we don't have calls of loadModule for Dashboard
		if (application == 'Dashboard') {
			return this.navigate(DashboardsRouter.viewer(this._currentModuleConfig.id));
		}

		if (application == 'AccountDetails') {
			return this.navigate(AdministrationRouter.accountDetails(this._currentModuleConfig.id));
		}

		if (application == 'AccountConfigurationDetails') {
			const {id, data, mode} = this._currentModuleConfig;
			const {type, name, showProfile, factoryName} = data;
			return this.navigate(AdministrationRouter.accountConfigurationDetails(id,
				type, mode, name, showProfile, factoryName, ));
		}

		if (application == 'AdministrationCalendarsDetails') {
			return this.navigate(AdministrationRouter.calendarDetails(this._currentModuleConfig.id));
		}

		this.executeOnNavigateCallbacks();

		if(State.mainApp.standalone){
			sessionStorage.setItem('redirectModule', application);
			sessionStorage.setItem('redirectConfig', JSON.stringify(config));
			Utils.redirectTo(Settings.defaultLogoutPage);
			return;
		}

		try {
			State.isFromBreadcrumb = this._currentModuleConfig.isFromBreadcrumb;

			var pathname = window.location.pathname,
				path,
				position = pathname.toLowerCase().indexOf('index.jsp'),
				module = {
					application: null, //module unique id
					name: null //used as as title to show to users on UI
				},
				breadcrumbItem = {};

			if (position > -1) {
				path = pathname.substr(0, position);
			} else {
				path = String(pathname);
			}

			var historyUrl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + path.split('#')[0];

			if(!await this.unloadModule()) {
				return false;
			}

			if (event && (event.ctrlKey || event.shiftKey)) {
				var url = 'index.jsp?moduleName=' + encodeURIComponent(this._currentModuleConfig.moduleName)
					+ '&id=' + encodeURIComponent(this._currentModuleConfig.id);
				var focus = false;
				for (var key in this._currentModuleConfig) {
					if (key !== 'id') {
						url += '&' + key + '=' + encodeURIComponent(this._currentModuleConfig[key]);
					}
				}
				event.preventDefault();
				/*if (window.event && (window.event.ctrlKey || window.event.shiftKey)) {
				 focus = window.event.shiftKey;
				 window.event.ctrlKey = false;
				 window.event.shiftKey = false;
				 window.event.preventDefault();
				 }*/
				var openWindow = function () {
					var windowName = 'popup_' + Utils.guid();
					var newWindow = window.open(url, windowName, 'width=' + screen.width + ',height=' + screen.height + ',location=yes,scrollbars=yes,status=yes;');
					if (focus && window.focus) {
						newWindow.focus();
					} else {
						window.focus();
					}
				};
				setTimeout(openWindow, 500);
				return;
			}

			if (this._currentModuleConfig.moduleName) {
				if (this._currentModuleConfig.moduleName.indexOf('Administration') === -1) {
					//flag helpful when going to grids accesible from upper buttons, even if the administration left panel is still expanded
					this.isFalseAdministrationPanel = true;
					$('.cw_include_subaccounts').removeClass('is_disabled').data('enable', 'enable').css('pointer-events', 'initial');
					$('.cw_include_subaccounts .cw_item').removeClass('is_selected');
					$('.cw_include_subaccounts .cw_item[data-include="' + State.includeSubaccounts + '"]').addClass('is_selected');

				} else {
					this.isFalseAdministrationPanel = false;
					$('.cw_include_subaccounts').data('enable', 'disable').addClass('is_disabled').css('pointer-events', 'none');
					$('.cw_include_subaccounts .cw_item').removeClass('is_selected');
					$('.cw_include_subaccounts .cw_item[data-include="false"]').addClass('is_selected');
				}
			}

			this._currentModuleConfig.containerSelector = '#content_area';
			this.cleanUpContainer(document.querySelector(this._currentModuleConfig.containerSelector));

			setBodyClass("");

			switch (this._currentModuleConfig.moduleName) {
				// SERVICE
				case 'Service':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						$('#nav li').removeClass('current').parent().find('#service').addClass('current');
					}
					if (this._currentModuleConfig.moduleName !== this.breadcrumb.module.application) {
						breadcrumbItem.name = lang.SERVICE;
						breadcrumbItem.text = 'Service';
					} else {
						module.name = lang.SERVICE;
						module.text = 'Service';
					}
					setBodyClass('cw_service_module');
					$('#content_area').empty().load('include/Service.jsp', $.proxy(function () {
						State.currentApp = new ServicesList(this._currentModuleConfig);
					}, this));
					historyUrl += '#/service';
					break;

				case 'ServiceLogDetails':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						module.name = lang.SERVICE;
						module.application = 'Service';
						module.text = 'Service';
					}
					breadcrumbItem.name = this._currentModuleConfig.name || lang.service.SERVICE_LOG_DETAILS;
					$('#content_area').empty().load('include/Service/LogDetailsView.jsp', $.proxy(function () {
						State.currentApp = new ServiceLogDetailsView(this._currentModuleConfig);
					}, this));
					break;

				case 'SLAs':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						$('#nav li').removeClass('current').parent().find('#slas').addClass('current');
					}
					if (this._currentModuleConfig.moduleName !== this.breadcrumb.module.application) {
						breadcrumbItem.name = lang.widget.SLA;
						breadcrumbItem.text = 'SLA';
					}
					setBodyClass('cw_sla_module');
					$('#content_area').empty().load('include/SLAs.jsp', $.proxy(function () {
						State.currentApp = new Sla(this._currentModuleConfig);
					}, this));
					historyUrl += '#/sla';
					break;

				case '':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						module.name = lang.widget.SLA;
						module.application = 'SLAs';
						module.text = 'SLA';
					}
					if (this._currentModuleConfig.moduleName !== this.breadcrumb.module.application) {
						breadcrumbItem.name = lang.widget.SLA;
					}
					setBodyClass('cw_sla_module');
					$('#content_area').empty().load('include/SLAs.jsp', $.proxy(function () {
						State.currentApp = new Sla(this._currentModuleConfig);
					}, this));
					historyUrl += '#/sla';
					break;
				case 'Reasons':
					breadcrumbItem.name = lang.REASONS + ' - ' + this._currentModuleConfig.assetName;
					breadcrumbItem.text = 'Reason';
					$('#content_area').empty().load('include/Summary/ReasonsView.jsp', $.proxy(function () {
						State.currentApp = new ReasonsView(this._currentModuleConfig);
					}, this));
					break;
				case 'AssetHealthSummary':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						$('#nav li').removeClass('current').parent().find('#asset_health').addClass('current');
					}
					breadcrumbItem.name = lang.ASSET;
					breadcrumbItem.text = 'Asset';
					setBodyClass('cw_assethealth_module');
					$('#content_area').empty().load('include/AssetHealth/Summary.jsp', $.proxy(function () {
						State.currentApp = new AssetHealthSummary(this._currentModuleConfig);
					}, this));
					historyUrl += '#/asset';
					break;
				case 'AssetGroupSummaryView':
					breadcrumbItem.name = lang.account.ASSET_GROUP;
					breadcrumbItem.text = 'Asset Group';
					setBodyClass('cw_assethealth_module');
					//$('#nav li').removeClass('current').parent().find('#asset_health').addClass('current');
					$('#content_area').empty().load('include/AssetHealth/GroupSummary.jsp', $.proxy(function () {
						State.currentApp = new AssetGroupSummaryView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/asset';
					break;

				case 'AssetHealthReasons':
					breadcrumbItem.name = lang.REASON;
					breadcrumbItem.text = 'Reason';
					setBodyClass('cw_assethealth_module');
					$('#content_area').empty().load('include/AssetHealth/Reasons.jsp', $.proxy(function () {
						State.currentApp = new AssetHealthReasons(this._currentModuleConfig);
					}, this));
					historyUrl += '#/asset';
					break;
				case 'StatesView':
					breadcrumbItem.name = lang.STATE_SUMMARY;
					if (this._currentModuleConfig.serviceId && this._currentModuleConfig.serviceName) {
						breadcrumbItem.name = this._currentModuleConfig.serviceName + ' - ' + breadcrumbItem.name;
					}
					setBodyClass('cw_assethealth_module');
					$('#content_area').empty().load('include/Service/StatesView.jsp', $.proxy(function () {
						State.currentApp = new ServiceStatesView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/service';
					break;
				case 'QualifierStatesView':
					breadcrumbItem.name = lang.summary.SERVICE_QUALIFIER_STATE;
					setBodyClass('cw_assethealth_module');
					$('#content_area').empty().load('include/Service/QualifierStatesView.jsp', $.proxy(function () {
						State.currentApp = new ServiceQualifierStatesView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/service';
					break;
				case 'AssetHealthFilters':
					breadcrumbItem.name = lang.FILTER;
					breadcrumbItem.text = 'Filter';
					setBodyClass('cw_assethealth_module');
					$('#content_area').empty().load('include/AssetHealth/FiltersView.jsp', $.proxy(function () {
						State.currentApp = new FiltersView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/asset';
					break;
				case 'AssetHealthFiltersConfiguration':
					breadcrumbItem.name = lang.CONFIGURATION;
					setBodyClass('cw_assethealth_module');
					$('#content_area').empty().load('include/AssetHealth/FiltersConfiguration.jsp', $.proxy(function () {
						State.currentApp = new FiltersConfigurationView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/asset';
					break;

				case 'Incidents':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						module.name = lang.service.INCIDENT;
						module.application = 'Incidents';
						module.text = 'Incidents';
						$('#nav li').removeClass('current').parent().find('#incidents').addClass('current');
					}
					if (this._currentModuleConfig.moduleName !== this.breadcrumb.module.application) {
						breadcrumbItem.name = lang.service.INCIDENT;
						breadcrumbItem.text = 'Incident';
						module.text = 'Incidents';
					} else {
						module.name = lang.service.INCIDENT;
					}
					setBodyClass('cw_incident_module');
					$('#content_area').empty().load('include/Incidents.jsp', $.proxy(function () {
						State.currentApp = new Incidents(this._currentModuleConfig);
					}, this));
					historyUrl += '#/incidents';
					break;
				// REPORTS
				case 'Reports':
					if ($.isEmptyObject(this.breadcrumb.module) || this._currentModuleConfig.loadDefaultModule) {
						module.name = lang.REPORT;
						module.application = 'Reports';
						module.text = 'Report';
						$('#nav li').removeClass('current').parent().find('#reports').addClass('current');
					}
					setBodyClass('cw_report_module');
					$('#content_area').empty().load('include/Reports.jsp', $.proxy(function () {
						State.currentApp = new Reports(this._currentModuleConfig);

					}, this));
					historyUrl += '#/reports';
					break;
				case 'Automation':
					if ($.isEmptyObject(this.breadcrumb.module) || this._currentModuleConfig.loadDefaultModule) {
						module.name = lang.AUTOMATION;
						module.application = 'Automation';
						module.text = 'Automation';
						$('#nav li').removeClass('current').parent().find('#automation').addClass('current');
					}
					setBodyClass('cw_automation_module');
					$('#content_area').empty().load('include/Automation/View.jsp', $.proxy(function () {
						State.currentApp = new Automation(this._currentModuleConfig);
					}, this));
					historyUrl += '#/automation';
					break;

				case 'Integration':
					if ($.isEmptyObject(this.breadcrumb.module) || this._currentModuleConfig.loadDefaultModule) {
						module.name = lang.INTEGRATION;
						module.application = 'Integration';
						module.text = 'Integration';
						$('#nav li').removeClass('current').parent().find('#integration').addClass('current');
					}
					breadcrumbItem.name = lang.INTEGRATION;
					breadcrumbItem.text = 'Integration';
					setBodyClass('cw_integration_module');
					$('#content_area').empty().load('include/Integration/View.jsp', $.proxy(function () {
						State.currentApp = new Integration(this._currentModuleConfig);
					}, this));
					historyUrl += '#/integration';
					break;
				case 'IntegrationDetails':
					breadcrumbItem.name = this._currentModuleConfig.name || lang.INTEGRATION;
					setBodyClass('cw_integration_module');
					$('#content_area').empty().load('include/Integration/Details.jsp', $.proxy(function () {
						State.currentApp = new IntegrationDetails(this._currentModuleConfig);
					}, this));
					break;
				// ADMINISTRATION
				case 'AdministrationAuthentications':
					breadcrumbItem.name = lang.AUTHENTICATIONS;
					breadcrumbItem.text = 'Authentications';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/Authentications.jsp', $.proxy(function () {
						State.currentApp = new AuthenticationsView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;
				case 'AdministrationServiceModelTemplates':
					breadcrumbItem.name = lang.designer.SERVICE_MODEL_TEMPLATES;
					breadcrumbItem.text = 'Service Model Template';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/ServiceModelTemplates.jsp', $.proxy(function () {
						State.currentApp = new ServiceModelView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;
				case 'AdministrationServiceElementTemplates':
					breadcrumbItem.name = lang.designer.SERVICE_ELEMENT_TEMPLATES;
					breadcrumbItem.text = 'Service Element Template';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/ServiceElementTemplates.jsp', $.proxy(function () {
						State.currentApp = new ServiceElementView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;
				case 'AdministrationServiceQualifierTemplates':
					breadcrumbItem.name = lang.designer.SERVICE_QUALIFIER_TEMPLATES;
					breadcrumbItem.text = 'Service Qualifier Template';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/ServiceQualifierTemplates.jsp', $.proxy(function () {
						State.currentApp = new ServiceQualifierView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;

				case 'AdministrationUserDetails':
					breadcrumbItem.name = this._currentModuleConfig.name || ' ';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/User/Details.jsp', $.proxy(function () {
						State.currentApp = new UserDetails(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;
				case 'AdministrationTeamDetails':
					breadcrumbItem.name = this._currentModuleConfig.name || ' ';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/Team/Details.jsp', $.proxy(function () {
						State.currentApp = new TeamDetails(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;
				case 'AdministrationUserCategoriesView':
					breadcrumbItem.name = lang.USER_CATEGORIES;
					breadcrumbItem.text = 'Roles';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/UserCategories/View.jsp', $.proxy(function () {
						State.currentApp = new RoleView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;
				case 'AdministrationUserCategoriesDetails':
					breadcrumbItem.name = lang.USER_CATEGORIES;
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/UserCategories/Details.jsp', $.proxy(function () {
						State.currentApp = new RoleDetails(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;

				case 'AccountDetails':
					breadcrumbItem.name = this._currentModuleConfig.name || lang.ACCOUNT;
					breadcrumbItem.text = 'Account';
					import('areas/administration/accounts/details').then( m => {
						State.currentApp = new m.default(this._currentModuleConfig);
					});
					break;
				case 'AccountConfigurationDetails':
					breadcrumbItem.name = this._currentModuleConfig.name || lang.ACCOUNT;
					breadcrumbItem.text = 'Configuration';
					import('areas/administration/accounts/configurationDetails').then( m => {
						State.currentApp = new m.default(this._currentModuleConfig);
					});
					break;
				// MANAGEMENT
				case 'ManagementAgents':
					breadcrumbItem.name = lang.AGENTS;
					breadcrumbItem.text = 'Agents';
					setBodyClass('cw_management_module');
					$('#content_area').empty().load('include/Management/AgentsView.jsp', $.proxy(function () {
						State.currentApp = new AgentsView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/management';
					break;
				case 'ManagementServer':
					breadcrumbItem.name = lang.SERVER;
					breadcrumbItem.text = 'Server';
					setBodyClass('cw_management_module');
					State.currentApp = new ServerDetails(this._currentModuleConfig);
					historyUrl += '#/management';
					break;
				case 'ManagementRepository':
					breadcrumbItem.name = lang.agents.REPOSITORY;
					breadcrumbItem.text = 'Repository';
					setBodyClass('cw_management_module');
					$('#content_area').empty().load('include/Management/RepositoryView.jsp', $.proxy(function () {
						State.currentApp = new RepositoryView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/management';
					break;

				case 'ManagementModules':
					breadcrumbItem.name = lang.MODULE;
					breadcrumbItem.text = 'Module';
					setBodyClass('cw_management_module');
					$('#content_area').empty().load('include/Management/Modules.jsp', $.proxy(function () {
						State.currentApp = new ModulesView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/management';
					break;
				case 'ManagementAudit':
					breadcrumbItem.name = lang.AUDIT;
					breadcrumbItem.text = 'Audit';
					setBodyClass('cw_management_module');
					$('#content_area').empty().load('include/Management/Audit.jsp', $.proxy(function () {
						State.currentApp = new ManagementAudit(this._currentModuleConfig);
					}, this));
					historyUrl += '#/management';
					break;
				case 'ManagementUsers':
					breadcrumbItem.name = lang.ACTIVE_USERS;
					breadcrumbItem.text = 'Users';
					setBodyClass('cw_management_module');
					$('#content_area').empty().load('include/Management/UsersView.jsp', $.proxy(function () {
						State.currentApp = new ManagementUsersView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/management';
					break;
				case 'ManagementSystemErrors':
					module.name = lang.MANAGEMENT;
					module.application = 'ManagementAgents';
					module.text = 'System messages';
					breadcrumbItem.name = lang.SYSTEM_ERRORS;
					breadcrumbItem.application = 'ManagementSystemErrors';
					breadcrumbItem.text = 'System messages';
					breadcrumbItem.config = {};
					breadcrumbItem.config.parentBreadcrumb = {
						name: lang.MANAGEMENT
					};
					setBodyClass('cw_management_module');
					$('#content_area').empty().load('include/Management/SystemErrors.jsp', $.proxy(function () {
						State.currentApp = new SystemErrors(this._currentModuleConfig);
					}, this));
					historyUrl += '#/management';
					break;

				case 'LastChanges':
					module.name = lang.LAST_CHANGES;
					module.application = 'LastChanges';
					module.text = 'Last changes';
					$('#nav li').removeClass('current');
					$('#content_area').empty().load('include/Summary/LastChangesView.jsp', $.proxy(function () {
						State.currentApp = new LastChangesView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/lastchanges';
					break;
				case 'AgentConfiguration':
					breadcrumbItem.name = this._currentModuleConfig.agentName || ' ';
					if (this._currentModuleConfig.id !== '') {
						$('#content_area').empty().load('include/Management/AgentConfiguration.jsp', $.proxy(function () {
							State.currentApp = new AgentConfiguration(this._currentModuleConfig);
						}, this));
						historyUrl += '#/management';
					} else {
						this.loadModule('ManagementAgents');
					}
					break;
				case 'BundleConfiguration':
					breadcrumbItem.name = this._currentModuleConfig.bundleName || ' ';
					if (this._currentModuleConfig.configurationId && this._currentModuleConfig.id !== '') {
						$('#content_area').empty().load('include/Management/BundleConfiguration.jsp', $.proxy(function () {
							State.currentApp = new BundleConfiguration(this._currentModuleConfig);
						}, this));
						historyUrl += '#/management';
					} else {
						this.loadModule('ManagementAgents');
					}
					break;
				// PROFILE
				case 'Profile':
					module.name = lang.PROFILE;
					module.application = 'Profile';
					setBodyClass('');
					$('#nav li').removeClass('current');
					$('#content_area').empty().load('include/Administration/User/Details.jsp', $.proxy(function () {
						State.currentApp = new UserDetails(this._currentModuleConfig);
					}, this));
					historyUrl += '#/profile';
					break;
				// MESSAGE CENTER
				case 'MessageCenter':
					module.name = lang.mscenter.MESSAGE_CENTER;
					module.application = 'MessageCenter';
					$('#nav li').removeClass('current');
					$('#content_area').empty().load('include/MessageCenter.jsp', $.proxy(function () {
						State.currentApp = new MessageCenter(this._currentModuleConfig);
					}, this));
					historyUrl += '#/messages';
					break;
			}

			if (window.history && window.history.pushState) {
				if (!this.isVisitedModule || !this.isRoutedModule) {
					if (historyUrl.indexOf('#') > -1) {
						try {
							window.history.pushState(this._currentModuleConfig, document.title, historyUrl);
						} catch (e) {
							// console.log(e);
						}
					}
				} else {
					if (this.isRoutedModule) {
						this.isRoutedModule = false;
					}
					if (this.isVisitedModule) {
						this.isVisitedModule = false;
					}
				}
			}

			// BREADCRUMB
			if (!$.isEmptyObject(module)) {
				module.application = module.application || this._currentModuleConfig.moduleName;
				module.config = module.config || this._currentModuleConfig;
				if (!module.name && module.config && module.config.name) {
					module = module.config;
				}
				this.breadcrumb?.setModule(module);
			}
			if (!$.isEmptyObject(breadcrumbItem)) {
				breadcrumbItem.application = this._currentModuleConfig.moduleName;
				breadcrumbItem.config = this._currentModuleConfig;
				if (this._currentModuleConfig.parentBreadcrumb) {
					this.breadcrumb?.addItem(this._currentModuleConfig.parentBreadcrumb, false);
				}
				if (this._currentModuleConfig.moduleName !== this.breadcrumb?.module.application ) {
					this.breadcrumb?.addItem(breadcrumbItem);
				}
			}
			//Utils.removeNameTooltip();
		} catch (e) {
			console.log('App error:', e);
		}
	}

	removeListeners() {
		$('.cw_main_nav').off();
		$('.nav_header').off();
		$('.cw_current_user').off();
		$('#notify_bubble').off();
		$('.cw_multi_caret').off();
		$('#cw_multi_account').off();
		$('#cw_multi_agent').off();
		$('#cw_multi_asset').off();
		$('#cw_multi_asset_group').off();
		$('#cw_multi_auth').off();
		$('#cw_multi_user').off();
		$('#cw_multi_team').off();
		$('#cw_multi_service').off();
		$('#cw_multi_sla').off();
		$('#cw_multi_report').off();
		$('#cw_multi_dashboard').off();
		$('#cw_multi_automation').off();
		$('#cw_multi_filter').off();
		$('.aside').off();
		$('.row').off();
		$('body').off();
		$('.cw_usertags_section').off();
		$('.cw_toggle_nav').off();
		$('.cw_favorites_menu_item').off();
	}

	attachListeners() {
		$(document).on('click', $.proxy(this.resetTimer, this));
		$(document).on('keypress', $.proxy(this.resetTimer, this));
		$(document).on('mousemove', $.proxy(this.resetTimer, this));
		$(document).on('mousedown', $.proxy(this.resetTimer, this));
		$(document).on('DOMMouseScroll', $.proxy(this.resetTimer, this));
		$(document).on('mousewheel', $.proxy(this.resetTimer, this));
		$('.cw_main_nav > li').on('click', $.proxy(this.onCloseSubmenu, this));
		$('.nav_header').on('click', '.back, .main', $.proxy(this.onMenuBackClick, this));
		$('.cw_multi_caret').on('click', $.proxy(this.onToggleMenu, this));
		$('#nav_shortcuts .caret').on('click', $.proxy(this.onToggleShortcuts, this));
		$('#cw_multi_account').on('click', $.proxy(this.onNewAccount, this));
		$('#cw_multi_asset').on('click', $.proxy(this.onNewAsset, this));
		$('#cw_multi_asset_group').on('click', $.proxy(this.onNewAssetGroup, this));
		$('#cw_multi_auth').on('click', $.proxy(this.onNewAuthentication, this));
		$('#cw_multi_user').on('click', $.proxy(this.onNewUser, this));
		$('#cw_multi_team').on('click', $.proxy(this.onNewTeam, this));
		$('#cw_multi_service').on('click', $.proxy(this.onNewService, this));
		$('#cw_multi_sla').on('click', $.proxy(this.onNewSla, this));
		$('#cw_multi_report').on('click', $.proxy(this.onNewReport, this));
		$('#cw_multi_dashboard').on('click', $.proxy(this.onNewDashboard, this));
		$('#cw_multi_automation').on('click', $.proxy(this.onNewAutomation, this));
		$('#cw_multi_filter').on('click', $.proxy(this.onNewFilter, this));
		$('#cw_multi_calendar').on('click', $.proxy(this.onNewCalendar, this));
		$('#cw_multi_role').on('click', $.proxy(this.onNewRole, this));
		$('#cw_multi_application').on('click', $.proxy(this.onNewApplication, this));
		$('#cw_multi_cost').on('click', () => this.onNewCostModel());
		$('.cw_current_user').not('#notify_bubble').on('click', $.proxy(this.onCurrentUser, this));
		$('.row').on('click', $.proxy(this.closePopups, this));
		$('.cw_notifications').on('click', $.proxy(this.onNotificationsMenu, this));
		$('.cw_systemerror_notifications').on('click', $.proxy(this.onSystemErrorsIcon, this));
		$('.cw_event_summary_notifications').on('click', $.proxy(this.onEventSummaryIcon, this));
		$('.cw_last_changes_notifications').on('click', $.proxy(this.onLastChangesIcon, this));
		$('.aside').on('click', '.cw_item', $.proxy(this.loadFromNavigation, this));
		$('.cw_include_subaccounts').on('click', '.cw_item', $.proxy(this.onIncludeSubaccounts, this));
		$('.cw_toggle_nav').on('click', $.proxy(this.toggleNavigation, this));
		$('.cw_favorites_menu_item').off().on('click', $.proxy(this.toggleFavoritesMenu, this));
		$('.main_content').on('click', $.proxy(this.hideFavoritesMenu, this));
		$('.aside ul li').on('click', $.proxy(this.hideFavoritesMenu, this));

		$(window).on("unload", $.proxy(function () {
			State.isUnloading = true;
			this.destroy();
			if (State.currentApp) {
				State.currentApp.destroy();
			}
		}, this));
		$(window).on('popstate', $.proxy(function (event) {
			this.isVisitedModule = true;
			/*
			 * if (window.location.href.indexOf('index.jsp') > -1) { var r =
			 * confirm("Are you sure you want to leave the application?"); if (r ===
			 * true) { x = "You pressed OK!"; } else { x = "You pressed Cancel!"; } }
			 */
		}, this));

		$(document).off('click').on('click', $.proxy(function (e) {
			var preferecensWindow = $(e.target).closest(".k-window.cw_preferences_window");
			if (preferecensWindow.length === 0) {
				var windows = $('.cw_preferences_window'), window, handler;
				for (let i = 0, length = windows.length; i < length; i++) {
					handler = $(windows[i]).find('.k-window-content');
					window = handler.data('kendoWindow');
					if ($(windows[i]).find('.k-window-content').length && window) {
						window.close();
					}
				}
			}
		}, this));
		this.startTimer();
	}

	startTimer() {
		if (!ApplicationState.isGuestUser) {
			this.timeoutId = window.setTimeout($.proxy(this.goInactive, this), Settings.instanceTimeout * 60000);
		}
	}

	resetTimer() {
		window.clearTimeout(this.timeoutId);
		this.goActive();
	}

	goActive() {
		this.startTimer();
	}

	goInactive() {
		if (State.currentApp && !State.currentApp.isPublic) {
			this.doLogout();
		}
	}

	initKendoComponents() {
		var userId;
		$('#new_content').kendoDropDownList();
		$(".cw_user_actions").kendoMenu({
			orientation: 'vertical',
			hoverDelay: 500,
			select: $.proxy(function (item) {
				switch (item.item.id) {
					case 'menu_logout':
						this.doLogout();
						break;
					case 'menu_account':
						$(".current_account").trigger('click');
						break;
					case 'menu_profile':
						userId = Cookies.CeesoftUserId || 'current';

						State.mainApp.navigate(UserRouter.profile(userId), {
							navigationAction: NavigationAction.PopOne
						});

						$('.cw_user_actions').removeClass('is_open');
						break;
					case 'menu_about':
						this.showAboutDialog();
						$('.cw_user_actions').removeClass('is_open');
						break;
					case 'menu_clear_context':
						UserPrefs.clearCategory($.proxy(function () {
							this.actionNotification.setOptions({
								message: lang.messages.CLEAR_CONTEXT_MESSAGE,
								status: 'success'
							}).show();
							var grid = $('.k-grid.k-widget:visible').data('kendoCustomGrid');
							if (grid) {
								grid.dataSource.sort([]);
								grid.dataSource.filter([]);
								var searchBox = $('.cw_search_box');
								if (searchBox.length) {
									searchBox.val('');
								}
							}
						}, this));
						break;
				}
				//$('.cw_current_user').trigger('click')
			}, this)
		});
	}

	initCustomComponents() {

	}

	doLogout() {
		this.destroy();
		if (State.currentApp && State.currentApp.destroy) {
			State.currentApp.destroy();
		}
		State.currentApp = null;

		Api.fetchPost(Settings.serverPath + 'sessions/logout/', [])

		Cookies.erase('CeesoftUsername');
		Cookies.erase('CeesoftUserId');
		Cookies.erase('CeesoftAccountName');
		Cookies.erase('CeesoftAccountId');
		Cookies.erase('sessionId');
		Cookies.erase('CeesoftUserLocale');
		Cookies.erase('sbAccountId');
		Cookies.erase('sbSessionId');
		Cookies.erase('CeesoftLoggedIntoDashboardId');

		Utils.redirectTo(Settings.logoutPage);
	}


	onIncludeSubaccounts(e) {
		var target = $(e.currentTarget);
		var items = target.parent().find('.cw_item'), include = target.data('include');
		var parent = target.parent();
		if (parent.data('enable') === 'disable' && include) {
			return;
		}
		items.removeClass('is_selected');
		target.addClass('is_selected');

		//QUICKFIX Administration include or not subaccounts toggling
		if (!$('#administration_submenu').hasClass('expanded') || this.isFalseAdministrationPanel) {
			State.includeSubaccounts = include;
		}
		ApplicationState.includeSubaccounts = State.includeSubaccounts;
		this.cachedIncludeSubaccounts = State.includeSubaccounts;

		if (State.currentApp) {
			if (State.currentApp.onIncludeSubaccounts) {
				State.currentApp.onIncludeSubaccounts(State.includeSubaccounts);
			}
		}
		this.saveUserPreferences();
		this.getStatusesCount();
		this.subscribe();
	}

	onNewAccount(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('ACCOUNT_CREATE')) {
			State.mainApp.loadModule('AccountDetails', null, {
				id: null,
				mode: 'create',
				redirectBack: true
			}, e);
			e.stopPropagation();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}

	onNewAsset(e, assetGroupId) {
		e.stopPropagation();
		this.closeCreatePanel();
		if (State.mainApp.session.hasRole('ASSET_CREATE')) {
			var modalWindow = new ModalWindow({
				title: lang.account.ASSET_CREATE,
				width: 400,
				minHeight: 567,
				height: 'auto',
				position: topLeftCornerPosition,
				url: 'include/Administration/AssetForm.jsp',
				refresh: function () {
					new AssetForm({
						id: null,
						groupId: null,
						mode: 'create',
						assetGroupId: assetGroupId
					});
				}
			});
			modalWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
	}

	onNewAssetGroup(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('ASSET_CREATE')) {
			var assetGroup = {
				extra: {}
			};
			assetGroup.extra.mode = 'create';
			var target = $(e.currentTarget);
			State.mainApp.navigate(AssetGroupRouter.createNew(), assetGroup);
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new authentication menu item
	 * @param {Object} e The click event object
	 */
	onNewAuthentication(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('AUTHENTICATION_CREATE')) {
			var modalWindow = new ModalWindow({
				title: lang.CREATE,
				width: 400,
				minHeight: 400,
				maxHeight: 800,
				position: topLeftCornerPosition,
				id: 'authentication_modal',
				url: 'include/Administration/AuthenticationForm.jsp',
				refresh: $.proxy(function () {
					new AuthenticationForm({
						id: 'new_authentication',
						modalId: 'authentication_modal',
						mode: 'create'
					});
				}, this)

			});
			modalWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new user menu item
	 * @param {Object} e The click event object
	 */
	onNewUser(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('USER_CREATE')) {
			State.mainApp.navigate(UserRouter.profile('new'), {extra: {mode: 'create'}});
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new team menu item
	 * @param {object} e The click event
	 */
	onNewTeam(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('TEAM_CREATE')) {
			var modalWindow = new ModalWindow({
				title: lang.account.TEAM_CREATE,
				width: 400,
				minHeight: 435,
				height: 'auto',
				position: topLeftCornerPosition,
				url: 'include/Administration/TeamForm.jsp',
				refresh: function () {
					new TeamForm({
						id: null,
						mode: 'create'
					});
				}
			});
			modalWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new service board menu item
	 * @param {Object} e The click event object
	 */
	onNewDashboard(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('SERVICEBOARD_CREATE')) {
			State.mainApp.navigate(DashboardsRouter.create())
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new sla menu item
	 * @param {Object} e The click event object
	 */
	onNewSla(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('SLA_CREATE')) {
			State.mainApp.navigate(SlaRouter.createNew());
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new report menu item
	 * @param {Object} e The click event object
	 */
	onNewReport(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		State.mainApp.navigate(ReportsRouter.createNew());
	}
	/**
	 * Handler function for the create new presentation menu item
	 * @param {Object} e The click event object
	 */
	onNewPresentation(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		this.navigate(DashboardsRouter.create());
	}
	/**
	 * Handler function for the create new automation menu item
	 * @param {Object} e The click event object
	 *
	 */

	onNewAutomation(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		this.navigate(AutomationRouter.createNew());
	}

	onNewFilter(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		State.mainApp.loadModule('AssetHealthFiltersConfiguration', '', {
			mode: 'create'
		}, e);
	}

	onNewRole(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		NavigationStore.go(AdministrationRouter.roleDetails());
	}

	onNewApplication() {
		$('.aside').append('<div id="application_window_container"></div>');
		let onCloseApplicationWindow = () => {
			ReactDOM.unmountComponentAtNode(document.getElementById('application_window_container'));
		};
		ReactDOM.render(<NewApplicationWindow onCancel={onCloseApplicationWindow}/>, document.getElementById('application_window_container'));
		this.closeCreatePanel();
	}

	onNewCost(type) {
		$('.aside').append('<div id="cost_window_container"></div>');
		let onCloseCostWindow = () => {
			ReactDOM.unmountComponentAtNode(document.getElementById('cost_window_container'));
		};
		ReactDOM.render(
			<NewBudgetWindow onCancel={onCloseCostWindow} modelType={type}/>,
			document.getElementById('cost_window_container')
		);
		this.closeCreatePanel();
	}

	onNewCostModel() {
		this.onNewCost('MANUAL');
	}

	onNewCostStore() {
		this.onNewCost('CLOUD');
	}

	onNewCostGateway() {
		NavigationStore.go(CostsRouter.gatewayCreate());
	}

	onNewIncident() {
		State.mainApp.navigate(IncidentsRouter.createNew('GENERIC'), {
			extra: {
				accountList: [{
					id: ApplicationState.AccountId,
					name: ApplicationState.AccountName
				}]
			}
		});
	}

	/**
	 * Handler function for the create new service menu item
	 * @param {Object} e The click event object
	 */
	onNewService(e) {
		if(e) {
			e.stopPropagation();
		}
		if (State.mainApp.session.hasRole('SERVICE_CREATE')) {
			var win = new NewServiceWindow({});
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	onNewCalendar(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		let modalWindow = new ModalWindow({
			title: lang.account.CALENDAR_CREATE,
			width: 400,
			resizable: false,
			height: 440,
			position: topLeftCornerPosition,
			url: 'include/Administration/CalendarForm.jsp',
			refresh: function () {
				new CalendarForm({
					id: null,
					mode: 'create'
				});
			}
		});
		modalWindow.open();
		e.stopPropagation();
	}
	/**
	 * Handler function for Multi Content Menu
	 * @param {Object} e The click event object
	 */
	onToggleMenu(e) {
		e.stopPropagation();
		this.closePopups(e);
		$('.cw_multi_options').toggleClass('is_open');
	}
	/**
	 * Handler function for Toggle Shortcuts menu
	 */
	onToggleShortcuts() {
		$('#nav_shortcuts ul.cw_nav_tier2').toggleClass('hide');
	}
	/**
	 * Closes all floating panels
	 * @param {object} e The click event
	 */
	closePopups(e) {
		var target = e.target ? $(e.target) : $(e.sender.element), secondaryNav = $('.cw_nav_secondary.extended'),
			openControls = $('body').find('.is_open');

		if (!$(document).has(target).length || target.closest('.is_open').length) {
			return;
		}

		$('.cw_userinfo .cw_item.is_selected, .cw_actions_trigger.is_selected, .cw_view_button.is_selected').removeClass('is_selected');
		openControls.removeClass('is_open');

		if (secondaryNav.length && !secondaryNav.is(':visible') && !$('.cw_multi_content').hasClass('is_open')) {
			secondaryNav.show().animate({left: '180px'}, 250);
		}

		this.tempUserTagsWindow.clickedElement = target;
		this.tempUserTagsWindow.close();
	}

	closeCreatePanel() {
		let panel = $('.cw_multi_options');
		panel.removeClass('is_open');
	}

	/**
	 * Verifies if a service model still exists. TODO: check if needed anymore
	 * @param {Object} serviceModelId The service model id
	 */
	serviceModelExists(serviceModelId) {
		for (let i = 0; i < this.serviceModels.length; i++) {
			if (this.serviceModels[i].id === serviceModelId) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Gets the notifications counters and unread notifications
	 */
	getStatusesCount = () => {
		if(ApplicationState.standalone)
			return

		var lastChanges = State.lastChangesTimestamp || '';
		if (this.session.hasRole('SUMMARY_LIST')) {
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/stateCounters?includeSubaccounts=' + (State.includeSubaccounts ? 'true' : 'false') + '&lastChanges=' + lastChanges;
			Utils.ajax(url, 'GET', '', $.proxy(function (result) {
				if (result.success) {
					this.updateStatuses(result.data);
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		} else {
			//console.log('np SUMMARY_LIST role', this.session);
		}
	}
	updateStatuses(statuses) {
		$('.cw_statuses').empty();
		if (this.session.hasRole('SERVICE_LIST')) {
			this.serviceIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'service',
				items: {
					down: statuses.serviceCounters.down,
					error: statuses.serviceCounters.warning,
					inactive: statuses.serviceCounters.inactive
				}
			});
		}
		if (this.session.hasRole('ASSET_LIST')) {
			this.healthIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'health',
				items: {
					critical: statuses.healthCounters.critical,
					major: statuses.healthCounters.major,
					minor: statuses.healthCounters.minor,
					maintenance: statuses.healthCounters.maintenance
				}
			});
		}
		this.applicationIndicators = new ModuleStatusIndicator({
			renderTo: $('.cw_statuses'),
			type: 'application',
			items: {
				critical: statuses.applicationCounters.critical,
				major: statuses.applicationCounters.major,
				minor: statuses.applicationCounters.minor,
				maintenance: statuses.applicationCounters.maintenance
			}
		});
		if (this.session.hasRole('SLA_LIST')) {
			this.slaIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'sla',
				items: {
					breached: statuses.slaCounters.breaches,
					warning: statuses.healthCounters.warnings
				}
			});
		}
		if (this.session.hasRole('AGENT_LIST')) {
			this.agentIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'agent',
				items: {
					down: statuses.agentCounters.agentsDown,
					warning: statuses.agentCounters.agentsWarning,
					maintenance: statuses.agentCounters.agentsMaintenance
				}
			});
		}
		if (this.session.hasRole('SERVER_MENU')) {
			this.serverIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'server',
				items: {
					down: statuses.serverCounters.agentsDown,
					warning: statuses.serverCounters.agentsWarning,
					maintenance: statuses.serverCounters.agentsMaintenance
				}
			});
		}

		this.eventStateIndicators = new ModuleStatusIndicator({
			renderTo: $('.cw_statuses'),
			type: 'event',
			items: {
				critical: statuses.eventCounters?.critical,
				major: statuses.eventCounters?.major,
				minor: statuses.eventCounters?.minor,
				maintenance: statuses.eventCounters?.maintenance
			}
		});

		var messagesEl = $('.cw_notifications').find('b');
		if (statuses.unreadMessages) {
			if (messagesEl.hasClass('hide')) {
				messagesEl.removeClass('hide');
			}
			messagesEl.text(Utils.abbreviateNumber(statuses.unreadMessages));
		} else {
			$('#notify_bubble').addClass('hide');
		}
		var systemErrorNotificationEl = $('.cw_systemerror_notifications').find('b');
		if (statuses.unreadSystemErrors) {
			if (systemErrorNotificationEl.hasClass('hide')) {
				systemErrorNotificationEl.removeClass('hide');
			}
			systemErrorNotificationEl.text(Utils.abbreviateNumber(statuses.unreadSystemErrors));
		} else {
			systemErrorNotificationEl.addClass('hide');
		}
		var eventSummaryNotificationEl = $('.cw_event_summary_notifications').find('b');
		if (statuses.eventSummaryCount) {

			if (eventSummaryNotificationEl.hasClass('hide')) {
				eventSummaryNotificationEl.removeClass('hide');
			}
			eventSummaryNotificationEl.text(Utils.abbreviateNumber(statuses.eventSummaryCount));
		} else {
			eventSummaryNotificationEl.addClass('hide');
		}
		ApplicationState.ignoreTags = statuses.ignoreTags;
		var lastChangesNotificationEl = $('.cw_last_changes_notifications').find('b');
		if (statuses.lastChanges) {
			if (lastChangesNotificationEl.hasClass('hide')) {
				lastChangesNotificationEl.removeClass('hide');
			}
			lastChangesNotificationEl.text(Utils.abbreviateNumber(statuses.lastChanges));
		} else {
			lastChangesNotificationEl.addClass('hide');
		}
	}
	/**
	 * Gets the notifications count
	 */
	getUnreadNotifications() {
		var messageCount = 3;
		var url = Settings.serverPath + 'notifications/messages/last' + messageCount + 'Unread';
		Utils.ajax(url, 'GET', '', $.proxy(function (result) {
			var notificationsList = $('.cw_user_notifications');
			var notificationCode, notification;
			notificationsList.empty();
			notificationsList.append('<li class="cw_item cw_notifications_header">' + i('Notifications') + ' </li>');
			var notificationsHeader = $('.cw_notifications_header');
			if (result.length) {
				notificationsHeader.append('<button id="cw_clear_notifications" class="k-button right">' + lang.CLEAR + '</button>');
				for (let i = 0; i < result.length; i++) {
					notification = result[i];
					notificationCode = '<li data-uid="' + notification.notificationId + '" class="cw_item cw_notification">';
					notificationCode += '<h2>' + notification.fromUserName + '</h2>';
					notificationCode += '<div class="cw_message_excerpt">';
					notificationCode += '<p class="ellipsis">' + notification.message + '</p>';
					notificationCode += '<div class="cw_field"><time class="cw_notification_date" title="' + Renderer.browserDateRenderer(notification.time, Constants.DATETIME) + '">' + Renderer.messageLocalDate(notification.utcTime, notification.time) + '</time>';
					notificationCode += '<span class="cw_mark_as_read"><span class="glyphicons check go_right" title="' + lang.MARK_READ + '"></span></span></div>';
					notificationCode += '</div></li>';
					notificationsList.append(notificationCode);
				}
			} else {
				notificationsList.append('<li class="cw_item">' + lang.mscenter.messages.NO_MESSAGES + '</li>');
			}
			notificationsList.append('<li class="cw_item cw_message_center">' + lang.mscenter.messages.MESSAGE_CENTER_GO + '</li>');

			$('.cw_notification').off();
			$('.cw_notification').on('click', $.proxy(this.onNotificationView, this));
			$('.cw_message_center').off();
			$('.cw_message_center').on('click', $.proxy(this.openNotifications, this));
			$('#cw_clear_notifications').off();
			$('#cw_clear_notifications').on('click', $.proxy(this.clearNotifications, this));
			$('.cw_mark_as_read').off();
			$('.cw_mark_as_read').on('click', $.proxy(this.markAsReadNotification, this));
			$('.cw_notifications').addClass('is_selected');
		}, this));
	}
	/**
	 * Handler function for the click event on the View Message button
	 * @param {Object} e The click event object
	 */
	onNotificationView(e) {
		var notificationId = $(e.currentTarget).attr('data-uid');
		if (!$(e.target).hasClass('cw_mark_as_read') && !$(e.target).parents('.cw_mark_as_read').length) {
			$('.cw_notifications').toggleClass('is_selected');
			State.mainApp.loadModule('MessageCenter', '', {
				notificationId: notificationId
			}, e);

		}
	}
	/**
	 * Handler function for the click event on the Go To Message Center button
	 * @param {Object} e The click event object
	 */
	openNotifications(e) {
		e.stopPropagation();
		State.mainApp.loadModule('MessageCenter', '', {}, e);
		$('.cw_user_notifications').removeClass('is_open');
		$('.cw_userinfo').find('.cw_notifications').removeClass('is_selected');
	}
	/**
	 * Handler function for the click event on the Clear button (notifications)
	 * @param {Object} e The click event object
	 */
	clearNotifications(e) {
		var notificationIds = $(e.currentTarget).parent().parent().children('[data-uid]');
		var notificationsList = [];
		for (let i = 0, length = notificationIds.length; i < length; i++) {
			notificationsList.push($(notificationIds[i]).attr('data-uid'));
		}
		var url = Settings.serverPath + 'notifications/messages/markRead';
		Utils.ajax(url, 'POST', JSON.stringify(notificationsList), $.proxy(function (result) {
			if (result.success) {
				$(e.currentTarget).parent().parent().children('[data-uid]').slideUp(350);
				$(e.currentTarget).remove();
				this.getStatusesCount();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}
	/**
	 * Handler function for the click event on the Mark As Read button
	 * (notifications)
	 * @param {Object} e The click event object
	 */
	markAsReadNotification(e) {
		var notificationId = $(e.currentTarget).parent().parent().parent().attr('data-uid');
		var url = Settings.serverPath + 'notifications/messages/markRead';
		e.preventDefault();
		Utils.ajax(url, 'POST', JSON.stringify([notificationId]), $.proxy(function (result) {
			if (result.success) {
				$(e.currentTarget).parent().parent().parent().slideUp(350);
				this.getStatusesCount();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}

	/**
	 * Called when user menu is clicked
	 * @param {Object} e The click event
	 */

	onCurrentUser(e) {
		var wasOpen = $('.cw_current_user').hasClass('is_selected');
		e.stopPropagation();
		// hide other open elements
		this.closePopups(e);
		if (!wasOpen) {
			$('.cw_current_user').addClass('is_selected');
			$('.cw_user_actions').addClass('is_open');
		} else {
			$('.cw_current_user').removeClass('is_selected');
			$('.cw_user_actions').removeClass('is_open');
		}
	}
	/**
	 * Called when the notifications menu is clicked
	 * @param {Object} e The click event
	 */
	onNotificationsMenu(e) {
		e.stopPropagation();
		var target = $(e.target);
		var currentTarget = $(e.currentTarget);
		// hide other open elements
		this.closePopups(e);

		if (target[0] === currentTarget[0] || target.parent('.cw_notifications').length || target.is('.glyphicons.envelope i') || target.is('.cw_message_center')) {
			this.getUnreadNotifications();
			$('.cw_notifications').toggleClass('is_selected');
		}
		$('.cw_user_notifications').addClass('is_open');
	}
	onSystemErrorsIcon(e) {
		this.loadModule('ManagementSystemErrors', null, {}, e);
	}

	onEventSummaryIcon(e) {
		this.navigate(SummaryRouter.events(), {
			navigationAction: NavigationAction.Clear
		});
	}

	onLastChangesIcon(e) {
		this.loadModule('LastChanges', null, {}, e);
	}

	onCurrentAccount = e => {
		e.stopPropagation();
		var target = $(e.target);
		if (!$('.cw_account_drop').hasClass('is_open')) {
			State.mainApp.closePopups(e, true);
			$('.cw_account_drop').load('include/SelectAccount.jsp', function () {
				this.selectAccountForm = new SelectAccountForm();
			});
			$('.cw_account_drop').addClass('is_open');
		} else {
			$('.cw_account_drop').removeClass('is_open');
		}
	}

	/**
	 * Called when one or multiple events are received from the server
	 * @param {Array} events The event data
	 */
	handleEvents(events) {
		//console.log('Multiple Events:', events);
		var refreshStatuses = false, refreshNotifications = false;
		if (State.currentApp && State.currentApp.handleEvents) {
			State.currentApp.handleEvents(events);
		}

		if(ApplicationState.standalone)
			return

		for (let i = 0, length = events.length; i < length; i++) {
			if (events[i].eventType === 'AccountSummary' || events[i].eventType === 'AgentState' || events[i].eventType == 'EventSummaryCounter') {
				refreshStatuses = true;
			} else if (events[i].eventType === 'Notification') {
				refreshNotifications = true;
			}
		}
		if (refreshStatuses || refreshNotifications) {
			this.getStatusesCount();
		}
		if (refreshNotifications) {
			var messageGrid = $('.cw_messages_list').data('kendoCustomGrid');
			if (messageGrid) {
				messageGrid.dataSource.read();
				messageGrid.refresh();
			}
		}
	}
	/*
	* Called when one config event is received from the server
	* @param {Object} event The event data
	* */
	handleConfigEvents(properties) {
		if (properties && properties.logoutPage) {
			var timeout = Settings.COOKIE_TIMEOUT;
			Cookies.create("CeesoftLogoutPage", properties.logoutPage, timeout);
		}
		Utils.apply(Settings, properties);
	}
	/**
	 * Called after the user roles were obtained from server
	 * @param {Object} roles The user roles object
	 */
	onRolesRetrieve() {
		this.updateLangMessages();


		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftAccountId + '/picker';
		if (this.session.hasRole('ACCOUNT_LIST')) {
			Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
				if (result.success) {
					this.accountList = result.data;
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		}

		this.updateViewByRoles();

		this.contextMenu = new ContextMenu();
		this.contextMenu.setActionButton('ACCOUNT');

		this.loadUserPreferences();
		if (Cookies.CeesoftCurrentAccountId) {
			this.loadAccountDetails();
		}
		this.setUserIcon();
		//this.monitorsMenu();

		if (this.session.hasRole('ACCOUNT_LIST')) {
			$('.cw_account_context').off().on('click', this.onCurrentAccount);
		}
	}
	/**
	 * Handler function for setting the user icon
	 */
	setUserIcon() {
		var pictureId = (Cookies.CeesoftUserPictureId != 'null' && Cookies.CeesoftUserPictureId != null) ? Cookies.CeesoftUserPictureId : 'user-rounded';
		var handler = '';
		$('#cw_user_icon').remove();
		if (Utils.isGuid(pictureId)) {
			//use img with src
			var src = Settings.serverPath + 'sessions/' + Cookies.sessionId + '/accounts/' + Cookies.CeesoftAccountId + '/users/' + Cookies.CeesoftUserId + '/picture/' + pictureId;
			handler = '<img alt="' + lang.USER_PROFILE + '" src="' + src + '" id="cw_user_icon" onError="$(this).hide();$(\'.cw_current_user\').append(\'<span class=&quot;glyphicons user&quot;></span>\')" />';
		} else {
			// use span with glyphicon
			handler = '<span id="cw_user_icon" class="glyphicons ' + pictureId + '"></span>';
		}

		$('.cw_current_user').append(handler);
		$('#cw_user_icon').on('click', e => this.onProfileIconClick(e));
	}

	updateViewByRoles() {
		// left menu items
		//Home
		if (!this.session.hasRole('HOME_MENU')) {
			$('#service_summary').remove();
		}
		if (!this.session.hasRole('ACCOUNT_TRAVERSE')) {
			$('.cw_account_drop').remove();
			$('.cw_account_context .caret').remove();
			$('.cw_include_subaccounts').off('click', '.cw_item');
			$('.cw_include_subaccounts').find('.cw_item').addClass('hide');
		}
		//SERVICE
		if (!this.session.hasRole('SERVICE_MENU')) {
			$('#service').remove();
		}
		//SLA
		if (!this.session.hasRole('SLA_MENU')) {
			$('#slas').remove();
		}
		//SERVICEBOARD
		if (!this.session.hasRole('SERVICEBOARD_MENU')) {
			$('#board').remove();
		}
		//REPORT
		if (!this.session.hasRole('REPORT_MENU')) {
			$('#reports').remove();
		}
		//KPI
		if (!this.session.hasRole('KPI_MENU')) {
			$('#kpi').remove();
		}
		//INCIDENTS
		if (!this.session.hasRole('INCIDENT_MENU')) {
			$('#incidents').remove();
		}
		//INTEGRATION
		if (!this.session.hasRole('AGGR_INTEGRATION_MENU')) {
			$('#integration').remove();
		}
		//AUTOMATION
		if (!this.session.hasRole('AGGR_AUTOMATION_MENU')) {
			$('#automation').remove();
		}
		var canListReasons = this.session.hasRole('AGENT_READ') && this.session.hasRole('MONITOR_READ') && this.session.hasRole('ASSET_READ') && this.session.hasRole('ACCOUNT_READ');
		//ADMINISTRATION
		if (!(this.session.hasRole('ACCOUNT_MENU') || this.session.hasRole('ASSET_MENU') || this.session.hasRole('AUTHENTICATION_MENU') || this.session.hasRole('GROUP_MENU')
			|| this.session.hasRole('TEAM_MENU') || this.session.hasRole('USER_MENU') || this.session.hasRole('USER_CATEGORY_MENU') || this.session.hasRole('TEMPLATE_MENU'))) {
			$('#administration').remove();
		} else {
			//ADMINISTRATION - ACCOUNTS
			if (!this.session.hasRole('ACCOUNT_MENU')) {
				$('#administration_accounts').remove();
			}
			//ADMINISTRATION - AUTHENTICATIONS
			if (!this.session.hasRole('AUTHENTICATION_MENU')) {
				$('#administration_authentications').remove();
			}
			//ADMINISTRATION - TEMPLATES
			if (!this.session.hasRole('TEMPLATE_MENU')) {
				$('#administration_templates').remove();
			}
			//ADMINISTRATION - TEAMS
			if (!this.session.hasRole('TEAM_MENU')) {
				$('#administration_teams').remove();
			}
			//ADMINISTRATION - USERS
			if (!this.session.hasRole('USER_MENU')) {
				$('#administration_users').remove();
			}
			//ADMINISTRATION - USER CATEGORIES
			if (!this.session.hasRole('USER_CATEGORY_MENU')) {
				$('#administration_usercategories').remove();
			}
		}
		//MANAGEMENT
		if (!(this.session.hasRole('AGENT_MENU') || this.session.hasRole('AUDIT_MENU') || this.session.hasRole('MESSAGE_MENU') || this.session.hasRole('ACTIVEUSER_MENU')
			|| this.session.hasRole('SERVER_MENU'))) {
			$('#management').remove();
		} else {
			//MANAGEMENT - AGENTS
			if (!this.session.hasRole('AGENT_MENU')) {
				$('#management_agents').remove();
			}
			//MANAGEMENT - SERVER
			if (!this.session.hasRole('SERVER_MENU') || !this.session.hasRole('AGENT_READ')) {
				$('#management_server').remove();
			}
			//MANAGEMENT - AUDIT
			if (!this.session.hasRole('AUDIT_MENU')) {
				$('#management_audit').remove();
			}
			//MANAGEMENT - SYSTEM ERRORS
			if (!this.session.hasRole('MESSAGE_MENU')) {
				$('#management_system_errors').remove();
			}
			//MANAGEMENT - ACTIVE USERS
			if (!this.session.hasRole('ACTIVEUSER_MENU')) {
				$('#management_users').remove();
			}
			//MANAGEMENT - MODULES
			if (!this.session.hasRole('MODULE_LIST')) {
				$('#management_modules').remove();
			}
			//MANAGEMENT - REPOSITORY
			if (!this.session.hasRole('REPOSITORY_READ')) {
				$('#management_repository').remove();
			}
		}

		//HEALTH
		if (!(this.session.hasRole('HEALTHASSET_MENU') || this.session.hasRole('MONITOR_MENU') || this.session.hasRole('HEALTHFILTER_MENU') || this.session.hasRole('HEALTHREASON_MENU') || this.session.hasRole('HEALTHGROUP_MENU'))) {
			$('#asset_health').remove();
		} else {
			//HEALTH - ASSETS
			if (!this.session.hasRole('HEALTHASSET_MENU')) {
				$('#assethealth_summary').remove();
			}
			//HEALTH - ASSET GROUPS
			if (!this.session.hasRole('HEALTHGROUP_MENU')) {
				$('#assethealth_group_summary').remove();
			}
			//HEALTH - REASONS
			if (!this.session.hasRole('HEALTHREASON_MENU')) {
				$('#assethealth_reasons').remove();
			}
			//HEALTH - MONITORS
			if (!this.session.hasRole('MONITOR_MENU')) {
				$('#assethealth_monitors').remove();
			}
			//HEALTH - FILTERS
			if (!this.session.hasRole('HEALTHFILTER_MENU')) {
				$('#assethealth_filters').remove();
			}
		}

		//SHORTCUTS
		if (!(this.session.hasRole('AGENT_MENU'))) {
			$('#management_agents_shortcut').remove();
		}
		if (!this.session.hasRole('HEALTHASSET_MENU')) {
			$('#assethealth_summary_shortcut').remove();
		}
		if (!this.session.hasRole('MONITOR_MENU')) {
			$('#assethealth_monitors_shortcut').remove();
		}
		if (!this.session.hasRole('HEALTHREASON_MENU')) {
			$('#assethealth_reasons_shortcut').remove();
		}
		if (!this.session.hasRole('HEALTHFILTER_MENU')) {
			$('#assethealth_filters_shortcut').remove();
		}

		if (!this.session.hasRole('AGENT_MENU') && !this.session.hasRole('HEALTHASSET_MENU') && !this.session.hasRole('MONITOR_MENU') && !this.session.hasRole('HEALTHREASON_MENU') && !this.session.hasRole('HEALTHFILTER_MENU')) {
			$('#nav_shortcuts').remove();
		}

		//user profile
		if (!this.session.hasRole('USER_PROFILE')) {
			$('#menu_profile').remove();
		}


		if (!this.session.hasRole('MESSAGE_LIST')) {
			$('.cw_userinfo').find('.cw_notifications').remove();
		}
		if (!this.session.hasRole('SYSTEM_ERROR_LIST')) {
			$('.cw_systemerror_notifications').addClass('hide');
		}

		if (!(this.session.hasRole('EVENT_SUMMARY_LIST') && this.session.hasRole('SUMMARY_LIST'))) {
			$('.cw_event_summary_notifications').remove();
		}
		//PM
		if (!this.session.hasRole('PRESENTATION_MENU')) {
			$('#nav_presentations').remove();
		}
		if (!this.session.hasRole('APPLICATION_MENU')) {
			$('#nav_applications').remove();
		}
		if (!this.session.hasRole('COST_MENU')) {
			$('#nav_costs').remove();
		}
	}
	/**
	 * Displays the about dialog
	 */
	showAboutDialog() {
		var loadUrl = Settings.serverPath + 'about/';
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var data = result.data;
				let message = '';
				let frontEndData = data['FRONTEND'];
				message += '<div style="margin: 0px 0 15px 5px">';
				message += '<div>' + '<span style="font-weight: bold">' + frontEndData.name + '</span>' + ': ' + frontEndData.version + ' (build ' + frontEndData.buildNumber + ')' + '</div>';
				message += '<div>' + 'Build time' + ': ' + frontEndData.buildDate + '</div>';
				message += '</div>';
				let serverData = data['SERVER'];
				message += '<div style="margin-left: 5px">';
				message += '<div>' + '<span style="font-weight: bold">' + serverData.name + '</span>' + ': ' + serverData.version + ' (build ' + serverData.buildNumber + ')' + '</div>';
				message += '<div>' + 'Build time' + ': ' + serverData.buildDate + '</div>';
				message += '</div>';

				var dialog = new Dialog({
					title: lang.INFO,
					msg: message,
					icon: 'INFO',
					buttons: {
						ok: true
					},
					dialogHeight: 220
				});
				dialog.show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}
	/**
	 * Loads the main application modules when clicking on a left navigation item
	 * @param {Object} e The click object
	 */
	async loadFromNavigation(e) {
		var navItem = $(e.currentTarget), targetId = navItem.attr('id'),
			module = {}, menu = navItem.closest('ul'), level = parseInt(menu.data('level')), breadcrumbItem = {},
			startLoadingModule = true, id, parentMenuItem = $('#' + menu.data('parent')),
			parentBreadcrumb;
		//e.stopPropagation(); // add it back if needed

		this.closePopups(e);

		menu.find('.current').removeClass('current');
		navItem.addClass('current');

		if (menu.hasClass('cw_nav_secondary')) {
			if (parentMenuItem.length) {
				parentMenuItem.attr('data-state', 'closed');
			}
		} else {
			$('.cw_nav_secondary').find('.current').removeClass('current');
		}

		if (($('#nav').width() == 60) && navItem.data('minimized') === 'autohide' && !e.isTrigger) {
			menu.find('.nav_header .remove').trigger('click');
		}

		e.stopPropagation();

		let onlySetBreadcrumb = false;

		switch (targetId) {
			// Home
			case 'service_summary':
				this.breadcrumb.module = {};
				this.navigate('#/', {navigationAction: 'clear'});
				break;
			// Service
			case 'service':
				module.name = lang.SERVICE;
				module.application = 'Service';
				module.text = 'Service';
				module.config = {routeId: 'service'}
				startLoadingModule = false;
				break;
			// SLA
			case 'slas':
				module.name = lang.widget.SLA;
				module.application = 'SLAs';
				module.text = 'SLA';
				module.config = {routeId: 'slas'}
				break;

			// Health
			case 'asset_health':
				if (navItem.attr('data-state') === 'closed') {
					this.openSubmenu('assethealth_submenu', targetId);
				} else {
					$('#assethealth_submenu').removeClass('extended');
					navItem.removeClass('current').attr('data-state', 'closed');
					navItem.data('state', 'closed');
				}
				startLoadingModule = false;
				break;
			// Health - level 1
			case 'assethealth_summary':
			case 'assethealth_summary_shortcut':
				module.name = lang.INFRASTRUCTURE;
				module.application = '-AssetHealthSummary-';
				module.text = 'Asset';
				breadcrumbItem.name = lang.ASSET;
				breadcrumbItem.application = 'AssetHealthSummary';
				breadcrumbItem.text = 'Asset';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'assethealth_summary';
				breadcrumbItem.config.parentBreadcrumb = {
					name: 'assethealth'
				};
				break;
			case 'assethealth_group_summary':
				module.name = lang.INFRASTRUCTURE;
				module.application = '-AssetGroupSummaryView-';
				module.text = 'Asset Group';
				breadcrumbItem.name = lang.account.ASSET_GROUP;
				breadcrumbItem.application = 'AssetGroupSummaryView';
				breadcrumbItem.text = 'Asset group';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'assethealth_group_summary';
				breadcrumbItem.config.parentBreadcrumb = {
					name: 'assethealth'
				};
				break;
			// Health - Monitors
			case 'assethealth_monitors':
			case 'assethealth_monitors_shortcut':
				if (navItem.attr('data-state') === 'closed') {
					this.openSubmenu('monitors_submenu', targetId);
				} else {
					$('#monitors_submenu').removeClass('expanded');
					navItem.removeClass('current').attr('data-state', 'closed');
					navItem.data('state', 'closed');
				}
				startLoadingModule = false;
				break;
			case 'assethealth_reasons':
			case 'assethealth_reasons_shortcut':
				module.name = lang.INFRASTRUCTURE;
				module.application = '-AssetHealthReasons-';
				module.text = 'Reason';
				breadcrumbItem.name = lang.REASON;
				breadcrumbItem.application = 'AssetHealthReasons';
				breadcrumbItem.text = 'Reason';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'assethealth_reasons';
				breadcrumbItem.config.parentBreadcrumb = {
					name: 'assethealth'
				};
				break;
			case 'assethealth_states':
				module.name = lang.INFRASTRUCTURE;
				module.application = '-StatesView-';
				breadcrumbItem.name = lang.STATE_SUMMARY;
				breadcrumbItem.application = 'StatesView';
				breadcrumbItem.config = {routeId: 'assethealth_states'};
				break;
			case 'assethealth_filters':
			case 'assethealth_filters_shortcut':
				module.name = lang.INFRASTRUCTURE;
				module.application = '-AssetHealthFilters-';
				module.text = 'Filter';
				breadcrumbItem.name = lang.FILTER;
				breadcrumbItem.application = 'AssetHealthFilters';
				breadcrumbItem.text = 'Filter';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'assethealth_filters';
				breadcrumbItem.config.parentBreadcrumb = {
					name: 'assethealth'
				};
				break;
			// end Health
			// Incident
			case 'incidents':
				module.name = lang.service.INCIDENT;
				module.application = 'Incidents';
				module.text = 'Incident';
				module.config = {routeId: 'incidents'}
				break;
			// Report
			case 'reports':
				module.name = lang.REPORT;
				module.application = 'Reports';
				module.text = 'Report';
				module.config = {routeId: 'reports'}
				break;
			//Automation
			case 'automation':
				module.name = lang.AUTOMATION;
				module.application = 'Automation';
				module.text = 'Automation';
				module.config = {routeId: 'automation'}
				break;
			// Administration
			case 'administration':
				if (navItem.attr('data-state') === 'closed') {
					this.openSubmenu('administration_submenu', targetId);
				} else {
					$('#administration_submenu').removeClass('expanded');
					navItem.removeClass('current').attr('data-state', 'closed');
					navItem.data('state', 'closed');
				}
				startLoadingModule = false;
				break;
			// Administration - level 1
			case 'administration_accounts':
				module.name = lang.ADMINISTRATION;
				module.application = '-AdministrationAccounts-';
				module.text = 'Accounts';
				breadcrumbItem.name = lang.ADMINISTRATION;
				breadcrumbItem.application = 'AdministrationAccounts';
				breadcrumbItem.text = 'Accounts';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'administration_accounts';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.ADMINISTRATION
				};
				break;

			case 'administration_authentications':
				module.name = lang.ADMINISTRATION;
				module.application = 'AdministrationAccounts';
				module.text = 'Authentications';
				breadcrumbItem.name = lang.AUTHENTICATIONS;
				breadcrumbItem.application = 'AdministrationAuthentications';
				breadcrumbItem.text = 'Authentications';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'administration_authentications';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.ADMINISTRATION
				};
				break;
			// Administration - Templates
			case 'administration_templates':
				if (navItem.attr('data-state') === 'closed') {
					this.openSubmenu('templates_submenu', targetId);
				} else {
					$('#templates_submenu').removeClass('expanded');
					navItem.removeClass('current').attr('data-state', 'closed');
					navItem.data('state', 'closed');
				}
				startLoadingModule = false;
				break;
			// Templates - level 2
			case 'administration_servicemodel_templates':
				module.name = lang.ADMINISTRATION;
				module.application = 'AdministrationAccounts';
				module.text = 'Service model templates';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'administration_servicemodel_templates';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.TEMPLATES,
					application: '-AdministrationServiceModelTemplates-'
				};
				breadcrumbItem.name = lang.designer.SERVICE_MODEL_TEMPLATES;
				breadcrumbItem.application = 'AdministrationServiceModelTemplates';
				breadcrumbItem.text = 'Service model templates';
				break;
			case 'administration_serviceelement_templates':
				module.name = lang.ADMINISTRATION;
				module.application = 'AdministrationAccounts';
				module.text = 'Service element templates';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'administration_serviceelement_templates'
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.TEMPLATES,
					application: 'AdministrationServiceModelTemplates'
				};
				breadcrumbItem.name = lang.designer.SERVICE_ELEMENT_TEMPLATES;
				breadcrumbItem.application = 'AdministrationServiceElementTemplates';
				breadcrumbItem.text = 'Service element templates';
				break;
			case 'administration_servicequalifier_templates':
				module.name = lang.ADMINISTRATION;
				module.application = 'AdministrationAccounts';
				module.text = 'Service qualifier templates';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'administration_servicequalifier_templates';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.TEMPLATES,
					application: 'AdministrationServiceModelTemplates'
				};
				breadcrumbItem.name = lang.designer.SERVICE_QUALIFIER_TEMPLATES;
				breadcrumbItem.application = 'AdministrationServiceQualifierTemplates';
				breadcrumbItem.text = 'Service qualifier templates';
				break;
			// end Templates
			case 'administration_teams':
				module.name = lang.ADMINISTRATION;
				module.application = 'AdministrationAccounts';
				module.text = 'Teams';
				breadcrumbItem.name = lang.account.TEAMS;
				breadcrumbItem.application = 'AdministrationTeams';
				breadcrumbItem.text = 'Teams';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'administration_teams';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.ADMINISTRATION
				};
				break;
			case 'administration_users':
				module.name = lang.ADMINISTRATION;
				module.application = 'AdministrationAccounts';
				module.text = 'Users';
				breadcrumbItem.name = lang.USERS;
				breadcrumbItem.application = 'AdministrationUsers';
				breadcrumbItem.text = 'Users';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'administration_users';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.ADMINISTRATION
				};
				break;
			case 'administration_usercategories':
				module.name = lang.ADMINISTRATION;
				module.application = 'AdministrationAccounts';
				module.text = 'Roles';
				breadcrumbItem.name = lang.USER_CATEGORIES;
				breadcrumbItem.application = 'AdministrationUserCategoriesView';
				breadcrumbItem.text = 'Roles';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'administration_usercategories';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.ADMINISTRATION
				};
				break;
			// end Administration
			// Management
			case 'administration_calendar':
				module.name = lang.ADMINISTRATION;
				module.application = '-AdministrationCalendars-';
				module.text = 'Calendars';
				breadcrumbItem.name = lang.CALENDAR;
				breadcrumbItem.application = 'AdministrationCalendars';
				breadcrumbItem.text = 'Calendar';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'administration_calendar';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.ADMINISTRATION
				};
				break;
			case 'management':
				if (navItem.attr('data-state') === 'closed') {
					this.openSubmenu('management_submenu', targetId);
				} else {
					$('#management_submenu').removeClass('expanded');
					navItem.removeClass('current').attr('data-state', 'closed');
					navItem.data('state', 'closed');
				}
				startLoadingModule = false;
				break;
			// Management - level 1
			case 'management_agents':
			case 'management_agents_shortcut':
				module.name = lang.MANAGEMENT;
				module.application = '-ManagementAgents-';
				breadcrumbItem.name = lang.AGENTS;
				breadcrumbItem.application = 'ManagementAgents';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'management_agents';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.MANAGEMENT
				};
				break;
			case 'management_server':
				module.name = lang.MANAGEMENT;
				module.application = 'ManagementServer';
				module.text = 'Server';
				breadcrumbItem.name = lang.SERVER;
				breadcrumbItem.application = 'ManagementServer';
				breadcrumbItem.text = 'Server';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'management_server';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.MANAGEMENT
				};
				break;
			//Integration
			case 'integration':
				module.name = lang.MANAGEMENT;
				module.application = 'ManagementAgents';
				module.text = 'Integration';
				breadcrumbItem.name = lang.INTEGRATION;
				breadcrumbItem.application = 'Integration';
				breadcrumbItem.text = 'Integration';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'integration';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.MANAGEMENT
				};
				break;
			case 'management_repository':
				module.name = lang.MANAGEMENT;
				module.application = 'ManagementAgents';
				module.text = 'Repository';
				breadcrumbItem.name = lang.agents.REPOSITORY;
				breadcrumbItem.application = 'ManagementRepository';
				breadcrumbItem.text = 'Repository';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'management_repository';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.MANAGEMENT
				};
				break;
			case 'management_modules':
				module.name = lang.MANAGEMENT;
				module.application = 'ManagementAgents';
				module.text = 'Modules';
				breadcrumbItem.name = lang.MODULE;
				breadcrumbItem.application = 'ManagementModules';
				breadcrumbItem.text = 'Modules';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'management_modules';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.MANAGEMENT
				};
				break;
			case 'management_audit':
				module.name = lang.MANAGEMENT;
				module.application = 'ManagementAgents';
				module.text = 'Audit';
				breadcrumbItem.name = lang.AUDIT;
				breadcrumbItem.application = 'ManagementAudit';
				breadcrumbItem.text = 'Audit';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'management_audit';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.MANAGEMENT
				};
				break;
			case 'management_users':
				module.name = lang.MANAGEMENT;
				module.application = 'ManagementAgents';
				module.text = 'Active Users';
				breadcrumbItem.name = lang.USERS;
				breadcrumbItem.application = 'ManagementUsers';
				breadcrumbItem.text = 'Active Users';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'management_users';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.MANAGEMENT
				};
				break;
			case 'management_system_errors':
				module.name = lang.MANAGEMENT;
				module.application = 'ManagementAgents';
				module.text = 'System messages';
				breadcrumbItem.name = lang.SYSTEM_ERRORS;
				breadcrumbItem.application = 'ManagementSystemErrors';
				breadcrumbItem.text = 'System messages';
				breadcrumbItem.config = {};
				breadcrumbItem.config.routeId = 'management_system_errors';
				breadcrumbItem.config.parentBreadcrumb = {
					name: lang.MANAGEMENT
				};
				break;
			// end Management
			case 'nav_costs':
				$('#cost_submenu').addClass('expanded');
				State.mainApp.navigate(CostsRouter.listByType('cost-model'), {navigationAction: 'clear'});
				break;
			case 'board':
				module.name = lang.serviceBoard.DASHBOARD;
				module.application = 'Dashboard';
				module.text = 'Dashboard';
				module.config = {routeId: 'board'}
				onlySetBreadcrumb = true;
				break;
		}

		if($(e.currentTarget).children('a[href]').length > 0)
			return

		if (startLoadingModule) {
			let loaded = true

			id = module.config ? module.config.id : null;
			if (!$.isEmptyObject(module)) {
				if (onlySetBreadcrumb) {
					this.breadcrumb.setModule(module);
					return;
				}

				let navigationItemsBackup = [...NavigationStore.items]

				if ($.isEmptyObject(breadcrumbItem)) {
					this.breadcrumb.setModule(module);
					loaded = await this.loadModule(module.application, id, module.config || {});
				} else {
					this.breadcrumb.setModule(module, false);
					loaded = await this.loadModule(breadcrumbItem.application, id, breadcrumbItem.config);
				}

				if (loaded === false) {
					NavigationStore.items = navigationItemsBackup
				}
			}
		}
	}
	/*
	 * Sets the health/monitors installed monitors
	 */
	setInstalledMonitors(isMonitorManagement) {
		var url;
		if (isMonitorManagement) {
			url = Settings.managementPath + 'monitors/';
		} else {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/types';
		}
		Configuration.installedMonitors = [];
		Configuration.installedMonitorsNames = [];
		Configuration.installedMonitorSettings = [];
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			for (let i = 0; i < result.length; i++) {
				Configuration.installedMonitors.push(result[i].value);
				Configuration.installedMonitorsNames[result[i].value] = result[i].text;
				Configuration.installedMonitorSettings[result[i].value] = result[i];
			}

			ApplicationState.enabledMonitors = [...result]

			this.monitorsMenu();
			for (var key in Configuration.installedMonitorsNames) {
				var monitorContainer = $('#assethealth_' + key.toLowerCase());
				if (Configuration.installedMonitors.indexOf(key) > -1) {
					monitorContainer.removeClass('hide');
				} else {
					monitorContainer.addClass('hide');
				}
			}
		}, this));
	}
	/**
	 * Handler for the back button in the menu header
	 * @param {Object} e The click event object
	 */
	onMenuBackClick(e) {
		var target = $(e.currentTarget),
			menuId = target.closest('ul').attr('id');

		if (target.hasClass('back')) {
			this.backToMenu(menuId, false);
		} else {
			this.backToMenu(menuId, true);
		}
	}
	/**
	 * Handler for closing submenu
	 * @param {Object} e The click event object
	 */
	onCloseSubmenu(e) {
		var target = $(e.currentTarget),
			menuId = target.attr('id');
		if ($('.cw_nav_secondary.expanded').attr('data-parent') !== menuId) {
			this.backToMenu(menuId, true);
		}
	}
	/**
	 * Opens a submenu
	 * @param {String} menuId The id os the menu to expand
	 * @param {String} parentItemId The id of the parent menu item
	 * @param {Boolean} [openFirstItem=true] Optional parameter used to configure if the first item of the submenu should be clicked
	 */
	openSubmenu(menuId, parentItemId, openFirstItem) {
		var submenu = $('#' + menuId),
			parentItem = $('#' + parentItemId),
			clickOnFirst = true;
		if (openFirstItem === false) {
			clickOnFirst = false;
		}
		submenu.addClass('expanded');
		if (clickOnFirst) {
			submenu.find('.cw_item').first()[0].click();
		}
		parentItem.attr('data-state', 'open');
	}
	/**
	 * Closes a submenu or all submenus
	 * @param {String} menuId The id os the menu to close
	 * @param {Boolean} backToMainMenu
	 */
	backToMenu(menuId, backToMainMenu) {
		var menu, parentItem;
		if (menuId) {
			menu = $('#' + menuId);
			parentItem = $('#' + menu.data('parent'));
			if (backToMainMenu) {
				$('.cw_nav_secondary').removeClass('expanded');
				$('.cw_item[data-state=open]').data('state', 'closed');
				$('.cw_item[data-state=open]').attr('data-state', 'closed');
			} else {
				menu.removeClass('expanded');
				parentItem.data('state', 'closed');
				parentItem.attr('data-state', 'closed');
			}
		}
	}
	/**
	 * Starts the events sending from server
	 */
	monitorsMenu() {
		var monitorIcons = {
			'CONNECTIVITY': 'engineering-networks',
			'WINEVENTS': 'list-alt',
			'EXTERNAL': 'new-window-alt',
			'HYPERV': 'show-big-thumbnails',
			'IBMSVC': 'hdd',
			'SOLARWINDS': 'door',
			'MAILGTW': 'envelope',
			'LOGMONITOR': 'log-book',
			'MSCLUSTER': 'show-big-thumbnails',
			'NIMSOFT': 'door',
			'SYSTEM': 'cogwheels',
			'PROCESSES': 'cluster',
			'WINSERVICE': 'share-alt',
			'PRTG': 'door',
			'VMWARE': 'server',
			'VMM': 'show-big-thumbnails',
			'NAGIOS': 'door',
			'FILESEARCH': 'file',
			'SNMP': 'coins',
			'SNMPTRAP': 'thumbnails-list',
			'XEN_SERVER': 'times',
			'REMOTEISERIES': 'server',
			'REMOTESYSTEM': 'indent-right',
			'ICINGA': 'door',
			'PROCESSMONITOR': 'cluster',
			'GROOVY': 'code',
			'METRICMONITOR': 'hdd',
			'DATABASE': 'database',
			'EATONUPS': 'battery-charged',
			'MODBUS': 'artificial-intelligence',
			'URL': 'link',
			'DBWATCH': 'database-search',
			'SCRIPT': 'code',
			'CONTAINER': 'palette-package',
			'INTERFACETRAFFIC': 'indent-right',
			'WEBHOOK': 'link'
		};
		var menuItems = [{
			title: lang.assethealth.monitor.list.ALL,
			icon: 'cardio',
			id: 'assethealth_monitor_all'
		}];

		for (var key in Configuration.installedMonitorsNames) {
			if (monitorIcons[key]) {
				menuItems.push({
					title: key === 'GROOVY'
						? Configuration.installedMonitorsNames[key] + lang.LEGACY
						: Configuration.installedMonitorsNames[key],
					icon: monitorIcons[key],
					id: 'assethealth_' + key.toLowerCase(),
					key: key
				});
			}
		}
		// Sorting the object alphabetically based on a property
		menuItems.sort(function (a, b) {
			return (a.title < b.title) ? -1 : (a.title > b.title) ? 1 : 0;
		});

		var monitorClass = '';
		if ((menuItems.length >= 10) && (menuItems.length <= 20)) {
			monitorClass = 'up-to-20';
		} else if (menuItems.length > 20) {
			monitorClass = 'up-to-30';
		}
		$('#monitors_submenu').closest('.cw_nav_secondary').addClass(monitorClass);

		var template = '';
		for (let i = 0, length = menuItems.length; i < length; i++) {
			let title = menuItems[i].id === 'assethealth_groovy' ? lang.assethealth.GROOVY_LEGACY : '';
			template += `<li class="cw_item" data-minimized="autohide">`;
			template += `<a href="#${MonitorsRouter.list(menuItems[i].key)}" navigation-action="${NavigationAction.Clear}"><span class="glyphicons ${menuItems[i].icon}"></span>`;
			template += `<span title="${title}" class="label">${menuItems[i].title}</span></a>`;
			template += `</li>`;
		}
		$('#monitors_submenu').find('.cw_item').remove();
		$('#monitors_submenu').append(template);
		$('#monitors_submenu').append('<li class="cw_favorites_menu_item cw_item"><span class="glyphicons star"></span><span class="label">' + i('Favorites') + '</span></li>');
		$('.cw_favorites_menu_item').off().on('click', $.proxy(this.toggleFavoritesMenu, this));
	}

	addLoadingMask(type = "mask", bounceTime = 0){
		if(this.setLoadingMaskTimeout){
			clearTimeout(this.setLoadingMaskTimeout)
		}

		if(bounceTime > 0) {
			this.setLoadingMaskTimeout = setTimeout(() => {
				document.getElementById("content_area")
					.classList.add(type);
			}, bounceTime);
		}else{
			document.getElementById("content_area")
				.classList.add(type);
		}

		if(this.loadingMaskTimeout){
			clearTimeout(this.loadingMaskTimeout);
		}

		this.maskRemoved = false;
		this.loadingMaskTimeout = setTimeout(() => {
			if (!this.maskRemoved) {
				this.removeLoadingMask();
			}
		}, 10000)
	}

	removeLoadingMask = () => {
		if(this.setLoadingMaskTimeout){
			clearTimeout(this.setLoadingMaskTimeout)
		}

		$('.cw_white_mask').remove();
		$('.cw_content_mask').remove();
		$('#main_loading_mask').remove();
		$('.k-loading-mask').remove();

		setTimeout(function() {
			$("#content_area")
				.removeClass("mask")
				.removeClass("busy")
				.removeClass("mask_hide");
		},300);

		$('#content_area').addClass('mask_hide')

		this.maskRemoved = true;
	}
	toggleNavigation(e) {
		$(e.currentTarget).toggleClass('chevron-left chevron-right');
		$('.main_content').toggleClass('is_minimized_nav');
		//$('#nav_shortcuts').toggleClass('hide');
	}
	/**
	 * Handler for counting the number of columns in the favorites list
	 */
	async toggleFavoritesMenu() {
		try {
			await loadFavorites()

			const favoritesModules = $('.cw_favorites_modules');

			if (!favoritesModules.hasClass('hide')) {
				this.hideFavoritesMenu();
				return;
			}
			favoritesModules.removeClass('hide');
			var visibleSubmenuNo = $('.cw_nav_secondary.expanded').length;
			var listItems = {}, top = 0;
			if (visibleSubmenuNo > 0) {
				listItems = $('.cw_nav_secondary.expanded')[visibleSubmenuNo - 1].children;
			} else {
				listItems = $('.cw_main_nav li');
			}
			for (let i = 0; i < listItems.length; i++) {
				top += $(listItems[i]).outerHeight();
			}
			top = top + $('.cw_multi_content').outerHeight() - 15;
			favoritesModules.css({left: ($('.aside').width() + 15), top: top});
			var moduleWidth = $('.cw_favorites_modules li').outerWidth() || 150;
			this.renderFavoriteItems();
			if ($('.cw_user_favs li').length < 5) {
				favoritesModules.css({width: (moduleWidth + 40)});
			} else if (($('.cw_user_favs li').length >= 5) && ($('.cw_user_favs li').length <= 10)) {
				favoritesModules.css({width: (moduleWidth * 2 + 40)});
			} else if (($('.cw_user_favs li').length > 10) && ($('.cw_user_favs li').length <= 15)) {
				favoritesModules.css({width: (moduleWidth * 3 + 65)});
			}
		} catch (e) {
			Utils.showInfo(lang.ALERT, e.message, e.details);
		}
	}
	/**
	 * Handler fot rendering the favorite items list
	 */
	renderFavoriteItems() {
		const list = $('.cw_user_favs');
		list.empty();

		if (Favorites.length < 1) {
			$(".cw_favorites_modules").addClass("hide")
			return
		}

		Favorites.sort(function (a, b) {
			const textA = a.item.name.toUpperCase();
			const textB = b.item.name.toUpperCase();
			return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
		});

		$('.cw_no_fav').addClass('hide');
		Favorites.forEach(item => {
			let name;
			if (item.item.text) {
				name = item.item.text.toUpperCase().replace(/ /gi, '_');
			} else {
				name = item.item.name;
			}
			let itemName = lang.favorites[name] || name;
			const listItem = '<li class="cw_item" id="' + item.id + '"><span class="cw_fav_item label" data-module="' + item.item.application + '">' + itemName + '</span><i class="glyphicons bin cw_remove"></i></li>';
			list.append(listItem);
		})

		//due to css manangement_server id selector; todo to be refined
		list.find('.cw_fav_item').css({
			'width': '90%',
			'float': 'left',
			'left': 0
		});

		$('.cw_user_favs').find('.cw_item').on('click', '.cw_fav_item', $.proxy(this.onFavoriteItemClick, this));
		$('.cw_user_favs').off().on('click', '.cw_remove', $.proxy(this.removeFromFavorites, this));
	}
	/**
	 * Handler for removing an item from favorite list
	 * @param e
	 */
	removeFromFavorites(e) {
		const target = $(e.currentTarget).prev().data('module');
		const itemId = target;
		$(target).remove();

		removeFromFavorites(itemId);
	}
	/**
	 * Handler for hiding the favorite list
	 * @param e
	 */
	hideFavoritesMenu(e) {
		if( e ){
			var target = $(e.currentTarget);
			if (target.attr('id') === 'cw_favorites') {
				return;
			}
		}
		$(".cw_favorites_modules").addClass("hide");
	}
	/**
	 * Handler for loading a module when clicking on a favorite item
	 * @param e
	 */
	onFavoriteItemClick(e) {
		var target = $(e.currentTarget).data('module');
		var length = Favorites.length;
		var item;
		this.hideFavoritesMenu();
		for (let i = 0; i < length; i++) {
			if (target === Favorites[i].item.application) {
				item = Favorites[i];
				item.module = item.id;
				if (item.id === 'asset_health') {
					item.module = 'assethealth';
				}
				if (item.item.config) {
					if (item.item.config.parentBreadcrumb) {
						item.module = item.item.config.parentBreadcrumb.name.toLowerCase();
					}
				}
			}
		}
		if ($('#' + item.module + '_submenu').length) {
			this.openSubmenu(item.module + '_submenu', item.module, false);
		} else {
			var menuId = item.module + '_submenu';
			this.backToMenu(menuId, true);
		}
		if (item.item.config && item.item.config.parentBreadcrumb) {
			var breadcrumbConfig = {...item.item.config.parentBreadcrumb};
			breadcrumbConfig.text = breadcrumbConfig.name;
			this.breadcrumb.setModule(breadcrumbConfig);
		} else {
			this.breadcrumb.setModule(item.breadcrumbConfig);
		}
		$('#' + item.id).addClass('current');
		if (item.item.reactModule) {
			switch (target) {
				case 'Application':
					State.mainApp.navigate('#/applications');
					break;
				case 'Cost':
					State.mainApp.navigate(CostsRouter.listByType('cost-model'), {navigationAction: 'clear'});
					break;
				case 'DataRegistry':
					State.mainApp.navigate('#' + ManagementRouter.dataRegistryList());
					break;
			}
		} else {
			switch (target) {
				case 'ManagementAgentDetails':
					State.mainApp.loadModule('ManagementServer');
					break;
				case 'Dashboard':
					State.mainApp.navigate(DashboardsRouter.list(), {navigationAction: 'clear'});
					break;
				default:
					State.mainApp.loadModule(target);
			}
		}
	}
	/**
	 * Subscribes to server Notification events
	 */
	subscribe() {
		if (ApplicationState.standalone) {
			return
		}

		if (typeof State.includeSubaccounts === 'undefined') {
			State.includeSubaccounts = true;
		}
		if (this.session.hasRole('ACCOUNT_LIST')) {
			var subscriptionObj = [{
				eventType: 'AccountSummary',
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: State.includeSubaccounts
			}, {
				eventType: 'Notification',
				userId: Cookies.CeesoftUserId,
				notificationType: 'MESSAGE'
			}];
			if (this.session.hasRole('SYSTEM_ERROR_LIST')) {
				subscriptionObj.push({
					eventType: 'Notification',
					notificationType: 'SYSTEM_ERROR',
					includeSubaccounts: State.includeSubaccounts
				});
			}
			if (this.session.hasRole('SERVER_MENU')) {
				subscriptionObj.push({
					eventType: 'AgentState',
					serverAgent: true
				});
			}
			/*
			* This is used for accountPicker
			* */
			subscriptionObj.push({
				eventType: 'Administration',
				entityType: 'ACCOUNT'
			});

			subscriptionObj.push({
				eventType: 'EventSummaryCounter',
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: State.includeSubaccounts
			});
			RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
		}
	}

	_onNavigateCallbacks = [];
	registerForOnNavigate(callback){
		this._onNavigateCallbacks.push(callback);
	}

	executeOnNavigateCallbacks(){
		this._onNavigateCallbacks.forEach(x => x());
		this._onNavigateCallbacks = [];
	}

	markDirty(dirty = true){
		this._genericDirtyCheck.markDirty(dirty);
	}
	/**
	 * Destroy
	 */
	destroy() {
		RemoteEventsManager.unsubscribe(this.subscriberId);
		RemoteEventsManager.closeConnection();
		this.saveUserPreferences();
	}

	reloadCurrentModule(){
		if(this._runtime.moduleDescription){
			this.setMainModule(this._runtime.moduleDescription,
				this._runtime.configuration);
		}else {
			this.loadModule(this._runtime.configuration);
		}
	}

	onProfileIconClick(e) {
		e.stopPropagation();
		const userId = Cookies.CeesoftUserId;
		State.mainApp.navigate(UserRouter.profile(userId), {
			navigationAction: NavigationAction.PopOne
		});
	}
}

export default MainApplication;
