import {State} from "tools/state";
import {Cookies} from "core/cookies";
import {ApplicationState} from "framework/applicationState";
import {Permission} from "framework/permission";

const root = '/agents';

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Management/AgentDetails.jsp',
			url: root + '/:id',
			bodyCssClass: 'cw_management_module',
			getModuleImport: () => import('../agentDetails'),
			routeId: 'management_agents_details'
		}
	];
}

export class AgentsRouter {
	static details(agentId) {
		return root + '/' + agentId;
	}
}

export class AgentSecurity{
	static canEdit(agentId){
		if (!State.mainApp.session.hasRole("AGENT_UPDATE"))
			return false;

		if (agentId == Cookies.serverId && !State.mainApp.session.hasRole("SERVER_MENU"))
			return false;

		return true
	}

	static canRead(agentId){
		return ApplicationState.hasPermissions(Permission.AgentRead)
	}
}
