import './designerToolbar.less'

import {observer} from "mobx-react";
import React from "react";

import {DesignerContext} from "controls/designer/designerStore";


export const b = require('b_').with('designer-toolbar')


export const DesignerToolbar = observer(() => {
	const store = React.useContext(DesignerContext);
	if(!store.featuresLoaded)
		return null;

	let hasItemBeforeSeparator = false;

	return <ul className={b()}>
		{store.toolbar.items.split(" ").map( (item, index) => {
			if(item == '|') {
				if(!hasItemBeforeSeparator) {
					return null;
				}
				hasItemBeforeSeparator = false;
				return <li key={index} className={b('item', {type: 'separator'})}></li>;
			}

			const action = store.am.get(item);
			if(action == null)
				return null;

			hasItemBeforeSeparator = true;

			if(action.icon) {
				const Icon = action.icon;
				return <li key={action.id}
				           title={action.tooltip ?? action.title}
				           className={b('item', {type: 'icon',
					           toggled: action.toggledWrapper})}
				           onClick={() => action.callback(action)}><Icon/></li>;
			}

			if(action.toolbarEntryRenderer){
				return action.toolbarEntryRenderer();
			}

		}).filter(x => x)}
	</ul>
});

