import Settings from 'settings';
import {Cookies} from 'core/cookies';
import {TempData} from 'core/tempData';
import {ErrorHandler} from 'core/errorHandler';
import {Dialog, infoDialogAsync} from 'controls/dialog';
import {openTagsFormWindow} from "controls/tagsForm";
import moment from 'moment';
import CustomNotification from 'controls/customNotification';
import {CeeViewDataSource} from 'tools/ceeViewDataSource';
import {Api} from 'tools/api';
import {State} from 'tools/state';
import {setMonitorErrorTooltipText} from "../areas/assets/monitors/monitorUi";
import {AgentSecurity, AgentsRouter} from 'areas/management/agents/bundleDescription';
import {AssetsRouter} from "areas/assets/bundleDescription";
import {newGuid} from './guid'
import {redirectTo} from "tools/navigation";
import {parseDateFormat} from "./dateTimeUtils";
import translator from 'core/localization'
import {GroupConjunction, RuleDefinition} from 'controls/queryBuilder/ruleDefinition';
import {deserialize, serialize} from "serializr"

const i = translator({
  "Name exists": {
    "en": "An identical name exist on this Account, please use another name.",
    "no": "Et identisk navn finnes på denne kontoen, bruk et annet navn."
  },
  'Invalid license': {
	no: 'Ugyldig lisens'
  },
  'Invalid license message': {
	en: 'License is not valid for this Account. Contact Ceeview Support',
	no: 'Lisensen er ikke gyldig for denne kontoen. Kontakt Ceeview Support'
  }
});


export let Utils = {
	/**
	 *
	 * @param url
	 * @param method
	 * @param data
	 * @param timeout
	 * @param customTimeout
	 * @returns {Promise}
	 */
	ajaxPromise: function (url, method = 'GET', data = {}, timeout, customTimeout) {
		return new Promise((resolve, reject) => {
			const success = (result, status, obj) => {
				return resolve({result, status, obj});
			};

			const error = (result, status, obj) => {
				return reject({result, status, obj});
			};

			this.ajax(url, method, data, success, error, timeout, customTimeout)
		})
	},

	getAccountUrl(subUrl) {
		return `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}${subUrl}`
	},


		ajax: function (url, method, data, success, error, timeout, customTimeout) {
			return Api.ajax(url, method, data, success, error, timeout, customTimeout);
		},

		get: function (url) {
			return this.ajax(url, "GET", null, null, function () {
			});
		},

		post: function (url, data) {
			return this.ajax(url, "POST", JSON.stringify(data), null, function () {
			});
		},

		apply: function (destObj, sourceObj) {
			if (destObj && sourceObj && typeof sourceObj === 'object') {
				for (var p in sourceObj) {
					if (sourceObj[p] !== undefined && sourceObj[p] !== null) {
						destObj[p] = sourceObj[p];
					}
				}
			}
			return destObj;
		},
		/**
		 * Closes current opened eventsources and redirects to url
		 * @param {String} url
		 */
		redirectTo: function (url, preserveHash = false, newTab = false) {
			redirectTo(url, preserveHash, newTab);
		},
		/**
		 *    Handler function for getting index by value
		 */
		getLastAppearance: function (array, value) {
			var index = -1;
			for (var i = 0; i < array.length; i++) {
				if (array[i] === value) {
					index = i;
				}
			}
			return index;
		},
		/**
		 * Removes the # character from the color code
		 * @param {String} colorCode
		 * @return {String} colorCode
		 */
		cutHex: function (colorCode) {
			return (colorCode.charAt(0) === "#") ? colorCode.substring(1, 7) : colorCode;
		},
		/**
		 * Finds an element in an array, depending on its id
		 * @param {Array} array
		 * @param {String} id
		 * @return {Object} data If the exists, else, null.
		 */
		getFromArrayById: function (array, id) {
			for (var i = 0, size = array.size; i < size; i++) {
				if (array.data[i].id === id) {
					return array.data[i];
				}
			}
			return null;
		},
		/**
		 * Finds an element in a list, depending on its id
		 * @param {Array} array
		 * @param {String} id
		 * @return {Object} array element If the exists, else, null.
		 */
		getFromListById: function (array, id) {
			return array.find(x => x.id == id);
		},
		/**
		 * Finds a JSON object from a JSON array by key and value
		 * @param {Array} array The JSON array
		 * @param {String} key The lookup key
		 * @param {String} value The test value
		 * @return {Object} The first detected JSON object
		 */
		getFromJsonArray: function (array, key, value) {
			return array?.find(x => x[key] === value);
		},
		/**
		 * Finds a JSON index from a JSON array by key and value
		 * @param {Array} array The JSON array
		 * @param {String} key The lookup key
		 * @param {String} value The test value
		 * @return {Object} The first detected JSON object
		 */
		getIndexFromJsonArray: function (array, key, value) {
			return array?.findIndex(x => x[key] === value);
		},
		/**
		 * Gets the maximum value from an array of numbers
		 * @param {Array} array
		 * @param {String} key
		 * @return {Number} max
		 */
		getMaxFromObjectArray: function (array, key) {
			var max = 0;
			for (var i = 0, length = array.length; i < length; i++) {
				if (array[i][key] !== null && array[i][key] > max) {
					max = array[i][key];
				}
			}
			return max;
		},
		/**
		 * Gets the minimum value from an array of numbers included in objects
		 * @param {Array} array
		 * @param {String} key
		 * @return {Number} min
		 */
		getMinFromObjectArray: function (array, key) {
			var min = 9007199254740992;
			for (var i = 0, length = array.length; i < length; i++) {
				if (array[i][key] !== null && array[i][key] < min) {
					min = array[i][key];
				}
			}
			return min;
		},
		/**
		 * Method that finds an element in an hashmap, depending on its label
		 * @return {String} qsParam The correspondent value
		 */
		getQueryStringArray: function () {
			var query = window.location.search.substring(1);
			var parms = query.split('&');
			var qsParam = [];
			for (var i = 0; i < parms.length; i++) {
				var pos = parms[i].indexOf('=');
				if (pos > 0) {
					var key = parms[i].substring(0, pos);
					var val = parms[i].substring(pos + 1);
					qsParam[key] = val;
				}
			}
			return qsParam;
		},
		getKeyByValue: function (obj, value) {
				return Object.keys(obj).find(key => obj[key] === value);
		},
		/**
		 * Method that creates a guid
		 * @return {String} guid
		 */
		guid: function () {
			return newGuid();
		},
		/**
		 * Method that extracts the R from a hexa color
		 * @param {String} h
		 * @return {Number} R
		 */
		hexToR: function (h) {
			return parseInt((Utils.cutHex(h)).substring(0, 2), 16);
		},
		/**
		 * Method that extracts the G from a hexa color
		 * @param {String} h
		 * @return {Number} G
		 */
		hexToG: function (h) {
			return parseInt((Utils.cutHex(h)).substring(2, 4), 16);
		},
		/**
		 * Method that extracts the B from a hexa color
		 * @param {String} h
		 * @return {Number} B
		 */
		hexToB: function (h) {
			return parseInt((Utils.cutHex(h)).substring(4, 6), 16);
		},
		/**
		 * Tests if a value is a valid guid or not
		 * @param {String} value The value to be tested
		 * @return {Boolean} isGuid true or false
		 */
		isGuid: function (value) {
			var regex = /^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$/;
			return regex.test(value) ? true : false;
		},

		parseGuid: function (value) {
			return this.isGuid(value) ? value : '';
		},
		/**
		 * Returns the formatted local date and time converted to Kendo readable pattern.
		 * Use together with Kendo DateTimePicker Does not support timezone,
		 * milliseconds.
		 * @param {String} pattern The date pattern to convert
		 * @return {String}formattedTime The local time
		 */
		datePatternConverter: function (pattern) {

			// convert E into ddd || EEE indo ddd || EEEE into dddd (name of day)
			if (pattern.indexOf('EEEE') !== -1) {
				pattern = pattern.replace('EEEE', 'dddd');
			} else if (pattern.indexOf('E') !== -1 || pattern.indexOf('EEE') !== -1) {
				pattern = pattern.replace('EEE', 'ddd').replace('E', 'ddd');
			}
			// convert a into tt (AM/PM)
			if (pattern.indexOf('a') !== -1 || pattern.indexOf('aa') !== -1) {
				pattern = pattern.replace('aa', 'tt').replace('a', 'tt');
			}
			// remove ssss (milliseconds not supported)
			if (pattern.indexOf('ssss') !== -1) {
				pattern = pattern.replace('ssss', '').slice(0, -1);
			}
			if (pattern.indexOf('SSS') !== -1) {
				pattern = pattern.replace('SSS', '').slice(0, -1);
			}
			// remove zzz (timezone not supported)
			if (pattern.indexOf('z') !== -1 || pattern.indexOf('zzz') !== -1 || pattern.indexOf('zzzz') !== -1) {
				pattern = pattern.replace('zzzz', '').replace('zzz', '').replace('z', '');
			}

			return pattern;
		},
		/**
		 * Returns the time format converted to Kendo readable pattern. Use
		 * together with Kendo DateTimePicker
		 * @param {String} pattern The date pattern to parse
		 * @return {String} timeFormat
		 */
		getTimeFormat: function (pattern) {
			var timeFormat;
			if (pattern.indexOf('a') !== -1) {
				timeFormat = 'h:mm tt';
			} else {
				timeFormat = 'HH:mm';
			}
			return timeFormat;
		},
		/**
		 * Returns the formatted local time for widget.
		 * @param {String} pattern The date pattern to convert
		 * @param {String} widget The widget type
		 * @param {String} type The type of result expected: millisecond ||
		 * second || minute || hour || day || week || month || year
		 * @return {String}formattedTime The local time
		 */
		widgetDateFormat: function (pattern, widget, type) {
			var result = '', month, year, separator;
			if (pattern.indexOf('MMMM') !== -1) {
				month = '%B';
			} else if (pattern.indexOf(' MMM ') !== -1) {
				month = '%b';
			} else {
				month = '%m';
			}
			if (pattern.indexOf('yyyy') !== -1) {
				year = '%Y';
			} else {
				year = '%y';
			}
			if (pattern.indexOf('-') !== -1) {
				separator = '-';
			} else if (pattern.indexOf('/') !== -1) {
				separator = '/';
			} else {
				separator = ' ';
			}

			switch (widget) {
				case 'kpiHistory':
				case 'slaHistory':
					result = parseDateFormat(pattern).replace('yyyy', '').replace('yy', '').replace('- ', ' ').replace(' -', ' ').replace(' zzz', '');
					break;
				case 'metric':
					switch (type) {
						case 'millisecond':
							result = '%M:%S';
							break;
						case 'second':
							result = '%M:%S';
							break;
						case 'minute':
							if (pattern.indexOf('a') !== -1) {
								result = '%I:%M %P';
							} else {
								result = '%H:%M';
							}
							break;
						case 'hour':
							if (pattern.indexOf('a') !== -1) {
								result = '%I:%M %P';
							} else {
								result = '%H:%M';
							}
							break;
						case 'day':
							if (pattern.indexOf('E') !== -1) {
								if (pattern.indexOf(',') !== -1) {
									result = '%a, %d %b';
								} else {
									result = '%a %b %d';
								}
							} else {
								if (pattern.indexOf('MM') < pattern.indexOf('dd')) {
									result = month + separator + '%d';
								} else {
									result = '%d' + separator + month;
								}
							}
							break;
						case 'week':
							if (pattern.indexOf('E') !== -1) {
								if (pattern.indexOf(',') !== -1) {
									result = '%a, %d %b';
								} else {
									result = '%a %b %d';
								}
							} else {
								if (pattern.indexOf('MM') < pattern.indexOf('dd')) {
									result = month + separator + '%d';
								} else {
									result = '%d' + separator + month;
								}
							}
							break;
						case 'month':
							if (pattern.indexOf('yy') < pattern.indexOf('mm')) {
								result = year + separator + month;
							} else {
								result = month + separator + year;
							}
							break;
						case 'year':
							if (pattern.indexOf('yyyy') !== -1) {
								result = '%Y';
							} else {
								result = '%y';
							}
							break;
					}
					break;
			}

			return result;
		},
		/**
		 * Returns a string of the date parameter in the desired format
		 * @param {Object} date
		 * @param {String} format The return pattern
		 * @return {String} formattedTime for messages
		 */
		customDateToString: function (date, format) {
			var time, formattedTime;
			time = moment(date);
			formattedTime = time.format(parseDateFormat(format));
			return formattedTime;
		},

		changeDateFilterToTimestamp(filters) {
			for(let filter of filters){
				if (filter.value && typeof filter.value.getMonth === 'function') {
					filter.value = filter.value.getTime();
				} else {
					if (filter.filters) {
						for (let subFilter of filter.filters) {
							if (subFilter.value && typeof subFilter.value.getMonth === 'function') {
								subFilter.value = subFilter.value.getTime();
							}
						}
					}
				}
			}
			return filters;
		},

		changeDateFilterToString(filters) {
			for(let filter of filters){
				if (filter.value && typeof filter.value.getMonth === 'function') {
					filter.value = this.customDateToString(filter.value, 'YYYY-MM-DD HH:mm:ss');
					filter.timezone = Cookies.CeesoftTimezone;
				} else {
					if (filter.filters) {
						for (let subFilter of filter.filters) {
							if (subFilter.value && typeof subFilter.value.getMonth === 'function') {
								subFilter.value = this.customDateToString(subFilter.value, 'YYYY-MM-DD HH:mm:ss');
								subFilter.timezone = Cookies.CeesoftTimezone;
							}
						}
					}
				}
			}
			return filters;
		},

		/**
		 * Proxy function for the state color
		 *
		 * @deprecated please use getServiceQualifierColorIndex instead
		 * @param {String} state
		 * @return {Number} colorIndex
		 */
		getStateColorIndex: function (state) {
			var stateColors = {
				INACTIVE: 1,
				ERROR: 2,
				AGENT_DOWN: 2,
				WARNING: 3,
				ACTIVE: 5,
				//IN_MAINTENANCE: 6,
				SERVICE_INACTIVE: 6,
				INVALID: 6,
			};
			return stateColors[state] || 6;
		},

		getServiceQualifierColorIndex: function (state) {
			return this.getStateIndex(state);
		},

		renderExclamationMark() {
			return '<span class="glyphicons exclamation-mark"></span>';
		},

		renderWrench() {
			return '<span class="glyphicons wrench"></span>';
		},

		renderExclamationMarkHtml() {
			return $('<div></div>').addClass('exclamation-mark-container').append(
				$('<div></div>').addClass('exclamation-mark-inner-circle').append(
					$('<span></span>').addClass('exclamation-mark-symbol').text('!')
				)
			);
		},

		getStateIndex: function (state) {
			let stateColors = {
				INACTIVE: 1, // red
				WARNING: 2, // orange
				ACTIVE: 5, // green
				INVALID: 6 // gray
			};

			return stateColors[state] || 6;
		},

		compareStates: function (a, b, field, direction) {
			let x = this.getStateIndex(a[field]);
			let y = this.getStateIndex(b[field]);

			return x - y;
		},

		getStateClass: function (state) {
			var stateClass = {
				INACTIVE: 'is_critical',
				WARNING: 'is_major',
				ACTIVE: 'is_ok',
				INVALID: 'is_idle'
			};

			if (!stateClass[state]) {
				return 'is_idle';
			}

			return stateClass[state];
		},

		getStateClassFromIndex: function (state) {
			var stateClass = {
				0: 'is_critical',
				1: 'is_major',
				2: 'is_ok',
				3: 'is_idle'
			};

			if (!stateClass[state]) {
				return 'is_idle';
			}

			return stateClass[state];
		},

		getMonitorIndexClass(monitorIndex) {
			let statusClass = '';
			if (monitorIndex < 0) {
				statusClass = "is_idle";
			} else if (monitorIndex >= 0 && monitorIndex < 25) {
				statusClass = "is_critical";
			} else if (monitorIndex >= 25 && monitorIndex < 50) {
				statusClass = "is_major";
			} else if (monitorIndex >= 50 && monitorIndex < 75) {
				statusClass = "is_minor";
			} else if (monitorIndex >= 75 && monitorIndex < 101) {
				statusClass = "is_ok";
			}
			return statusClass;
		},

		/**
		 * Displays a simple dialog info
		 * @param {String} title
		 * @param {String} msg
		 * @param {Array} details
		 */
		showInfo: function (title, msg, details, grid, windowClose) {
			infoDialogAsync(title, msg, details, grid, windowClose);
		},
		/**
		 * Gets a CSS style sheet by name
		 * @param {String} name The sheet name
		 * @return {Object} sheet The style sheet
		 */
		findCssStyleSheet: function (name) {
			var sheet = {};
			for (var i = 0, length = document.styleSheets.length; i < length; i++) {
				if (document.styleSheets[i].href.indexOf(name) > -1) {
					sheet = document.styleSheets[i];
					break;
				}
			}
			return sheet;
		},
		/**
		 * Sets the a same height for 2 containers (the highest one)
		 * @param {String} first container
		 * @param {String} second container
		 */
		equalHeight: function (configSection, dataSection) {
			var configHeight = $(configSection).height();
			var dataHeight = $(dataSection).height();
			if (dataSection.indexOf('.cw_section_content') < 0) {
				configHeight += 40;
			}
			if (dataSection === '.cw_process_data') {
				configHeight += 55;
			}
			if ($(dataSection).find('.k-tabstrip')) {
				configHeight += 16;
			}
			if ($(dataSection).find('.k-tabstrip .cw_tier_top')) {
				configHeight += 40;
			}
			if (configHeight < dataHeight) {
				$(configSection).css('height', dataHeight);
			} else {
				$(dataSection).css('height', configHeight);
			}
		},
		/**
		 * Sets the a same height for 2 containers (the highest one)
		 * @param {String} first container
		 * @param {String} second container
		 */
		equalHeightWithTabs: function (configSection, dataSection) {
			var configHeight = $(configSection).height();
			var dataHeight = $(dataSection).height();
			if (dataSection.indexOf('.cw_section_content') < 0 && dataSection.indexOf('.cw_sla_data') < 0) {
				configHeight += 40;
			}
			if (configHeight < dataHeight) {
				$(configSection).css('height', dataHeight);
				$(dataSection).css('height', dataHeight);
			} else {
				$(dataSection).css('height', configHeight);
				$(configSection).css('height', configHeight);
			}
		},


		/**
		 * Gets a rule from a sheet object
		 * @param {String} selector The selector for the rule
		 * @param {Object} sheet Teh shhet object
		 * @return {object} rule The css rule object
		 */
		findRuleInCssSheet: function (selector, sheet) {
			var rule = null, selectorText, i, length;
			if (sheet.cssRules) {
				for (let i = 0, length = sheet.cssRules.length; i < length; i++) {
					selectorText = sheet.cssRules[i].selectorText;
					if (selectorText && selectorText.indexOf(selector) > -1) {
						rule = sheet.cssRules[i];
						break;
					}
				}
			} else {
				for (let i = 0, length = sheet.rules.length; i < length; i++) {
					selectorText = sheet.rules[i].selectorText;
					if (selectorText && selectorText.indexOf(selector) > -1) {
						rule = sheet.rules[i];
						break;
					}
				}
			}
			return rule;
		},
		onLiveChange: function (input, preview, placeholder) {
			$(input).off('keyup mouseup mousemove').on('keyup mouseup mousemove', function (e) {
				var value = $(e.currentTarget).val();
				if (value) {
					$(preview).text(value);
				} else {
					$(preview).text(placeholder);
				}
			});
		},
		fixedCharCodeAt: function (str, idx) {
			// ex. fixedCharCodeAt ('\uD800\uDC00', 0); // 65536
			// ex. fixedCharCodeAt ('\uD800\uDC00', 1); // 65536
			idx = idx || 0;
			var code = str.charCodeAt(idx);
			var hi, low;
			if (0xD800 <= code && code <= 0xDBFF) { // High surrogate (could
				// change last hex to 0xDB7F
				// to treat high private
				// surrogates as single
				// characters)
				hi = code;
				low = str.charCodeAt(idx + 1);
				if (isNaN(low)) {
					throw 'High surrogate not followed by low surrogate in fixedCharCodeAt()';
				}
				return ((hi - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000;
			}
			if (0xDC00 <= code && code <= 0xDFFF) { // Low surrogate
				// We return false to allow loops to skip this iteration since
				// should have already handled high surrogate above in the
				// previous iteration
				return false;
			}
			return code;
		},
		removeFromArrayByKey: function (array, key, value) {
			var i, length, found = false;
			for (i = 0, length = array.length; i < length; i++) {
				if (array[i][key] === value) {
					found = true;
					break;
				}
			}
			if (found) {
				array.splice(i, 1);
			}
		},
		/**
		 * Gets the grid column preferences
		 * @param {kendoCustomGrid} grid
		 * @return {Object} columns
		 */
		getGridColumns: function (grid) {
			var columns = {}, column;
			for (var i = 0; i < grid.columns.length; i++) {
				column = grid.columns[i];
				columns[column.field] = {
					hidden: Boolean(column.hidden),
					index: i
				};
				if (column.width !== null && column.width !== undefined) {
					columns[column.field].width = column.width;
				}
			}
			return columns;
		},
		setGridColumns: function (grid, columns) {
			var visibleIndex = -1;
			var position = -1, width, gridColumn, colIndex, inGridIndex;
			for (var column in columns) {
				if (!columns[column].hidden) {
					grid.showColumn(column);
					++visibleIndex;

				} else {
					grid.hideColumn(column);
				}
				width = columns[column].width;
				if (width) {
					gridColumn = Utils.getFromJsonArray(grid.columns, 'field', column);
					if (!gridColumn) {
						continue;
					}
					gridColumn.width = width; // This sets value, while rest redraws
					colIndex = grid.detailTemplate ? visibleIndex + 1 : visibleIndex;
					grid.thead.prev().find('col:eq(' + colIndex + ')').width(width);
					grid.table.find('>colgroup col:eq(' + colIndex + ')').width(width);
				}
				//reorder
				inGridIndex = -1;
				for (var i = 0; i < grid.columns.length; i++) {
					if (grid.columns[i].field === column) {
						inGridIndex = i;
						break;
					}
				}
				position++;
				if (grid.columns[inGridIndex]) {
					grid.reorderColumn(position, grid.columns[inGridIndex]);
				}
			}
		},
		/**
		 * Updates the column filter indicators for a given grid
		 * @param {kendoCustomGrid} grid
		 */
		updateGridFilterIndicators: function (grid) {
			var setFilteredMembers = function (filter, members) {
				if (filter.filters) {
					for (var i = 0; i < filter.filters.length; i++) {
						setFilteredMembers(filter.filters[i], members);
					}
				} else {
					members[filter.field] = true;
				}
			};
			var filter = grid.dataSource.filter();
			grid.thead.find('.cw_filtered_column').removeClass('cw_filtered_column');
			if (filter) {
				var filteredMembers = {};
				setFilteredMembers(filter, filteredMembers);
				grid.thead.find('th[data-field]').each(function () {
					var cell = $(this);
					var filtered = filteredMembers[cell.data('field')];
					if (filtered) {
						cell.addClass('cw_filtered_column');
					}
				});
			}
		},

	restoreScrollPos: function (grid) {
		if (grid.options.cacheScrollPosition) {
			//timeout necessary for grid scroll to be created
			setTimeout($.proxy(function () {
				//restore grid scroll position when coming back from breadcrumb
				if (State.dynamicGridPos) {
					if (!State.gridPosLoaded && State.isFromBreadcrumb) {
						grid.wrapper.find(".k-scrollbar").scrollTop(State.dynamicGridPos);
						State.gridPosLoaded = true;
					}
					if (State.isFromEvent) {
						grid.wrapper.find(".k-scrollbar").scrollTop(State.dynamicGridPos - 1);
						State.isFromEvent = false;
					}
				}
			}, this), 0);
		}
	},
		/**
		 * Add Kendo tooltip to the header of the columns for a given grid
		 * @param {kendoCustomGrid} grid
		 */
		gridColumnHeaderTooltip: function (grid) {
			if (grid && grid.thead) {
				grid.thead.kendoTooltip({
					filter: 'th',
					width: '150',
					show: function (e) {
						var target = e.target;
						if (this.content.text().length > 1) {
							this.content.parent().css("visibility", "visible");
						} else {
							this.content.parent().css("visibility", "hidden");
						}
					},
					hide: function (e) {
						this.content.parent().css("visibility", "hidden");
					},
					content: function (e) {
						var target = e.target;
						return $(target).attr('data-title');
					}
				});
			}
		},
		get15nextHour: function (hour, min) {
			if (min === '45') {
				if (hour === '23') {
					return '00';
				} else {
					var nextHour = parseInt(hour, 10);
					nextHour++;
					if (nextHour < 10) {
						return '0' + nextHour;
					} else {
						return nextHour.toString();
					}
				}
			}
			return hour;
		},
		get15nextMinute: function (min) {
			var nextMin = '00';
			switch (min) {
				case '00':
					nextMin = '15';
					break;
				case '15':
					nextMin = '30';
					break;
				case '30':
					nextMin = '45';
					break;
			}
			return nextMin;
		},
		get15prevHour: function (hour, min) {
			if (min === '00') {
				if (hour === '00') {
					return '23';
				} else {
					var prevHour = parseInt(hour, 10);
					prevHour--;
					if (prevHour < 10) {
						return '0' + prevHour;
					} else {
						return prevHour.toString();
					}
				}
			}
			return hour;
		},
		get15prevMinute: function (min) {
			var prevMin = '00';
			switch (min) {
				case '00':
					prevMin = '45';
					break;
				case '45':
					prevMin = '30';
					break;
				case '30':
					prevMin = '15';
					break;
			}
			return prevMin;
		},
		onFilterDropDownChange: function (e) {
			e.sender.element.closest('form').find('button').first().trigger('click');
		},
		setPlaceholder: function (container, message) {
			if(container.is("input")) {
				container.attr("placeholder", message);
			}
		},
		setInvalidField: function (value, container, isForm, cssClass, notRequired) {
			const className = cssClass || 'required';
			if (value?.trim() === '' && (notRequired !== true)) {
				container.parent().addClass(isForm ? 'required_form' : className);
			} else {
				container.parent().removeClass(isForm ? 'required_form' : className);
			}
		},

		checkIfNameExists: async function (url, name, modalNotification, isStatic, initialName) {
			let result, arrayLength;
			name = name.trim();
			initialName = initialName?.trim();
			if (initialName?.toUpperCase() !== name.toUpperCase()) {
				if (isStatic) {
					url = url + encodeURIComponent(name);
					result = await Api.fetch(url);
					arrayLength = result.data ? result.data.length : result.length;
				} else {
					let filters = [{
						field: "name",
						operator: "eq",
						value: name
					}];
					let filter = {
						filters,
						logic: "and"
					}
					result = await Api.fetchPost(url, {filter});
					arrayLength = result.items.length;
				}
				if (arrayLength > 0) {
					modalNotification.setOptions({
						message: i('Name exists'),
						status: 'error'
					}).show();
					return true;
				}
			}
			return false;
		},
		checkIfMonitorExists: async function(name, type, notification, initialName) {
			let rule = RuleDefinition.emptyRule('name', 'equal');
			let group = RuleDefinition.emptyGroup();
			rule.properties.value = [name];
			group.addOrUpdateRule(rule);
			group.properties.conjunction = GroupConjunction.And;

			if (initialName?.toUpperCase() !== name.toUpperCase()) {
				let filterObj = {
					monitorType: type,
					filter: serialize(group)
				};
				delete filterObj.filter.properties.field;
				delete filterObj.filter.properties.operator;
				for (let item in filterObj.filter.children1) {
					delete filterObj.filter.children1[item].properties.conjunction;
					delete filterObj.filter.children1[item].properties.not;
				}
				let monitors = await Api.fetchPost(`${Api.accountRoot()}monitors/search/lite?includeSubaccounts=false`, filterObj);
				if(monitors.items.length > 0) {
					notification.setOptions({
						message: i('Name exists'),
						status: 'error'
					}).show();
					return true;
				}
			}
			return false;
		},
		/**
		 * Displays the amount of bytes in human readable format
		 * @param {Number} bytes
		 * @return {String} humanized
		 */
		humanizeBytes: function (bytes) {
			if (bytes === 0) {
				return '0 MB';
			}
			var units = ['bytes', 'kb', 'MB', 'GB', 'TB', 'PB'];
			var e = Math.floor(Math.log(bytes) / Math.log(1024));
			var size = (bytes / Math.pow(1024, Math.floor(e))).toFixed(2);
			var unit = units[e];
			return size + ' ' + unit;
		},
		/**
		 * Abbreviates large numbers (ex: 2000 -> 2k)
		 * @param {number} value
		 * @return {string} newValue
		 */
		abbreviateNumber: function (value) {
			var newValue = value,
				precision, suffixes, suffixNum, shortValue, dotLessShortValue, shortNum;
			if (value >= 1000) {
				suffixes = ['', 'k', 'm', 'b', 't'];
				suffixNum = Math.floor(('' + value).length / 3);
				shortValue = '';
				for (precision = 2; precision >= 1; precision--) {
					shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
					dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
					if (dotLessShortValue.length <= 2) {
						break;
					}
				}
				if (shortValue % 1 !== 0) {
					shortNum = shortValue.toFixed(1);
				}
				newValue = shortValue + suffixes[suffixNum];
			}
			return newValue;
		},
		/**
		 * Custom compare function for grid columns
		 * @param {Object} row1 The row object of the first value to compare
		 * @param {type}
		 * @param {type} field
		 * @param {type} grayValue
		 * @param {type} dir
		 * @return {Number}
		 */
		customCompare: function (row1, row2, field, grayValue, dir) {
			if (row1[field] === row2[field]) {
				return 0;
			}
			if (dir === 'asc') {
				if (row1[field] === grayValue) {
					return 1;
				}
				if (row2[field] === grayValue) {
					return -1;
				}
			} else {
				if (row1[field] === grayValue) {
					return -1;
				}
				if (row2[field] === grayValue) {
					return 1;
				}
			}
			return row1[field] - row2[field];
		},
		/**
		 * Compare if two arrays are equal, even having different element order
		 * @param {Array} a The first array
		 * @param {Array} b The second array
		 * @return {Boolean} isEqual True if the arrays are equal, false otherwise
		 */
		areSimilarArrays: function (a, b) {
			if (a.length !== b.length) {
				return false;
			}
			a.sort();
			b.sort();
			for (var i = 0, length = a.length; i < length; i++) {
				if (a[i] !== b[i]) {
					return false;
				}
			}
			return true;
		},
		/*
		 * Transform period in seconds
		 * @param {Object} Period object: startDate, endDate, width
		 * @return {Number} Milliseconds
		 */
		getPeriodInterval: function (obj, factStart) {
			let returnObj = {}, interval;
			if (factStart) {
				interval = parseInt((new Date().getTime() - factStart) / obj.width, 10);
			}
			switch (obj.period) {
				case 'LAST30DAYS':
					returnObj.interval = interval || parseInt(30 * 24 * 60 * 60 * 1000 / obj.width, 10);
					break;
				case 'LAST7DAYS':
					returnObj.interval = interval || parseInt(7 * 24 * 60 * 60 * 1000 / obj.width, 10);
					break;
				case 'LASTDAY':
					returnObj.interval = interval || parseInt(24 * 60 * 60 * 1000 / obj.width, 10);
					break;
				case 'LASTHOUR':
					returnObj.interval = interval || parseInt(60 * 60 * 1000 / obj.width, 10);
					break;
				case 'CUSTOM':
					returnObj.startDate = obj.startDate < factStart ? new Date(factStart) : new Date(obj.startDate);
					returnObj.endDate = new Date(obj.endDate);
					returnObj.interval = parseInt((returnObj.endDate.getTime() - returnObj.startDate.getTime()) / obj.width, 10);
					break;
			}

			return returnObj;
		},
		/**
		 * Replaces in an URL string the value of a given parameter
		 * @param {String} url
		 * @param {String} paramName
		 * @param {String} paramValue
		 * @return {String} the new url
		 */
		replaceUrlParam: function (url, paramName, paramValue) {
			var pattern = new RegExp('(' + paramName + '=).*?(&|$)');
			var newUrl = url;
			if (url.search(pattern) >= 0) {
				newUrl = url.replace(pattern, '$1' + paramValue + '$2');
			}
			else {
				newUrl = newUrl + (newUrl.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
			}
			return newUrl;
		},
		/*
		 * Check kendoWindow position if outsite painting area
		 * @param {Object} event The window drag end event
		 * @param {Object} area The area jQuery object
		 */
		checkWindowPosition: function (event, area) {
			var wrapper = event.sender.wrapper, wrapperOffset = wrapper.offset(), width, height, top, left;
			var areaOffset = area.offset();
			var heightGap = 32;

			top = wrapperOffset.top;
			left = wrapperOffset.left;
			if (wrapperOffset.top < areaOffset.top) {
				top = areaOffset.top + 5;
			}
			width = wrapper.width();
			height = wrapper.height();
			if (wrapperOffset.left + width > areaOffset.left + area.width()) {
				left = areaOffset.left + area.width() - width - 8;
			}

			if (wrapperOffset.left < areaOffset.left) {
				left = areaOffset.left + 5;
			}

			//if (wrapperOffset.top > area.height() - wrapper.height()) {
			if (wrapperOffset.top + height + heightGap > areaOffset.top + area.height()) {
				top = areaOffset.top + area.height() - height - heightGap;
			}

			event.sender.options.position.top = top;
			event.sender.options.position.left = left;

			wrapper.offset({
				top: top,
				left: left
			});
		},
		/**
		 * Returns the color index when severity is given
		 * @param {Number} severityIndex
		 * @return {Number} colorIndex
		 */
		severityToColor: function (severityIndex) {
			//var colors= [1,2,3,5,0,0,6];
			var colors = [5, 3, 2, 1, 0, 0, 6];
			return colors[severityIndex];
		},
		/**
		 * Restarts the agent
		 * @param {Object} obj Configuration object
		 * @property: cache, values: true, false
		 * @property: agentId,
		 * @property: appendToElement
		 */
		onAgentRestart: function (obj) {
			var url = Settings.serverPath + 'agents/restart?cleanCache=' + (obj.cache || false);
			var agents = obj.agents;

			Utils.ajax(url, 'POST', JSON.stringify(agents), function (result) {
				var status, message;
				if (result.success) {
					status = 'success';
					message = lang.agents.messages.AGENTS_REQUEST;
				} else {
					status = 'error';
					message = result.message;
				}

				this.actionNotification = new CustomNotification({
					appendToElement: obj.appendToElement,
					message: message,
					status: status
				}).show();
			});
		},
		/**
		 * Get week of month
		 * @param {Number} time Timestamp
		 * @return {Number} Number of week in a month
		 */
		getWeekOfMonth: function (time) {
			return moment(time).week();
		},
		htmlEscape: function (str) {
			return String(str).replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
		},
		htmlUnescape: function (value) {
			return String(value).replace(/&quot;/g, '"').replace(/&#39;/g, "'").replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
		},
		timer: function (callback) {
			return {
				/*
				 * milliseconds
				 */
				delay: 1000,
				start: new Date().getTime(),
				time: 0,
				elapsed: '0',
				destroyMethod: false,
				callback: false,
				/*
				 * Delay: seconds
				 */
				init: function (delay) {
					this.delay = delay * 1000;
					window.setTimeout($.proxy(this.instance, this), this.delay);

					return this;
				},
				instance: function () {
					var diff;
					this.time += this.delay;

					this.elapsed = Math.floor(this.time / this.delay);

					diff = (new Date().getTime() - this.start) - this.time;

					if (!this.destroyMethod) {
						window.setTimeout($.proxy(this.instance, this), (this.delay - diff));

						if (typeof callback === 'function') {
							callback.call(this, this.elapsed);
						}
					}
				},
				destroy: function () {
					this.destroyMethod = true;
				}
			};
		},
		/**
		 * Gets the GUIDs of the records that are checked in a grid
		 * @param {grid} grid A grid with checkboxes having the class cw_grid_check
		 * @return {Array} states An array with the GUIDs of the checked records
		 */
		getCheckboxStates: function (grid) {
			if (!grid || !grid.element) {
				return [];
			}

			var states = [];
			var checkboxes = $(grid.element).find('.cw_grid_check:checked');
			for (var i = 0; i < checkboxes.length; i++) {
				states.push($(checkboxes[i]).attr('data-id'));
			}
			return states;
		},
		/**
		 * Restore the state of checked revords in a grid
		 * @param {grid} grid The grid to be processed
		 * @param {Array} states  An array with the GUIDs of the checked records
		 */
		setCheckboxStates: function (grid, states) {
			var checkboxes = $(grid.element).find('.cw_grid_check');
			for (var i = 0; i < checkboxes.length; i++) {
				var id = $(checkboxes[i]).attr('data-id');
				if (states.indexOf(id) > -1) {
					$(checkboxes[i]).prop('checked', true);
				}
			}
		},
		/*
		 * Handler function for creating the grid columns2
		 * @param {Object} columns The (processed) columns from user preferences
		 * @param {Object} defaultColumns The default columns (this.defaultColumns)
		 * */
		completeColumns: function (columns, defaultColumns) {
			var newColumns = [];
			var columns = $.extend({}, columns), columnsIndex = Object.keys(columns);
			var defaultColumns = $.extend({}, defaultColumns), defaultColumnsIndex = Object.keys(defaultColumns);

			var column;
			for (var i = 0, length = columnsIndex.length; i < length; i++) {
				column = columnsIndex[i];
				if (defaultColumns[column]) {
					newColumns.push($.extend({column: column}, columns[column]));
				}
			}

			for (var i = 0, length = defaultColumnsIndex.length; i < length; i++) {
				column = defaultColumnsIndex[i];
				if (!columns[column]) {
					newColumns.splice(i, 0, $.extend({column: column}, {
						added: true,
						width: defaultColumns[column].width,
						hidden: defaultColumns[column].hidden,
						index: i
					}));
					for (let j = i + 1; j < newColumns.length; j++) {
						if (newColumns[j].index) {
							newColumns[j].index++;
						}
					}
				}
			}

			var newColumnsObj = {}, newColObj;
			for (var newCol in newColumns) {
				newColObj = newColumns[newCol];
				newColumnsObj[newColObj.column] = {
					width: newColObj.width,
					hidden: newColObj.hidden,
					index: newColObj.index
				};
				if (newColObj.width === undefined) {
					delete newColumnsObj[newColObj.column].width;
				}
			}

			return newColumnsObj;
		},

		rearrangeColumns: function (gridColumns, preferencesColumns) {
			if (preferencesColumns == null)
				return gridColumns;

			preferencesColumns = JSON.parse(JSON.stringify(preferencesColumns));

			let preferencesArray = [];
			const newColumns = [];

			for (let columnName in preferencesColumns) {
				let column = preferencesColumns[columnName];
				column.name = columnName;
				if (column.index !== undefined) {
					preferencesArray[column.index] = column;
				} else {
					newColumns.push(column);
				}
			}

			preferencesArray = preferencesArray.concat(newColumns);

			//if there is an Id column then move it to the first position
			let idIndex = preferencesArray.findIndex(x => x?.name == 'id');
			if (idIndex != -1) {
				let idColumn = preferencesArray[idIndex];
				if (!idColumn.hidden && idColumn.index != 0) {
					preferencesArray.splice(idIndex, 1);
					preferencesArray.forEach(x => x.index++);
					preferencesArray.unshift(idColumn);
					idColumn.index = 0;
				}
			}

			const orderedColumns = [];
			for (let columnFromPreferences of preferencesArray) {
				if (!columnFromPreferences)
					continue;

				let columnFromGrid = gridColumns.find(c => c.field == columnFromPreferences.name);
				if (columnFromGrid == null)
					continue;

				orderedColumns.push(columnFromGrid);
				if (columnFromPreferences.hidden !== undefined) {
					columnFromGrid.hidden = columnFromPreferences.hidden;
				}

				if (columnFromPreferences.width !== undefined) {
					columnFromGrid.width = columnFromPreferences.width;
				}
			}

			for (let column of orderedColumns) {
				//accountName should always be shown according to global includeSubaccounts toggle
				if (column.field === 'accountName') {
					column.hidden = !State.includeSubaccounts;
				}
			}

			//there are might be columns in gridsColumn which are not in preferences, we should show them
			for (const columnFromGrid of gridColumns) {
				const columnInResultList = orderedColumns.find(c => c.field == columnFromGrid.field);
				if (columnInResultList == null) {
					orderedColumns.push(columnFromGrid);
				}
			}

			return orderedColumns;
		},

		getHealthIndicatorStatus: function (monitorHI) {
			var statusIndicator = '6';
			if (monitorHI > -1) {
				if (monitorHI >= 0 && monitorHI < 25) {
					statusIndicator = '3';
				}
				if (monitorHI >= 25 && monitorHI < 50) {
					statusIndicator = '2';
				}
				if (monitorHI >= 50 && monitorHI < 75) {
					statusIndicator = '1';
				}
				if (monitorHI >= 75 && monitorHI < 101) {
					statusIndicator = '0';
				}
			}
			return statusIndicator;
		},
		getReadableRuleset: function (node) {
			if (!node && !node.rule) {
				return;
			}
			var j, i, readableRule = '';
			if (node.rule) {
				var ruleset = node.rule.ruleset;
				var updatedRule = ruleset.replace('(', ' ( ');
				updatedRule = updatedRule.replace(')', ' ) ');
				var ops = updatedRule.substr(2, updatedRule.length - 3).split(' ');
				var serviceQualifiers = node.qualifiers;
				var nodeChildren = node.getFirstLevelChildrenNodes();
				for (let i = 0; i < ops.length; i++) {
					var op = ops[i];
					if (op && op.substr(0, 2) === 'sq') {
						var sqId = op.substr(4, op.length - 6);
						for (j = 0; j < serviceQualifiers.length; j++) {
							if (serviceQualifiers[j].id === sqId) {
								//sqId: sqId
								readableRule += '<span class="cw_rule_sq_text">' + serviceQualifiers[j].name + '</span>';

							}
						}
					} else if (op && op.substr(0, 2) === 'se') {
						var seId = op.substr(4, op.length - 6);
						for (j = 0; j < nodeChildren.length; j++) {
							if (nodeChildren[j].id === seId) {
								readableRule += '<span class="cw_rule_se_text">' + nodeChildren[j].name + '</span>';
							}
						}
					} else {
						switch (op) {
							case 'and':
								readableRule += '<span class="cw_rule_operator"> ' + lang.AND + ' </span>';
								break;
							case 'or':
								readableRule += '<span class="cw_rule_operator"> ' + lang.OR + ' </span>';
								break;
							case 'not':
								readableRule += '<span class="cw_rule_operator">  ' + lang.NOT + ' </span>';
								break;
							case '(':
								readableRule += '<span class="cw_rule_paranthesis"> ( </span>';
								break;
							case ')':
								readableRule += '<span class="cw_rule_paranthesis"> ) </span>';
								break;
						}
					}
				}
			}

			return readableRule;
		},
		getReadableRulesetDiagram: function (node) {
			if (!node && !node.rule) {
				return;
			}
			var j, i, readableRule = '';
			if (node.rule && node.rule.ruleset) {
				var ruleset = node.rule.ruleset;
				var updatedRule = ruleset.replace('(', ' ( ');
				updatedRule = updatedRule.replace(')', ' ) ');
				var ops = updatedRule.substr(2, updatedRule.length - 3).split(' ');
				var serviceQualifiers = node.serviceQualifiers;
				var nodeChildren = State.currentApp.workflow.getFirstLevelChildrenNodes(node);
				for (let i = 0; i < ops.length; i++) {
					var op = ops[i];
					if (op && op.substr(0, 2) === 'sq') {
						var sqId = op.substr(4, op.length - 6);
						for (j = 0; j < serviceQualifiers.length; j++) {
							if (serviceQualifiers[j].id === sqId) {
								//sqId: sqId
								readableRule += '<span class="cw_rule_sq_text">' + serviceQualifiers[j].name + '</span>';

							}
						}
					} else if (op && op.substr(0, 2) === 'se') {
						var seId = op.substr(4, op.length - 6);
						for (j = 0; j < nodeChildren.length; j++) {
							if (nodeChildren[j].id === seId) {
								readableRule += '<span class="cw_rule_se_text">' + nodeChildren[j].name + '</span>';
							}
						}
					} else {
						switch (op) {
							case 'and':
								readableRule += '<span class="cw_rule_operator"> ' + lang.AND + ' </span>';
								break;
							case 'or':
								readableRule += '<span class="cw_rule_operator"> ' + lang.OR + ' </span>';
								break;
							case 'not':
								readableRule += '<span class="cw_rule_operator">  ' + lang.NOT + ' </span>';
								break;
							case '(':
								readableRule += '<span class="cw_rule_paranthesis"> ( </span>';
								break;
							case ')':
								readableRule += '<span class="cw_rule_paranthesis"> ) </span>';
								break;
						}
					}
				}
			}

			return readableRule;
		},
		/*
		 * Handler function for getting the index from severity datasource by value
		 * */
		getSeverityIndex: function (value, dataSource) {
			for (var i = 0, length = dataSource.length; i < length; i++) {
				if (value === dataSource[i].value) {
					return i;
				}
			}

			return false;
		},
		/*
		* Handler function for setting tags
		* @param {String} type Type of view where the tags will be used
		* @param {Object} gridScope The view scope
		* */
		openTagsFormWindow: function (type, viewScope) {
			openTagsFormWindow(type, viewScope);
		},
		/**
		 * Handler funtion for resetting monitors health index
		 * @param {Object} monitors Array of objects
		 * @property: {monitorId, assetId}
		 * @param {Function} callback Callback function
		 */
		resetMonitorsHI: function (monitors, callback) {
			if (monitors.length) {
				var dialog = new Dialog({
					title: lang.assethealth.RESET_HI,
					actionText: 'RESET',
					headerIcon: 'question-sign',
					headerMouseover: lang.assethealth.RESET_HI_MOUSEOVER,
					prompt: true,
					multiline: true,
					multilineCols: 75,
					resizable: true,
					dialogHeight: 170,
					placeholder: lang.RESET_HEALTH_INDEX_PLACEHOLDER,
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							if (callback) {
								callback.call(this);
							}
							var data = {
								user: Cookies.CeesoftUsername,
								description: value || '',
								monitors: monitors
							};
							var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/resetHealthIndexes';
							Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
								if (result.success) {
									var status = $('.status');
									status.find('p').addClass('success');
									status.find('p').text(lang.assethealth.messages.HI_SUCCES_RESET);
									status.slideDown().delay(2000).slideUp();

								} else {
									Utils.showInfo(lang.ALERT, result.message, result.details);
								}
							}, this));
						}
					}, this)
				});
				dialog.show();
			}
		},
		/*
		 * Handler function for parsing url
		 * @param {String} url
		 */
		parseUrl: function (url) {
			var match = url.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)(\/[^?#]*)(\?[^#]*|)(#.*|)$/);
			return match && {
				protocol: match[1],
				host: match[2],
				hostname: match[3],
				port: match[4],
				pathname: match[5],
				search: match[6],
				hash: match[7]
			};
		},
		/**
		 * Handler function for restricting input value to digits
		 * @param e {Event} change event
		 * */
		onInputDigitRestrict: function (e) {
			/*
			 * allowed keys
			 * delete (46), backspace (8), tab (9)
			 * left arrow (38), right arrow (39)
			 *
			 * */
			if (e.which !== 46 && e.which !== 8 && e.which !== 37 && e.which !== 39 && e.which !== 9 && !(e.which >= 48 && e.which <= 57) && !(e.which >= 96 && e.which <= 105)) {
				e.preventDefault();
			}
		},
		/**
		 * Handler function for restricting input value to digits for decimal fields
		 * @param e {Event} change event
		 * */
		onDecimalInputDigitRestrict: function (e) {
			/*
			 * allowed keys
			 * delete (46), backspace (8), tab (9)
			 * left arrow (38), right arrow (39)
			 * decimal point (190)
			 *
			 * */
			if (e.which !== 190 && e.which !== 46 && e.which !== 8 && e.which !== 37 && e.which !== 39 && e.which !== 9 && !(e.which >= 48 && e.which <= 57) && !(e.which >= 96 && e.which <= 105)) {
				e.preventDefault();
			}
		},
		/**
		 * Handler function for blur event
		 * @param e {Event} blur event
		 * */
		onSliderValueBlur: function (e) {
			var target = $(e.currentTarget);
			var slider = target.closest('.cw_dropdown_container').find('input:first').data('kendoSlider');
			slider.value(target.val());
			slider.trigger('change');

			e.preventDefault();

			var clickedTarget = $(e.relatedTarget);
			if (clickedTarget.hasClass('.k-button.k-primary')) {
				setTimeout(function () {
					clickedTarget.trigger('click');
				}, 50);
			}
		},
		/**
		 * Sets multiselect value based on filter
		 * @param {kendoMultiselect} multiselect
		 * @param {String} field
		 * @param {Object} filter
		 */
		setMultiSelectValueFromFilter: function (multiselect, operator, field, filter) {
			var values = [];
			var operatorValue = 'eq';
			var processFilter = function (filter) {
				if (filter && filter.filters) {
					for (var i = 0; i < filter.filters.length; i++) {
						var currentFilter = filter.filters[i];
						if (currentFilter.filters) {
							processFilter(currentFilter);
						} else {
							if (currentFilter.field === field) {
								values.push(currentFilter.value);
								operatorValue = currentFilter.operator;
							}
						}
					}
				}
			};
			processFilter(filter);
			multiselect.value(values);
			//fix for default value
			setTimeout(function () {
				operator.data('kendoDropDownList').value(operatorValue);
			}, 100);
		},
		/*
		* Handler function for converting bytes to MB
		* @param {Number} bytes The number of bytes
		* */
		convertBytesToMb: function (bytes) {
			var mb = 0;
			if (bytes) {
				mb = (bytes / (1000 * 1000)).toFixed(0);
			}

			return mb;
		},
		/*
		* Handler function for converting MB to byes
		* @param {Number} mb The number mb
		* */
		convertMbToBytes: function (mb) {
			var bytes = 0;
			if (mb) {
				bytes = mb * 1000 * 1000;
			}

			return bytes;
		},
		/*
		* Handler function for checking the combobox value
		* @param {Object} e The databound event
		* @param {Boolean} eraseError Shows if the current error should be cleared
		* @param {Boolean} eraseErrorFields Shows if all the errors should be cleared
		* */
		comboBoxValueCheck: function (e, eraseError, eraseErrorFields) {
			/*//console.log('abc');
			if (e.sender.selectedIndex === -1) {
				e.sender.wrapper.css('border', '1px solid red');
			}*/
			if (eraseErrorFields) {
				this.errorFieldArray = [];
			} else {
				this.errorFieldArray = this.errorFieldArray || []
				var wrapper = e.sender.wrapper;
				var index = this.errorFieldArray.indexOf(wrapper);
				if (e.sender.selectedIndex === -1) {
					if (!eraseError) {
						wrapper.css('border', '1px solid red');
						if (index === -1) {
							this.errorFieldArray.push(wrapper);
						}
					}
				} else {
					wrapper.css('border', '');
					if (index > -1) {
						this.errorFieldArray.splice(index, 1);
					}
				}
			}
			return this.errorFieldArray;
		},
		/* Handler function for focusing the next combobox
		 * @param {Object} e The databound event
		 * */
		focusNextComboBox: function (e) {
			if (e.sender && e.sender.wrapper && e.sender.wrapper.context) {
				$(e.sender.wrapper.context).data('kendoComboBox').focus();
			}
		},
		/*
		* Handler function for setting the position of selected item
		* @param {Object} e The select object event
		* */
		keepMultiselectItemPosition: function (e) {
			var item = e.item, index = item.index(), values = e.sender.value();
			values.splice(index, 0, item.text());
			e.sender.value(values);
			e.preventDefault();
		},
		/*
		* Handler function for getting the config and merging it with our local settings
		* @param {Function} callback The callback function for success
		* */
		getConfig: function (callback) {
			var url = Settings.serverPath + 'sessions/config';
			this.ajax(url, 'GET', '', $.proxy(function (result) {
				if (result.success) {
					this.apply(Settings, result.data);
				}
				if (callback) {
					callback.call(this, result || {});
				}
			}, this), function () {
				window.location = Cookies.CeesoftLogoutPage || Settings.logoutPage;
			});
		},
		/*
		 * Handler function for mouse over monitor error exclamation mark
		 * @param {Object} e The mouse over event object
		 * @param {Object} options The options item details
		 * */
		onMonitorErrorOver: function (e, options) {
			const target = $(e.currentTarget);
			const isReason = target.data('reason') || options.isReason;
			const tr = target.closest('tr');

			let item = null;
			if( options.dataSource) {
				let uid = target.closest('tr').data('uid');
				item = options.dataSource.getByUid(uid) || options.dataSource.data().find(function (element) {
					return element.monitorId === options.monitorId;
				});
			}else {
				const grid = target.closest('.k-grid').data('kendoCustomGrid')
					|| target.closest('.k-treelist').data('kendoCustomTreeList');

				item = grid.dataItem(tr);
			}

			var accountId = options.accountId || item.accountId || item.accId;
			var assetId = options.assetId || item.assetId || item.targetId || item.id;

			var url = Settings.serverPath + 'accounts/' + accountId + '/assets/' + assetId + '/health/';

			var monitorType = options.monitorType || item.monitorType;
			var monitorId = options.monitorId || item.monitorId;

			if (isReason && monitorId) {
				url += 'monitors/' + monitorId + '/';
			} else if (monitorType) {
				url += 'monitors/types/' + monitorType + '/';
			}

			if (options.onlyMonitor) {
				url = Settings.serverPath + 'accounts/' + accountId + '/health/monitors/' + item.id + '/';
			}

			url += 'errorReasons';

			setMonitorErrorTooltipText(options.toolTip, item, url, target);
		},
		/*
		* Handler function for calculating width, height, top, left, exceeding the context
		* @param {Object} container The jQuery element where windows should be bound to
		* @param {Object} config The windows config object with width, height, top, left data
		* */
		getForcedWindowsConfig: function (container, config) {
			var defaults = {
				qualifiers: {
					width: null,//380,
					height: 250
				},
				preview: {
					width: null, //380,
					height: 250
				},
				statusLog: {
					width: null, //it means we let the browser calculate the size (default behaviour)
					height: 150
				},
				margin: {
					height: 90 // each window margin 30 + 30 + 30
				}
			};
			var calculate = {
				width: function (item) {
					if (item.windowName === 'statusLog') {
						return item.width > dim.width ? dim.width - 5 : item.width;
					} else {
						return item.width;
					}
				},
				height: function (item) {

				},
				top: function (item) {
					var maxTop = pos.top + dim.height;
					return item.top > maxTop ? null : item.top;
				},
				left: function (item) {
					if (item.left + item.width > pos.left + dim.width) {
						return item.left = pos.left + dim.width - item.width - 5;
					} else {
						return item.left;
					}
				}
			};

			if (container && container.length) {
				var dim = {
					width: container.width(),
					height: container.height()
				};
				var pos = container.offset();

				var item;
				for (var i = 0, length = config.length; i < length; i++) {
					item = config[i];
					item.width = calculate.width(item);
					//item.height = calculate.height(item);
					item.top = calculate.top(item);
					item.left = calculate.left(item);
				}

				var qualifiers = Utils.getFromJsonArray(config, 'windowName', 'qualifiers');
				var preview = Utils.getFromJsonArray(config, 'windowName', 'preview');
				var statusLog = Utils.getFromJsonArray(config, 'windowName', 'statusLog');
				var heightGap = 32;

				//recalculate - qualifiers,preview height
				if ((heightGap * 2) + qualifiers.height + preview.height + 15 > dim.height) {
					var height = dim.height / 2;
					qualifiers.height = height - heightGap - 2;
					preview.height = height - heightGap - 2;

					preview.top = qualifiers.top + qualifiers.height + heightGap;

					statusLog.height = height - heightGap - 2;
				}

				//recalculate - if statusLog top position is outside the visible area
				if (statusLog.top + statusLog.height > pos.top + dim.height) {
					statusLog.top = pos.top + dim.height - statusLog.height - heightGap;
					statusLog.width -= qualifiers.width + 6;
				}
			}

			return config;
		},
		/*
		* Controls if the user account is allowed to access agent data
		 */
		isAccessDenied: function (agentId) {
			return Cookies.CeesoftAccountId !== '00000000-0000-0000-0000-000000000000' && agentId === Cookies.serverId;
		},

		canEditAgent: function (agentId) {
			return AgentSecurity.canEdit(agentId)
		},

		getServerId: function () {
			let url = Settings.serverPath + 'sessions/serverId';
			this.ajax(url, 'GET', {}, $.proxy(function (result) {
				if (result.success) {
					Cookies.serverId = result.data || null;
				}
			}, this));
		},

		uploadAccountImage: function (button, success) {
			$(button).kendoUpload({
				async: {
					autoUpload: true,
					saveUrl: Api.images.urls.save(),
					saveField: 'file'
				},
				multiple: false,
				showFileList: false,
				localization: {
					select: lang.kendo.UPLOAD_SELECT,
					statusFailed: lang.kendo.UPLOAD_STATUS_FAILED,
					statusUploaded: lang.kendo.UPLOAD_UPLOADED,
					statusUploading: lang.kendo.UPLOAD_UPLOADING,
					uploadSelectedFiles: lang.kendo.UPLOAD_SELECTED_FILES
				},
				upload: function (e) {
					var xhr = e.XMLHttpRequest;
					if (xhr) {
						xhr.addEventListener("readystatechange", function (e) {
							if (xhr.readyState === 1 /* OPENED */) {
								xhr.setRequestHeader("Auth-Token", Cookies.sessionId);
							}

						});
					}
					var files = e.files;
					// Check the extension of each file and abort the upload if it is not .jpg, .jpeg or .png
					$.each(files, function () {
						if (this.extension.toLowerCase() !== ".jpg" && this.extension.toLowerCase() !== ".jpeg" && this.extension.toLowerCase() !== ".png") {
							oThis.statusNotification.setOptions({
								message: lang.account.messages.ASSET_FILE_TYPES,
								status: 'error'
							}).show();
							e.preventDefault();
						}
					});
				},
				success: success
			});
		},
		/**
		 * Formats milliseconds into human readable time
		 * @param {Number} downtime
		 * @param {Boolean} isDowntime
		 * @return {String} text
		 */
		formatDowntime: function (downtime, isDowntime) {
			var temp, seconds, minutes, hours, days, milliseconds, downtimeText = [], hoverText = [], displayedHoverText,
				noDowntime = '', text;
			var daysShown, hoursShown, minutesShown, secondsShown;
			milliseconds = downtime % 1000;
			temp = Math.floor(downtime / 1000); // seconds
			seconds = temp % 60;
			temp = Math.floor(temp / 60); // minutes
			minutes = temp % 60;
			temp = Math.floor(temp / 60); // hours
			hours = temp % 24;
			temp = Math.floor(temp / 24); // days
			days = temp;
			if (days > 0) {
				downtimeText.push(days + lang.SHORT_DAY + ' ');
				downtimeText.push(hours + lang.SHORT_HOUR + ' ');
				daysShown = true;
				hoursShown = true;
			} else {
				if (hours > 0) {
					downtimeText.push(hours + lang.SHORT_HOUR + ' ');
					downtimeText.push(minutes + lang.SHORT_MINUTE + ' ');
					hoursShown = true;
					minutesShown = true;
				} else {
					if (minutes > 0) {
						downtimeText.push(minutes + lang.SHORT_MINUTE + ' ');
						downtimeText.push(seconds + lang.SHORT_SECOND + ' ');
						minutesShown = true;
						secondsShown = true;
					} else {
						if (seconds > 0) {
							downtimeText.push(seconds + lang.SHORT_SECOND + ' ');
							secondsShown = true;
						} else {
							//round to 1 second
							if (milliseconds > 0) {
								downtimeText.push(1 + lang.SHORT_SECOND + ' ');
							}
						}
					}
				}
			}
			if (days > 0 || daysShown) {
				hoverText.push(days + lang.SHORT_DAY + ' ');
			}
			if (hours > 0 || hoursShown) {
				hoverText.push(hours + lang.SHORT_HOUR + ' ');
			}
			if (minutes > 0 || minutesShown) {
				hoverText.push(minutes + lang.SHORT_MINUTE + ' ');
			}
			if (seconds > 0 || secondsShown) {
				hoverText.push(seconds + lang.SHORT_SECOND + ' ');
			}
			if (milliseconds > 0) {
				hoverText.push(milliseconds + lang.SHORT_MILISECOND + ' ');
			}
			if (isDowntime) {
				noDowntime = lang.slas.NO_DOWNTIME;
			} else {
				noDowntime = lang.BREACHED;
			}
			text = downtimeText.length ? downtimeText.join(" ") : noDowntime;
			displayedHoverText = hoverText.length ? hoverText.join(" ") : noDowntime;
			return {
				text: text,
				hoverText: displayedHoverText
			};
		},

		isJSON: function (text) {
			try {
				JSON.parse(text);
			} catch (e) {
				return false;
			}
			return true;
		},

		applyTooltip: function () {
			jQuery(".add-tooltip").each((index, item) => {
				let $item = jQuery(item);
				let title = $item.attr('kendo-title');

				if (!$item || !title) {
					return;
				}

				$item.kendoTooltip({
					width: title.length * 6.8,
					content: title
				});
			});
		},

		timestamp: function () {
			return new Date().getTime();
		},

		/**
		 * Handler function for exporting the grid content in CSV
		 * @param [Object} grid Grid which should be exported
		 * @param {String} url Url to export the grid
		 * @param {Array} excludedColumns Columns which should not be exported
		 */
		exportGridCsv: function (grid, url, excludedColumns) {
			var fields = [];
			var titles = [];
			var excludedColumns = excludedColumns || [];
			var columns = grid.columns;
			for (var i = 0; i < columns.length; i++) {
				if (!columns[i].hidden && columns[i].field !== 'id' && excludedColumns.indexOf(columns[i].field) === -1) {
					fields.push(columns[i].field);
					titles.push(columns[i].title);
				}
			}
			var payload = {
				fields: fields,
				titles: titles,
				filter: grid.dataSource.filter(),
				sort: grid.dataSource.sort()
			};
			Utils.ajax(url, 'POST', JSON.stringify(payload), $.proxy(function (result) {
				if (result.success) {
					var fileId = result.data;
					var downloadUrl = Settings.serverPath + 'sessions/exportCSV/' + fileId;
					window.open(downloadUrl);
				}
			}, this));
		},
		/**
		 * Handler function for checking if any of the grid column name has been changed and affects filtering
		 * @param {Array} filters The existing filters on the grid
		 * @param {Array} columnChanges The changed columns
		 */
		replaceObsoleteFilters: function (filters, columnChanges) {
			for (var i = 0; i < filters.length; i++) {
				if (filters[i].field) {
					for (var j = 0; j < columnChanges.length; j++) {
						if (filters[i].field === columnChanges[j].oldName) {
							filters[i].field = columnChanges[j].newName;
						}
					}
				} else {
					if (filters[i].filters && filters[i].filters.length) {
						Utils.replaceObsoleteFilters(filters[i].filters, columnChanges);
					}
				}
			}
		},
		/**
		 * Handler function for checking if any of the grid column name has been changed and affects sorting
		 * @param {Array} sort The existing sorting on the grid
		 * @param {Array} columnChanges The changed columns
		 */
		replaceObsoleteSort: function (sort, columnChanges) {
			for (var i = 0; i < sort.length; i++) {
				for (var j = 0; j < columnChanges.length; j++) {
					if (sort[i].field === columnChanges[j].oldName) {
						sort[i].field = columnChanges[j].newName;
					}
				}
			}
		},
		/**
		 * Handler function for checking if any of the grid column name has been changed and affects columsn
		 * @param {Array} columns The existing columns on the grid
		 * @param {Array} columnChanges The changed columns
		 */
		replaceObsoleteColumns: function (columns, columnChanges) {
			for (var i = 0; i < columnChanges.length; i++) {
				let oldColumn = columnChanges[i].oldName;
				let oldColumnProperties = columns[oldColumn];
				if (oldColumnProperties) {
					columns[columnChanges[i].newName] = oldColumnProperties;
					delete columns[oldColumn];
				}
			}
		},
		getTodayDate: function () {
			var now = new Date();
			var dd = now.getDate();
			var mm = now.getMonth() + 1; //January is 0!
			var yyyy = now.getFullYear();

			if(dd < 10) {
				dd = '0' + dd
			}
			if(mm < 10) {
				mm = '0' + mm
			}
			return dd + '/' + mm + '/' + yyyy;
		},

	changeAccount: function (newAccountId, newAccountName, doNotRememberCurrentAccount) {
		let accountChanged = false;
		var timeout = Settings.COOKIE_TIMEOUT;
		if (newAccountId === Cookies.CeesoftAccountId) {
			Cookies.create('CeesoftParentAccountId', '', timeout);
			Cookies.create('CeesoftParentAccountName', '', timeout);
		} else {
			Cookies.create('CeesoftParentAccountId', Cookies.CeesoftCurrentAccountId, timeout);
			Cookies.create('CeesoftParentAccountName', Cookies.CeesoftCurrentAccountName, timeout);
		}
		if (Cookies.CeesoftCurrentAccountId != newAccountId) {
			!doNotRememberCurrentAccount && TempData.set('accountToReturnTo', {
				id: Cookies.CeesoftCurrentAccountId,
				name: Cookies.CeesoftCurrentAccountName
			});
			Cookies.create('CeesoftCurrentAccountId', newAccountId, timeout);
			Cookies.create('CeesoftCurrentAccountName', newAccountName, timeout);
			accountChanged = true;
		}

		$('.cw_account_context').find('.cw_name').text(newAccountName);
		return accountChanged;
	},

	returnToPrevAccount(){
		 let accountToReturnTo = TempData.get('accountToReturnTo');
		 accountToReturnTo && this.changeAccount(accountToReturnTo.id, accountToReturnTo.name, true);
	},

	delayExecution: function (callback, ms) {
		var timer = 0;
		return function() {
			var context = this, args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function () {
				callback.apply(context, args);
			}, ms || 0);
		};
	},

	setClickableNameListener: function (type, control) {
		setTimeout($.proxy(function() {
			let wrapper = control.wrapper;
			control.enable(true);
			wrapper.removeClass('k-disabled')
			let children = wrapper.children();
			let input = children[0];
			$(input).addClass('cw_link_elements');
			let isDisabled = $(input).hasClass('is_disabled');
			if (isDisabled || isDisabled === 'disabled') {
				$(input).removeClass('is_disabled');
				wrapper.css('background-color', '#ebebeb');
			}
			if (type === 'agent') {
				wrapper.on('click', $.proxy((e) => this.onAgentNameClick(e, control), this));
			}
			if (type === 'asset') {
				wrapper.on('click', $.proxy((e) => this.onAssetNameClick(e, control), this));
			}
			if (type === 'assetGroup') {
				wrapper.on('click', $.proxy((e) => this.onAssetGroupNameClick(e, control), this));
			}
		}, this), 0);
	},
	onAgentNameClick: function (e, control) {
		let target = $(e.target);
		if (target.hasClass('k-i-arrow-s') || target.find('span').hasClass('k-i-arrow-s')) {
			e.preventDefault();
		} else {
			let id = target.closest('.k-combobox').find('input[data-role="combobox"]').data('kendoComboBox')?.value() ?? control?.value();
			if (id) {
				State.mainApp.navigate(AgentsRouter.details(id));
			}
		}
	},
	onAssetNameClick: function (e, control, assetId) {
		let target = $(e.target);
		if (target.hasClass('k-i-arrow-s') || target.find('span').hasClass('k-i-arrow-s')) {
			e.preventDefault();
		} else {
			let id = target.closest('.k-combobox').find('input[data-role="combobox"]').data('kendoComboBox')?.value() ?? control?.value() ?? assetId;
			if (id) {
				State.mainApp.navigate(AssetsRouter.details(id))
			}
		}
	},
	onAssetGroupNameClick: function (e, control) {
		let target = $(e.target);
		if (target.hasClass('k-i-arrow-s') || target.find('span').hasClass('k-i-arrow-s')) {
			e.preventDefault();
		} else {
			let name = target.closest('.k-combobox').find('input[data-role="combobox"]').data('kendoComboBox')?.text() ?? control?.text();
			if (name) {
				let options = {
					isView: true,
					isFromAssetGroupWidget: true,
					assetGroupNameFilter: name
				};
				State.mainApp.loadModule('AssetGroupSummaryView', '', options);
			}
		}
	},
	removeNameTooltip() {
		var checkTooltip = setInterval($.proxy(function() {
			if ($('.k-tooltip-content').length) {
				$('.k-tooltip-content').parent('div').remove();
				clearInterval(checkTooltip);
			}
		}, this), 10)
	},

	aggregate(aggregationType, point) {
		switch(aggregationType) {
			case 'high': {
				if (point.vH === undefined) {
					return point.v;
				}

				return point.vH;
			}
			case 'low': {
				if (point.vL === undefined) {
					return point.v;
				}

				return point.vL;
			}
			default: {
				return point.v;
			}
		}
	},
	getAccountTags: async function () {
		let url = `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/tags`;
		let tags =  await Api.fetch(url);
		let tagsObj = [];
		for (let tag of tags.data) {
			tagsObj.push({
				text: tag,
				value: tag
			});
		}
		return Object.values(tagsObj.reduce((acc,current)=>Object.assign(acc,{[current.value]:current}),{}));
	},

	greyReason(grid) {
		let items = grid.dataSource.view();
		let gridBody = grid.wrapper.find('tbody');
		for (var i = 0; i < items.length; i++) {
			if (items[i].severity !== 'NONE' && items[i].inIncident) {
				let uid = items[i].uid;
				let row = $(gridBody).find("tr[data-uid=" + uid + "]");
				let reasonText = row.find('.reason_text');
				let text = $(reasonText).html();
				reasonText.html('');
				row.css('background-color', '#e5e5e5');
				reasonText.append('<span style="color: #333333">' + text + '</span>');
			}
		}

	},

	truncateDecimals(value, decimalsCount) {
		let stringValue = JSON.stringify(value);
		let splitStringValue = stringValue.split('.');

		let integerPart = splitStringValue[0];
		let decimalPart = splitStringValue[1];

		if (decimalPart && decimalsCount) {
			decimalPart = decimalPart.substr(0, decimalsCount);
			value = integerPart + '.' + decimalPart;
			value = JSON.parse(value);
		} else {
			value = integerPart;
		}

		return value;
	},
	convertToMilliseconds: function (value, unit) {
		let result;
		switch (unit) {
			case 'SECONDS':
				result = parseInt(value) * 1000;
				break;
			case 'MINUTES':
				result = parseInt(value) * 60 * 1000;
				break;
			case 'HOURS':
				result = parseInt(value) * 60 * 60 * 1000;
				break;
		}
		return result;
	},

	checkLicenseStatus() {
		if (Cookies.CeesoftLicenseInfo) {
			const licenseInfo = JSON.parse(Cookies.CeesoftLicenseInfo);
			if (licenseInfo.status !== 'OK') {
				const dialog = new Dialog({
					title: i('Invalid license'),
					msg: i('Invalid license message'),
					icon: 'WARNING'
				});
				dialog.show();
			}
			Cookies.erase('CeesoftLicenseInfo');
		}
	}
};

export default Utils;

let materialIconsLoaded = false;
export function ensureMaterialIconsLoaded() {
	if (materialIconsLoaded) {
		return Promise.resolve();
	}

	return new Promise((resolve, reject) => {
		let node = document.createElement('div');
		node.innerText = 'local_laundry_service';
		node.className = 'material-icons';
		node.style.position = 'absolute';
		node.style.zIndex = -1;
		node.style.fontSize = '20px;';
		document.body.appendChild(node);

		let timeout = null;
		let interval = setInterval(() => {
			if (node.clientWidth < 30) {
				resolve();
				clearInterval(interval);
				clearTimeout(timeout);
				document.body.removeChild(node);
				materialIconsLoaded = true;
			}
		}, 50);

		timeout = setTimeout(() => {
			reject('Material icons are not loaded');
		}, 2000)
	});
}

let glyphIconsLoaded = false;
export function ensureGlyphIconsLoaded() {
	if (glyphIconsLoaded) {
		return Promise.resolve();
	}

	return new Promise((resolve, reject) => {
		let node = document.createElement('div');
		node.className = 'glyphicons home';
		node.style.position = 'absolute';
		node.style.zIndex = -1;
		node.style.fontSize = '20px;';
		document.body.appendChild(node);

		let timeout = null;
		let interval = setInterval(() => {
			//here should be a condition to check if icons are loaded already
			//but so far no ideas
			if (false) {
				resolve();
				clearInterval(interval);
				clearTimeout(timeout);
				document.body.removeChild(node);
				glyphIconsLoaded = true;
			}
		}, 50);

		timeout = setTimeout(() => {
			resolve();
			glyphIconsLoaded = true;
			console.warn("Glyph icons are not loaded")
		}, 2000)
	});
}

export function createDataSource(url, method = 'GET', additionalProperties, parameterMap){
	return new CeeViewDataSource({
		...{
			transport: {
				read: {
					url: url,
					contentType: 'application/json; charset=utf-8',
					type: method,
					dataType: 'json',
					cache: false
				},
				parameterMap: parameterMap
			},
			error: ErrorHandler.kendoServerError
		},
		...additionalProperties
	});
}

export function gridGetSelectedIds(grid){
	let ids = [];

	if( grid[0] != null )
		grid = grid[0];

	if( grid.element != null )
		grid = grid.element[0];

	grid.querySelectorAll('.cw_grid_check:checked')
		.forEach( c => ids.push(c.getAttribute('data-id')));

	return ids;
}

export function updateQueryStringParameter(uri, key, value) {
	var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
	var separator = uri.indexOf('?') !== -1 ? "&" : "?";
	if (uri.match(re)) {
		return uri.replace(re, '$1' + key + "=" + value + '$2');
	}
	else {
		return uri + separator + key + "=" + value;
	}
}

export function removeHiddenColumnsFromSorting(columns, sort){
	let result = [];
	for(let sortEntry of sort){
		const column = columns[sortEntry.field];
		if(column != null && !column.hidden){
			result.push(sortEntry);
		}
	}
	return result;
}

function getObjectByPath(object, path, shift = 0, doNotBuildUp = false){
	if(object == null)
		return [{}, ''];

	//converting a.b[1].c to a.b.1.c
	path = path.replace('[', '.')
		.replace(']', '')
		.split('.');

	path.splice(-shift, shift);

	const lastPathEntry = path.pop();

	let currentObject = object;
	for (let i = 0; i < path.length; i++){
		let pathEntry = path[i];

		if (currentObject[pathEntry] == null) {
			if(doNotBuildUp){
				return [null, lastPathEntry];
			}

			let nextPathEntry = i == path.length - 1 ? lastPathEntry : path[i]

			currentObject[pathEntry] = isNaN(nextPathEntry) ? {} : [];
		}

		currentObject = currentObject[pathEntry];
	}

	return [currentObject, lastPathEntry];
}

export function setValueOnPath(object, path, value){
	let [targetObject, lastPathEntry] = getObjectByPath(object, path);
	targetObject[lastPathEntry] = value;
}

export function getValueOnPath(object, path, shift = 0){
	if(path == "") {
		return object;
	}

	let [targetObject, lastPathEntry] = getObjectByPath(object, path, shift, true);
	if(targetObject == null)
		return null;

	return targetObject[lastPathEntry];
}

export const byParentIdFilterFn = (parents, parentIdKey) => {
	return (x) => {
		return parents.some((parent) => parent.id === x[parentIdKey] );
	}
}

export const filterAndUpdateArraysOnPaths = (root, paths, filterFn) => {
	paths.forEach(path => {
		const result = getValueOnPath(root, path).filter(filterFn);
		setValueOnPath(root, path, result)
	});
}

export function isOneOf(currentValue, allValues){
	return allValues.find(value => value == currentValue) != null;
}

export function isNullOrWhitespace(string){
	return string == null || string.trim() == '';
}

export function trimText(text, maxLength){
	if(text?.length > maxLength){
		return text.substring(0, maxLength) + '...';
	}

	return text;
}

export function getWorstState(states){
	return Math.prototyp.min.apply(null, states.map(stateToNumber));
}

const stateToNumberObject = Object.freeze({
	'critical': 0,
	'major': 1,
	'minor': 2,
	'ok': 3,
	'none': 4
});

export function stateToNumber(state){
	return stateToNumberObject[state.toLowerCase()];
}


export function applyRequired(fields) {
	for (let field of fields) {
		if (!$(field).val()) {
			$(field).parent().addClass('required_input');
		}
		$(field).on('change', () => {
			if ($(field).val()) {
				$(field).parent().removeClass('required_input');
			} else {
				$(field).parent().addClass('required_input');
			}
		});
	}
}

export function toggleEllipsis(e) {
	const selectedRow = $(e.sender.select());
	let rowInitialHeight = selectedRow.height()
	const messageEl = $(selectedRow).find('.to_expand');
	messageEl.toggleClass('ellipsis');

	let rowTop = selectedRow.offset().top;
	let rowHeight = selectedRow.height();
	let pageHeight = document.body.offsetHeight;

	if (rowTop + rowHeight > pageHeight - 20) {
		let diff = rowHeight - rowInitialHeight;
		let currentScrollPos = $('.k-virtual-scrollable-wrap').scrollTop();
		$('.k-virtual-scrollable-wrap').scrollTop(currentScrollPos + diff);
	}
}

export function completeColumnsWidth(columns, gridContainer, defaultColumns) {
	let columnsWidth = 0;
	for (let column in columns) {
		if (columns[column].width) {
			columnsWidth += columns[column].width;
		}
	}

	if (columnsWidth > gridContainer.width()) {
		for (let column in columns) {
			if (!columns[column].width) {
				columns[column].width = defaultColumns[column].width;
			}
		}
	}

	return columns;
}

export function createImage(url) {
	const image = new Image();
	image.src = url;
	return image;
}

export function toIsoStringTimezone(date) {
	let extract = function(num) {
		let norm = Math.floor(Math.abs(num));
		return (norm < 10 ? '0' : '') + norm;
	};

	return date.getFullYear() +
		'-' + extract(date.getMonth() + 1) +
		'-' + extract(date.getDate()) +
		'T' + extract(date.getHours()) +
		':' + extract(date.getMinutes()) +
		':' + extract(date.getSeconds()) +
		'.000Z'
}

export function sortAlphabetically(a, b) {
	if (a.title < b.title) {
		return -1;
	}
	if (a.title < b.title) {
		return -1;
	}
	return 0;
};

export const rgbToHex = (rgb) => {
	let colors = rgb.split('rgb(')[1];
	let r = colors.split(', ')[0];
	let g = colors.split(', ')[1];
	let b = colors.split(', ')[2];
	b = b.split(')')[0];
	const finalHex = '#' + [r, g, b].map(x => {
		const hex = parseInt(x).toString(16)
		return hex.length === 1 ? '0' + hex : hex
	}).join('')
	return finalHex;
}

export const replaceStringAtIndex = (text, index, replacement) => {
	return text.substring(0, index) + (replacement || '') + text.substring(index + 1 + replacement.length);
}

export const delay = async (delayMs) => {
	return new Promise((resolve, reject) => {
		if (!delayMs) {
			resolve();
		}
		setTimeout(() => resolve(), delayMs);
	})
};

export const firstToUpper = (val) => {
	if (!val) {
		return val;
	}
	return val.toLowerCase().split('')
		.map((x, i) => i === 0 ? x.toUpperCase() : x)
		.join('');
}
