import './antAlert.less'

import React, {ChangeEventHandler} from "react"
import classnames from 'classnames'
import { observer } from 'mobx-react'
import {Alert, AlertProps} from 'antd'


interface AntAlertProps extends AlertProps{
}

const b = require('b_').with('ant-alert-wrapper')

export const AntAlert = observer( (props: AntAlertProps) => {
	let { className,...antNativeProps} = props

	const classes = classnames(b({"clickable": props.onClick != null}), className)

	return <Alert className={classes} {...antNativeProps}/>
})
