
export function getDescriptions(){
	return [
		{
			jspUrl: 'include/AssetHealth/AssetGroupDetails.jsp',
			bodyCssClass: 'cw_assethealth_module',
			url: '/assetgroups/:id',
			getModuleImport: () => import('./assetGroupDetails'),
			routeId: 'assethealth_group_details'
		}
	];
}

export class AssetGroupRouter {
	static details(assetGroupId) {
		return`/assetgroups/${assetGroupId}`;
	}

	static createNew(){
		return '/assetgroups/new';
	}
}
