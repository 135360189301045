import {Cookies} from "core";
import UrlBuilder from "tools/urlBuilder";

const root = '/reports';

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Report/ReportDetails.jsp',
			url: root + '/:id',
			bodyCssClass: 'cw_report_module',
			getModuleImport: () => import('./reportDetails'),
			routeId: 'reports'
		}
	];
}

export class ReportsRouter {
	static details(reportId, mode = 'update', additional) {
		return new UrlBuilder(root + '/' + reportId)
			.add('mode', mode)
			.add(additional)
			.build();
	}

	static copy(reportId, accountId = null) {
		return ReportsRouter.details(reportId,'copy');
	}

	static view(reportId, additional) {
		return ReportsRouter.details(reportId, 'view', additional);
	}

	static createNew() {
		return root + '/new';
	}
}
