import {Api, UrlBuilder} from 'tools';
import {useRemoteList} from "tools/api";
import {ApiRequest} from "framework/api";

export class ServicesApi{
	static getBaseUrl(accountId, sessionId){
		return Api.accountRoot(accountId, sessionId) + 'services';
	}

	static getServiceLiteUrl(accountId, sessionId) {
		return ServicesApi.getBaseUrl(accountId, sessionId) + '/lite?includeSubaccounts=true';
	}

	static getDynamicSearchUrl(accountId, sessionId) {
		return ServicesApi.getBaseUrl(accountId, sessionId) + '/search/lite?includeSubaccounts=true';
	}

	static getServiceUrl(serviceId, accountId, sessionId){
		return ServicesApi.getBaseUrl(accountId, sessionId) + '/' + serviceId;
	}

	static getServiceUpdateUrl(serviceId, updateMode, accountId, sessionId) {
		return `${ServicesApi.getBaseUrl(accountId, sessionId)}/${serviceId}/?update=${updateMode}`;
	}

	static getService(serviceId, updateMode = false, accountId, sessionId) {
		return Api.fetch(ServicesApi.getServiceUpdateUrl(serviceId, updateMode, accountId, sessionId));
	}

	static getModel(serviceId, accountId, cache, sessionId){
		return Api.fetch(
			ServicesApi.getServiceUrl(serviceId, accountId, sessionId) + "/mxmodel",
			null,
			cache,
			"serviceModel" + serviceId);
	}

	static getModelUrl(serviceId){
		return new UrlBuilder(ServicesApi.getServiceUrl(serviceId) + "/mxmodel");
	}

	static getModelToEdit(serviceId){
		return Api.fetch(
			ServicesApi.getModelUrl(serviceId)
				.addPath("edit")
		);
	}

	static saveModel(serviceId, model, continueEditing) {
		let urlBuilder = ServicesApi.getModelUrl(serviceId)
			.add('continueEditing', true, continueEditing);

		return Api.fetchPost(urlBuilder.build(), model);
	}

	static releaseModel(serviceId){
		return Api.fetch(ServicesApi.getServiceUrl(serviceId) + "/release");
	}

	static getServices(accountId, sessionId, cache) {
		return Api.fetch(
			ServicesApi.getBaseUrl(accountId, sessionId) + '/lite?hasModel=true',
			null,
			cache,
			'services' + accountId
		);
	}

	static getServiceElementUrl(serviceId) {
		return Api.server + 'services/' + serviceId + '/elements/lite';
	}

	static getServiceElements(serviceId, cache) {
		return Api.fetch(ServicesApi.getServiceElementUrl(serviceId),
			null, cache, 'service-elements' + serviceId);
	}

	static getElementQualifiers(serviceId, elementId){
		return Api.fetch(`${ServicesApi.getBaseUrl()}/${serviceId}/elements/${elementId}/qualifiers/lite?update=false`)
	}

	static getServiceQualifiers(serviceId){
		return Api.fetch(`${ServicesApi.getBaseUrl()}/${serviceId}/qualifiers/lite`)
	}

	static getRevisionsUrl(serviceId){
		return ServicesApi.getModelUrl(serviceId)
			.addPath('revisions')
	}

	static getRevisions(serviceId){
		return Api.fetch(ServicesApi.getRevisionsUrl(serviceId));
	}

	static deleteRevisions(serviceId, revisions){
		return Api.fetchPost(
			ServicesApi.getRevisionsUrl(serviceId)
				.addPath('delete'),
			revisions
		);
	}

	static updateRevision(serviceId, revision){
		return Api.fetchPost(
			ServicesApi.getRevisionsUrl(serviceId)
				.addPath(revision.time),
			revision
		);
	}

	static getRevisionToEdit(serviceId, revisionId){
		return Api.fetch(
			ServicesApi.getRevisionsUrl(serviceId)
				.addPath(revisionId)
				.addPath('model')
		);
	}

	static promoteRevision(serviceId, revisionId){
		return Api.fetch(
			ServicesApi.getRevisionsUrl(serviceId)
				.addPath(revisionId)
				.addPath('deploy')
		);
	}

	static getDraftUrl(serviceId){
		return ServicesApi.getModelUrl(serviceId)
			.addPath('draft')
	}

	static getDraftToEdit(serviceId){
		return Api.fetch(ServicesApi.getDraftUrl(serviceId));
	}

	static saveDraft(serviceId, model, promoteToCurrent = false){
		return Api.fetchPost(
			ServicesApi.getDraftUrl(serviceId).add("deploy", promoteToCurrent),
			model
		);
	}

	static deleteDraft(serviceId){
		return Api.fetchDelete(ServicesApi.getDraftUrl(serviceId));
	}

	static getSearchUrl() {
		return ServicesApi.getBaseUrl() + '/search/lite?includeSubaccounts=false';
	}

	static getLastMaintenances(serviceId) {
		return Api.fetch(`${ServicesApi.getBaseUrl()}/${serviceId}/maintenance/next3`)
	}
}

export class ServiceModelsApi{
	static getRootUrl(serviceId, accountId, sessionId) {
		return sessionId ?
			Api.sessionAndAccountRoot(accountId, sessionId) + "services/" + serviceId + "/" :
			Api.accountRoot(accountId) + "services/" + serviceId + "/";
	}

	static readModelTemplate(templateId, serviceId, accountId) {
		return Api.fetch(
			ServiceModelsApi.getRootUrl(serviceId, accountId) + "mxmodel/templates/" + templateId
		);
	}

	static saveModelTemplate(serviceId, templateData, accountId) {
		return Api.fetchPost(
			ServiceModelsApi.getRootUrl(serviceId, accountId) + "mxmodel/templates",
			templateData
		);
	}

	static readElementTemplate(templateId, serviceId, accountId) {
		return Api.fetch(
			ServiceModelsApi.getRootUrl(serviceId, accountId) + "mxmodel/elements/templates/" + templateId
		);
	}

	static saveElementTemplate(serviceId, templateData, accountId) {
		return Api.fetchPost(
			ServiceModelsApi.getRootUrl(serviceId, accountId) + "mxmodel/elements/templates",
			templateData
		);
	}

	static getServiceFromModel(modelId) {
		return Api.fetch(`${Api.serverRoot()}services/serviceInfo?modelId=${modelId}`)
	}
}

export function useServices(accountId) {
	return useRemoteList(ServicesApi.getServiceLiteUrl(accountId), {directList: true});
}

export function getServicesLite(accountId){
	return Api.fetch(ServicesApi.getServiceLiteUrl(accountId))
}

export function createLogEntry(args){
	return new ApiRequest({
		url: `services/${args.serviceId}/notifications/?status=${args.state}`,
		payload: {
			message: args.message
		}
	})
}
