import './recalculationWindow.less'
import {observer} from "mobx-react";
import React from "react";
import {AntModal, ModalPosition} from "../../../controls/react/ant/antModal";
import {DatePicker} from "antd";
import {Section} from "../../../controls/react/layout";
import {
	cancelRecalculation,
	IGridStorePayloadProvider,
	RecalculationDetail,
	RecalculationStatus,
	recalculationStatus
} from "./api";
import {makeAutoObservable} from "mobx";
import {apiFetch, ApiRequest, ApiResponse} from "../../../framework/api";
import {delay} from "../../../tools/utils";
import {AntButton} from 'controls/react/ant/antButton';
import FormEntry from 'controls/react/form/formEntry';
import {ApplicationState} from "../../../framework/applicationState";
import moment from "moment";
import {ModelValidator} from "../../../framework/mobx-integration";

const i = require('core/localization/localization').translator({
	'Time used': {
		no: 'Måler tiden'
	},
	'Recalculating': {
		no: 'Rekalkulerer'
	},
	'Not recaclulated': {
		no: 'Ikke rekalkulert'
	}
});

const b = require('b_').with('service-recalculation');

export class ServiceRecalculationProps {
	onCancel: () => void;
	gridStore: IGridStorePayloadProvider;
	startRecalculationRequest: <TIn extends IRecalculationModel>(store: IGridStorePayloadProvider, additionalData: IRecalculationModel) => ApiRequest<any>;
	additionalItems?: (store: ServiceRecalculationStore) => JSX.Element[] | JSX.Element;
	recalculatedLabel: string;
	recalculationFailedLabel: string;
	infoLabel: string;
}

export interface IRecalculationModel {
	startTime: moment.Moment;
	getPayload: () => any;
}

export class RecalculationModel implements IRecalculationModel {
	startTime: moment.Moment;
	recalculateSla: boolean = false;
	validator: ModelValidator<RecalculationModel>;

	constructor() {
		this.validator = new ModelValidator<RecalculationModel>(this);
		makeAutoObservable(this);
		this.validator.required('startTime');
	}

	getPayload = (): [Date, boolean] => {
		return [this.startTime.toDate(), this.recalculateSla];
	}
}

export class ServiceRecalculationStore {
	jobId: string;
	model: RecalculationModel;
	displayDetails: boolean;
	recalculationData: RecalculationStatus;
	timeUsed: string = '0';
	recalculationStarted: Date;
	props: ServiceRecalculationProps;

	get width() {
		return this.displayDetails
			? 850
			: 520;
	}

	constructor(props: ServiceRecalculationProps) {
		this.model = new RecalculationModel();
		this.props = props;
		makeAutoObservable(this);
	}

	onServiceRecalculate = async () => {
		this.recalculationStarted = new Date();
		const recalculateStartResult = await apiFetch(this.props.startRecalculationRequest(this.props.gridStore, this.model));
		if (recalculateStartResult.success) {
			this.jobId = recalculateStartResult.data;
			this.recalculationData = {
				total: 0,
				completed: 0,
				errors: 0
			};
			await this.startRecalculateListen();
		}
	}

	startRecalculateListen = async () => {
		while (this.jobId) {
			const status = await apiFetch(recalculationStatus(this.jobId));
			this.updateStatus(status);
			await delay(1000);
		}
	}

	updateStatus = (status: ApiResponse<RecalculationStatus>) => {
		if (!status.success) {
			this.recalculationData = {
				total: 0,
				completed: 0,
				errors: 0,
				details: []
			};
		} else {
			this.recalculationData = status.data;
		}
		if (this.recalculationData?.details != undefined) {
			this.jobId = undefined;
		}
		this.timeUsed = moment().diff(this.recalculationStarted, 'seconds', true).toFixed(1);
	}

	toggleRecalculationDetails = () => {
		this.displayDetails = !this.displayDetails;
	}

	cancelStatusRecalculation = async () => {
		const status = await apiFetch(cancelRecalculation(this.jobId));
		this.updateStatus(status);
		this.jobId = undefined;
	}
}


export const ServiceRecalculationContext = React.createContext<ServiceRecalculationStore>(null);

export const RecalculationWindow = observer(class RecalculationWindow extends React.Component<ServiceRecalculationProps> {
	store: ServiceRecalculationStore;

	constructor(props: ServiceRecalculationProps) {
		super(props);
		this.store = new ServiceRecalculationStore(props);
	}

	render() {
		return <ServiceRecalculationContext.Provider value={this.store}>
			<RecalculationWindowInner />
		</ServiceRecalculationContext.Provider>
	}
});

const RecalculationWindowInner = observer(() => {
	const store = React.useContext(ServiceRecalculationContext);

	return <AntModal
		title={<b>{i('Recalculating')}</b>}
		visible={true}
		positionType={ModalPosition.TopLeft}
		footer={<RecalculationWindowFooter />}
		destroyOnClose={true}
		className={b()}
		width={store.width}
		maskClosable={false}
		onCancel={store.props.onCancel}>
		{store.recalculationData && <>
			<Section childrenPadding={true}>
				<div className={b('info-row')}><label>{i('Time used')}:</label>{store.timeUsed}</div>
				<div className={b('info-row')}><label>{store.props.recalculatedLabel}:</label>{store.recalculationData?.completed}</div>
				<div className={b('info-row')}><label>{store.props.recalculationFailedLabel}:</label>{store.recalculationData?.errors}</div>
			</Section>
			{store.recalculationData?.details?.length > 0 && <>
				<Section>
					<div className={b('details-header')}>{i('Not recaclulated')}:</div>
					<div>{store.recalculationData.details.map(x => x.name).join((', '))}</div>
					<div><a onClick={store.toggleRecalculationDetails}>{i('Advanced')}</a></div>
				</Section>
				{store.displayDetails && <Section>
					{store.recalculationData.details.map((x: RecalculationDetail, index: number) => <div className={b('info-row')} key={index}>
						<label>{x.name}:</label>{x.details[0]}
					</div>)}
				</Section>}
			</>}
		</>}
		{!store.recalculationData && <Section childrenPadding={true}>
			<div>{store.props.infoLabel}</div>
			<FormEntry label={i('From')} model={store.model} modelField={'startTime'}>
				<DatePicker format={ApplicationState.dateFormat} popupStyle={{zIndex: 99999}} />
			</FormEntry>
			{store.props.additionalItems?.(store) ?? null}
		</Section>}
	</AntModal>
})

const RecalculationWindowFooter = observer(() => {
	const store = React.useContext(ServiceRecalculationContext);
	return <>
		{store.jobId && <AntButton onClick={store.cancelStatusRecalculation} key={'close'}>{i('Cancel')}</AntButton>}
		{!store.jobId && !store.recalculationData &&
			<AntButton disabled={!store.model.startTime} type="primary" onClick={store.onServiceRecalculate} key={'update'}>{i('Update')}</AntButton>}
		{!store.jobId && <AntButton onClick={store.props.onCancel} key={'close'}>{i('Close')}</AntButton>}
	</>
})
